import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import React, { FC, ReactNode, useMemo } from 'react'
import { OrganizationRiskSetting } from '@app/src/types/resourceExplorer'
import { useAccount } from './AccountContext'
import RiskSettingsContext, { RiskSettingsContextProps } from './RiskSettingsContext'

type RiskSettingsContextProviderProps = {
  children: ReactNode
}

const RiskSettingsContextProvider: FC<RiskSettingsContextProviderProps> = ({
  children,
}: RiskSettingsContextProviderProps) => {
  const { hasPermission } = usePermissions()
  const { account } = useAccount()

  const {
    data: riskSettings = [],
    isLoading,
    isError,
  } = useFetchResource<OrganizationRiskSetting[]>({
    key: FetchKey.OrganizationRiskSettings,
    endpoint: endpoints.organizationRiskSettings,
    options: {
      enabled: Boolean(account?.organization) && hasPermission(Permissions.RISK_ACCESS),
      keepPreviousData: true,
    },
  })

  const activeRiskSettings =
    useMemo(
      () => riskSettings?.filter(setting => setting.isActivated).map(riskSetting => riskSetting.riskType),
      [riskSettings],
    ) ?? []

  const getCountryRisksForSustainabilityArea: RiskSettingsContextProps['getCountryRisksForSustainabilityArea'] = (
    countryRisks = [],
    sustainabilityArea,
  ) => {
    return countryRisks
      .filter(risk => risk.riskType.sustainabilityArea === sustainabilityArea)
      .filter(risk => activeRiskSettings?.find(riskSetting => riskSetting.id === risk.riskType.id))
  }

  return (
    <RiskSettingsContext.Provider
      value={{
        riskSettings,
        activeRiskSettings,
        isLoadingRiskSettings: isLoading,
        isErrorRiskSettings: isError,
        getCountryRisksForSustainabilityArea,
      }}
    >
      {children}
    </RiskSettingsContext.Provider>
  )
}

export default RiskSettingsContextProvider
