import AddIcon from '@mui/icons-material/Add'
import { Alert, Box, Button, Chip, Link, TextField as MuiTextField, Typography } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

type TagsFieldType = {
  id: number
  tag: string
}

const EditRequestTags: React.FC = () => {
  const { formatMessage } = useIntl()
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray<TagsFieldType>({
    control,
    name: 'tags',
  })

  const [tag, setTag] = useState<string>('')

  const handleAddTag = () => {
    append({ tag })
    setTag('')
  }

  return (
    <>
      <Typography variant="h6" paragraph>
        {formatMessage({ id: 'templateBuilder.requestTags' })}
      </Typography>

      <Alert severity="warning" variant="filled" sx={{ mb: 2 }}>
        {formatMessage(
          { id: 'templateBuilder.advanced.warning' },
          {
            link: (chunks: ReactNode) => (
              <Link
                href="https://www.notion.so/worldfavor/Template-builder-cc3d4d7474fe441390a5329dc753dae7?pvs=4#05ed4ba98b944bfa8b951603fb16a631"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
              >
                {chunks}
              </Link>
            ),
          },
        )}
      </Alert>

      <Box display="flex" alignItems="center">
        <Box flexGrow={1} mr={2}>
          <MuiTextField
            variant="filled"
            fullWidth
            label={formatMessage({ id: 'templateBuilder.advanced.requestTagsTitle' })}
            inputProps={{
              maxLength: 50,
            }}
            size="small"
            value={tag}
            onChange={e => setTag(e.target.value)}
          />
        </Box>

        <Button variant="contained" startIcon={<AddIcon />} disabled={!tag} onClick={handleAddTag}>
          {formatMessage({ id: 'general.add' })}
        </Button>
      </Box>

      <Box display="flex" flexWrap="wrap" mt={1}>
        {fields?.map((field, index) => (
          <Controller
            key={field.id}
            control={control}
            name={`tags.${index}.tag`}
            defaultValue={field.tag}
            render={({ value }) => <Chip label={value} onDelete={() => remove(index)} sx={{ mt: 1, mr: 1 }} />}
          />
        ))}
      </Box>
    </>
  )
}

export default EditRequestTags
