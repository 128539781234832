import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewProviderRiskCard from '@app/src/components/Drawer/Views/DrawerViewProviderRiskCard'
import DrawerViewRiskAssessment from '@app/src/components/Drawer/Views/DrawerViewRiskAssessment'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import FinalRiskRatingCell from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import ProviderRiskCell from '@app/src/components/Table/Cells/ProviderRiskCell'
import RiskChipCell from '@app/src/components/Table/Cells/RiskChipCell'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import { FinalRiskRating } from '@app/src/types/categories'
import { RiskPrioritizationSummary, RiskStatus, SustainabilityArea } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'

interface ProviderRiskRowProps {
  row: RiskPrioritizationSummary
}

export const ProviderRiskRow: React.FC<ProviderRiskRowProps> = ({ row: riskPrioritizationSummary }) => {
  const { openDrawer } = useDrawer()
  const { getCountryRisksForSustainabilityArea } = useRiskSettings()

  const providerRisk = riskPrioritizationSummary.riskPrioritization
  const countryRisks = riskPrioritizationSummary.hqCountry?.risks ?? []

  const hasEnvironmentalRisk = getCountryRisksForSustainabilityArea(countryRisks, SustainabilityArea.Environment).some(
    risk => risk.status !== RiskStatus.Low,
  )
  const hasSocialRisk = getCountryRisksForSustainabilityArea(countryRisks, SustainabilityArea.Social).some(
    risk => risk.status !== RiskStatus.Low,
  )
  const hasGovernanceRisk = getCountryRisksForSustainabilityArea(countryRisks, SustainabilityArea.Governance).some(
    risk => risk.status !== RiskStatus.Low,
  )

  return (
    <TableRow>
      <CompanyCell company={providerRisk.provider} drawer />
      <RiskChipCell
        risk={providerRisk.riskStatus}
        size="medium"
        onClick={() => openDrawer(<DrawerViewProviderRiskCard riskPrioritizationSummary={riskPrioritizationSummary} />)}
      />
      <ProviderRiskCell
        count={providerRisk.environmentFlagCount}
        hqCountry={riskPrioritizationSummary.hqCountry?.name}
        hasPotentialRisk={hasEnvironmentalRisk}
        onClick={() => openDrawer(<DrawerViewProviderRiskCard riskPrioritizationSummary={riskPrioritizationSummary} />)}
        riskStatus={providerRisk.environmentRiskStatus}
      />
      <ProviderRiskCell
        count={providerRisk.socialFlagCount}
        hqCountry={riskPrioritizationSummary.hqCountry?.name}
        hasPotentialRisk={hasSocialRisk}
        riskStatus={providerRisk.socialRiskStatus}
        onClick={() => openDrawer(<DrawerViewProviderRiskCard riskPrioritizationSummary={riskPrioritizationSummary} />)}
      />
      <ProviderRiskCell
        count={providerRisk.governanceFlagCount}
        hqCountry={riskPrioritizationSummary.hqCountry?.name}
        hasPotentialRisk={hasGovernanceRisk}
        riskStatus={providerRisk.governanceRiskStatus}
        onClick={() => openDrawer(<DrawerViewProviderRiskCard riskPrioritizationSummary={riskPrioritizationSummary} />)}
      />
      <ProviderRiskCell
        count={providerRisk.customFlagCount}
        riskStatus={providerRisk.customRiskStatus}
        onClick={() => openDrawer(<DrawerViewProviderRiskCard riskPrioritizationSummary={riskPrioritizationSummary} />)}
      />
      <FinalRiskRatingCell
        value={providerRisk.provider.finalRiskRating}
        onClick={() =>
          openDrawer(
            <DrawerViewRiskAssessment
              providerIds={[providerRisk.provider.id]}
              subTitle={providerRisk.provider.organization.name}
              finalRiskRating={providerRisk.provider.finalRiskRating ?? FinalRiskRating.NotSelected}
            />,
          )
        }
      />
    </TableRow>
  )
}
export default ProviderRiskRow
