import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { OptionIcon } from '@app/src/components/Form/Select'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { AmplitudeTrackingEvents, Solutions } from '@app/src/wf-constants'
import { Box, Button, Chip, Grid, createFilterOptions } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { FacetItem, FiltersProps } from '..'
import AssessmentSubFilter from './AssessmentSubFilter'
import CustomCategoriesFilters from './CustomCategoriesFilters'
import FilterFacetSelect from './FilterFacetSelect'
import FilterSection from './FilterSection'
import OptionWithLabel from './OptionWithLabel'
import PreFetchedSingleFilterFacet from './PreFetchedSingleFilterFacet'

const ProviderRiskFilters: React.FC<FiltersProps> = ({ allowedFilters, resetPage, implicitFilters }) => {
  const { formatMessage } = useIntl()
  const { renderWithPermission } = usePermissions()
  const { solution } = useAuthentication().scope
  const { openDrawer } = useDrawer()
  const { trackEvent } = useAmplitude()
  const { assessments } = useFlags()
  const { hasPermission } = usePermissions()
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)
  const effectiveImplicitFilters = implicitFilters || []

  const tierFilter = createFilterOptions<FacetItem>({
    stringify: option =>
      formatMessage(
        {
          id: `schemas.provider.tierValues`,
        },
        { tier: option.value },
      ),
  })

  return (
    <Grid container spacing={1}>
      <Filters resetPage={resetPage} allowedFilters={allowedFilters}>
        {({ filters, inDrawerFiltersCount, clearQueryFilter }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item md={8} sm={12}>
                <Filter operator={Operators.In} name="provider.organization.name">
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.inquiryWithFacets,
                        facetsParam: [{ name: 'provider.organization.name' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.providerRisk.companyName' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>

              <Grid item md={2} sm={8}>
                <Filter name="periodName" operator={Operators.EqualTo}>
                  {({ value, onChange, filterName }) => (
                    <PreFetchedSingleFilterFacet
                      implicitFilters={implicitFilters}
                      endpoint={endpoints.riskPrioritizationWithFacets}
                      filterName={filterName}
                      isEnum
                      onChange={value => onChange(value, true)}
                      fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                      value={[value].flat()}
                      sort={{ target: 'label', order: SortOrder.DESCENDING }}
                    />
                  )}
                </Filter>
              </Grid>

              <Grid item md={2} sm={4}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2} mt={2}>
                            <Filter name="periodName" operator={Operators.EqualTo}>
                              {({ value, onChange, filterName }) => (
                                <PreFetchedSingleFilterFacet
                                  implicitFilters={implicitFilters}
                                  endpoint={endpoints.riskPrioritizationWithFacets}
                                  filterName={filterName}
                                  isEnum
                                  onChange={value => onChange(value, true)}
                                  fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                                  value={[value].flat()}
                                  sort={{ target: 'label', order: SortOrder.DESCENDING }}
                                />
                              )}
                            </Filter>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.companyDetails' })}>
                              <Filter operator={Operators.In} name="provider.organization.name">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'provider.organization.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.providerRisk.companyName' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            {canAccessAssessmentTemplate && (
                              <AssessmentSubFilter
                                effectiveImplicitFilters={effectiveImplicitFilters}
                                canAccessAssessmentTemplate={canAccessAssessmentTemplate}
                                assessments={assessments}
                                assessmentFilterNamePrefix="provider.assessments"
                                fetchKey={FetchKey.RequestFacets}
                                endpoint={endpoints.inquiryWithFacets}
                              />
                            )}
                            <FilterSection
                              defaultCollapsed
                              label={formatMessage({ id: 'schemas.filter.additionalInformation' })}
                            >
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.ownerUserId">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.inquiryWithFacets,
                                        facetsParam: [{ name: 'provider.ownerUser.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>

                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.finalRiskRating">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.inquiryWithFacets,
                                        facetsParam: [{ name: 'provider.finalRiskRating', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }): JSX.Element => (
                                        <li {...props}>
                                          <OptionIcon selected={selected} multiple />
                                          <FinalRiskRatingIcon value={option?.label} />
                                          {option?.label
                                            ? formatMessage({
                                                id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                              })
                                            : ''}
                                        </li>
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                icon={<FinalRiskRatingIcon value={option.label} />}
                                                label={formatMessage({
                                                  id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={value => {
                                        if (value.length) {
                                          trackEvent({
                                            name: AmplitudeTrackingEvents.Analyze.Filter.RiskAssessment,
                                            eventProps: {
                                              filter_value: value,
                                            },
                                          })
                                        }

                                        onChange(value)
                                      }}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="provider.priority">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.inquiryWithFacets,
                                        facetsParam: [{ name: 'provider.priority', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.priorityValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.provider.priorityValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              {solution !== Solutions.Finance && (
                                <>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="provider.activityStatus">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.RequestFacets, filterName],
                                            endpoint: endpoints.inquiryWithFacets,
                                            facetsParam: [{ name: 'provider.activityStatus', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.activityStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.activityStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="provider.providerApprovalStatus">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.RequestFacets, filterName],
                                            endpoint: endpoints.inquiryWithFacets,
                                            facetsParam: [{ name: 'provider.providerApprovalStatus', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.providerApprovalStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              )}
                              {renderWithPermission({
                                [Permissions.SOURCING_USER]: (
                                  <>
                                    <Box mb={2}>
                                      <Filter operator={Operators.In} name="provider.supplierUsage">
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.RequestFacets, filterName],
                                              endpoint: endpoints.inquiryWithFacets,
                                              facetsParam: [{ name: 'provider.supplierUsage', isEnum: true }],
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                              <OptionWithLabel
                                                key={option.label}
                                                props={props}
                                                label={formatMessage({
                                                  id: option?.label
                                                    ? `schemas.provider.supplierUsageValues.${option.label}`
                                                    : 'general.notAvailable',
                                                })}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage({
                                                        id: `schemas.provider.supplierUsageValues.${option.label}`,
                                                      })}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                    <Box mb={2}>
                                      <Filter operator={Operators.In} name="provider.tier">
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.RequestFacets, filterName],
                                              endpoint: endpoints.inquiryWithFacets,
                                              facetsParam: [{ name: 'provider.tier', isEnum: true }],
                                            }}
                                            filterOptions={(options, state) => [...tierFilter(options, state)]}
                                            renderOption={(props, option, { selected }) => (
                                              <OptionWithLabel
                                                key={option.label}
                                                props={props}
                                                label={formatMessage(
                                                  {
                                                    id: `schemas.provider.tierValues`,
                                                  },
                                                  { tier: option?.label ?? '' },
                                                )}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage(
                                                        {
                                                          id: `schemas.provider.tierValues`,
                                                        },
                                                        { tier: option.label },
                                                      )}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                  </>
                                ),
                              })}
                            </FilterSection>
                            <CustomCategoriesFilters baseFilterName="provider" />
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default ProviderRiskFilters
