import { useQueryParams } from '@app/src/hooks/queryParams'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthentication } from '@app/src/context/AuthenticationContext'

export const getRedirectionParameters = (
  path: string,
  queryString: string,
): { redirectTo: string; loginParams: string } => {
  const params = new URLSearchParams(queryString)

  // if there's an organizationId and solution we save them and remove them from the params
  const organizationId = params.get('organizationId')
  const solution = params.get('solution')
  params.delete('organizationId')
  params.delete('solution')

  // building the url to redirect to, including params, without organizationId and solution that are used to automatically log user in
  const redirectTo = params.toString() ? `${path}?${params.toString()}` : path

  // passing the login params to the solution selector scene page, to automatically log user in to a solution if needed
  const loginParams = organizationId && solution ? `&organizationId=${organizationId}&solution=${solution}` : ''

  return { redirectTo, loginParams }
}

const useSolutionAutoLogin = () => {
  const history = useHistory()
  const params = useQueryParams()
  const organizationId = parseInt(params.get('organizationId') ?? '')
  const solution = params.get('solution')
  const { scope } = useAuthentication()
  const pathname = history.location.pathname

  useEffect(() => {
    if (organizationId && solution && (scope.organization !== organizationId || scope.solution !== solution)) {
      const { search } = history.location
      const { redirectTo, loginParams } = getRedirectionParameters(history.location.pathname, search)

      history.push(
        `/?redirectTo=${encodeURIComponent(redirectTo)}${loginParams}`, // this will redirect to the solution selector scene page, which will automatically log user in to the solution
      )
      return
    }
    if (scope.solution === 'default' && scope.role === 'default' && pathname !== '/') {
      history.push(
        `/?redirectTo=${encodeURIComponent(pathname)}`, // this will redirect to the solution selector scene page, which will automatically log user in to the solution
      )
    }
  }, [organizationId, solution])
}
export default useSolutionAutoLogin
