import React from 'react'

import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import EvaluationStatus from '@app/src/pages/ResourceCollection/Collections/Evaluation/EvaluationStatus'
import { Indicator } from '@app/src/types/resourceExplorer'
import { InputAdornment } from '@mui/material'
import { Control, DeepMap, FieldError, FieldValues, UseFormMethods } from 'react-hook-form'
import { useIntl } from 'react-intl'

type IndicatorResultInputProps = {
  activeIndicator?: Indicator
  control: Control<FieldValues>
  register: UseFormMethods['register']
  setValue: UseFormMethods['setValue']
  errors: DeepMap<FieldValues, FieldError>
}

const IndicatorResultInput: React.FC<IndicatorResultInputProps> = ({
  activeIndicator,
  control,
  register,
  setValue,
  errors,
}) => {
  const { formatMessage } = useIntl()

  if (activeIndicator?.indicatorType === 'Options') {
    const options = activeIndicator?.frameworkOptionCollection?.values?.map(item => ({
      label: item,
      value: item,
    }))

    return (
      <Select
        name="result"
        fieldLabel={formatMessage({ id: 'form.createEvaluation.resultSelect' })}
        control={control}
        required
        enableAutoSelect
        disabled={!activeIndicator}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <OptionIcon selected={selected} />
            <EvaluationStatus value={option.label} />
            <OptionAdornment option={option} />
          </li>
        )}
        options={options}
        error={errors.result}
      />
    )
  }

  return (
    <TextField
      inputRef={register}
      fullWidth
      name="result"
      label={formatMessage({ id: 'form.createEvaluation.result' })}
      disabled={!activeIndicator}
      error={Boolean(errors.result)}
      type="number"
      helperText={errors?.name?.result}
      InputProps={{
        endAdornment: activeIndicator?.unit?.symbol && (
          <InputAdornment position="end">{activeIndicator.unit.symbol}</InputAdornment>
        ),
      }}
      onClear={(): void => setValue('result', '')}
    />
  )
}

export default IndicatorResultInput
