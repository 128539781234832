import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const CustomReportHeader: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell minWidth={TableCellWidth.LARGE} label={formatMessage({ id: 'schemas.requestTemplate.title' })} />
      <TableHeaderCell
        minWidth={TableCellWidth.EXTRA_SMALL}
        label={formatMessage({ id: 'schemas.requestTemplate.status' })}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'automations.flagNumberHeader' })}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <TableHeaderCell
        minWidth={TableCellWidth.EXTRA_SMALL}
        label={formatMessage({ id: 'schemas.requestTemplate.questionnaireType' })}
      />
      <TableHeaderCell minWidth={TableCellWidth.EMPTY} label="" />
    </TableRow>
  )
}

export default CustomReportHeader
