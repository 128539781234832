import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Box, Divider, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const Reporter = () => {
  const { response } = useReport()
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()

  if (!response?.creatorOrganization) {
    return null
  }

  return (
    <Box pt={2}>
      <ListItem disablePadding>
        <ListItemAvatar sx={({ spacing }) => ({ minWidth: spacing(5) })}>
          <CompanyAvatar
            imageUrl={response?.creatorOrganization?.image?.url}
            organizationName={response?.creatorOrganization?.name ?? ''}
            size={AvatarSize.Medium}
          />
        </ListItemAvatar>
        <ListItemText
          primary={response?.creatorOrganization?.name}
          secondary={
            <Typography variant="caption">
              {formatMessage(
                { id: 'reporting.sharing.reportedOn' },
                { date: formatValueToDate({ value: response?.createdAt ?? '' }) },
              )}
            </Typography>
          }
        />
      </ListItem>

      <Divider variant="fullWidth" />
    </Box>
  )
}

export default Reporter
