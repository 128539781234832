import { TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'
import ResponseItemHeader from './ResponseItemHeader'

const ResponseItemCompanyHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.responseItem.target' })}
        sorting={{ name: 'creatorOrganizationId', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <ResponseItemHeader activeSorting={activeSorting} toggleSorting={toggleSorting} disableTableRow />
    </TableRow>
  )
}

export default ResponseItemCompanyHeader
