import { Components } from '@mui/material'

const MuiDrawer: Components['MuiDrawer'] = {
  defaultProps: {},
  styleOverrides: {
    paper: {
      width: 500,
      padding: 24,
      margin: 16,
      height: '90%',
    },
  },
}

export default MuiDrawer
