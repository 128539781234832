import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import HoverDialog from '@app/src/components/HoverDialog'
import LinkButton from '@app/src/components/LinkButton'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import paths from '@app/src/wf-constants/paths'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'
import { Box, Button, Menu, MenuItem, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import React, { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import GraphLegend from './GraphLegend'
import StatisticsCard from './StatisticsCard'

const ALL_PERIODS = 'All'

enum ProgressTypes {
  Done = 'done',
  Started = 'started',
  NotStarted = 'not-started',
}

const CompaniesByReportingProgress = () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { palette } = useTheme()
  const [selectedPeriod, setSelectedPeriod] = useState<string>(ALL_PERIODS)
  const [highlightedProgress, setHighLightedProgress] = useState<ProgressTypes>()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const [isPeriodMenuOpen, setIsPeriodMenuOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const {
    facets: [companiesWithRequestedInquiries],
    isLoading: companiesWithRequestedInquiriesIsLoading,
  } = useFetchFacets({
    key: FetchKey.OverdueRequests,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested],
            operator: Operators.In,
          },
        ],
      },
      ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
        ? [
            {
              name: 'periodName',
              filters: [
                {
                  value: [selectedPeriod],
                  operator: Operators.In,
                },
              ],
            },
          ]
        : []),
    ],
  })

  const {
    facets: [companiesWithCorrectionNeededInquiries],
    isLoading: companiesWithCorrectionNeededInquiriesIsLoading,
  } = useFetchFacets({
    key: FetchKey.OverdueRequests,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.CorrectionNeeded],
            operator: Operators.In,
          },
        ],
      },
      ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
        ? [
            {
              name: 'periodName',
              filters: [
                {
                  value: [selectedPeriod],
                  operator: Operators.In,
                },
              ],
            },
          ]
        : []),
    ],
  })

  const {
    facets: [companiesWithSubmittedInquiries],
    isLoading: companiesWithSubmittedInquiriesIsLoading,
  } = useFetchFacets({
    key: FetchKey.OverdueRequests,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Submitted],
            operator: Operators.In,
          },
        ],
      },
      ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
        ? [
            {
              name: 'periodName',
              filters: [
                {
                  value: [selectedPeriod],
                  operator: Operators.In,
                },
              ],
            },
          ]
        : []),
    ],
  })

  const {
    facets: [companiesWithApprovedInquiries],
    isLoading: companiesWithApprovedInquiriesIsLoading,
  } = useFetchFacets({
    key: FetchKey.OverdueRequests,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Approved],
            operator: Operators.In,
          },
        ],
      },
      ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
        ? [
            {
              name: 'periodName',
              filters: [
                {
                  value: [selectedPeriod],
                  operator: Operators.In,
                },
              ],
            },
          ]
        : []),
    ],
  })

  const {
    facets: [companiesWithInquiries],
    isLoading: companiesWithInquiriesIsLoading,
  } = useFetchFacets({
    key: FetchKey.RequestsOverdueWithinAWeek,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.NotSet],
            operator: Operators.NotIn,
          },
        ],
      },
      ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
        ? [
            {
              name: 'periodName',
              filters: [
                {
                  value: [selectedPeriod],
                  operator: Operators.In,
                },
              ],
            },
          ]
        : []),
    ],
  })

  const {
    facets: [requestedPeriods],
    isLoading: requestedPeriodsIsLoading,
  } = useFetchFacets({
    key: [FetchKey.Inquiry, 'periods'],
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [],
  })

  const availablePeriods = requestedPeriods?.map(facet => facet.label).sort((a, b) => parseInt(b) - parseInt(a))

  const companiesWithInquiriesIds = companiesWithInquiries?.map(item => item.value)
  const companiesWithRequestedInquiriesIds = companiesWithRequestedInquiries?.map(item => item.value)
  const companiesWithCorrectionNeededInquiriesIds = companiesWithCorrectionNeededInquiries?.map(item => item.value)
  const companiesWithSubmittedInquiriesIds = companiesWithSubmittedInquiries?.map(item => item.value)
  const companiesWithApprovedInquiriesIds = companiesWithApprovedInquiries?.map(item => item.value)

  const doneCompanies = companiesWithInquiriesIds?.filter(
    company =>
      !companiesWithRequestedInquiriesIds?.includes(company) &&
      !companiesWithCorrectionNeededInquiriesIds?.includes(company),
  )
  const notStartedCompanies = companiesWithInquiriesIds?.filter(
    company =>
      !companiesWithCorrectionNeededInquiriesIds?.includes(company) &&
      !companiesWithSubmittedInquiriesIds?.includes(company) &&
      !companiesWithApprovedInquiriesIds?.includes(company),
  )
  const startedCompanies = companiesWithInquiriesIds?.filter(
    company => !doneCompanies?.includes(company) && !notStartedCompanies?.includes(company),
  )

  const doneCompaniesCount = doneCompanies?.length
  const startedCompaniesCount = startedCompanies?.length
  const notStartedCompaniesCount = notStartedCompanies?.length

  const noCompanyData = doneCompaniesCount === 0 && startedCompaniesCount === 0 && notStartedCompaniesCount === 0

  const isLoading =
    companiesWithRequestedInquiriesIsLoading ||
    companiesWithCorrectionNeededInquiriesIsLoading ||
    companiesWithInquiriesIsLoading ||
    companiesWithSubmittedInquiriesIsLoading ||
    companiesWithApprovedInquiriesIsLoading ||
    requestedPeriodsIsLoading
  const emptyState = !isLoading && !requestedPeriods?.length && noCompanyData
  const noData = Boolean(requestedPeriods?.length) && noCompanyData

  const doneLink = stringifyQueryFilters({
    url: generatePath(paths.manageRequest, {
      view: ViewTypeName.Requests,
    }),
    queryParams: {
      filters: [
        {
          name: 'provider.id',
          value: doneCompanies,
          operator: Operators.In,
        },
        ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
          ? [
              {
                name: 'periodName',
                value: [selectedPeriod],
                operator: Operators.In,
              },
            ]
          : []),
      ],
    },
  })

  const startedLink = stringifyQueryFilters({
    url: generatePath(paths.manageRequest, {
      view: ViewTypeName.Requests,
    }),
    queryParams: {
      filters: [
        {
          name: 'provider.id',
          value: startedCompanies,
          operator: Operators.In,
        },
        ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
          ? [
              {
                name: 'periodName',
                value: [selectedPeriod],
                operator: Operators.In,
              },
            ]
          : []),
      ],
    },
  })

  const notStartedLink = stringifyQueryFilters({
    url: generatePath(paths.manageRequest, {
      view: ViewTypeName.Requests,
    }),
    queryParams: {
      filters: [
        {
          name: 'provider.id',
          value: notStartedCompanies,
          operator: Operators.In,
        },
        ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
          ? [
              {
                name: 'periodName',
                value: [selectedPeriod],
                operator: Operators.In,
              },
            ]
          : []),
      ],
    },
  })

  const options: EChartsOption = {
    type: 'bar',
    stack: 'chart',
    itemStyle: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    xAxis: {
      show: false,
      max: doneCompaniesCount + startedCompaniesCount + notStartedCompaniesCount,
      splitLine: {
        show: false,
      },
      triggerEvent: true,
    },
    yAxis: {
      inverse: true,
      type: 'category',

      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      triggerEvent: true,
      axisLabel: {
        show: false,
      },
    },
    grid: {
      top: 0,
      bottom: 0,
      right: 0,
      containLabel: false,
      show: false,
      left: 0,
    },
    series: [
      {
        type: 'bar',
        stack: 'x',
        data: [{ value: doneCompaniesCount, name: ProgressTypes.Done }],

        emphasis: {
          focus: 'self',
        },

        itemStyle: {
          color: palette.visualization[1],
          borderType: 'solid',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius:
            Boolean(startedCompaniesCount) || Boolean(notStartedCompaniesCount) ? [5, 0, 0, 5] : [5, 5, 5, 5],
        },
      },

      {
        type: 'bar',
        stack: 'x',
        data: [{ value: startedCompaniesCount, name: ProgressTypes.Started }],

        emphasis: {
          focus: 'self',
        },
        itemStyle: {
          color: palette.visualization[3],
          borderType: 'solid',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius:
            Boolean(doneCompaniesCount) && Boolean(notStartedCompaniesCount)
              ? [0, 0, 0, 0]
              : doneCompaniesCount
                ? [0, 5, 5, 0]
                : notStartedCompaniesCount
                  ? [5, 0, 0, 5]
                  : [5, 5, 5, 5],
        },
      },
      {
        type: 'bar',
        stack: 'x',
        data: [{ value: notStartedCompaniesCount, name: ProgressTypes.NotStarted }],

        emphasis: {
          focus: 'self',
        },
        itemStyle: {
          color: palette.grey[300],
          borderType: 'solid',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius: Boolean(doneCompaniesCount) || Boolean(startedCompaniesCount) ? [0, 5, 5, 0] : [5, 5, 5, 5],
        },
      },
    ],
  }

  const events = useMemo(
    () => ({
      mouseover: ({ data }: { data: { name: ProgressTypes } }) => {
        setHighLightedProgress(data.name)
      },
      click: ({ data }: { data: { name: ProgressTypes } }) => {
        if (data.name === ProgressTypes.Done) {
          history.push(doneLink)
        }
        if (data.name === ProgressTypes.Started) {
          history.push(startedLink)
        }
        if (data.name === ProgressTypes.NotStarted) {
          history.push(notStartedLink)
        }
      },
    }),
    [],
  )

  return (
    <StatisticsCard
      title={formatMessage({ id: 'dashboard.sourcing.companiesByReportingProgress.title' })}
      helperText={formatMessage({ id: 'dashboard.sourcing.companiesByReportingProgress.helperText' }, { br })}
      action={
        !emptyState && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="primary" variant="body1">
              {formatMessage({ id: 'schemas.request.periodDisplayName' })}
            </Typography>
            <Box>
              <Button
                endIcon={<ArrowDropDownIcon />}
                size="small"
                onClick={() => setIsPeriodMenuOpen(true)}
                ref={anchorRef}
              >
                {selectedPeriod}
              </Button>
              <Menu
                open={isPeriodMenuOpen}
                anchorEl={anchorRef.current}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                elevation={1}
                onClose={() => setIsPeriodMenuOpen(false)}
              >
                <MenuItem
                  onClick={() => {
                    setIsPeriodMenuOpen(false)
                    setSelectedPeriod(ALL_PERIODS)
                  }}
                >
                  {formatMessage({ id: `dashboard.sourcing.companyStatistics.all` })}
                </MenuItem>
                {availablePeriods?.map(period => (
                  <MenuItem
                    key={period}
                    onClick={() => {
                      setIsPeriodMenuOpen(false)
                      setSelectedPeriod(period)
                    }}
                  >
                    {period}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Stack>
        )
      }
      loading={{
        isLoading,
        skeleton: (
          <Stack width="100%">
            <Skeleton width="100%" height={50} sx={{ my: 1 }} />

            <Stack direction="row" spacing={2}>
              <Skeleton width="10%" height={32} variant="rounded" />
              <Skeleton width="10%" height={32} variant="rounded" />
              <Skeleton width="10%" height={32} variant="rounded" />
            </Stack>
          </Stack>
        ),
      }}
    >
      <>
        {emptyState ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={4}>
            <EmptyState
              title={formatMessage({ id: 'dashboard.sourcing.companiesByReportingProgress.emptyState.title' })}
              description={formatMessage({
                id: 'dashboard.sourcing.companiesByReportingProgress.emptyState.description',
              })}
              iconComponent={SignalCellularAltOutlinedIcon}
            />
          </Box>
        ) : noData ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={4}>
            <EmptyState
              title={formatMessage({ id: 'dashboard.sourcing.companiesByReportingProgress.noData.title' })}
              description={formatMessage({
                id: 'dashboard.sourcing.companiesByReportingProgress.noData.description',
              })}
              iconComponent={SignalCellularAltOutlinedIcon}
            />
          </Box>
        ) : (
          <Stack width="100%">
            <HoverDialog
              content={
                <Box p={2} onMouseEnter={() => setHighLightedProgress(undefined)}>
                  <Stack spacing={1}>
                    <Typography variant="overline" color="textSecondary" noWrap>
                      {formatMessage({ id: 'dashboard.sourcing.companiesByReportingProgress.title' })}
                    </Typography>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius={2}
                      bgcolor={highlightedProgress === ProgressTypes.Done ? 'grey.100' : 'white'}
                      sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                      onMouseEnter={() => setHighLightedProgress(ProgressTypes.Done)}
                      onMouseLeave={() => setHighLightedProgress(undefined)}
                      onClick={() => history.push(doneLink)}
                    >
                      <GraphLegend
                        color="visualization.neutral.900"
                        variant="body1"
                        legend={formatMessage(
                          { id: 'dashboard.sourcing.companiesByReportingProgress.doneWithCount' },
                          { count: doneCompaniesCount },
                        )}
                      />
                      {highlightedProgress === ProgressTypes.Done && (
                        <LinkButton to={doneLink}>{formatMessage({ id: 'dashboard.sourcing.seeAll' })}</LinkButton>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius={2}
                      bgcolor={highlightedProgress === ProgressTypes.Started ? 'grey.100' : 'white'}
                      sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                      onMouseEnter={() => setHighLightedProgress(ProgressTypes.Started)}
                      onMouseLeave={() => setHighLightedProgress(undefined)}
                      onClick={() => history.push(startedLink)}
                    >
                      <GraphLegend
                        color="visualization.neutral.500"
                        variant="body1"
                        legend={formatMessage(
                          { id: 'dashboard.sourcing.companiesByReportingProgress.startedWithCount' },
                          { count: startedCompaniesCount },
                        )}
                      />
                      {highlightedProgress === ProgressTypes.Started && (
                        <LinkButton to={startedLink}>{formatMessage({ id: 'dashboard.sourcing.seeAll' })}</LinkButton>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius={2}
                      bgcolor={highlightedProgress === ProgressTypes.NotStarted ? 'grey.100' : 'white'}
                      sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                      onMouseEnter={() => setHighLightedProgress(ProgressTypes.NotStarted)}
                      onMouseLeave={() => setHighLightedProgress(undefined)}
                      onClick={() => history.push(notStartedLink)}
                    >
                      <GraphLegend
                        color="grey.300"
                        variant="body1"
                        legend={formatMessage(
                          { id: 'dashboard.sourcing.companiesByReportingProgress.notStartedWithCount' },
                          { count: notStartedCompaniesCount },
                        )}
                      />
                      {highlightedProgress === ProgressTypes.NotStarted && (
                        <LinkButton to={notStartedLink}>
                          {formatMessage({ id: 'dashboard.sourcing.seeAll' })}
                        </LinkButton>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              }
            >
              <Box width="100%" py={1}>
                <ReactEcharts option={options} onEvents={events} style={{ height: 50 }} />
              </Box>
            </HoverDialog>
            <Stack direction="row" spacing={2}>
              <GraphLegend
                color={palette.visualization[1]}
                legend={formatMessage({ id: 'dashboard.sourcing.companiesByReportingProgress.done' })}
                linkTo={doneLink}
              />
              <GraphLegend
                color={palette.visualization[3]}
                legend={formatMessage({ id: 'dashboard.sourcing.companiesByReportingProgress.started' })}
                linkTo={startedLink}
              />
              <GraphLegend
                color={palette.grey[300]}
                legend={formatMessage({ id: 'dashboard.sourcing.companiesByReportingProgress.notStarted' })}
                linkTo={notStartedLink}
              />
            </Stack>
          </Stack>
        )}
      </>
    </StatisticsCard>
  )
}

export default CompaniesByReportingProgress
