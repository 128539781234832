import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { User } from '@app/src/types/resourceExplorer'
import { ChevronRight } from '@mui/icons-material'
import { List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerViewUser from './DrawerViewUser'

export type DrawerViewUserGroupProps = {
  users: User[]
} & Omit<DrawerViewProps, 'title'>

const DrawerViewUserGroup: React.FC<DrawerViewUserGroupProps> = ({ users, ...props }) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()

  return (
    <DrawerView title={formatMessage({ id: 'schemas.provider.contacts' })} {...props}>
      <List disablePadding>
        {users.map(user => (
          <ListItemButton key={user.id} onClick={() => openDrawer(<DrawerViewUser user={user} />)}>
            <ListItemAvatar>
              <PersonAvatar name={user.name} />
            </ListItemAvatar>
            <ListItemText primary={user.name} secondary={user.position ?? '-'} />
            <ChevronRight color="action" />
          </ListItemButton>
        ))}
      </List>
    </DrawerView>
  )
}

export default DrawerViewUserGroup
