import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@mui/material'
import React from 'react'

export enum AvatarSize {
  XS = 'xs',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XL = 'xl',
  XXL = 'XXL',
  XXXL = 'XXXL',
}

export type AvatarProps = MuiAvatarProps & {
  size?: AvatarSize
}

const getAvatarSizeCss = (size: AvatarSize, rest: MuiAvatarProps['sx'] = []): MuiAvatarProps['sx'] => {
  switch (size) {
    case AvatarSize.XS:
      return [
        ...[rest].flat(),
        {
          height: 16,
          width: 16,
          fontSize: 'caption.fontSize',
        },
      ]
    case AvatarSize.Small:
      return [
        ...[rest].flat(),
        {
          width: 24,
          height: 24,
          fontSize: 'caption.fontSize',
          letterSpacing: 'caption.letterSpacing',
          fontWeight: 'caption.fontWeight',
          lineHeight: 'caption.lineHeight',
        },
      ]
    case AvatarSize.Medium:
      return [
        ...[rest].flat(),
        {
          width: 32,
          height: 32,
        },
      ]
    case AvatarSize.XL:
      return [
        ...[rest].flat(),
        {
          width: 48,
          height: 48,
        },
      ]

    case AvatarSize.XXL:
      return [
        ...[rest].flat(),
        {
          width: 56,
          height: 56,
        },
      ]

    case AvatarSize.XXXL:
      return [
        ...[rest].flat(),
        {
          width: 96,
          height: 96,
        },
      ]

    //Large is default (40px)
    default:
      return [rest].flat()
  }
}

const Avatar: React.FC<AvatarProps> = ({ size = AvatarSize.Large, children, sx, ...rest }) => {
  return (
    <MuiAvatar {...rest} sx={getAvatarSizeCss(size, sx)}>
      {children}
    </MuiAvatar>
  )
}

export default Avatar
