import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import React from 'react'
import { ResourceCollectionSceneProps } from '../../ResourceCollectionScene'
import BaseProviderScene from './BaseProviderScene'

type AllProvidersSceneProps = {
  relatedContentFilter?: FilterGroup[]
  allowedFilters: string[]
} & Omit<ResourceCollectionSceneProps, 'title'>

const AllProvidersScene: React.FC<AllProvidersSceneProps> = ({
  relatedContentFilter = [],
  allowedFilters,
  ...props
}) => {
  return <BaseProviderScene implicitFilters={relatedContentFilter} allowedFilters={allowedFilters} {...props} />
}
export default AllProvidersScene
