import { MoreHorizOutlined } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, TableRow } from '@mui/material'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewDeleteResource from '@app/src/components/Drawer/Views/DrawerViewDeleteResource'
import DrawerViewInvitationForm from '@app/src/components/Drawer/Views/DrawerViewInvitationForm'
import EmailCell from '@app/src/components/Table/Cells/EmailCell'
import IconCell from '@app/src/components/Table/Cells/IconCell'

import UserCell from '@app/src/components/Table/Cells/UserCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Invitation } from '@app/src/types/resourceExplorer'
import { Solutions } from '@app/src/wf-constants'

interface InvitationRowProps {
  row: Invitation
}

export const InvitationRow: React.FC<InvitationRowProps> = ({ row: invitation }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { formatValueToDate } = useGetFormattedDate()
  const [dialogAnchorEl, setDialogAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(dialogAnchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDialogAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setDialogAnchorEl(null)
  }

  return (
    <>
      <TableRow>
        <UserCell user={invitation} drawer canEdit canDelete isDisabled />
        <EmailCell mail={invitation.email} />
        <ValueCell value={invitation.role} />
        <ValueCell value={invitation.status} />
        <ValueCell value={formatValueToDate({ value: invitation.createdAt })} />
        <IconCell
          icon={
            <IconButton size="small" onClick={handleClick}>
              <MoreHorizOutlined color="action" />
            </IconButton>
          }
        />
      </TableRow>

      <Menu anchorEl={dialogAnchorEl} open={open} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            openDrawer(
              <DrawerViewInvitationForm
                title={formatMessage({ id: 'general.edit' })}
                subTitle={invitation.name}
                invitation={invitation}
              />,
            )
            closeMenu()
          }}
        >
          {formatMessage({ id: 'general.edit' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDrawer(
              <DrawerViewDeleteResource
                fetchKey={FetchKey.Invitation}
                resourceId={invitation.id}
                solution={Solutions.Resources}
                resourceType={invitation.type.toLowerCase()}
                subTitle={invitation.name}
              />,
            )
            closeMenu()
          }}
        >
          {formatMessage({ id: 'general.delete' })}
        </MenuItem>
      </Menu>
    </>
  )
}

export default InvitationRow
