import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import DateDisplay from '@app/src/components/DateDisplay'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import React from 'react'
import { useIntl } from 'react-intl'
import Assessment from '@app/src/types/assessment'
import { Response } from '@app/src/types/resourceExplorer'
import AssessmentSectionComponent from './AssessmentSection'
import BaseAssessmentInfoDialog from './BaseAssessmentInfoDialog'

interface DetailedReviewProps {
  latestResponse: Response
  assessment: Assessment
}

const DetailedReview = ({ latestResponse, assessment }: DetailedReviewProps) => {
  const { formatMessage } = useIntl()
  const [open, openDialog, closeDialog] = useDialogState()

  return (
    <Paper elevation={0} sx={{ px: 3, py: 2, mt: 2, mr: 5 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Stack direction="column">
          <Typography variant="overline" color="text.secondary" gutterBottom>
            {formatMessage({ id: 'baseAssessment.providerPage.detailedReview' })}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {formatMessage({ id: 'baseAssessment.providerPage.responseSubmittedOn' })}{' '}
            <DateDisplay value={latestResponse.submittedAt} />
          </Typography>
        </Stack>
        <Button startIcon={<LibraryBooksOutlinedIcon />} onClick={openDialog} variant="text" id="learnMoreButton">
          {formatMessage({ id: 'general.learnMore' })}
        </Button>
      </Box>
      {assessment.assessmentTemplate.sections.map((section, i) => (
        <AssessmentSectionComponent
          key={section.id}
          section={section}
          assessment={assessment}
          response={latestResponse}
        />
      ))}
      <BaseAssessmentInfoDialog open={open} closeDialog={closeDialog} />
    </Paper>
  )
}

export default DetailedReview
