import { BaseAssessmentViews } from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/BaseAssessmentScene'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { ProviderTabs } from '@app/src/pages/ResourceCollection/Collections/Providers/ProvidersScene'
import { RiskScreeningViews } from '@app/src/pages/ResourceCollection/Collections/RiskScreening/RiskScreeningScene'
import { Flows } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { generatePath } from 'react-router-dom'

export const providers = generatePath(paths.providers, { view: ProviderTabs.All })
export const contacts = generatePath(paths.contacts)
export const responseItems = generatePath(paths.dataHubCollection, {
  flow: Flows.Interaction,
  view: DataHubView.DataInsights,
})
export const transparencySubscriptions = generatePath(paths.subscriptions)
export const evaluations = generatePath(paths.evaluations)
export const dashboard = generatePath(paths.dashboard)
export const notifications = generatePath(paths.notifications)
export const co2eCalculator = generatePath(paths.co2eCalculator)
export const engagedProviders = generatePath(paths.engagedProviders)
export const products = generatePath(paths.productsCollection)
export const productMappingCompanies = generatePath(paths.productMappingCompanies)
export const productMappingContacts = generatePath(paths.productMappingContacts)
export const valueChains = generatePath(paths.valueChainCollection)
export const mappingRequests = generatePath(paths.mappingRequestCollection)
export const sentMappingRequests = generatePath(paths.sentMappingRequestCollection)
export const receivedMappingResponses = generatePath(paths.receivedMappingResponseCollection)
export const requests = generatePath(paths.manageRequest, { view: ViewTypeName.Requests })
export const responses = generatePath(paths.manageRequest, { view: ViewTypeName.Responses })
export const manageRequestOverview = generatePath(paths.manageRequest, { view: ViewTypeName.Overview })
export const providerRisk = generatePath(paths.providerRisk)
export const riskScreeningHq = generatePath(paths.riskScreening, { view: RiskScreeningViews.Headquarter })
export const riskScreeningAllLocations = generatePath(paths.riskScreening, {
  view: RiskScreeningViews.Headquarter,
})
export const baseAssessment = generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Overview })
export const assessments = generatePath(paths.assessments, { view: BaseAssessmentViews.Overview })

const navbarLinks = {
  providers,
  contacts,
  responseItems,
  transparencySubscriptions,
  evaluations,
  dashboard,
  notifications,
  co2eCalculator,
  engagedProviders,
  products,
  valueChains,
  mappingRequests,
  sentMappingRequests,
  receivedMappingResponses,
  productMappingCompanies,
  productMappingContacts,
  requests,
  responses,
  manageRequestOverview,
  providerRisk,
  riskScreeningHq,
  baseAssessment,
  assessments,
}

export default navbarLinks
