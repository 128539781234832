import DateDisplay from '@app/src/components/DateDisplay'
import RenderFileDownload from '@app/src/components/RenderFileDownload'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { getResponseValueTypes } from '../../utils'

export interface AnswerValueProps {
  item: ResponseItem
  questionType: string
  unitSymbol: string | null
}

const AnswerValue: React.FC<AnswerValueProps> = ({ item, questionType, unitSymbol }) => {
  const { formatMessage } = useIntl()
  const { displayAsFile, displayAsOptions, selectedOptions, hasValue } = getResponseValueTypes(questionType, item)

  if (displayAsFile) {
    return (
      <RenderFileDownload
        fileName={item.uploadReferences?.[0].originalFileName}
        id={item.uploadReferences?.[0].id ?? 0}
      >
        {item.expiresAt && (
          <Box display="flex" alignItems="flex-end" mr={2}>
            <Typography variant="caption">
              {formatMessage({ id: 'questionnaire.expiresAt' }, { date: <DateDisplay value={item.expiresAt} /> })}
            </Typography>
          </Box>
        )}
      </RenderFileDownload>
    )
  }

  if (displayAsOptions) {
    if (selectedOptions?.length) {
      return <Typography>{selectedOptions?.join(', ')}</Typography>
    }
  }

  if (hasValue) {
    return (
      <Typography sx={{ wordBreak: 'break-word' }}>
        {item.answer || ''} {unitSymbol || ''}
      </Typography>
    )
  }

  return <Typography>{formatMessage({ id: 'reporting.noResponse' })}</Typography>
}

export default AnswerValue
