import GraphHoverDialog from '@app/src/components/GraphHoverDialog'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import StatisticsBar from '@app/src/pages/Dashboards/StatisticsBar'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import OverviewCompanyProgressDialog from '@app/src/pages/ResourceCollection/Collections/ManageRequests/Overview/OverviewCompanyProgressDialog'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { GenericOrganization } from '@app/src/types/organizations'
import { QuestionnaireCompanyProgressStatistics } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { Box, Grid, useTheme } from '@mui/material'
import React from 'react'
import { generatePath, useHistory } from 'react-router'

interface CompanyProgressSectionProps {
  statistics: QuestionnaireCompanyProgressStatistics
  userFilters: FilterGroup[]
}

export const CompanyProgressSection: React.FC<CompanyProgressSectionProps> = ({ statistics, userFilters }) => {
  const { palette } = useTheme()
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  return (
    <Box py={1}>
      <Grid container alignItems="center">
        <Grid item xs={5} display="flex" gap={1} alignItems="center">
          <CompanyCell
            drawer
            disableCell
            isPartialProviderData
            company={
              {
                name: statistics.responderCompanyName,
                id: statistics.providerId,
                linked: true,
                image: { url: statistics.responderImageUrl },
              } as GenericOrganization
            }
          />
        </Grid>
        <Grid item xs={7}>
          <GraphHoverDialog
            content={
              <OverviewCompanyProgressDialog
                companyName={statistics.responderCompanyName}
                providerId={statistics.providerId}
                userFilters={userFilters}
                imageUrl={statistics.responderImageUrl}
              />
            }
          >
            <span>
              <StatisticsBar
                percent
                value={statistics.numberOfRespondedRequests}
                max={statistics.totalNumberOfRequestsSent}
                color={palette.visualization[1]}
                label={`${statistics.numberOfRespondedRequests} / ${statistics.totalNumberOfRequestsSent}`}
                barWidth={32}
                showBackground
                onBarClick={() =>
                  history.push(
                    stringifyQueryFilters({
                      url: generatePath(paths.manageRequest, {
                        view: ViewTypeName.Requests,
                      }),
                      queryParams: {
                        filters: [
                          {
                            name: 'provider.id',
                            value: statistics.providerId,
                            operator: Operators.EqualTo,
                          },
                        ],
                      },
                      includeCurrentFilters: true,
                    }),
                  )
                }
              />
            </span>
          </GraphHoverDialog>
        </Grid>
      </Grid>
    </Box>
  )
}
