import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import React from 'react'
import {
  ThreeIntervalChartIconOne,
  ThreeIntervalChartIconThree,
  ThreeIntervalChartIconTwo,
} from '../icons/ThreeIntervals'
import {
  FiveIntervalChartIconOne,
  FiveIntervalChartIconTwo,
  FiveIntervalChartIconThree,
  FiveIntervalChartIconFour,
  FiveIntervalChartIconFive,
} from '../icons/FiveIntervals'

type AssessmentMethodologyPieChartProps = {
  assessmentTemplate: AssessmentTemplate
  level: number
}

const IconBasedOnInterval: React.FC<{ intervals: number; value: number }> = ({ intervals, value }) => {
  if (intervals === 3) {
    switch (value) {
      case 1:
        return <ThreeIntervalChartIconOne large />
      case 2:
        return <ThreeIntervalChartIconTwo large />
      case 3:
        return <ThreeIntervalChartIconThree large />
    }
  }

  switch (value) {
    case 1:
      return <FiveIntervalChartIconOne large />
    case 2:
      return <FiveIntervalChartIconTwo large />
    case 3:
      return <FiveIntervalChartIconThree large />
    case 4:
      return <FiveIntervalChartIconFour large />
    case 5:
      return <FiveIntervalChartIconFive large />
  }
}

const AssessmentMethodologyPieChart: React.FC<AssessmentMethodologyPieChartProps> = ({ assessmentTemplate, level }) => {
  const intervals = assessmentTemplate.levels ?? 5

  return <IconBasedOnInterval intervals={intervals} value={level} />
}

export default AssessmentMethodologyPieChart
