import Permissions from '@app/src/auth/permissions'
import { useAccount } from '@app/src/context/AccountContext'
import { ContactTabs } from '@app/src/pages/ResourceCollection/Collections/ProviderContactsOverview'
import { AllTabs } from '@app/src/pages/ResourceExplorer/Content'
import { insertIf } from '@app/src/utils/helpersTs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useIntl } from 'react-intl'

type TabProps = {
  type: AllTabs
  url: string
  view?: string
  label: string
}

const useRelatedTabs = (): TabProps[] => {
  const { hasPermission } = useAccount()
  const { evaluations } = useFlags()
  const { productMapping } = useFlags()
  const { formatMessage } = useIntl()

  return [
    {
      type: AllTabs.Overview,
      url: '/resource/overview',
      label: formatMessage({ id: 'resourceTypes.overview' }),
    },
    {
      type: AllTabs.Contact,
      url: '/resource/contact/public',
      label: formatMessage({ id: 'resourceTypes.contact' }),
      view: ContactTabs.Public,
    },
    ...insertIf(hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS), {
      type: AllTabs.BaseAssessment,
      url: '/resource/assessment',
      label: formatMessage({ id: 'navbar.baseAssessment' }),
    }),
    {
      type: AllTabs.ProviderProgress,
      url: '/resource/providerProgress',
      label: formatMessage({ id: 'resourceTypes.supplierProgress' }),
    },
    {
      type: AllTabs.ResponseItem,
      url: '/resource/responseItem',
      label: formatMessage({ id: 'resourceTypes.responseItem' }),
    },
    {
      type: AllTabs.Request,
      url: '/resource/request',
      label: formatMessage({ id: 'resourceTypes.request' }),
    },
    {
      type: AllTabs.Response,
      url: '/resource/response',
      label: formatMessage({ id: 'resourceTypes.response' }),
    },
    ...insertIf(evaluations && hasPermission(Permissions.EVALUATION_ACCESS), {
      type: AllTabs.Evaluation,
      url: '/evaluations',
      label: formatMessage({ id: 'resourceTypes.datapoint' }),
    }),
    {
      type: AllTabs.RiskScreening,
      url: '/risk-screening',
      label: formatMessage({ id: 'resourceTypes.risk-screening' }),
    },
    ...insertIf(productMapping, {
      type: AllTabs.MappingNode,
      url: '/mappingNode',
      label: formatMessage({ id: 'resourceTypes.product' }),
    }),
    {
      type: AllTabs.Activity,
      url: '/activity',
      label: formatMessage({ id: 'resourceTypes.providerActivity' }),
    },
  ]
}

export default useRelatedTabs
