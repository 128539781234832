import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewReviewResponseValueChainToCopyToProduct from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewResponseValueChainToCopyToProduct'
import DrawerViewSelectProductMappingResponse from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewSelectProductMappingResponse'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import { MappingResponseWithOrg } from '@app/src/pages/Product/ProductScene'
import { Product, ProductMappingRequest } from '@app/src/types/product'
import { CancelOutlined } from '@mui/icons-material'
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined'
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ProductRequestHeader from './ProductRequestHeader'
import ProductRequestRow from './ProductRequestRow'

interface ProductRequestCardProps {
  latestMappingResponsesWithOrg: MappingResponseWithOrg[]
  mappingRequests: ProductMappingRequest[]
  product: Product
  disableValueChainReplacement?: boolean
}

export const ValueChainEmptyStateAlternative: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <EmptyState
      iconComponent={CancelOutlined}
      title={formatMessage({ id: 'schemas.product.valueChainEmptyState' })}
      description=""
    />
  )
}

const ProductRequestCard: React.FC<ProductRequestCardProps> = ({
  latestMappingResponsesWithOrg,
  mappingRequests,
  product,
  disableValueChainReplacement = false,
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const handleAddToValueChain = () => {
    if (mappingRequests.length > 1) {
      openDrawer(
        <DrawerViewSelectProductMappingResponse
          targetProduct={product}
          mappingResponses={latestMappingResponsesWithOrg}
        />,
      )
    } else {
      openDrawer(
        <DrawerViewReviewResponseValueChainToCopyToProduct
          organization={latestMappingResponsesWithOrg[0].responderOrganization}
          mappingResponse={latestMappingResponsesWithOrg[0].response}
          targetProduct={product}
          disableValueChainReplacement={disableValueChainReplacement}
        />,
      )
    }
  }

  const isAnyResponded = mappingRequests.some(item => item.productMappingResponses?.[0])

  return (
    <Stack spacing={4}>
      <Typography variant="h5">{formatMessage({ id: 'schemas.mappingRequest.sentMappingRequests' })}</Typography>

      <Table<ProductMappingRequest>
        RowComponent={({ row }) => (
          <ProductRequestRow row={row} product={product} disableValueChainReplacement={disableValueChainReplacement} />
        )}
        HeaderComponent={ProductRequestHeader}
        data={mappingRequests}
        count={mappingRequests?.length}
        isLoading={false}
        isError={false}
        page={0}
        pageSize={10000}
        noPagination
      />

      {isAnyResponded && !disableValueChainReplacement && (
        <Stack alignItems="center" justifyContent="center">
          <Button
            startIcon={<PlaylistAddOutlinedIcon />}
            variant="contained"
            size="large"
            onClick={handleAddToValueChain}
          >
            {formatMessage({ id: 'schemas.product.addToValueChain' })}
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

export default ProductRequestCard
