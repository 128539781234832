import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const SendInviteTimeline: React.FC = () => {
  const { formatMessage } = useIntl()

  const events = [
    { id: 1, message: 'referral.invitationSchedule.emailSentOnSend' },
    { id: 2, message: 'referral.invitationSchedule.reminderEmailIn7Days' },
    { id: 3, message: 'referral.invitationSchedule.reminderEmailIn14Days' },
  ]

  return (
    <Stack flexGrow={1} spacing={2}>
      <Typography variant="subtitle1">
        {formatMessage({ id: 'form.connectProviders.review.invitationSchedule' })}
      </Typography>
      <Timeline
        sx={{
          padding: 0,

          '& :before': {
            display: 'none',
          },
        }}
      >
        {events.map((event, i) => (
          <TimelineItem key={event.id}>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              {i !== events.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography>{formatMessage({ id: event.message })}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Stack>
  )
}

export default SendInviteTimeline
