import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'

export type DrawerViewAnswerDetailsProps = {
  companyName: string
  answer: string
  question: string
} & Omit<DrawerViewProps, 'title'>

const DrawerViewAnswerDetails: React.FC<DrawerViewAnswerDetailsProps> = ({
  companyName,
  answer,
  question,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.responseItem.answerDetail' })}
      subTitle={companyName}
      buttons={[
        {
          label: formatMessage({ id: 'general.close' }),
          onClick: closeDrawer,
        },
      ]}
      {...props}
    >
      <Stack pt={2} flexGrow={1} spacing={1} px={3}>
        <Typography variant="subtitle1" mt={2}>
          {question}
        </Typography>
        <Typography>{answer}</Typography>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAnswerDetails
