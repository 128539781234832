import { Box, Chip, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import LinkButton from '@app/src/components/LinkButton'
import qs from 'qs'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { SimpleOrganization } from '@app/src/types/organizations'
import { ReportAccessModel } from '@app/src/types/resourceExplorer'
import { RequestTags, SfdrRequestTags, WfRequestTags } from '@app/src/wf-constants'
import { reporting } from '@app/src/wf-constants/paths'
import { TagIcon } from './TagIcon'

interface ResponseRowProps {
  requestId: number
  responseId: number
  title: string
  tags: RequestTags[]
  isLastTemplate: boolean
  isDraft: boolean
  accessors?: SimpleOrganization[]
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  tagChip: {
    marginRight: spacing(1),
  },
  draftChip: {
    backgroundColor: palette.warning.light,
  },
}))

const ResponseRow: React.FC<ResponseRowProps> = ({
  requestId,
  responseId,
  title,
  tags,
  isLastTemplate,
  isDraft,
  accessors,
}) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()

  const { data: currentAccess, isLoading: isLoadingCurrentAccess } = useFetchResource<ReportAccessModel>({
    key: [FetchKey.Response, FetchKey.ResponseSharing, requestId],
    endpoint: endpoints.getAccessForReport(requestId),
  })

  const organizationsWithAccess = useMemo(
    () => accessors?.filter(org => currentAccess?.organizationIds.includes(org.id)) ?? [],
    [accessors, currentAccess],
  )

  return (
    <React.Fragment>
      <Box py={2}>
        <Grid container>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <TagIcon tags={tags} />
              <Typography variant="body1">{title}</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" alignItems="center" height="100%">
              {Boolean(tags?.length) &&
                tags[0] !== WfRequestTags.PublicRequest1 &&
                tags[0] !== SfdrRequestTags.Sfdr && (
                  <Chip
                    className={classes.tagChip}
                    label={formatMessage({ id: `transparencyOverview.tags.${tags[0]}` })}
                    size="small"
                  />
                )}
              {isDraft && (
                <Chip className={classes.draftChip} label={formatMessage({ id: 'general.draft' })} size="small" />
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" alignItems="center" height="100%">
              {isLoadingCurrentAccess && <CircularProgress size={16} />}
              {currentAccess?.isAllAccess && (
                <Typography variant="body1">
                  {formatMessage({ id: 'transparencyOverview.sharedWithNetwork' })}
                </Typography>
              )}
              {!currentAccess?.isAllAccess && Boolean(currentAccess?.organizationIds.length) && (
                <Typography variant="body1">
                  {formatMessage(
                    { id: 'transparencyOverview.sharedWithCompanies' },
                    {
                      isMultiple: organizationsWithAccess.length > 1,
                      count: organizationsWithAccess.length - 1,
                      target: organizationsWithAccess[0].name,
                    },
                  )}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <LinkButton
                variant="outlined"
                color="primary"
                to={{
                  pathname: reporting,
                  search: qs.stringify({ requestId, responseId }),
                }}
              >
                {formatMessage({ id: `general.open` })}
              </LinkButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isLastTemplate && <Divider />}
    </React.Fragment>
  )
}

export default ResponseRow
