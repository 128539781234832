import React from 'react'

const LeftBackBlob = () => {
  return (
    <svg width="265" height="94" viewBox="0 0 265 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M149.584 32.1642C96.0359 45.0468 74.5208 -38.5316 -22 22.6478V140.016C73.21 152.176 265 159.534 265 91.6416C265 22.6478 189.139 22.6478 149.584 32.1642Z"
        fill="#FFCE85"
      />
    </svg>
  )
}

export default LeftBackBlob
