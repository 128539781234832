import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Organization } from '@app/src/types/organizations'
import { Divider, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'

interface Props {
  organizations?: Organization[]
}

const OrganizationList: React.FC<Props> = ({ organizations }) => {
  if (!organizations) return null
  return (
    <>
      {organizations.map((org, i) => (
        <React.Fragment key={org.id}>
          <ListItem>
            <ListItemAvatar>
              <CompanyAvatar organizationName={org.name[0]} imageUrl={org.image?.url} />
            </ListItemAvatar>
            <ListItemText primary={org.name} />
          </ListItem>
          {i < organizations.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </>
  )
}

export default OrganizationList
