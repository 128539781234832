import {
  RenderWithPermission as _RenderWithPermission,
  usePermissions as _usePermissions,
  UsePermissionsType as _UsePermissionsType,
} from '@app/src/auth/permissions/usePermissions'

export const usePermissions = _usePermissions
export const RenderWithPermission = _RenderWithPermission
export type UsePermissionsType = _UsePermissionsType

export enum Permissions {
  // Transparency specific permissions
  TRANSPARENCY_USER = 'TRANSPARENCY_USER_ACCESS',

  // Sourcing specific permissions
  SOURCING_USER = 'SOURCING_USER_ACCESS',

  // Finance specific permissions
  FINANCE_USER = 'FINANCE_USER_ACCESS',

  // Investment specific permissions
  INVESTMENT_USER = 'INVESTING_USER_ACCESS',

  // General permissions
  CREATE_REQUEST = 'REQUEST_WRITE_ACCESS',
  WRITE_VERIFICATION = 'RESPONSE_REVIEW_ACCESS',
  WRITE_ADVANCED_ORG_DETAILS = 'ADVANCED_ORGANIZATION_DETAILS_WRITE_ACCESS',
  FULL_DASHBOARD_ACCESS = 'DASHBOARD_READ_ACCESS',
  DATA_HUB_ACCESS = 'RESPONSE_ITEMS_READ_ACCESS',
  RISK_ACCESS = 'RISK_READ_ACCESS',
  EVALUATION_ACCESS = 'EVALUATION_READ_ACCESS',
  PROVIDER_RISK_ACCESS = 'PROVIDER_RISK_READ_ACCESS',

  // Solution Admin permissions
  ACCESS_SOLUTION_CONFIGURATION = 'CONFIGURATION_ACCESS',
  WF_EMAIL_ACCESS = 'WF_EMAIL_ACCESS',

  // Worldfavor Admin permissions
  WORLDFAVOR_ADMIN = 'WF_ADMIN_USER_ACCESS',
  WORLDFAVOR_EMPLOYEE_ADMIN = 'WF_EMPLOYEE_ADMIN_ACCESS',

  //EDCI permissions
  EDCI_ENABLED = 'EDCI_ENABLE',

  PRODUCT_MAPPING_RESPOND_ACCESS = 'PRODUCT_MAPPING_RESPOND_ACCESS',
  ASSESSMENT_TEMPLATE_ACCESS = 'ASSESSMENT_TEMPLATE_ACCESS',
}

export default Permissions
