import { Unit } from '@app/src/types/resourceExplorer'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import EuroIcon from '@mui/icons-material/Euro'
import { capitalize, Typography } from '@mui/material'
import React from 'react'

const SUFFIXES = [
  { symbol: 'T', value: 1_000_000_000_000 },
  { symbol: 'B', value: 1_000_000_000 },
  { symbol: 'M', value: 1_000_000 },
  { symbol: 'k', value: 1_000 },
]

const addCurrencySuffix = (amount: number) => {
  const suffixToUse = SUFFIXES.find(s => amount > s.value)
  if (!suffixToUse) return Math.round(amount).toString()
  const convertedAmount = Math.round(amount / suffixToUse.value)
  return `${convertedAmount}${suffixToUse.symbol}`
}

export const toCurrencyString = (amount: number, currencyUnit?: Unit) => {
  const amountWithSuffix = addCurrencySuffix(amount)
  if (!currencyUnit?.symbol) return amountWithSuffix

  switch (currencyUnit.symbol.toLowerCase()) {
    case 'eur':
      return `€${amountWithSuffix}`
    case 'usd':
      return `$${amountWithSuffix}`
    case 'sek':
      return `${amountWithSuffix} kr`
    default:
      return `${amountWithSuffix} ${currencyUnit.symbol}`
  }
}

export const getCurrencyIcon = (currencyUnit?: Unit) => {
  if (!currencyUnit?.symbol) return
  switch (currencyUnit.symbol.toLowerCase()) {
    case 'eur':
      return <EuroIcon sx={{ pr: 1 }} />
    case 'usd':
      return <AttachMoneyIcon sx={{ pr: 1 }} />
    default:
      return (
        <Typography variant="body2" color="textSecondary" pr={1}>
          {capitalize(currencyUnit.symbol)}
        </Typography>
      )
  }
}
