import { TableRow } from '@mui/material'
import { QuestionStepRenderPropParams } from '@app/src/components/CreateNewRequestModal/Generic/QuestionStep'
import Table from '@app/src/components/Table'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { useMultipleStepsFormContext } from '@app/src/context/MultipleStepsFormContext'
import React from 'react'
import { FieldValues } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Product } from '@app/src/types/product'

interface Props extends QuestionStepRenderPropParams {
  product: Product
}

const Row: React.FC<{ row: FieldValues }> = ({ row }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <ValueCell value={row.product.name} />
      <ValueCell value={row.period} />
      <CompanyCell company={row.provider} />
      <DateCell value={row.deadline} />
      <ValueCell value={formatMessage({ id: row.instructions ? 'general.yes' : 'general.no' })} />
    </TableRow>
  )
}

const Header: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'general.product' }, { count: 1 })} />
      <TableHeaderCell label={formatMessage({ id: 'form.createRequest.tableHeaders.period' })} />
      <TableHeaderCell label={formatMessage({ id: 'general.Company' }, { count: 1 })} />
      <TableHeaderCell label={formatMessage({ id: 'form.createRequest.tableHeaders.deadline' })} />
      <TableHeaderCell label={formatMessage({ id: 'form.createRequest.tableHeaders.instructions' })} />
    </TableRow>
  )
}

const ReviewStep: React.FC<Props> = ({ product }) => {
  const { values } = useMultipleStepsFormContext()

  return (
    <Table<FieldValues>
      RowComponent={Row}
      HeaderComponent={Header}
      data={[{ ...values, product: product, providerId: values.provider.id }]}
      count={1}
      page={1}
      isError={false}
      isLoading={false}
      pageSize={1}
      noPagination
    />
  )
}

export default ReviewStep
