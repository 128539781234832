import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import ListItemSkeletonWrapper from '@app/src/components/Skeleton/ListItem'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import useDeleteAssessmentDialog from '@app/src/hooks/useDeleteAssessmentDialog'
import { useOrganizationAssessmentSettingsCount } from '@app/src/hooks/useOrganizationAssessmentSettingsCount'
import SustainabilityLibraryCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/SustainabilityLibraryCollection'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { DeleteOutlined } from '@mui/icons-material'
import { RadioGroupProps } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router'
import AssessmentForm from './AssessmentForm'
import { AssessmentTemplateFormValues } from './CreateAssessmentFromQuestionnaire'

type EditAssessmentProps = {
  assessmentId: number
}

const EditAssessment: React.FC<EditAssessmentProps> = ({ assessmentId }) => {
  const { formatMessage } = useIntl()
  const formMethods = useForm<AssessmentTemplateFormValues>()
  const [intervals, setIntervals] = useState<number>(5)
  const { mutate: updateAssessmentTemplate } = useCreateResource()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const history = useHistory()
  const organizationAssessmentSettingsCount = useOrganizationAssessmentSettingsCount(assessmentId)
  const { confirmDelete } = useDeleteAssessmentDialog({ assessmentTemplateId: assessmentId })

  const { items = [], isLoading } = useFetchCollectionWithPost<AssessmentTemplate>({
    endpoint: endpoints.assessmentTemplateCollection,
    key: [FetchKey.AssessmentCollection, assessmentId],
    payload: {
      filter: [
        {
          name: 'id',
          filters: [
            {
              operator: Operators.EqualTo,
              value: assessmentId,
            },
          ],
        },
      ],
      include: [
        'questionnaireTemplate.creatorOrganization.name',
        'questionnaireTemplate.sections.questions.questionType',
        'questionnaireTemplate.sections.questions.unit',
        'questionnaireTemplate.sections.questions.requestCollection',
        'questionnaireTemplate.sections.questions.reportFlagRules',
        'questionnaireTemplate.sections.questions.reportFlagRules.creatorOrganization',
        'questionnaireTemplate.sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskNaceCodes.naceCode',
        'questionnaireTemplate.sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskGuidanceLinks',
        'questionnaireTemplate.sections.questions.questionAnswerClassificationRules',
      ],
    },
    options: {
      enabled: Boolean(assessmentId),
    },
  })

  const assessmentTemplate = items[0]
  const questions = assessmentTemplate?.questionnaireTemplate?.sections?.map(i => i.questions)?.flat() ?? []

  const onSubmit = (values: AssessmentTemplateFormValues) => {
    const assessmentTemplateToEdit = {
      ...values,
      id: assessmentTemplate.id,
      questionnaireTemplateId: assessmentTemplate.questionnaireTemplateId,
      levels: intervals,
    }

    const body = {
      assessmentTemplate: assessmentTemplateToEdit,
      questionIds: questions?.map(q => q.id) ?? [],
      questionnaireTemplateId: assessmentTemplate.questionnaireTemplateId,
    }

    updateAssessmentTemplate(
      {
        url: endpoints.assessmentTemplate,
        body,
      },
      {
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.OrganizationSettings)
          showSnackbar({
            message: formatMessage({ id: 'assessments.successfullyUpdated' }),
            severity: NotificationSeverity.success,
          })
          history.push(
            generatePath(paths.sustainabilityLibrary, {
              configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
            }),
          )
        },
      },
    )
  }

  const handleIntervalsChange: RadioGroupProps['onChange'] = event => {
    setIntervals(parseInt(event.target.value))
  }

  useEffect(() => {
    if (assessmentTemplate) {
      setIntervals(assessmentTemplate.levels ?? 5)
      formMethods.reset({ ...assessmentTemplate })
    }
  }, [assessmentTemplate])

  return (
    <>
      <FormProvider {...formMethods}>
        <SustainabilityLibraryCollection
          title={formatMessage({ id: 'assessments.editAssessment' })}
          actionButtons={[
            {
              label: formatMessage({ id: 'general.delete' }),
              onClick: confirmDelete,
              variant: 'outlined',
              startIcon: <DeleteOutlined />,
              disabled: !!organizationAssessmentSettingsCount,
              tooltip: organizationAssessmentSettingsCount
                ? formatMessage({ id: 'assessmentTemplate.delete.toolTip' })
                : undefined,
            },
            {
              label: formatMessage({ id: 'general.cancel' }),
              to: generatePath(paths.sustainabilityLibrary, {
                configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
              }),
            },
            {
              label: formatMessage({ id: 'general.update' }),
              onClick: formMethods.handleSubmit(onSubmit),
              variant: 'contained',
            },
          ]}
        >
          {isLoading ? (
            <ListItemSkeletonWrapper />
          ) : (
            <AssessmentForm
              intervals={intervals}
              onIntervalsChange={handleIntervalsChange}
              questions={questions}
              isPublished={assessmentTemplate.isPublished}
              disablePublishButton={Boolean(organizationAssessmentSettingsCount)}
            />
          )}
        </SustainabilityLibraryCollection>
      </FormProvider>
    </>
  )
}

export default EditAssessment
