import Dialog from '@app/src/components/Dialog'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import VerificationForm from '@app/src/pages/Questionnaire/Accessor/Actions/VerificationForm'
import {
  BaseVerification,
  ExternalVerificationStatus,
  InternalVerificationStatus,
  ResponseItem,
  ResponseWithPreviousCorrectionNeededItem,
  Verification,
} from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewVerificationProps = {
  response: ResponseWithPreviousCorrectionNeededItem
  item?: ResponseItem
  onSubmit: (verification: Partial<Verification>) => void
} & Omit<DrawerViewProps, 'title'>

const DrawerViewVerification: React.FC<DrawerViewVerificationProps> = ({ response, item, onSubmit, ...props }) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const [isSubmitting, setSubmitting] = useState(false)
  const [isConfirmVerificationModalOpen, openConfirmVerificationModal, closeConfirmVerificationModal] =
    useDialogState(false)

  const formMethods = useForm<BaseVerification>()

  const { watch, reset, handleSubmit } = formMethods

  const internalVerificationStatus: InternalVerificationStatus = watch('internalVerificationStatus')
  const externalVerificationStatus: ExternalVerificationStatus = watch('externalVerificationStatus')

  const submit = (submittedVerification: BaseVerification) => {
    setSubmitting(true)
    onSubmit(submittedVerification)
    closeConfirmVerificationModal()
    closeDrawer()
  }

  useEffect(() => {
    if (response && item) {
      reset({
        id: item.id,
        externalVerificationComment: item?.verifications?.[0]?.externalVerificationComment ?? '',
        externalVerificationStatus:
          item?.verifications?.[0]?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet,
        internalVerificationComment: item?.verifications?.[0]?.internalVerificationComment ?? '',
        internalVerificationStatus:
          item?.verifications?.[0]?.internalVerificationStatus ?? InternalVerificationStatus.NotSet,
      })
    } else if (response && !item) {
      reset({
        id: response.id,
        externalVerificationComment: response.verifications?.[0]?.externalVerificationComment ?? '',
        externalVerificationStatus:
          response.verifications?.[0]?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet,
        internalVerificationComment: response.verifications?.[0]?.internalVerificationComment ?? '',
        internalVerificationStatus:
          response.verifications?.[0]?.internalVerificationStatus ?? InternalVerificationStatus.NotSet,
      })
    }
  }, [response, item])

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        title={formatMessage({ id: 'reporting.verification.review' })}
        buttons={[
          {
            label: formatMessage({ id: 'reporting.verification.save' }),
            variant: 'contained',
            color: 'primary',
            disabled:
              internalVerificationStatus === InternalVerificationStatus.NotSet &&
              externalVerificationStatus === ExternalVerificationStatus.NotSet,
            loading: isSubmitting,
            onClick: item ? handleSubmit(submit) : openConfirmVerificationModal,
          },
        ]}
        {...props}
      >
        <Box px={2}>
          <VerificationForm item={response} />
        </Box>

        <Dialog
          open={isConfirmVerificationModalOpen}
          onClose={closeConfirmVerificationModal}
          title={formatMessage({ id: 'general.areYouSure' })}
          content={
            <>
              {!item && (
                <Typography variant="body1">{formatMessage({ id: 'reporting.verification.confirmBody' })}</Typography>
              )}
              <Typography variant="body1">
                {formatMessage(
                  { id: 'reporting.verification.confirmBody2' },
                  { b: (chunks: React.ReactNode) => <b>{chunks}</b>, br },
                )}
              </Typography>
            </>
          }
          buttons={[
            {
              label: formatMessage({ id: 'general.cancel' }),
              variant: 'text',
              onClick: closeConfirmVerificationModal,
              disabled: isSubmitting,
            },
            {
              label: formatMessage({ id: 'reporting.verification.confirm' }),
              variant: 'contained',
              onClick: handleSubmit(submit),
              disabled: isSubmitting,
              loading: isSubmitting,
            },
          ]}
        />
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewVerification
