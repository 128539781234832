import { SvgIconComponent } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { generatePath, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

interface NavbarMenuItemProps {
  path: string
  title: string
  icon: SvgIconComponent
  iconSelected: SvgIconComponent
}

const NavbarMenuItem: React.FC<NavbarMenuItemProps> = ({ path, title, icon, iconSelected }) => {
  const generatedPath = generatePath(path)

  const { pathname } = useLocation()

  const isSelected = pathname.includes(generatedPath)
  return (
    <Tooltip arrow title={title}>
      <IconButton
        sx={({ palette }) => ({
          p: 2,
          borderRadius: 0,
          height: '100%',
          ...(isSelected && {
            borderBottom: `2px solid ${palette.primary.main}`,
          }),
        })}
        component={Link}
        to={generatedPath}
      >
        {React.createElement(isSelected ? iconSelected : icon, {
          color: isSelected ? 'primary' : 'inherit',
          fontSize: 'medium',
        })}
      </IconButton>
    </Tooltip>
  )
}

export default NavbarMenuItem
