import { Box, Stack, Typography } from '@mui/material'
import RatingDots from '@app/src/components/RatingDots'
import React from 'react'
import Assessment from '@app/src/types/assessment'
import {
  AssessmentRule,
  AssessmentSection as AssessmentSectionInterface,
  Response,
} from '@app/src/types/resourceExplorer'
import AssessmentSectionResponseItemRow from './AssessmentSectionResponseItemRow'

interface AssessmentSectionProps {
  section: AssessmentSectionInterface
  assessment: Assessment
  response: Response
}

const groupRulesByQuestionId = (sections: AssessmentSectionInterface[]) => {
  const questionRuleMap = new Map<number, AssessmentRule[]>()

  sections.forEach(section => {
    section.rules?.forEach(rule => {
      if (!questionRuleMap.has(rule.questionId)) {
        questionRuleMap.set(rule.questionId, [])
      }
      const rules = questionRuleMap.get(rule.questionId)
      if (rules !== undefined) {
        rules.push(rule)
      }
    })
  })

  return questionRuleMap
}

const getSectionLevel = (sectionNumber: number, assessment: Assessment): number | undefined => {
  if (sectionNumber === 1) return assessment.section1Level
  if (sectionNumber === 2) return assessment.section2Level
  if (sectionNumber === 3) return assessment.section3Level
  if (sectionNumber === 4) return assessment.section4Level
  if (sectionNumber === 5) return assessment.section5Level
  if (sectionNumber === 6) return assessment.section6Level
}

const AssessmentSection = ({ section, assessment, response }: AssessmentSectionProps) => {
  const responseItemMap = new Map(response.items.map(item => [item.requestItem.questionId, item]))
  const questionRuleMap = groupRulesByQuestionId(assessment.assessmentTemplate.sections)

  const uniqueQuestionIds = new Set<number>()

  section.rules?.forEach(rule => {
    uniqueQuestionIds.add(rule.questionId)
  })

  return (
    <Stack spacing={2} key={section.assessmentSectionNumber} p={2} my={2} bgcolor="grey.50" borderRadius={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{section.name}</Typography>
        <RatingDots level={getSectionLevel(section.assessmentSectionNumber, assessment)} />
      </Box>

      <Stack spacing={0.125}>
        {Array.from(uniqueQuestionIds.values()).map(questionId => (
          <AssessmentSectionResponseItemRow
            key={questionId}
            questionId={questionId}
            responseItemMap={responseItemMap}
            questionsRuleMap={questionRuleMap}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default AssessmentSection
