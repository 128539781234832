import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { LinkButtonProps } from '@app/src/components/LinkButton'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link as RouterLink, generatePath, matchPath } from 'react-router-dom'
import paths from '@app/src/wf-constants/paths'

interface ConfigurationBreadcrumbProps {
  path: string
  subPageTitle?: string
  configurationsPage?: string
  subPage?: string
  activeTabParam?: string
  activeTabSubId?: string
}

export const BreadcrumbLink = ({ to, ...props }: LinkButtonProps): JSX.Element => (
  <Link color="inherit" underline="none" component={RouterLink} to={to}>
    {props.children}
  </Link>
)

const ConfigurationBreadcrumbs: React.FC<ConfigurationBreadcrumbProps> = ({
  path,
  subPageTitle,
  configurationsPage,
  subPage,
  activeTabParam,
  activeTabSubId,
}) => {
  const hasSubPage = Boolean(subPageTitle)
  const { formatMessage } = useIntl()
  const isSustainabilityLibrary = matchPath(path, paths.sustainabilityLibrary)

  return (
    <Box mb={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <BreadcrumbLink to={generatePath(paths.dashboard)}>
          <Box mt={0.5}>
            <HomeOutlinedIcon fontSize="small" />
          </Box>
        </BreadcrumbLink>
        <BreadcrumbLink to={generatePath(path)}>
          {formatMessage({
            id: isSustainabilityLibrary ? 'configurations.sustainabilityLibrary' : 'configurations.configurations',
          })}
        </BreadcrumbLink>

        {hasSubPage ? (
          <Breadcrumbs>
            <BreadcrumbLink to={generatePath(path, { configurationsPage: configurationsPage })}>
              {formatMessage({ id: `configurations.${configurationsPage}` })}
            </BreadcrumbLink>

            {activeTabSubId && (
              <BreadcrumbLink
                to={generatePath(path, {
                  configurationsPage: configurationsPage,
                  activeTabParam: activeTabParam,
                  configurationsSubPage: subPage,
                })}
              >
                ...
              </BreadcrumbLink>
            )}

            <Typography color="textPrimary">{subPageTitle}</Typography>
          </Breadcrumbs>
        ) : (
          <Typography color="textPrimary">{formatMessage({ id: `configurations.${configurationsPage}` })}</Typography>
        )}
      </Breadcrumbs>
    </Box>
  )
}
export default ConfigurationBreadcrumbs
