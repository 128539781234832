import Select from '@app/src/components/Form/Select/ControlledSelect'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { FILE_UPLOAD_TYPE_OPTIONS, QuestionType } from '@app/src/types/reporting'
import { Question } from '@app/src/types/resourceExplorer'
import { PERCENTAGE_UNIT_ID, PERCENTAGE_UNIT_SYMBOL } from '@app/src/wf-constants'
import { GOAL_TRACKER_QUESTION_KEYS, GoalTrackerFormType } from './CreateGoalTrackerModal'

type SelectAnswerStepProps = {
  validRequestItems: Question[]
}

export function extractBooleanAndUnitId(items: Question[], watchRequestItemTemplateId: number) {
  const numberItem = items.find(
    u => u.id === watchRequestItemTemplateId && u.questionTypeId === QuestionType.Number,
  )?.unit

  const isNumber = Boolean(numberItem)
  const unitSymbol = isNumber ? numberItem?.symbol : PERCENTAGE_UNIT_SYMBOL
  const unitId = isNumber ? numberItem?.id : PERCENTAGE_UNIT_ID
  const isSimpleNumerical = isNumber && unitId !== PERCENTAGE_UNIT_ID && numberItem?.quantityType !== 'Fraction'

  return { isNumber, unitSymbol, unitId, isSimpleNumerical }
}

const SelectAnswerStep: React.FC<SelectAnswerStepProps> = ({ validRequestItems }) => {
  const { formatMessage } = useIntl()
  const { watch, setValue, control } = useFormContext()

  const watchRequestItemTemplateId: GoalTrackerFormType['questionId'] = watch(
    GOAL_TRACKER_QUESTION_KEYS.REQUEST_ITEM_TEMPLATE_ID,
  )

  const requestCollections = validRequestItems.find(
    u => u.id === watchRequestItemTemplateId && u.questionTypeId === QuestionType.Options,
  )?.requestCollection?.items

  const { isNumber } = extractBooleanAndUnitId(validRequestItems, watchRequestItemTemplateId)

  const options = (requestCollections && requestCollections.length ? requestCollections : FILE_UPLOAD_TYPE_OPTIONS).map(
    item => ({
      label: item.value,
      value: item.value,
    }),
  )

  if (isNumber) return null

  return (
    <Select
      name={GOAL_TRACKER_QUESTION_KEYS.TARGET_REFERENCE}
      fieldLabel={formatMessage({ id: 'schemas.goalTracker.answer.label' })}
      control={control}
      required
      enableAutoSelect
      onInputChange={(e, value) => {
        if (!value) setValue(GOAL_TRACKER_QUESTION_KEYS.TARGET_REFERENCE, undefined)
      }}
      forceFetch
      options={options}
    />
  )
}

export default SelectAnswerStep
