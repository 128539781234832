import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'
import Markdown from '@app/src/components/Markdown'
import { InfoOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'

export type InfoIconMarkdownCellProps = {
  value?: string
}

const InfoIconMarkdownCell: React.FC<InfoIconMarkdownCellProps> = ({ value }) => {
  const { formatMessage } = useIntl()
  return (
    <ParentCellOrFragment>
      {value ? (
        <Tooltip title={<Markdown>{value.toString()}</Markdown>}>
          <Box ml={1} display="flex" alignItems="center">
            <InfoOutlined />
          </Box>
        </Tooltip>
      ) : (
        <Typography variant="caption" color="textSecondary">
          {formatMessage({ id: 'general.notAvailableFull' })}
        </Typography>
      )}
    </ParentCellOrFragment>
  )
}

export default InfoIconMarkdownCell
