import { Delete } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import TextField from '@app/src/components/Ui/TextField'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { SuggestedRiskGuidanceLink } from '@app/src/types/flags'

type SuggestedRiskGuidanceLinkBuilderProps = {
  startOfName: string
}
const SuggestedRiskGuidanceLinkBuilder: React.FC<SuggestedRiskGuidanceLinkBuilderProps> = ({ startOfName }) => {
  const { control, register } = useFormContext()
  const { formatMessage } = useIntl()
  const { fields, append, remove } = useFieldArray<SuggestedRiskGuidanceLink>({
    control,
    name: `${startOfName}.suggestedRiskGuidance.suggestedRiskGuidanceLinks`,
  })

  return (
    <>
      {fields.map((f, index) => (
        <Stack key={f.id} spacing={2} mt={2} justifyContent="center">
          <Typography variant="body1">
            {formatMessage({ id: 'automations.suggestedRiskGuidanceLinks.linkSectionHeader' })} {index + 1}
            <IconButton size="small" onClick={() => remove(index)}>
              <Delete />
            </IconButton>
          </Typography>
          <TextField
            name={`${startOfName}.suggestedRiskGuidance.suggestedRiskGuidanceLinks[${index}].title`}
            required
            inputRef={register}
            defaultValue={f.title}
            label={formatMessage({ id: 'automations.suggestedRiskGuidanceLinks.title' })}
            size="small"
            inputProps={{
              maxLength: 100,
            }}
          />
          <TextField
            required
            name={`${startOfName}.suggestedRiskGuidance.suggestedRiskGuidanceLinks[${index}].url`}
            inputRef={register}
            defaultValue={f.url}
            label={formatMessage({ id: 'automations.suggestedRiskGuidanceLinks.linkUrl' })}
            inputProps={{
              maxLength: 1000,
            }}
            size="small"
          />
        </Stack>
      ))}

      <Box mt={2}>
        <Button disabled={fields.length > 2} variant="outlined" onClick={() => append({ title: '', url: '' })}>
          {formatMessage({ id: 'automations.suggestedRiskGuidanceLinks.addLinkButton' })}
        </Button>
      </Box>
    </>
  )
}

export default SuggestedRiskGuidanceLinkBuilder
