import { Permissions } from '@app/src/auth/permissions/index'
import { AccountContextProps, useAccount } from '@app/src/context/AccountContext'
import React from 'react'

type ComponentsType = Partial<Record<Permissions, JSX.Element | null | undefined>>
type ValuesType<T> = Partial<Record<Permissions, T | undefined>>

interface RenderIfPermittedOptions {
  multiple?: boolean
}

type RenderIfPermittedType = (
  componentsToRender: ComponentsType & { fallback?: JSX.Element | null },
  options?: RenderIfPermittedOptions,
) => JSX.Element

interface FallbackType<T> {
  fallback?: T | undefined
}

type RenderWithPermissionProps = {
  render: Omit<ComponentsType, 'fallback'>
  fallback?: JSX.Element | null
  options?: RenderIfPermittedOptions
}

export interface UsePermissionsType {
  renderWithPermission: RenderIfPermittedType
  getWithPermission: <T>(valueToReturn: ValuesType<T> & { fallback?: T | undefined }) => T | undefined
  hasPermission: AccountContextProps['hasPermission']
}

export const usePermissions = (): UsePermissionsType => {
  const { hasPermission } = useAccount()
  const renderWithPermission: RenderIfPermittedType = (components, options) => {
    const { fallback = null } = components
    const componentsToRender = []
    for (const [permission, component] of Object.entries(components)) {
      if (hasPermission(permission as Permissions)) {
        if (!options?.multiple) {
          // if we render only one component, we return the first one that matches
          return <>{component ?? null}</>
        }
        // if we render multiple component, we just add the matching components to the list
        componentsToRender.push(component)
      }
    }

    // if render is not empty, we return all the components to render, otherwise we render fallback
    return <>{componentsToRender.length ? componentsToRender : fallback}</>
  }

  //TODO Improve return type. It cannot be undefined if a fallback is provided
  function getWithPermission<T>(values: ValuesType<T> & FallbackType<T>): T | undefined {
    const { fallback } = values
    for (const [permission, value] of Object.entries(values)) {
      if (hasPermission(permission as Permissions)) {
        return value
      }
    }

    // if render is not empty, we return all the components to render, otherwise we render fallback
    return fallback
  }

  return {
    getWithPermission,
    renderWithPermission,
    hasPermission,
  }
}

export const RenderWithPermission: React.FC<RenderWithPermissionProps> = ({ render, fallback, options }) => {
  const { renderWithPermission } = usePermissions()

  const componentsToRender: ComponentsType & { fallback?: JSX.Element | null } = {
    ...render,
    fallback: fallback ?? null,
  }
  return renderWithPermission(componentsToRender, options)
}
