import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React, { ReactNode, useMemo, useState } from 'react'

import SendIcon from '@mui/icons-material/Send'
import LoadingButton from '@app/src/components/LoadingButton'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useMultipleStepsFormContext } from '@app/src/context/MultipleStepsFormContext'
import { FieldValues, FormProvider, UnpackNestedValue, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

export interface QuestionStepRenderPropParams {
  enableNext: React.Dispatch<React.SetStateAction<boolean>>
}

type QuestionStepProps = {
  step?: number
  title: string
  description?: ReactNode
  allowSkip?: boolean
  render: (params: QuestionStepRenderPropParams) => ReactNode
  onSubmit?: (values: FieldValues) => void
  disableInitialBack?: boolean
}

function QuestionStep<FormData extends FieldValues>({
  title,
  description,
  render,
  onSubmit: onSubmitProp,
  allowSkip = false,
  disableInitialBack = false,
}: QuestionStepProps): React.ReactElement | null {
  const { formatMessage } = useIntl()

  const { activeStep, totalSteps, setActiveStep } = useCreationModalProgress()
  const { values: combinedValues, setValues } = useMultipleStepsFormContext()
  const [isNextEnabled, setIsNextEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const canGoNext = useMemo(() => {
    const hasNextStep = activeStep < totalSteps + 1
    return hasNextStep && isNextEnabled
  }, [activeStep, totalSteps, isNextEnabled])
  const isLastStep = useMemo(() => activeStep === totalSteps, [activeStep, totalSteps])
  const formMethods = useForm<FormData>()

  const onSubmit = (values: UnpackNestedValue<FormData>) => {
    if (isLastStep) {
      if (!onSubmitProp) {
        throw new Error('The onSubmit prop is required for the final step')
      }
      onSubmitProp(combinedValues)
      setIsLoading(true)
      return
    }
    setValues(combinedValues => ({ ...combinedValues, ...values }))
    if (canGoNext) {
      setActiveStep(activeStep + 1)
    }
  }

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" justifyContent="center" width={isLastStep ? 900 : 780}>
        <>
          <Box mt={8}>
            <Typography variant="h1" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {description}
            </Typography>
          </Box>

          <Box pt={4} overflow="auto">
            <FormProvider {...formMethods}>{render({ enableNext: setIsNextEnabled })}</FormProvider>
          </Box>
        </>

        <Box display="flex" justifyContent="space-between" alignItems="center" mt={5}>
          {disableInitialBack && activeStep === 1 ? (
            <Box />
          ) : (
            <Button onClick={() => setActiveStep(activeStep - 1)} startIcon={<ArrowBack />}>
              {formatMessage({ id: 'general.back' })}
            </Button>
          )}

          {isLastStep ? (
            <Box display="flex">
              <LoadingButton
                loading={isLoading}
                variant="contained"
                startIcon={isLastStep ? <SendIcon /> : <ArrowForward />}
                type="submit"
              >
                {formatMessage({ id: 'general.send' })}
              </LoadingButton>
            </Box>
          ) : (
            <Box display="flex">
              {allowSkip && (
                <Box mr={1}>
                  <Button variant="outlined" type="submit" onClick={() => setActiveStep(activeStep + 1)}>
                    {formatMessage({ id: 'general.skip' })}
                  </Button>
                </Box>
              )}
              <Button variant="contained" endIcon={!isLastStep && <ArrowForward />} disabled={!canGoNext} type="submit">
                {formatMessage({
                  id: activeStep === totalSteps - 1 ? 'general.reviewAndSend' : 'general.next',
                })}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  )
}

export default QuestionStep
