import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { CheckboxProps, TableRow } from '@mui/material'

import { ProductMappingRequest } from '@app/src/types/product'
import * as React from 'react'
import { useIntl } from 'react-intl'

interface SentMappingRequestHeaderProps extends SortableHeaderProps {
  selectedRequestsIds?: Array<number>
  setSelectedRequestsIds?: React.Dispatch<React.SetStateAction<Array<number>>>
  requestsInPage?: ProductMappingRequest[]
}

function SentMappingRequestHeader({
  toggleSorting,
  activeSorting,
  requestsInPage,
  selectedRequestsIds,
  setSelectedRequestsIds,
}: SentMappingRequestHeaderProps) {
  const { formatMessage } = useIntl()

  const isChecked = requestsInPage?.every(request => selectedRequestsIds?.includes(request.id))

  const shouldShowCheckboxRow = setSelectedRequestsIds && requestsInPage // Inquiries table is shown on the insights modal, so we need to support not having this

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (!shouldShowCheckboxRow) return
    if (checked) {
      setSelectedRequestsIds(prev => Array.from(new Set([...prev, ...requestsInPage.map(request => request.id)])))
    } else {
      setSelectedRequestsIds([])
    }
  }

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isChecked, onChange: handleCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.status' })}
        sorting={{ name: 'status', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.productName' })}
        sorting={{ name: 'product.name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.sentTo' })}
        sorting={{ name: 'responderOrganization.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.deadline' })}
        sorting={{ name: 'deadline', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.period' })}
        sorting={{ name: 'periodName', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.SMALL}
      />
    </TableRow>
  )
}

export default SentMappingRequestHeader
