import { Box, Button, ButtonProps, CircularProgress } from '@mui/material'
import React from 'react'

export type LoadingButtonProps = {
  loading?: boolean
} & ButtonProps

const LoadingButton = ({ loading = false, ...props }: LoadingButtonProps): JSX.Element => {
  return (
    <Button {...props} disabled={props.disabled || loading} data-testid="loading-button">
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
        >
          <CircularProgress size={14} color="primary" />
        </Box>
      )}

      <Box visibility={loading ? 'hidden' : 'visible'}>{props.children}</Box>
    </Button>
  )
}

export default LoadingButton
