import Permissions from '@app/src/auth/permissions'
import { ResourceCreator } from '@app/src/auth/User'
import { createContext, useContext } from 'react'
import { Country, SolutionType } from '@app/src/types/resourceExplorer'

interface Item {
  id: string
  route: string
  href?: string
  icon?: string
  items?: Item[]
  legacy?: boolean
  target?: string
}

interface Image {
  url: string
}

interface Evaluations {
  status: string
  tier: number
  solutionType: string
}

interface NavbarOrganization {
  name: string
  country: Country
  vatNumber: string
  id: number
  image: Image
  evaluations?: Evaluations[]
  websiteAddress: string
  assignedSolutions?: AssignedSolution[]
}

export interface AssignedSolution {
  organizationId: number
  solutionType: SolutionType
}

export interface NavbarUser {
  name?: string
  familyName: string
  givenName: string
  organization?: NavbarOrganization
  image?: Image
  email: string
  items?: Item[][]
  culture: string
  id: number
  auth0UserId: string
  position: string
  phoneNumber: string
  createdAt: string
  hasAcceptedTAndC: boolean
}

export interface Account {
  user: NavbarUser
  organization?: NavbarOrganization
  items: Item[]
  referralCode?: string
  permissions: string[]
}

export type AccountContextProps = {
  account?: Account
  isLoading: boolean
  fetchAccount: () => void
  hasPermission: (permissionToVerify: Permissions | Permissions[], resource?: ResourceCreator) => boolean
}

const AccountContext = createContext<AccountContextProps>({
  account: undefined,
  isLoading: false,
  fetchAccount: () => undefined,
  hasPermission: () => false,
})

export const useAccount = (): AccountContextProps => {
  return useContext(AccountContext)
}

export default AccountContext
