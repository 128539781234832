import { Link, LinkProps } from '@mui/material'
import React from 'react'
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom'

export type RouterLinkProps = LinkProps & ReactRouterLinkProps

const RouterLink = ({ to, ...props }: RouterLinkProps): JSX.Element => (
  <Link component={ReactRouterLink} to={to} underline="hover" {...props}>
    {props.children}
  </Link>
)

export default RouterLink
