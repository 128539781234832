import { Box, Step, StepLabel, Stepper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Dialog from '@app/src/components/Dialog'
import React from 'react'

const useStyles = makeStyles({
  stepper: {
    padding: 0,
  },
})

interface Props {
  children: JSX.Element
  onClose: () => void
  isOpen: boolean
  steps: Array<string>
  activeStep: number
  fullScreen?: boolean
}

const Wizard = ({ children, onClose, isOpen, steps, activeStep, fullScreen }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullScreen={fullScreen}
      maxWidth="md"
      title={
        <Stepper classes={{ root: classes.stepper }} activeStep={activeStep} alternativeLabel>
          {steps?.map(step => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      }
      content={
        <Box padding={2} marginTop={4} width={900}>
          {children}
        </Box>
      }
    />
  )
}

export default Wizard
