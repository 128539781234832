import { Box, Skeleton } from '@mui/material'
import Markdown from '@app/src/components/Markdown'
import React from 'react'
import { useReport } from './ReportContext'

const ReportingDescription: React.FC = () => {
  const { request, isRequestLoading } = useReport()

  if (isRequestLoading) {
    return (
      <Box display="flex" flexDirection="column">
        <Skeleton variant="text" width="100%" component="h2" />
        <Skeleton variant="text" width="100%" />

        <Box width="100%" display="flex" flexDirection="column" mt={2}>
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="50%" />
        </Box>
      </Box>
    )
  }

  if (!request?.description) {
    return null
  }

  return <Markdown>{request?.description}</Markdown>
}

export default ReportingDescription
