import endpoints from '@app/src/api/endpoints'
import ActionButtons from '@app/src/components/ActionButtons'
import ControlledLocationField, { SearchResult } from '@app/src/components/Form/ControlledLocationField'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import ImageUpload from '@app/src/components/Image/ImageUpload'
import TextField from '@app/src/components/Ui/TextField'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { CompanyRanges, NaceCode, Organization } from '@app/src/types/organizations'
import { validURL } from '@app/src/utils/helpers'
import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface OrganizationInfoFormProps {
  organization: Organization
  cancelEdit: () => void
  isUpdatingOrganization: boolean
  onSubmit: (values: Partial<Organization>) => void
}

function OrganizationInfoForm({
  organization,
  cancelEdit,
  isUpdatingOrganization,
  onSubmit,
}: OrganizationInfoFormProps) {
  const { errors, register, control, setValue, watch, handleSubmit } = useFormContext()
  const { formatMessage } = useIntl()

  const selectedIndustryId = watch('industryId')
  const imageUrlWatch = watch('imageUrl')

  useEffect(() => {
    if (selectedIndustryId !== organization?.industryId) {
      setValue('subIndustryId', undefined)
    }
  }, [selectedIndustryId])

  const handleLocationValue = (searchResult: SearchResult) =>
    setValue('locations[0]', {
      address: searchResult.candidates[0].attributes.LongLabel,
      countryCode: searchResult.candidates[0].attributes.Country,
      region: searchResult.candidates[0].attributes.Region,
      subRegion: searchResult.candidates[0].attributes.Subregion,
      city: searchResult.candidates[0].attributes.City,
      district: searchResult.candidates[0].attributes.District,
      latitude: searchResult.candidates[0].location.y,
      longitude: searchResult.candidates[0].location.x,
    })

  return (
    <form>
      <Stack spacing={3}>
        <ImageUpload currentImageSrc={imageUrlWatch} onSuccess={fileUrl => setValue('imageUrl', fileUrl)} />
        <input ref={register} type="hidden" name="imageUrl" />
        <TextField
          fullWidth
          hoveringLabel
          name="name"
          error={Boolean(errors?.name)}
          label={`${formatMessage({ id: 'general.Organization' })} ${formatMessage({
            id: 'schemas.organization.name',
          }).toLowerCase()}`}
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          helperText={errors?.name?.message}
        />

        <ControlledLocationField
          label={formatMessage({ id: 'schemas.organization.address' })}
          locationName="locations[0]"
          addressName="locations[0].address"
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          valueSetter={handleLocationValue}
          fieldErrors={errors?.locations?.[0]?.address}
        />
        <input ref={register} type="hidden" name="locations[0].countryCode" />
        <input ref={register} type="hidden" name="locations[0].region" />
        <input ref={register} type="hidden" name="locations[0].subRegion" />
        <input ref={register} type="hidden" name="locations[0].city" />
        <input ref={register} type="hidden" name="locations[0].district" />
        <input ref={register} type="hidden" name="locations[0].latitude" />
        <input ref={register} type="hidden" name="locations[0].longitude" />
        <TextField
          fullWidth
          hoveringLabel
          label={formatMessage({ id: 'schemas.organization.websiteAddress' })}
          type="text"
          name="websiteAddress"
          inputRef={register({
            required: formatMessage({ id: 'form.validation.required' }),
            validate: (value: string): string | true =>
              validURL(value) || formatMessage({ id: 'notifications.errorInvalidWebsite' }),
          })}
          required
          error={Boolean(errors?.websiteAddress)}
          helperText={errors?.websiteAddress?.message}
        />
        <Select<number, NaceCode>
          name="industryId"
          control={control}
          hoveringLabel
          required
          forceFetch
          error={errors?.industryId?.message}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          objectToOption={object => ({
            label: object.description,
            value: object.id,
          })}
          findSelectedValue={(value, option) => value === option.value}
          navigation={{
            url: endpoints.naceCodes,
            type: 'post',
            postObject: {
              filter: [
                {
                  name: 'parentId',
                  filters: [{ operator: Operators.IsNull }],
                },
              ],
              include: [],
              sort: { target: 'description', order: SortOrder.ASCENDING },
            },
          }}
          fieldLabel={formatMessage({ id: 'schemas.organization.industry' })}
        />
        <Select<number, NaceCode>
          name="subIndustryId"
          control={control}
          hoveringLabel
          required
          forceFetch={Boolean(selectedIndustryId)}
          error={errors?.subIndustryId?.message}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          disabled={!selectedIndustryId}
          objectToOption={object => ({
            label: object.description,
            value: object.id,
          })}
          findSelectedValue={(value, option) => value === option.value}
          navigation={{
            url: endpoints.naceCodes,
            type: 'post',
            postObject: {
              filter: [
                {
                  name: 'parentId',
                  filters: [{ operator: Operators.EqualTo, value: selectedIndustryId }],
                },
              ],
              include: [],
              sort: { target: 'description', order: SortOrder.ASCENDING },
            },
          }}
          fieldLabel={formatMessage({ id: 'schemas.organization.subIndustry' })}
        />
        <Select
          fieldLabel={formatMessage({ id: 'schemas.organization.employees' })}
          name="companySizeRange"
          hoveringLabel
          control={control}
          required
          error={errors?.companySizeRange?.message}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          options={Object.values(CompanyRanges).map(range => ({
            label: formatMessage({ id: `claim.companySize.${range}` }),
            value: range,
          }))}
        />
        <Select
          fieldLabel={formatMessage({ id: 'schemas.organization.revenue' })}
          hoveringLabel
          name="revenueRange"
          control={control}
          error={errors?.revenueRange?.message}
          options={Object.values(CompanyRanges).map(range => ({
            label: formatMessage({ id: `claim.companyRevenue.${range}` }),
            value: range,
          }))}
        />
        <TextField
          fullWidth
          hoveringLabel
          name="vatNumber"
          label={formatMessage({ id: 'schemas.organization.vatNumber' })}
          inputRef={register}
        />
        <TextField
          fullWidth
          hoveringLabel
          name="registrationNumber"
          label={formatMessage({ id: 'schemas.organization.registrationNumber' })}
          inputRef={register}
        />
        <Stack flexDirection="row" justifyContent="flex-end">
          <ActionButtons
            buttons={[
              {
                label: formatMessage({ id: 'general.cancel' }),
                variant: 'text',
                size: 'large',
                onClick: cancelEdit,
                loading: isUpdatingOrganization,
                sx: { mr: 2 },
              },
              {
                label: formatMessage({ id: 'general.save' }),
                variant: 'contained',
                size: 'large',
                onClick: handleSubmit(onSubmit),
                loading: isUpdatingOrganization,
              },
            ]}
          />
        </Stack>
      </Stack>
    </form>
  )
}

export default OrganizationInfoForm
