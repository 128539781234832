import { MutableRefObject, useEffect, useRef, useState } from 'react'

const isMutableRefObjectElement = <T extends Element>(ref: MutableRefObject<T | null>): ref is MutableRefObject<T> => {
  return Boolean(ref.current)
}

export default function useOnScreen<T extends Element, U extends Element>(
  ref: MutableRefObject<T | null>,
  root?: MutableRefObject<U | null>,
  threshold?: number,
): boolean {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [isIntersecting, setIntersecting] = useState(false)

  const options: { root?: U; threshold?: number } = { threshold }

  if (root && isMutableRefObjectElement<U>(root)) {
    options.root = root.current
  }

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), options)
  }, [])

  useEffect(() => {
    if (!isMutableRefObjectElement<T>(ref)) return

    observerRef.current?.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observerRef.current?.disconnect()
    }
  }, [ref, root])

  return isIntersecting
}
