import endpoints from '@app/src/api/endpoints'
import { useUpdateResourceWithoutBody } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { CompanyRanges, OrganizationDuplicateMatch } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { ArrowBack, Check } from '@mui/icons-material'
import { Button, FormControl, FormHelperText, FormLabel, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ClaimFormFields, ExistingOrganizationsData } from './ClaimDialog'

interface OrgDetailsStepProps {
  onExistingOrganizations: (data: ExistingOrganizationsData) => void
  onPreviousStep: () => void
}

const OrgDetailsStep: React.FC<OrgDetailsStepProps> = ({ onExistingOrganizations, onPreviousStep }) => {
  const { formatMessage } = useIntl()
  const { register, setValue, errors, handleSubmit, control, getValues } = useFormContext()
  const { trackEvent } = useAmplitude()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { mutateAsync: checkExistingOrganization, isLoading: isLoadingExistingOrgs } = useUpdateResourceWithoutBody<
    OrganizationDuplicateMatch[]
  >({
    method: 'get',
  })

  const onNextClicked = async () => {
    const values = getValues()
    const existingOrganizationData = await checkExistingOrganization({
      url: endpoints.potentialDuplicates(
        values.name,
        values.websiteAddress,
        values.vatNumber,
        values.location?.address,
      ),
    })

    const exactMatch = existingOrganizationData.find(d => d.matchType === 'ExactMatch')
    const approximateMatches = exactMatch ? [] : existingOrganizationData.map(o => o.organization)
    onExistingOrganizations({
      existingOrganizationData: exactMatch?.organization,
      similarOrganizationsData: approximateMatches,
    })
  }

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Onboarding.AddOrganization.OpenedOrgDetails,
      eventProps: {
        is_first_org: !accessibleOrganizations.length,
      },
    })
  }, [])

  return (
    <OnboardingForm
      preHeader={formatMessage({ id: 'claim.createClaim.orgDetailsStep.preHeader' })}
      header={formatMessage({ id: 'claim.createClaim.orgDetailsStep.header' })}
    >
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.vatNumber' })}
        label={formatMessage({ id: 'claim.createClaim.orgDetailsStep.vatNumber' })}
        name={ClaimFormFields.VatNumber}
        inputRef={register()}
        error={Boolean(errors?.vatNumber)}
        helperText={errors?.vatNumber?.message}
        onClear={(): void => setValue(ClaimFormFields.VatNumber, '')}
      />
      <FormControl required>
        <FormLabel sx={{ mb: 2 }}>{formatMessage({ id: 'claim.companySize.label' })}</FormLabel>
        <Controller
          name={ClaimFormFields.CompanySizeRange}
          control={control}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          render={({ value, onChange }): JSX.Element => (
            <Stack direction="row" flexWrap="wrap">
              {Object.values(CompanyRanges).map(range => (
                <Button
                  key={range}
                  startIcon={value === range && <Check />}
                  sx={{ mr: 2, mb: 2 }}
                  variant={value === range ? 'contained' : 'outlined'}
                  onClick={() => onChange(range)}
                >
                  {formatMessage({ id: `claim.companySize.${range}` })}
                </Button>
              ))}
            </Stack>
          )}
        />
        <FormHelperText error={Boolean(errors?.companySizeRange)}>{errors?.companySizeRange?.message}</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel sx={{ mb: 2 }}>{formatMessage({ id: 'claim.companyRevenue.label' })}</FormLabel>
        <Controller
          name={ClaimFormFields.RevenueRange}
          control={control}
          render={({ value, onChange }): JSX.Element => (
            <Stack direction="row" flexWrap="wrap">
              {Object.values(CompanyRanges).map(range => (
                <Button
                  key={range}
                  startIcon={value === range && <Check />}
                  sx={{ mr: 2, mb: 2 }}
                  variant={value === range ? 'contained' : 'outlined'}
                  onClick={() => onChange(range)}
                >
                  {formatMessage({ id: `claim.companyRevenue.${range}` })}
                </Button>
              ))}
            </Stack>
          )}
        />
      </FormControl>
      <Stack pt={2} direction="row" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onPreviousStep}>
          {formatMessage({ id: 'general.back' })}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoadingExistingOrgs}
          onClick={handleSubmit(() => onNextClicked())}
        >
          {formatMessage({ id: 'general.continue' })}
        </LoadingButton>
      </Stack>
    </OnboardingForm>
  )
}

export default OrgDetailsStep
