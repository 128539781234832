import { getOrganizationDisplayName } from '@app/src/components/Table/Cells/CompanyCell'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Provider } from '@app/src/types/organizations'
import { Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import ProviderContactCard from './ProviderContactCard'
import CompanyOverview from './ResourceDetails/CompanyOverview'
import { AvatarSize } from './Ui/Avatar'

interface ProviderDrawerContentProps {
  provider: Provider
}

const ProviderDrawerContent: React.FC<ProviderDrawerContentProps> = ({ provider }) => {
  const providerName = getOrganizationDisplayName(provider)

  return (
    <Stack pt={2} flexGrow={1}>
      <Stack alignItems="center">
        <CompanyAvatar
          organizationName={providerName}
          imageUrl={provider?.organization?.image?.url}
          size={AvatarSize.XXL}
        />
        <Typography variant="h5" mt={2} color="textPrimary">
          {providerName}
        </Typography>
      </Stack>

      <Stack flexGrow={1} mt={2}>
        <CompanyOverview provider={provider} />
      </Stack>

      <Stack>
        <Divider sx={{ m: 2 }} />
        <ProviderContactCard provider={provider} />
      </Stack>
    </Stack>
  )
}

export default ProviderDrawerContent
