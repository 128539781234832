import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import { Option } from '@app/src/components/Form/Select/SimpleSelect'
import { ListboxComponent } from '@app/src/components/Form/SelectVirtualList'
import TextField from '@app/src/components/Ui/TextField'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import type { Provider } from '@app/src/types/organizations'
import AddIcon from '@mui/icons-material/Add'
import { Autocomplete, Button } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

export type ProviderFieldValue = Pick<Provider, 'id' | 'organizationId' | 'name'> | null
interface Props {
  onChange: (value: ProviderFieldValue) => void
  value: ProviderFieldValue
  useOrganizationId?: boolean
  disabled?: boolean
  onAddProviderClick?: () => void
  showNotSelected?: boolean
}

const ProviderField: React.FC<Props> = ({ onChange, value, disabled, onAddProviderClick, showNotSelected }) => {
  const { formatMessage } = useIntl()
  const { errors } = useFormContext()
  const error = errors.provider

  const { count, isLoading: isCountLoading } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'id', type: 'count' }],
  })

  const { items: providers, isLoading } = useFetchCollectionWithPost<Provider>({
    endpoint: endpoints.providersCollection,
    key: FetchKey.ProviderCollection,
    payload: {
      include: ['organization'],
      filter: [
        {
          name: 'linked',
          filters: [
            {
              value: 'true',
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      sort: {
        target: 'name',
        order: SortOrder.ASCENDING,
      },
      pagination: {
        itemsPerPage: count,
        pageNumber: 1,
      },
    },
    options: {
      enabled: !isCountLoading,
    },
  })

  const options = useMemo(() => {
    const providerOptions: Option<number | null>[] = providers.map(provider => {
      return {
        label: provider.organization.name,
        value: provider.id,
      }
    })

    if (showNotSelected) {
      providerOptions.unshift({
        label: formatMessage({ id: 'general.notSelected' }),
        value: null,
      })
    }

    return providerOptions
  }, [providers, showNotSelected, formatMessage])

  return (
    <>
      <Autocomplete
        options={options}
        disableListWrap
        getOptionLabel={option => option.label}
        ListboxComponent={ListboxComponent}
        onChange={(_, option) => onChange(providers.find(provider => provider.id === option?.value) ?? null)}
        value={options.find(option => option.value === value?.id) ?? null}
        loading={isCountLoading || isLoading}
        renderInput={(params): JSX.Element => (
          <TextField
            variant="outlined"
            label={formatMessage({ id: 'schemas.product.responsibleSupplier' })}
            {...params}
            translate="no"
            error={Boolean(error)}
            helperText={typeof error === 'string' ? error : error?.message}
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <OptionIcon selected={selected} multiple={false} />
              {option?.label || ''}
              <OptionAdornment option={option} />
            </li>
          )
        }}
        disabled={disabled}
      />
      {onAddProviderClick && (
        <Button sx={{ mt: 1 }} startIcon={<AddIcon />} onClick={onAddProviderClick}>
          {formatMessage({ id: 'schemas.mappingNode.addNewSupplier' })}
        </Button>
      )}
    </>
  )
}

export default ProviderField
