import { GoalDirectionType } from '@app/src/types/resourceExplorer'
import { Button, ButtonGroup } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { GOAL_TRACKER_QUESTION_KEYS } from './CreateGoalTrackerModal'

const useStyles = makeStyles(({ palette }) => ({
  buttonGroup: {
    '& .MuiButton-outlined': {
      border: `1px solid ${palette.grey[500]}`,
    },
  },
  activeButton: {
    backgroundColor: palette.grey[300],
  },
}))

const GoalDirectionField = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { watch, setValue } = useFormContext()

  const watchGoalDirection: string = watch(GOAL_TRACKER_QUESTION_KEYS.GOAL_DIRECTION)

  useEffect(() => {
    if (!watchGoalDirection) {
      setValue(GOAL_TRACKER_QUESTION_KEYS.GOAL_DIRECTION, GoalDirectionType.Exact)
    }
  }, [])

  return (
    <ButtonGroup className={classes.buttonGroup} variant="outlined">
      <Button
        className={classNames({ [classes.activeButton]: watchGoalDirection === GoalDirectionType.Below })}
        onClick={() => {
          setValue(GOAL_TRACKER_QUESTION_KEYS.GOAL_DIRECTION, GoalDirectionType.Below)
        }}
      >
        {formatMessage({ id: 'schemas.goalTracker.goalDirection.below' })}
      </Button>
      <Button
        className={classNames({ [classes.activeButton]: watchGoalDirection === GoalDirectionType.Exact })}
        onClick={() => {
          setValue(GOAL_TRACKER_QUESTION_KEYS.GOAL_DIRECTION, GoalDirectionType.Exact)
        }}
      >
        {formatMessage({ id: 'schemas.goalTracker.goalDirection.exact' })}
      </Button>
      <Button
        className={classNames({ [classes.activeButton]: watchGoalDirection === GoalDirectionType.Above })}
        onClick={() => {
          setValue(GOAL_TRACKER_QUESTION_KEYS.GOAL_DIRECTION, GoalDirectionType.Above)
        }}
      >
        {formatMessage({ id: 'schemas.goalTracker.goalDirection.above' })}
      </Button>
    </ButtonGroup>
  )
}

export default GoalDirectionField
