import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const RiskSettingsHeader: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell
        minWidth={TableCellWidth.EXTRA_SMALL}
        label={formatMessage({ id: 'schemas.riskSetting.active' })}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.riskSetting.indexName' })} />
      <TableHeaderCell minWidth={TableCellWidth.SMALL} label={formatMessage({ id: 'schemas.riskSetting.esgArea' })} />
      <TableHeaderCell minWidth={TableCellWidth.SMALL} label={formatMessage({ id: 'schemas.riskSetting.indexType' })} />
      <TableHeaderCell minWidth={TableCellWidth.SMALL} label="" />
    </TableRow>
  )
}

export default RiskSettingsHeader
