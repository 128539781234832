import React from 'react'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { LinkOutlined, SyncOutlined } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { Organization } from '@app/src/types/organizations'
import { Product } from '@app/src/types/product'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'

interface Props {
  autoAcceptValueChainEnabled: boolean
  product?: Product
  responder?: Organization
  showLastUpdate: boolean
  showConnectedProduct: boolean
  connectedResponsesCount?: number
  responseSubmittedAt?: string
}

const Icon: React.FC<Props> = ({
  showLastUpdate,
  product,
  showConnectedProduct,
  autoAcceptValueChainEnabled,
  connectedResponsesCount,
}) => {
  if ((showLastUpdate && product?.updatedByUser?.id === 1) || (showLastUpdate && autoAcceptValueChainEnabled)) {
    return (
      <Avatar size={AvatarSize.Small} sx={{ backgroundColor: 'info.dark', color: 'grey.900', mr: 1 }}>
        <SyncOutlined />
      </Avatar>
    )
  }
  if ((showConnectedProduct && product) || connectedResponsesCount) {
    return (
      <Avatar
        size={AvatarSize.Small}
        sx={{ backgroundColor: connectedResponsesCount ? 'warning.dark' : 'info.dark', color: 'grey.900', mr: 1 }}
      >
        <LinkOutlined />
      </Avatar>
    )
  }
  return null
}

const Message: React.FC<Props> = ({
  showLastUpdate,
  product,
  responder,
  showConnectedProduct,
  autoAcceptValueChainEnabled,
  responseSubmittedAt,
}) => {
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()
  if (showLastUpdate && product?.updatedByUser?.id === 1)
    return (
      <Typography variant="body2" color="text.secondary">
        {product?.updatedAt &&
          formatMessage(
            { id: 'schemas.valueChain.automaticallyUpdatedAt' },
            {
              responder: responder?.name,
              date: product?.updatedAt && formatValueToDate({ value: product.updatedAt }),
            },
          )}
      </Typography>
    )
  if (showLastUpdate && autoAcceptValueChainEnabled) {
    return (
      <Typography variant="body2" color="text.secondary">
        {formatMessage({ id: 'schemas.valueChain.automaticallyUpdatedFallback' })}
      </Typography>
    )
  }
  if (responseSubmittedAt) {
    return (
      <Typography variant="body2" color="text.secondary">
        {formatMessage(
          { id: 'schemas.valueChain.submittedOn' },
          {
            date: formatValueToDate({ value: responseSubmittedAt, withTime: true }),
          },
        )}
      </Typography>
    )
  }
  return (
    <Typography variant="body2" color="text.secondary">
      {formatMessage(
        { id: 'schemas.valueChain.productInfo' },
        {
          showBoth: showConnectedProduct && showLastUpdate,
          showConnectedProduct,
          product: product?.name,
          date: product?.updatedAt && formatValueToDate({ value: product.updatedAt, withTime: true }),
          showLastUpdate,
          by:
            product?.updatedByUser &&
            formatMessage(
              { id: 'schemas.valueChain.updatedBy' },
              {
                name: product.updatedByUser.name,
              },
            ),
          b: (chunks: React.ReactNode) => <b>{chunks}</b>,
        },
      )}
    </Typography>
  )
}
const ValueChainViewHeader: React.FC<Props> = props => {
  return (
    <Stack mb={2} direction="row">
      <Icon {...props} />
      <Message {...props} />
    </Stack>
  )
}

export default ValueChainViewHeader
