import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { TabsProps } from '@app/src/components/Tabs'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import usePrevious from '@app/src/hooks/previous'
import { useQueryState } from '@app/src/hooks/queryState'
import AssessmentInsightsScene from '@app/src/pages/Assessments/AssessmentInsight/AssessmentInsightsScene'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { generatePath, Route, Switch, useParams } from 'react-router-dom'
import { DEFAULT_FILTER_NAME, FilterInterface } from '../../ResourceCollection/Filters/Filters'
import AssessmentOverviewScene from './AssessmentInsightsScene'
import AssessmentMethodologyScene from './AssessmentMethodologyScene'

export enum AssessmentExploreViews {
  Overview = 'overview',
  Methodology = 'methodology',
  Insights = 'insights',
}

const AssessmentsExploreScene: React.FC = () => {
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const { view, assessmentTemplateId, activePeriod } = useParams<{
    view: AssessmentExploreViews
    assessmentTemplateId: string
    activePeriod: string
  }>()

  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)
  const [filters, setFilters] = useQueryState<Array<FilterInterface>>(DEFAULT_FILTER_NAME, [])
  const previousFilters = usePrevious(filters)

  useEffect(() => {
    if (!previousFilters?.length) return
    setFilters(previousFilters)
  }, [view])

  const actionTabs: TabsProps['tabs'] = [
    {
      type: AssessmentExploreViews.Overview,
      url: generatePath(paths.exploreAssessment, {
        view: AssessmentExploreViews.Overview,
        assessmentTemplateId,
        activePeriod,
      }),
      label: formatMessage({ id: 'assessment.tabs.overview' }),
    },
    {
      type: AssessmentExploreViews.Insights,
      url: generatePath(paths.exploreAssessment, {
        view: AssessmentExploreViews.Insights,
        assessmentTemplateId,
        activePeriod,
      }),
      label: formatMessage({ id: 'assessment.tabs.insights' }),
    },
    {
      type: AssessmentExploreViews.Methodology,
      url: generatePath(paths.exploreAssessment, {
        view: AssessmentExploreViews.Methodology,
        assessmentTemplateId,
      }),
      label: formatMessage({ id: 'assessment.tabs.methodology' }),
    },
  ]

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.Assessment.ViewedPage,
      eventProps: {
        page: view,
      },
    })
  }, [view])

  if (!canAccessAssessmentTemplate) return null

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.assessments, {
          view: AssessmentExploreViews.Overview,
        })}
      />
    )
  }

  return (
    <>
      <Switch>
        <Route
          path={generatePath(paths.exploreAssessment, {
            view: AssessmentExploreViews.Overview,
            assessmentTemplateId,
            activePeriod,
          })}
          exact
        >
          <AssessmentOverviewScene
            tabs={{ actionTabs, activeTabParam: view }}
            assessmentTemplateId={assessmentTemplateId}
            activePeriod={activePeriod}
          />
        </Route>
        <Route
          path={generatePath(paths.exploreAssessment, {
            view: AssessmentExploreViews.Insights,
            assessmentTemplateId,
            activePeriod,
          })}
          exact
        >
          <AssessmentInsightsScene
            tabs={{ actionTabs, activeTabParam: view }}
            assessmentTemplateId={assessmentTemplateId}
            activePeriod={activePeriod}
          />
        </Route>
        <Route
          path={generatePath(paths.exploreAssessment, {
            view: AssessmentExploreViews.Methodology,
            assessmentTemplateId,
          })}
          exact
        >
          <AssessmentMethodologyScene
            tabs={{ actionTabs, activeTabParam: view }}
            assessmentTemplateId={assessmentTemplateId}
          />
        </Route>
      </Switch>
    </>
  )
}

export default AssessmentsExploreScene
