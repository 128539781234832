import React from 'react'

import Permissions, { usePermissions } from '@app/src/auth/permissions'
import AccessorReport from './Accessor/AcccessorReport'
import ProviderReport from './Provider/ProviderReport'
import ReportContextProvider from './ReportContextProvider'

const Questionnaire: React.FC = () => {
  const { renderWithPermission } = usePermissions()

  return (
    <ReportContextProvider>
      {renderWithPermission({
        [Permissions.TRANSPARENCY_USER]: <ProviderReport />,
        fallback: <AccessorReport />,
      })}
    </ReportContextProvider>
  )
}

export default Questionnaire
