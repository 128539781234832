import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'

const UserHeader = (): JSX.Element => {
  return (
    <TableRow>
      <TableHeaderCell label="" minWidth={TableCellWidth.EMPTY} />
      <TableHeaderCell label="Full Name" minWidth={TableCellWidth.LARGE} />
      <TableHeaderCell label="Email" />
      <TableHeaderCell label="Worldfavor ID" />
      <TableHeaderCell label="Auth0 ID" />
    </TableRow>
  )
}

export default UserHeader
