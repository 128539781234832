import { DeleteOutlined } from '@mui/icons-material'
import { IconButton, TableRow } from '@mui/material'
import * as Sentry from '@sentry/react'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFileDownload } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import IconCell from '@app/src/components/Table/Cells/IconCell'
import UserChipCell from '@app/src/components/Table/Cells/UserChipCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { File } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'

interface ProviderDocumentRowProps {
  row: File
}

export const ProviderDocumentRow: React.FC<ProviderDocumentRowProps> = ({ row: file }) => {
  const { formatMessage } = useIntl()
  const { account, trackEvent } = useAmplitude()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const { downloadFile } = useFileDownload()
  const { mutate: mutateDeleteFile, isLoading } = useDeleteResource()
  const queryClient = useQueryClient()
  const confirm = useConfirm()

  const confirmDeleteFile = () => {
    confirm({
      content: formatMessage({ id: 'resourceExplorer.activity.deleteFileContent' }),
      confirmationText: formatMessage({ id: 'general.delete' }),
      cancellationButtonProps: { variant: 'text' },
      confirmationButtonProps: { variant: 'text' },
      buttonOrder: ['confirm', 'cancel'],
    })
      .then(() => {
        mutateDeleteFile(
          {
            url: endpoints.deleteFile(file.id),
          },
          {
            onSuccess: () => {
              showSnackbar({
                message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
                severity: 'success',
              })
              trackEvent({
                name: AmplitudeTrackingEvents.Accessor.ProviderOrganization.AdditionalInformation,
                eventProps: {
                  information_type: 'file',
                  added_or_deleted: 'deleted',
                },
              })
              queryClient.invalidateQueries(FetchKey.FileCollection)
            },
            onError: error => {
              Sentry.captureException(error, {
                tags: { event: 'Error deleting a provider file' },
                user: { id: String(account?.user?.id) },
                extra: { errorDetails: error.message, providerId: String(file.sourceObjectId) },
              })
              showErrorNotification({ requestError: error })
            },
          },
        )
      })
      .catch(() => '')
  }

  const handleFileDownload = async () => {
    await downloadFile(endpoints.fileDownload(file.id))
  }

  return (
    <TableRow>
      <ValueCell
        value={file.originalFileName}
        sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
        onClick={handleFileDownload}
      />
      <DateCell value={file.accesses?.[0]?.createdAt} />
      <UserChipCell user={file.accesses?.[0]?.creatorUser} drawer />
      <IconCell
        icon={
          <IconButton size="small" onClick={confirmDeleteFile} disabled={isLoading}>
            <DeleteOutlined color="action" />
          </IconButton>
        }
      />
    </TableRow>
  )
}

export default ProviderDocumentRow
