import { Alert, Box, Button, Divider, Stack, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import Dialog from '@app/src/components/Dialog'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import React from 'react'
import { useIntl } from 'react-intl'
import { ReferralCode } from '@app/src/types/organizations'
import OnboardingForm from '../OnboardingForm'
import CompanyCard from '../OnboardingForm/CompanyCard'
import NoReferralEmptyState from './NoReferralEmptyState'

interface ReferralInvitationProps {
  referralCode: string
  onAccept: () => void
  onClose: () => void
  onReferralExpired: () => void
}

const ReferralInvitation: React.FC<ReferralInvitationProps> = ({
  referralCode,
  onClose,
  onAccept,
  onReferralExpired,
}) => {
  const [isConfirmDeclineOpen, openConfirmDecline, closeConfirmDecline] = useDialogState()
  const { formatMessage } = useIntl()
  const { data, error, isLoading } = useFetchResource<ReferralCode>({
    endpoint: endpoints.referralCode(referralCode),
    key: [FetchKey.ReferralCode, referralCode],
    options: { retry: false, staleTime: Infinity, enabled: Boolean(referralCode) },
    showSnackbarOn404: false,
  })

  if (error?.statusCode === 404) return <NoReferralEmptyState onReferralExpired={onReferralExpired} />

  return (
    <OnboardingForm
      header={formatMessage({ id: 'referral.referralInvitation.header' })}
      subheader={formatMessage({ id: 'referral.referralInvitation.subHeader' })}
    >
      <Stack>
        <Typography variant="subtitle1" mb={1}>
          {formatMessage({ id: 'statistics.reportOverview.requests' })}
        </Typography>
        <CompanyCard
          avatarAlt={data?.creatorOrganizationName[0]}
          mainText={data?.creatorOrganizationName}
          secondaryText={data?.country?.name}
          isLoading={isLoading}
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button onClick={openConfirmDecline}>{formatMessage({ id: 'general.decline' })}</Button>
        <Button variant="contained" color="primary" onClick={onAccept}>
          {formatMessage({ id: 'general.accept' })}
        </Button>
      </Stack>
      <Stack spacing={2} flexGrow={1} justifyContent="flex-end">
        <Box width="100%">
          <Divider />
        </Box>
        <Alert severity="error" sx={{ backgroundColor: 'common.white' }}>
          {formatMessage({ id: 'referral.referralWarning' })}
        </Alert>
      </Stack>
      <Dialog
        open={isConfirmDeclineOpen}
        onClose={closeConfirmDecline}
        title={formatMessage({ id: 'general.areYouSure' })}
        content={formatMessage({ id: 'referral.declineInfo' })}
        buttons={[
          {
            label: formatMessage({ id: 'general.confirm' }),
            variant: 'text',
            onClick: onClose,
          },
          {
            label: formatMessage({ id: 'general.cancel' }),
            variant: 'text',
            onClick: closeConfirmDecline,
          },
        ]}
      />
    </OnboardingForm>
  )
}

export default ReferralInvitation
