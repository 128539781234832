import { FetchKey } from '@app/src/api/fetchHooks'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { Invitation } from '@app/src/types/resourceExplorer'
import { Roles, Solutions } from '@app/src/wf-constants'
import { BlockOutlined, CircleOutlined, MailOutline } from '@mui/icons-material'
import { List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerViewDeleteResource from './DrawerViewDeleteResource'
import DrawerViewInvitationForm from './DrawerViewInvitationForm'

export type DrawerViewInvitationProps = {
  invitation: Invitation
} & Omit<DrawerViewProps, 'title'>

const DrawerViewInvitation: React.FC<DrawerViewInvitationProps> = ({ invitation, ...props }) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()

  return (
    <DrawerView
      title=""
      buttons={[
        {
          label: formatMessage({ id: 'general.delete' }),
          variant: 'text',
          onClick: () =>
            openDrawer(
              <DrawerViewDeleteResource
                fetchKey={FetchKey.Invitation}
                resourceId={invitation.id}
                solution={Solutions.Resources}
                resourceType={invitation.type.toLowerCase()}
                subTitle={invitation.name}
              />,
            ),
        },
        {
          label: formatMessage({ id: 'general.edit' }),
          variant: 'contained',
          onClick: () =>
            openDrawer(
              <DrawerViewInvitationForm
                title={formatMessage({ id: 'general.edit' })}
                subTitle={invitation.name}
                invitation={invitation}
              />,
            ),
        },
      ]}
      {...props}
    >
      <Stack pt={2} flexGrow={1} gap={2}>
        <Stack alignItems="center">
          <PersonAvatar name={invitation.name} size={AvatarSize.XXL} disabled />
          <Typography variant="h6" align="center" mt={2} px={2}>
            {invitation.name}
          </Typography>
        </Stack>

        <List disablePadding>
          <ListItem>
            <ListItemAvatar>
              <MailOutline color="action" />
            </ListItemAvatar>
            <ListItemText primary={formatMessage({ id: 'schemas.user.email' })} secondary={invitation.email} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              {invitation.role.toLowerCase() === Roles.Contributor ? (
                <BlockOutlined color="action" />
              ) : (
                <CircleOutlined color="action" />
              )}
            </ListItemAvatar>
            <ListItemText primary={formatMessage({ id: 'schemas.user.role' })} secondary={invitation.role} />
          </ListItem>
        </List>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewInvitation
