import { useSignalR } from '@app/src/context/SignalRContext'
import { useEffect } from 'react'

const useNotificationListener = (method: () => void): void => {
  const { on } = useSignalR()
  useEffect(() => {
    on('Notification', method)
  }, [on])
}

export default useNotificationListener
