import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewReviewProductValueChainToConnectToNewResponse from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewProductValueChainToConnectToNewResponse'
import SelectProductForm from '@app/src/pages/Product/SelectProductForm'
import { Product, ProductIndustry } from '@app/src/types/product'
import { Alert, Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type DrawerViewSelectProductToConnectProps = {
  mappingRequestId: number
  connectProduct?: boolean
  productIndustry: ProductIndustry
} & Omit<DrawerViewProps, 'title'>

const DrawerViewSelectProductToConnect: React.FC<DrawerViewSelectProductToConnectProps> = ({
  mappingRequestId,
  productIndustry,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(null)

  const { openDrawer, goBack, closeDrawer } = useDrawer()
  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.mappingNode.connectToExistingProduct' })}
      subTitle={formatMessage({ id: 'schemas.mappingNode.connectToExistingProductDescription' })}
      {...props}
      buttons={[
        {
          label: formatMessage({ id: 'general.cancel' }),
          onClick: goBack,
        },
        {
          label: formatMessage({ id: 'general.continue' }),
          variant: 'contained',
          disabled: !selectedProduct,
          onClick: () => {
            if (!selectedProduct) return
            openDrawer(
              <DrawerViewReviewProductValueChainToConnectToNewResponse
                mappingRequestId={mappingRequestId}
                productToConnect={selectedProduct}
                onSuccess={closeDrawer}
              />,
            )
          },
        },
      ]}
    >
      <Box p={2}>
        <SelectProductForm
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          productIndustry={productIndustry}
        />
        <Alert severity="warning" sx={{ mt: 2 }}>
          <Typography gutterBottom>
            {formatMessage({ id: 'schemas.mappingNode.connectToExistingProductAlert1' })}
          </Typography>
          <Typography>{formatMessage({ id: 'schemas.mappingNode.connectToExistingProductAlert2' })}</Typography>
        </Alert>
      </Box>
    </DrawerView>
  )
}
export default DrawerViewSelectProductToConnect
