import { GenericOrganization, NaceCode } from '@app/src/types/organizations'
import { RequestItem } from './resourceExplorer'

export interface ReportFlagRule {
  id: number
  conditionType: ConditionType
  checkValue?: ReportFlagCheckValue[]
  question: RequestItem
  questionId: number
  content: string
  contentTitle: string
  updatedAt: string
  activationType: ActivationType
  suggestedRiskGuidance?: SuggestedRiskGuidance
  creatorOrganizationId: number
  creatorOrganization: GenericOrganization
}

export interface ReportFlagRuleBulkModel {
  activationType: ActivationType
  questionId: number
  reportFlagRules: ReportFlagRule[]
}

export enum SuggestedGuidanceTypeOfRisk {
  Environmental = 'Environmental',
  Social = 'Social',
  Governance = 'Governance',
}

export interface SuggestedRiskGuidance {
  id: number
  introductionTitle: string
  introductionDescription: string
  introductionAffectedIndustries: string
  potentialNegativeRiskDescription: string
  potentialNegativeRiskRelatedInformation: string
  typeOfRiskDescription: SuggestedGuidanceTypeOfRisk
  riskDescription: string
  regulatoryDescription: string
  reputationalDescription: string
  suggestedRiskGuidanceLinks?: SuggestedRiskGuidanceLink[]
  suggestedRiskNaceCodes?: SuggestedRiskNaceCode[]
}

export interface SuggestedRiskGuidanceLink {
  id: number
  title: string
  url: string
}

export interface SuggestedRiskNaceCode {
  naceCodeId: number
  naceCode: NaceCode
}

export enum ConditionType {
  CannotAnswer = 'CannotAnswer',
  Options = 'Options',
  File = 'File',
}

export enum ActivationType {
  Inactive = 'Inactive',
  Active = 'Active',
  Deactivated = 'Deactivated',
}

export interface ReportFlagCheckValue {
  key: string
  value: string
  flag: ReportFlagType
}

export enum ReportFlagType {
  Red = 'Red',
  Green = 'Green',
  NotSet = 'NotSet',
}

export enum OptionConditionType {
  Yes = 'Yes',
  No = 'No',
}
