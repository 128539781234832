import React, { useState } from 'react'

import endpoints from '@app/src/api/endpoints'

import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import { useQueryState } from '@app/src/hooks/queryState'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import UserHeader from '@app/src/pages/Configurations/ConfigurationsPages/Users/UserHeader'
import UserRow from '@app/src/pages/Configurations/ConfigurationsPages/Users/UserRow'
import { Box, TextField as MuiTextField, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

export interface Auth0Account {
  organizationId: number
  solution: string
  role: string
}

export interface Auth0User {
  auth0Id: string
  email: string
  userMetadata: {
    familyName: string
    givenName: string
  }
  appMetadata: {
    id: number //WF ID
    accounts: Auth0Account[]
  }
}

const UsersCollection: React.FC = () => {
  const { formatMessage } = useIntl()
  const [pageSize, setPageSize] = useQueryState<number>('pageSize', 25)
  const [page, setPage] = useQueryState<number>('page', 1)
  const [emailSearch, setEmailSearch] = useState('')

  const emailWildcardSearch = emailSearch.endsWith('*') || emailSearch === '' ? emailSearch : emailSearch + '*'

  const { data, isLoading, isError } = useFetchResource<Auth0User[]>({
    key: [FetchKey.Users, page, pageSize, emailWildcardSearch],
    endpoint:
      `${endpoints.userManagement}?page=${page}&pagesize=${pageSize}` +
      (emailWildcardSearch !== '' ? `&email=${emailWildcardSearch}` : ''),
  })

  return (
    <ConfigurationCollection
      secondaryTitle={<Typography variant="body1">{formatMessage({ id: 'userConfig.subtitle' })}</Typography>}
    >
      <Box flexGrow={1} display="flex" flexDirection="column" height={100} mb={2}>
        <MuiTextField
          fullWidth
          variant="outlined"
          size="small"
          label={formatMessage({ id: 'userConfig.searchLabel' })}
          value={emailSearch}
          onChange={e => setEmailSearch(e.target.value)}
          sx={{ mb: 4, pr: 4 }}
        />
        <Table<Auth0User>
          RowComponent={UserRow}
          HeaderComponent={UserHeader}
          data={data ?? []}
          isLoading={isLoading}
          count={1000} //need to figure out how to get the info from Auth0
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </Box>
    </ConfigurationCollection>
  )
}

export default UsersCollection
