import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import Table from '@app/src/components/Table'
import { useAccount } from '@app/src/context/AccountContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { GenericOrganization } from '@app/src/types/organizations'
import { ProductMappingRequest } from '@app/src/types/product'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { NotificationsActiveOutlined } from '@mui/icons-material'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import SentMappingRequestFilters from '../Filters/SentMappingRequestFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import RequestsReminderModal, { REQUEST_TYPES } from './ManageRequests/RequestsReminder/RequestsReminderModal'
import SentMappingRequestHeader from './MappingRequest/SentMappingRequestHeader'
import SentMappingRequestRow from './MappingRequest/SentMappingRequestRow'

export interface GroupedRequest {
  responder: GenericOrganization
  requests: ProductMappingRequest[]
}

function groupRequestsByResponder(requests: ProductMappingRequest[]): GroupedRequest[] {
  const grouped = Object.groupBy(requests, request => request.responderOrganizationId)

  return Object.entries(grouped).map(([organizationId, groupedRequests]) => ({
    responder: groupedRequests?.[0].responderOrganization as GenericOrganization,
    requests: groupedRequests || [],
  }))
}

const allowedFilters: string[] = ['product.id', 'product.name', 'status', 'periodName']

const SentMappingRequestCollection: React.FC = () => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const [isRequestsReminderDialogOpen, openRequestsReminderDialog, closeRequestsReminderDialog] = useDialogState(false)
  const [selectedRequestsIds, setSelectedRequestsIds] = useState<Array<number>>([])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, request: ProductMappingRequest) => {
    if (e.target.checked) {
      setSelectedRequestsIds(prev => [...prev, request.id])
    } else {
      setSelectedRequestsIds(prev => prev.filter(requestId => requestId !== request.id))
    }
  }

  const filters = useGetApiQueryFilters(allowedFilters)
  const implicitFilters: FilterGroup[] = [
    {
      name: 'creatorOrganizationId',
      filters: [{ value: account?.organization?.id, operator: Operators.EqualTo }],
    },
  ]

  const {
    items: mappingRequests,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<ProductMappingRequest>({
    key: FetchKey.MappingRequests,
    endpoint: endpoints.mappingRequestCollection,
    payload: {
      filter: [...filters, ...implicitFilters],
      sort: sorting,
      include: ['product', 'responderOrganization'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  const {
    items: allRequests,
    isLoading: isLoadingAllRequests,
    refetch: fetchAllRequests,
  } = useFetchCollectionWithPost<ProductMappingRequest>({
    key: FetchKey.AllMappingRequests,
    endpoint: endpoints.mappingRequestCollection,
    payload: {
      filter: [...filters, ...implicitFilters],
      sort: sorting,
      include: ['product', 'responderOrganization'],
      pagination: {
        itemsPerPage: count,
        pageNumber: 1,
      },
    },
    options: {
      enabled: false,
    },
  })

  const unhandledRequests = useMemo(
    () =>
      mappingRequests
        .filter(request => selectedRequestsIds.includes(request.id))
        .filter(request => request.status !== InquiryStatus.Submitted && request.status !== InquiryStatus.Approved)
        .filter(request => !request.responderOrganization.deletedAt),
    [selectedRequestsIds, mappingRequests],
  )

  const unhandledRequestsByResponder = groupRequestsByResponder(unhandledRequests)

  const isAllSelected = () => {
    return allRequests.length ? allRequests.every(request => selectedRequestsIds.includes(request.id)) : false
  }

  const handleSelectAll = async () => {
    if (allRequests.length) {
      setSelectedRequestsIds(allRequests.map(request => request.id) ?? [])
      return
    }

    const { data } = await fetchAllRequests()
    setSelectedRequestsIds(data?.items.map(request => request.id) ?? [])
  }

  const handleCloseRequestReminder = () => {
    setSelectedRequestsIds([])
    closeRequestsReminderDialog()
  }

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.sentMappingRequests' })}
        filter={<SentMappingRequestFilters allowedFilters={allowedFilters} implicitFilters={implicitFilters} />}
        buttonRow={
          selectedRequestsIds.length
            ? [
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.sendRequestReminder' },
                    { count: unhandledRequests.length },
                  ),
                  startIcon: <NotificationsActiveOutlined />,
                  onClick: openRequestsReminderDialog,
                  disabled: !unhandledRequests.length || isLoading || isFetching || isLoadingAllRequests,
                },
                ...insertIf<ActionButton>(count > pageSize, {
                  label: formatMessage({ id: 'resourceCollections.general.selectAllRequests' }, { count }),
                  variant: 'text',
                  onClick: handleSelectAll,
                  disabled: isLoading || isFetching || isAllSelected(),
                  loading: isLoadingAllRequests,
                }),
              ]
            : undefined
        }
      >
        <Table<ProductMappingRequest>
          RowComponent={({ row }) => (
            <SentMappingRequestRow
              row={row}
              onCheckboxChange={handleCheckboxChange}
              selectedRequestsIds={selectedRequestsIds}
            />
          )}
          HeaderComponent={() => (
            <SentMappingRequestHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              selectedRequestsIds={selectedRequestsIds}
              setSelectedRequestsIds={setSelectedRequestsIds}
              requestsInPage={mappingRequests}
            />
          )}
          data={mappingRequests}
          count={count}
          page={page}
          isError={isError}
          isLoading={isLoading || isFetching}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ResourceCollectionScene>
      <CreationModalProgressContextProvider>
        <RequestsReminderModal
          onClose={handleCloseRequestReminder}
          open={isRequestsReminderDialogOpen}
          unhandledRequests={unhandledRequests}
          unhandledRequestsByResponder={unhandledRequestsByResponder}
          requestType={REQUEST_TYPES.MappingRequest}
        />
      </CreationModalProgressContextProvider>
    </>
  )
}

export default SentMappingRequestCollection
