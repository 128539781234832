import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import React, { useMemo } from 'react'
import { useDataInsightsModal } from './DataInsightsModalContext'
import { EchartClickItemData } from './OptionMultiPeriodChart'
import { useSummaryChartConfigs } from './useSummaryChartConfigs'

export interface StatisticsSummary {
  id: number
  title: string
  resultForPeriods: StatisticsSummaryPerPeriod[]
}

export interface StatisticsSummaryPerPeriod {
  period: string
  companiesAnswered: number
  companiesAnsweredDontHaveThis: number
  companiesNotAnswered: number
}

interface OptionBarProps {
  statistics: StatisticsSummary
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  selectedChartType: ChartType
}

const DataHubSummaryChart: React.FC<OptionBarProps> = ({ statistics, echartsRef, selectedChartType }) => {
  const { openDataInsightsModal } = useDataInsightsModal()

  const height = '384px'
  const resultsForPeriod: StatisticsSummaryPerPeriod[] = statistics.resultForPeriods
  const options = useSummaryChartConfigs({ resultsForPeriod, selectedChartType })

  echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, LegendComponent])

  const events = useMemo(
    () => ({
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          period: data.period,
          answer: [data.name],
          summaryType: data.summaryType ? [data.summaryType] : [],
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }),
    [openDataInsightsModal, statistics],
  )

  return (
    <ReactEChartsCore
      onEvents={events}
      option={options}
      echarts={echarts}
      style={{ minWidth: '100%', height }}
      ref={echartsRef}
    />
  )
}

export default DataHubSummaryChart
