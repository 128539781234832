import { ReportContextProps } from '@app/src/pages/Questionnaire/ReportContext'
import {
  Question,
  QuestionnaireTemplate,
  QuestionnaireTemplateSection,
  Request,
  RequestItem,
  RequestSection,
  ResponseDraftStatus,
} from '@app/src/types/resourceExplorer'

//TODO Avoid casting
export const mapToRequestItem = (question: Question, isRequired: boolean): RequestItem => {
  const { questionText, ...requestItem } = {
    ...question,
    description: question.questionText,
    template: question,
    questionId: question.id,
    isRequired: isRequired,
  }
  return requestItem as unknown as RequestItem
}

const mapToRequestSection = (section: QuestionnaireTemplateSection): RequestSection => {
  const { questions, ...requestSection } = {
    ...section,
    items: section.questions?.map(q => mapToRequestItem(q, q.isRequired)),
  }
  return requestSection
}

const mapTemplateToRequest = (template: QuestionnaireTemplate): Request => {
  return {
    ...template,
    sections: template?.sections?.map(mapToRequestSection),
    periodName: new Date().getFullYear().toString(),
    template: template,
  } as unknown as Request
}

export const mapToReportContextPropsForPreview = (template: QuestionnaireTemplate): ReportContextProps => {
  return {
    isRequestLoading: false,
    isResponseLoading: false,
    isCurrentAccessLoading: false,
    isRequestHistoryLoading: false,
    isRequestsPerPeriodLoading: false,
    isPreview: true,
    request: mapTemplateToRequest(template),
    reportStatus: ResponseDraftStatus.Draft,
  } as ReportContextProps
}
