import { useAuthentication } from '@app/src/context/AuthenticationContext'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Solutions } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'

const DashboardsScene: React.FC = () => {
  const { solution } = useAuthentication().scope

  const renderContent = () => {
    switch (solution?.toLowerCase()) {
      case Solutions.Transparency:
        return <Redirect to={paths.transparencyDashboard} />
      case Solutions.Investment:
        return <Redirect to={paths.investmentDashboard} />

      case Solutions.Sourcing:
        return <Redirect to={paths.sourcingDashboard} />

      case Solutions.Finance:
        return <Redirect to={paths.financeDashboard} />

      default:
        return <></>
    }
  }

  return renderContent()
}

export default DashboardsScene
