import React, { useState } from 'react'

import Table from '@app/src/components/Table'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { OrganizationRiskSetting } from '@app/src/types/resourceExplorer'
import { DEFAULT_TABLE_PAGE_SIZE } from '@app/src/wf-constants'
import { InfoOutlined, LibraryBooksOutlined } from '@mui/icons-material'
import { Alert } from '@mui/material'
import { useIntl } from 'react-intl'
import RiskScreeningInfoDialog from './RiskScreeningInfoDialog'
import RiskSettingsHeader from './RiskSettingsHeader'
import RiskSettingsRow from './RiskSettingsRow'

const RiskScreeningConfig: React.FC = () => {
  const { formatMessage } = useIntl()
  const { riskSettings, isLoadingRiskSettings, isErrorRiskSettings } = useRiskSettings()
  const [open, openDialog, closeDialog] = useDialogState()

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_TABLE_PAGE_SIZE)

  return (
    <>
      <ConfigurationCollection
        secondaryTitle={
          <Alert variant="filled" severity="info" icon={<InfoOutlined color="primary" />}>
            {formatMessage({ id: 'riskScreeningConfig.info' })}
          </Alert>
        }
        actionButtons={[
          {
            label: formatMessage({ id: 'schemas.riskSetting.infoDialog.infoButton' }),
            variant: 'text',
            startIcon: <LibraryBooksOutlined />,
            onClick: openDialog,
          },
        ]}
        enablePadding={{ top: false, right: true }}
      >
        <Table<OrganizationRiskSetting>
          HeaderComponent={RiskSettingsHeader}
          RowComponent={RiskSettingsRow}
          count={riskSettings?.length ?? 0}
          data={riskSettings}
          isLoading={isLoadingRiskSettings}
          isError={isErrorRiskSettings}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ConfigurationCollection>
      <RiskScreeningInfoDialog open={open} closeDialog={closeDialog} />
    </>
  )
}

export default RiskScreeningConfig
