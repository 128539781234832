import { Box, Button, CircularProgress, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import paths from '@app/src/wf-constants/paths'
import StatisticsCard from '../StatisticsCard'

const SubscriptionsCard = () => {
  const history = useHistory()
  const { formatMessage } = useIntl()

  const {
    facets: [subscribers],
    isLoading: isSubscribersLoading,
  } = useFetchFacets({
    endpoint: endpoints.subscriptionsFacets,
    filter: [],
    facetsParam: [{ name: 'creatorOrganizationId' }],
    key: FetchKey.SubscriptionFacets,
  })

  if (isSubscribersLoading) {
    return (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <StatisticsCard
      title={formatMessage({ id: 'transparencyOverview.subscribers' })}
      justifyContent="left"
      withBorder
      action={
        <Button size="small" onClick={() => history.push(paths.subscriptions)}>
          {formatMessage({ id: 'dashboard.investment.seeAll' })}
        </Button>
      }
    >
      <Box display="flex" alignItems="center" py={2}>
        <Typography variant="kpi">{subscribers?.length ?? 0}</Typography>
      </Box>
    </StatisticsCard>
  )
}

export default SubscriptionsCard
