import { Co2Outlined, PublicOutlined } from '@mui/icons-material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Avatar, Box, Button, Grid, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface RecommendationCardProps {
  description: string
  linkUrl: string
  icon?: JSX.Element
}

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  description,
  linkUrl,
  icon = <DescriptionOutlinedIcon />,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: 'grey.200',
        border: ({ palette }) => `1px solid ${palette.grey[300]}`,
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'grey.300',
        },
      }}
      onClick={() => window.open(linkUrl, '_blank', 'noopener,noreferrer')}
      p={3}
      borderRadius="4px"
      height={160}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Avatar
            variant="circular"
            sx={{
              height: 48,
              width: 48,
              color: 'common.white',
              backgroundColor: 'secondary.main',
            }}
          >
            {icon}
          </Avatar>
          <OpenInNewIcon color="primary" />
        </Stack>

        <Typography variant="subtitle1">{description}</Typography>
      </Stack>
    </Box>
  )
}

const RecommendationsSection = () => {
  const { formatMessage } = useIntl()

  return (
    <Stack px={4} py={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1">
          {formatMessage({ id: 'transparencyOverview.recommendations.title' })}
        </Typography>
        <Button
          component={Link}
          size="small"
          target="_blank"
          href="https://helpdesk.worldfavor.com/worldfavor-app/en/collections/3714205-sustainability-library"
          rel="noopener noreferrer"
          endIcon={<OpenInNewIcon />}
        >
          {formatMessage({ id: 'transparencyOverview.recommendations.exploreMore' })}
        </Button>
      </Box>
      <Box py={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <RecommendationCard
              icon={<PublicOutlined />}
              description={formatMessage({ id: 'transparencyOverview.recommendations.ghgBody' })}
              linkUrl="https://helpdesk.worldfavor.com/en/articles/6961962-what-are-greenhouse-gases"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <RecommendationCard
              icon={<Co2Outlined />}
              description={formatMessage({ id: 'transparencyOverview.recommendations.emissionsBody' })}
              linkUrl="https://helpdesk.worldfavor.com/en/articles/6962187-user-guide-wf-s-co2e-calculator"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <RecommendationCard
              description={formatMessage({ id: 'transparencyOverview.recommendations.envPolicy' })}
              linkUrl="https://helpdesk.worldfavor.com/en/articles/6737231-guide-environmental-policy"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <RecommendationCard
              description={formatMessage({ id: 'transparencyOverview.recommendations.hrPolicy' })}
              linkUrl="https://helpdesk.worldfavor.com/en/articles/6737238-guide-human-rights-policy"
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default RecommendationsSection
