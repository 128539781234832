import { ExpandMoreOutlined } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Stack, Typography } from '@mui/material'
import { CollectionResponseType } from '@app/src/api/fetchHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import ProviderRiskCell from '@app/src/components/Table/Cells/ProviderRiskCell'
import RequestError from '@app/src/errors/RequestError'
import React from 'react'
import { useIntl } from 'react-intl'
import { UseInfiniteQueryResult } from 'react-query'
import { ProviderRiskStatus, ProviderRiskStatusType, ResponseItem } from '@app/src/types/resourceExplorer'
import FlagRuleItem from './FlagRuleItem'

export type RiskAccordionProps = {
  label: string
  riskStatus: ProviderRiskStatusType
  count: number
  items: ResponseItem[]
  loadMore: () => void
} & Pick<
  UseInfiniteQueryResult<CollectionResponseType<ResponseItem>, RequestError>,
  'isLoading' | 'hasNextPage' | 'isFetchingNextPage'
>

const removeDividerLineSx = { '&:before': { position: 'unset' } }

const RiskAccordion: React.FC<RiskAccordionProps> = ({
  label,
  riskStatus,
  count,
  items,
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  loadMore,
}) => {
  const { formatMessage } = useIntl()

  if (isLoading) {
    return (
      <Box mt={1} display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <Skeleton variant="rounded" width={150} height={20} />
        <Skeleton variant="rounded" width={48} height={24} />
      </Box>
    )
  }

  return (
    <Accordion elevation={0} disableGutters sx={{ ...removeDividerLineSx, '&.Mui-expanded': { bgcolor: 'grey.100' } }}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined fontSize="medium" sx={{ mx: 1 }} />}
        sx={{
          flexDirection: 'row-reverse',
        }}
        disabled={!count || riskStatus === ProviderRiskStatus.Pending}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="subtitle1">{label}</Typography>
          <ProviderRiskCell count={count} disableCell riskStatus={riskStatus} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          paddingLeft: 3,
          paddingRight: 2,
        }}
      >
        {items.map(item => (
          <FlagRuleItem
            key={item.id}
            title={item.flagRule?.contentTitle || item.requestItem?.description}
            reportFlagRules={[item.flagRule ?? []].flat()}
          />
        ))}

        {hasNextPage && (
          <LoadingButton fullWidth loading={isFetchingNextPage} onClick={loadMore}>
            {formatMessage({ id: 'general.loadMore' })}
          </LoadingButton>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default RiskAccordion
