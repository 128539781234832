import { FetchBaseParameters } from '@app/src/api/fetchHooks'
import RequestError from '@app/src/errors/RequestError'
import useApi from '@app/src/hooks/api'
import { UseBaseMutationResult, useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { MutationFunction } from 'react-query/types/core/types'
import { HTTPMethod, RequestParametersWithBody } from '@app/src/types/api'

export type UpdateResourceParameters<ResponseBody, RequestBody = unknown, RequestError = unknown> = Omit<
  FetchBaseParameters<ResponseBody>,
  'key' | 'endpoint' | 'options'
> & {
  options: Omit<
    UseMutationOptions<ResponseBody, RequestError, MutateFunctionParams<RequestBody>, unknown>,
    'mutationKey' | 'mutationFn'
  >
}
export type MutateFunctionParams<T> = Omit<RequestParametersWithBody<T>, 'config'>
export type UpdateResourceNoBody<TError, TResponse> = UpdateResourceParameters<TResponse, never, TError> & {
  method?: HTTPMethod
}

export const useUpdateResource = <ResponseBody, RequestBody = ResponseBody>(
  params?: UpdateResourceParameters<ResponseBody, RequestBody, RequestError>,
): UseMutationResult<ResponseBody, RequestError, MutateFunctionParams<RequestBody>> => {
  const { put } = useApi()

  const mutationFunction: MutationFunction<ResponseBody, MutateFunctionParams<RequestBody>> = async ({ body, url }) =>
    await put<ResponseBody, RequestBody>({
      url: url,
      body: body,
      config: params?.config,
    })
  return useMutation<ResponseBody, RequestError, MutateFunctionParams<RequestBody>>(mutationFunction, params?.options)
}

export const usePatchUpdateResource = <ResponseBody, RequestBody = ResponseBody>(
  params?: UpdateResourceParameters<ResponseBody, RequestBody, RequestError>,
): UseMutationResult<ResponseBody, RequestError, MutateFunctionParams<RequestBody>> => {
  const { patch } = useApi()

  const mutationFunction: MutationFunction<ResponseBody, MutateFunctionParams<RequestBody>> = async ({ body, url }) =>
    await patch<ResponseBody, RequestBody>({
      url: url,
      body: body,
      config: params?.config,
    })
  return useMutation<ResponseBody, RequestError, MutateFunctionParams<RequestBody>>(mutationFunction, params?.options)
}

export const useCreateResource = <ResponseBody, RequestBody = ResponseBody>(
  params?: UpdateResourceParameters<ResponseBody, RequestBody, RequestError>,
): UseMutationResult<ResponseBody, RequestError, MutateFunctionParams<RequestBody>> => {
  const { post } = useApi()

  const mutationFunction: MutationFunction<ResponseBody, MutateFunctionParams<RequestBody>> = async ({ body, url }) =>
    await post<ResponseBody, RequestBody>({
      url: url,
      body: body,
      config: params?.config,
    })
  return useMutation<ResponseBody, RequestError, MutateFunctionParams<RequestBody>>(mutationFunction, params?.options)
}

export const useCreateResourceWithoutBody = <ResponseBody>(
  params?: UpdateResourceParameters<ResponseBody>,
): UseBaseMutationResult<ResponseBody, RequestError, MutateFunctionParams<{}>> => {
  const { post } = useApi()

  const mutationFunction = async ({ url }: MutateFunctionParams<{}>) =>
    await post<ResponseBody>({
      url: url,
      config: params?.config,
    })
  return useMutation<ResponseBody, RequestError, MutateFunctionParams<{}>>(mutationFunction, params?.options)
}

export const useDeleteResource = <ResponseBody, RequestBody = ResponseBody>(
  params?: UpdateResourceParameters<ResponseBody, RequestBody, RequestError>,
): UseMutationResult<ResponseBody, RequestError, MutateFunctionParams<RequestBody>> => {
  const { delete: deleteMethod } = useApi()

  const mutationFunction = async ({ body, url }: MutateFunctionParams<RequestBody>) =>
    await deleteMethod<ResponseBody, RequestBody>({
      url: url,
      body: body,
      config: params?.config,
    })
  return useMutation<ResponseBody, RequestError, MutateFunctionParams<RequestBody>>(mutationFunction, params?.options)
}

export const useUpdateResourceWithoutBody = <TResponseBody>({
  method = 'put',
  options,
  config,
}: Partial<UpdateResourceNoBody<RequestError, TResponseBody>>): UseBaseMutationResult<
  TResponseBody,
  RequestError,
  MutateFunctionParams<never>
> => {
  const { request } = useApi()

  const mutationFunction = async ({ url }: MutateFunctionParams<{}>) =>
    await request<TResponseBody, RequestError>(method, {
      url: url,
      config,
    })
  return useMutation<TResponseBody, RequestError, MutateFunctionParams<never>>(mutationFunction, options)
}
