import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { Question } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FilterGroup } from '../../Filters/useFilters'

type AssessmentsDetailsHeaderProps = SortableHeaderProps & {
  questions?: Question[]
  userFilters?: FilterGroup[]
}

const AssessmentsDetailsHeader: React.FC<AssessmentsDetailsHeaderProps> = ({
  questions,
  userFilters,
  toggleSorting,
  activeSorting,
}) => {
  const { formatMessage } = useIntl()

  const itemCells = questions?.map((item, index) => (
    <TableHeaderCell
      maxRows={2}
      label={item?.kpiName ?? item.questionText}
      key={`${item}-${index}`}
      minWidth={TableCellWidth.LARGE}
    />
  ))
  if (!itemCells?.length) return null

  return (
    <TableRow>
      <TableHeaderCell
        maxRows={2}
        label={formatMessage({ id: 'resourceCollections.general.company' })}
        sorting={{ name: 'provider.organization.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        maxRows={2}
        label={formatMessage({ id: 'assessment.filter.result' })}
        minWidth={TableCellWidth.SMALL}
        sorting={{ name: 'percentage', toggleSorting, activeSorting }}
      />
      {...itemCells}
    </TableRow>
  )
}

export default AssessmentsDetailsHeader
