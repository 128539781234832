import { Components } from '@mui/material'

const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      height: '48px',
    },
  },
}

export default MuiMenuItem
