import { makeStyles } from '@mui/styles'

export const useCustomScrollbarStyles = makeStyles(({ palette }) => ({
  scrollbar: {
    scrollbarColor: `${palette.grey[600]} ${palette.grey[300]}`,

    '&::-webkit-scrollbar': {
      width: 10,
    },

    '&::-webkit-scrollbar-track': {
      background: palette.grey[300],
      borderRadius: 10,
    },

    '&::-webkit-scrollbar-thumb': {
      background: palette.grey[600],
      borderRadius: 10,
    },
  },
}))
