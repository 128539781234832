import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { ProviderComment } from '@app/src/types/resourceExplorer'
import { InsertDriveFileOutlined } from '@mui/icons-material'
import React from 'react'
import { useIntl } from 'react-intl'
import ProviderCommentHeader from './ProviderCommentHeader'
import ProviderCommentRow from './ProviderCommentRow'

type ProviderCommentCollectionProps = {
  providerId: number
  embedded: boolean
}

const ProviderCommentCollection: React.FC<ProviderCommentCollectionProps> = ({ providerId, embedded }) => {
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { formatMessage } = useIntl()

  const { items, count, isLoading, isError } = useFetchCollectionWithPost<ProviderComment>({
    key: FetchKey.CommentCollection,
    endpoint: endpoints.providerComments,
    payload: {
      filter: [
        {
          name: 'objectInstanceId',
          filters: [
            {
              operator: Operators.EqualTo,
              value: providerId,
            },
          ],
        },
        {
          name: 'objectType',
          filters: [
            {
              operator: Operators.EqualTo,
              value: 'Provider',
            },
          ],
        },
      ],
      include: ['creatorUser'],
    },
  })

  return (
    <ResourceCollectionScene title={formatMessage({ id: 'resourceTypes.providerActivity' })} embedded={embedded}>
      <Table<ProviderComment>
        RowComponent={ProviderCommentRow}
        HeaderComponent={ProviderCommentHeader}
        data={items}
        page={page}
        pageSize={pageSize}
        noPagination
        isLoading={isLoading}
        count={count}
        isError={isError}
        setPage={setPage}
        setPageSize={setPageSize}
        emptyState={
          <EmptyState
            iconComponent={InsertDriveFileOutlined}
            title={formatMessage({ id: 'resourceExplorer.activity.commentEmptyStateTitle' })}
            description={formatMessage({ id: 'resourceExplorer.activity.commentEmptyStateDescription' })}
            sx={{ my: 2 }}
          />
        }
      />
    </ResourceCollectionScene>
  )
}

export default ProviderCommentCollection
