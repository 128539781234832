import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ReportFlagRuleHeader = (): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'automations.flagNumberHeader' })}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <TableHeaderCell label="" minWidth={TableCellWidth.EMPTY} />
      <TableHeaderCell
        label={formatMessage({ id: 'automations.questionSupportingFlags' })}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell label={formatMessage({ id: 'automations.activation' })} minWidth={TableCellWidth.SMALL} />
      <TableHeaderCell label={formatMessage({ id: 'automations.action' })} />
    </TableRow>
  )
}

export default ReportFlagRuleHeader
