import React from 'react'

const BaselineAsessmentIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" stroke="#000" strokeWidth=".7" strokeMiterlimit="10">
        <path
          clipRule="evenodd"
          d="M21.12 1.35H2.66C1.39 1.35.35 2.39.35 3.66v16.15c0 1.27 1.04 2.31 2.31 2.31h18.46c1.27 0 2.31-1.04 2.31-2.31V3.66c0-1.27-1.04-2.31-2.31-2.31Z"
        />
        <path clipRule="evenodd" d="M20.44 9.91 18.8 8.27l-3.66 3.66-1.63-1.64-1.63 1.63 3.25 3.28 5.3-5.28.01-.01Z" />
        <path d="M9.58 5.97H3.81v2.31h5.77V5.97Zm0 4.61H3.81v2.31h5.77v-2.31Zm0 4.62H3.81v2.31h5.77V15.2Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(0 1)" d="M0 0h23.78v21.47H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BaselineAsessmentIcon
