import React from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { isDateOnly } from '@app/src/utils/helpersTs'

export interface DateDisplayProps {
  value: string | Date
  withTime?: boolean
}

export const useGetFormattedDate = () => {
  const { formatDate } = useIntl()

  const formatValueToDate = ({ value, withTime }: DateDisplayProps) => {
    if (withTime) {
      return formatDate(value, {
        hour12: false,
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
      })
    }

    return formatDate(value, {
      year: 'numeric',
      day: 'numeric',
      month: 'long',
    })
  }

  return { formatValueToDate }
}

interface DateTimeDisplayWithTodayProps {
  date: Date
}

export const DateTimeDisplayWithToday: React.FC<DateTimeDisplayWithTodayProps> = ({ date }) => {
  const { formatMessage, formatTime } = useIntl()
  const currentTime = new Date()
  const isToday =
    date.getFullYear() === currentTime.getFullYear() &&
    date.getMonth() === currentTime.getMonth() &&
    date.getDate() === currentTime.getDate()

  if (isToday)
    return (
      <>
        {formatMessage({ id: 'general.today' })} {formatTime(date, { hour12: false })}
      </>
    )

  return <DateDisplay value={date} withTime />
}

const DateDisplay: React.FC<DateDisplayProps> = ({ value, withTime }) => {
  if (!value) {
    return <>-</>
  }

  if (withTime)
    return (
      <FormattedDate
        hour12={false}
        value={value}
        year="numeric"
        day="numeric"
        month="long"
        hour="2-digit"
        minute="2-digit"
      />
    )

  return (
    <FormattedDate
      year="numeric"
      day="numeric"
      month="long"
      value={typeof value === 'string' && isDateOnly(value) ? `${value} 00:00:00` : value}
    />
  )
}

export default DateDisplay
