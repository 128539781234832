import Permissions from '@app/src/auth/permissions'
import LoadingButton from '@app/src/components/LoadingButton'
import { useAccount } from '@app/src/context/AccountContext'
import { useAiSuggestionsUploadFile } from '@app/src/hooks/useAiSuggestionsUploadFile'
import useFeatureFlagsByAccessor from '@app/src/hooks/useFeatureFlagsByAccessor'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { FileUpload } from '@mui/icons-material'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'

const UploadFiles = () => {
  const { response } = useReport()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { isWaitingToReload, isUploading, uploadFile } = useAiSuggestionsUploadFile()
  const { formatMessage } = useIntl()
  const { hasPermission } = useAccount()
  const { orgOrAccessorHasFeature: suggestedAnswers } = useFeatureFlagsByAccessor({
    featureToggleName: 'suggested-answers',
    enabled: hasPermission(Permissions.TRANSPARENCY_USER),
  })

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      await uploadFile(selectedFile)
    }
  }

  if (!suggestedAnswers) {
    return null
  }

  if (!response?.id) {
    return null
  }

  return (
    <>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      <LoadingButton
        startIcon={<FileUpload />}
        onClick={handleFileClick}
        sx={{ px: 2, bgcolor: 'communication.light', color: 'communication.dark' }}
        loading={isUploading || isWaitingToReload}
      >
        {formatMessage({ id: 'suggestedAnswers.uploadFilesForAi' })}
      </LoadingButton>
    </>
  )
}

export default UploadFiles
