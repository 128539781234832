import { TabsProps } from '@app/src/components/Tabs'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, Redirect, Route, Switch, useParams } from 'react-router'
import paths from '@app/src/wf-constants/paths'
import ProviderPublicContactCollection from './ProviderPublicContactCollection'
import SuggestedContactCollection from './SuggestedContactCollection'

interface ProviderContactsOverviewParams {
  activeTabParam: string
  view: string
  resourceType: string
  resourceId: string
}

export enum ContactTabs {
  Public = 'public',
  Suggested = 'suggested',
}

const ProviderContactsOverview: React.FC = () => {
  const { formatMessage } = useIntl()
  const { resourceType, resourceId, activeTabParam, view } = useParams<ProviderContactsOverviewParams>()

  const actionTabs: TabsProps['tabs'] = [
    {
      type: ContactTabs.Public,
      url: generatePath(paths.resourceExplorer, { resourceType, resourceId, activeTabParam, view: ContactTabs.Public }),
      label: formatMessage({ id: 'resourceExplorer.contacts.public' }),
    },
    {
      type: ContactTabs.Suggested,
      url: generatePath(paths.resourceExplorer, {
        resourceType,
        resourceId,
        activeTabParam,
        view: ContactTabs.Suggested,
      }),
      label: formatMessage({ id: 'resourceExplorer.contacts.suggested' }),
    },
  ]

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.resourceExplorer, {
          resourceType,
          resourceId,
          activeTabParam,
          view: ContactTabs.Public,
        })}
      />
    )
  }

  return (
    <Switch>
      <Route
        path={generatePath(paths.resourceExplorer, {
          resourceType,
          resourceId,
          activeTabParam,
          view: ContactTabs.Public,
        })}
      >
        <ProviderPublicContactCollection tabs={{ actionTabs, activeTabParam: view }} providerId={resourceId} />
      </Route>
      <Route
        path={generatePath(paths.resourceExplorer, {
          resourceType,
          resourceId,
          activeTabParam,
          view: ContactTabs.Suggested,
        })}
      >
        <SuggestedContactCollection tabs={{ actionTabs, activeTabParam: view }} providerId={resourceId} />
      </Route>
    </Switch>
  )
}

export default ProviderContactsOverview
