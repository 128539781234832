import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import CategoryDrawer from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawer'
import { CategoryDrawerViews } from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import { CategoryHeader } from '@app/src/pages/Configurations/ConfigurationsPages/Category/CategoryHeader'
import { Category, CategoryOption } from '@app/src/types/categories'
import { Box, Chip, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface Props {
  category: Category
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  paper: {
    marginBottom: spacing(3),
    '& h2': {
      color: palette.text.secondary,
    },
  },
  chip: {
    margin: 2,
  },
  chipRoot: {
    borderRadius: 16,
  },
}))

const CategoryValue = ({ label }: { label: string }) => {
  const classes = useStyles()
  return <Chip className={classes.chip} variant="outlined" classes={{ root: classes.chipRoot }} label={label} />
}

const CategoryCard: React.FC<Props> = ({ category }) => {
  const classes = useStyles()
  const [isDrawerOpen, openDrawer, closeDrawer] = useDrawer_DEPRECATED(false)
  const { data: categoryOptions } = useFetchResource<CategoryOption[]>({
    key: [FetchKey.CategoryOption, category.id],
    endpoint: endpoints.categoryOptionsByCategory(category.id),
  })
  return (
    <>
      <Paper elevation={1} className={classes.paper} data-testid="category-card">
        <CategoryHeader name={category.name} onClick={openDrawer} />
        <Box padding={2}>
          {categoryOptions?.map(categoryOption => (
            <CategoryValue key={categoryOption.id} label={categoryOption.name} />
          ))}
        </Box>
      </Paper>
      <CategoryDrawer
        category={category}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        view={CategoryDrawerViews.EditCategoryOptions}
      />
    </>
  )
}

export default CategoryCard
