import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import FilterFacetSelect, {
  FilterFacetSelectProps,
  SingleModeProps,
} from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import { Sort } from '@app/src/types/filter'
import React, { useEffect, useMemo } from 'react'
import { useFiltersContext } from './Filters'

type PreFetchedSingleFilterFacetProps = {
  endpoint: string
  onChange: SingleModeProps['onChange']
  queueAfterFilters?: string[]
  sort?: Sort
  facetsName?: string
  isEnum?: boolean
} & Omit<FilterFacetSelectProps, 'facetsParam' | 'multiple' | 'onChange'>

const PreFetchedSingleFilterFacet: React.FC<PreFetchedSingleFilterFacetProps> = ({
  endpoint,
  filterName,
  facetsName,
  implicitFilters = [],
  queueAfterFilters,
  value,
  fieldLabel,
  sort,
  isEnum,
  onChange,
  ...props
}) => {
  const { apiFilters } = useFiltersContext()

  const flatValue = [value].flat()
  const queuedAfterFilters = useMemo(
    () => queueAfterFilters?.every(filter => apiFilters.find(f => f.name === filter)),
    [apiFilters, queueAfterFilters],
  )

  const facetsNameToFetch = facetsName || filterName

  const {
    facets: [facetData],
    refetch,
  } = useFetchFacets({
    key: [FetchKey.FilterFacet, facetsNameToFetch],
    endpoint,
    facetsParam: [{ name: facetsNameToFetch, isEnum }],
    filter: [...implicitFilters, ...apiFilters.filter(filter => filter.name !== filterName)].flat(),
    sort,
    options: { enabled: !queueAfterFilters?.length },
  })

  useEffect(() => {
    if (!facetData?.length || flatValue?.[0]) return
    onChange(facetData[0].value.toString())
  }, [facetData, flatValue])

  useEffect(() => {
    if (queuedAfterFilters) {
      refetch()
    }
  }, [queuedAfterFilters])

  return (
    <FilterFacetSelect
      implicitFilters={implicitFilters}
      multiple={false}
      facetsParam={{
        key: [FetchKey.FilterFacet, facetsNameToFetch],
        endpoint,
        facetsParam: [{ name: facetsNameToFetch, isEnum }],
        sort,
        options: { enabled: false },
      }}
      filterName={filterName}
      onChange={onChange}
      fieldLabel={fieldLabel}
      value={flatValue?.[0]}
      {...props}
    />
  )
}

export default PreFetchedSingleFilterFacet
