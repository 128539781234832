import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { ExternalVerificationStatus, VerificationResponseItem } from '@app/src/types/resourceExplorer'
import { ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  verification: VerificationResponseItem
}

const ExternalVerificationStatusItem = ({ verification }: Props): JSX.Element | null => {
  const { formatValueToDate } = useGetFormattedDate()
  const { formatMessage } = useIntl()

  return (
    <>
      <ListItem
        alignItems={verification?.externalVerificationComment ? 'flex-start' : 'center'}
        disablePadding
        sx={{ mt: 2 }}
      >
        <ListItemAvatar sx={({ spacing }) => ({ minWidth: spacing(4) })}>
          <CompanyAvatar
            imageUrl={verification.creatorOrganization?.image?.url}
            organizationName={verification.creatorOrganization?.name ?? ''}
            size={AvatarSize.Small}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack direction="row" alignItems="center">
              <Typography>{verification.creatorOrganization?.name}</Typography>
              <Typography variant="caption" color="textSecondary" ml={1}>
                {formatMessage(
                  { id: 'general.valueWithDotSeparatorBefore' },
                  { value: formatValueToDate({ value: verification.createdAt }) },
                )}
              </Typography>
            </Stack>
          }
          secondary={
            verification?.externalVerificationComment &&
            verification?.externalVerificationComment !== ExternalVerificationStatus.NotSet && (
              <Typography variant="body1" mt={1}>
                {verification.externalVerificationComment}
              </Typography>
            )
          }
        />
      </ListItem>
    </>
  )
}

export default ExternalVerificationStatusItem
