import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'

type TableSkeletonProps = {
  cols?: number
  rows?: number
}

const TableSkeleton = ({ cols = 5, rows = 3 }: TableSkeletonProps) => {
  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table stickyHeader aria-label="table" size="medium">
          <TableHead>
            <TableRow>
              {Array.from({ length: cols }, (_, idx) => (
                <TableCell key={idx} sx={{ minWidth: 200, backgroundColor: 'grey.100' }} size="medium" align="left">
                  <Skeleton variant="rounded" height={24} width="80%" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.from({ length: rows }, (_, idx) => (
              <TableRow key={idx}>
                {Array.from({ length: cols }, (_, innerIdx) => (
                  <TableCell key={innerIdx} align="left">
                    <Skeleton variant="text" width="60%" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" alignItems="center" justifyContent="flex-end" mr={8}>
        <Typography variant="body1" mr={2}>
          <Skeleton variant="text" width={200} height={40} />
        </Typography>
        <Typography variant="body1">
          <Skeleton variant="text" width={100} height={40} />
        </Typography>
      </Box>
    </>
  )
}

export default TableSkeleton
