import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import Permissions from '@app/src/auth/permissions'
import Dialog from '@app/src/components/Dialog'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Contact, User } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, NotificationSeverity, Roles } from '@app/src/wf-constants'
import { Alert, Button, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useAccount } from './AccountContext'
import { useAmplitude } from './AmplitudeContext'
import { useSnackbar } from './SnackbarContext'

const SESSION_STORAGE_KEY = 'pcp-dialog-hidden'

type PublicContactForm = {
  publicContact: number
}

const PublicContactEnforcer: React.FC = () => {
  const { formatMessage } = useIntl()
  const { account, hasPermission } = useAccount()
  const { trackEvent } = useAmplitude()
  const [isPublicContactDialogOpen, openPublicContactDialog, closePublicContactDialog] = useDialogState()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { control, formState, handleSubmit } = useForm<PublicContactForm>()
  const { mutate: mutatePublicContact, isLoading: isMutatingPublicContact } =
    useUpdateResource<Pick<Contact, 'userId'>>()

  const isTransparency = hasPermission(Permissions.TRANSPARENCY_USER)
  const isAdmin = hasPermission(Permissions.ACCESS_SOLUTION_CONFIGURATION)

  const { items: contacts, isLoading } = useFetchCollectionWithPost<User>({
    key: FetchKey.Contact,
    endpoint: endpoints.contactsCollection,
    payload: {
      filter: [
        {
          name: 'creatorOrganizationId',
          filters: [
            {
              value: account?.organization?.id,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: [],
    },
    options: {
      enabled: isTransparency && isAdmin && Boolean(account?.organization?.id),
    },
  })

  const hasPublicContact = Boolean(contacts?.[0])
  const hasSessionItem = sessionStorage.getItem(SESSION_STORAGE_KEY)

  const onSubmit = (values: PublicContactForm) => {
    mutatePublicContact(
      {
        url: endpoints.saveContact,
        body: {
          userId: values.publicContact,
        },
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulUpdatePublicContact' }),
            severity: NotificationSeverity.success,
          })

          trackEvent({
            name: AmplitudeTrackingEvents.Organization.UpdatePublicContact.ContactUpdated,
          })

          queryClient.invalidateQueries(FetchKey.Colleague)
          queryClient.invalidateQueries(FetchKey.Contact)
          sessionStorage.removeItem(SESSION_STORAGE_KEY)
          closePublicContactDialog()
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const handleSkip = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, 'true')
    closePublicContactDialog()
  }

  useEffect(() => {
    if (hasPublicContact) {
      sessionStorage.removeItem(SESSION_STORAGE_KEY)
    }
  }, [hasPublicContact])

  useEffect(() => {
    if (!isTransparency || !isAdmin || isLoading || hasPublicContact || hasSessionItem) {
      return
    }

    openPublicContactDialog()
  }, [isTransparency, hasPublicContact, isLoading])

  useEffect(() => {
    if (isPublicContactDialogOpen) {
      trackEvent({
        name: AmplitudeTrackingEvents.Organization.UpdatePublicContact.ViewedNudge,
        eventProps: {
          nudge_type: 'pop_up',
        },
      })
    }
  }, [isPublicContactDialogOpen])

  useEffect(() => {
    if (hasSessionItem) {
      trackEvent({
        name: AmplitudeTrackingEvents.Organization.UpdatePublicContact.ViewedNudge,
        eventProps: {
          nudge_type: 'banner',
        },
      })
    }
  }, [hasSessionItem])

  if (!isTransparency) {
    return null
  }

  return (
    <>
      {hasSessionItem && (
        <Alert
          severity="error"
          sx={{ borderRadius: 0 }}
          action={<Button onClick={openPublicContactDialog}>{formatMessage({ id: 'general.update' })}</Button>}
        >
          {formatMessage({ id: 'schemas.publicContactEnforcer.bannerTitle' })}
        </Alert>
      )}

      <Dialog
        open={isPublicContactDialogOpen}
        onClose={handleSkip}
        title={formatMessage(
          { id: 'schemas.publicContactEnforcer.dialogTitle' },
          { companyName: account?.organization?.name },
        )}
        content={
          <Stack gap={1}>
            <Typography paragraph>{formatMessage({ id: 'schemas.publicContactEnforcer.dialogContent' })}</Typography>

            <Select
              name="publicContact"
              fieldLabel={formatMessage({ id: 'schemas.user.publicContact' })}
              rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
              disabled={isMutatingPublicContact}
              control={control}
              required
              enableAutoSelect
              navigation={{
                url: endpoints.colleagues,
                type: 'post',
                postObject: {
                  filter: [],
                  include: [],
                  sort: { target: 'name', order: SortOrder.ASCENDING },
                },
              }}
              objectToOption={(colleague: User) => ({
                label: colleague.name,
                value: colleague.id,
                disabled: colleague.role.toLowerCase() !== Roles.Admin,
              })}
            />
          </Stack>
        }
        buttons={[
          {
            label: formatMessage({ id: 'general.skip' }),
            onClick: handleSkip,
            disabled: isMutatingPublicContact,
          },
          {
            label: formatMessage({ id: 'general.apply' }),
            variant: 'contained',
            disabled: !formState.dirtyFields.publicContact,
            loading: isMutatingPublicContact,
            type: 'submit',
          },
        ]}
        fullWidth
        maxWidth="sm"
        onFormSubmit={handleSubmit(onSubmit)}
      />
    </>
  )
}

export default PublicContactEnforcer
