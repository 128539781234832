import Permissions, { usePermissions } from '@app/src/auth/permissions'
import React from 'react'
import { useIntl } from 'react-intl'
import navbarLinks from '@app/src/wf-constants/navbarLinks'
import MenuWrapper, { MenuWrapperProps } from './MenuWrapper'
import type { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems/index'

const RequestMenu: React.FC<NavbarItemProps> = ({ closeMenuDrawer }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const isTransparency = hasPermission(Permissions.TRANSPARENCY_USER)

  const menuItems: MenuWrapperProps['menuItems'] = [
    {
      title: formatMessage({ id: 'navbar.requests' }),
      link: navbarLinks.requests,
      permission: [
        Permissions.TRANSPARENCY_USER,
        Permissions.SOURCING_USER,
        Permissions.INVESTMENT_USER,
        Permissions.FINANCE_USER,
      ],
    },
    {
      title: formatMessage({ id: 'navbar.responses' }),
      link: navbarLinks.responses,
      permission: [
        Permissions.TRANSPARENCY_USER,
        Permissions.SOURCING_USER,
        Permissions.INVESTMENT_USER,
        Permissions.FINANCE_USER,
      ],
    },
  ]

  if (!isTransparency) {
    menuItems.unshift({
      title: formatMessage({ id: 'navbar.manageRequestOverview' }),
      link: navbarLinks.manageRequestOverview,
      permission: [
        Permissions.TRANSPARENCY_USER,
        Permissions.SOURCING_USER,
        Permissions.INVESTMENT_USER,
        Permissions.FINANCE_USER,
      ],
    })
  }

  return (
    <MenuWrapper
      closeMenuDrawer={closeMenuDrawer}
      menuItems={menuItems}
      title={formatMessage({ id: 'navbar.manageRequests' })}
    />
  )
}

export default RequestMenu
