import { FetchFacetsOptions } from '@app/src/api/fetchHooks'
import CommonFilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/CommonFilterFacetSelect'
import {
  FilterFacetSelectProps,
  FilterFacetSelectTruncateProps,
} from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import { useFiltersContext } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { FilterGroupQueryString } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import React from 'react'

export type SmartFacetSettings = {
  key: FetchFacetsOptions['key']
  isEnum?: boolean
  moreEfficientEndpoint: string
  moreEfficientFacetParam: string
  filterPrefix: string
  fallbackEndpoint: string
  fallbackFacetParam: string
  additionalFiltersForEfficient?: FilterGroupQueryString[]
  filter?: FilterGroupQueryString[]
}
type SmartFilterFacetSelectProps = Omit<FilterFacetSelectProps, 'facetsParam'> &
  FilterFacetSelectTruncateProps & {
    facetSettings: SmartFacetSettings
  }

const getMappedFilters = (filters: FilterGroupQueryString[], filterPrefix: string) =>
  filters.map(f => ({
    ...f,
    name: f.name.slice(filterPrefix.length),
  }))

const getFilterSettings = (
  facetSettings: SmartFilterFacetSelectProps['facetSettings'],
  filterName: string,
  filters?: FilterGroupQueryString[],
  implicitFilters?: FilterGroupQueryString[],
) => {
  const totalFilters = [...(filters ?? []), ...(implicitFilters ?? [])]
  const filterPrefixWithDot = `${facetSettings.filterPrefix}.`
  const allFiltersStartWithPrefix = !totalFilters.some(f => !f.name.startsWith(filterPrefixWithDot))
  if (!allFiltersStartWithPrefix)
    return {
      endpoint: facetSettings.fallbackEndpoint,
      paramName: facetSettings.fallbackFacetParam,
      filters: filters ?? [],
      implicitFilters: implicitFilters,
      filterName: filterName,
    }

  const mappedFilters = getMappedFilters(filters ?? [], filterPrefixWithDot)

  const mappedImplicitFilters = [
    ...getMappedFilters(implicitFilters ?? [], filterPrefixWithDot),
    ...(facetSettings.additionalFiltersForEfficient ?? []),
  ]

  return {
    endpoint: facetSettings.moreEfficientEndpoint,
    paramName: facetSettings.moreEfficientFacetParam,
    filters: mappedFilters,
    implicitFilters: mappedImplicitFilters,
    filterName: filterName.slice(filterPrefixWithDot.length),
  }
}

/*
  For filter dropdowns, getting all values on for example requests is a lot faster than for response items since there are way more response items
  However when fetching for example periods, asking on requests will yield the same results as response items.
  The only time this doesn't work if is the user has also filtered on something like "Answer".
  A smart filter will first try to do the facet on a more efficient facet endpoint, but fallback to the slow one if the filters don't allow it.
 */
const SmartFilterFacetSelect: React.FC<SmartFilterFacetSelectProps> = ({
  facetSettings,
  implicitFilters,
  filterName,
  ...filterFacetSelectProps
}) => {
  const { apiFilters } = useFiltersContext()

  const {
    endpoint,
    paramName,
    filters,
    implicitFilters: mappedImplicitFilters,
    filterName: newFilterName,
  } = getFilterSettings(facetSettings, filterName, apiFilters, implicitFilters)

  return (
    <CommonFilterFacetSelect
      {...filterFacetSelectProps}
      filterName={newFilterName}
      apiFilters={filters}
      implicitFilters={mappedImplicitFilters}
      facetsParam={{
        key: facetSettings.key,
        endpoint: endpoint,
        facetsParam: [{ name: paramName, isEnum: facetSettings.isEnum }],
        filter: facetSettings.filter,
        sort: { target: 'label', order: SortOrder.ASCENDING },
      }}
    />
  )
}

export default SmartFilterFacetSelect
