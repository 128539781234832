import { FacetItem } from '@app/src/pages/ResourceCollection'
import { comparePeriods } from './getOrderedPeriods'

const sortDistinctPeriodLabels = (allAvailablePeriods?: FacetItem[]) =>
  allAvailablePeriods
    ?.map(availablePeriod => availablePeriod.label)
    ?.filter((label, index, self) => self.indexOf(label) === index)
    ?.sort(comparePeriods) ?? []

export default sortDistinctPeriodLabels
