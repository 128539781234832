import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewShareWithAllInfoProps = Omit<DrawerViewProps, 'title'>

const DrawerViewShareWithAllInfo: React.FC<DrawerViewShareWithAllInfoProps> = ({ ...props }) => {
  const { formatMessage } = useIntl()

  return (
    <DrawerView title={formatMessage({ id: 'general.learnMore' })} {...props}>
      <Box px={2}>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'reporting.sharing.information.whatIsTitle' })}
        </Typography>
        <Typography paragraph>{formatMessage({ id: 'reporting.sharing.information.whatIsBody' })}</Typography>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'reporting.sharing.information.whyShareTitle' })}
        </Typography>
        <Typography variant="subtitle2">
          {formatMessage({ id: 'reporting.sharing.information.joinTheChange' })}
        </Typography>
        <Typography gutterBottom>{formatMessage({ id: 'reporting.sharing.information.whyShareBody' })}</Typography>
        <Typography variant="subtitle2">
          {formatMessage({ id: 'reporting.sharing.information.snowballEffectTitle' })}
        </Typography>
        <Typography paragraph>{formatMessage({ id: 'reporting.sharing.information.snowballEffectBody' })}</Typography>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'reporting.sharing.information.goodToKnowTitle' })}
        </Typography>
        <Typography>{formatMessage({ id: 'reporting.sharing.information.goodToKnowBody' })}</Typography>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewShareWithAllInfo
