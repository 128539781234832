import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { ActionButton } from '@app/src/components/ActionButtons'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePrevious from '@app/src/hooks/previous'
import { useQueryState } from '@app/src/hooks/queryState'
import { DEFAULT_FILTER_NAME, FilterInterface } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { insertIf } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, Route, Switch, useLocation, useParams } from 'react-router-dom'
import AccessorRequestsScene from './AccessorRequestsScene'
import AccessorResponseScene from './AccessorResponseScene'
import OverviewManageRequestsScene from './Overview/OverviewManageRequestsScene'
import ProviderRequestsScene from './ProviderRequestScene'
import ProviderResponseScene from './ProviderResponseScene'

interface ManageRequestsSceneParams {
  view: ViewTypeName
}

export enum ViewTypeName {
  Requests = 'requests',
  Responses = 'responses',
  Overview = 'overview',
}

const VIEWS_WITH_REQUEST_FILTERS = [ViewTypeName.Overview, ViewTypeName.Requests]

const toResponseFilterName = (requestFilterName: string) => {
  if (requestFilterName.toLowerCase() === 'request.responseinternalstatus')
    return 'verifications.internalVerificationStatus'

  return `request.${requestFilterName}`
}

const toRequestFilterName = (responseFilterName: string) => {
  if (responseFilterName.toLowerCase() === 'verifications.internalverificationstatus')
    return 'request.responseInternalStatus'

  if (!responseFilterName.startsWith('request.')) return responseFilterName
  return responseFilterName.substring('request.'.length)
}

const mapFilters = (filters: FilterInterface[], previousView: ViewTypeName, view: ViewTypeName) => {
  if (VIEWS_WITH_REQUEST_FILTERS.includes(previousView) && view === ViewTypeName.Responses)
    return filters.map(f => ({ ...f, name: toResponseFilterName(f.name) }))

  if (previousView === ViewTypeName.Responses && VIEWS_WITH_REQUEST_FILTERS.includes(view))
    return filters.map(f => ({ ...f, name: toRequestFilterName(f.name) }))

  return filters
}

const ManageRequestsScene: React.FC = () => {
  const { view } = useParams<ManageRequestsSceneParams>()
  const [isCreateDialogOpen, setCreateDialogOpen, closeCreateDialog] = useDialogState()
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const [filters, setFilters] = useQueryState<Array<FilterInterface>>(DEFAULT_FILTER_NAME, [])
  const previousFilters = usePrevious(filters)
  const previousView = usePrevious(view)

  const location = useLocation<{ openCreateDialog: boolean }>()

  useEffect(() => {
    if (location.state?.openCreateDialog) {
      setCreateDialogOpen()
    }
  }, [])

  useEffect(() => {
    if (!previousView || !previousFilters?.length) return
    const mappedFilters = mapFilters(previousFilters, previousView, view)
    setFilters(mappedFilters)
  }, [view])

  const actionTabs = [
    ...insertIf(!hasPermission(Permissions.TRANSPARENCY_USER), {
      type: ViewTypeName.Overview,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Overview }),
      label: formatMessage({ id: 'navbar.manageRequestOverview' }),
    }),
    {
      type: ViewTypeName.Requests,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Requests }),
      label: formatMessage({ id: 'navbar.requests' }),
    },
    {
      type: ViewTypeName.Responses,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Responses }),
      label: formatMessage({ id: 'navbar.responses' }),
    },
  ]

  const actionButtons: ResourceCollectionSceneProps['actionButtons'] = [
    ...insertIf<ActionButton>(!hasPermission(Permissions.TRANSPARENCY_USER), {
      label: formatMessage({ id: 'resourceCollections.general.createRequest' }),
      onClick: setCreateDialogOpen,
      variant: 'contained',
    }),
  ]

  return (
    <>
      <Switch>
        {!hasPermission(Permissions.TRANSPARENCY_USER) && (
          <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Overview })}>
            <OverviewManageRequestsScene
              tabs={{ actionTabs, activeTabParam: view }}
              actionButtons={actionButtons}
              setCreateDialogOpen={setCreateDialogOpen}
            />
          </Route>
        )}

        <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Requests })}>
          {hasPermission(Permissions.TRANSPARENCY_USER) ? (
            <ProviderRequestsScene tabs={{ actionTabs, activeTabParam: view }} />
          ) : (
            <AccessorRequestsScene
              tabs={{ actionTabs, activeTabParam: view }}
              actionButtons={actionButtons}
              setCreateDialogOpen={setCreateDialogOpen}
            />
          )}
        </Route>

        <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Responses })}>
          {hasPermission(Permissions.TRANSPARENCY_USER) ? (
            <ProviderResponseScene tabs={{ actionTabs, activeTabParam: view }} />
          ) : (
            <AccessorResponseScene tabs={{ actionTabs, activeTabParam: view }} actionButtons={actionButtons} />
          )}
        </Route>
      </Switch>

      <CreationModalProgressContextProvider>
        <CreateNewRequestModal open={isCreateDialogOpen} onClose={closeCreateDialog} />
      </CreationModalProgressContextProvider>
    </>
  )
}

export default ManageRequestsScene
