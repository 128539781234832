import { FlagIndicator } from '@app/src/components/Table/Cells/FlagIndicator'
import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import RiskChipCell, { getColorBasedOnRisk } from '@app/src/components/Table/Cells/RiskChipCell'
import { ProviderRiskStatus, ProviderRiskStatusType, RiskStatus } from '@app/src/types/resourceExplorer'
import { ErrorOutlined } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

interface ProviderRiskCellProps {
  count: number
  riskStatus: ProviderRiskStatusType
  hqCountry?: string
  hasPotentialRisk?: boolean
  disableCell?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>
}

const ProviderRiskCell: React.FC<ProviderRiskCellProps> = ({
  count,
  hqCountry,
  hasPotentialRisk,
  disableCell,
  riskStatus,
  onClick,
}) => {
  const { formatMessage } = useIntl()
  const { iconBgColor } = getColorBasedOnRisk(
    riskStatus === ProviderRiskStatus.NotApplicable ? RiskStatus.Low : riskStatus,
  )
  const [isHovered, setIsHovered] = useState(false)

  if (riskStatus === ProviderRiskStatus.Pending || riskStatus === ProviderRiskStatus.NotApplicable)
    return <RiskChipCell risk={riskStatus} size="medium" disableCell={disableCell} onClick={onClick} />

  return (
    <ParentCellOrFragment
      disableCell={disableCell}
      onClick={onClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      tabIndex={0}
      sx={{ outline: 'none' }}
    >
      <Box display="flex">
        <FlagIndicator label={count} bgcolor={iconBgColor} focusEffect={onClick && isHovered} />
        {hasPotentialRisk && hqCountry && (
          <Tooltip
            title={formatMessage({ id: 'schemas.providerRisk.hqRiskCountryTooltip' }, { country: hqCountry })}
            arrow
          >
            <ErrorOutlined color="action" sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </Box>
    </ParentCellOrFragment>
  )
}

export default ProviderRiskCell
