import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import RiskIndexInfoDrawerView from '@app/src/components/Drawer/Views/RiskIndexInfoDrawerView'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { Provider } from '@app/src/types/organizations'
import { RiskType } from '@app/src/types/resourceExplorer'
import { CheckboxProps, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface RiskScreeningHqHeaderProps extends SortableHeaderProps {
  enabledRiskTypes: RiskType[]
  selectedProviders: Array<number>
  setSelectedProviders: React.Dispatch<React.SetStateAction<Array<number>>>
  providersInPage: Provider[]
}

const RiskScreeningHqHeader: React.FC<RiskScreeningHqHeaderProps> = ({
  toggleSorting,
  activeSorting,
  enabledRiskTypes,
  selectedProviders,
  setSelectedProviders,
  providersInPage,
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const isChecked = providersInPage?.every(provider => selectedProviders?.includes(provider.id))

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedProviders(prev => Array.from(new Set([...prev, ...providersInPage.map(provider => provider.id)])))
    } else {
      setSelectedProviders([])
    }
  }

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isChecked, onChange: handleCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'riskScreening.companyName' })}
        sorting={{ name: 'name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.organization.country' })}
        sorting={{ name: 'country.name', toggleSorting, activeSorting }}
      />
      {enabledRiskTypes.map(riskType => (
        <TableHeaderCell
          key={`riskType-${riskType.source}`}
          label={formatMessage({ id: `schemas.risk.riskIndexes.${riskType.source}.label` })}
          onInfoClick={() => {
            openDrawer(<RiskIndexInfoDrawerView riskType={riskType} />)
          }}
        />
      ))}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.provider.priority' })}
        sorting={{ name: 'priority', toggleSorting, activeSorting }}
      />
    </TableRow>
  )
}

export default RiskScreeningHqHeader
