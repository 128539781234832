import { ButtonBase, Stack, StackProps, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import TextTruncate, { TextTruncateProps } from 'react-text-truncate'
import ParentCellOrFragment from './ParentCellOrFragment'

export type IconCellProps = {
  title?: string | number
  onClick?: () => void
  icon: React.ReactElement
  tooltipText?: string
  maxRows?: TextTruncateProps['line']
  disableCell?: boolean
} & Omit<StackProps, 'title'>

const IconCell: React.FC<IconCellProps> = ({
  title,
  icon,
  onClick,
  tooltipText,
  maxRows = 1,
  disableCell,
  ...stackProps
}) => {
  const [truncated, setTruncated] = useState(false)

  const handleTruncate = () => {
    setTruncated(true)
  }

  if (!title) {
    return <ParentCellOrFragment disableCell={disableCell}>{icon}</ParentCellOrFragment>
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip title={truncated ? title : tooltipText}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          component={onClick ? ButtonBase : 'span'}
          onClick={onClick ? onClick : undefined}
          data-testid="request-cell"
          width="100%"
          {...stackProps}
        >
          {Boolean(icon) && icon}

          <Typography
            variant="body1"
            align="left"
            sx={{ '&:hover': { textDecoration: onClick ? 'underline' : 'none' } }}
            flexGrow={1}
            component="span"
          >
            <TextTruncate line={maxRows} text={title.toString()} onTruncated={handleTruncate} />
          </Typography>
        </Stack>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default IconCell
