import Chip from '@mui/material/Chip'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Option } from './SimpleSelect'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles(({ spacing }) => ({
  adornment: {
    marginLeft: spacing(1),
  },
}))

interface Props<T> {
  option?: Option<T>
}

const OptionAdornment = ({ option }: Props<unknown>): JSX.Element | null => {
  const classes = useStyles()
  return option?.adornment ? <Chip className={classes.adornment} label={option?.adornment} size="small" /> : null
}

export default OptionAdornment
