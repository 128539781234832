import { TableRow } from '@mui/material'
import { SortableHeaderProps } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { ConstVars } from '@app/src/wf-constants'

const EvaluationHeader: React.FC<SortableHeaderProps> = ({
  toggleSorting,
  activeSorting,
}: SortableHeaderProps): JSX.Element => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'schemas.evaluation.target' })} />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.evaluation.frameworkSection' })}
        sorting={{ name: ConstVars.EvaluationAccessors.FrameworkSection, toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.evaluation.frameworkIndicator' })}
        sorting={{ name: ConstVars.EvaluationAccessors.FrameworkIndication, toggleSorting, activeSorting }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.evaluation.result' })} />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.evaluation.period' })}
        sorting={{ name: ConstVars.EvaluationAccessors.Period, toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.evaluation.evidence' })}
        sorting={{ name: ConstVars.EvaluationAccessors.Evidence, toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.evaluation.framework' })}
        sorting={{ name: ConstVars.EvaluationAccessors.Framework, toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.evaluation.createdAt' })}
        sorting={{ name: ConstVars.EvaluationAccessors.CreatedAt, toggleSorting, activeSorting }}
      />
    </TableRow>
  )
}

export default EvaluationHeader
