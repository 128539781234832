import { Components } from '@mui/material'
import { palette } from '@app/src/theme/palette'

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      '&.Mui-error': {
        color: palette.semantic.error,
      },
    },
  },
}

export default MuiFormHelperText
