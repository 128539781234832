import { CheckboxProps, TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { Question } from '@app/src/types/resourceExplorer'
import { QuestionCheckboxCellProps } from './QuestionCheckboxCell'
import { BASIC_QUESTION_CATEGORIES } from '@app/src/wf-constants/questionCategories'

interface StandardQuestionHeaderProps extends SortableHeaderProps {
  includeCheckboxHeader: boolean
  selectionProps?: Partial<QuestionCheckboxCellProps>
  questionsInPage?: Question[]
}

const StandardQuestionHeader: React.FC<StandardQuestionHeaderProps> = ({
  toggleSorting,
  activeSorting,
  includeCheckboxHeader,
  selectionProps,
  questionsInPage,
}) => {
  const { formatMessage } = useIntl()
  const { selectedQuestionIds, setSelectedQuestionIds, questionIdsAlreadyInQuestionnaire } = selectionProps || {}

  const remainingQuestions = questionsInPage?.filter(
    question => !questionIdsAlreadyInQuestionnaire?.includes(question.id),
  )

  const isChecked = remainingQuestions?.every(question => selectedQuestionIds?.includes(question.id))

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedQuestionIds?.(prev =>
        Array.from(new Set([...prev, ...(remainingQuestions?.map(provider => provider.id) ?? [])])),
      )
    } else {
      setSelectedQuestionIds?.([])
    }
  }

  return (
    <TableRow>
      {includeCheckboxHeader && (
        <TableHeaderCell
          label=""
          minWidth={TableCellWidth.EMPTY}
          checkbox={{ checked: isChecked, onChange: handleCheckboxChange, color: 'primary' }}
        />
      )}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.question.name' })}
        sorting={{ name: 'questionText', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.question.guidance' })} minWidth={TableCellWidth.SMALL} />
      {BASIC_QUESTION_CATEGORIES.map(category => (
        <TableHeaderCell
          key={category.name}
          label={formatMessage({ id: category.translationKey })}
          minWidth={TableCellWidth.SMALL}
        />
      ))}
    </TableRow>
  )
}

export default StandardQuestionHeader
