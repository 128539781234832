import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const AssessmentSettingsHeader: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell
        minWidth={TableCellWidth.EXTRA_SMALL}
        label={formatMessage({ id: 'general.activeSetting' })}
        guidance={formatMessage({ id: 'assessments.activateTooltip' })}
      />
      <TableHeaderCell label={formatMessage({ id: 'assessments.name' })} />
    </TableRow>
  )
}

export default AssessmentSettingsHeader
