import { Location } from 'history'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Prompt, useLocation } from 'react-router-dom'
import Dialog from './Dialog'

interface RouteLeavingGuardProps {
  when?: boolean
  navigate: (path: string) => void
  shouldBlockNavigation: (location: Location) => boolean
  ignoreSearchParamChanges?: boolean
}

const RouteLeavingGuard: React.FC<RouteLeavingGuardProps> = ({
  when,
  navigate,
  shouldBlockNavigation,
  ignoreSearchParamChanges,
}) => {
  const { formatMessage } = useIntl()
  const currentLocation = useLocation()

  const [isPromptOpen, setPromptOpen] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = () => {
    setPromptOpen(false)
  }

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (ignoreSearchParamChanges && currentLocation.pathname === nextLocation.pathname) return true

    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setPromptOpen(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setPromptOpen(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(`${lastLocation.pathname}${lastLocation.search}`)
    }
  }, [confirmedNavigation, lastLocation])

  useEffect(() => {
    setConfirmedNavigation(false)
  }, [currentLocation])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Dialog
        open={isPromptOpen}
        onClose={closeModal}
        title={formatMessage({ id: 'routeLeavingGuard.title' })}
        content={formatMessage({ id: 'routeLeavingGuard.body' })}
        buttons={[
          {
            label: formatMessage({ id: 'routeLeavingGuard.confirmButton' }),
            variant: 'outlined',
            onClick: handleConfirmNavigationClick,
          },
          {
            label: formatMessage({ id: 'routeLeavingGuard.cancelButton' }),
            variant: 'contained',
            onClick: closeModal,
          },
        ]}
      />
    </>
  )
}
export default RouteLeavingGuard
