import { typography } from '@app/src/theme/typography'
import { Components } from '@mui/material'

const MuiTableCell: Components['MuiTableCell'] = {
  styleOverrides: {
    root: {
      fontWeight: 500,
      height: 72,
      fontSize: typography?.body1?.fontSize,
      lineHeight: typography?.body1?.lineHeight,
      letterSpacing: typography?.body1?.letterSpacing,
    },
  },
}

export default MuiTableCell
