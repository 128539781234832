import SelectableTemplateWithPreview from '@app/src/components/CreateNewRequestModal/Common/SelectableTemplateWithPreview'
import EmptyState from '@app/src/components/EmptyState'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import useTemplatesThatCanBeSent from '@app/src/hooks/useTemplatesThatCanBeSent'
import {
  AUTOMATION_QUESTION_KEYS,
  AutomationFormData,
} from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { TaskOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'

interface AutomationQuestionnairesProps {
  selectedQuestionnaireTemplates?: QuestionnaireTemplate[]
}

const AutomationQuestionnaires: React.FC<AutomationQuestionnairesProps> = ({ selectedQuestionnaireTemplates }) => {
  const { formatMessage } = useIntl()
  const { getValues, watch } = useFormContext<AutomationFormData>()
  const reportType = watch('reportType') as ReportType
  const { templates, isLoading } = useTemplatesThatCanBeSent(reportType as ReportType)

  const sortedTemplates = selectedQuestionnaireTemplates?.length
    ? templates
        .map(x => ({ ...x, isPreselected: selectedQuestionnaireTemplates.some(y => x.id === y.id) }))
        .sort(a => (a.isPreselected ? -1 : 1))
    : templates

  return (
    <Box display="flex" flexDirection="column">
      {isLoading ? (
        <Box height={150} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography color="textSecondary" variant="caption">
            {formatMessage({ id: 'form.createRequest.selected' }, { number: getValues()?.templates?.length ?? 0 })}
          </Typography>

          <Box height={300} sx={{ overflowY: 'auto' }}>
            {!templates.length && (
              <EmptyState
                title={formatMessage({ id: 'form.createAutomation.selectQuestionnaire.templateEmptyState.title' })}
                description={formatMessage({
                  id: 'form.createAutomation.selectQuestionnaire.templateEmptyState.description',
                })}
                iconComponent={TaskOutlined}
                sx={{ mt: 2 }}
              />
            )}
            {sortedTemplates.map(template => (
              <SelectableTemplateWithPreview
                template={template}
                key={template.id}
                formKey={AUTOMATION_QUESTION_KEYS.TEMPLATES}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  )
}
export default AutomationQuestionnaires
