import { TableRow } from '@mui/material'
import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Request } from '@app/src/types/resourceExplorer'
import ConfigDialog from './ConfigDialog'

interface EdciRowProps {
  row: Request
}

export const EdciRow: React.FC<EdciRowProps> = ({ row: request }) => {
  const { formatMessage } = useIntl()
  const [dialogConfigId, setDialogConfigId] = useState<number | undefined>()

  const isConfigOpen = dialogConfigId === request.id

  return (
    <>
      <TableRow>
        <CompanyCell company={request.targetAliasObject} drawer />
        <ValueCell value={request.periodName} />
        <StatusCell value={request.requestStatus} />
        <ButtonCell
          label={formatMessage({ id: 'form.createRequest.edci.updateDialog.edit' })}
          variant="outlined"
          onClick={() => setDialogConfigId(request.id)}
        />
      </TableRow>

      <ConfigDialog isOpen={isConfigOpen} closeDialog={() => setDialogConfigId(undefined)} request={request} />
    </>
  )
}

export default EdciRow
