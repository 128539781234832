import Permissions from '@app/src/auth/permissions'
import React from 'react'
import { useIntl } from 'react-intl'
import navbarLinks from '@app/src/wf-constants/navbarLinks'
import { MenuLinkWrapper, MenuLinkWrapperProps } from './MenuWrapper'
import type { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems/index'

const SubscriptionLink: React.FC<NavbarItemProps> = ({ closeMenuDrawer }) => {
  const { formatMessage } = useIntl()

  const menuItems: MenuLinkWrapperProps['menuItems'] = {
    link: navbarLinks.transparencySubscriptions,
    permission: [Permissions.TRANSPARENCY_USER],
  }

  return (
    <MenuLinkWrapper
      closeMenuDrawer={closeMenuDrawer}
      menuItems={menuItems}
      title={formatMessage({ id: 'navbar.subscribers' })}
    />
  )
}

export default SubscriptionLink
