import Dialog from '@app/src/components/Dialog'
import React, { useEffect, useRef, useState } from 'react'
import IdleTimer from 'react-idle-timer'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

const WARNING_DELAY = 25 /* MINUTES */
const LOGOUT_DELAY_ON_WARNING = 5 /* MINUTES */

const minutesToMs = (minutes: number): number => minutes * 60 * 1000

const InactivityCheck = (): JSX.Element => {
  const [idle, setIdle] = useState(false)
  const logoutTimeout = useRef<number | null>()
  const countdownInterval = useRef<number | null>()
  const [countdown, setCountdown] = useState(LOGOUT_DELAY_ON_WARNING)
  const { formatMessage } = useIntl()
  const history = useHistory()

  const logout = (): void => {
    history.push('/logout')
  }

  const onIdle = (): void => {
    setIdle(true)
    logoutTimeout.current = window.setTimeout(logout, minutesToMs(LOGOUT_DELAY_ON_WARNING))
    countdownInterval.current = window.setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, minutesToMs(1))
  }

  const clearTimeouts = (): void => {
    if (logoutTimeout.current) {
      window.clearTimeout(logoutTimeout.current)
    }
    if (countdownInterval.current) {
      window.clearInterval(countdownInterval.current)
    }
  }

  const stay = (): void => {
    clearTimeouts()
    setIdle(false)
    setCountdown(LOGOUT_DELAY_ON_WARNING)
  }

  useEffect((): (() => void) => {
    return (): void => {
      clearTimeouts()
    }
  }, [])

  if (window.location.hostname === 'localhost') {
    // disabling auto-logout in dev mode
    return <></>
  }

  return (
    <>
      <IdleTimer element={document} onIdle={onIdle} debounce={5000} timeout={minutesToMs(WARNING_DELAY)} />
      <Dialog
        onClose={stay}
        open={idle}
        title={formatMessage({ id: 'inactivityCheck.title' }, { count: WARNING_DELAY })}
        content={formatMessage({ id: 'inactivityCheck.description' }, { count: countdown })}
        buttons={[
          {
            label: formatMessage({ id: 'inactivityCheck.stay' }),
            variant: 'outlined',
            onClick: stay,
          },
          {
            label: formatMessage({ id: 'inactivityCheck.leave' }),
            variant: 'contained',
            onClick: logout,
          },
        ]}
      />
    </>
  )
}

export default InactivityCheck
