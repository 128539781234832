import { useState } from 'react'
import _Drawer_DEPRECATED from './Drawer'

export const Drawer_DEPRECATED = _Drawer_DEPRECATED
export const useDrawer_DEPRECATED = (initialState: boolean): [boolean, () => void, () => void, () => void] => {
  const [isOpen, setOpen] = useState(initialState)

  function openDrawer(): void {
    setOpen(true)
  }

  function closeDrawer(): void {
    setOpen(false)
  }

  function toggleDrawer(): void {
    setOpen(state => !state)
  }

  return [isOpen, openDrawer, closeDrawer, toggleDrawer]
}

export default Drawer_DEPRECATED
