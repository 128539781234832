import SimpleSelect, { Option } from '@app/src/components/Form/Select/SimpleSelect'
import { ActorTypeModel } from '@app/src/types/product'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface Props {
  onChange: (value?: number) => void
  value: ActorTypeModel | null
  actorTypes: ActorTypeModel[]
}

const ActorTypeField: React.FC<Props> = ({ onChange, value, actorTypes }) => {
  const [isOpen, setOpen] = React.useState(false)
  const { formatMessage } = useIntl()
  const { errors } = useFormContext()

  const options: Option<number | null>[] = actorTypes.map(actorType => ({
    label: formatMessage({ id: `schemas.mappingNode.actorTypeValues.${actorType.name}` }),
    value: actorType.id,
    additionalText: actorType.actorTypeGroup
      ? formatMessage({ id: `schemas.mappingNode.actorTypeGroups.${actorType.actorTypeGroup}` })
      : formatMessage({ id: `schemas.product.productIndustry.${actorType.industry}` }),
  }))

  return (
    <SimpleSelect<number | null>
      multiple={false}
      onChange={optionSelected => onChange((optionSelected as Option<number>)?.value ?? null)}
      open={isOpen}
      setOpen={setOpen}
      loading={false}
      groupBy={option => option.additionalText ?? ''}
      options={options}
      fieldLabel={formatMessage({ id: 'schemas.mappingNode.actorType' })}
      value={options.find(opt => opt.value === value) ?? null}
      error={errors?.actorType}
    />
  )
}

export default ActorTypeField
