import AssessmentPieChartCell from '@app/src/components/Table/Cells/AssessmentPieChartCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import Assessment from '@app/src/types/assessment'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'
import AnswerWithClassificationCell from '@app/src/components/Table/Cells/AnswerWithClassificationCell'

type AssessmentsDetailsRowProps = {
  responseItems: Array<ResponseItem | undefined>
  assessment: Assessment
}

const AssessmentsDetailsRow: React.FC<AssessmentsDetailsRowProps> = ({ responseItems, assessment }) => {
  if (!assessment) return null
  return (
    <TableRow>
      <CompanyCell company={assessment.provider} drawer maxRows={2} />
      <AssessmentPieChartCell assessment={assessment} disableCell={false} />
      {...responseItems.map((item, index) =>
        item ? (
          <AnswerWithClassificationCell
            item={item}
            key={`${index}-${item?.id}`}
            maxRows={2}
            responderName={item.creatorOrganization.name}
          />
        ) : (
          <ValueCell value="-" key={`${index}`}></ValueCell>
        ),
      )}
    </TableRow>
  )
}

export default AssessmentsDetailsRow
