import BaselineAsessmentIcon from '@app/src/pages/Dashboards/TransparencyDashboard/icons/BaselineAsessmentIcon'
import EnvironmentIcon from '@app/src/pages/Dashboards/TransparencyDashboard/icons/EnvironmentIcon'
import GovernanceIcon from '@app/src/pages/Dashboards/TransparencyDashboard/icons/GovernanceIcon'
import InitiativesIcon from '@app/src/pages/Dashboards/TransparencyDashboard/icons/InitiativesIcon'
import RegulationsIcon from '@app/src/pages/Dashboards/TransparencyDashboard/icons/RegulationsIcon'
import SocialIcon from '@app/src/pages/Dashboards/TransparencyDashboard/icons/SocialIcon'
import SupplyChainIcon from '@app/src/pages/Dashboards/TransparencyDashboard/icons/SupplyChainIcon'
import SustainableApproachIcon from '@app/src/pages/Dashboards/TransparencyDashboard/icons/SustainableApproachIcon'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { EsgRequestTags, RequestTags, WfRequestTags } from '@app/src/wf-constants'
import { Assignment } from '@mui/icons-material'
import { Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(({ spacing, palette }) => ({
  recommendedChip: {
    backgroundColor: palette.info.dark,
    color: palette.common.white,
    marginRight: spacing(1),
  },
  reportedChip: {
    backgroundColor: palette.success.main,
    marginRight: spacing(1),
  },
  environmental: {
    marginRight: spacing(1),
    background: palette.success.light,
  },
  governance: {
    marginRight: spacing(1),
    background: palette.info.light,
  },
  initiatives: {
    marginRight: spacing(1),
    background: palette.error.light,
  },
  regulations: {
    marginRight: spacing(1),
    background: palette.brand.oyster,
  },
  social: {
    marginRight: spacing(1),
    background: palette.warning.light,
  },
  sustainableApproach: {
    marginRight: spacing(1),
    background: palette.communication.light,
  },
  supplyChain: {
    marginRight: spacing(1),
    background: palette.communication.light,
  },
  baselineAssessment: {
    marginRight: spacing(1),
    background: palette.communication.light,
  },
}))

export const isEsgRequestTag = (tag: RequestTags): tag is EsgRequestTags => {
  return tag ? Object.values<string>(EsgRequestTags).includes(tag) : false
}

export const isWfRequestTag = (tag: RequestTags): tag is WfRequestTags => {
  return tag ? Object.values<string>(WfRequestTags).includes(tag) : false
}

type TagIconProps = {
  tags: QuestionnaireTemplate['tags']
}

export const TagIcon: React.FC<TagIconProps> = ({ tags }) => {
  const classes = useStyles()
  const tag = tags?.filter(tag => isEsgRequestTag(tag) || isWfRequestTag(tag))?.[0]

  switch (tag) {
    case EsgRequestTags.Environmental:
      return (
        <Avatar className={classes.environmental}>
          <EnvironmentIcon />
        </Avatar>
      )
    case EsgRequestTags.Governance:
      return (
        <Avatar className={classes.governance}>
          <GovernanceIcon />
        </Avatar>
      )
    case EsgRequestTags.Initiatives:
      return (
        <Avatar className={classes.initiatives}>
          <InitiativesIcon />
        </Avatar>
      )
    case EsgRequestTags.Regulations:
      return (
        <Avatar className={classes.regulations}>
          <RegulationsIcon />
        </Avatar>
      )
    case EsgRequestTags.Social:
      return (
        <Avatar className={classes.social}>
          <SocialIcon />
        </Avatar>
      )
    case WfRequestTags.PublicRequest1:
      return (
        <Avatar className={classes.sustainableApproach}>
          <SustainableApproachIcon />
        </Avatar>
      )
    case WfRequestTags.PublicRequest2:
      return (
        <Avatar className={classes.supplyChain}>
          <SupplyChainIcon />
        </Avatar>
      )
    case WfRequestTags.PublicRequest3:
      return (
        <Avatar className={classes.baselineAssessment}>
          <BaselineAsessmentIcon />
        </Avatar>
      )
    default:
      return (
        <Avatar className={classes.supplyChain}>
          <Assignment color="disabled" />
        </Avatar>
      )
  }
}
