import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { Organization } from '@app/src/types/organizations'
import React from 'react'
import ConfigurationSkeleton from '../../ConfigurationsSkeleton'
import OrganizationInfoConfig from './OrganizationInfoConfig'

const OrganizationInfoConfigWrapper = () => {
  const {
    data: organization,
    isLoading,
    isFetching,
  } = useFetchResource<Organization>({
    endpoint: endpoints.getMyOrganization,
    key: FetchKey.GetMyOrganization,
  })

  if (isLoading || isFetching || !organization) return <ConfigurationSkeleton />

  return <OrganizationInfoConfig organization={organization} />
}

export default OrganizationInfoConfigWrapper
