import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { ResourceTypes } from '@app/src/wf-constants'

type CurrentProviderType =
  | typeof ResourceTypes.Supplier
  | typeof ResourceTypes.Investee
  | typeof ResourceTypes.Company
  | typeof ResourceTypes.Provider

type CurrentProviderTypeInPlural =
  | typeof ResourceTypes.Suppliers
  | typeof ResourceTypes.Investees
  | typeof ResourceTypes.Companies
  | typeof ResourceTypes.Providers

interface UseCurrentProviderTypeParams {
  isPlural?: boolean
}

const useCurrentProviderType = ({ isPlural = false }: UseCurrentProviderTypeParams = {}):
  | CurrentProviderTypeInPlural
  | CurrentProviderType => {
  const { getWithPermission } = usePermissions()

  const pluralTypes: Record<string, CurrentProviderTypeInPlural> = {
    [Permissions.SOURCING_USER]: ResourceTypes.Suppliers,
    [Permissions.INVESTMENT_USER]: ResourceTypes.Investees,
    [Permissions.FINANCE_USER]: ResourceTypes.Companies,
    fallback: ResourceTypes.Providers,
  }

  const singularTypes: Record<string, CurrentProviderType> = {
    [Permissions.SOURCING_USER]: ResourceTypes.Supplier,
    [Permissions.INVESTMENT_USER]: ResourceTypes.Investee,
    [Permissions.FINANCE_USER]: ResourceTypes.Company,
    fallback: ResourceTypes.Provider,
  }

  const types = isPlural ? pluralTypes : singularTypes

  return getWithPermission(types) ?? types.fallback
}

export default useCurrentProviderType
