import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import CategoryFilter from '@app/src/pages/ResourceCollection/Filters/CategoryFilter'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Category } from '@app/src/types/categories'
import React from 'react'
import { useIntl } from 'react-intl'

interface CustomCategoriesFiltersProps {
  baseFilterName?: string
}

const CustomCategoriesFilters: React.FC<CustomCategoriesFiltersProps> = ({ baseFilterName }) => {
  const { formatMessage } = useIntl()
  const { data: categories } = useFetchResource<Category[]>({
    key: FetchKey.Category,
    endpoint: endpoints.category,
  })

  if (!categories || !categories?.length) {
    return null
  }

  return (
    <FilterSection defaultCollapsed label={formatMessage({ id: 'schemas.supplier.customCategories' })}>
      {categories.map(category => (
        <Filter
          key={category.id}
          uniqueId={category.id?.toString()}
          intersect
          operator={Operators.In}
          name={`${baseFilterName ? `${baseFilterName}.` : ''}categoryOptions.id`}
        >
          {({ value, onChange }) => <CategoryFilter category={category} value={[value].flat()} onChange={onChange} />}
        </Filter>
      ))}
    </FilterSection>
  )
}

export default CustomCategoriesFilters
