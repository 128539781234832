import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { SuggestedContact } from '@app/src/types/resourceExplorer'
import { ResourceTypes } from '@app/src/wf-constants'
import { MailOutline, PendingOutlined, PersonAddAltOutlined } from '@mui/icons-material'
import { Chip, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerViewAddProviderContactInformation from './AddProviderContact/DrawerViewAddProviderContactInformation'

export type DrawerViewSuggestedContactProps = {
  contact: SuggestedContact
} & Omit<DrawerViewProps, 'title'>

export const isCurrentUser = (resourceType: string, accountId?: number, userId?: number) =>
  resourceType === ResourceTypes.User && accountId === userId

const DrawerViewSuggestedContact: React.FC<DrawerViewSuggestedContactProps> = ({ contact, ...props }) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()

  return (
    <DrawerView
      title=""
      buttons={[
        {
          label: formatMessage({ id: 'general.learnMore' }),
          onClick: () => openDrawer(<DrawerViewAddProviderContactInformation />),
        },
      ]}
      {...props}
    >
      <Stack pt={2} flexGrow={1} gap={2}>
        <Stack alignItems="center">
          <PersonAvatar name={contact.name} size={AvatarSize.XXL} disabled />
          <Typography variant="h6" align="center" mt={2} px={2} paragraph>
            {contact.name}
          </Typography>
          <Chip label={formatMessage({ id: `suggestedContact.contactStatuses.${contact.status}` })} />
        </Stack>

        <List disablePadding>
          <ListItem>
            <ListItemAvatar>
              <MailOutline color="action" />
            </ListItemAvatar>
            <ListItemText primary={formatMessage({ id: 'schemas.user.email' })} secondary={contact.email} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <PersonAddAltOutlined color="action" />
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: 'resourceExplorer.contacts.suggested' })}
              secondary={formatValueToDate({ value: contact.createdAt })}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <PendingOutlined color="action" />
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: `suggestedContact.contactStatuses.${contact.status}` })}
              secondary={formatValueToDate({ value: contact.createdAt })}
            />
          </ListItem>
        </List>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewSuggestedContact
