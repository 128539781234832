import TextField, { TextFieldProps } from '@app/src/components/Ui/TextField'
import { SearchOutlined } from '@mui/icons-material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useFiltersContext } from './Filters'
import { Operators } from './useFilters'

type TextFieldFilterProps = {
  defaultValue: string | string[]
  label: string
  filterName: string
  operator: Operators
  uniqueId?: string
  isSearchField?: boolean
  inputProps?: TextFieldProps['InputProps']
} & TextFieldProps

const TextFieldFilter = ({
  defaultValue,
  filterName,
  operator,
  uniqueId,
  variant = 'outlined',
  isSearchField = true,
  inputProps,
  ...props
}: TextFieldFilterProps): JSX.Element => {
  const { setQueryFilter, filters } = useFiltersContext()
  const [fieldValue, setFieldValue] = useState<TextFieldFilterProps['value']>(defaultValue)

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    //SET FIELD VALUE
    setFieldValue(e.target.value)

    //DEBOUNCE THE QUERY PARAM
    debouncedQueryChange(e.target.value)
  }

  const debouncedQueryChange = useDebouncedCallback(value => {
    setQueryFilter(filterName, value ?? '', operator, uniqueId)
  }, 500)

  useEffect(() => {
    if (!filters[filterName]) {
      setFieldValue('')
    }
    if (props.value && fieldValue !== props.value) {
      setFieldValue(props.value)
    }
  }, [filters[filterName], props.value])

  return (
    <TextField
      variant={variant}
      InputProps={{
        endAdornment: isSearchField ? <SearchOutlined color="action" /> : inputProps?.endAdornment,
        ...inputProps,
      }}
      {...props}
      value={fieldValue}
      onChange={handleChange}
    />
  )
}

export default TextFieldFilter
