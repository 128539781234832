import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCount } from '@app/src/api/fetchHooks'
import LinkButton from '@app/src/components/LinkButton'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import useLocalStorage from '@app/src/hooks/localStorage'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { palette } from '@app/src/theme/palette'
import { ScreeningResult } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { AutoAwesomeOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Button } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

const getScreeningResultFilter = (screeningResults: ScreeningResult[]) => [
  {
    name: 'response.isLatestSubmitted',
    filters: [
      {
        value: true,
        operator: Operators.EqualTo,
      },
    ],
  },
  {
    name: 'fileScreeningResults.result',
    filters: [
      {
        value: screeningResults,
        operator: Operators.In,
      },
    ],
  },
]

type HideCertificateScreeningSettings = Record<string, string[]>

const CertificateScreeningSummary: React.FC = () => {
  const { formatMessage } = useIntl()
  const { scope } = useAuthentication()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const confirm = useConfirm()
  const { showFileScreeningResult } = useFlags()

  const [hideCertificateScreeningSolutions, setHideCertificateScreeningSolutions] =
    useLocalStorage<HideCertificateScreeningSettings>('hideCertificateScreeningSolutions', {})

  const hideCertificateScreeningFromDashboardForSolution = Boolean(
    scope.organization &&
      scope.solution &&
      hideCertificateScreeningSolutions?.[scope.organization.toString()]?.includes(scope.solution.toLowerCase()),
  )

  const shouldShowScreeningSummary =
    !hideCertificateScreeningFromDashboardForSolution && Boolean(showFileScreeningResult)
  const { count: someFailedCount, isLoading: isLoadingPassed } = useFetchCount({
    endpoint: endpoints.responseItemCount,
    key: FetchKey.ResponseItemCount,
    payload: getScreeningResultFilter([ScreeningResult.Failed, ScreeningResult.Error]),
    options: {
      enabled: shouldShowScreeningSummary,
    },
  })

  const { count: totalCount, isLoading: isLoadingTotal } = useFetchCount({
    endpoint: endpoints.responseItemCount,
    key: FetchKey.ResponseItemCount,
    payload: getScreeningResultFilter([ScreeningResult.Passed, ScreeningResult.Failed, ScreeningResult.Error]),
    options: {
      enabled: shouldShowScreeningSummary,
    },
  })

  const isLoading = isLoadingPassed || isLoadingTotal
  if (!shouldShowScreeningSummary || isLoading || !totalCount) return null

  const hideForSolution = () => {
    if (!scope.solution || !scope.organization) return

    const currentForOrganization = hideCertificateScreeningSolutions?.[scope.organization.toString()] ?? []
    if (currentForOrganization.includes(scope.solution.toLowerCase())) return

    setHideCertificateScreeningSolutions({
      ...hideCertificateScreeningSolutions,
      [scope.organization.toString()]: [...currentForOrganization, scope.solution.toLowerCase()],
    })
  }

  return (
    <Alert
      severity="info"
      icon={<AutoAwesomeOutlined />}
      sx={{
        mt: 2,
        backgroundColor: palette.communication.main,
        '& .MuiAlert-message': {
          width: '100%',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <AlertTitle>{formatMessage({ id: 'certificateScreeningResult.title' })}</AlertTitle>
        <Box>
          <Button
            onClick={async () => {
              await confirm({
                content: formatMessage({
                  id: 'dashboard.certificateScreeningSummary.removeFromDashboardConfirmation',
                }),
                confirmationText: formatMessage({ id: 'general.remove' }),
              })
              hideForSolution()
            }}
          >
            {formatMessage({ id: 'dashboard.certificateScreeningSummary.removeFromDashboard' })}
          </Button>
          <LinkButton
            variant="contained"
            to={stringifyQueryFilters({
              url: generatePath(paths.dataHubCollection, { view: DataHubView.Answers }),
              queryParams: {
                filters: [
                  {
                    name: 'fileScreeningResults.result',
                    value: [ScreeningResult.Passed, ScreeningResult.Failed, ScreeningResult.Error],
                    operator: Operators.In,
                  },
                ],
              },
            })}
          >
            {formatMessage({ id: 'dashboard.certificateScreeningSummary.seeCertificates' })}
          </LinkButton>
        </Box>
      </Box>
      {formatMessage(
        { id: 'dashboard.certificateScreeningSummary.summaryText' },
        { inconsistencyCount: someFailedCount, totalCount: totalCount },
      )}
    </Alert>
  )
}

export default CertificateScreeningSummary
