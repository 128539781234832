import { Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { TypographyProps } from '@mui/material/Typography'
import TextTruncate, { TextTruncateProps } from 'react-text-truncate'

type TruncatedTextProps = { line: TextTruncateProps['line']; text: string } & Omit<TypographyProps, 'children'>

const TruncatedText: React.FC<TruncatedTextProps> = ({ text, line, ...typographyProps }) => {
  const [isTruncated, setIsTruncated] = useState(false)

  return (
    <Tooltip title={isTruncated ? text : undefined}>
      <Typography {...typographyProps}>
        <TextTruncate text={text} line={line} onTruncated={() => setIsTruncated(true)} />
      </Typography>
    </Tooltip>
  )
}

export default TruncatedText
