import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import ProviderRiskCard from '@app/src/pages/ResourceCollection/Collections/ProviderRisk/ProviderRiskCard'
import { FinalRiskRating } from '@app/src/types/categories'
import { RiskPrioritizationSummary } from '@app/src/types/resourceExplorer'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerViewRiskAssessment from './DrawerViewRiskAssessment'

type DrawerViewProviderRiskCardProps = {
  riskPrioritizationSummary?: RiskPrioritizationSummary
} & Omit<DrawerViewProps, 'title' | 'subtitle'>

const DrawerViewProviderRiskCard: React.FC<DrawerViewProviderRiskCardProps> = ({
  riskPrioritizationSummary,
  ...props
}) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.providerRisk.riskCard.title' })}
      subTitle={riskPrioritizationSummary?.riskPrioritization.provider.name}
      buttons={[
        {
          label: formatMessage({ id: 'schemas.providerRisk.riskCard.updateRiskAssessment' }),
          variant: 'contained',
          onClick: () =>
            openDrawer(
              <DrawerViewRiskAssessment
                providerIds={[riskPrioritizationSummary?.riskPrioritization.provider.id ?? 0]}
                subTitle={riskPrioritizationSummary?.riskPrioritization.provider.name}
                finalRiskRating={
                  riskPrioritizationSummary?.riskPrioritization.provider.finalRiskRating ?? FinalRiskRating.NotSelected
                }
              />,
            ),
        },
      ]}
      {...props}
    >
      <ProviderRiskCard riskPrioritizationSummary={riskPrioritizationSummary} />
    </DrawerView>
  )
}

export default DrawerViewProviderRiskCard
