import { Stack } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { capitalize } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { User } from '@app/src/types/resourceExplorer'
import { NotificationSeverity, Roles } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'

export type DrawerViewEditUserProps = {
  user: User
} & Omit<DrawerViewProps, 'title'>

type UpdateUserBody = {
  organizationId?: number
  solutionType?: string
} & Pick<User, 'email' | 'role'>

const DrawerViewEditUser: React.FC<DrawerViewEditUserProps> = ({ user, ...props }) => {
  const { closeDrawer } = useDrawer()
  const { account } = useAccount()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { solution } = useAuthentication().scope
  const { mutateAsync: mutateRole, isLoading: isMutatingRole } = useUpdateResource<UpdateUserBody>()
  const { handleSubmit, control } = useForm<Pick<User, 'role'>>({
    defaultValues: {
      role: user.role,
    },
  })

  const onSubmit = async (values: User): Promise<void> => {
    const colleague: UpdateUserBody = {
      email: user.email,
      role: values.role,
      organizationId: account?.organization?.id,
      solutionType: capitalize(solution),
    }

    await mutateRole(
      { url: endpoints.saveSolutionMembership, body: colleague },
      {
        onSuccess: () => {
          //The colleagues doesn't update directly so adding a timeout solves this.
          setTimeout(() => {
            showSnackbar({
              message: formatMessage({ id: 'notifications.successfulUpdateRole' }),
              severity: NotificationSeverity.success,
            })

            queryClient.invalidateQueries(FetchKey.Colleague)
            closeDrawer()
          }, 1000)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'general.edit' })}
      subTitle={user.name}
      buttons={[
        {
          label: formatMessage({ id: 'reporting.submit' }),
          variant: 'contained',
          onClick: handleSubmit(onSubmit),
          loading: isMutatingRole,
        },
      ]}
      {...props}
      onFormSubmit={handleSubmit(onSubmit)}
    >
      <Stack p={2} gap={2}>
        <Select
          name="role"
          fieldLabel={formatMessage({ id: 'schemas.user.role' })}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          disabled={isMutatingRole}
          control={control}
          required
          enableAutoSelect
          options={[
            { value: capitalize(Roles.Admin), label: formatMessage({ id: 'roles.admin' }) },
            { value: capitalize(Roles.Contributor), label: formatMessage({ id: 'roles.contributor' }) },
          ]}
          defaultValue={user?.role.toLowerCase()}
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewEditUser
