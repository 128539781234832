import Avatar, { AvatarProps } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { Image } from '@app/src/types/resourceExplorer'
import { capitalize } from '@app/src/utils/helpers'

type PersonAvatarProps = {
  name: string
  disabled?: boolean
  imageUrl?: Image['url']
} & Omit<AvatarProps, 'children' | 'src'>

const PersonAvatar: React.FC<PersonAvatarProps> = ({ name, imageUrl, disabled, ...props }) => (
  <Avatar
    variant="circular"
    src={imageUrl}
    sx={[{ bgcolor: disabled ? 'grey.500' : 'secondary.main' }, ...[props?.sx ?? []]].flat()}
    {...props}
  >
    {capitalize(name?.[0])}
  </Avatar>
)

export default PersonAvatar
