import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import React from 'react'
import { useIntl } from 'react-intl'
import { Product, ProductMappingResponse } from '@app/src/types/product'
import { IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { generatePath } from 'react-router'
import paths from '@app/src/wf-constants/paths'

type DrawerViewConnectedResponsesProps = {
  product: Product
  responses: ProductMappingResponse[]
} & Omit<DrawerViewProps, 'title'>

const DrawerViewConnectedResponses: React.FC<DrawerViewConnectedResponsesProps> = ({
  product,
  responses,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.mappingRequest.connectedRequests' })}
      subTitle={product.name}
      {...props}
    >
      <Typography sx={{ mx: 3, mb: 2 }}>
        {formatMessage({ id: 'schemas.mappingRequest.productIsConnectedToFollowingRequests' })}
      </Typography>
      <List>
        {responses.map(response => (
          <ListItem key={response.id}>
            <ListItemButton
              onClick={() =>
                history.push(generatePath(paths.mappingRequest, { id: response.productMappingRequest.id }))
              }
            >
              <ListItemText
                primary={response.productMappingRequest.product.name}
                secondary={`${response.productMappingRequest.creatorOrganization.name} - ${response.productMappingRequest.periodName}`}
              />
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </DrawerView>
  )
}
export default DrawerViewConnectedResponses
