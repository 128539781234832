import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { Provider } from '@app/src/types/organizations'
import React from 'react'
import { useIntl } from 'react-intl'
import EmailPreview from './EmailPreview'

interface InviteProviderEmailPreviewProps {
  provider: Provider
}

function InviteProviderEmailPreview({ provider }: InviteProviderEmailPreviewProps) {
  const { account } = useAccount()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { formatMessage } = useIntl()

  const currentOrg = accessibleOrganizations.find(
    org => org?.organization?.id === account?.organization?.id,
  )?.organization

  const currentOrgName = currentOrg?.name

  if (!provider) return null

  return (
    <EmailPreview
      provider={provider}
      header={formatMessage(
        { id: 'form.connectProviders.emailPreview.invitationHeader' },
        { currentOrgName, providerName: provider.name },
      )}
      description={formatMessage(
        { id: 'form.connectProviders.emailPreview.invitationDescription' },
        { currentOrgName },
      )}
      ctaText={formatMessage({ id: 'form.connectProviders.emailPreview.signUpCTA' })}
    />
  )
}

export default InviteProviderEmailPreview
