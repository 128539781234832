import { useEffect, useRef } from 'react'

function usePrevious<T = undefined>(value: T | undefined): T | undefined {
  const ref = useRef<T | undefined>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePrevious
