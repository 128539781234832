import endpoints from '@app/src/api/endpoints'
import { useUpdateResource } from '@app/src/api/updateHooks'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { BusinessOutlined, FileUpload } from '@mui/icons-material'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import LoadingButton from '../LoadingButton'
import Avatar, { AvatarSize } from '../Ui/Avatar'

interface ImageUploadProps {
  currentImageSrc?: string
  onSuccess?: (fileUrl: string) => void
}

function ImageUpload({ onSuccess, currentImageSrc }: ImageUploadProps) {
  const { formatMessage } = useIntl()
  const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUpdateResource<{ fileUrl: string }, FormData>()
  const { showErrorNotification } = useErrorNotification()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const uploadImage = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    await uploadImageAsync(
      { url: endpoints.uploadImage, body: formData },
      {
        onSuccess: res => {
          onSuccess?.(res.fileUrl)
        },
        onError: error => {
          showErrorNotification({
            requestError: error,
            specificFallbackErrorMessage: formatMessage({ id: 'reporting.uploadFileError' }),
          })
        },
      },
    )
  }

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      await uploadImage(selectedFile)
    }
  }
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" spacing={2}>
        {isUploading ? (
          <Skeleton variant="rounded" height={48} width={48} />
        ) : (
          <Avatar size={AvatarSize.XL} variant="rounded" src={currentImageSrc}>
            <BusinessOutlined />
          </Avatar>
        )}
        <Stack>
          <Typography variant="subtitle1">{formatMessage({ id: 'organizationConfig.yourLogo' })}</Typography>
          <Typography variant="body2">{formatMessage({ id: 'organizationConfig.uploadInfo' })}</Typography>
        </Stack>
      </Stack>
      <Box>
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
        <LoadingButton startIcon={<FileUpload />} onClick={handleFileClick} loading={isUploading}>
          {formatMessage({ id: 'organizationConfig.uploadImage' })}
        </LoadingButton>
      </Box>
    </Stack>
  )
}

export default ImageUpload
