import { HomeOutlined } from '@mui/icons-material'
import { Box, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { generatePath } from 'react-router'
import paths from '@app/src/wf-constants/paths'
import RouterLink from './RouterLink'

interface Crumb {
  label?: string
  to?: string
}

interface SimpleBreadcrumbsProps {
  crumbs: Crumb[]
}

const SimpleBreadcrumbs: React.FC<SimpleBreadcrumbsProps> = ({ crumbs }) => {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      <RouterLink to={generatePath(paths.dashboard)}>
        <Box mt={0.5}>
          <HomeOutlined fontSize="small" />
        </Box>
      </RouterLink>
      {crumbs?.map(crumb => {
        if (crumb.to) {
          return (
            <RouterLink key={crumb.label} to={crumb.to}>
              {crumb.label}
            </RouterLink>
          )
        }
        return <Typography key={crumb.label}>{crumb.label}</Typography>
      })}
    </MuiBreadcrumbs>
  )
}

export default SimpleBreadcrumbs
