import React, { useState } from 'react'
import _ReviewItem from './ReviewItem'
import _StepContainer from './StepContainer'
import Wizard from './Wizard'

export const ReviewItem = _ReviewItem
export const StepContainer = _StepContainer

export const useStepper = (): {
  activeStep: number
  resetStep: () => void
  handleBack: () => void
  handleNext: (e?: React.MouseEvent<HTMLButtonElement>) => void
} => {
  const [activeStep, setActiveStep] = useState(0)

  const resetStep = (): void => {
    setActiveStep(0)
  }

  const handleNext = (e?: React.MouseEvent<HTMLButtonElement>): void => {
    e?.preventDefault?.()
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return { activeStep, resetStep, handleBack, handleNext }
}

export default Wizard
