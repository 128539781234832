import { QuestionStepRenderPropParams } from '@app/src/components/CreateNewRequestModal/Generic/QuestionStep'
import { useMultipleStepsFormContext } from '@app/src/context/MultipleStepsFormContext'
import ProviderField from '@app/src/pages/Product/ProviderField'
import { Provider } from '@app/src/types/organizations'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Props = QuestionStepRenderPropParams

export interface SelectProviderStepFormData {
  provider: Provider | null
}

const SelectProviderStep: React.FC<Props> = ({ enableNext }) => {
  const { control, watch, setValue } = useFormContext<SelectProviderStepFormData>()
  const providersWatch = watch('provider')
  const { values } = useMultipleStepsFormContext()

  useEffect(() => {
    enableNext(Boolean(providersWatch))
  }, [providersWatch])

  useEffect(() => {
    if (values['provider']) {
      setValue('provider', values['provider'])
    }
  }, [values])

  return (
    <form>
      <Controller
        defaultValue={null}
        control={control}
        name="provider"
        render={({ onChange, value }) => <ProviderField onChange={onChange} value={value} />}
      />
    </form>
  )
}

export default SelectProviderStep
