import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useLDClient } from 'launchdarkly-react-client-sdk'

const ONE_HOUR = 60 * 60 * 1000
const ONE_MINUTE = 60 * 1000

interface useFeatureFlagsByAccessorProps {
  featureToggleName: string
  enabled?: boolean
}

const useFeatureFlagsByAccessor = ({ featureToggleName, enabled }: useFeatureFlagsByAccessorProps) => {
  const ldClient = useLDClient()
  const hasOwnAccess: boolean = ldClient?.variation(featureToggleName, false) ?? false

  const { data: anyAccessorHasFeature, isLoading } = useFetchResource<boolean>({
    key: FetchKey.AccessorFeature,
    endpoint: endpoints.anyAccessorHasFeature(featureToggleName),
    options: {
      enabled: enabled && !hasOwnAccess,
      cacheTime: ONE_HOUR,
      staleTime: ONE_MINUTE,
    },
  })

  const orgOrAccessorHasFeature = hasOwnAccess || (anyAccessorHasFeature ?? false)

  return { orgOrAccessorHasFeature, isLoading }
}

export default useFeatureFlagsByAccessor
