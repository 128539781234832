import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import ReactMarkdown, { Options } from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

type MarkdownProps = {
  extraClasses?: {
    text: string
  }
  isSingleLine?: boolean
} & Options

const useStyles = makeStyles({
  root: {
    color: 'inherit',
    whiteSpace: 'pre-wrap',

    '& li': {
      listStylePosition: 'inside',
    },
    '& p, & h1, & h2': {
      margin: 0,
    },
    '& ul, & ol': {
      margin: 0,
    },
  },
})

const Markdown = ({ extraClasses, ...props }: MarkdownProps): JSX.Element => {
  const classes = useStyles()

  return (
    <ReactMarkdown
      {...props}
      remarkPlugins={[remarkBreaks, remarkGfm]}
      linkTarget={props.linkTarget ?? '_blank'}
      className={classNames(classes.root, extraClasses?.text)}
    />
  )
}

export default Markdown
