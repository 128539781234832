import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import MultipleStepsFormContextProvider from '@app/src/context/MultipleStepsFormContextProvider'
import React, { Children, PropsWithChildren, useEffect } from 'react'

const QuestionSteps: React.FC<PropsWithChildren> = ({ children }) => {
  const { activeStep, setTotalSteps } = useCreationModalProgress()
  const childrenCount = Children.count(children)
  const childrenArray = Children.toArray(children)
  const stepToRender = activeStep - 1 < childrenCount ? activeStep - 1 : 0

  useEffect(() => {
    setTotalSteps(childrenCount)
  }, [childrenCount])

  return (
    <MultipleStepsFormContextProvider>
      <>{childrenArray[stepToRender]}</>
    </MultipleStepsFormContextProvider>
  )
}

export default QuestionSteps
