import endpoints from '@app/src/api/endpoints'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { palette } from '@app/src/theme/palette'
import { Organization } from '@app/src/types/organizations'
import { NotificationSeverity } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import { CONFIGURATION_PAGE_IDS } from '../../ConfigurationsScene'

type OrganizationDeleteDialogProps = {
  organization: Organization
  isOpen: boolean
  closeDialog: () => void
}

const OrganizationDeleteDialog = ({ organization, closeDialog, isOpen }: OrganizationDeleteDialogProps) => {
  const { mutate, isLoading: isDeleting } = useDeleteResource()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const [deleteInputValue, setDeleteInputValue] = useState('')
  const history = useHistory()

  const deleteOrganization = () => {
    mutate(
      { url: endpoints.organizationDelete(organization.id) },
      {
        onSuccess: () => {
          showSnackbar({
            message: `${organization?.name} deleted successfully!`,
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          history.push(
            generatePath(paths.configurations, {
              configurationsPage: CONFIGURATION_PAGE_IDS.Organizations,
            }),
          )
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Danger zone</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Stack spacing={2}>
            <Stack spacing={1} direction="row">
              <WarningAmberIcon sx={{ color: palette.error.dark }} />
              <Typography variant="h6" color={palette.error.dark}>
                This action is irreversible!
              </Typography>
            </Stack>
            <DialogContentText>
              All organization data will be deleted: connected accessors will no longer see the organization as a
              provider, nor their reports or product mapping data.
            </DialogContentText>
            <TextField
              label="Organization to be deleted"
              value={deleteInputValue}
              onChange={e => setDeleteInputValue(e.target.value)}
              fullWidth
            />
            <Typography>
              Type the organization name above in order to proceed: <strong>{organization?.name}</strong>
            </Typography>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={deleteInputValue !== organization?.name || isDeleting}
          loading={isDeleting}
          variant="contained"
          color="error"
          onClick={deleteOrganization}
        >
          Confirm deletion
        </LoadingButton>
        <Button onClick={closeDialog}>{formatMessage({ id: 'general.cancel' })}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrganizationDeleteDialog
