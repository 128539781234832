import { ListItem, ListItemAvatar, ListItemAvatarProps, ListItemProps, ListItemText, Skeleton } from '@mui/material'
import Avatar, { AvatarProps } from '@app/src/components/Ui/Avatar'
import React from 'react'

type ListItemSkeletonProps = {
  howMany?: number
  avatar?: boolean
  listItemProps?: ListItemProps
  listItemAvatarSx?: ListItemAvatarProps['sx']
  avatarProps?: AvatarProps
  primary?: boolean
  secondary?: boolean
}

const ListItemSkeleton = ({
  avatar,
  avatarProps,
  listItemProps,
  listItemAvatarSx,
  primary,
  secondary,
}: Omit<ListItemSkeletonProps, 'howMany'>) => {
  return (
    <ListItem {...listItemProps}>
      {avatar && (
        <ListItemAvatar sx={listItemAvatarSx}>
          <Avatar sx={{ bgcolor: 'grey.300' }} {...avatarProps}>
            <Skeleton variant="rectangular" />
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        primary={primary ? <Skeleton variant="text" width="70%" /> : undefined}
        secondary={secondary ? <Skeleton variant="text" width="30%" /> : undefined}
      />
    </ListItem>
  )
}

const ListItemSkeletonWrapper = ({ howMany = 1, ...props }: ListItemSkeletonProps): JSX.Element => {
  if (howMany > 1) {
    return (
      <>
        {Array.from({ length: howMany }, (_, idx) => (
          <ListItemSkeleton key={idx} {...props} />
        ))}
      </>
    )
  }

  return <ListItemSkeleton {...props} />
}

export default ListItemSkeletonWrapper
