import { AddCommentOutlined, ArrowDropDown, UploadFileOutlined } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import Dialog from '@app/src/components/Dialog'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewProviderComment from '@app/src/components/Drawer/Views/DrawerViewProviderComment'
import LoadingButton from '@app/src/components/LoadingButton'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { Provider } from '@app/src/types/organizations'
import { bytesToMegaBytes } from '@app/src/utils/helpersTs'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { ActivityTabs } from './ActivityOverview'

type AddButtonGroupProps = {
  provider: Provider
  view: ActivityTabs
}

const MAX_UPLOAD_SIZE_IN_MB = 95

const AddButtonGroup: React.FC<AddButtonGroupProps> = ({ view, provider }) => {
  const { formatMessage } = useIntl()
  const { account, trackEvent } = useAmplitude()
  const { openDrawer } = useDrawer()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { mutate: mutateUploadFile, isLoading } = useCreateResource()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [isMaxSizeDialogOpen, openMaxSizeDialog, closeMaxSizeDialog] = useDialogState()

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
      setOpen(false)
    }
  }

  const handleCommentClick = () => openDrawer(<DrawerViewProviderComment provider={provider} />)

  const handleAddClick = () => {
    switch (view) {
      case ActivityTabs.Documents:
        return handleFileClick()
      case ActivityTabs.Comments:
        return handleCommentClick()
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (!selectedFile) return

    if (bytesToMegaBytes(selectedFile.size) > MAX_UPLOAD_SIZE_IN_MB) {
      return openMaxSizeDialog()
    }

    uploadFile(selectedFile)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return

    setOpen(false)
  }

  const uploadFile = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)

    mutateUploadFile(
      { url: endpoints.fileUpload(provider.id, 'Provider'), body: formData },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'resourceExplorer.activity.successfullyUploadedFile' }),
            severity: 'success',
          })
          trackEvent({
            name: AmplitudeTrackingEvents.Accessor.ProviderOrganization.AdditionalInformation,
            eventProps: {
              information_type: 'file',
              added_or_deleted: 'added',
            },
          })
          queryClient.invalidateQueries(FetchKey.FileCollection)
        },
        onError: error => {
          Sentry.captureException(error, {
            tags: { event: 'Error uploading a provider file' },
            user: { id: String(account?.user?.id) },
            extra: { errorDetails: error.message, providerId: String(provider.id) },
          })
          showErrorNotification({
            requestError: error,
            specificFallbackErrorMessage: formatMessage({ id: 'reporting.uploadFileError' }),
          })
        },
      },
    )
  }

  return (
    <>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />

      <ButtonGroup ref={anchorRef}>
        <LoadingButton variant="contained" onClick={handleAddClick} loading={isLoading}>
          {formatMessage({ id: 'general.add' })}
        </LoadingButton>
        <Button size="small" variant="contained" onClick={handleToggle} disabled={isLoading}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper sx={{ zIndex: 3 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={handleFileClick}>
                    <ListItemIcon sx={{ pr: 4 }}>
                      <UploadFileOutlined />
                    </ListItemIcon>
                    {formatMessage({ id: 'general.file' })}
                  </MenuItem>
                  <MenuItem onClick={handleCommentClick}>
                    <ListItemIcon sx={{ pr: 4 }}>
                      <AddCommentOutlined />
                    </ListItemIcon>
                    {formatMessage({ id: 'general.comment' })}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Dialog
        open={isMaxSizeDialogOpen}
        title={formatMessage({ id: 'resourceExplorer.activity.maxFileSizeTitle' })}
        content={formatMessage(
          { id: 'resourceExplorer.activity.maxFileSizeContent' },
          { fileSize: MAX_UPLOAD_SIZE_IN_MB },
        )}
        onClose={closeMaxSizeDialog}
      />
    </>
  )
}

export default AddButtonGroup
