import React from 'react'

const InitiativesIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="M21.316 4.442a11.96 11.96 0 0 0-9.14-4.438C12.12.002 12.063 0 12.005 0c-.058 0-.114.002-.172.004a11.961 11.961 0 0 0-9.123 4.42 11.963 11.963 0 0 0 .094 15.268l.002.002a11.959 11.959 0 0 0 9.372 4.303 11.96 11.96 0 0 0 9.139-4.438 11.968 11.968 0 0 0 0-15.117Zm-2.657 7.23a19.915 19.915 0 0 0-.763-5.256 13.045 13.045 0 0 0 3.035-1.408 11.28 11.28 0 0 1 2.412 6.664h-4.684ZM12.332.673c2.005.206 3.749 2.276 4.756 5.307-1.55.425-3.148.65-4.756.67V.674Zm-.656 5.978a18.865 18.865 0 0 1-4.755-.671C7.928 2.95 9.67.88 11.676.673v5.978Zm0 .656v4.365h-5.67c.018-1.713.26-3.416.722-5.066a19.53 19.53 0 0 0 4.948.701Zm0 5.022v4.365c-1.673.02-3.336.256-4.948.701a19.577 19.577 0 0 1-.722-5.066h5.67Zm0 5.021v5.978c-2.004-.206-3.748-2.277-4.755-5.307a18.862 18.862 0 0 1 4.755-.67Zm.656 0c1.608.02 3.206.246 4.756.671-1.007 3.03-2.751 5.1-4.756 5.307V17.35Zm0-.656v-4.365h5.67a19.59 19.59 0 0 1-.721 5.068 19.375 19.375 0 0 0-4.949-.703Zm0-5.022V7.307a19.376 19.376 0 0 0 4.949-.703 19.59 19.59 0 0 1 .722 5.068h-5.67Zm8.176-7.174c-.879.548-1.822.983-2.81 1.296-.72-2.141-1.801-3.844-3.09-4.836a11.363 11.363 0 0 1 5.9 3.54ZM9.4.958c-1.288.992-2.37 2.695-3.09 4.836A12.569 12.569 0 0 1 3.5 4.5c1.553-1.763 3.614-3 5.9-3.542ZM3.077 5.006a13.052 13.052 0 0 0 3.036 1.412 19.889 19.889 0 0 0-.763 5.254H.666c.067-2.423.912-4.76 2.411-6.666ZM5.35 12.33c.018 1.777.275 3.544.763 5.254v.001c-1.069.337-2.09.811-3.036 1.41A11.286 11.286 0 0 1 .666 12.33H5.35ZM3.5 19.5c.88-.546 1.824-.98 2.81-1.294.72 2.141 1.802 3.844 3.09 4.836A11.348 11.348 0 0 1 3.5 19.5Zm11.109 3.542c1.288-.992 2.37-2.695 3.09-4.836.986.313 1.93.748 2.809 1.296a11.363 11.363 0 0 1-5.9 3.54Zm6.322-4.05a13.048 13.048 0 0 0-3.035-1.409c.488-1.71.744-3.477.763-5.255h4.684a11.28 11.28 0 0 1-2.412 6.664Z"
          fill="#131315"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InitiativesIcon
