import OptionBox from '@app/src/components/Ui/OptionBox'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import RequestAutomationWarningAlert from '@app/src/pages/Configurations/ConfigurationsPages/Automation/Warning/RequestAutomationWarningAlert'
import { Stack, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'
import DrawerViewAddProviderPlaceholder from './DrawerViewAddProviderPlaceholder'
import DrawerViewAddProviderReferral from './DrawerViewAddProviderReferral'
import DrawerViewFindProvider from './DrawerViewFindProvider'

type DrawerViewAddProviderProps = {
  providerType: string
} & Omit<DrawerViewProps, 'title'>

const DrawerViewAddProvider: React.FC<DrawerViewAddProviderProps> = ({ providerType, ...props }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <DrawerView
      title={formatMessage(
        { id: 'resourceCollections.create.addProvider' },
        { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }).toLowerCase() },
      )}
      {...props}
    >
      <Stack px={2} flexGrow={1}>
        <Typography paragraph>
          {formatMessage(
            { id: 'resourceCollections.create.addProviderDescription' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }).toLowerCase() },
          )}
        </Typography>
        <RequestAutomationWarningAlert
          automationType={RequestAutomationType.FOR_EVERY_NEW_PROVIDER}
          providerType={providerType}
        />
        <OptionBox
          title={formatMessage(
            { id: 'resourceCollections.create.findProvider' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 2 }).toLowerCase() },
          )}
          description={formatMessage({ id: 'resourceCollections.create.findProviderDescription' })}
          onClick={() => openDrawer(<DrawerViewFindProvider providerType={providerType} />)}
          flexGrow={2}
          data-testid="find-suppliers"
        />
        <OptionBox
          title={formatMessage({ id: 'resourceCollections.create.invitation' })}
          description={formatMessage(
            { id: 'resourceCollections.create.invitationDescription' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 2 }).toLowerCase() },
          )}
          onClick={() => openDrawer(<DrawerViewAddProviderReferral providerType={providerType} />)}
          flexGrow={2}
          data-testid="send-invitation-link"
        />
        <OptionBox
          title={formatMessage(
            { id: 'resourceCollections.create.createProviderPlaceholder' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }).toLowerCase() },
          )}
          description={formatMessage({ id: 'resourceCollections.create.createProviderPlaceholderDescription' })}
          onClick={() => openDrawer(<DrawerViewAddProviderPlaceholder providerType={providerType} />)}
          small
          data-testid="create-supplier-link-later"
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddProvider
