import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import { Option } from '@app/src/components/Form/Select/SimpleSelect'
import { ListboxComponent } from '@app/src/components/Form/SelectVirtualList'
import TextField from '@app/src/components/Ui/TextField'
import { Auth0User } from '@app/src/pages/Configurations/ConfigurationsPages/Users/UsersConfig'
import { Organization } from '@app/src/types/organizations'
import { Autocomplete } from '@mui/material'
import React from 'react'

interface Props {
  setValue: (organization: Organization | null) => void
  value: Organization | null
  user: Auth0User
}

const OrganizationSelect: React.FC<Props> = ({ setValue, value, user }) => {
  const { items, isLoading } = useFetchCollectionWithPost<Organization>({
    endpoint: endpoints.organizationsCollection,
    key: FetchKey.Organization,
    payload: {
      filter: [],
      pagination: { itemsPerPage: 1000000, pageNumber: 1 },
      include: ['AssignedSolutions'],
    },
  })
  const options: Option<number>[] = items?.reduce<Option<number>[]>((options, org) => {
    if (user.appMetadata.accounts.find(account => account.organizationId === org.id)) {
      return options
    }
    options.push({
      label: org.name,
      value: org.id,
    })
    return options
  }, [])

  return (
    <Autocomplete
      options={options}
      disableListWrap
      getOptionLabel={option => option.label}
      ListboxComponent={ListboxComponent}
      onChange={(_, option) => setValue(items.find(item => item.id === option?.value) ?? null)}
      value={options.find(option => option.value === value?.id) ?? null}
      loading={isLoading}
      renderInput={(params): JSX.Element => (
        <TextField variant="outlined" label="Organization" {...params} translate="no" />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <OptionIcon selected={selected} multiple={false} />
            {option?.label || ''}
            <OptionAdornment option={option} />
          </li>
        )
      }}
    />
  )
}

export default OrganizationSelect
