import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Invitation } from '@app/src/types/resourceExplorer'
import { Box } from '@mui/material'
import React from 'react'
import InvitationHeader from './InvitationHeader'
import InvitationRow from './InvitationRow'

const InvitationsTable: React.FC = () => {
  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Invitation>({
    key: FetchKey.Invitation,
    endpoint: endpoints.invitations,
    payload: {
      filter: [
        {
          name: 'createdByMe',
          filters: [
            {
              value: true,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'status',
          filters: [
            {
              value: ['Sent', 'Opened'],
              operator: Operators.In,
            },
          ],
        },
      ],
      include: ['*'],
      sort: {
        order: SortOrder.ASCENDING,
        target: 'name',
      },
    },
  })

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" height={100}>
      <Table<Invitation>
        HeaderComponent={InvitationHeader}
        RowComponent={InvitationRow}
        count={count}
        data={items}
        isLoading={isLoading || isFetching}
        noPagination
        isError={isError}
        page={1}
        pageSize={count}
      />
    </Box>
  )
}

export default InvitationsTable
