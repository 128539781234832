import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, BoxProps, Button, Collapse, CollapseProps, Typography } from '@mui/material'
import React, { FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { insertObjIf } from '@app/src/utils/helpersTs'

interface CollapseWithEllipsisProps {
  defaultVisible?: boolean
  boxProps?: BoxProps
  collapsedSize?: CollapseProps['collapsedSize']
  numberOfLines?: string
  footer?: ReactNode
  isVisible?: boolean
  setIsVisible?: (isVisible: boolean) => void
}

const CollapseWithEllipsis: FC<PropsWithChildren<CollapseWithEllipsisProps>> = ({
  children,
  defaultVisible = true,
  boxProps = {},
  collapsedSize = '6rem',
  numberOfLines = '3',
  footer,
  isVisible: isVisibleProp,
  setIsVisible: setIsVisibleProp,
}) => {
  const [isVisibleState, setIsVisibleState] = useState(defaultVisible)
  const [isShowMoreButtonEnabled, setIsShowMoreButtonEnabled] = useState(false)
  const [isEllipsisDisplayed, setIsEllipsisDisplayed] = useState(!defaultVisible)
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLParagraphElement | null>(null)
  const isVisible = typeof isVisibleProp !== 'undefined' ? isVisibleProp : isVisibleState
  const setIsVisible = setIsVisibleProp ?? setIsVisibleState

  const show = () => {
    setIsEllipsisDisplayed(false)
    setIsVisible(true)
  }

  const hide = () => {
    setIsVisible(false)
  }

  useEffect(() => {
    setIsShowMoreButtonEnabled(
      (ref?.current?.scrollHeight ?? 0) > 12 * parseFloat(getComputedStyle(document.documentElement).fontSize),
    )
  }, [ref, setIsShowMoreButtonEnabled])

  return (
    <Box {...boxProps}>
      <Collapse
        in={isVisible}
        onExited={() => {
          if (!isVisible) {
            setIsEllipsisDisplayed(true)
          }
        }}
        collapsedSize={collapsedSize}
      >
        <Typography
          ref={ref}
          sx={{
            display: '-webkit-box',
            ...insertObjIf(isEllipsisDisplayed, {
              WebkitLineClamp: numberOfLines,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }),
          }}
        >
          {children}
        </Typography>
        {footer}
      </Collapse>
      {isShowMoreButtonEnabled && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button onClick={isVisible ? hide : show} endIcon={isVisible ? <ExpandLess /> : <ExpandMore />}>
            {formatMessage({ id: isVisible ? 'general.showLess' : 'general.showMore' })}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CollapseWithEllipsis
