import { Box, Button, ButtonGroup, ButtonProps, Stack } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, Redirect, Route, Switch, useHistory, useParams } from 'react-router'
import { Provider } from '@app/src/types/organizations'
import paths from '@app/src/wf-constants/paths'
import AddButtonGroup from './AddButtonGroup'
import ProviderCommentCollection from './Comments/ProviderCommentCollection'
import ProviderDocumentCollection from './Documents/ProviderDocumentCollection'
import ProviderRiskAssessmentCollection from './RiskAssessment/ProviderRiskAssessmentCollection'

export enum ActivityTabs {
  Documents = 'documents',
  Comments = 'comments',
  Log = 'log',
}

type ActivityOverviewProps = {
  provider: Provider
}

interface ActivityOverviewParams {
  activeTabParam: string
  view: ActivityTabs
  resourceType: string
  resourceId: string
}

type ButtonTab = {
  type: ActivityTabs
  url: string
  variant: ButtonProps['variant']
  label: string
}

const ActivityOverview: React.FC<ActivityOverviewProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { resourceType, resourceId, activeTabParam, view } = useParams<ActivityOverviewParams>()

  const buttonTabs: ButtonTab[] = [
    {
      type: ActivityTabs.Documents,
      url: generatePath(paths.resourceExplorer, {
        resourceType,
        resourceId,
        activeTabParam,
        view: ActivityTabs.Documents,
      }),
      variant: view === ActivityTabs.Documents ? 'contained' : 'outlined',
      label: formatMessage({ id: 'resourceExplorer.activity.documents' }),
    },
    {
      type: ActivityTabs.Comments,
      url: generatePath(paths.resourceExplorer, {
        resourceType,
        resourceId,
        activeTabParam,
        view: ActivityTabs.Comments,
      }),
      variant: view === ActivityTabs.Comments ? 'contained' : 'outlined',
      label: formatMessage({ id: 'resourceExplorer.activity.comments' }),
    },
    {
      type: ActivityTabs.Log,
      url: generatePath(paths.resourceExplorer, {
        resourceType,
        resourceId,
        activeTabParam,
        view: ActivityTabs.Log,
      }),
      variant: view === ActivityTabs.Log ? 'contained' : 'outlined',
      label: formatMessage({ id: 'resourceExplorer.activity.log' }),
    },
  ]

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.resourceExplorer, {
          resourceType,
          resourceId,
          activeTabParam,
          view: ActivityTabs.Documents,
        })}
      />
    )
  }

  return (
    <Stack bgcolor="grey.50" flexGrow={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2} px={4}>
        <ButtonGroup>
          {buttonTabs.map(tab => (
            <Button key={tab.type} variant={tab.variant} onClick={() => history.push(tab.url)}>
              {tab.label}
            </Button>
          ))}
        </ButtonGroup>
        {view !== ActivityTabs.Log && <AddButtonGroup view={view} provider={provider} />}
      </Box>

      <Switch>
        <Route
          path={generatePath(paths.resourceExplorer, {
            resourceType,
            resourceId,
            activeTabParam,
            view: ActivityTabs.Documents,
          })}
        >
          <ProviderDocumentCollection embedded providerId={provider.id} />
        </Route>
        <Route
          path={generatePath(paths.resourceExplorer, {
            resourceType,
            resourceId,
            activeTabParam,
            view: ActivityTabs.Comments,
          })}
        >
          <ProviderCommentCollection embedded providerId={provider.id} />
        </Route>
        <Route
          path={generatePath(paths.resourceExplorer, {
            resourceType,
            resourceId,
            activeTabParam,
            view: ActivityTabs.Log,
          })}
        >
          <ProviderRiskAssessmentCollection providerId={provider.id} embedded />
        </Route>
      </Switch>
    </Stack>
  )
}

export default ActivityOverview
