import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useUpdateResource, useUpdateResourceWithoutBody } from '@app/src/api/updateHooks'
import { formatDate } from '@app/src/components/Form/DatePicker'
import RouteLeavingGuard from '@app/src/components/RouteLeavingGuard'
import WfLoader from '@app/src/components/WfLoader'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { RequestItemWithOrderNumbers } from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/Flags/ReportFlagRules'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ActivationType, ReportFlagRule, ReportFlagRuleBulkModel, ReportFlagType } from '@app/src/types/flags'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, NotificationSeverity, QuestionTypes } from '@app/src/wf-constants'
import { Box, Button, FormControlLabel, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material'
import { maxBy } from 'lodash'
import markdownToTxt from 'markdown-to-txt'
import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import SustainabilityLibraryCollection from '../../SustainabilityLibraryCollection'
import CreateAutomationContent from './CreateAutomationContent'

interface CreateAutomationProps {
  requestItemTemplateId: string
}

type FormData = {
  rules: FormDataRules[]
}

export type FormDataRules = Omit<ReportFlagRule, 'id'> & {
  ruleId: number
}

function checkForDuplicateArrays<T>(arrayOfArrays: T[][]): boolean {
  const seen = new Set<string>()

  for (const subArray of arrayOfArrays) {
    const sortedSubArrayString = JSON.stringify([...subArray].sort())
    if (seen.has(sortedSubArrayString)) {
      return true
    }
    seen.add(sortedSubArrayString)
  }

  return false
}

const hasNoConditionType = (data: FormData): boolean => {
  return data.rules.some(rule => !rule.conditionType)
}

const CreateAutomation: React.FC<CreateAutomationProps> = ({ requestItemTemplateId }) => {
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { trackEvent } = useAmplitude()
  const history = useHistory()
  const formMethods = useForm<FormData>({ shouldUnregister: false, mode: 'onChange' })
  const {
    handleSubmit,
    setError,
    reset,
    control,
    formState: { isDirty },
  } = formMethods
  const { fields, append, remove } = useFieldArray<Partial<FormDataRules>>({
    control,
    name: 'rules',
  })
  const [isFormChanged, setFormChanged] = useState(false)
  const [isRulesActive, setIsRulesActive] = useState<boolean | undefined>()

  const { mutateAsync: saveAsync, isLoading: isLoadingSave } = useUpdateResource<
    ReportFlagRuleBulkModel,
    Partial<ReportFlagRuleBulkModel>
  >()

  const { items: requestTemplates, isFetching } = useFetchCollectionWithPost<QuestionnaireTemplate>({
    payload: {
      filter: [
        { name: 'sections.questions.id', filters: [{ value: requestItemTemplateId, operator: Operators.EqualTo }] },
      ],
      include: [
        'sections.questions.questionType',
        'sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskGuidanceLinks',
        'sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskNaceCodes.naceCode',
        'sections.questions.requestCollection',
      ],
      pagination: { pageNumber: 1, itemsPerPage: 1 },
    },
    endpoint: endpoints.organizationOwnedTemplates,
    key: [FetchKey.RequestTemplateByItemId, requestItemTemplateId],
    options: {
      enabled: Boolean(requestItemTemplateId),
    },
  })

  const { mutateAsync: mutateActivateOrDeactivateAsync, isLoading: isLoadingActivateOrDeactivate } =
    useUpdateResourceWithoutBody<ReportFlagRule>({
      method: 'get',
      options: {
        onSuccess: () => {
          setIsRulesActive(prev => !prev)
          queryClient.invalidateQueries([FetchKey.RequestTemplateByItemId, requestItemTemplateId])
          queryClient.invalidateQueries(FetchKey.OrganizationOwnedTemplates)
        },
      },
    })

  const save = async (values: FormData) => {
    const checkValuesKeys = values.rules.map(r =>
      (Array.isArray(r.checkValue) ? r.checkValue : [r.checkValue]).map(v => v?.key),
    )
    if (checkForDuplicateArrays(checkValuesKeys)) {
      showSnackbar({
        message: formatMessage({ id: 'schemas.notification.flagRepeatedError' }),
        severity: NotificationSeverity.error,
        disableAutoClose: true,
      })
      return
    }
    if (hasNoConditionType(values)) {
      showSnackbar({
        message: formatMessage({ id: 'schemas.notification.flagEmptyError' }),
        severity: NotificationSeverity.error,
        disableAutoClose: true,
      })
      return
    }
    await saveAsync(
      {
        body: {
          activationType: isRulesActive ? ActivationType.Active : ActivationType.Deactivated,
          questionId: Number(requestItemTemplateId),
          reportFlagRules: values.rules.map(rule => ({
            ...rule,
            id: rule.ruleId,
            checkValue: [rule.checkValue].flat().map(r => r ?? { key: '', flag: ReportFlagType.Red, value: '' }),
          })),
        },
        url: endpoints.saveReportFlag,
      },
      {
        onSuccess: async () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceSave' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          await queryClient.invalidateQueries(FetchKey.RequestTemplateByItemId)
          await queryClient.invalidateQueries(FetchKey.OrganizationOwnedTemplates, {
            refetchInactive: true,
          })
          setFormChanged(false)
        },
        onError: error => {
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
            return
          }
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
        onSettled: () => {
          trackEvent({
            name: AmplitudeTrackingEvents.Analyze.CustomFlag.Saved,
            eventProps: {
              flag_active: Boolean(isRulesActive),
            },
          })
        },
      },
    )
  }

  const requestTemplate = requestTemplates?.[0]
  const requestItemTemplate: RequestItemWithOrderNumbers | undefined = requestTemplate?.sections
    ?.flatMap((s, sectionNumber) => s.questions.map((ri, itemNumber) => ({ ...ri, sectionNumber, itemNumber })))
    .find(ri => ri.id === parseInt(requestItemTemplateId))
  const latestUpdatedRule = maxBy(requestItemTemplate?.reportFlagRules, rule => rule.updatedAt)

  //We have to do this because of a limitation in the current react-hook-form when using UseFieldArray.
  //The id property is used internally by react-hook-form and we need rename it to avoid conflicts.
  const renameIdPropsToRuleId = (reportFlagRules: ReportFlagRule[]) =>
    reportFlagRules?.map(rule => {
      const { id, ...mappedRule } = { ...rule, ruleId: rule.id }
      return mappedRule
    }) ?? []

  useEffect(() => {
    if (!isFetching && requestItemTemplate?.reportFlagRules) {
      reset({ rules: renameIdPropsToRuleId(requestItemTemplate.reportFlagRules) })
    }
  }, [requestItemTemplate?.reportFlagRules, isFetching])

  useEffect(() => {
    setFormChanged(isDirty)
  }, [isDirty])

  const activated = requestItemTemplate?.reportFlagRules?.[0]?.activationType === ActivationType.Active

  useEffect(() => {
    setIsRulesActive(activated)
  }, [activated])

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.CustomFlag.Opened,
    })
  }, [])

  const isLoading = isLoadingSave || isFetching || isLoadingActivateOrDeactivate

  if (!requestItemTemplate || isLoading) return <WfLoader />

  return (
    <FormProvider {...formMethods}>
      <Box component="form" width="100%" onSubmit={handleSubmit(values => save(values))}>
        <SustainabilityLibraryCollection
          title={formatMessage({ id: 'automations.flagRisks' })}
          secondaryTitle={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box display="flex">
                <Typography variant="h6" mr={1}>
                  {requestItemTemplate.sectionNumber + 1}.{requestItemTemplate.itemNumber + 1}
                </Typography>
                <Typography variant="h6" fontWeight={500}>
                  {markdownToTxt(requestItemTemplate?.questionText)}
                </Typography>
              </Box>

              {latestUpdatedRule?.updatedAt && (
                <Typography color="textSecondary">
                  {formatMessage(
                    { id: 'automations.lastEdited' },
                    { date: formatDate({ date: new Date(latestUpdatedRule.updatedAt), withoutTime: true }) },
                  )}
                </Typography>
              )}
            </Stack>
          }
          actionButtons={[
            {
              label: formatMessage({ id: 'general.save' }),
              variant: 'contained',
              type: 'submit',
              disabled: !isFormChanged,
            },
          ]}
          enablePadding={{ right: true }}
          secondaryAction={
            <Controller
              name="activationType"
              render={() => (
                <Tooltip title={isDirty ? formatMessage({ id: 'automations.cannotToggleUntilSaved' }) : ''}>
                  <FormControlLabel
                    onChange={() =>
                      mutateActivateOrDeactivateAsync({
                        url: isRulesActive
                          ? endpoints.deactivateReportFlag(requestItemTemplateId)
                          : endpoints.activateReportFlag(requestItemTemplateId),
                      })
                    }
                    disabled={isDirty}
                    control={
                      <Switch
                        disabled={isLoadingActivateOrDeactivate}
                        color="primary"
                        name="activated"
                        checked={isRulesActive}
                      />
                    }
                    label={
                      isRulesActive ? formatMessage({ id: 'automations.on' }) : formatMessage({ id: 'automations.off' })
                    }
                  />
                </Tooltip>
              )}
            />
          }
        >
          <Grid container item xs={6} alignSelf="center" gap={3}>
            {fields.map((item, index) => (
              <Grid
                key={item.id}
                item
                xs={12}
                sx={({ palette, spacing }) => ({
                  backgroundColor: palette.background.default,
                  padding: spacing(4),
                  borderRadius: spacing(2),
                })}
              >
                <CreateAutomationContent
                  requestItem={requestItemTemplate}
                  questionnaireTemplateType={requestTemplate?.questionnaireTemplateType}
                  item={item}
                  index={index}
                  remove={() => remove(index)}
                />
              </Grid>
            ))}

            {(!fields.length || requestItemTemplate?.questionType.name === QuestionTypes.Options) && (
              <Grid item xs={12}>
                <Box bgcolor="grey.50" py={5} px={6} textAlign="center" borderRadius={4}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      append({
                        questionId: Number(requestItemTemplateId),
                        ruleId: 0,
                      })
                      setFormChanged(true)
                    }}
                  >
                    {formatMessage({ id: 'automations.createNew' })}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </SustainabilityLibraryCollection>

        <RouteLeavingGuard
          when={isFormChanged}
          navigate={path => history.push(path)}
          shouldBlockNavigation={() => isFormChanged}
        />
      </Box>
    </FormProvider>
  )
}

export default CreateAutomation
