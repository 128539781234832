import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import paths from '@app/src/wf-constants/paths'
import { AutoAwesomeOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'

const AiFileRepositoryLink = () => {
  const { formatMessage } = useIntl()
  return (
    <Tooltip arrow title={formatMessage({ id: 'configurations.file-repository' })}>
      <IconButton
        sx={{
          p: 2,
          borderRadius: 0,
          height: '100%',
          color: 'communication.dark',
        }}
        component={Link}
        to={generatePath(paths.configurations, { configurationsPage: CONFIGURATION_PAGE_IDS.FileRepository })}
      >
        <AutoAwesomeOutlined color="inherit" />
      </IconButton>
    </Tooltip>
  )
}

export default AiFileRepositoryLink
