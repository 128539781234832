import { PublicOutlined } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import React from 'react'
import { useIntl } from 'react-intl'
import ChipCell, { ChipCellProps } from './ChipCell'
import ParentCellOrFragment from './ParentCellOrFragment'

export type RoleCellProps = {
  value: string
  isPublicContact?: boolean
  disableCell?: boolean
} & ChipCellProps

const RoleCell: React.FC<RoleCellProps> = ({ value, isPublicContact, disableCell, ...props }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Box display="flex" gap={1}>
        <ChipCell value={value} disableCell size="small" {...props} />

        {hasPermission(Permissions.TRANSPARENCY_USER) && isPublicContact && (
          <Tooltip title={formatMessage({ id: 'schemas.user.publicContact' })}>
            <PublicOutlined />
          </Tooltip>
        )}
      </Box>
    </ParentCellOrFragment>
  )
}

export default RoleCell
