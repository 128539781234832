import { Components } from '@mui/material'

const MuiPaper: Components['MuiPaper'] = {
  defaultProps: {
    elevation: 2,
  },
  styleOverrides: {
    root: {
      borderRadius: 8,
    },
  },
}

export default MuiPaper
