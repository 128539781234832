import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import React from 'react'
import { useIntl } from 'react-intl'
import { Contact } from '@app/src/types/resourceExplorer'
import ProductMappingContactsFilters from '../Filters/ProductMappingContactsFilters'
import { FilterGroup } from '../Filters/useFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import ProductMappingContactHeader from './ProductMappingContact/ProductMappingContactHeader'
import ProductMappingContactRow from './ProductMappingContact/ProductMappingContactRow'

const allowedFilters = ['user.id', 'user.name', 'user.email', 'user.phoneNumber']

type ProductMappingContactsCollectionProps = {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
}

const ProductMappingContactsCollection: React.FC<ProductMappingContactsCollectionProps> = ({
  relatedContentFilter = [],
  embedded,
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const userFilters = useGetApiQueryFilters(allowedFilters)

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Contact>({
    key: FetchKey.Contact,
    endpoint: endpoints.contactsCollection,
    payload: {
      filter: [...relatedContentFilter, ...userFilters],
      sort: sorting,
      include: ['user', 'creatorOrganization', 'creatorOrganization.image', 'user.image'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'resourceTypes.contact' })}
      filter={<ProductMappingContactsFilters allowedFilters={allowedFilters} resetPage={resetPage} />}
      embedded={embedded}
    >
      <Table<Contact>
        RowComponent={ProductMappingContactRow}
        HeaderComponent={() => <ProductMappingContactHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
        data={items}
        isLoading={isLoading || isFetching}
        count={count}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollectionScene>
  )
}

export default ProductMappingContactsCollection
