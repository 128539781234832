import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { SfdrRequestTags } from '@app/src/wf-constants'

const useTemplatesThatCanBeSent = (reportType: ReportType) => {
  const { items: wfTemplates, isLoading: isWfTemplatesLoading } = useFetchCollectionWithPost<QuestionnaireTemplate>({
    endpoint: endpoints.worldfavorTemplates,
    key: FetchKey.RequestTemplate,
    payload: {
      filter: [],
      sort: { target: 'title', order: SortOrder.ASCENDING },
      include: ['tags', 'sections', 'sections.questions', 'sections.questions.requestCollection'],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 9999,
      },
    },
    options: { enabled: reportType === ReportType.WF },
  })

  const { items: privateTemplates, isLoading: isPrivateTemplatesLoading } =
    useFetchCollectionWithPost<QuestionnaireTemplate>({
      endpoint: endpoints.requestTemplates,
      key: FetchKey.RequestTemplate,
      payload: {
        sort: { target: 'title', order: SortOrder.ASCENDING },
        filter: [],
        include: ['sections', 'sections.questions', 'sections.questions.requestCollection'],
        pagination: {
          pageNumber: 1,
          itemsPerPage: 10000,
        },
      },
      options: { enabled: reportType === ReportType.COMPANY },
    })

  const { items: sfdrPrivateTemplates, isLoading: isSfdrPrivateTemplatesLoading } =
    useFetchCollectionWithPost<QuestionnaireTemplate>({
      endpoint: endpoints.requestTemplates,
      key: FetchKey.SFDRPrivateTemplates,
      payload: {
        sort: { target: 'title', order: SortOrder.ASCENDING },
        filter: [
          {
            name: 'tags.tag',
            filters: [
              {
                value: [SfdrRequestTags.Sfdr],
                operator: Operators.In,
              },
            ],
          },
        ],
        include: ['tags', 'sections', 'sections.questions', 'sections.questions.requestCollection'],
      },
      options: { enabled: reportType === ReportType.SFDR_WITH_COMPANIES },
    })

  const { items: sfdrWfTemplates, isLoading: isSfdrWfTemplatesLoading } =
    useFetchCollectionWithPost<QuestionnaireTemplate>({
      endpoint: endpoints.worldfavorTemplates,
      key: FetchKey.SFDRWfTemplates,
      payload: {
        sort: { target: 'title', order: SortOrder.ASCENDING },
        filter: [
          {
            name: 'tags.tag',
            filters: [
              {
                value: [SfdrRequestTags.Sfdr],
                operator: Operators.In,
              },
            ],
          },
        ],
        include: ['tags', 'sections', 'sections.questions', 'sections.questions.requestCollection'],
        pagination: {
          pageNumber: 1,
          itemsPerPage: 9999,
        },
      },
      options: { enabled: reportType === ReportType.SFDR_WITH_COMPANIES },
    })
  const isLoading =
    isWfTemplatesLoading || isPrivateTemplatesLoading || isSfdrPrivateTemplatesLoading || isSfdrWfTemplatesLoading

  if (reportType === ReportType.WF) return { templates: wfTemplates, isLoading }
  if (reportType === ReportType.COMPANY) return { templates: privateTemplates, isLoading }
  if (reportType === ReportType.SFDR_WITH_COMPANIES)
    return { templates: sfdrPrivateTemplates.concat(sfdrWfTemplates), isLoading }
  return { templates: [], isLoading }
}

export default useTemplatesThatCanBeSent
