import React from 'react'

const SupplyChainIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" fill="#131315">
        <path d="M22.688 14.848h-2.11v-1.319a2.77 2.77 0 0 0-2.765-2.766h-1.32v-2.02a.328.328 0 0 0-.328-.327H13.7l-.483-7.125a.328.328 0 0 0-.327-.307h-2.492a.328.328 0 0 0-.327.306l-.484 7.125h-1.7L7.407 1.29a.328.328 0 0 0-.328-.306H4.586a.328.328 0 0 0-.327.306l-.483 7.125H1.313a.328.328 0 0 0-.329.329v13.944a.328.328 0 0 0 .329.328h21.375a.328.328 0 0 0 .328-.328v-7.512a.326.326 0 0 0-.328-.328Zm-4.875-3.428a2.112 2.112 0 0 1 2.109 2.11v1.318h-.99v-1.173a1.267 1.267 0 0 0-1.265-1.266h-1.174v-.99h1.32Zm-1.32 3.428v-1.782h1.173a.61.61 0 0 1 .61.609v1.173h-1.783ZM10.706 1.64h1.878l.46 6.775h-2.798l.46-6.775Zm-5.812 0h1.878l.46 6.775H4.433l.46-6.775ZM1.64 9.072h14.196v13.287h-3.864v-6.15a.328.328 0 0 0-.328-.328H5.832a.328.328 0 0 0-.328.328v6.15H1.641V9.072Zm9.676 13.287H6.16v-5.822h5.157v5.822Zm11.042 0h-5.866v-6.855h5.866v6.855Z" />
        <path d="M7.87 13.675h1.74a.329.329 0 0 0 .328-.328v-1.74a.328.328 0 0 0-.328-.329H7.87a.328.328 0 0 0-.328.328v1.74a.328.328 0 0 0 .327.33Zm.327-1.74h1.085v1.084H8.198v-1.084ZM11.646 13.675h1.74a.328.328 0 0 0 .328-.328v-1.74a.328.328 0 0 0-.328-.329h-1.74a.329.329 0 0 0-.328.328v1.74a.329.329 0 0 0 .328.33Zm.328-1.74h1.084v1.084h-1.084v-1.084ZM5.833 13.675a.328.328 0 0 0 .328-.328v-1.74a.328.328 0 0 0-.328-.329h-1.74a.328.328 0 0 0-.328.328v1.74a.328.328 0 0 0 .328.328l1.74.001Zm-1.412-1.74h1.084v1.084H4.421v-1.084Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SupplyChainIcon
