import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '.'

type DataHubInsightsDetailsHeaderProps = SortableHeaderProps & {
  requestItems?: RequestItem[]
}

const DataHubInsightsDetailsHeader: React.FC<DataHubInsightsDetailsHeaderProps> = ({
  requestItems,
  toggleSorting,
  activeSorting,
}) => {
  const { formatMessage } = useIntl()
  const filters = useGetApiQueryFilters(dataHubAllowedFilters)

  const questionsFilterIds = filters?.find(filter => filter.name === 'requestItem.questionId')?.filters?.[0]?.value

  const responseItemsToShow =
    Array.isArray(questionsFilterIds) && questionsFilterIds.length
      ? requestItems?.filter(requestItem => (questionsFilterIds as string[]).includes(String(requestItem.questionId)))
      : requestItems

  const itemCells = responseItemsToShow?.map((item, index) => (
    <TableHeaderCell maxRows={2} label={item.description} key={`${item}-${index}`} minWidth={TableCellWidth.LARGE} />
  ))
  if (!itemCells?.length) return null

  return (
    <TableRow>
      <TableHeaderCell
        maxRows={2}
        label={formatMessage({ id: 'resourceCollections.general.company' })}
        sorting={{ name: 'request.responderOrganization.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        maxRows={2}
        label={formatMessage({ id: 'statistics.totalRisk' })}
        minWidth={TableCellWidth.SMALL}
        sorting={{ name: 'responseAnalytics.flagCount', toggleSorting, activeSorting }}
      />
      {...itemCells}
    </TableRow>
  )
}

export default DataHubInsightsDetailsHeader
