import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { useDeleteResource } from '@app/src/api/updateHooks'
import endpoints from '@app/src/api/endpoints'
import useErrorNotification from '@app/src/hooks/errorNotification'
import CircularProgress from '@mui/material/CircularProgress'
import { useQueryClient } from 'react-query'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useConfirm } from 'material-ui-confirm'
import { useMenuState } from '@app/src/hooks/mui-hooks'

interface AutomationMenuProps {
  automationId: number
  editAutomation?: () => void
}

const AutomationMenu: React.FC<AutomationMenuProps> = ({ automationId, editAutomation }) => {
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()

  const { formatMessage } = useIntl()
  const { mutateAsync: deleteAutomationAsync, isLoading: isLoadingDelete } = useDeleteResource()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const confirm = useConfirm()

  const performDelete = async () => {
    await deleteAutomationAsync(
      { url: endpoints.deleteAutomation(automationId) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.Automation)
          closeMenu()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const confirmDelete = async () => {
    confirm({
      content: formatMessage({ id: 'automatedSendout.deleteConfirmButton.content' }),
      confirmationText: formatMessage({ id: 'automatedSendout.deleteConfirmButton.confirmationButtonText' }),
    })
      .then(performDelete)
      .catch(() => {
        closeMenu()
      })
  }

  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={closeMenu}>
        <MenuItem onClick={editAutomation}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          {formatMessage({ id: 'general.edit' })}
        </MenuItem>
        <MenuItem onClick={confirmDelete} disabled={isLoadingDelete}>
          <ListItemIcon>{isLoadingDelete ? <CircularProgress size={20} /> : <Delete />}</ListItemIcon>
          {formatMessage({ id: 'general.delete' })}
        </MenuItem>
      </Menu>
    </>
  )
}

export default AutomationMenu
