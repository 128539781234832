import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import DatePicker, { DatePickerProps } from './DatePicker'

export type ControlledDateFieldProps = Omit<DatePickerProps, 'onChange' | 'value'> & {
  control: Control
  name: string
  required?: boolean
  fieldLabel: string
}

export const formatDate = (date: Date | string | null, withoutTime?: boolean): string | null => {
  if (!date) return null
  if (typeof date === 'string') {
    date = new Date(date)
  }
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()
  const year = date.getFullYear()

  if (month?.length < 2) month = '0' + month
  if (day?.length < 2) day = '0' + day

  return withoutTime ? `${year}-${month}-${day}` : `${year}-${month}-${day} 00:00:00`
}

const ControlledDateField: React.FC<ControlledDateFieldProps> = ({
  control,
  name,
  defaultValue,
  required,
  fieldLabel,
  ...props
}) => {
  const { formatMessage } = useIntl()

  return (
    <Controller
      name={name}
      label={fieldLabel}
      defaultValue={defaultValue}
      rules={{ required: required ? formatMessage({ id: 'form.validation.required' }) : false }}
      render={({ onChange, value }): JSX.Element => (
        <DatePicker {...props} value={value} onChange={onChange} label={fieldLabel} />
      )}
      control={control}
    />
  )
}

export default ControlledDateField
