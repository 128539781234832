import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { TabsProps } from '@app/src/components/Tabs'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { generatePath, Route, Switch, useParams } from 'react-router-dom'
import AssessmentCompareScene from './AssessmentCompareScene'
import AssessmentOverviewScene from './AssessmentOverviewScene'

export enum AssessmentViews {
  Overview = 'overview',
  Compare = 'compare',
}

interface AssessmentSceneParams {
  view: AssessmentViews
}

const AssessmentsScene: React.FC = () => {
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { view } = useParams<AssessmentSceneParams>()

  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)

  const actionTabs: TabsProps['tabs'] = [
    {
      type: AssessmentViews.Overview,
      url: stringifyQueryFilters({
        url: generatePath(paths.assessments, { view: AssessmentViews.Overview }),
        queryParams: {},
      }),
      label: formatMessage({ id: 'assessment.tabs.overview' }),
    },
    {
      type: AssessmentViews.Compare,
      url: stringifyQueryFilters({
        url: generatePath(paths.assessments, { view: AssessmentViews.Compare }),
        queryParams: {},
      }),
      label: formatMessage({ id: 'assessment.tabs.compare' }),
    },
  ]

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.Assessment.ViewedPage,
      eventProps: {
        page: view,
      },
    })
  }, [view])

  if (!canAccessAssessmentTemplate) return null

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.assessments, {
          view: AssessmentViews.Overview,
        })}
      />
    )
  }

  return (
    <>
      <Switch>
        <Route path={generatePath(paths.assessments, { view: AssessmentViews.Overview })} exact>
          <AssessmentOverviewScene tabs={{ actionTabs, activeTabParam: view }} />
        </Route>
        <Route path={generatePath(paths.assessments, { view: AssessmentViews.Compare })} exact>
          <AssessmentCompareScene tabs={{ actionTabs, activeTabParam: view }} />
        </Route>
      </Switch>
    </>
  )
}

export default AssessmentsScene
