import { AccountTreeOutlined } from '@mui/icons-material'
import { TableRow } from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewReviewResponseValueChainToCopyToProduct from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewResponseValueChainToCopyToProduct'
import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import MappingRequestStatusCell from '@app/src/components/Table/Cells/MappingRequestStatusCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { Product, ProductMappingRequest } from '@app/src/types/product'

interface ProductRequestHeader {
  row: ProductMappingRequest
  product: Product
  disableValueChainReplacement: boolean
}

const ProductRequestRow: React.FC<ProductRequestHeader> = ({
  row: mappingRequest,
  product,
  disableValueChainReplacement,
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const latestMappingResponse = mappingRequest.productMappingResponses?.sort((a, b) => b.id - a.id)[0]

  return (
    <TableRow>
      <MappingRequestStatusCell value={mappingRequest?.status} dueDate={mappingRequest?.deadline} />
      <CompanyCell onClick={e => e.preventDefault()} drawer company={mappingRequest?.responderOrganization} />
      <DateCell value={latestMappingResponse?.createdAt} />
      <CategoryCell
        value={
          latestMappingResponse?.valueChainStatus
            ? formatMessage({
                id: `schemas.product.mappingStatusValues.${latestMappingResponse.valueChainStatus}`,
              })
            : undefined
        }
      />
      {latestMappingResponse ? (
        <ButtonCell
          variant="text"
          startIcon={<AccountTreeOutlined />}
          label={formatMessage({ id: 'schemas.valueChain.review' })}
          onClick={() =>
            openDrawer(
              <DrawerViewReviewResponseValueChainToCopyToProduct
                organization={mappingRequest.responderOrganization}
                mappingResponse={latestMappingResponse}
                targetProduct={product}
                disableValueChainReplacement={disableValueChainReplacement}
              />,
            )
          }
        />
      ) : (
        <ValueCell value="" />
      )}
    </TableRow>
  )
}

export default ProductRequestRow
