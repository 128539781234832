import { QuestionStepRenderPropParams } from '@app/src/components/CreateNewRequestModal/Generic/QuestionStep'
import TextField from '@app/src/components/Ui/TextField'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

type Props = QuestionStepRenderPropParams
export interface InstructionsFormData {
  instructions?: string
}

const InstructionsStep: React.FC<Props> = ({ enableNext }) => {
  const { formatMessage } = useIntl()
  const { watch, register, errors } = useFormContext<InstructionsFormData>()
  const instructionsWatch = watch('instructions')

  useEffect(() => {
    enableNext(Boolean(instructionsWatch))
  }, [instructionsWatch])

  return (
    <TextField
      label={formatMessage({ id: 'form.createProduct.instructions.fieldLabel' })}
      multiline
      minRows={4}
      maxRows={8}
      inputRef={register({
        maxLength: {
          value: 4000,
          message: formatMessage(
            { id: 'form.createProduct.instructions.errorMessage' },
            { length: instructionsWatch?.length },
          ),
        },
      })}
      error={Boolean(errors?.instructions)}
      helperText={errors?.instructions?.message}
      name="instructions"
      fullWidth
    />
  )
}

export default InstructionsStep
