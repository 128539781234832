import { FlagOutlined } from '@mui/icons-material'
import { Box, ButtonBase, alpha } from '@mui/material'
import Avatar, { AvatarProps } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { insertObjIf } from '@app/src/utils/helpersTs'

type FlagSuggestedRiskAvatarProps = {
  isEnabled?: boolean
  onClick?: () => void
} & AvatarProps

const FlagSuggestedRiskAvatar: React.FC<FlagSuggestedRiskAvatarProps> = ({ size, onClick, isEnabled = true }) => (
  <Box component={onClick ? ButtonBase : undefined} onClick={onClick}>
    <Avatar
      size={size}
      variant="rounded"
      sx={({ palette }) => ({
        color: isEnabled ? 'error.contrastText' : 'common.black',
        backgroundColor: isEnabled ? 'communication.main' : 'grey.400',
        ...insertObjIf(Boolean(onClick), {
          '&:hover': { backgroundColor: alpha(palette.communication.main, 0.7) },
        }),
      })}
    >
      <FlagOutlined />
    </Avatar>
  </Box>
)

export default FlagSuggestedRiskAvatar
