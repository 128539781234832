import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import React from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'
import ProductMappingCompaniesFilters from '../Filters/ProductMappingCompaniesFilters'
import { FilterGroup } from '../Filters/useFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import ProductMappingCompaniesHeader from './ProductMappingCompanies/ProductMappingCompaniesHeader'
import ProductMappingCompaniesRow from './ProductMappingCompanies/ProductMappingCompaniesRow'

type ProductMappingCompaniesCollectionProps = {
  relatedContentFilter?: FilterGroup[]
}

const allowedFilters = ['id', 'name', 'country.id', 'websiteAddress', 'vatNumber', 'registrationNumber']

const ProductMappingCompaniesCollection: React.FC<ProductMappingCompaniesCollectionProps> = ({
  relatedContentFilter = [],
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()

  const userFilters = useGetApiQueryFilters(allowedFilters)

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Provider>({
    key: FetchKey.ProviderCollection,
    endpoint: endpoints.providersCollection,
    payload: {
      filter: [...relatedContentFilter, ...userFilters],
      sort: sorting,
      include: ['country', 'organization.*'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'resourceTypes.provider' })}
      filter={
        <ProductMappingCompaniesFilters
          allowedFilters={allowedFilters}
          implicitFilters={relatedContentFilter}
          resetPage={resetPage}
        />
      }
    >
      <Table<Provider>
        RowComponent={ProductMappingCompaniesRow}
        HeaderComponent={() => <ProductMappingCompaniesHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
        data={items}
        isLoading={isLoading || isFetching}
        count={count}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollectionScene>
  )
}

export default ProductMappingCompaniesCollection
