import { ResponseItem as ResponseItemType } from '@app/src/types/resourceExplorer'

export type ResponseValueTypes = {
  displayAsFile: boolean
  displayAsOptions: boolean
  selectedOptions: string[]
  hasValue: boolean
}

export const shouldDisplayAsFile = (
  questionType: string,
  item: ResponseItemType,
): ResponseValueTypes['displayAsFile'] => {
  return Boolean(questionType.toLowerCase() === 'file' && item?.uploadReferences?.[0])
}

export const shouldDisplayAsOptions = (
  questionType: string,
  item: ResponseItemType,
): ResponseValueTypes['displayAsOptions'] => {
  return questionType.toLowerCase() === 'options' && item?.selectedOptions instanceof Array
}

export const getSelectedOptions = (item: ResponseItemType): ResponseValueTypes['selectedOptions'] => {
  return item?.selectedOptions || []
}

export const hasValue = (questionType: string, item: ResponseItemType): boolean => {
  const displayAsOptions = shouldDisplayAsOptions(questionType, item)
  const selectedOptions = getSelectedOptions(item)
  return displayAsOptions ? Boolean(selectedOptions?.length) : Boolean(item?.answer)
}

export const getResponseValueTypes = (questionType: string, item: ResponseItemType): ResponseValueTypes => {
  return {
    displayAsFile: shouldDisplayAsFile(questionType, item),
    displayAsOptions: shouldDisplayAsOptions(questionType, item),
    selectedOptions: getSelectedOptions(item),
    hasValue: hasValue(questionType, item),
  }
}
