import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useAccount } from '@app/src/context/AccountContext'
import { OrganizationAssessmentSettings } from '@app/src/types/resourceExplorer'

export const useFetchAssessmentSettings = () => {
  const { account } = useAccount()
  const {
    data: assessmentSettings = [],
    isLoading,
    isError,
  } = useFetchResource<OrganizationAssessmentSettings[]>({
    key: FetchKey.OrganizationAssessmentSettings,
    endpoint: endpoints.organizationAssessmentSettings,
    options: {
      enabled: Boolean(account?.organization),
      keepPreviousData: true,
    },
  })

  return {
    assessmentSettings,
    isLoading,
    isError,
  }
}
