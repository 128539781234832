import TextField from '@app/src/components/Ui/TextField'
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from '@mui/material'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { RequestCollectionPicker } from '@app/src/types/resourceExplorer'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useQueryClient } from 'react-query'

type DrawerViewAddPickerProps = {
  assignPicker: (pickerId: number) => void
} & Omit<DrawerViewProps, 'title'>

type FormData = {
  items: { value: string }[]
} & Omit<RequestCollectionPicker, 'permittedOptions'>

const DrawerViewAddPicker: React.FC<DrawerViewAddPickerProps> = ({ assignPicker, ...props }) => {
  const { closeDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const { handleSubmit, errors, register, setValue, control, getValues, reset } = useForm<FormData>({
    defaultValues: { name: '', isGlobal: false, items: [{ value: '' }, { value: '' }] },
  })
  const { fields, append, remove } = useFieldArray<{ value: string }>({
    control,
    name: 'items',
  })
  const { showSnackbar } = useSnackbar()
  const { mutateAsync, isLoading } = useUpdateResource<RequestCollectionPicker, RequestCollectionPicker>()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()

  const onSubmit = async (values: RequestCollectionPicker) => {
    const formData = getValues()
    const submitData: RequestCollectionPicker = {
      ...formData,
      permittedOptions: formData.items.map(p => p.value),
    }
    await mutateAsync(
      {
        url: endpoints.savePicker,
        body: submitData,
      },
      {
        onSuccess: async data => {
          showSnackbar({
            message: formatMessage(
              { id: 'templateBuilder.savePickerSuccess' },
              { b: (chunks: React.ReactNode) => <b>{chunks}</b>, pickerName: values.name },
            ),
            severity: 'success',
          })

          await queryClient.invalidateQueries({
            predicate: query => {
              if (!Array.isArray(query.queryKey)) return query.queryKey === FetchKey.Picker
              return query.queryKey.includes(FetchKey.Picker)
            },
          })

          assignPicker(data.id)
          reset()
          closeDrawer()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const addPickerOption = () => {
    append({ value: '' })
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'templateBuilder.createAPicker' })}
      buttons={[
        {
          onClick: handleSubmit(onSubmit),
          variant: 'contained',
          label: formatMessage({ id: 'templateBuilder.createPicker' }),
          loading: isLoading,
          disabled: isLoading,
        },
      ]}
      onFormSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Stack px={2} mt={2} spacing={3} style={{ overflowX: 'clip' }} data-testid="create-picker-fields">
        <TextField
          fullWidth
          label={formatMessage({ id: 'templateBuilder.nameOfThePicker' })}
          type="text"
          name="name"
          inputRef={register({
            required: formatMessage({ id: 'form.validation.required' }),
          })}
          required
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
          onClear={(): void => setValue('name', '')}
          inputProps={{
            maxLength: 100,
          }}
        />

        <FormControl variant="filled" component="fieldset" required>
          <FormLabel component="legend">{formatMessage({ id: 'templateBuilder.pickerPerimeter' })}</FormLabel>
          <Controller
            name="isGlobal"
            control={control}
            defaultValue="false"
            render={({ onChange, value }): JSX.Element => (
              <RadioGroup onChange={onChange} value={value?.toString()}>
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={formatMessage({ id: 'templateBuilder.organizationSpecific' })}
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={formatMessage({ id: 'templateBuilder.globalWorldfavor' })}
                />
              </RadioGroup>
            )}
          ></Controller>
        </FormControl>

        <FormLabel component="legend">{formatMessage({ id: 'templateBuilder.nameOfTheOptions' })}</FormLabel>

        <Alert variant="filled" severity="info">
          {formatMessage({ id: 'templateBuilder.pickerOptionInfo' })}
        </Alert>

        {fields.map((field, index) => {
          const name = `items[${index}].value`
          const disableDeletion = fields.length <= 1
          return (
            <Stack direction="row" alignItems="center" key={field.id}>
              <TextField
                fullWidth
                defaultValue={field.value}
                label={formatMessage({ id: 'templateBuilder.optionName' }, { number: index + 1 })}
                type="text"
                name={name}
                inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
                required
                error={Boolean(errors?.items?.[index]?.value)}
                helperText={errors?.items?.[index]?.value?.message}
                onClear={(): void => setValue(name, '')}
                data-testid="add-picker-drawer-input"
              />

              <Box ml={1}>
                <Tooltip
                  title={disableDeletion ? formatMessage({ id: 'templateBuilder.mustHaveAtLeastOneOption' }) : ''}
                >
                  <span>
                    <IconButton onClick={() => remove(index)} disabled={disableDeletion}>
                      <DeleteIcon color={disableDeletion ? 'disabled' : 'primary'} fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Stack>
          )
        })}

        <Button onClick={() => addPickerOption()} variant="text">
          {formatMessage({ id: 'templateBuilder.addAnotherOption' })}
        </Button>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddPicker
