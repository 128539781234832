import { ChipProps } from '@mui/material'
import StatusChip from '@app/src/components/StatusChip'
import React from 'react'
import { useIntl } from 'react-intl'
import { AllStatuses, InquiryStatus, MappingRequestStatus } from '@app/src/types/resourceExplorer'
import { isDateDueSoon, isPassedDeadline } from '@app/src/utils'
import ParentCellOrFragment from './ParentCellOrFragment'

interface MappingRequestStatusCellProps {
  value: MappingRequestStatus | AllStatuses
  size?: 'small' | 'medium'
  isProvider?: boolean
  dueDate: string
  disableCell?: boolean
}

const getColor = (value: MappingRequestStatusCellProps['value'], isProvider: boolean): ChipProps['color'] => {
  switch (value) {
    case MappingRequestStatus.DueSoon:
      return 'warning'

    case MappingRequestStatus.Overdue:
      return 'error'

    case InquiryStatus.Requested:
      return isProvider ? 'info' : 'default'

    case InquiryStatus.Submitted:
      return !isProvider ? 'info' : 'default'

    default:
      return 'default'
  }
}

const MappingRequestStatusCell = ({
  value,
  size = 'small',
  isProvider = false,
  dueDate,
  disableCell,
}: MappingRequestStatusCellProps): JSX.Element => {
  const { formatMessage } = useIntl()

  if (value === InquiryStatus.Requested) {
    if (isDateDueSoon(dueDate)) value = MappingRequestStatus.DueSoon
    if (isPassedDeadline(dueDate)) value = MappingRequestStatus.Overdue
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <StatusChip
        label={formatMessage({
          id: `reporting.statuses.due.${value}`,
        })}
        color={getColor(value, isProvider)}
        size={size}
      />
    </ParentCellOrFragment>
  )
}

export default MappingRequestStatusCell
