import React from 'react'

import endpoints from '@app/src/api/endpoints'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { SuggestedGuidanceTypeOfRisk, SuggestedRiskGuidance } from '@app/src/types/flags'
import { NaceCode } from '@app/src/types/organizations'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Box, Typography, useTheme } from '@mui/material'
import { get, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import SuggestedRiskGuidanceLinkBuilder from './SuggestedRiskGuidanceLinkBuilder'

enum SuggestedRiskGuidanceEnum {
  introductionTitle = 'suggestedRiskGuidance.introductionTitle',
  introductionDescription = 'suggestedRiskGuidance.introductionDescription',
  potentialNegativeRiskDescription = 'suggestedRiskGuidance.potentialNegativeRiskDescription',
  typeOfRiskDescription = 'suggestedRiskGuidance.typeOfRiskDescription',
  riskDescription = 'suggestedRiskGuidance.riskDescription',
  regulatoryDescription = 'suggestedRiskGuidance.regulatoryDescription',
  reputationalDescription = 'suggestedRiskGuidance.reputationalDescription',
  potentialNegativeRiskRelatedInformation = 'suggestedRiskGuidance.potentialNegativeRiskRelatedInformation',
  suggestedRiskNaceCodes = 'suggestedRiskGuidance.suggestedRiskNaceCodes',
}

interface SuggestedRiskGuidanceBuilderProps {
  suggestedRiskGuidance?: SuggestedRiskGuidance
  index?: number
}

const SuggestedRiskGuidanceBuilder: React.FC<SuggestedRiskGuidanceBuilderProps> = ({
  suggestedRiskGuidance,
  index,
}) => {
  const { formatMessage } = useIntl()
  const { errors, register, control } = useFormContext()
  const { spacing } = useTheme()
  const startOfName = `rules[${index}]`

  return (
    <>
      <Typography variant="h6" mt={2}>
        {formatMessage({ id: 'automations.suggestedRiskGuidance.header' })}
      </Typography>
      <Box mt={2}>
        <Alert severity="info">{formatMessage({ id: 'automations.suggestedRiskGuidance.infoMessage' })}</Alert>
      </Box>
      <Box mt={3} justifyContent="space-between">
        <Box>
          <Box mt={4} mb={1}>
            <Typography variant="h6">
              {formatMessage({ id: 'automations.suggestedRiskGuidance.introductionHeader' })}
            </Typography>
          </Box>
          <TextField
            inputRef={register({
              maxLength: {
                value: 255,
                message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 255 }),
              },
            })}
            required
            defaultValue={suggestedRiskGuidance?.introductionTitle}
            label={formatMessage({ id: 'automations.suggestedRiskGuidance.introductionTitle' })}
            error={Boolean(get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.introductionTitle}`))}
            helperText={get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.introductionTitle}`)?.message}
            name={`${startOfName}.${SuggestedRiskGuidanceEnum.introductionTitle}`}
            fullWidth
          />
        </Box>
        <Box mt={3} justifyContent="space-between">
          <TextField
            inputRef={register({
              maxLength: {
                value: 255,
                message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 255 }),
              },
            })}
            multiline
            required
            rows={4}
            defaultValue={suggestedRiskGuidance?.introductionDescription}
            label={formatMessage({ id: 'automations.suggestedRiskGuidance.introductionDescription' })}
            error={Boolean(get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.introductionDescription}`))}
            helperText={get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.introductionDescription}`)?.message}
            name={`${startOfName}.${SuggestedRiskGuidanceEnum.introductionDescription}`}
            fullWidth
          />
        </Box>
        <Box mt={3}>
          <Select<{ naceCodeId: number }, NaceCode>
            name={`${startOfName}.${SuggestedRiskGuidanceEnum.suggestedRiskNaceCodes}`}
            control={control}
            multiple
            required
            forceFetch
            defaultValue={suggestedRiskGuidance?.suggestedRiskNaceCodes}
            objectToOption={object => ({ label: object.description, value: { naceCodeId: object.id } })}
            findSelectedValue={(value, option) => value.naceCodeId === option.value.naceCodeId}
            navigation={{
              url: endpoints.naceCodes,
              type: 'post',
              postObject: {
                filter: [{ name: 'parentId', filters: [{ operator: Operators.IsNull }] }],
                include: [],
                sort: { target: 'description', order: SortOrder.ASCENDING },
              },
            }}
            fieldLabel={formatMessage({ id: 'automations.suggestedRiskGuidance.industries' })}
          />
        </Box>
      </Box>
      <Box mt={3} display="flex" justifyContent="space-between" rowGap={spacing(3)} flexDirection="column">
        <Typography variant="h6">
          {formatMessage({ id: 'automations.suggestedRiskGuidance.potentialNegativeRiskHeader' })}
        </Typography>
        <TextField
          inputRef={register({
            maxLength: {
              value: 1000,
              message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 1000 }),
            },
          })}
          multiline
          rows={4}
          required
          defaultValue={suggestedRiskGuidance?.potentialNegativeRiskDescription}
          label={formatMessage({ id: 'automations.suggestedRiskGuidance.potentialNegativeRiskDescription' })}
          error={Boolean(get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.potentialNegativeRiskDescription}`))}
          helperText={
            get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.potentialNegativeRiskDescription}`)?.message
          }
          name={`${startOfName}.${SuggestedRiskGuidanceEnum.potentialNegativeRiskDescription}`}
          fullWidth
        />

        <Select
          name={`${startOfName}.${SuggestedRiskGuidanceEnum.typeOfRiskDescription}`}
          control={control}
          required
          defaultValue={suggestedRiskGuidance?.typeOfRiskDescription ?? SuggestedGuidanceTypeOfRisk.Environmental}
          options={Object.values(SuggestedGuidanceTypeOfRisk).map(value => ({ label: value, value: value }))}
          findSelectedValue={(value, option) => value === option.value}
          fieldLabel={formatMessage({ id: 'automations.suggestedRiskGuidance.riskType' })}
        />

        <TextField
          multiline
          rows={4}
          inputRef={register({
            maxLength: {
              value: 255,
              message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 255 }),
            },
          })}
          defaultValue={suggestedRiskGuidance?.riskDescription}
          label={formatMessage({ id: 'automations.suggestedRiskGuidance.riskDescription' })}
          error={Boolean(get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.riskDescription}`))}
          helperText={get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.riskDescription}`)?.message}
          name={`${startOfName}.${SuggestedRiskGuidanceEnum.riskDescription}`}
          fullWidth
        />

        <TextField
          multiline
          rows={4}
          inputRef={register({
            maxLength: {
              value: 1000,
              message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 1000 }),
            },
          })}
          defaultValue={suggestedRiskGuidance?.regulatoryDescription}
          label={formatMessage({ id: 'automations.suggestedRiskGuidance.regulatoryDescription' })}
          error={Boolean(get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.regulatoryDescription}`))}
          helperText={get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.regulatoryDescription}`)?.message}
          name={`${startOfName}.${SuggestedRiskGuidanceEnum.regulatoryDescription}`}
          fullWidth
        />

        <TextField
          multiline
          rows={4}
          inputRef={register({
            maxLength: {
              value: 1000,
              message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 1000 }),
            },
          })}
          defaultValue={suggestedRiskGuidance?.reputationalDescription}
          label={formatMessage({ id: 'automations.suggestedRiskGuidance.reputationalDescription' })}
          error={Boolean(get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.reputationalDescription}`))}
          helperText={get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.reputationalDescription}`)?.message}
          name={`${startOfName}.${SuggestedRiskGuidanceEnum.reputationalDescription}`}
          fullWidth
        />

        <TextField
          multiline
          required
          rows={4}
          inputRef={register({
            maxLength: {
              value: 1000,
              message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 1000 }),
            },
          })}
          defaultValue={suggestedRiskGuidance?.potentialNegativeRiskRelatedInformation}
          label={formatMessage({
            id: 'automations.suggestedRiskGuidance.potentialNegativeRiskRelatedInformation',
          })}
          error={Boolean(
            get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.potentialNegativeRiskRelatedInformation}`),
          )}
          helperText={
            get(errors, `${startOfName}.${SuggestedRiskGuidanceEnum.potentialNegativeRiskRelatedInformation}`)?.message
          }
          name={`${startOfName}.${SuggestedRiskGuidanceEnum.potentialNegativeRiskRelatedInformation}`}
          fullWidth
        />
      </Box>
      <Box mt={3} rowGap={spacing(3)} justifyContent="space-between">
        <Typography variant="h6" mb={2}>
          {formatMessage({ id: 'automations.suggestedRiskGuidanceLinks.learnMore' })}
        </Typography>
        <Alert severity="info" icon={<InfoOutlinedIcon />}>
          {formatMessage({ id: 'automations.suggestedRiskGuidanceLinks.suggestedRiskGuidanceInfo' })}
        </Alert>
        <SuggestedRiskGuidanceLinkBuilder startOfName={startOfName} />
      </Box>
    </>
  )
}

export default SuggestedRiskGuidanceBuilder
