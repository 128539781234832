import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Schema } from '@app/src/types/schemas'
import { ConstVars } from '@app/src/wf-constants'

const EvaluationsSchema: Schema = {
  name: 'evaluation',
  items: [
    {
      accessor: ConstVars.EvaluationAccessors.Target,
      translationId: 'schemas.evaluation.target',
      exportPath: 'targetObject.name',
      display: {
        export: true,
      },
    },
    {
      accessor: ConstVars.EvaluationAccessors.FrameworkSection,
      translationId: 'schemas.evaluation.frameworkSection',
      exportPath: 'indicator.section.title',
      display: {
        export: true,
      },
    },
    {
      accessor: ConstVars.EvaluationAccessors.FrameworkIndication,
      translationId: 'schemas.evaluation.frameworkIndicator',
      exportPath: 'indicator.description',
      display: {
        export: true,
      },
    },
    {
      accessor: ConstVars.EvaluationAccessors.Value,
      translationId: 'schemas.evaluation.result',
      exportPath: 'value',
      display: {
        export: true,
      },
    },
    {
      accessor: ConstVars.EvaluationAccessors.Unit,
      translationId: 'schemas.evaluation.unit',
      exportPath: 'indicator.unit.symbol',
      display: {
        export: true,
      },
    },
    {
      accessor: ConstVars.EvaluationAccessors.Period,
      translationId: 'schemas.evaluation.period',
      exportPath: 'periodStartsAt',
      display: {
        export: true,
      },
    },
    {
      accessor: ConstVars.EvaluationAccessors.Evidence,
      translationId: 'schemas.evaluation.evidence',
      exportPath: 'evidence',
      display: {
        export: true,
      },
    },
    {
      accessor: ConstVars.EvaluationAccessors.Framework,
      translationId: 'schemas.evaluation.framework',
      exportPath: 'indicator.section.framework.title',
      display: {
        export: true,
      },
    },
    {
      accessor: ConstVars.EvaluationAccessors.CreatedAt,
      translationId: 'schemas.evaluation.createdAt',
      exportPath: 'createdAt',
      display: {
        export: true,
      },
    },
  ],
  relatedTabs: [],
  actions: [],
  relatedContentFilters: {
    supplier: [
      {
        accessor: 'id',
        name: 'targetObjectId',
        operator: Operators.EqualTo,
      },
    ],
    investee: [
      {
        accessor: 'id',
        name: 'targetObjectId',
        operator: Operators.EqualTo,
      },
    ],
    company: [
      {
        accessor: 'id',
        name: 'targetObjectId',
        operator: Operators.EqualTo,
      },
    ],
  },
}

export default EvaluationsSchema
