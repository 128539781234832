import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { AssessmentTemplate, AssessmentType } from '@app/src/types/resourceExplorer'
import { BarChartOutlined, ChatOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import AssessmentDialog from './AssessmentDialog'
import AssessmentGraphCard from './AssessmentGraphCard'
import useAssessmentOverviewGraph, { AssessmentOverviewGraphDataPoint } from './useAssessmentOverviewGraph'
import { FilterGroup, Operators } from '../ResourceCollection/Filters/useFilters'
import ChartTypeSelector, { ChartType } from '../ResourceCollection/Collections/Assessments/ChartTypeSelector'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import useAssessmentQuestionnaireInquiriesCount from '@app/src/hooks/useAssessmentQuestionnaireInquiriesCount'

type AssessmentOverviewGraphProps = {
  assessmentTemplate: AssessmentTemplate
  userFilters?: FilterGroup[]
  activePeriod?: number
}

const AssessmentOverviewGraph: React.FC<AssessmentOverviewGraphProps> = ({
  assessmentTemplate,
  userFilters = [],
  activePeriod,
}) => {
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)

  const { formatMessage } = useIntl()
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Horizontal)
  const [assessmentDialogOpen, openAssessmentDialog, closeAssessmentDialog] = useDialogState()
  const [activeLevel, setActiveLevel] = useState<number>()
  const isBaselineAssessment = assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment
  const providerTypeNamePlural = useCurrentProviderType({ isPlural: true })
  const [isCreateRequestDialogOpen, openCreateRequestDialog, closeCreateRequestDialog] = useDialogState()
  const filters: FilterGroup[] = [
    { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
  ]

  if (activePeriod) {
    filters.push({ name: 'periodName', filters: [{ value: activePeriod?.toString(), operator: Operators.EqualTo }] })
  } else {
    const periodFromUserFilter = userFilters.find(x => x.name === 'periodName')?.filters[0]?.value
    if (periodFromUserFilter) {
      activePeriod = parseInt(periodFromUserFilter.toString())
    } else {
      activePeriod = new Date().getFullYear()
    }
  }

  const {
    facets: [levelFacets = []],
    isFetched: isLevelsFacetFetched,
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'levels'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'totalLevel', isEnum: true }],
    filter: [...filters, ...userFilters],
    options: {
      enabled: Boolean(activePeriod),
      staleTime: 60000,
    },
  })

  const { inquiriesCount, isLoadingInquiriesCount, questionnaireTemplates } = useAssessmentQuestionnaireInquiriesCount(
    assessmentTemplate,
    Boolean(isLevelsFacetFetched && !levelFacets.length),
  )

  const has5Levels = assessmentTemplate.levels !== 3 // null or 5 shows 5 levels to be backward compatible

  const levelChartObj = useMemo(
    () =>
      Array.from(Array(assessmentTemplate.levels ?? 5).keys()).map(i => {
        const level = levelFacets.find(l => l.value === i + 1)
        return {
          value: Number(level?.count ?? 0),
          level: i + 1,
        }
      }),
    [assessmentTemplate, levelFacets],
  )

  const options = useAssessmentOverviewGraph(levelChartObj, selectedChartType, has5Levels, isBaselineAssessment)

  const events = useMemo(
    () => ({
      click: ({ data }: { data: AssessmentOverviewGraphDataPoint }) => {
        if (!data.value) {
          return
        }

        setActiveLevel(data.level)
        openAssessmentDialog()
      },
    }),
    [],
  )

  const handleCloseDialog = () => {
    closeAssessmentDialog()
    setTimeout(() => {
      setActiveLevel(undefined)
    }, 250)
  }

  if (!isLevelsFacetFetched || isLoadingInquiriesCount) {
    return (
      <Stack minHeight={433} height="100%" flexGrow={1}>
        <Stack flexGrow={1} alignItems="center" justifyContent="center" width="100%">
          <CircularProgress />
        </Stack>
      </Stack>
    )
  }

  if (!levelFacets.length) {
    return (
      <Stack bgcolor="grey.200" borderRadius={1} p={4} gap={4} minHeight={470} height="100%" flexGrow={1}>
        <Stack alignItems="center" justifyContent="center" flexGrow={1} p={3}>
          {inquiriesCount ? (
            <EmptyState
              iconComponent={ChatOutlined}
              title={formatMessage({ id: 'assessments.overviewGraph.noResponsesEmptyState.title' })}
              description={formatMessage(
                { id: 'assessments.overviewGraph.noResponsesEmptyState.description' },
                { providerTypePlural: providerTypeNamePlural },
              )}
            />
          ) : (
            <>
              <EmptyState
                iconComponent={BarChartOutlined}
                title={formatMessage({ id: 'assessments.overviewGraph.noInquiriesEmptyState.title' })}
                description={formatMessage({ id: 'assessments.overviewGraph.noInquiriesEmptyState.description' })}
              >
                <Button variant="outlined" onClick={openCreateRequestDialog}>
                  {formatMessage({ id: 'assessments.overviewGraph.noInquiriesEmptyState.action' })}
                </Button>
              </EmptyState>
              <CreationModalProgressContextProvider>
                <CreateNewRequestModal
                  open={isCreateRequestDialogOpen}
                  onClose={closeCreateRequestDialog}
                  reportType={ReportType.WF}
                  disableInitialBack
                  selectedQuestionnaireTemplates={questionnaireTemplates}
                />
              </CreationModalProgressContextProvider>
            </>
          )}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      <Stack justifyContent="flex-end" flexGrow={1}>
        <AssessmentGraphCard
          onClickTitle={openAssessmentDialog}
          eChartsRef={eChartsRef}
          period={activePeriod}
          assessmentTemplateName={assessmentTemplate.name}
          chartSelector={
            <ChartTypeSelector
              selectedChartType={selectedChartType}
              setSelectedChartType={setSelectedChartType}
              allowedChartTypes={[ChartType.Pie, ChartType.Horizontal, ChartType.Vertical]}
            />
          }
        >
          <Box height={384} display="flex" flexDirection="column">
            <ReactEChartsCore
              onEvents={events}
              option={options}
              echarts={echarts}
              style={{ minWidth: '100%', flexGrow: 1 }}
              ref={eChartsRef}
              notMerge
            />
          </Box>
        </AssessmentGraphCard>
      </Stack>

      <AssessmentDialog
        isOpen={assessmentDialogOpen}
        closeModal={handleCloseDialog}
        assessmentTemplate={assessmentTemplate}
        period={activePeriod}
        activeLevel={activeLevel}
        filters={[...filters, ...userFilters]}
      />
    </Stack>
  )
}

export default AssessmentOverviewGraph
