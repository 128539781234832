import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ReferralCode } from '@app/src/types/organizations'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { SfdrRequestTags } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { FileCopyOutlined } from '@mui/icons-material'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import GraphLegend from '../GraphLegend'
import StatisticsBar from '../StatisticsBar'
import StatisticsCard from '../StatisticsCard'

type SFDRProgressCardProps = {
  hasInvestees: boolean
  totalRequests: number
  pendingRequests: number
  referralCode?: ReferralCode
  hasSFDRRequests?: boolean
  isSFDRProgressComplete?: boolean
}

const useStyles = makeStyles(({ palette }) => ({
  getStartedButton: {
    backgroundColor: palette.common.white,
    color: palette.common.black,
    '&:hover': {
      backgroundColor: palette.grey[200],
    },
  },
}))

const SFDRProgressCard: React.FC<SFDRProgressCardProps> = ({
  hasInvestees,
  referralCode,
  hasSFDRRequests,
  totalRequests,
  pendingRequests,
  isSFDRProgressComplete,
}) => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const { currentReportingPeriod } = useFlags()
  const { getStartedButton } = useStyles()
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useDialogState()
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const hasReferralCode = Boolean(referralCode)
  const { showSnackbar } = useSnackbar()

  const sfdrTagFilter = {
    name: 'template.tags.tag',
    value: [SfdrRequestTags.Sfdr],
    operator: Operators.In,
  }

  const periodFilter = {
    name: 'periodName',
    value: [currentReportingPeriod],
    operator: Operators.In,
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(`${window.location.origin}?referral=${referralCode?.code}`)
    showSnackbar({ message: formatMessage({ id: 'form.createRequest.sfdr.copyTextSuccess' }), severity: 'success' })
  }

  if (hasInvestees && hasSFDRRequests) {
    return (
      <StatisticsCard
        title={formatMessage({ id: 'dashboard.investment.sfdrProgress' })}
        helperText={formatMessage({ id: 'dashboard.investment.sfdrProgressHelperText' }, { br })}
        action={
          <Button
            size="small"
            onClick={() =>
              history.push(
                stringifyQueryFilters({
                  url: generatePath(paths.manageRequest, {
                    view: ViewTypeName.Requests,
                  }),
                  queryParams: {
                    filters: [sfdrTagFilter, periodFilter],
                  },
                  includeCurrentFilters: true,
                }),
              )
            }
          >
            {formatMessage({ id: 'dashboard.investment.seeAll' })}
          </Button>
        }
      >
        <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between" height="100%" py={1}>
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            <Typography>{currentReportingPeriod}</Typography>
            <StatisticsBar
              barWidth={30}
              value={totalRequests - pendingRequests}
              max={totalRequests}
              color={palette.secondary.main}
              onBarClick={() =>
                history.push(
                  stringifyQueryFilters({
                    url: generatePath(paths.manageRequest, {
                      view: ViewTypeName.Requests,
                    }),
                    queryParams: {
                      filters: [
                        {
                          name: 'status',
                          value: [InquiryStatus.Submitted, InquiryStatus.Approved],
                          operator: Operators.In,
                        },
                        sfdrTagFilter,
                        periodFilter,
                      ],
                    },
                    includeCurrentFilters: true,
                  }),
                )
              }
              percent
              withCount
              showBackground
            />
            <Stack direction="row" spacing={2}>
              <GraphLegend
                color={palette.secondary.main}
                legend={formatMessage({ id: 'dashboard.investment.reported' })}
                linkTo={stringifyQueryFilters({
                  url: generatePath(paths.manageRequest, {
                    view: ViewTypeName.Requests,
                  }),
                  queryParams: {
                    filters: [
                      {
                        name: 'status',
                        value: [InquiryStatus.Submitted, InquiryStatus.Approved],
                        operator: Operators.In,
                      },
                      sfdrTagFilter,
                      periodFilter,
                    ],
                  },
                  includeCurrentFilters: true,
                })}
              />
              <GraphLegend
                color={palette.grey[200]}
                legend={formatMessage({ id: 'dashboard.investment.requested' })}
                linkTo={stringifyQueryFilters({
                  url: generatePath(paths.manageRequest, {
                    view: ViewTypeName.Requests,
                  }),
                  queryParams: {
                    filters: [
                      {
                        name: 'status',
                        value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
                        operator: Operators.In,
                      },
                      sfdrTagFilter,
                      periodFilter,
                    ],
                  },
                  includeCurrentFilters: true,
                })}
              />
            </Stack>
          </Box>
          {hasReferralCode && (
            <Box mt={1}>
              <Button color="inherit" startIcon={<FileCopyOutlined />} onClick={handleCopy}>
                {formatMessage({ id: 'dashboard.investment.copyInvite' })}
              </Button>
            </Box>
          )}

          {isSFDRProgressComplete && (
            <Box mt={2} width="100%">
              <Button
                fullWidth
                variant="contained"
                onClick={() =>
                  history.push(
                    stringifyQueryFilters({
                      url: generatePath(paths.dataHubCollection, {
                        view: DataHubView.Answers,
                      }),
                      queryParams: {
                        filters: [
                          {
                            name: 'response.request.template.tags.tag',
                            value: [SfdrRequestTags.Sfdr],
                            operator: Operators.In,
                          },
                          {
                            name: 'response.request.periodName',
                            value: [currentReportingPeriod],
                            operator: Operators.In,
                          },
                        ],
                      },
                      includeCurrentFilters: true,
                    }),
                    {
                      fromDashboardCreateReportButton: true,
                    },
                  )
                }
              >
                {formatMessage({ id: 'dashboard.investment.createSFDRReport' })}
              </Button>
            </Box>
          )}
        </Box>
      </StatisticsCard>
    )
  }

  if (hasInvestees && !hasSFDRRequests) {
    return (
      <StatisticsCard title={formatMessage({ id: 'dashboard.investment.sfdrProgress' })} isEmptyState>
        <Box
          color="common.white"
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          p={2}
        >
          <Typography color="inherit">{formatMessage({ id: 'dashboard.investment.noRequestsEmptyState' })}</Typography>
          <Box mt={2}>
            <Button variant="contained" className={getStartedButton} onClick={openCreateDialog}>
              {formatMessage({ id: 'dashboard.investment.noRequestsCallToAction' })}
            </Button>
          </Box>

          <CreationModalProgressContextProvider>
            <CreateNewRequestModal
              open={isCreateDialogOpen}
              onClose={closeCreateDialog}
              reportType={ReportType.SFDR_WITH_COMPANIES}
              disableInitialBack
            />
          </CreationModalProgressContextProvider>
        </Box>
      </StatisticsCard>
    )
  }

  if (!hasInvestees && !hasSFDRRequests && hasReferralCode)
    return (
      <StatisticsCard title={formatMessage({ id: 'dashboard.investment.sfdrProgress' })} isEmptyState>
        <Box
          color="common.white"
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          p={2}
        >
          <Typography color="inherit" variant="h6">
            {formatMessage({ id: 'dashboard.investment.nothingToShow' })}
          </Typography>
          <Box mt={1}>
            <Typography color="inherit" variant="caption">
              {formatMessage({ id: 'dashboard.investment.nothingToShowDescription' })}
            </Typography>
          </Box>
          <Box mt={1} color="common.white">
            <Button startIcon={<FileCopyOutlined />} onClick={handleCopy}>
              {formatMessage({ id: 'dashboard.investment.copyInvite' })}
            </Button>
          </Box>
        </Box>
      </StatisticsCard>
    )

  return (
    <StatisticsCard title={formatMessage({ id: 'dashboard.investment.sfdrProgress' })} isEmptyState>
      <Box color="common.white" textAlign="center" display="flex" flexDirection="column" justifyContent="center" p={2}>
        <Typography color="inherit">{formatMessage({ id: 'dashboard.investment.noProvidersEmptyState' })}</Typography>
        <Box mt={2}>
          <Button variant="contained" className={getStartedButton} onClick={openCreateDialog}>
            {formatMessage({ id: 'general.getStarted' })}
          </Button>
        </Box>

        <CreationModalProgressContextProvider>
          <CreateNewRequestModal
            open={isCreateDialogOpen}
            onClose={closeCreateDialog}
            reportType={ReportType.SFDR_WITHOUT_COMPANIES}
            disableInitialBack
          />
        </CreationModalProgressContextProvider>
      </Box>
    </StatisticsCard>
  )
}

export default SFDRProgressCard
