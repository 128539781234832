import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import LinkButton from '@app/src/components/LinkButton'
import Table from '@app/src/components/Table'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RiskPrioritizationSummary } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { TuneOutlined } from '@mui/icons-material'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import { Alert, Box, Typography, capitalize } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import ProviderRiskFilters from '../Filters/ProviderRiskFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import ProviderRiskHeader from './ProviderRisk/ProviderRiskHeader'
import ProviderRiskInfoDialog from './ProviderRisk/ProviderRiskInfoDialog'
import ProviderRiskRow from './ProviderRisk/ProviderRiskRow'

interface Props {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
}

const allowedFilters = [
  'provider.organization.name',
  'riskStatus',
  'periodName',
  'provider.ownerUserId',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.activityStatus',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'provider.categoryOptions.id',
  'provider.assessments.totalLevel',
  'provider.assessments.intervalType',
  'provider.assessments.section1Level',
  'provider.assessments.section2Level',
  'provider.assessments.section3Level',
  'provider.assessments.section4Level',
  'provider.assessments.section5Level',
  'provider.assessments.assessmentTemplate.name',
]

const ProviderRiskCollection: React.FC<Props> = ({ relatedContentFilter = [], embedded }): JSX.Element => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const providerType = useCurrentProviderType()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const { sorting, toggleSorting } = useSort('riskStatus')
  const [open, openDialog, closeDialog] = useDialogState()
  const [showRiskScreenAlert, setShowRiskScreenAlert] = useState(() =>
    hasPermission(Permissions.ACCESS_SOLUTION_CONFIGURATION),
  )

  const rawExportPayload = {
    filter: relatedContentFilter,
    sort: sorting,
    include: ['provider.organization'],
  }

  const userFilters = useGetApiQueryFilters(allowedFilters)

  const payload = {
    ...rawExportPayload,
    filter: [...rawExportPayload.filter, ...userFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<RiskPrioritizationSummary>({
    key: FetchKey.ProviderRisk,
    endpoint: endpoints.riskPrioritization,
    payload,
  })

  return (
    <>
      {showRiskScreenAlert && (
        <Alert
          variant="filled"
          severity="info"
          icon={<TuneOutlined />}
          onClose={() => setShowRiskScreenAlert(false)}
          sx={{ borderRadius: 0, bgcolor: 'communication.main' }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography variant="inherit" flexGrow={1}>
              {formatMessage({ id: 'schemas.providerRisk.riskScreenAlertMessage' })}
            </Typography>
            <LinkButton
              to={generatePath(paths.configurations, { configurationsPage: CONFIGURATION_PAGE_IDS.RiskScreening })}
            >
              {formatMessage({ id: 'schemas.providerRisk.riskScreenAlertButton' })}
            </LinkButton>
          </Box>
        </Alert>
      )}

      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.providerRisk' }, { providerType: capitalize(providerType) })}
        actionButtons={[
          {
            label: formatMessage({ id: 'schemas.providerRisk.infoButton' }, { providerType }),
            variant: 'text',
            startIcon: <LibraryBooksOutlinedIcon />,
            onClick: openDialog,
          },
        ]}
        filter={
          <ProviderRiskFilters
            implicitFilters={relatedContentFilter}
            allowedFilters={allowedFilters}
            resetPage={resetPage}
          />
        }
        embedded={embedded}
      >
        <Table<RiskPrioritizationSummary>
          RowComponent={ProviderRiskRow}
          HeaderComponent={() => <ProviderRiskHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
          data={items}
          isLoading={isLoading || isFetching || isLoading}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ResourceCollectionScene>
      <ProviderRiskInfoDialog open={open} closeDialog={closeDialog} />
    </>
  )
}
export default ProviderRiskCollection
