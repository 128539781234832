import { LinkOff } from '@mui/icons-material'
import { Button } from '@mui/material'
import EmptyState from '@app/src/components/EmptyState'
import React from 'react'
import { useIntl } from 'react-intl'

interface NoReferralEmptyStateProps {
  onReferralExpired: () => void
}

const NoReferralEmptyState: React.FC<NoReferralEmptyStateProps> = ({ onReferralExpired }) => {
  const { formatMessage } = useIntl()
  return (
    <EmptyState
      title={formatMessage({ id: 'referral.referralExpired.header' })}
      description={formatMessage({ id: 'referral.referralExpired.subHeader' })}
      iconComponent={LinkOff}
    >
      <Button onClick={onReferralExpired} variant="outlined">
        {formatMessage({ id: 'general.close' })}
      </Button>
    </EmptyState>
  )
}

export default NoReferralEmptyState
