import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewPriorty from '@app/src/components/Drawer/Views/DrawerViewPriorty'
import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import PriorityCell from '@app/src/components/Table/Cells/PriorityCell'
import SimpleRiskChipCell from '@app/src/components/Table/Cells/SimpleRiskChipCell'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Provider, RiskTableView } from '@app/src/types/organizations'
import { AggregatedCountryRisk, Risk, RiskType } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import RiskScreeningInfoDialog, { RiskScreeningInfoDialogProps } from './RiskScreeningInfoDialog'

interface RiskScreeningAllRisksRowProps {
  row: RiskTableView
  enabledRiskTypes: RiskType[]
  activeFilters: FilterGroup[]
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => void
  selectedProviders: Array<number>
}

export const getHqRisk = (row: RiskTableView, riskTypeId: number): Risk | undefined => {
  return row.risks.find(r => r.riskTypeId === riskTypeId)?.headquarter
}

export const getOwnOperationsRisk = (row: RiskTableView, riskTypeId: number): AggregatedCountryRisk | undefined => {
  return row.risks.find(r => r.riskTypeId === riskTypeId)?.operation
}

export const getSubsupplierRisk = (row: RiskTableView, riskTypeId: number): AggregatedCountryRisk | undefined => {
  return row.risks.find(r => r.riskTypeId === riskTypeId)?.subsupplier
}

const RiskScreeningAllRisksRow: React.FC<RiskScreeningAllRisksRowProps> = ({
  row,
  enabledRiskTypes,
  onCheckboxChange,
  selectedProviders,
  activeFilters,
}) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const [riskDialogContent, setRiskDialogContent] = useState<RiskScreeningInfoDialogProps['riskDialogContent']>()

  const riskFilters = activeFilters.find(f => f.name === 'riskStatus')?.filters.flatMap(f => f.value)
  const isChecked = Boolean(selectedProviders?.some(selectedProviderId => selectedProviderId === row.parentObject.id))

  return (
    <>
      <TableRow>
        <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, row.parentObject)} />
        <CompanyCell company={row.parentObject} drawer />
        {enabledRiskTypes.map(riskType => {
          const hqRisk = getHqRisk(row, riskType.id)
          const ownOperationsRisk = getOwnOperationsRisk(row, riskType.id)
          const subsupplierRisk = getSubsupplierRisk(row, riskType.id)

          return (
            <React.Fragment key={riskType.source}>
              <SimpleRiskChipCell
                riskStatus={hqRisk?.status}
                disabled={!riskFilters?.includes(hqRisk?.status)}
                onClick={() =>
                  setRiskDialogContent({
                    providerName: row.parentObject.name,
                    countryName: row.parentObject.country.name,
                    risk: hqRisk,
                    location: formatMessage({ id: 'riskScreening.subLabels.headquarter' }),
                  })
                }
              />
              <SimpleRiskChipCell
                riskStatus={ownOperationsRisk?.highestRisk.status}
                disabled={!riskFilters?.includes(ownOperationsRisk?.highestRisk.status)}
                onClick={() =>
                  setRiskDialogContent({
                    providerName: row.parentObject.name,
                    countryName: ownOperationsRisk?.countryName,
                    location: formatMessage({ id: 'riskScreening.subLabels.ownOperations' }),
                    risk: ownOperationsRisk?.highestRisk,
                  })
                }
              />
              <SimpleRiskChipCell
                riskStatus={subsupplierRisk?.highestRisk.status}
                disabled={!riskFilters?.includes(subsupplierRisk?.highestRisk.status)}
                onClick={() =>
                  setRiskDialogContent({
                    providerName: row.parentObject.name,
                    countryName: subsupplierRisk?.countryName,
                    location: formatMessage({ id: 'riskScreening.subLabels.subsupplier' }),
                    risk: subsupplierRisk?.highestRisk,
                  })
                }
              />
            </React.Fragment>
          )
        })}

        <PriorityCell
          value={row.parentObject.priority}
          onClick={() => openDrawer(<DrawerViewPriorty provider={row.parentObject} />)}
          size="small"
        />
      </TableRow>

      <RiskScreeningInfoDialog riskDialogContent={riskDialogContent} setRiskDialogContent={setRiskDialogContent} />
    </>
  )
}

export default RiskScreeningAllRisksRow
