import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewProductMappingReviewConnections from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewProvidersConnections'
import { ValueChainEmptyStateAlternative } from '@app/src/pages/Product/ProductRequestCard/ProductRequestCard'
import ValueChainView, { View } from '@app/src/pages/Product/ValueChainView'
import { Organization } from '@app/src/types/organizations'
import { Product, ProductMappingResponse } from '@app/src/types/product'
import { Fullscreen, FullscreenExit, Loop } from '@mui/icons-material'
import { Alert, AlertTitle, Box } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type DrawerViewReviewResponseValueChainToCopyToProductProps = {
  organization: Organization
  mappingResponse: ProductMappingResponse
  targetProduct: Product
  disableValueChainReplacement?: boolean
} & Omit<DrawerViewProps, 'title'>

const DrawerViewReviewResponseValueChainToCopyToProduct: React.FC<
  DrawerViewReviewResponseValueChainToCopyToProductProps
> = ({ organization, mappingResponse, targetProduct, disableValueChainReplacement = false, ...props }) => {
  const { formatMessage } = useIntl()
  const { openDrawer, setOptions, options } = useDrawer()

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.mappingRequest.supplyChainReview' })}
      subTitle={organization.name}
      buttons={[
        {
          label: formatMessage({ id: options.fullScreen ? 'general.exitFullScreen' : 'general.viewFullScreen' }),
          onClick: () => setOptions({ fullScreen: !options.fullScreen }),
          variant: 'outlined',
          endIcon: options.fullScreen ? <FullscreenExit /> : <Fullscreen />,
        },
        {
          label: formatMessage({ id: 'schemas.mappingNode.addValueChain' }),
          disabled: disableValueChainReplacement,
          tooltip: disableValueChainReplacement
            ? formatMessage({ id: 'schemas.mappingNode.disabledByAutomation' })
            : undefined,
          onClick: () =>
            openDrawer(
              <DrawerViewProductMappingReviewConnections
                targetProduct={targetProduct}
                mappingResponse={mappingResponse}
              />,
            ),
          variant: 'contained',
        },
      ]}
      stackButtons
      fullscreenContent={
        <ValueChainView
          showHeader={false}
          mappingNodes={mappingResponse.mappingNodesSnapshot}
          isSnapshot
          forceView={View.Tree}
          treeViewHeight="100%"
          customEmptyState={<ValueChainEmptyStateAlternative />}
        />
      }
      {...props}
    >
      <Box px={2} height="100%">
        {disableValueChainReplacement && (
          <Alert sx={{ mb: 3 }} severity="info" icon={<Loop />}>
            <AlertTitle sx={{ m: 0 }}>
              {formatMessage({ id: 'automations.productMapping.acceptLatestValueChainsEnabledInfo.title' })}
            </AlertTitle>
          </Alert>
        )}
        <ValueChainView
          mappingNodes={mappingResponse.mappingNodesSnapshot}
          isSnapshot
          forceView={View.Table}
          showHeader={false}
          customEmptyState={<ValueChainEmptyStateAlternative />}
        />
      </Box>
    </DrawerView>
  )
}

export default DrawerViewReviewResponseValueChainToCopyToProduct
