import { Components } from '@mui/material'
import { palette } from '@app/src/theme/palette'

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {},
    filled: {
      fontSize: '1.5rem',
      lineHeight: '3rem',
      transform: 'none',

      '&.Mui-error': {
        color: palette.semantic.error,
      },
    },
  },
}

export default MuiInputLabel
