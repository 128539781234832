import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewReviewResponseValueChainToCopyToProduct from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewResponseValueChainToCopyToProduct'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import { MappingResponseWithOrg } from '@app/src/pages/Product/ProductScene'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Product } from '@app/src/types/product'
import {
  Box,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import React from 'react'
import { FormattedDate, useIntl } from 'react-intl'

type DrawerViewSelectProductMappingResponseProps = {
  targetProduct: Product
  mappingResponses: MappingResponseWithOrg[]
} & Omit<DrawerViewProps, 'title'>

const DrawerViewSelectProductMappingResponse: React.FC<DrawerViewSelectProductMappingResponseProps> = ({
  targetProduct,
  mappingResponses,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <DrawerView title={formatMessage({ id: 'schemas.mappingNode.addValueChainFromResponse' })} {...props}>
      <Box p={2}>
        <List>
          {mappingResponses?.map(({ response, responderOrganization }, i) => (
            <React.Fragment key={response.id}>
              <ListItemButton
                onClick={() => {
                  openDrawer(
                    <DrawerViewReviewResponseValueChainToCopyToProduct
                      organization={responderOrganization}
                      mappingResponse={response}
                      targetProduct={targetProduct}
                    />,
                  )
                }}
              >
                <ListItemAvatar>
                  <CompanyAvatar
                    organizationName={responderOrganization.name[0]}
                    imageUrl={responderOrganization.image?.url}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={responderOrganization.name}
                  secondary={<FormattedDate value={response.submittedAt} year="numeric" day="numeric" month="long" />}
                />
                <ListItemSecondaryAction>
                  <CategoryCell
                    value={
                      response.valueChainStatus
                        ? formatMessage({
                            id: `schemas.product.mappingStatusValues.${response.valueChainStatus}`,
                          })
                        : undefined
                    }
                    disableCell
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
              {i < mappingResponses.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </DrawerView>
  )
}
export default DrawerViewSelectProductMappingResponse
