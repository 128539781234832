// TODO: type this file
// es-lint-disable @typescript-eslint/ban-ts-comment
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck

import enLocale from './locales/en.json'

import { DEFAULT_LANGUAGE } from '@app/src/wf-constants'

const availableLocale = {
  en: [enLocale],
  'en-US': [enLocale, enLocale],
  'en-GB': [enLocale, enLocale],
}

const availableLocaleKeys = Object.keys(availableLocale)

/**
 * Get the compatible locale based on a language.
 * If no locale is compatible, use the default language (en)
 * @param language The language to find compatible locale
 * @returns {*} A compatible locale based on the language or the default locale
 */
export const getLocale = (language = '') => {
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]
  if (availableLocaleKeys.includes(language) || availableLocaleKeys.includes(languageWithoutRegionCode)) {
    return language
  }
  return DEFAULT_LANGUAGE
}

/**
 * Get translation items for a specific language
 * Allow use of nested object by flattening when
 * returning the translations
 * @param language
 * @returns {*} The translation object with flatten values
 */

/**
 * Flatten and merge a list of translations.
 * This function is used to merge general translations
 * with region based translations
 * @param translationObjects
 * @returns {any} The merged and flatten translation object
 */

const flattenTranslations = translations =>
  Object.keys(translations).reduce((acc, key) => {
    if (typeof translations[key] === 'object') {
      if (Object.keys(translations[key])?.length === 0) {
        return acc
      }

      const flattenObject = flattenTranslations(translations[key])
      return {
        ...acc,
        ...Object.keys(flattenObject).reduce(
          (acc2, key2) => ({
            ...acc2,
            [`${key}.${key2}`]: flattenObject[key2], // append key to beginning of flatten key
          }),
          {},
        ),
      }
    }
    return {
      ...acc,
      [key]: translations[key],
    }
  }, {})

const prepareTranslations = translationObjects =>
  translationObjects
    ?.map(translationObject => flattenTranslations(translationObject))
    .reduce((acc, translations) => ({
      ...acc,
      ...translations,
    }))

export const getMessages = language => {
  const translations = availableLocale[getLocale(language)]
  return prepareTranslations(translations)
}
