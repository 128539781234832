import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Schema } from '@app/src/types/schemas'

const ResponseSchema: Schema = {
  name: 'risk-screening',
  defaultImage: '',
  items: [],
  relatedContentFilters: {
    supplier: [
      {
        accessor: 'id',
        name: 'id',
        operator: Operators.EqualTo,
      },
    ],
    investee: [
      {
        accessor: 'id',
        name: 'id',
        operator: Operators.EqualTo,
      },
    ],
    company: [
      {
        accessor: 'id',
        name: 'id',
        operator: Operators.EqualTo,
      },
    ],
  },
}

export default ResponseSchema
