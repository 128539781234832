import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import Dialog from '@app/src/components/Dialog'
import { useAccount } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import EditQuestionAnswerClassification from '@app/src/pages/QuestionEditor/AnswerClassification'
import {
  mapToAnswerClassificationDataFormData,
  mapToClassificationSubmitData,
  mapToNumberClassificationDataFormData,
  mapToOptionClassificationDataFormData,
  QuestionAnswerClassificationRuleFormData,
  QuestionNumberClassificationRuleFormData,
  QuestionOptionClassificationRuleFormData,
} from '@app/src/pages/QuestionEditor/AnswerClassification/utils'
import { EditQuestionFormData } from '@app/src/pages/QuestionEditor/EditQuestion'
import { QuestionAnswerClassificationRule, QuestionInTemplate } from '@app/src/types/resourceExplorer'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

interface EditQuestionClassificationDialogProps {
  isOpen: boolean
  closeDialog: () => void
  setCurrentQuestion: (question: QuestionInTemplate | undefined) => void
  currentQuestion: QuestionInTemplate | undefined
}

export interface ClassificationRulesFormData {
  questionAnswerClassificationRules?: QuestionAnswerClassificationRuleFormData
  questionNumberClassificationRules?: QuestionNumberClassificationRuleFormData
  questionOptionClassificationRules?: QuestionOptionClassificationRuleFormData
}

export const EditQuestionClassificationDialog: React.FC<EditQuestionClassificationDialogProps> = ({
  isOpen,
  closeDialog,
  setCurrentQuestion,
  currentQuestion,
}) => {
  const { formatMessage } = useIntl()
  const formMethods = useForm<ClassificationRulesFormData>({ mode: 'onChange', shouldUnregister: false })
  const { mutateAsync: saveAsync } = useCreateResource<
    QuestionAnswerClassificationRule[],
    (QuestionAnswerClassificationRule | undefined)[]
  >()
  const { account } = useAccount()
  const isWorldfavorOrganization = account?.organization?.id === WF_ORGANIZATION_ID
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { setValue } = formMethods

  useEffect(() => {
    if (currentQuestion) {
      formMethods.reset({
        questionAnswerClassificationRules: mapToAnswerClassificationDataFormData(
          currentQuestion.questionAnswerClassificationRules,
        ),
        questionNumberClassificationRules: mapToNumberClassificationDataFormData(
          currentQuestion.questionAnswerClassificationRules,
        ),
        questionOptionClassificationRules: mapToOptionClassificationDataFormData(
          currentQuestion.questionAnswerClassificationRules,
        ),
      })
    }
  }, [currentQuestion])

  useEffect(() => {
    if (currentQuestion?.requestCollectionId) {
      setValue('requestCollectionId', currentQuestion.requestCollectionId)
    }
  }, [currentQuestion?.requestCollectionId, setValue])

  const onSubmit = async (values: EditQuestionFormData) => {
    await saveAsync(
      {
        url: endpoints.questionAnswerClassificationRule,
        body: mapToClassificationSubmitData(isWorldfavorOrganization, values)
          .filter(i => !!i)
          .map(i => ({
            ...i,
            questionId: currentQuestion?.id,
          })),
      },
      {
        onSuccess: async data => {
          showSnackbar({
            message: formatMessage(
              { id: 'templateBuilder.success' },
              { b: (chunks: React.ReactNode) => <b>{chunks}</b>, templateName: values.questionText },
            ),
            severity: 'success',
          })
          queryClient.invalidateQueries(FetchKey.OrganizationOwnedTemplates)
          queryClient.invalidateQueries(FetchKey.AssessmentCollection)
          closeDialog()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  if (!currentQuestion) return null

  return (
    <FormProvider {...formMethods}>
      <Dialog
        maxWidth="sm"
        onFormSubmit={formMethods.handleSubmit(onSubmit)}
        open={isOpen}
        onClose={() => {
          setCurrentQuestion(undefined)
          closeDialog()
        }}
        buttons={[
          { onClick: () => closeDialog(), label: formatMessage({ id: 'general.cancel' }) },
          { type: 'submit', variant: 'contained', label: formatMessage({ id: 'general.update' }) },
        ]}
        title={formatMessage({ id: 'assessments.editClassification' })}
        content={<EditQuestionAnswerClassification questionTypeId={currentQuestion.questionTypeId} />}
      />
    </FormProvider>
  )
}
