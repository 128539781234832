import { CircularProgress } from '@mui/material'
import React from 'react'

const WfLoader = (): JSX.Element => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default WfLoader
