import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewSubmitQuestionnaire from '@app/src/components/Drawer/Views/DrawerViewSubmitQuestionnaire'
import DrawerViewSubmitShareQuestionnaire from '@app/src/components/Drawer/Views/DrawerViewSubmitShareQuestionnaire'
import { Assertion } from '@app/src/types/reporting'
import { AnswerStatus, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { SendOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Tooltip } from '@mui/material'
import React from 'react'
import { UnpackNestedValue, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import useProviderQuestionnaire from '../../hooks/useProviderQuestionnaire'
import { useReport } from '../../ReportContext'
import { FormData } from '../ProviderReport'

const scrollErrorIntoView = (key: string) => {
  const input =
    document.querySelector(`input[name="${key}.answer"]`) ??
    document.querySelector(`input[name="${key}.selectedOptions"]`) ??
    document.querySelector(`[name="${key}.uploadReferences.fileUpload"]`)

  input?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'start',
  })
}

function SubmitButton() {
  const { formatMessage } = useIntl()
  const { request, currentAccess, openSuccessSubmitDialog } = useReport()
  const { submitResponse, isLoadingDeleteDraft, isLoadingUpdateSaveDraft, isLoadingSubmitDraft } =
    useProviderQuestionnaire()

  const { getValues, formState, reset, setError, clearErrors } = useFormContext<FormData>()
  const { openDrawer } = useDrawer()

  const isRegularQuestionnaire = request?.template?.questionnaireTemplateType === QuestionnaireTypeEnum.Regular

  const validateForm = (values: UnpackNestedValue<FormData>) => {
    clearErrors()
    let errorsExist = false
    Object.entries(values).forEach(([key, value]) => {
      const commentRequired = value.answerStatus === AnswerStatus.Other
      const questionSkipped =
        value.answerStatus !== AnswerStatus.Answered && (!commentRequired || (value.comment?.length ?? 0) >= 10)

      const isAnswered = Boolean(
        value.answer || value.selectedOptions?.length || value.uploadReferences?.originalFileName || questionSkipped,
      )

      if (isAnswered) return

      setError(key, {
        type: 'required',
        message: formatMessage({
          id: commentRequired ? 'form.validation.requiredMinLength' : 'form.validation.required',
        }),
      })

      setError(`${key}.skippedQuestionRequiredInfo`, {
        type: 'required',
      })

      if (errorsExist) return

      scrollErrorIntoView(key)
      errorsExist = true
    })

    return !errorsExist
  }

  const handleSubmit = async (values: UnpackNestedValue<FormData>, assertions: Assertion[]) => {
    await submitResponse(values, assertions, openSuccessSubmitDialog)
    reset(values)
  }

  const startSubmitFlow = () => {
    const values = getValues()
    if (!validateForm(values)) {
      return
    }
    openDrawer(
      isRegularQuestionnaire ? (
        <DrawerViewSubmitQuestionnaire
          isAllAccess={currentAccess?.isAllAccess}
          onSubmit={assertions => handleSubmit(values, assertions)}
        />
      ) : (
        <DrawerViewSubmitShareQuestionnaire
          request={request}
          currentAccess={currentAccess}
          onSubmit={assertions => handleSubmit(values, assertions)}
        />
      ),
    )
  }

  return (
    <>
      <Tooltip
        title={formatMessage({ id: 'questionnaire.actions.requiredFields' })}
        arrow
        disableHoverListener={formState.isValid && !isLoadingDeleteDraft && !isLoadingUpdateSaveDraft}
      >
        <Box display="inline-block">
          <LoadingButton
            startIcon={<SendOutlined />}
            fullWidth
            variant="contained"
            onClick={startSubmitFlow}
            loading={isLoadingSubmitDraft}
          >
            {formatMessage({ id: 'questionnaire.actions.submit' })}
          </LoadingButton>
        </Box>
      </Tooltip>
    </>
  )
}

export default SubmitButton
