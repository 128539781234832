import React, { useEffect } from 'react'

import WfLoader from '@app/src/components/WfLoader'
import { useAuthentication } from '@app/src/context/AuthenticationContext'

const Logout = (): JSX.Element => {
  const { logout } = useAuthentication()

  useEffect(() => {
    logout()
  }, [logout])

  return <WfLoader />
}

export default Logout
