import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { Check } from '@mui/icons-material'
import { alpha, Avatar, Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: alpha(theme.palette.success.main, 0.5),
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(2),
  },
  checkMark: {
    fontSize: theme.spacing(8),
    color: theme.palette.primary.main,
    opacity: '87%',
  },
}))

interface Props {
  closeDialog: () => void
}

const SuccessStep = ({ closeDialog }: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  const classes = useStyles()

  return (
    <OnboardingForm header={formatMessage({ id: 'resourceCollections.create.organization' })}>
      <Box
        textAlign="center"
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        height="100%"
      >
        <Avatar variant="circular" className={classes.avatar}>
          <Check className={classes.checkMark} />
        </Avatar>
        <Typography variant="subtitle1" paragraph>
          {formatMessage({ id: 'claim.done_claim' })}
        </Typography>
        <Typography variant="body1" paragraph>
          {formatMessage({ id: 'claim.saved_claim' })}
        </Typography>
        <Button
          variant="outlined"
          size="medium"
          onClick={() => {
            closeDialog()
          }}
        >
          {formatMessage({ id: 'general.close' })}
        </Button>
      </Box>
    </OnboardingForm>
  )
}

export default SuccessStep
