import Endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import Dialog from '@app/src/components/Dialog'
import { UpdateProvidersDrawerViews } from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import RequestAutomationWarningAlert from '@app/src/pages/Configurations/ConfigurationsPages/Automation/Warning/RequestAutomationWarningAlert'
import { Category, ProviderStandardCategoryNames } from '@app/src/types/categories'
import { br } from '@app/src/utils/translationMarkup'
import { CreationLimits, Solutions } from '@app/src/wf-constants'
import { ChevronRight } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { alpha, IconButton, List, ListItem, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'

interface UpdateProviderMenuProps {
  providersLength: number
  setDrawerView: Dispatch<SetStateAction<UpdateProvidersDrawerViews>>
  setEditCategory: Dispatch<SetStateAction<Category | Pick<Category, 'name'> | undefined>>
}

const useStyles = makeStyles(({ palette }) => ({
  createCatBoxBorder: {
    padding: 0,
    borderBottom: `solid 1px ${alpha(palette?.primary?.main, 0.15)}`,
    borderTop: `solid 1px ${alpha(palette?.primary?.main, 0.15)}`,
  },
  itemPadding: {
    padding: 0,
  },
}))

const UpdateProviderMenu = ({
  providersLength,
  setDrawerView,
  setEditCategory,
}: UpdateProviderMenuProps): JSX.Element => {
  const { data: categories } = useFetchResource<Category[]>({
    key: FetchKey.Category,
    endpoint: Endpoints.category,
  })
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const [isDialogOpen, openDialog, closeDialog] = useDialogState(false)
  const { renderWithPermission } = usePermissions()
  const { solution } = useAuthentication().scope

  const isCategories = Boolean(categories?.length)
  const handleCreateCategoryClick = (): void => {
    if (categories && categories.length >= CreationLimits.Categories) {
      openDialog()
      return
    }
    setDrawerView(UpdateProvidersDrawerViews.CreateCategory)
  }

  return (
    <>
      <RequestAutomationWarningAlert
        automationType={RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY}
        sx={{ borderRadius: 0 }}
      />
      <List sx={{ px: 3, overflow: 'auto', mt: 2 }}>
        {solution === Solutions.Finance ? (
          <>
            <ListItem
              button
              disableGutters
              onClick={(): void => {
                setEditCategory({ name: ProviderStandardCategoryNames.OwnerUserId })
                setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
              }}
              className={classes.itemPadding}
            >
              <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.ownerUser' })}</ListItemText>
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItem>
            <ListItem
              button
              disableGutters
              onClick={(): void => {
                setEditCategory({ name: ProviderStandardCategoryNames.Priority })
                setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
              }}
              className={classes.itemPadding}
            >
              <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.priority' })}</ListItemText>
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItem>

            <ListItem
              button
              disableGutters
              onClick={(): void => {
                setEditCategory({ name: ProviderStandardCategoryNames.FinalRiskRating })
                setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
              }}
              className={classes.itemPadding}
            >
              <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.finalRiskRating' })}</ListItemText>
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItem>

            {isCategories &&
              categories?.map(category => {
                return (
                  <ListItem
                    button
                    disableGutters
                    onClick={(): void => {
                      setEditCategory(category)
                      setDrawerView(UpdateProvidersDrawerViews.AssignCategoryOption)
                    }}
                    key={category.id}
                    className={classes.itemPadding}
                  >
                    <ListItemText disableTypography>{category.name}</ListItemText>
                    <IconButton size="large">
                      <ChevronRight />
                    </IconButton>
                  </ListItem>
                )
              })}
          </>
        ) : (
          <>
            <ListItem
              button
              disableGutters
              onClick={(): void => {
                setEditCategory({ name: ProviderStandardCategoryNames.ActivityStatus })
                setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
              }}
              className={classes.itemPadding}
            >
              <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.activityStatus' })}</ListItemText>
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItem>

            <ListItem
              button
              disableGutters
              onClick={(): void => {
                setEditCategory({ name: ProviderStandardCategoryNames.FinalRiskRating })
                setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
              }}
              className={classes.itemPadding}
            >
              <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.finalRiskRating' })}</ListItemText>
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItem>

            <ListItem
              button
              disableGutters
              onClick={(): void => {
                setEditCategory({ name: ProviderStandardCategoryNames.Priority })
                setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
              }}
              className={classes.itemPadding}
            >
              <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.priority' })}</ListItemText>
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItem>
            <ListItem
              button
              disableGutters
              onClick={(): void => {
                setEditCategory({ name: ProviderStandardCategoryNames.ApprovalStatus })
                setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
              }}
              className={classes.itemPadding}
            >
              <ListItemText disableTypography>
                {formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
              </ListItemText>
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItem>
            <ListItem
              button
              disableGutters
              onClick={(): void => {
                setEditCategory({ name: ProviderStandardCategoryNames.OwnerUserId })
                setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
              }}
              className={classes.itemPadding}
            >
              <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.ownerUser' })}</ListItemText>
              <IconButton size="large">
                <ChevronRight />
              </IconButton>
            </ListItem>
          </>
        )}
        {renderWithPermission({
          [Permissions.SOURCING_USER]: (
            <>
              <ListItem
                button
                disableGutters
                onClick={(): void => {
                  setEditCategory({ name: ProviderStandardCategoryNames.Tier })
                  setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
                }}
                className={classes.itemPadding}
              >
                <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.tier' })}</ListItemText>
                <IconButton size="large">
                  <ChevronRight />
                </IconButton>
              </ListItem>
              <ListItem
                button
                disableGutters
                onClick={(): void => {
                  setEditCategory({ name: ProviderStandardCategoryNames.SupplierUsage })
                  setDrawerView(UpdateProvidersDrawerViews.SetStandardCategory)
                }}
                className={classes.itemPadding}
              >
                <ListItemText disableTypography>{formatMessage({ id: 'schemas.provider.supplierUsage' })}</ListItemText>
                <IconButton size="large">
                  <ChevronRight />
                </IconButton>
              </ListItem>
            </>
          ),
        })}
        {solution !== Solutions.Transparency && (
          <>
            <ListItem button disableGutters onClick={handleCreateCategoryClick} className={classes.createCatBoxBorder}>
              <ListItemText disableTypography>{formatMessage({ id: 'category.createNew' })}</ListItemText>
              <IconButton size="large">
                <AddIcon />
              </IconButton>
            </ListItem>
            {isCategories &&
              categories?.map(category => {
                return (
                  <ListItem
                    button
                    disableGutters
                    onClick={(): void => {
                      setEditCategory(category)
                      setDrawerView(UpdateProvidersDrawerViews.AssignCategoryOption)
                    }}
                    key={category.id}
                    className={classes.itemPadding}
                  >
                    <ListItemText disableTypography>{category.name}</ListItemText>
                    <IconButton size="large">
                      <ChevronRight />
                    </IconButton>
                  </ListItem>
                )
              })}
          </>
        )}
      </List>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        title={formatMessage({ id: 'category.limitExceeded' })}
        content={formatMessage({ id: 'category.limitExplanation' }, { categoryLimit: CreationLimits.Categories, br })}
        buttons={[
          {
            label: formatMessage({ id: 'general.close' }),
            variant: 'outlined',
            onClick: closeDialog,
          },
        ]}
      />
    </>
  )
}

export default UpdateProviderMenu
