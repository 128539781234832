import React from 'react'
import { Alert, Button, Stack } from '@mui/material'
import DrawerViewConnectedResponses from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewConnectedResponses'
import { Product, ProductMappingResponse } from '@app/src/types/product'
import { useIntl } from 'react-intl'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'

interface Props {
  connectedMappingResponses: ProductMappingResponse[]
  product?: Product
}

const ProductHasConnectionsAlert: React.FC<Props> = ({ connectedMappingResponses, product }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  if (!connectedMappingResponses.length || !product) return null
  return (
    <Alert severity="warning" sx={{ mt: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {formatMessage({ id: 'schemas.mappingRequest.changesToConnectedRequests' })}
        <Button
          variant="text"
          onClick={() =>
            openDrawer(<DrawerViewConnectedResponses product={product} responses={connectedMappingResponses} />)
          }
        >
          {formatMessage({ id: 'schemas.mappingRequest.seeConnectedRequests' })}
        </Button>
      </Stack>
    </Alert>
  )
}

export default ProductHasConnectionsAlert
