import SelectFilterStep, {
  CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS,
} from '@app/src/components/CreateNewRequestModal/Common/SelectFilterStep'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useFiltersContext } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { GOAL_TRACKER_QUESTION_KEYS } from '../CreateGoalTrackerModal'

export enum FilterOptions {
  ApplyFilter = 'apply-filter',
  NoFilter = 'no-filter',
}

const GoalTrackerSelectFilterStep = () => {
  const { formatMessage } = useIntl()
  const { setAllowsNextWithoutData } = useCreationModalProgress()
  const { watch, getValues, setValue, reset } = useFormContext()
  const { clearQueryFilter } = useFiltersContext()
  const filters = useGetApiQueryFilters(CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS)
  const filterCollectionsValue = watch(GOAL_TRACKER_QUESTION_KEYS.FILTER_COLLECTIONS)
  const [activeFilterOption, setActiveFilterOption] = useState<FilterOptions>(
    filterCollectionsValue?.length ? FilterOptions.ApplyFilter : FilterOptions.NoFilter,
  )

  const transformToResponseItemFilters = (filterCollections: FilterGroup[]): FilterGroup[] => {
    if (!Array.isArray(filterCollections)) {
      return []
    }
    return filterCollections?.map(filterGroup => {
      const newName = `response.request.subscriptions.target.${filterGroup.name}`
      return { ...filterGroup, name: newName }
    })
  }

  useDeepCompareEffect(() => {
    setValue(GOAL_TRACKER_QUESTION_KEYS.FILTER_COLLECTIONS, transformToResponseItemFilters(filters))
  }, [filters])

  const currentValues = getValues()

  useDeepCompareEffect(() => {
    if (activeFilterOption === FilterOptions.NoFilter) {
      clearQueryFilter()
      reset({ ...currentValues, filterCollections: [] })
    }
    setAllowsNextWithoutData(activeFilterOption === FilterOptions.NoFilter)
    reset({ ...currentValues, filterCollections: filters })
  }, [filters, currentValues, activeFilterOption])

  const handleFilterOptionChange = (
    event: React.ChangeEvent<{
      value: string
    }>,
  ): void => {
    if (event.target.value === FilterOptions.NoFilter) {
      clearQueryFilter()
      setAllowsNextWithoutData(true)
      reset({ ...currentValues, filterCollections: [] })
      setActiveFilterOption(FilterOptions.NoFilter)
    }
    if (event.target.value === FilterOptions.ApplyFilter) {
      setAllowsNextWithoutData(false)
      reset({ ...currentValues, filterCollections: filters })
      setActiveFilterOption(FilterOptions.ApplyFilter)
    }
  }

  return (
    <>
      <RadioGroup value={activeFilterOption} onChange={handleFilterOptionChange}>
        <FormControlLabel
          value={FilterOptions.NoFilter}
          control={<Radio />}
          label={formatMessage({ id: 'schemas.goalTracker.filter.noFilter' })}
        />
        <FormControlLabel
          value={FilterOptions.ApplyFilter}
          control={<Radio />}
          label={formatMessage({ id: 'schemas.goalTracker.filter.applyFilters' })}
        />
      </RadioGroup>
      <SelectFilterStep activeFilterOption={activeFilterOption} />
    </>
  )
}
export default GoalTrackerSelectFilterStep
