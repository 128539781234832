import { Checkbox, CheckboxProps } from '@mui/material'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'

type CheckboxCellProps = {
  disableCell?: boolean
} & CheckboxProps

const CheckboxCell: React.FC<CheckboxCellProps> = ({ disableCell, ...props }) => {
  return (
    <ParentCellOrFragment disableCell={disableCell} width={20}>
      <Checkbox data-testid="checkbox-cell" color="primary" size="medium" sx={{ p: 0, mx: 0.5 }} {...props} />
    </ParentCellOrFragment>
  )
}

export default CheckboxCell
