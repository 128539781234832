import { Components } from '@mui/material'

const MuiTableContainer: Components['MuiTableContainer'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      borderRadius: 0,
    },
  },
}

export default MuiTableContainer
