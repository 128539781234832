import { Badge, BadgeProps, Tab as MuiTab, Typography } from '@mui/material'
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs'
import React from 'react'

interface Tab {
  type: string | number
  url: string
  skipQueryParams?: boolean
  label: React.ReactNode
  hidden?: boolean
  badgeText?: string | number
  badgeSx?: BadgeProps['sx']
}

export type TabsProps = {
  tabs: Tab[]
  activeTab: number | string
  handleChange: MuiTabsProps['onChange']
} & MuiTabsProps

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, handleChange, ...props }) => {
  return (
    <MuiTabs
      variant="scrollable"
      value={activeTab}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      data-testid="tabs-container"
      {...props}
    >
      {tabs
        .filter(tab => !tab.hidden)
        .map(tab => (
          <MuiTab
            value={tab.type}
            label={
              <Badge color="primary" badgeContent={tab.badgeText} sx={tab.badgeSx}>
                <Typography variant="button">{tab.label}</Typography>
              </Badge>
            }
            key={tab.type}
            data-testid={'tab-' + tab.type}
          />
        ))}
    </MuiTabs>
  )
}

export default Tabs
