import { TableCellProps, Tooltip, Typography, TypographyProps } from '@mui/material'
import React, { useState } from 'react'
import TextTruncate from 'react-text-truncate'
import ParentCellOrFragment from './ParentCellOrFragment'

export type ValueCellProps = {
  value?: string | number
  maxRows?: number
  disableCell?: boolean
  width?: TableCellProps['width']
} & TypographyProps

const ValueCell: React.FC<ValueCellProps> = ({ value, maxRows = 2, disableCell, width, ...props }) => {
  const [truncated, setTruncated] = useState(false)

  const handleTruncate = () => {
    setTruncated(true)
  }

  return (
    <ParentCellOrFragment disableCell={disableCell} width={width}>
      <Tooltip title={truncated ? value : undefined}>
        <Typography variant="body1" component="span" {...props}>
          <TextTruncate line={maxRows} text={value?.toString() || '-'} onTruncated={handleTruncate} />
        </Typography>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default ValueCell
