import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { ValueChainEmptyStateAlternative } from '@app/src/pages/Product/ProductRequestCard/ProductRequestCard'
import ValueChainView, { View } from '@app/src/pages/Product/ValueChainView'
import { Product, ProductMappingResponse } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import { Alert, Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

type DrawerViewReviewProductValueChainToConnectToNewResponseProps = {
  productToConnect: Product
  mappingRequestId: number
  onSuccess: () => void
} & Omit<DrawerViewProps, 'title'>

const DrawerViewReviewProductValueChainToConnectToNewResponse: React.FC<
  DrawerViewReviewProductValueChainToConnectToNewResponseProps
> = ({ productToConnect, onSuccess, mappingRequestId, ...props }) => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { options, setOptions } = useDrawer()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()

  const { mutate: createResponse, isLoading: isCreatingResponse } = useCreateResource<
    ProductMappingResponse,
    Partial<ProductMappingResponse>
  >({
    options: {
      onError: (error: RequestError) => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const createResponseProductAndConnectProduct = () => {
    createResponse(
      {
        url: endpoints.productMappingResponse,
        body: { productMappingRequestId: mappingRequestId, productId: productToConnect.id },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          queryClient.invalidateQueries(FetchKey.ProductCollection)
          queryClient.invalidateQueries(FetchKey.Product)
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfullyConnectedValueChain' }),
            severity: NotificationSeverity.success,
          })
          onSuccess()
        },
      },
    )
  }

  return (
    <DrawerView
      title={productToConnect.name}
      buttons={[
        {
          label: formatMessage({ id: options.fullScreen ? 'general.exitFullScreen' : 'general.viewFullScreen' }),
          onClick: () => setOptions({ fullScreen: !options.fullScreen }),
          variant: 'outlined',
          endIcon: options.fullScreen ? <FullscreenExit /> : <Fullscreen />,
        },
        {
          label: formatMessage({ id: 'schemas.mappingNode.addValueChain' }),
          onClick: createResponseProductAndConnectProduct,
          loading: isCreatingResponse,
          variant: 'contained',
        },
      ]}
      stackButtons
      fullscreenContent={
        <ValueChainView
          showHeader={false}
          mappingNodes={productToConnect?.mappingNodes ?? []}
          forceView={View.Tree}
          treeViewHeight="100%"
          customEmptyState={<ValueChainEmptyStateAlternative />}
        />
      }
      {...props}
    >
      <Box p={2} height="100%">
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography>{formatMessage({ id: 'schemas.mappingNode.connectToExistingProductAlert1' })}</Typography>
        </Alert>
        <ValueChainView
          mappingNodes={productToConnect?.mappingNodes ?? []}
          forceView={View.Table}
          showHeader={false}
          customEmptyState={<ValueChainEmptyStateAlternative />}
        />
      </Box>
    </DrawerView>
  )
}

export default DrawerViewReviewProductValueChainToConnectToNewResponse
