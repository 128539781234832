import React, { useRef, useState } from 'react'
import { MappingNode } from '@app/src/types/product'
import {
  Button,
  ClickAwayListener,
  ClickAwayListenerProps,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import EditIcon from '@mui/icons-material/Edit'
import { useIntl } from 'react-intl'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

interface Props {
  mappingNode: MappingNode
  editNode: (mappingNode: MappingNode) => void
  deleteNode: (mappingNode: MappingNode) => void
}

const MappingNodeMenuCell: React.FC<Props> = ({ mappingNode, editNode, deleteNode }) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClickAway: ClickAwayListenerProps['onClickAway'] = event => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={handleToggle} size="large" ref={anchorRef}>
        <MoreHorizIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal sx={{ zIndex: 2 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <MenuItem>
                    <Button
                      startIcon={<EditIcon />}
                      onClick={() => {
                        editNode(mappingNode)
                      }}
                    >
                      {formatMessage({ id: 'general.edit' })}
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      startIcon={<DeleteForeverIcon />}
                      onClick={() => {
                        deleteNode(mappingNode)
                      }}
                    >
                      {formatMessage({ id: 'general.delete' })}
                    </Button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default MappingNodeMenuCell
