import { createContext, useContext } from 'react'

export interface Scope {
  organization?: number
  role?: string
  solution?: string
}

export interface Params {
  role: string
  solution: string
  organization: number
}

export interface Options {
  forceNewToken: boolean
}

export type GetToken = (params?: Params, options?: Options) => Promise<string | undefined>

export type AuthenticationContextProps = {
  authenticated: boolean
  logout: () => void
  getToken: GetToken
  scope: Scope
  userId: number | null
}

const AuthenticationContext = createContext<AuthenticationContextProps>({
  authenticated: false,
  logout: () => undefined,
  getToken: async () => undefined,
  scope: { role: undefined, organization: undefined, solution: undefined },
  userId: null,
})

export const useAuthentication = (): AuthenticationContextProps => {
  return useContext(AuthenticationContext)
}

export default AuthenticationContext
