import { Config } from '@app/src/config'

export const NetworkImages = [
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-1.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-2.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-3.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-4.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-5.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-6.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-7.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-8.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-9.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-10.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-11.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-12.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-13.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-14.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-15.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-16.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-17.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-18.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-19.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-20.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-21.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-22.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-23.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-24.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-25.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-26.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-27.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-28.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-29.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-30.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-31.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-32.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-33.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-34.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-35.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-36.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-37.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-38.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-39.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-40.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-41.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-42.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-43.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-44.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-45.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-46.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-47.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-48.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-49.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-50.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-51.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-52.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-53.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-54.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-55.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-56.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-57.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-58.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-59.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-60.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-61.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-62.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-63.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-64.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-65.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-66.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-67.jpeg`,
  `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/Network-Image-68.jpeg`,
]
