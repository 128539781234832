import SimpleSelect, { Option, SimpleSelectProps } from '@app/src/components/Form/Select/SimpleSelect'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ProductMappingStatus } from '@app/src/types/product'

interface Props {
  onChange: SimpleSelectProps<ProductMappingStatus>['onChange']
  value: ProductMappingStatus
  hideNotMapped?: boolean
}

const ProductMappingStatusField: React.FC<Props> = ({ onChange, value, hideNotMapped }) => {
  const [isOpen, setOpen] = useState(false)
  const { formatMessage } = useIntl()
  const { errors } = useFormContext()

  const options: Option<ProductMappingStatus>[] = useMemo(() => {
    const notSelectedOption = {
      label: formatMessage({ id: 'schemas.product.mappingStatusValues.NotSelected' }),
      value: ProductMappingStatus.NotSelected,
    }

    return [
      ...(hideNotMapped
        ? []
        : [
            {
              label: formatMessage({ id: 'schemas.product.mappingStatusValues.NotMapped' }),
              value: ProductMappingStatus.NotMapped,
            },
            notSelectedOption,
          ]),
      {
        label: formatMessage({ id: 'schemas.product.mappingStatusValues.PartlyMapped' }),
        value: ProductMappingStatus.PartlyMapped,
      },
      {
        label: formatMessage({ id: 'schemas.product.mappingStatusValues.FullyMapped' }),
        value: ProductMappingStatus.FullyMapped,
      },
    ]
  }, [hideNotMapped, formatMessage])

  const selectedValue =
    options.find(opt => opt.value === value) ??
    (hideNotMapped
      ? null
      : {
          label: formatMessage({ id: 'schemas.product.mappingStatusValues.NotSelected' }),
          value: ProductMappingStatus.NotSelected,
        })

  return (
    <SimpleSelect<ProductMappingStatus>
      multiple={false}
      onChange={onChange}
      open={isOpen}
      setOpen={setOpen}
      loading={false}
      options={options}
      fieldLabel={formatMessage({ id: 'schemas.product.mappingStatus' })}
      value={selectedValue}
      error={errors?.mappingStatus}
    />
  )
}

export default ProductMappingStatusField
