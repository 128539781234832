import React from 'react'

const EnvironmentIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 16.894c.111 1.625.144 3.362.067 4.989l-.842-.001c.082-1.612.07-3.228-.034-4.838-.862-1.053-1.848-2-2.937-2.82-.52.147-1.06.219-1.6.213a5.854 5.854 0 0 1-3.13-.843c-1.24-.796-1.94-2.106-2.023-3.786a.405.405 0 0 1 .286-.409c1.746-.545 4.95-.725 6.897 4.123a19.299 19.299 0 0 1 2.381 2.127c-.382-3.422-1.238-5.953-2.263-7.821h-.024a2.928 2.928 0 0 1-.113.004c-1.244 0-3.194-.527-4.432-1.867-1.036-1.121-1.395-2.62-1.038-4.332a.407.407 0 0 1 .38-.324c1.903-.098 5.185.558 5.859 5.977a18.455 18.455 0 0 1 1.706 4.28 18.7 18.7 0 0 1 2.11-1.874c1.968-4.887 5.202-4.704 6.963-4.156a.41.41 0 0 1 .287.409c-.086 1.697-.792 3.019-2.043 3.823a5.914 5.914 0 0 1-3.16.851 5.745 5.745 0 0 1-1.628-.218 17.23 17.23 0 0 0-2.295 2.114 29.471 29.471 0 0 1 .627 4.379Zm.067 4.989h4.11l.83-.001h.996a.411.411 0 0 1 .41.407.406.406 0 0 1-.41.407H4.855a.411.411 0 0 1-.41-.407.406.406 0 0 1 .41-.407h7.712Zm7.59-15.658c-.135 1.283-.687 2.244-1.644 2.86-1.074.69-2.468.808-3.465.674a14.71 14.71 0 0 1 2.85-1.58.41.41 0 0 0 .236-.526.407.407 0 0 0-.372-.261.412.412 0 0 0-.158.027 14.25 14.25 0 0 0-2.491 1.318c1.234-2.176 2.955-3.039 5.045-2.512ZM4.472 12.911c-.945-.608-1.49-1.556-1.625-2.823 2.064-.519 3.762.333 4.98 2.48a14.238 14.238 0 0 0-2.459-1.307.412.412 0 0 0-.556.39.405.405 0 0 0 .263.37c.985.417 1.922.94 2.793 1.56-.983.126-2.344.006-3.396-.67Zm.868-7.497c-.8-.866-1.1-1.975-.897-3.3 2.084.008 3.53 1.145 4.256 3.323a11.894 11.894 0 0 0-1.354-1.445.412.412 0 0 0-.684.324c.005.108.054.209.135.281a11.71 11.71 0 0 1 2.02 2.406c-1.05-.087-2.527-.561-3.476-1.589Z"
        fill="#131315"
      />
    </svg>
  )
}

export default EnvironmentIcon
