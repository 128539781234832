import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'

export const SET_FILTER = 'SET_FILTER'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const SET_SORTING = 'SET_SORTING'
export const CLEAN_SORTING = 'CLEAN_SORTING'
export const SET_PAGE = 'SET_PAGE'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'

export interface FilterAction {
  type: typeof SET_FILTER | typeof CLEAR_FILTER
  filterValue?: FilterValue
  filterTarget?: string
}

export interface SortAction {
  type: typeof SET_SORTING | typeof CLEAN_SORTING
  sortTarget?: string
  sortOrder?: SortOrder
}

export type PaginationAction =
  | {
      type: typeof SET_PAGE
      page: number
    }
  | {
      type: typeof SET_PAGE_SIZE
      pageSize: number
    }

export type UseFilterAction = SortAction | FilterAction | PaginationAction

export interface Filter {
  [filterTarget: string]: FilterValue
}

export interface Sort {
  target?: string
  order?: SortOrder
}

export enum SortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export interface Pagination {
  page: number
  pageSize: number
}

export interface UseSort {
  sorting: Sort
  setSorting: SetSorting
  toggleSorting: ToggleSorting
}

export interface UseFilter {
  filter: Filter
}

export interface RawExportPayload {
  filter?: FilterGroup[]
  sort?: Sort
  include?: string[]
  objectType?: string
  riskTypeNames?: Array<string>
  solutionInstanceType?: string
}

export type FilterValue = string | number | Array<string | number> | boolean | undefined
export type SetFilter = (filterTarget: string, filterValue?: FilterValue) => void
export type ClearFilter = () => void
export type SetSorting = (sortTarget: string, sortOrder?: SortOrder) => void
export type ToggleSorting = (sortTarget: string) => void
export type SetPage = (page: number) => void
export type ResetPage = () => void
export type SetPageSize = (pageSize: number) => void
