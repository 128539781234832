import signalR from '@microsoft/signalr'
import { createContext, useContext } from 'react'

export type RegisterType = (userId: number, organizationId: number, solution: string, unregister?: boolean) => void

export type SignalRContextProps = {
  on: signalR.HubConnection['on']
}

const SignalRContext = createContext<SignalRContextProps>({
  on: () => undefined,
})

export const useSignalR = (): SignalRContextProps => {
  return useContext(SignalRContext)
}

export default SignalRContext
