import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { ProviderRisk } from '@app/src/pages/ResourceCollection/Collections/RiskScreening/ExposedCountryRiskMap'
import { ProviderLinkStatus } from '@app/src/types/organizations'
import { ChevronRight } from '@mui/icons-material'
import { IconButton, List, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import TextTruncate from 'react-text-truncate'
import { useDrawer } from '../DrawerContext'
import DrawerViewCompany from './DrawerViewCompany'

export type DrawerViewCountryRiskProps = {
  providerRisks: ProviderRisk[]
} & DrawerViewProps

type GroupedProviders = {
  [key: string]: GroupedProvider
}

type GroupedProvider = {
  riskTypes: Array<ProviderRisk['riskType']>
  provider: ProviderRisk['provider']
}

const CompanyListItem: React.FC<GroupedProvider> = ({ provider, riskTypes }) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()

  return (
    <ListItemButton onClick={() => openDrawer(<DrawerViewCompany company={provider} />)}>
      <ListItemAvatar>
        <CompanyAvatar
          organizationName={provider.name}
          unlinked={provider.linkStatus !== ProviderLinkStatus.Connected}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<TextTruncate line={1} text={provider.name} />}
        secondary={riskTypes.sort().map((riskType, index) => {
          return index
            ? formatMessage(
                { id: 'general.valueWithDotSeparatorBefore' },
                { value: formatMessage({ id: `schemas.risk.riskLocations.${riskType}` }) },
              )
            : formatMessage({ id: `schemas.risk.riskLocations.${riskType}` })
        })}
      />
      <IconButton size="large">
        <ChevronRight />
      </IconButton>
    </ListItemButton>
  )
}

const DrawerViewCountryRisk: React.FC<DrawerViewCountryRiskProps> = ({ providerRisks, ...props }) => {
  const { formatMessage } = useIntl()

  const groupedProviders = providerRisks.reduce<GroupedProviders>((acc, { provider, riskType }) => {
    if (!acc[provider.name]) {
      acc[provider.name] = {
        riskTypes: [],
        provider,
      }
    }
    if (!acc[provider.name].riskTypes.includes(riskType)) {
      acc[provider.name].riskTypes.push(riskType)
    }
    return acc
  }, {})

  return (
    <DrawerView {...props}>
      <Stack py={2} flexGrow={1}>
        <Typography variant="overline" color="textSecondary" ml={2}>
          {formatMessage({ id: 'schemas.risk.companies' }, { count: Object.keys(groupedProviders).length })}
        </Typography>
        <List>
          {Object.values(groupedProviders).map(groupedProvider => (
            <CompanyListItem key={groupedProvider.provider.id} {...groupedProvider} />
          ))}
        </List>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewCountryRisk
