import { FlagOutlined } from '@mui/icons-material'
import { Box, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { SuggestedRiskGuidance } from '@app/src/types/flags'

interface SuggestedRiskGuidanceDrawerContentProps {
  suggestedRiskGuidance: SuggestedRiskGuidance
}

const SuggestedRiskGuidanceDrawerContent: React.FC<SuggestedRiskGuidanceDrawerContentProps> = ({
  suggestedRiskGuidance,
}) => {
  const { formatMessage } = useIntl()

  const negativeEffectCategories = [
    {
      nameKey: `automations.suggestedRiskGuidance.topics.${suggestedRiskGuidance.typeOfRiskDescription.toLowerCase()}`,
      text: suggestedRiskGuidance.riskDescription,
    },
    {
      nameKey: 'automations.suggestedRiskGuidance.topics.regulatory',
      text: suggestedRiskGuidance.regulatoryDescription,
    },
    {
      nameKey: 'automations.suggestedRiskGuidance.topics.reputational',
      text: suggestedRiskGuidance.reputationalDescription,
    },
  ]

  return (
    <Box px={2}>
      <Stack bgcolor={({ palette }) => palette.communication.light} p={3}>
        <Box display="flex" alignItems="flex-start">
          <FlagOutlined color="primary" />
          <Typography variant="subtitle1" ml={1}>
            {suggestedRiskGuidance.introductionTitle}
          </Typography>
        </Box>
        <Typography mt={1}>{suggestedRiskGuidance.introductionDescription}</Typography>
      </Stack>

      {(suggestedRiskGuidance.suggestedRiskNaceCodes?.length ?? 0) > 0 && (
        <>
          <Typography variant="subtitle1" mt={3}>
            {formatMessage({ id: 'automations.suggestedRiskGuidance.industriesCommonlyAffected' })}
          </Typography>
          <ul>
            {suggestedRiskGuidance?.suggestedRiskNaceCodes
              ?.sort((n1, n2) => (n1.naceCode?.description > n2.naceCode?.description ? 1 : -1))
              ?.map(suggestedNaceCode => (
                <li key={suggestedNaceCode.naceCodeId}>{suggestedNaceCode.naceCode?.description}</li>
              ))}
          </ul>
        </>
      )}

      <Typography variant="subtitle1" mt={3}>
        {formatMessage({ id: 'automations.suggestedRiskGuidance.potentialNegativeRiskHeader' })}
      </Typography>
      <Typography variant="body1" mt={2}>
        {suggestedRiskGuidance.potentialNegativeRiskDescription}
      </Typography>
      {negativeEffectCategories
        .filter(f => Boolean(f?.text))
        .map(negativeEffectCategory => (
          <Box mt={2} key={negativeEffectCategory?.nameKey}>
            <Typography variant="subtitle1">{formatMessage({ id: negativeEffectCategory.nameKey })}</Typography>
            {negativeEffectCategory.text}
          </Box>
        ))}
      <Typography variant="subtitle1" mt={3}>
        {formatMessage({ id: 'automations.suggestedRiskGuidance.relatedFrameworksAndRegulations' })}
      </Typography>
      <Box mt={2}>{suggestedRiskGuidance.potentialNegativeRiskRelatedInformation}</Box>
      {(suggestedRiskGuidance.suggestedRiskGuidanceLinks?.length ?? 0) > 0 && (
        <Typography variant="subtitle1" mt={3}>
          {formatMessage({ id: 'automations.suggestedRiskGuidanceLinks.learnMore' })}
        </Typography>
      )}
      {suggestedRiskGuidance.suggestedRiskGuidanceLinks?.map(link => (
        <Box mt={2} key={link?.title}>
          <Link href={link?.url} target="_blank" rel="noopener noreferrer">
            {link?.title}
          </Link>
        </Box>
      ))}
    </Box>
  )
}

export default SuggestedRiskGuidanceDrawerContent
