import { Box, Stack } from '@mui/material'
import React from 'react'

interface RatingDotsProps {
  level?: number
}

const RatingDots: React.FC<RatingDotsProps> = ({ level = 0 }) => {
  return (
    <Stack direction="row" spacing={0.4} alignItems="center">
      {Array.from({ length: 5 }, (_, i) => (
        <Box
          key={i}
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: i < level ? 'secondary.main' : 'grey.400',
          }}
          aria-hidden="true"
        />
      ))}
    </Stack>
  )
}

export default RatingDots
