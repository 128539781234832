import React, { ReactNode } from 'react'
import HoverDialog from '@app/src/components/HoverDialog'

interface GraphHoverTooltipProps {
  content: ReactNode
  children: JSX.Element
}

const GraphHoverDialog: React.FC<GraphHoverTooltipProps> = ({ children, content }) => (
  <HoverDialog content={content} children={children} width={475} positionOffset={[0, -22]} />
)
export default GraphHoverDialog
