import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ReferralContact } from '@app/src/types/resourceExplorer'

export const useReferralContact = (providerId: number) => {
  const {
    items: [referralContact],
    isLoading: isReferralContactsLoading,
    isFetching: isReferralContactsFetching,
  } = useFetchCollectionWithPost<ReferralContact>({
    key: FetchKey.ReferralContactCollection,
    endpoint: endpoints.referralContactCollection,
    payload: {
      filter: [
        {
          name: 'providerId',
          filters: [
            {
              value: providerId,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 1,
      },
      include: ['referralEmailHistory', 'creatorOrganization.image'],
    },
  })
  return { referralContact, isReferralContactsLoading, isReferralContactsFetching }
}
