import React, { createContext, useContext } from 'react'

export type LocaleContextProps = {
  locale: string
  setLocale: React.Dispatch<React.SetStateAction<string>>
}

const LocaleContext = createContext<LocaleContextProps>({
  locale: 'en',
  setLocale: () => 'en',
})

export const useLocale = (): LocaleContextProps => {
  return useContext(LocaleContext)
}

export default LocaleContext
