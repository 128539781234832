import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { AUTOMATION_QUESTION_KEYS } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import { BusinessOutlined, LabelOutlined, SvgIconComponent } from '@mui/icons-material'
import { Button, Card, CardActionArea, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface AutomationTypeCardProps {
  onClick: () => void
  icon: SvgIconComponent
  title: string
  description: string
}

const AutomationTypeCard: React.FC<AutomationTypeCardProps> = ({ onClick, icon, title, description }) => {
  const { formatMessage } = useIntl()
  return (
    <Card elevation={0} variant="outlined" sx={{ height: '100%' }}>
      <CardActionArea
        onClick={onClick}
        sx={{
          height: '100%',
          padding: 4,
        }}
      >
        <Stack textAlign="center" spacing={2} height="100%">
          <Stack
            fontSize={48}
            color="grey.600"
            alignItems="center"
            spacing={3}
            justifyContent="space-between"
            height="100%"
          >
            {React.createElement(icon, { color: 'inherit', fontSize: 'inherit' })}
            <Typography variant="subtitle1" color="textPrimary">
              {title}
            </Typography>
          </Stack>
          <Stack flexGrow={1} height="100%">
            <Typography color="textSecondary">{description}</Typography>
          </Stack>
          <Divider sx={{ width: '100%' }} />
          <Button sx={{ '&:hover': { bgcolor: 'transparent' } }}>{formatMessage({ id: 'general.select' })}</Button>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

const AutomationType: React.FC = () => {
  const { formatMessage } = useIntl()
  const { setActiveStep } = useCreationModalProgress()
  const { setValue } = useFormContext()

  const onTypeSelect = (requestAutomationType: RequestAutomationType) => {
    setActiveStep(currentValue => currentValue + 1)
    setValue(AUTOMATION_QUESTION_KEYS.AUTOMATION_TYPE, requestAutomationType)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <AutomationTypeCard
          onClick={() => onTypeSelect(RequestAutomationType.FOR_EVERY_NEW_PROVIDER)}
          title={formatMessage({ id: 'form.createAutomation.automationType.newCompany' })}
          icon={BusinessOutlined}
          description={formatMessage({ id: 'form.createAutomation.automationType.newCompanyDescription' })}
        />
      </Grid>
      <Grid item xs={6}>
        <AutomationTypeCard
          onClick={() => onTypeSelect(RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY)}
          title={formatMessage({ id: 'form.createAutomation.automationType.category' })}
          icon={LabelOutlined}
          description={formatMessage({ id: 'form.createAutomation.automationType.categoryDescription' })}
        />
      </Grid>
    </Grid>
  )
}
export default AutomationType
