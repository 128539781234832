import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import Table from '@app/src/components/Table'
import { InquiryExportColumnsTransparency } from '@app/src/export-columns/inquiry'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import ProviderInquiryHeader from '@app/src/pages/ResourceCollection/Collections/Request/ProviderInquiryHeader'
import ProviderInquiryRow from '@app/src/pages/ResourceCollection/Collections/Request/ProviderInquiryRow'
import ProviderRequestsFilters from '@app/src/pages/ResourceCollection/Filters/ProviderRequestsFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { Inquiry } from '@app/src/types/resourceExplorer'
import { ResourceTypes } from '@app/src/wf-constants'
import React from 'react'
import { useIntl } from 'react-intl'
import { ViewTypeName } from './ManageRequestsScene'

const allowedFilters = [
  'template.id',
  'status',
  'creatorOrganization.id',
  'request.responseExternalStatus',
  'request.response.id',
  'deadline',
  'activatedAt',
  'creatorUserId',
]

type ProviderRequestsSceneProps = {
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
}

const ProviderRequestsScene: React.FC<ProviderRequestsSceneProps> = ({ tabs, actionButtons = [] }) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const { openDrawer } = useDrawer()

  const basePayload = {
    filter: [],
    sort: sorting,
    include: ['creatorOrganization', 'template', 'template.image', 'request.responses.verifications'],
  }

  const payload = {
    ...basePayload,
    filter: [...basePayload.filter, ...apiFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.Request,
    endpoint: endpoints.inquiriesCollection,
    payload,
  })

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.manageRequests' })}
        tabs={tabs}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Inquiry}
                  count={count}
                  userFilter={apiFilters}
                  exportColumns={InquiryExportColumnsTransparency}
                  rawExportPayload={basePayload}
                />,
              ),
            disabled: tabs?.activeTabParam === ViewTypeName.Overview || isLoading || count === 0,
          },
          ...actionButtons,
        ]}
        filter={<ProviderRequestsFilters allowedFilters={allowedFilters} resetPage={resetPage} />}
      >
        <Table<Inquiry>
          RowComponent={({ row }) => <ProviderInquiryRow row={row} />}
          HeaderComponent={() => <ProviderInquiryHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
          data={items}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ResourceCollectionScene>
    </>
  )
}

export default ProviderRequestsScene
