import * as Sentry from '@sentry/react'
import { Config } from '@app/src/config'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import RequestError from '@app/src/errors/RequestError'
import { useCallback } from 'react'
import {
  ErrorResponse,
  HTTPMethod,
  MethodWithBodyAndMethodType,
  MethodWithBodyType,
  MethodWithoutBodyType,
  OptionsType,
  RequestParameters,
  RequestParametersWithBody,
  UseApiType,
} from '@app/src/types/api'

const isFormData = (value: FormData | unknown): value is FormData => value instanceof FormData

const useApi = (): UseApiType => {
  const { getToken } = useAuthentication()

  const getHeaders = useCallback(
    async (headers?: RequestInit['headers'], skipContentTypeHeader?: boolean): Promise<RequestInit['headers']> => {
      return {
        Authorization: `Bearer ${await getToken()}`,
        ...(!skipContentTypeHeader ? { 'Content-Type': 'application/json;  charset=UTF-8' } : {}),
        ...(headers ?? {}),
      }
    },
    [getToken],
  )

  async function getBlobBody(response: Response) {
    const contentDispositionHeader = response.headers.get('content-disposition') ?? ''
    const pattern = new RegExp(/filename=(.*);/)
    const fileName = pattern.exec(contentDispositionHeader)?.[1] ?? 'File'
    return { fileName, blob: await response.blob() }
  }

  const getBody = async (response: Response, isBlob?: boolean): Promise<unknown> => {
    if (response.headers.get('Content-Length') === '0') return {}
    try {
      if (isBlob) {
        return await getBlobBody(response)
      }
      if (response.status === 204) {
        return
      }
      return await response.json()
    } catch (jsonError: unknown) {
      if (jsonError instanceof Error) {
        throw new RequestError(response.status, null, jsonError.message)
      } else {
        throw jsonError
      }
    }
  }

  const request: MethodWithBodyAndMethodType = async <ResponseBody, RequestBody = ResponseBody>(
    method: HTTPMethod,
    requestParameters: RequestParametersWithBody<RequestBody>,
    options?: OptionsType,
  ): Promise<ResponseBody> => {
    try {
      //TODO remove /api when backend is updated
      const url = `${Config.API_URL}${requestParameters.url}`

      const skipContentTypeHeader = isFormData(requestParameters.body)
      const body = isFormData(requestParameters.body) ? requestParameters.body : JSON.stringify(requestParameters.body)

      const response = await fetch(url, {
        method: method?.toUpperCase(),
        ...requestParameters.config,
        body,
        headers: await getHeaders(requestParameters.config?.headers, skipContentTypeHeader),
      })

      const responseBody = await getBody(response, options?.isBlob)
      if (!response.ok) {
        throw new RequestError(response.status, responseBody as ErrorResponse, '')
      }
      return responseBody as ResponseBody // here we get the actual data
    } catch (error: unknown) {
      if (error instanceof Error) {
        Sentry.captureException(error)
      }
      throw error
    }
  }

  const get: MethodWithoutBodyType = async <ResponseBody>(
    requestParameters: RequestParameters,
    options?: OptionsType,
  ) => {
    return request<ResponseBody>('get', requestParameters, options)
  }

  const post: MethodWithBodyType = async <ResponseBody, RequestBody>(
    requestParameters: RequestParametersWithBody<RequestBody>,
    options?: OptionsType,
  ) => {
    return request<ResponseBody, RequestBody>('post', requestParameters, options)
  }

  const put: MethodWithBodyType = async <ResponseBody, RequestBody>(
    requestParameters: RequestParametersWithBody<RequestBody>,
  ) => {
    return request<ResponseBody, RequestBody>('put', requestParameters)
  }

  const patch: MethodWithBodyType = async <ResponseBody, RequestBody>(
    requestParameters: RequestParametersWithBody<RequestBody>,
  ) => {
    return request<ResponseBody, RequestBody>('patch', requestParameters)
  }

  const deleteMethod: MethodWithBodyType = async <ResponseBody, RequestBody>(
    requestParameters: RequestParametersWithBody<RequestBody>,
  ) => {
    return request<ResponseBody, RequestBody>('delete', requestParameters)
  }

  return {
    request,
    get,
    post,
    put,
    delete: deleteMethod,
    patch,
  }
}

export default useApi
