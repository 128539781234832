import Dialog from '@app/src/components/Dialog'
import React from 'react'
import { useIntl } from 'react-intl'
import { br } from '@app/src/utils/translationMarkup'

interface SetPublicContactDialogProps {
  isSetPublicContactDialogOpen: boolean
  closeSetPublicContactDialog: () => void
  addUserAsPublicContact: () => Promise<void>
  goToPersonalSettings: () => void
}

const SetPublicContactDialog: React.FC<SetPublicContactDialogProps> = ({
  isSetPublicContactDialogOpen,
  closeSetPublicContactDialog,
  addUserAsPublicContact,
  goToPersonalSettings,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog
      open={isSetPublicContactDialogOpen}
      onClose={closeSetPublicContactDialog}
      title={formatMessage({
        id: 'schemas.editUser.sharingOfDataHeader',
      })}
      content={formatMessage({ id: 'schemas.editUser.sharingOfDataInfo' }, { br })}
      buttons={[
        {
          label: formatMessage({
            id: 'navbar.userSettings',
          }),
          onClick: () => {
            closeSetPublicContactDialog()
            goToPersonalSettings()
          },
          variant: 'text',
        },
        {
          label: formatMessage({ id: 'general.confirm' }),
          variant: 'text',
          onClick: () => {
            closeSetPublicContactDialog()
            return addUserAsPublicContact()
          },
        },
      ]}
    />
  )
}

export default SetPublicContactDialog
