import RequestItemContent from '@app/src/pages/Questionnaire/Provider/Report/RequestItemContent'
import RequestItemTitle from '@app/src/pages/Questionnaire/RequestItemTitle'
import {
  RequestItem as RequestItemType,
  ResponseItemWithPreviousCorrectionNeededItem,
} from '@app/src/types/resourceExplorer'
import { Box } from '@mui/material'
import React from 'react'

interface RequestItemProps {
  requestItem: RequestItemType
  responseItem?: ResponseItemWithPreviousCorrectionNeededItem
  sectionNumber: number
  itemNumber: number
}

const RequestItem: React.FC<RequestItemProps> = ({ requestItem, responseItem, sectionNumber, itemNumber }) => {
  return (
    <Box mt={5} sx={({ palette }) => ({ border: `1px solid ${palette.grey[300]}`, borderRadius: 2 })}>
      <RequestItemTitle
        item={requestItem}
        sectionNumber={sectionNumber}
        itemNumber={itemNumber}
        responseItem={responseItem}
      />
      <Box flexGrow={1} data-testid="request">
        <RequestItemContent
          item={requestItem}
          responseItem={responseItem}
          sectionNumber={sectionNumber}
          itemNumber={itemNumber}
        />
      </Box>
    </Box>
  )
}

export default RequestItem
