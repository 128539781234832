import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import LoadingButton from '@app/src/components/LoadingButton'
import RouteLeavingGuard from '@app/src/components/RouteLeavingGuard'
import SimpleBreadcrumbs from '@app/src/components/SimpleBreadcrumbs'
import Avatar from '@app/src/components/Ui/Avatar'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import CreateProductRequestModal from '@app/src/pages/Product/CreateProductRequestModal'
import { Product, ProductIndustry, ProductSceneView } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { ContentCut, Delete, LiquorOutlined, Send } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router-dom'
import AutomationAlert from './AutomationAlert'

interface ProductSceneContainerProps {
  view: ProductSceneView
  onCreateRequest?: () => void
  product?: Product
  isUpdating?: boolean
  responseSubmittedAt?: string
  kpiCards?: JSX.Element
}

const ProductSceneContainer: React.FC<PropsWithChildren<ProductSceneContainerProps>> = ({
  children,
  view,
  product,
  isUpdating,
  onCreateRequest,
  kpiCards,
}) => {
  const { formatMessage } = useIntl()
  const isDirty = false
  const history = useHistory()
  const [isCreateProductRequestDialogOpen, openCreateProductRequestDialog, closeCreateProductRequestDialog] =
    useDialogState(false)
  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useDialogState()
  const { mutate: deleteResource, isLoading: isDeleting } = useDeleteResource()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { hasPermission } = usePermissions()

  const productName = product?.name || formatMessage({ id: 'schemas.product.create.title' })

  const deleteProduct = (id: number) => {
    deleteResource(
      { url: endpoints.deleteProduct(id) },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'schemas.product.delete.success' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.Product)
          queryClient.invalidateQueries(FetchKey.MappingNodeCollection)
          queryClient.invalidateQueries(FetchKey.MappingRequests)
          history.push(generatePath(paths.productsCollection))
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const getButtons = (): JSX.Element | null => {
    if (view === ProductSceneView.Edit || view === ProductSceneView.Create) {
      return null
    }

    if (hasPermission(Permissions.TRANSPARENCY_USER)) {
      return null
    }

    return (
      <>
        <Box mx={2}>
          <LoadingButton loading={isUpdating} size="large" startIcon={<Delete />} onClick={openConfirmationDialog}>
            {formatMessage({ id: 'schemas.product.delete.deleteProduct' })}
          </LoadingButton>
        </Box>
        {hasPermission(Permissions.FULL_DASHBOARD_ACCESS) && (
          <LoadingButton
            loading={isUpdating}
            variant="contained"
            startIcon={<Send />}
            size="large"
            onClick={openCreateProductRequestDialog}
            data-testid="create-mapping-request"
          >
            {formatMessage({ id: 'schemas.mappingRequest.create' })}
          </LoadingButton>
        )}
      </>
    )
  }

  return (
    <>
      <AutomationAlert />
      <RouteLeavingGuard when={isDirty} navigate={path => history.push(path)} shouldBlockNavigation={() => isDirty} />
      <Helmet>
        <title>{formatMessage({ id: 'resourceTypes.product' })}</title>
      </Helmet>
      <Stack px={4} pt={2}>
        <SimpleBreadcrumbs
          crumbs={[
            {
              label: formatMessage({
                id: 'resourceTypes.product',
              }),
              to: generatePath(paths.productsCollection),
            },
            {
              label: productName,
            },
          ]}
        />
      </Stack>
      <Box py={2} px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="flex-end">
            <Stack direction="row" mr={2} spacing={2} alignItems="center">
              {view !== ProductSceneView.Create && (
                <Avatar sx={({ palette }) => ({ backgroundColor: palette.success.light })}>
                  {product?.industry === ProductIndustry.Textile ? (
                    <ContentCut color="secondary" />
                  ) : (
                    <LiquorOutlined color="secondary" />
                  )}
                </Avatar>
              )}
              <Typography variant="h2">{productName}</Typography>
            </Stack>
          </Box>
          <Box display="flex">{getButtons()}</Box>
        </Box>
        {kpiCards}
      </Box>
      <Box display="flex" justifyContent="center" flexGrow={1} bgcolor="grey.100">
        {children}
      </Box>
      {product && (
        <>
          <CreationModalProgressContextProvider>
            <CreateProductRequestModal
              product={product}
              onClose={() => {
                closeCreateProductRequestDialog()
                onCreateRequest?.()
              }}
              open={isCreateProductRequestDialogOpen}
            />
          </CreationModalProgressContextProvider>

          <Dialog open={isConfirmationDialogOpen} maxWidth="sm">
            <DialogTitle>{formatMessage({ id: 'schemas.product.delete.title' })}</DialogTitle>
            <DialogContent>{formatMessage({ id: 'schemas.product.delete.description' }, { br: <br /> })}</DialogContent>

            <DialogActions>
              <Button
                color="error"
                disabled={isDeleting}
                onClick={() => deleteProduct(product.id)}
                data-testid="delete-product-confirm"
              >
                {formatMessage({ id: 'general.delete' })}
              </Button>
              <Button onClick={closeConfirmationDialog}>{formatMessage({ id: 'general.cancel' })}</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

export default ProductSceneContainer
