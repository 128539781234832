import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import {
  ExternalVerificationStatus,
  InternalVerificationStatus,
  RequestItem,
  Response,
  ResponseItem,
} from '@app/src/types/resourceExplorer'
import { Box, Divider, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme: Theme) => ({
  infoSection: {
    marginBottom: theme.spacing(4),
  },
  verificationHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formField: {
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  item?: Response | ResponseItem
  requestItem?: RequestItem
}

const VerificationForm = ({ item, requestItem }: Props): JSX.Element => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { watch, control, register } = useFormContext()

  const externalVerificationStatus: ExternalVerificationStatus = watch('externalVerificationStatus')
  const internalVerificationStatus: InternalVerificationStatus = watch('internalVerificationStatus')

  return (
    <div>
      <input type="hidden" name="id" ref={register()} />
      {requestItem && (
        <div className={classes.infoSection}>
          <Typography variant="body1">{formatMessage({ id: 'reporting.verification.requestItem' })}</Typography>
          <Typography variant="subtitle1">{requestItem.description}</Typography>
        </div>
      )}
      {item?.request && item?.request?.title && (
        <>
          <Typography variant="body1">{formatMessage({ id: 'reporting.verification.response' })}</Typography>
          <Typography variant="subtitle1" paragraph>
            {item?.request && item?.request?.title}
          </Typography>
          <Divider light />
        </>
      )}
      <Box mt={2}>
        <div className={classes.verificationHeader}>
          <Typography variant="body1">{formatMessage({ id: 'reporting.verification.verification' })}</Typography>
          <div className={classes.divider}>|</div>
          <Typography variant="body1">
            {formatMessage({ id: 'reporting.verification.verificationDescription' })}
          </Typography>
        </div>
        <Box mt={2}>
          <Select<ExternalVerificationStatus>
            name="externalVerificationStatus"
            control={control}
            fieldLabel={formatMessage({ id: 'reporting.verification.verification' })}
            variant="outlined"
            options={[
              {
                value: ExternalVerificationStatus.NotSet,
                label: formatMessage({ id: 'reporting.statuses.ToBeVerified' }),
                disabled: true,
              },
              {
                value: ExternalVerificationStatus.Approved,
                label: formatMessage({ id: 'reporting.statuses.Approved' }),
              },
              {
                value: ExternalVerificationStatus.CorrectionNeeded,
                label: formatMessage({ id: 'reporting.statuses.CorrectionNeeded' }),
              },
            ]}
          />
        </Box>
        <TextField
          color="secondary"
          variant="outlined"
          className={classes.formField}
          fullWidth
          disabled={externalVerificationStatus === ExternalVerificationStatus.NotSet}
          label={formatMessage({ id: 'general.comment' })}
          name="externalVerificationComment"
          inputRef={register()}
          multiline
          maxRows={4}
        />
      </Box>
      <Divider light />
      <Box mt={2}>
        <div className={classes.verificationHeader}>
          <Typography variant="body1">
            {formatMessage({ id: 'reporting.verification.internalVerification' })}
          </Typography>
          <div className={classes.divider}>|</div>
          <Typography variant="body1">
            {formatMessage({ id: 'reporting.verification.internalVerificationDescription' })}
          </Typography>
        </div>
        <Box mt={2}>
          <Select<InternalVerificationStatus>
            name="internalVerificationStatus"
            control={control}
            fieldLabel={formatMessage({ id: 'reporting.verification.verification' })}
            variant="outlined"
            options={[
              {
                value: InternalVerificationStatus.NotSet,
                label: formatMessage({ id: 'reporting.statuses.ToBeVerified' }),
                disabled: true,
              },
              {
                value: InternalVerificationStatus.Approved,
                label: formatMessage({ id: 'reporting.statuses.Approved' }),
              },
              {
                value: InternalVerificationStatus.NotApproved,
                label: formatMessage({ id: 'reporting.statuses.NotApproved' }),
              },
            ]}
          />
        </Box>
        <TextField
          color="secondary"
          variant="outlined"
          className={classes.formField}
          fullWidth
          inputRef={register()}
          name="internalVerificationComment"
          label={formatMessage({ id: 'general.comment' })}
          multiline
          maxRows={4}
          disabled={internalVerificationStatus === InternalVerificationStatus.NotSet}
        />
      </Box>
    </div>
  )
}

export default VerificationForm
