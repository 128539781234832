import Markdown from '@app/src/components/Markdown'
import ClassificationRow, {
  sortClassificationBasedOnRuleType,
} from '@app/src/pages/QuestionEditor/AnswerClassification/ClassificationRow'
import { AssessmentRule, Question } from '@app/src/types/resourceExplorer'
import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface QuestionClassificationProps {
  question: Question
  assessmentRules: AssessmentRule[]
}

const QuestionClassification: React.FC<QuestionClassificationProps> = ({ question, assessmentRules }) => {
  const { formatMessage } = useIntl()
  const { optionClassifications, numberClassifications, answerClassifications } = sortClassificationBasedOnRuleType(
    question.questionAnswerClassificationRules,
  )

  if (optionClassifications.length || numberClassifications.length || answerClassifications.length) {
    return (
      <Box display="flex" mb={3}>
        <Paper
          elevation={0}
          sx={{ border: 1, borderColor: 'grey.300', borderRadius: 2, overflow: 'hidden', flex: 1, maxHeight: '300px' }}
        >
          <Box p={2} ml={2}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: 14, lineHeight: '24px' }}>
              {` ${question.kpiName || question.questionText}`}
            </Typography>
          </Box>
          <Box p={2} pt={0}>
            <Stack spacing={0}>
              {optionClassifications.map(item => (
                <ClassificationRow
                  key={`${item?.rank}-${item?.value}`}
                  item={item}
                  translationKey="templateBuilder.answerClassifications.optionValue"
                  questionId={question.id}
                  rules={assessmentRules}
                />
              ))}
              {numberClassifications.map(item => (
                <ClassificationRow
                  key={`${item?.rank}-${item?.value}`}
                  item={item}
                  translationKey="templateBuilder.answerClassifications.numberValue"
                  questionId={question.id}
                  rules={assessmentRules}
                />
              ))}
              {answerClassifications.map(item => (
                <ClassificationRow
                  key={`${item?.rank}-${item?.value}`}
                  item={item}
                  translationKey={`templateBuilder.answerClassifications.classificationsValues.${item?.value}`}
                  questionId={question.id}
                  rules={assessmentRules}
                />
              ))}
            </Stack>
          </Box>
        </Paper>
        <Box width="35%" pt={3} pl={3} pr={10}>
          {question.accessorGuidance ? (
            <>
              <Box borderLeft="5px solid black" paddingLeft={3}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600, fontSize: 14, lineHeight: '24px', mb: 1 }}>
                  {formatMessage({ id: 'assessments.methodology.whyItMatters' })}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 14,
                  lineHeight: '24px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'flex',
                  WebkitLineClamp: 7,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <Markdown>{question.accessorGuidance}</Markdown>
              </Typography>
            </>
          ) : (
            <Box height="100%" />
          )}
        </Box>
      </Box>
    )
  }
  return null
}

export default QuestionClassification
