import { ResponseItem } from '@app/src/types/resourceExplorer'
import React, { createContext, useContext } from 'react'
import { ResponseItemView } from './ResponseItemContextProvider'

export interface ResponseItemContextProps {
  responseItemView: ResponseItemView
  setResponseItemView: React.Dispatch<React.SetStateAction<ResponseItemView>>
  previousAnswer?: ResponseItem
  hideInputField: boolean
  setQuestionSkippedFields: () => void
  sectionNumber: number
  itemNumber: number
}

const ResponseItemContext = createContext<undefined | ResponseItemContextProps>(undefined)

export function useResponseItemContext(): ResponseItemContextProps {
  const context = useContext(ResponseItemContext)
  if (context === undefined) {
    throw new Error('useResponseItemContext must be used within ResponseItemContextProvider')
  }
  return context
}

export default ResponseItemContext
