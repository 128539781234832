import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import InquiryHeader from '@app/src/pages/ResourceCollection/Collections/Request/AccessorInquiryHeader'
import {
  removeQuestionFilters,
  transformFilterNames,
} from '@app/src/pages/ResourceCollection/Filters/StatisticsConstants'
import {
  FilterGroup,
  Operators,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Inquiry, InquiryStatus, ResponseItem } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import React, { useMemo } from 'react'
import { dataHubAllowedFilters } from '.'
import { AccessorInquiryRow } from '../../Request/AccessorInquiryRow'
import ResponseItemCompanyHeader from '../AllAnswers/ResponseItemCompanyHeader'
import ResponseItemCompanyRow from '../AllAnswers/ResponseItemCompanyRow'
import { UserSelection, useDataInsightsModal } from './DataInsightsModalContext'
import { SummaryChartDataTypes } from './useSummaryChartConfigs'

const getSelectionFilters = (
  questionId?: number,
  selection?: UserSelection,
  cleanFilters?: FilterGroup[],
): FilterGroup[] => {
  const hasFilterForCompaniesAnswered = selection?.summaryType?.includes(SummaryChartDataTypes.CompaniesAnswered)
  const hasFilterForWeDontHaveThis = selection?.summaryType?.includes(
    SummaryChartDataTypes.CompaniesAnsweredDontHaveThis,
  )
  return [
    ...(cleanFilters ?? []),
    RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
    ...insertIf(!(hasFilterForWeDontHaveThis && hasFilterForCompaniesAnswered), {
      name: 'cannotAnswer',
      filters: [
        {
          value: hasFilterForWeDontHaveThis ?? false,
          operator: Operators.EqualTo,
        },
      ],
    }),
    ...insertIf(Boolean(questionId), {
      name: 'requestItem.questionId',
      filters: [
        {
          value: questionId,
          operator: Operators.EqualTo,
        },
      ],
    }),
    ...insertIf(Boolean(selection?.answer?.length) && !selection?.summaryType, {
      name: 'answer',
      filters: [
        {
          value: selection?.answer,
          operator: Operators.In,
        },
      ],
    }),
    ...insertIf(Boolean(selection?.period), {
      name: 'response.request.periodName',
      filters: [
        {
          value: selection?.period,
          operator: Operators.EqualTo,
        },
      ],
    }),
  ]
}
const DataInsightsModalTable: React.FC = () => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { statistics, userSelection } = useDataInsightsModal()
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)

  const cleanFilters = removeQuestionFilters(userFilters)
  const renamedFiltersForInquiries = transformFilterNames(cleanFilters)

  const isCompaniesNotAnswered =
    userSelection?.summaryType?.includes(SummaryChartDataTypes.CompaniesNotAnswered) ?? false

  const selectionFilters = useMemo(
    () => getSelectionFilters(statistics?.id, userSelection, cleanFilters),
    [statistics, userSelection, cleanFilters],
  )

  const responseItemsPayload = {
    filter: selectionFilters,
    sort: sorting,
    include: [
      'requestItem',
      'response.request',
      'verifications',
      'creatorOrganization',
      'response.request.creatorOrganization',
      'response.request.responderOrganization',
      'response.request.subscriptions.target',
      'response.request.subscriptions.target.country.name',
      'requestItem.unit',
      'requestItem.questionType',
      'requestItem.section',
      'files',
      'flagRule.suggestedRiskGuidance',
      'fileScreeningResults',
      'response.request.target.organization',
    ],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const inquiriesPayload = {
    include: [
      'creatorOrganization',
      'request.responses',
      'request.responses.verifications',
      'template',
      'template.image',
      'provider.organization',
    ],
    sort: sorting,
    filter: [
      ...renamedFiltersForInquiries,
      {
        name: 'status',
        filters: [
          {
            value: InquiryStatus.Requested,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'template.sections.questions.id',
        filters: [
          {
            operator: Operators.EqualTo,
            value: statistics?.id,
          },
        ],
      },
      {
        name: 'periodName',
        filters: [
          {
            operator: Operators.EqualTo,
            value: userSelection?.period,
          },
        ],
      },
    ],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: responseItems,
    count: responseItemsCount,
    isLoading: isResponseItemsLoading,
    isFetching: isResponseItemsFetching,
    isError: isResponseItemsError,
  } = useFetchCollectionWithPost<ResponseItem>({
    key: FetchKey.Answer,
    endpoint: endpoints.responseItemsCollection,
    payload: responseItemsPayload,
    options: {
      enabled: Boolean(statistics) && !isCompaniesNotAnswered,
    },
  })

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.Request,
    endpoint: endpoints.inquiriesCollection,
    payload: inquiriesPayload,
    options: {
      enabled: Boolean(statistics) && isCompaniesNotAnswered,
    },
  })

  if (isCompaniesNotAnswered) {
    return (
      <>
        <Table<Inquiry>
          RowComponent={({ row }) => <AccessorInquiryRow row={row} />}
          HeaderComponent={() => <InquiryHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
          data={items}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </>
    )
  }

  return (
    <>
      <Table<ResponseItem>
        RowComponent={props => <ResponseItemCompanyRow {...props} drawer={false} />}
        HeaderComponent={() => <ResponseItemCompanyHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
        data={responseItems}
        isLoading={isResponseItemsLoading || isResponseItemsFetching}
        count={responseItemsCount}
        isError={isResponseItemsError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </>
  )
}

export default DataInsightsModalTable
