import CloseIcon from '@mui/icons-material/Close'
import { BoxProps, IconButton, Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import React, { MutableRefObject, ReactNode } from 'react'
import ActionButtons, { ActionButtonsProps } from '../ActionButtons'
import { ChevronLeft } from '@mui/icons-material'

export enum ButtonAlignment {
  Default,
  Right,
}

const useStyles = makeStyles({
  content: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})

export type DrawerProps = {
  title?: ReactNode
  description?: string
  closeDrawer?: () => void
  buttons?: ActionButtonsProps['buttons']
  buttonAlignment?: ButtonAlignment
  customHeader?: ReactNode
  children?: React.ReactNode
  containerRef?: MutableRefObject<HTMLDivElement | null>
  onClickBack?: () => void
  buttonGroupProps?: BoxProps
}

const DrawerTemplate: React.FC<DrawerProps> = ({
  children,
  title,
  description,
  closeDrawer,
  buttons,
  buttonAlignment,
  customHeader,
  containerRef,
  onClickBack,
  buttonGroupProps = {},
}) => {
  const classes = useStyles()
  const { spacing } = useTheme()

  const justifyButtons = buttonAlignment === ButtonAlignment.Right ? 'end' : 'space-between'
  return (
    <>
      {!customHeader && (
        <>
          {title && (
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Box display="flex" alignItems="center">
                {onClickBack && (
                  <IconButton onClick={onClickBack} sx={{ marginRight: 1 }}>
                    <ChevronLeft />
                  </IconButton>
                )}
                <Typography variant="h4">{title}</Typography>
              </Box>
              <IconButton onClick={closeDrawer} size="large">
                <CloseIcon fontSize="small" color="primary" />
              </IconButton>
            </Box>
          )}
          {description && (
            <Typography variant="body1" paragraph>
              {description}
            </Typography>
          )}
        </>
      )}

      {customHeader ?? null}

      <Box flexGrow={1} display="flex" className={classes.content}>
        <div ref={containerRef} className={classes.content} data-testid="drawer-content">
          {children}
        </div>
      </Box>
      {buttons?.length ? (
        <Box
          display="flex"
          justifyContent={justifyButtons}
          columnGap={spacing(1)}
          alignItems="center"
          mt={2}
          {...buttonGroupProps}
        >
          <ActionButtons buttons={buttons} />
        </Box>
      ) : null}
    </>
  )
}

export default DrawerTemplate
