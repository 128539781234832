import Permissions from '@app/src/auth/permissions'
import Logout from '@app/src/components/Logout'
import AssessmentsScene from '@app/src/pages/Assessments/AssessmentsScene'
import ConfigurationsScene from '@app/src/pages/Configurations/ConfigurationsScene'
import SustainabilityLibraryScene from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import DashboardsScene from '@app/src/pages/Dashboards/DashboardsScene'
import FinanceDashboard from '@app/src/pages/Dashboards/GroupCompaniesDashboard'
import InvestmentDashboard from '@app/src/pages/Dashboards/InvestmentDashboard'
import SourcingDashboard from '@app/src/pages/Dashboards/SourcingDashboard'
import TransparencyDashboard from '@app/src/pages/Dashboards/TransparencyDashboard'
import NotificationsScene from '@app/src/pages/Notifications/NotificationsScene'
import CreateProductScene from '@app/src/pages/Product/CreateProductScene'
import ProductScene from '@app/src/pages/Product/ProductScene'
import ReceivedMappingRequestScene from '@app/src/pages/Product/ReceivedMappingRequestScene'
import EditQuestion from '@app/src/pages/QuestionEditor/EditQuestion'
import Questionnaire from '@app/src/pages/Questionnaire'
import BaseAssessmentScene from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/BaseAssessmentScene'
import ContactsCollection from '@app/src/pages/ResourceCollection/Collections/ContactsCollection'
import DataHubScene from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import EvaluationsCollection from '@app/src/pages/ResourceCollection/Collections/EvaluationsCollection'
import ManageRequestsScene from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import MappingNodeCollection from '@app/src/pages/ResourceCollection/Collections/MappingNodeCollection'
import ProductMappingCompaniesCollection from '@app/src/pages/ResourceCollection/Collections/ProductMappingCompaniesCollection'
import ProductMappingContactsCollection from '@app/src/pages/ResourceCollection/Collections/ProductMappingContactsCollection'
import ProductsCollection from '@app/src/pages/ResourceCollection/Collections/ProductsCollection'
import ProviderRiskCollection from '@app/src/pages/ResourceCollection/Collections/ProviderRiskCollection'
import ProvidersScene from '@app/src/pages/ResourceCollection/Collections/Providers/ProvidersScene'
import ReceivedMappingRequestCollection from '@app/src/pages/ResourceCollection/Collections/ReceivedMappingRequestCollection'
import ReceivedMappingResponseCollection from '@app/src/pages/ResourceCollection/Collections/ReceivedMappingResponseCollection'
import RiskScreeningScene from '@app/src/pages/ResourceCollection/Collections/RiskScreening/RiskScreeningScene'
import SentMappingRequestCollection from '@app/src/pages/ResourceCollection/Collections/SentMappingRequestCollection'
import SourcingProviderProgressCollection from '@app/src/pages/ResourceCollection/Collections/SourcingProviderProgressCollection'
import SubscriptionsCollection from '@app/src/pages/ResourceCollection/Collections/SubscriptionsCollection'
import TransparencyProviderProgressCollection from '@app/src/pages/ResourceCollection/Collections/TransparencyProviderProgressCollection'
import ResourceExplorerScene from '@app/src/pages/ResourceExplorer'
import SolutionSelectorScene from '@app/src/pages/SolutionSelector/SolutionSelectorScene'
import TemplateBuilderWrapper from '@app/src/pages/TemplateBuilder/TemplateBuilder'
import UserSettingsScene from '@app/src/pages/UserSettingsScene'
import paths from '@app/src/wf-constants/paths'
import { RouteConfig } from '@sentry/react/types/reactrouter'
import { RouteProps } from 'react-router-dom'
import AssessmentsExploreScene from './pages/Assessments/AssessmentExplore/AssessmentsExploreScene'
import SustainabilityProfilePage from './pages/Transparency/SustainabilityProfile'

export interface RouteWithPermission {
  permission?: Permissions | Permissions[] // will be allowed if the user has any of the permission in the array
  path: string
  name: string
  exact: boolean
  component: RouteProps['component']
}

export const Routes: RouteWithPermission[] = [
  {
    name: 'home',
    path: '/',
    exact: true,
    component: SolutionSelectorScene,
  },
  {
    name: 'configurations',
    path: paths.configurations,
    exact: false,
    component: ConfigurationsScene,
    permission: Permissions.ACCESS_SOLUTION_CONFIGURATION,
  },
  {
    name: 'sustainability-library',
    path: paths.sustainabilityLibrary,
    exact: false,
    component: SustainabilityLibraryScene,
    permission: [Permissions.SOURCING_USER, Permissions.FINANCE_USER, Permissions.INVESTMENT_USER],
  },
  {
    name: 'resourceExplorerScene',
    path: paths.resourceExplorer,
    exact: true,
    component: ResourceExplorerScene,
  },
  {
    name: 'providersScene',
    path: paths.providers,
    exact: true,
    component: ProvidersScene,
    permission: [
      Permissions.SOURCING_USER,
      Permissions.FINANCE_USER,
      Permissions.INVESTMENT_USER,
      Permissions.TRANSPARENCY_USER,
    ],
  },
  {
    name: 'productsCollection',
    path: paths.productsCollection,
    exact: true,
    component: ProductsCollection,
  },
  {
    name: 'valueChainCollection',
    path: paths.mappingNodeCollection,
    exact: true,
    component: MappingNodeCollection,
    permission: [Permissions.SOURCING_USER],
  },
  {
    name: 'mappingRequestCollection',
    path: paths.mappingRequestCollection,
    exact: true,
    component: ReceivedMappingRequestCollection,
    permission: [Permissions.PRODUCT_MAPPING_RESPOND_ACCESS],
  },
  {
    name: 'sentMappingRequestCollection',
    path: paths.sentMappingRequestCollection,
    exact: true,
    component: SentMappingRequestCollection,
    permission: [Permissions.SOURCING_USER],
  },
  {
    name: 'mappingRequest',
    path: paths.mappingRequest,
    exact: true,
    component: ReceivedMappingRequestScene,
    permission: [Permissions.PRODUCT_MAPPING_RESPOND_ACCESS],
  },
  {
    name: 'createProduct',
    path: paths.createProduct,
    exact: true,
    component: CreateProductScene,
    permission: [Permissions.SOURCING_USER, Permissions.FINANCE_USER, Permissions.INVESTMENT_USER],
  },
  {
    name: 'product',
    path: paths.product,
    exact: true,
    component: ProductScene,
  },
  {
    name: 'productMappingCompanies',
    path: paths.productMappingCompanies,
    exact: true,
    component: ProductMappingCompaniesCollection,
    permission: [Permissions.TRANSPARENCY_USER],
  },
  {
    name: 'productMappingContacts',
    path: paths.productMappingContacts,
    exact: true,
    component: ProductMappingContactsCollection,
    permission: [Permissions.TRANSPARENCY_USER],
  },
  {
    name: 'logout',
    path: paths.logout,
    exact: true,
    component: Logout,
  },
  {
    name: 'userSettings',
    path: paths.userSettings,
    exact: false,
    component: UserSettingsScene,
  },
  {
    name: 'reporting',
    path: paths.reporting,
    exact: true,
    component: Questionnaire,
  },
  {
    name: 'contactsCollection',
    path: paths.contacts,
    exact: true,
    component: ContactsCollection,
  },
  {
    name: 'dataHubScene',
    path: paths.dataHubCollection,
    exact: true,
    component: DataHubScene,
    permission: Permissions.DATA_HUB_ACCESS,
  },
  {
    name: 'receivedMappingResponseCollection',
    path: paths.receivedMappingResponseCollection,
    exact: true,
    component: ReceivedMappingResponseCollection,
  },
  {
    name: 'subscriptionsCollection',
    path: paths.subscriptions,
    exact: true,
    component: SubscriptionsCollection,
    permission: Permissions.TRANSPARENCY_USER,
  },
  {
    name: 'evaluations',
    path: paths.evaluations,
    exact: true,
    component: EvaluationsCollection,
    permission: Permissions.EVALUATION_ACCESS,
  },
  {
    name: 'dashboard',
    path: paths.dashboard,
    exact: true,
    component: DashboardsScene,
  },
  {
    name: 'sourcingDashboard',
    path: paths.sourcingDashboard,
    exact: true,
    component: SourcingDashboard,
    permission: Permissions.SOURCING_USER,
  },
  {
    name: 'investmentDashboard',
    path: paths.investmentDashboard,
    exact: true,
    component: InvestmentDashboard,
    permission: Permissions.INVESTMENT_USER,
  },
  {
    name: 'financeDashboard',
    path: paths.financeDashboard,
    exact: true,
    component: FinanceDashboard,
    permission: Permissions.FINANCE_USER,
  },
  {
    name: 'notifications',
    path: paths.notifications,
    exact: true,
    component: NotificationsScene,
  },
  {
    name: 'transparencyOverview',
    path: paths.transparency,
    exact: true,
    component: TransparencyDashboard,
    permission: Permissions.TRANSPARENCY_USER,
  },
  {
    name: 'transparencyDashboard',
    path: paths.transparencyDashboard,
    exact: true,
    component: TransparencyDashboard,
    permission: Permissions.TRANSPARENCY_USER,
  },
  {
    name: 'editTemplate',
    path: paths.editTemplate,
    exact: true,
    component: TemplateBuilderWrapper,
  },
  {
    name: 'editQuestion',
    path: paths.questionnaireBuilderCustomQuestion,
    exact: true,
    component: EditQuestion,
    permission: Permissions.WF_EMAIL_ACCESS,
  },
  {
    name: 'manageRequestCollection',
    path: paths.manageRequest,
    exact: true,
    component: ManageRequestsScene,
  },
  {
    name: 'sustainabilityProfile',
    path: paths.sustainabilityProfile,
    exact: true,
    component: SustainabilityProfilePage,
  },
  {
    name: 'transparencyProviderProgress',
    path: paths.transparencyProviderProgress,
    exact: true,
    component: TransparencyProviderProgressCollection,
    permission: Permissions.TRANSPARENCY_USER,
  },
  {
    name: 'sourcingProviderProgress',
    path: paths.sourcingProviderProgress,
    exact: true,
    component: SourcingProviderProgressCollection,
    permission: Permissions.SOURCING_USER,
  },
  {
    name: 'providerRiskCollection',
    path: paths.providerRisk,
    exact: true,
    component: ProviderRiskCollection,
  },
  {
    name: 'riskScreeningCollection',
    path: paths.riskScreening,
    exact: true,
    component: RiskScreeningScene,
  },
  {
    name: 'baseAssessment',
    path: paths.baseAssessment,
    exact: true,
    component: BaseAssessmentScene,
  },
  {
    name: 'assessments',
    path: paths.assessments,
    exact: true,
    component: AssessmentsScene,
  },
  {
    name: 'assessmentExploreScene',
    path: paths.exploreAssessment,
    exact: true,
    component: AssessmentsExploreScene,
  },
]

export const SimplifiedRoutes: RouteConfig[] = Routes.map(({ path }) => ({ path }))
