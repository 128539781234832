import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { Detail } from '@app/src/components/ResourceDetails/ResourceDetailsElements/Detail'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { useAccount } from '@app/src/context/AccountContext'
import { Product, ProductSceneView } from '@app/src/types/product'
import paths from '@app/src/wf-constants/paths'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import qs from 'qs'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link, generatePath } from 'react-router-dom'

interface Props {
  product?: Product
  view: ProductSceneView
  isSavingProduct?: boolean
}

const ProductDetails: React.FC<Props> = ({ product, isSavingProduct }) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { formatValueToDate } = useGetFormattedDate()
  if (!product) return null
  return (
    <Box>
      <Stack mb={2}>
        <Typography variant="h5" gutterBottom>
          {formatMessage({ id: 'schemas.product.productDetails' })}
        </Typography>
        {product?.createdAt && account?.organization && (
          <Typography variant="body2" color="textSecondary">
            {formatMessage(
              { id: 'schemas.mappingRequest.productStamp' },
              {
                creator: account.organization.name,
                date: formatValueToDate({ value: product.createdAt }),
              },
            )}
          </Typography>
        )}
      </Stack>
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Detail label={formatMessage({ id: 'schemas.product.gtin' })}>
              <ValueCell value={product.gtin} disableCell />
            </Detail>
            <Detail label={formatMessage({ id: 'schemas.product.productNumber' })}>
              <ValueCell value={product.productNumber} disableCell />
            </Detail>
            <Detail label={formatMessage({ id: 'schemas.product.countryOfOrigin' })}>
              {product?.country?.countryCode ? (
                <CountryCell countryCode={product?.country?.countryCode} disableCell />
              ) : (
                <Typography>{formatMessage({ id: 'general.notSelected' })}</Typography>
              )}
            </Detail>
            <Detail label={formatMessage({ id: 'schemas.product.mappingStatus' })}>
              <CategoryCell
                value={formatMessage({ id: `schemas.product.mappingStatusValues.${product.mappingStatus}` })}
                disableCell
              />
            </Detail>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Detail label={formatMessage({ id: 'schemas.product.purchaseOrder' })}>
              <ValueCell value={product.purchaseOrder} disableCell />
            </Detail>
            <Detail label={formatMessage({ id: 'schemas.product.productCustomId' })}>
              <ValueCell value={product.productCustomId} disableCell />
            </Detail>
            <Detail label={formatMessage({ id: 'schemas.product.responsibleSupplier' })}>
              {product.provider ? (
                <CompanyCell company={product.provider} disableCell drawer />
              ) : (
                <Typography>{formatMessage({ id: 'general.notSelected' })}</Typography>
              )}
            </Detail>
            <Detail label={formatMessage({ id: 'schemas.product.productStatus' })}>
              <CategoryCell
                value={formatMessage({ id: `schemas.product.productStatusValues.${product.productStatus}` })}
                disableCell
              />
            </Detail>
          </Stack>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          disabled={isSavingProduct}
          variant="outlined"
          size="large"
          component={Link}
          startIcon={<EditIcon />}
          to={{
            pathname: generatePath(paths.product, { id: product?.id }),
            search: qs.stringify({ view: ProductSceneView.Edit }),
          }}
        >
          {formatMessage({ id: 'general.edit' })}
        </Button>
      </Box>
    </Box>
  )
}

export default ProductDetails
