import Permissions, { usePermissions } from '@app/src/auth/permissions'
import RequestTypeSelection from '@app/src/components/CreateNewRequestModal/RequestTypeSelection'
import { Periods } from '@app/src/components/EvaluationsWizard/PeriodField'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { ReportType, useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import {
  AUTOMATION_QUESTION_KEYS,
  AutomationFormData,
} from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { insertIf } from '@app/src/utils/helpersTs'
import { Logos } from '@app/src/wf-constants'
import { AttachMoneyOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const AutomationQuestionnaireType: React.FC = () => {
  const { formatMessage } = useIntl()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { setActiveStep } = useCreationModalProgress()
  const { setValue } = useFormContext<AutomationFormData>()
  const { account } = useAccount()
  const { hasPermission } = usePermissions()

  const currentOrg = accessibleOrganizations.find(org => org?.organization?.id === account?.organization?.id)
  const sfdrPermission = hasPermission(Permissions.INVESTMENT_USER)

  const setTypeAndGoToNext = (reportType: ReportType) => {
    setValue(AUTOMATION_QUESTION_KEYS.REPORT_TYPE, reportType)
    setValue(AUTOMATION_QUESTION_KEYS.TEMPLATES, [])
    setValue(AUTOMATION_QUESTION_KEYS.DATE_PERIOD, Periods.Yearly)
    setValue(AUTOMATION_QUESTION_KEYS.DATE_PERIOD, undefined)
    setActiveStep(currentValue => currentValue + 1)
  }

  const requestTypeCards = [
    {
      onClick: () => setTypeAndGoToNext(ReportType.WF),
      title: formatMessage({ id: 'form.createRequest.mainMenu.wfReportTitle' }),
      description: formatMessage({ id: 'form.createRequest.mainMenu.wfReportBody' }),
      logo: Logos.WorldfavorLogoSquareFilledBlack,
      whiteBg: true,
    },
    {
      onClick: () => setTypeAndGoToNext(ReportType.COMPANY),
      title: formatMessage(
        { id: 'form.createRequest.mainMenu.companyReportTitle' },
        { companyName: account?.organization?.name },
      ),
      description: formatMessage({ id: 'form.createRequest.mainMenu.companyReportBody' }),
      logo: currentOrg?.organization?.image?.url,
    },
    ...insertIf(sfdrPermission, {
      onClick: () => setTypeAndGoToNext(ReportType.SFDR_WITH_COMPANIES),
      title: formatMessage(
        { id: 'form.createRequest.mainMenu.sfdrReportTitle' },
        { companyName: account?.organization?.name },
      ),
      description: formatMessage({ id: 'form.createRequest.mainMenu.sfdrReportBody' }),
      icon: <AttachMoneyOutlined />,
    }),
  ]

  return (
    <Box width={1000}>
      <RequestTypeSelection requestTypeCards={requestTypeCards} />
    </Box>
  )
}
export default AutomationQuestionnaireType
