import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS } from '@app/src/components/CreateNewRequestModal/Common/SelectFilterStep'
import { OptionIcon } from '@app/src/components/Form/Select'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import useQueryFilters, { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { QueryParamType } from '@app/src/hooks/queryState'
import useActorTypes from '@app/src/hooks/useActorTypes'
import { AUTOMATION_QUESTION_KEYS } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import CategoryFilter from '@app/src/pages/ResourceCollection/Filters/CategoryFilter'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSelect from '@app/src/pages/ResourceCollection/Filters/FilterSelect'
import OptionWithLabel from '@app/src/pages/ResourceCollection/Filters/OptionWithLabel'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Category, ProviderStandardCategoryNames } from '@app/src/types/categories'
import { SortOrder } from '@app/src/types/filter'
import { User } from '@app/src/types/resourceExplorer'
import { getProviderCategoryOptions, MAPPING_NODE_TIER_OPTIONS } from '@app/src/utils/providerCategoryOptions'
import { Chip, Grid, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import useDeepCompareEffect from 'use-deep-compare-effect'

const getUniqueId = (filterValues: string[], categories: Category[]) => {
  const firstFilterValue = filterValues[0]
  return categories.find(c => c.options?.some(op => op.id.toString() === firstFilterValue))?.id
}

const mapToQueryParamType = (filter: FilterGroup, categories: Category[]): QueryParamType => {
  const firstSection = filter.filters[0]
  const filterSectionValue = firstSection.value
  if (!filterSectionValue) return
  const filterValues = Array.isArray(filterSectionValue)
    ? filterSectionValue.map(f => f.toString())
    : [filterSectionValue.toString()]

  const uniqueId = getUniqueId(filterValues, categories)

  return {
    name: filter.name,
    value: filterValues,
    operator: firstSection.operator,
    ...(uniqueId ? { intersect: true, uniqueId: uniqueId } : {}),
  }
}

const AutomationCategories: React.FC = () => {
  const { setValue, getValues } = useFormContext()
  const { formatMessage } = useIntl()
  const { renderWithPermission } = usePermissions()
  const initialFilterValues: FilterGroup[] | undefined = useMemo(
    () => getValues(AUTOMATION_QUESTION_KEYS.CATEGORY_FILTERS),
    [],
  )

  const { setQueryFilters } = useQueryFilters(CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS)

  const filters = useGetApiQueryFilters(CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS)
  const { productMapping } = useFlags()
  const { items: actorTypes } = useActorTypes()

  useDeepCompareEffect(() => setValue(AUTOMATION_QUESTION_KEYS.CATEGORY_FILTERS, filters), [filters])

  const { data: categories } = useFetchResourceWithPost<Category[]>({
    key: FetchKey.Category,
    endpoint: endpoints.categoriesWithIncludes,
    body: ['options'],
  })

  useDeepCompareEffect(() => {
    if (!initialFilterValues || !categories) return

    const filterQueryParams = initialFilterValues.map(f => mapToQueryParamType(f, categories))
    setQueryFilters(filterQueryParams)
  }, [initialFilterValues, categories])

  const { items: colleagues } = useFetchCollectionWithPost<User>({
    key: FetchKey.Colleague,
    endpoint: endpoints.colleagues,
    payload: {
      filter: [],
      include: ['*'],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 9999,
      },
      sort: {
        order: SortOrder.ASCENDING,
        target: 'name',
      },
    },
  })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'form.createAutomation.automationType.generalCategories' })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Filter operator={Operators.In} name="country.id">
          {({ value, onChange, filterName }) => (
            <FilterFacetSelect
              size="small"
              multiple
              facetsParam={{
                key: [FetchKey.Provider, filterName],
                endpoint: endpoints.countriesWithFacets,
                facetsParam: [{ name: 'name' }],
              }}
              filterName={filterName}
              disableAutoFilter
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
              value={[value].flat()}
            />
          )}
        </Filter>
      </Grid>
      <Grid item xs={6}>
        <Filter operator={Operators.In} name="ownerUserId">
          {({ value, onChange }) => (
            <FilterSelect
              size="small"
              multiple
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
              value={[value].flat()}
              options={
                colleagues?.map(user => ({
                  label: user.name,
                  value: user.id,
                })) ?? []
              }
            />
          )}
        </Filter>
      </Grid>
      <Grid item xs={6}>
        <Filter operator={Operators.In} name={ProviderStandardCategoryNames.ActivityStatus}>
          {({ value, onChange, filterName }) => (
            <FilterSelect
              size="small"
              multiple
              renderOption={(props, option, { selected }): JSX.Element => (
                <li {...props}>
                  <OptionIcon selected={selected} multiple />
                  {option?.label !== undefined
                    ? formatMessage({ id: `schemas.provider.activityStatusValues.${option.label}` })
                    : ''}
                </li>
              )}
              options={
                getProviderCategoryOptions(filterName)?.map(categoryOption => ({
                  label: String(categoryOption),
                  value: categoryOption,
                })) ?? []
              }
              renderTags={(value, getTagProps) =>
                value?.map(
                  (option, index) =>
                    option && (
                      <Chip
                        {...getTagProps({ index })}
                        key={option.value.toString() || index}
                        label={formatMessage({
                          id: `schemas.provider.activityStatusValues.${option.label}`,
                        })}
                        size="small"
                      />
                    ),
                )
              }
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
              value={[value].flat()}
            />
          )}
        </Filter>
      </Grid>
      <Grid item xs={6}>
        <Filter operator={Operators.In} name={ProviderStandardCategoryNames.FinalRiskRating}>
          {({ value, onChange, filterName }) => (
            <FilterSelect
              size="small"
              multiple
              renderOption={(props, option, { selected }): JSX.Element => (
                <li {...props}>
                  <OptionIcon selected={selected} multiple />
                  <FinalRiskRatingIcon value={option?.label} />
                  {option?.label !== undefined
                    ? formatMessage({
                        id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                      })
                    : ''}
                </li>
              )}
              options={
                getProviderCategoryOptions(filterName)?.map(categoryOption => ({
                  label: String(categoryOption),
                  value: categoryOption,
                })) ?? []
              }
              renderTags={(value, getTagProps) =>
                value?.map(
                  (option, index) =>
                    option && (
                      <Chip
                        {...getTagProps({ index })}
                        key={option.value.toString() || index}
                        icon={<FinalRiskRatingIcon value={option.label} />}
                        label={formatMessage({
                          id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                        })}
                        size="small"
                      />
                    ),
                )
              }
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
              value={[value].flat()}
            />
          )}
        </Filter>
      </Grid>
      <Grid item xs={6}>
        <Filter operator={Operators.In} name={ProviderStandardCategoryNames.Priority}>
          {({ value, onChange, filterName }) => (
            <FilterSelect
              size="small"
              multiple
              renderOption={(props, option, { selected }): JSX.Element => (
                <li {...props}>
                  <OptionIcon selected={selected} multiple />
                  {option?.label !== undefined
                    ? formatMessage({ id: `schemas.provider.priorityValues.${option.label}` })
                    : ''}
                </li>
              )}
              options={
                getProviderCategoryOptions(filterName)?.map(categoryOption => ({
                  label: String(categoryOption),
                  value: categoryOption,
                })) ?? []
              }
              renderTags={(value, getTagProps) =>
                value?.map(
                  (option, index) =>
                    option && (
                      <Chip
                        {...getTagProps({ index })}
                        key={option.value.toString() || index}
                        label={formatMessage({
                          id: `schemas.provider.priorityValues.${option.label}`,
                        })}
                        size="small"
                      />
                    ),
                )
              }
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
              value={[value].flat()}
            />
          )}
        </Filter>
      </Grid>
      <Grid item xs={6}>
        <Filter operator={Operators.In} name={ProviderStandardCategoryNames.ApprovalStatus}>
          {({ value, onChange, filterName }) => (
            <FilterSelect
              size="small"
              multiple
              renderOption={(props, option, { selected }): JSX.Element => (
                <li {...props}>
                  <OptionIcon selected={selected} multiple />
                  {option?.label !== undefined
                    ? formatMessage({
                        id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                      })
                    : ''}
                </li>
              )}
              options={
                getProviderCategoryOptions(filterName)?.map(categoryOption => ({
                  label: String(categoryOption),
                  value: categoryOption,
                })) ?? []
              }
              renderTags={(value, getTagProps) =>
                value?.map(
                  (option, index) =>
                    option && (
                      <Chip
                        {...getTagProps({ index })}
                        key={option.value.toString() || index}
                        label={formatMessage({
                          id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                        })}
                        size="small"
                      />
                    ),
                )
              }
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
              value={[value].flat()}
            />
          )}
        </Filter>
      </Grid>
      {renderWithPermission({
        [Permissions.SOURCING_USER]: (
          <>
            <Grid item xs={6}>
              <Filter operator={Operators.In} name={ProviderStandardCategoryNames.SupplierUsage}>
                {({ value, onChange, filterName }) => (
                  <FilterSelect
                    size="small"
                    multiple
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <li {...props}>
                        <OptionIcon selected={selected} multiple />
                        {option?.label !== undefined
                          ? formatMessage({ id: `schemas.provider.supplierUsageValues.${option.label}` })
                          : ''}
                      </li>
                    )}
                    options={
                      getProviderCategoryOptions(filterName)?.map(categoryOption => ({
                        label: String(categoryOption),
                        value: categoryOption,
                      })) ?? []
                    }
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Chip
                              {...getTagProps({ index })}
                              key={option.value.toString() || index}
                              label={formatMessage({
                                id: `schemas.provider.supplierUsageValues.${option.label}`,
                              })}
                              size="small"
                            />
                          ),
                      )
                    }
                    onChange={value => onChange(value)}
                    fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Grid>
            <Grid item xs={6}>
              <Filter operator={Operators.In} name={ProviderStandardCategoryNames.Tier}>
                {({ value, onChange, filterName }) => (
                  <FilterSelect
                    size="small"
                    multiple
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <li {...props}>
                        <OptionIcon selected={selected} multiple />
                        {option?.value !== undefined
                          ? formatMessage(
                              {
                                id: `schemas.provider.tierValues`,
                              },
                              { tier: option.label },
                            )
                          : ''}
                      </li>
                    )}
                    options={
                      getProviderCategoryOptions(filterName)?.map(categoryOption => ({
                        label: String(categoryOption),
                        value: categoryOption,
                      })) ?? []
                    }
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Chip
                              {...getTagProps({ index })}
                              key={option.value.toString() || index}
                              label={formatMessage(
                                {
                                  id: `schemas.provider.tierValues`,
                                },
                                { tier: option.label },
                              )}
                              size="small"
                            />
                          ),
                      )
                    }
                    onChange={value => onChange(value)}
                    fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Grid>
          </>
        ),
      })}
      {Boolean(categories?.length) && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'form.createAutomation.automationType.customCategories' })}
            </Typography>
          </Grid>
          {categories?.map(category => (
            <Grid item xs={6} key={category.id}>
              <Filter uniqueId={category.id?.toString()} intersect operator={Operators.In} name="categoryOptions.id">
                {({ value, onChange }) => (
                  <CategoryFilter category={category} value={[value].flat()} onChange={onChange} />
                )}
              </Filter>
            </Grid>
          ))}
        </>
      )}
      {productMapping && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'form.createAutomation.automationType.productMapping' })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Filter operator={Operators.In} name="mappingNodes.actorTypeModel.id">
              {({ value, onChange }) => (
                <FilterSelect
                  size="small"
                  multiple
                  groupBy={option => option.additionalText ?? ''}
                  renderOption={(props, option, { selected }) => (
                    <OptionWithLabel
                      key={Number(option.value)}
                      props={props}
                      label={option?.label}
                      multiple
                      selected={selected}
                    />
                  )}
                  options={actorTypes.map(actorType => ({
                    label: formatMessage({ id: `schemas.mappingNode.actorTypeValues.${actorType.name}` }),
                    value: actorType.id,
                    additionalText: actorType.actorTypeGroup
                      ? formatMessage({ id: `schemas.mappingNode.actorTypeGroups.${actorType.actorTypeGroup}` })
                      : formatMessage({ id: `schemas.product.productIndustry.${actorType.industry}` }),
                  }))}
                  renderTags={(value, getTagProps) =>
                    value?.map(
                      (option, index) =>
                        option && (
                          <Chip
                            {...getTagProps({ index })}
                            key={option?.value?.toString() || index}
                            label={option?.label}
                            size="small"
                          />
                        ),
                    )
                  }
                  onChange={value => onChange(value)}
                  fieldLabel={formatMessage({ id: 'schemas.mappingNode.actorType' })}
                  value={[value].flat()}
                />
              )}
            </Filter>
          </Grid>
          <Grid item xs={6}>
            <Filter operator={Operators.In} name="mappingNodes.tier">
              {({ value, onChange }) => (
                <FilterSelect
                  size="small"
                  multiple
                  options={
                    MAPPING_NODE_TIER_OPTIONS?.map(tier => ({
                      label: tier.toString(),
                      value: tier.toString(),
                    })) ?? []
                  }
                  onChange={value => onChange(value)}
                  fieldLabel={formatMessage({ id: 'schemas.mappingNode.tier' })}
                  value={[value].flat()}
                />
              )}
            </Filter>
          </Grid>
        </>
      )}
    </Grid>
  )
}
export default AutomationCategories
