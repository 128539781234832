import { AlertProps } from '@mui/material'
import { createContext, ReactNode, useContext } from 'react'

export type SnackbarProps = {
  severity: AlertProps['color']
  message: ReactNode
  disableAutoClose?: boolean
}

export type SnackbarContextProps = {
  showSnackbar: (snackbarOptions: SnackbarProps) => void
}

const SnackbarContext = createContext<SnackbarContextProps>({
  showSnackbar: () => undefined,
})

export const useSnackbar = (): SnackbarContextProps => {
  return useContext(SnackbarContext)
}

export default SnackbarContext
