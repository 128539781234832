import RouterLink from '@app/src/components/RouterLink'
import ChangePasswordForm from '@app/src/pages/UserSettingsScene/ChangePasswordForm'
import UserSettingsForm from '@app/src/pages/UserSettingsScene/UserSettingsForm'
import { Box, Paper, Theme, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { Route, Switch, generatePath, useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    maxWidth: '100vw',
  },
  paper: {
    padding: theme.spacing(4),
    // width: '100%',
    margin: `0 ${theme.spacing(2)}`,
  },
  infoText: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

const SETTINGS = 'settings'
const PASSWORD = 'password'

const UserSettingsScene = (): JSX.Element => {
  const classes = useStyles()

  const { formatMessage } = useIntl()
  const { path, params } = useRouteMatch<{ page?: string }>()

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'userSettings.title' })}</title>
      </Helmet>
      <Grid container spacing={3} className={classes.container} data-testid="networks-scene">
        <Grid item xs={12}>
          <Typography variant="h3">{formatMessage({ id: 'userSettings.title' })}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box component={Paper} display="flex" flexDirection="column" p={2}>
            <Box px={2} py={1}>
              <RouterLink
                to={generatePath(path, { page: SETTINGS })}
                color={params?.page === SETTINGS ? 'secondary' : 'primary'}
                variant="body1"
              >
                {formatMessage({ id: 'userSettings.profile' })}
              </RouterLink>
            </Box>
            <Box px={2} py={1}>
              <RouterLink
                to={generatePath(path, { page: PASSWORD })}
                color={params?.page === PASSWORD ? 'secondary' : 'primary'}
                variant="body1"
              >
                {formatMessage({ id: 'userSettings.changePassword' })}
              </RouterLink>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper className={classes.paper}>
            <Switch>
              <Route
                exact
                path={generatePath(path, { page: SETTINGS })}
                render={(): JSX.Element => (
                  <div>
                    <UserSettingsForm />
                  </div>
                )}
              />
              <Route
                exact
                path={generatePath(path, { page: PASSWORD })}
                render={(): JSX.Element => (
                  <>
                    <Typography variant="body1" className={classes.infoText}>
                      {formatMessage({ id: 'userSettings.passwordLogoutWarning' })}
                    </Typography>
                    <div>
                      <ChangePasswordForm />
                    </div>
                  </>
                )}
              />
            </Switch>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default UserSettingsScene
