import { useCompaniesStatisticsFilter } from '@app/src/context/CompaniesStatisticsFilterContext'
import { Ownership } from '@app/src/context/CompaniesStatisticsFilterContextProvider'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useFiltersContext } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FilterValue } from '@app/src/types/filter'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Chip, Stack } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { companiesListAllowedFilters } from './CompaniesList'

const FiltersSection = () => {
  const { setSelectedOwnership } = useCompaniesStatisticsFilter()
  const { setQueryFilter, clearQueryFilter } = useFiltersContext()
  const filters = useGetApiQueryFilters(companiesListAllowedFilters)
  const { formatMessage } = useIntl()

  const getFilterLabel = (property: string, value: FilterValue): string => {
    if (Array.isArray(value)) {
      if (property === 'priority') {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.priority` })}: ${value
          .map(v =>
            formatMessage({
              id: `schemas.provider.priorityValues.${v}`,
            }),
          )
          .join(', ')}`
      }
      if (property === 'tier') {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.tier` })}: ${value
          .map(v => formatMessage({ id: 'schemas.provider.tierValues' }, { tier: v }))
          .join(', ')}`
      }
      if (property === 'supplierUsage') {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.usage` })}: ${value
          .map(v =>
            formatMessage({
              id: `schemas.provider.supplierUsageValues.${v}`,
            }),
          )
          .join(', ')}`
      }
      if (property === 'finalRiskRating') {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.finalRiskRating` })}: ${value
          .map(v =>
            formatMessage({
              id: `schemas.provider.finalRiskRatingValues.${v}`,
            }),
          )
          .join(', ')}`
      }
      if (property === 'providerApprovalStatus') {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.approvalStatus` })}: ${value
          .map(v =>
            formatMessage({
              id: `schemas.provider.providerApprovalStatusValues.${v}`,
            }),
          )
          .join(', ')}`
      }
      return ''
    }
    if (property === 'ownerUserId') {
      return formatMessage({ id: `dashboard.sourcing.companyStatistics.myCompanies` })
    }
    return ''
  }

  return (
    <Stack direction="row" flexWrap="wrap">
      {filters.map(filter => (
        <Chip
          sx={{ mr: 1, my: 0.5 }}
          key={filter.name}
          label={getFilterLabel(filter.name, filter.filters[0].value)}
          onDelete={() => setQueryFilter(filter.name, '', Operators.In)}
        />
      ))}
      <Button
        startIcon={<ClearIcon />}
        size="small"
        onClick={() => {
          clearQueryFilter()
          setSelectedOwnership(Ownership.All)
        }}
      >
        {formatMessage({ id: `dashboard.sourcing.companyStatistics.clearAllFilters` })}
      </Button>
    </Stack>
  )
}

export default FiltersSection
