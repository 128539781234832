import { Alert, Box, Typography } from '@mui/material'
import TextField from '@app/src/components/Ui/TextField'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

type CustomQuestionnairFlagProps = {
  startOfName: string
  defaultValues: {
    contentTitle?: string
    content?: string
  }
  isTemplateShared?: boolean
}

const CustomQuestionnaireFlag: React.FC<CustomQuestionnairFlagProps> = ({
  startOfName,
  defaultValues,
  isTemplateShared,
}) => {
  const { formatMessage } = useIntl()
  const { register, errors } = useFormContext()

  return (
    <>
      <Typography variant="h6" mt={4} paragraph>
        {formatMessage({ id: 'automations.internalNoteSectionHeader' })}
      </Typography>

      <Alert severity="info">
        {formatMessage({
          id: isTemplateShared ? 'automations.internalNoteAlertShared' : 'automations.internalNoteAlert',
        })}
      </Alert>

      <Box mt={4}>
        <TextField
          inputRef={register({
            maxLength: {
              value: 255,
              message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 255 }),
            },
          })}
          required
          label={formatMessage({ id: 'automations.nameOfTheFlagLabel' })}
          error={Boolean(errors.contentTitle)}
          helperText={errors.contentTitle?.message}
          name={`${startOfName}.contentTitle`}
          fullWidth
          defaultValue={defaultValues.contentTitle}
        />
      </Box>

      <Box mt={2}>
        <TextField
          inputRef={register}
          label={formatMessage({ id: 'automations.descriptionLabel' })}
          name={`${startOfName}.content`}
          multiline
          fullWidth
          minRows={3}
          defaultValue={defaultValues.content}
        />
      </Box>
    </>
  )
}

export default CustomQuestionnaireFlag
