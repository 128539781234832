import FlagAvatar from '@app/src/components/Flags/FlagAvatar'
import FlagSuggestedRiskAvatar from '@app/src/components/Flags/FlagSuggestedRiskAvatar'
import AnswerWithFlagCell from '@app/src/components/Table/Cells/AnswerWithFlagCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import IconCell from '@app/src/components/Table/Cells/IconCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { ReportFlagType } from '@app/src/types/flags'
import { QuestionnaireTemplateSection, Response } from '@app/src/types/resourceExplorer'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '.'
import DetailsFlagOverviewDialog from './DetailsFlagOverviewDialog'

type DataHubInsightsDetailsRowProps = {
  row: Response
  sections: QuestionnaireTemplateSection[]
}

const DataHubInsightsDetailsRow: React.FC<DataHubInsightsDetailsRowProps> = ({ row: response, sections }) => {
  const { formatMessage } = useIntl()
  const [open, openDialog, closeDialog] = useDialogState(false)
  const filters = useGetApiQueryFilters(dataHubAllowedFilters)

  const questionsFilterIds = filters?.find(filter => filter.name === 'requestItem.questionId')?.filters?.[0]?.value
  const isWorldfavorRule =
    response.items.find(i => i?.flagRule?.creatorOrganizationId === WF_ORGANIZATION_ID) !== undefined

  const flagRuleTemplateItemId = response.items
    .filter(item => item.flag !== ReportFlagType.NotSet && item.flagRule?.questionId)
    .map(item => item.flagRule?.questionId)

  const filteredSections = sections?.map(section => ({
    ...section,
    questions: section.questions
      .filter(question => flagRuleTemplateItemId.includes(question.id))
      .map(question => ({ ...question, isWorldfavorRule })),
  }))

  const responseItemsToShow =
    Array.isArray(questionsFilterIds) && questionsFilterIds.length
      ? response.items.filter(responseItem =>
          (questionsFilterIds as string[]).includes(String(responseItem.requestItem.questionId)),
        )
      : response.items

  const flagCount =
    Array.isArray(questionsFilterIds) && questionsFilterIds.length
      ? flagRuleTemplateItemId.filter(id => (questionsFilterIds as string[]).includes(String(id)))
      : flagRuleTemplateItemId

  return (
    <TableRow>
      <CompanyCell company={response?.request?.targetAliasObject} drawer maxRows={2} />
      <>
        <IconCell
          icon={
            isWorldfavorRule ? (
              <FlagSuggestedRiskAvatar
                size={AvatarSize.Small}
                isEnabled={Boolean(flagCount.length)}
                onClick={flagCount.length ? openDialog : undefined}
              />
            ) : (
              <FlagAvatar
                size={AvatarSize.Small}
                isEnabled={Boolean(flagCount.length)}
                onClick={flagCount.length ? openDialog : undefined}
              />
            )
          }
          title={String(flagCount.length)}
        />
        <DetailsFlagOverviewDialog
          open={open}
          title={formatMessage(
            { id: isWorldfavorRule ? 'statistics.suggestedRisksWithCount' : 'statistics.flaggedRisksWithCount' },
            { count: flagCount.length },
          )}
          onClose={closeDialog}
          sections={filteredSections}
        />
      </>
      {...responseItemsToShow.map(item => (
        <AnswerWithFlagCell
          item={item}
          key={item.id}
          maxRows={2}
          responderName={response?.request?.targetAliasObject.name}
        />
      ))}
    </TableRow>
  )
}

export default DataHubInsightsDetailsRow
