import EmailPreview from '@app/src/components/ReferralContact/EmailPreview'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { Organization } from '@app/src/types/organizations'
import React from 'react'
import { useIntl } from 'react-intl'

interface RequestReminderEmailPreviewProps {
  responder: Organization
  respondersCount: number
}

function RequestReminderEmailPreview({ responder, respondersCount }: RequestReminderEmailPreviewProps) {
  const { account } = useAccount()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { formatMessage } = useIntl()

  const currentOrg = accessibleOrganizations.find(
    org => org?.organization?.id === account?.organization?.id,
  )?.organization

  const currentOrgName = currentOrg?.name

  if (!responder) return null

  return (
    <EmailPreview
      isReminderEmail
      provider={responder}
      header={formatMessage(
        { id: 'form.requestsReminder.emailHeader' },
        { currentOrgName, inquiryCount: respondersCount },
      )}
      description={formatMessage(
        { id: 'form.requestsReminder.emailDescription' },
        { currentOrgName, providerName: responder.name },
      )}
      ctaText={formatMessage({ id: 'form.requestsReminder.emailCTA' })}
    />
  )
}

export default RequestReminderEmailPreview
