import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import RequestItemSkeleton from './RequestItem'

type SectionSkeletonProps = {
  howMany?: number
}

const SectionSkeleton = ({ index }: Omit<SectionSkeletonProps, 'howMany'> & { index: number }) => {
  return (
    <>
      <Box display="flex" mt={index > 1 ? 7 : 0} alignSelf="stretch">
        <Box flexGrow={1}>
          <Typography variant="h6">
            <Skeleton variant="text" />
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" ml={3} flexGrow={9}>
          <Typography variant="h6" paragraph>
            <Skeleton variant="text" width="50%" />
          </Typography>
          <Typography variant="body1">
            <Skeleton variant="text" width="20%" />
          </Typography>
        </Box>
      </Box>

      <RequestItemSkeleton howMany={1} />
    </>
  )
}

const SectionSkeletonWrapper = ({ howMany = 1, ...props }: SectionSkeletonProps): JSX.Element => {
  if (howMany > 1) {
    return (
      <>
        {Array.from({ length: howMany }, (_, idx) => (
          <SectionSkeleton index={idx + 1} key={idx} {...props} />
        ))}
      </>
    )
  }

  return <SectionSkeleton {...props} index={1} />
}

export default SectionSkeletonWrapper
