import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import SimpleSelect, { Option } from '@app/src/components/Form/Select/SimpleSelect'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Product, ProductIndustry } from '@app/src/types/product'
import { plural } from '@app/src/utils/translationMarkup'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  selectedProduct: Product | null
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product | null>>
  productIdToExclude?: Product['id']
  productIndustry: ProductIndustry
}

const SelectProductForm: React.FC<Props> = ({
  selectedProduct,
  setSelectedProduct,
  productIdToExclude,
  productIndustry,
}) => {
  const { formatMessage } = useIntl()
  const [isOpen, setOpen] = useState(false)

  const filters = [
    {
      name: 'industry',
      filters: [{ value: productIndustry, operator: Operators.EqualTo }],
    },
    ...(productIdToExclude
      ? [
          {
            name: 'id',
            filters: [{ value: productIdToExclude, operator: Operators.NotEqualTo }],
          },
        ]
      : []),
  ]

  const {
    items: products,
    isLoading,
    isFetching,
  } = useFetchCollectionWithPost<Product>({
    key: FetchKey.ProductCollection,
    endpoint: endpoints.productsCollection,
    payload: {
      filter: filters,
      include: [
        'provider',
        'country',
        'mappingNodes',
        'mappingNodes.provider.organization',
        'mappingNodes.actorTypeModel',
        'mappingNodes.parentNode.actorTypeModel',
      ],
    },
  })

  const options = products.map(product => ({
    label: product.name,
    value: product,
  }))

  const isDisabled = isLoading || isFetching

  return (
    <Box mt={1}>
      <SimpleSelect<Product>
        disabled={isDisabled}
        isLoading={isDisabled}
        multiple={false}
        onChange={optionSelected => setSelectedProduct((optionSelected as Option<Product>)?.value ?? null)}
        open={isOpen}
        setOpen={setOpen}
        loading={false}
        options={options}
        fieldLabel={formatMessage({ id: `general.product` }, plural)}
        value={options.find(opt => opt.value.id === selectedProduct?.id) ?? null}
      />
    </Box>
  )
}

export default SelectProductForm
