import OrganizationNode from '@app/src/pages/Product/Tree/OrganizationNode'
import StopNode from '@app/src/pages/Product/Tree/StopNode'
import { MappingNode } from '@app/src/types/product'
import { Box } from '@mui/material'
import dagre from 'dagre'
import React, { useMemo } from 'react'
import { Controls, Edge, Node, Position, ReactFlow } from 'reactflow'
import 'reactflow/dist/style.css'

interface ValueChainTreeViewProps {
  nodes: MappingNode[]
  openDrawer?: () => void
  height?: string | number
}
export interface NodeData {
  node: MappingNode
  nodeProps: {
    openDrawer?: () => void
  }
}

const nodeTypes = { OrganizationNode, StopNode }

// we might want to get actual width and height of nodes https://stackoverflow.com/questions/63734566/react-flow-chart-automatic-layout
const nodeWidth = 320
const nodeHeight = 280

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

/**
 * Function taken from https://reactflow.dev/docs/examples/layout/dagre/
 */
const getLayoutedElements = (nodes: Node[], edges: Edge[], direction: 'TB' | 'LR' = 'TB') => {
  const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach(node => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach(node => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = isHorizontal ? Position.Left : Position.Top
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }

    return node
  })

  return { nodes, edges }
}

const ValueChainTreeView: React.FC<ValueChainTreeViewProps> = ({ nodes, openDrawer, height }) => {
  // sorting nodes by parent id to make sure the tree branches are not crossing
  const sortedNodes = [...nodes].sort((a, b) => (a.parentNode?.id ?? 0) - (b.parentNode?.id ?? 0))

  const [flowNodes, highestTier] = useMemo<[Node<NodeData>[], number]>(() => {
    let highestTier = 0
    return [
      sortedNodes.map(node => {
        highestTier = node.tier > highestTier ? node.tier : highestTier

        return {
          id: node.id.toString(),
          type: node.nodeType,
          data: { node, nodeProps: { openDrawer } },
          position: { x: 0, y: 0 },
        }
      }),
      highestTier,
    ]
  }, [nodes])

  const edges = useMemo(() => {
    return sortedNodes.reduce<Edge[]>((edges, node) => {
      if (!node.parentNode) return edges
      return [
        ...edges,
        {
          id: `e${node.id}-${node.parentNode.id}`,
          source: node.parentNode.id.toString(),
          target: node.id.toString(),
        },
      ]
    }, [])
  }, [nodes])

  const proOptions = { hideAttribution: true }
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(flowNodes, edges)

  return (
    <Box height={height ?? (highestTier + 1) * 150} width="100%">
      <ReactFlow
        fitView
        nodes={layoutedNodes}
        proOptions={proOptions}
        edges={layoutedEdges}
        nodeTypes={nodeTypes}
        nodeOrigin={[0.5, 0.5]}
      >
        <Controls />
      </ReactFlow>
    </Box>
  )
}

export default ValueChainTreeView
