import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Schema } from '@app/src/types/schemas'
import { ConstVars, DefaultImages } from '@app/src/wf-constants'

const ContactSchema: Schema = {
  name: 'contact',
  defaultImage: DefaultImages.User,
  items: [
    {
      accessor: ConstVars.ContactsAccessors.Contact,
      translationId: 'schemas.contact.name',
      fieldType: 'user',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'user.name',
    },
    {
      accessor: ConstVars.ContactsAccessors.Organization,
      translationId: 'schemas.contact.organization',
      fieldType: 'company',
      width: 300,
      display: {
        details: true,
        table: true,
        export: true,
      },
      exportPath: 'creatorOrganization.name',
    },
    {
      accessor: ConstVars.ContactsAccessors.Position,
      translationId: 'schemas.contact.position',
      width: 210,
      display: {
        details: true,
        table: true,
        export: true,
      },
      exportPath: 'position',
    },
    {
      accessor: ConstVars.ContactsAccessors.Phone,
      translationId: 'schemas.contact.phone',
      width: 210,
      display: {
        details: true,
        table: true,
        export: true,
      },
      exportPath: 'phoneNumber',
    },
    {
      accessor: ConstVars.ContactsAccessors.Email,
      translationId: 'schemas.contact.email',
      width: 300,
      display: {
        details: true,
        table: true,
        export: true,
      },
      exportPath: 'user.email',
    },
    {
      accessor: ConstVars.ContactsAccessors.CreatedAt,
      translationId: 'schemas.contact.createdAt',
      fieldType: 'date',
      width: 200,
      display: {
        details: false,
        table: false,
        export: false,
      },
    },
  ],
  actions: ['open'],
  relatedContentFilters: {
    supplier: [
      {
        accessor: 'organizationId',
        name: 'creatorOrganizationId',
        operator: Operators.EqualTo,
      },
    ],
    investee: [
      {
        accessor: 'organizationId',
        name: 'creatorOrganizationId',
        operator: Operators.EqualTo,
      },
    ],
    company: [
      {
        accessor: 'organizationId',
        name: 'creatorOrganizationId',
        operator: Operators.EqualTo,
      },
    ],
  },
}

export default ContactSchema
