import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import ProviderCategoryFilterDrawer from '@app/src/components/CreateNewRequestModal/Common/ProviderCategoryFilterDrawer'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import FilterDisplayChip from '@app/src/components/FilterDisplayChip'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useQueryState } from '@app/src/hooks/queryState'
import { GOAL_TRACKER_QUESTION_KEYS } from '@app/src/pages/Dashboards/GoalTracker/CreateGoalTrackerModal'
import { FilterOptions } from '@app/src/pages/Dashboards/GoalTracker/FilterStep/GoalTrackerSelectFilterStep'
import { SortOrder } from '@app/src/types/filter'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Box, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface SelectFilterStepProps {
  showFiltersButtonTextId?: string
  activeFilterOption?: FilterOptions
}

export const CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS = [
  'country.id',
  'ownerUserId',
  'categoryOptions.id',
  'activityStatus',
  'finalRiskRating',
  'priority',
  'providerApprovalStatus',
  'supplierUsage',
  'tier',
  'mappingNodes.actorTypeModel.id',
  'mappingNodes.tier',
]

const SelectFilterStep: React.FC<SelectFilterStepProps> = ({ showFiltersButtonTextId, activeFilterOption }) => {
  const { formatMessage } = useIntl()
  const { watch } = useFormContext()
  const { openDrawer } = useDrawer()
  const filters = useGetApiQueryFilters(CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS)
  const filterCollectionsValue = watch(GOAL_TRACKER_QUESTION_KEYS.FILTER_COLLECTIONS)
  const [, setQueryFilters] = useQueryState('filters', [])

  const {
    facets: [providersMatchingFiltersCount],
  } = useFetchFacets({
    key: FetchKey.ProvidersMatchingFilters,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'id' }],
    filter: filters ?? [],
  })

  const { count: totalProvidersCount } = useFetchCollectionWithPost({
    key: FetchKey.ProviderCollection,
    endpoint: endpoints.providersCollection,
    payload: {
      filter: [],
      include: [],
      pagination: { itemsPerPage: 1, pageNumber: 1 },
      sort: { target: 'id', order: SortOrder.ASCENDING },
    },
  })

  useEffect(() => {
    if (activeFilterOption === FilterOptions.NoFilter) {
      setQueryFilters([])
    }

    return () => {
      if (activeFilterOption === FilterOptions.NoFilter) {
        setQueryFilters([])
      }
    }
  }, [])

  return (
    <>
      <Box marginBottom={1}>
        {filters.map(filter => (
          <FilterDisplayChip
            key={filter.name}
            filterName={filter.name}
            filterValue={filter.filters[0].value}
            allowDelete
          />
        ))}
      </Box>
      {Boolean(filters?.length) && (
        <Typography color="textSecondary">
          {formatMessage(
            {
              id: 'schemas.goalTracker.filter.note',
            },
            {
              providersMatchingCount: totalProvidersCount,
              providersMatchingFiltersCount: providersMatchingFiltersCount?.length,
            },
          )}
        </Typography>
      )}
      <Box>
        <Button
          variant={filterCollectionsValue?.length ? 'outlined' : 'contained'}
          onClick={() =>
            openDrawer(
              <DrawerViewFilters allowedFilters={CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS}>
                <Box px={2}>
                  <ProviderCategoryFilterDrawer />
                </Box>
              </DrawerViewFilters>,
              { showAboveDialogs: true },
            )
          }
          startIcon={<FilterListIcon />}
          disabled={activeFilterOption === FilterOptions.NoFilter}
        >
          {`${formatMessage({ id: showFiltersButtonTextId ?? 'schemas.goalTracker.filter.showFilters' })} ${
            filters?.length ? `(${filters?.length})` : ''
          }`}
        </Button>
      </Box>
    </>
  )
}
export default SelectFilterStep
