import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import Tabs, { TabsProps } from '@app/src/components/Tabs'
import { ExternalVerificationStatus, HistoryStatus, Inquiry, InquiryStatus } from '@app/src/types/resourceExplorer'
import { reporting } from '@app/src/wf-constants/paths'
import { DeleteOutline, TodayOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Skeleton, Stack, Typography } from '@mui/material'
import { confirm } from 'material-ui-confirm'
import qs from 'qs'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import useProviderQuestionnaire from '../hooks/useProviderQuestionnaire'
import { useReport } from '../ReportContext'
import ResponseStatus from './ResponseStatus'

const UNHANDLED_STATUSES = [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded]

const getClosestNonHandledDeadline = (inquiries: Inquiry[]) => {
  const nonHandledInquiries = inquiries?.filter(inq => UNHANDLED_STATUSES.includes(inq.status)) ?? []
  const deadlines = nonHandledInquiries.filter(inq => inq.deadline).map(inq => new Date(inq?.deadline).getTime())
  return deadlines.length ? new Date(Math.min(...deadlines)).toString() : undefined
}

const ReportingHeader: React.FC = () => {
  const {
    request,
    response,
    isResponseLoading,
    isRequestLoading,
    requestsPerPeriod,
    isRequestsPerPeriodLoading,
    requestId,
    isPreview,
    reportStatus,
    providerInquiries,
  } = useReport()
  const { deleteDraft, isLoadingDeleteDraft, isLoadingUpdateSaveDraft } = useProviderQuestionnaire()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { formatValueToDate } = useGetFormattedDate()
  const { reset } = useFormContext<FormData>()

  const isHandledRequest = reportStatus === HistoryStatus.Approved || reportStatus === HistoryStatus.Submitted

  const handleDeleteDraft = async () => {
    await confirm({
      description: formatMessage({ id: 'questionnaire.actions.discardConfirmation' }),
    }).then(async () => await deleteDraft())

    reset({})
  }
  const closestUnhandledDeadline = getClosestNonHandledDeadline(providerInquiries)

  const tabs: TabsProps['tabs'] =
    requestsPerPeriod?.map(request => ({
      label: request.periodName,
      type: request.id.toString(),
      url: `${reporting}?${qs.stringify({ requestId: request.id })}`,
    })) ?? []

  const handleTabChange = (value: string): void => {
    const selectedTab = tabs?.find(t => t.type === value)

    if (selectedTab) {
      history.push(selectedTab.url)
    }
  }

  if (isPreview) {
    return <Typography variant="h2">{request?.title}</Typography>
  }

  return (
    <Stack spacing={2}>
      {isRequestLoading ? (
        <Skeleton variant="rounded" width="30%" height={40} />
      ) : (
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h2">{request?.title}</Typography>
            <ResponseStatus
              responseStatus={response?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet}
              draftStatus={response?.draftStatus}
              deadline={closestUnhandledDeadline}
              isLoadingStatus={isResponseLoading}
            />
          </Stack>
          {reportStatus === HistoryStatus.Draft && (
            <LoadingButton
              variant="text"
              startIcon={<DeleteOutline />}
              sx={{ color: 'semantic.error' }}
              onClick={handleDeleteDraft}
              loading={isLoadingDeleteDraft}
              disabled={isLoadingDeleteDraft || isLoadingUpdateSaveDraft}
            >
              {formatMessage({ id: 'questionnaire.actions.discard' })}
            </LoadingButton>
          )}
        </Stack>
      )}
      <Stack direction="row">
        {closestUnhandledDeadline && !isHandledRequest && (
          <Stack direction="row" spacing={1}>
            <TodayOutlined />
            <Typography variant="subtitle1">
              {formatMessage(
                { id: 'questionnaire.actions.deadline' },
                { date: formatValueToDate({ value: closestUnhandledDeadline }) },
              )}
            </Typography>
          </Stack>
        )}
      </Stack>

      {isRequestsPerPeriodLoading ? (
        <Stack direction="row" alignItems="center">
          <Skeleton variant="text" width="10%" height={48} sx={{ mr: 1 }} />
          <Skeleton variant="text" width="10%" height={48} />
        </Stack>
      ) : (
        <Tabs
          activeTab={requestId?.toString() ?? ''}
          tabs={tabs}
          handleChange={(_event, value) => handleTabChange(value)}
        />
      )}
    </Stack>
  )
}

export default ReportingHeader
