import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { Inquiry } from '@app/src/types/resourceExplorer'
import { CheckboxProps, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AccessorInquiryHeaderProps extends SortableHeaderProps {
  selectedInquiriesIds?: Array<number>
  setSelectedInquiriesIds?: React.Dispatch<React.SetStateAction<Array<number>>>
  inquiriesInPage?: Inquiry[]
}

function AccessorInquiryHeader({
  toggleSorting,
  activeSorting,
  inquiriesInPage,
  selectedInquiriesIds,
  setSelectedInquiriesIds,
}: AccessorInquiryHeaderProps) {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const verificationEnabled = hasPermission(Permissions.WRITE_VERIFICATION)

  const isChecked = inquiriesInPage?.every(request => selectedInquiriesIds?.includes(request.id))

  const shouldShowCheckboxRow = setSelectedInquiriesIds && inquiriesInPage // Inquiries table is shown on the insights modal, so we need to support not having this

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (!shouldShowCheckboxRow) return
    if (checked) {
      setSelectedInquiriesIds(prev => Array.from(new Set([...prev, ...inquiriesInPage.map(request => request.id)])))
    } else {
      setSelectedInquiriesIds([])
    }
  }

  return (
    <TableRow>
      {shouldShowCheckboxRow && (
        <TableHeaderCell
          label=""
          checkbox={{ checked: isChecked, onChange: handleCheckboxChange, color: 'primary' }}
          minWidth={TableCellWidth.EMPTY}
        />
      )}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.requestStatus' })}
        guidance={formatMessage({ id: 'schemas.request.guidance.status' })}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.request' })}
        sorting={{ name: 'template.title', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.periodDisplayName' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.target' })} />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.createdAt' })}
        sorting={{ name: 'createdAt', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.deadline' })}
        sorting={{
          name: 'deadline',
          toggleSorting,
          activeSorting,
        }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.responseDate' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.provider.contacts' })} />

      {verificationEnabled && (
        <>
          <TableHeaderCell
            label={formatMessage({ id: 'schemas.request.externalVerificationComment' })}
            guidance={formatMessage({ id: 'schemas.request.guidance.externalVerificationComment' })}
            maxRows={1}
          />
          <TableHeaderCell
            label={formatMessage({ id: 'schemas.request.responseInternalStatus' })}
            guidance={formatMessage({ id: 'schemas.request.guidance.internalVerificationStatus' })}
            maxRows={1}
          />
          <TableHeaderCell
            label={formatMessage({ id: 'schemas.request.internalVerificationComment' })}
            guidance={formatMessage({ id: 'schemas.request.guidance.internalVerificationComment' })}
            maxRows={1}
          />
        </>
      )}
      <TableHeaderCell label="" minWidth={TableCellWidth.EMPTY} />
    </TableRow>
  )
}

export default AccessorInquiryHeader
