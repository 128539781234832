import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import { StandardAssessmentsExportColumnsAccessor } from '@app/src/export-columns/standardAssessments'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import { ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Box, Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { Operators } from '../ResourceCollection/Filters/useFilters'
import AssessmentOverviewGraphWithHeader from './AssessmentOverviewGraphWithHeader'
import { useFetchAssessmentSettings } from './useFetchAssessmentSettings'

type AssessmentOverviewSceneProps = Pick<ResourceCollectionSceneProps, 'actionButtons' | 'tabs'>

const allowedFilters = [
  'assessments.id',
  'assessments.periodName',
  'assessments.totalLevel',
  'assessments.assessmentTemplate.id',
  'id',
  'name',
  'provider.country.id',
  'provider.createdAt',
  'provider.ownerUserId',
  'provider.websiteAddress',
  'provider.vatNumber',
  'provider.registrationNumber',
  'provider.linkStatus',
  'categoryOptions.id',
  'provider.activityStatus',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'organization.id',
  'provider.mappingNodes.actorTypeModel.id',
  'provider.mappingNodes.tier',
  'assessmentTemplate.id',
  'periodName',
  'provider.id',
  'provider.categoryOptions.id',
  'totalLevel',
]

const AssessmentOverviewScene: React.FC<AssessmentOverviewSceneProps> = ({ ...props }) => {
  const { formatMessage } = useIntl()
  const { sorting } = useSort()
  const { openDrawer } = useDrawer()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const { assessmentSettings } = useFetchAssessmentSettings()

  const basePayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.NotEqualTo,
            value: 0,
          },
        ],
      },
    ],
    sort: sorting,
    include: ['assessmentTemplate', 'provider.country.risks.riskType', 'provider.organization.contacts.user'],
  }

  return (
    <ResourceCollectionScene
      enableScroll
      title={formatMessage({ id: 'navbar.assessments' })}
      actionButtons={[
        {
          label: formatMessage({ id: 'assessments.setAssessments' }),
          variant: 'outlined',
          to: generatePath(paths.configurations, {
            configurationsPage: CONFIGURATION_PAGE_IDS.Assessments,
          }),
        },
        {
          label: formatMessage({ id: 'resourceCollections.general.export' }),
          variant: 'outlined',
          onClick: () =>
            openDrawer(
              <DrawerViewExport
                resourceType={ResourceTypes.Assessment}
                count={0}
                userFilter={userFilters}
                exportColumns={StandardAssessmentsExportColumnsAccessor}
                rawExportPayload={basePayload}
              />,
            ),
        },
      ]}
      disableLeftPadding
      {...props}
    >
      <Box px={4} my={3}>
        <Grid container spacing={2}>
          {assessmentSettings
            ?.filter(assessmentSetting => assessmentSetting.isActivated)
            .map(assessmentSetting => (
              <Grid item xs={12} md={6} key={assessmentSetting.assessmentTemplate.id}>
                <AssessmentOverviewGraphWithHeader assessmentTemplate={assessmentSetting.assessmentTemplate} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </ResourceCollectionScene>
  )
}

export default AssessmentOverviewScene
