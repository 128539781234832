import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import useProviderType from '@app/src/hooks/useProviderType'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import RangeFilter from '@app/src/pages/ResourceCollection/Filters/RangeFilter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { capitalize } from '@app/src/utils/helpers'
import { Box, Button, Chip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import OptionWithLabel from './OptionWithLabel'
import SpendFilter from './SpendFilter'

const ProductFilters: React.FC<FiltersProps> = ({ allowedFilters, resetPage }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const providerType = capitalize(useProviderType())

  return (
    <Box display="flex">
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Box flexGrow={1} marginRight={1}>
                <Filter name="id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.ProductFacets, filterName],
                        endpoint: endpoints.productsWithFacets,
                        facetsParam: [{ name: 'name' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'general.product' }, { count: 1 })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Box>

              <Box>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.product' })}>
                              <Filter name="id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'general.product' }, { count: 1 })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="gtin" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'gtin', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.product.gtin' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="productNumber" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'productNumber', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.product.productNumber' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="productCustomId" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'productCustomId', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.product.productCustomId' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="purchaseOrder" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'purchaseOrder', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.product.purchaseOrder' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="provider.organization.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'provider.organization.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: `general.${providerType}` }, { count: 0 })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter operator={Operators.In} name="country.id">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'country.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Box>
                                <SpendFilter spendFilterNamePrefix="provider" allowedFilters={allowedFilters} />
                              </Box>
                              <Typography variant="subtitle1">
                                {formatMessage({ id: 'schemas.product.created' })}
                              </Typography>
                              <RangeFilter
                                uniqueIdFrom="createdFrom"
                                uniqueIdTo="createdTo"
                                name="createdAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.product.createdFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.product.createdTo' })}
                              />
                              <Typography variant="subtitle1">
                                {formatMessage({ id: 'schemas.product.updated' })}
                              </Typography>
                              <RangeFilter
                                uniqueIdFrom="updatedFrom"
                                uniqueIdTo="updatedTo"
                                name="updatedAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.product.updatedFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.product.updatedTo' })}
                              />
                              <Filter operator={Operators.In} name="productStatus">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'productStatus', isEnum: true }],
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <OptionWithLabel
                                        key={option.label}
                                        props={props}
                                        label={formatMessage({
                                          id: option?.label
                                            ? `schemas.product.productStatusValues.${option.label}`
                                            : 'general.notAvailable',
                                        })}
                                        multiple
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option?.value?.toString() || index}
                                              label={formatMessage({
                                                id: `schemas.product.productStatusValues.${option.label}`,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.product.productStatus' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter operator={Operators.In} name="mappingStatus">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ProductFacets, filterName],
                                      endpoint: endpoints.productsWithFacets,
                                      facetsParam: [{ name: 'mappingStatus', isEnum: true }],
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <OptionWithLabel
                                        key={option.label}
                                        props={props}
                                        label={formatMessage({
                                          id: option?.label
                                            ? `schemas.product.mappingStatusValues.${option.label}`
                                            : 'general.notAvailable',
                                        })}
                                        multiple
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option?.value?.toString() || index}
                                              label={formatMessage({
                                                id: `schemas.product.mappingStatusValues.${option.label}`,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.product.mappingStatus' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Box>
            </>
          )
        }}
      </Filters>
    </Box>
  )
}

export default ProductFilters
