import { Chip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  isPublished: boolean
}

const PublishedStatusChip = ({ isPublished }: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  return (
    <Chip
      label={
        isPublished
          ? formatMessage({ id: 'templateBuilder.published' })
          : formatMessage({ id: 'templateBuilder.draft' })
      }
      color={isPublished ? 'info' : 'default'}
    />
  )
}

export default PublishedStatusChip
