import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useCreateResource, useCreateResourceWithoutBody } from '@app/src/api/updateHooks'
import Dialog from '@app/src/components/Dialog'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { NotificationSeverity } from '@app/src/wf-constants'
import EdciDialogContent from './EdciDialogContent'

type EdciDialogProps = {
  isOpen: boolean
  closeDialog: () => void
}

type EdciAccessorFormData = {
  id?: number
  gpKey?: string
  gpName?: string
  gpTotalProcos: number
}

type EdciAccessorConfig = {
  id: number
  createdAt: string
  accessorOrganizationId: number
  gpKey: string
  gpName: string
  gpTotalProcos: number
}

const EdciDialog: React.FC<EdciDialogProps> = ({ closeDialog, isOpen }) => {
  const { formatMessage } = useIntl()
  const { mutateAsync: saveAccessorConfig, isLoading: isLoadingConfigSave } = useCreateResource<EdciAccessorFormData>()
  const { mutateAsync: saveEdciData, isLoading: isLoadingEdciSave } = useCreateResourceWithoutBody<{}>()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const formMethods = useForm<EdciAccessorFormData>({
    mode: 'onChange',
  })

  const { data: accessorConfig, isLoading } = useFetchResource<EdciAccessorConfig>({
    endpoint: endpoints.edciAccessorConfiguration,
    key: FetchKey.EdciAccessorConfigs,
    options: {
      enabled: isOpen,
    },
  })

  const handleEdciConfig = async (values: EdciAccessorFormData) => {
    const body = {
      gpKey: accessorConfig?.gpKey,
      gpName: accessorConfig?.gpName,
      id: accessorConfig?.id,
      gpTotalProcos: Number(values.gpTotalProcos),
    }

    await saveAccessorConfig(
      {
        url: endpoints.saveEdciAccessorConfiguration,
        body,
      },
      {
        onSuccess: async () => {
          await handlePushEdciData()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const handlePushEdciData = async () => {
    await saveEdciData(
      {
        url: endpoints.publishEdciData,
      },
      {
        onSuccess: async () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceSave' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          closeDialog()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  useEffect(() => {
    if (accessorConfig) {
      formMethods.reset({ ...accessorConfig })
    }
  }, [accessorConfig])

  return (
    <FormProvider {...formMethods}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={closeDialog}
        title={formatMessage({ id: 'form.createRequest.edci.sendToEdci' })}
        content={<EdciDialogContent isLoading={isLoading} />}
        onFormSubmit={formMethods.handleSubmit(handleEdciConfig)}
        buttons={[
          {
            label: formatMessage({ id: 'general.close' }),
            variant: 'outlined',
            onClick: closeDialog,
            disabled: isLoadingConfigSave || isLoadingEdciSave,
          },
          {
            label: formatMessage({ id: 'general.submit' }),
            variant: 'contained',
            type: 'submit',
            disabled: isLoadingConfigSave || isLoadingEdciSave,
            loading: isLoadingConfigSave || isLoadingEdciSave,
          },
        ]}
      />
    </FormProvider>
  )
}

export default EdciDialog
