import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewReviewProductValueChainToCopyToOtherProduct from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewProductValueChainToCopyToOtherProduct'
import SelectProductForm from '@app/src/pages/Product/SelectProductForm'
import { Product, ProductIndustry } from '@app/src/types/product'
import { Box } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type DrawerViewSelectProductToCopyToOtherProductProps = {
  targetProduct: Product
  productIndustry: ProductIndustry
} & Omit<DrawerViewProps, 'title'>

const DrawerViewSelectProductToCopyToOtherProduct: React.FC<DrawerViewSelectProductToCopyToOtherProductProps> = ({
  targetProduct,
  productIndustry,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(null)

  const { openDrawer, goBack, closeDrawer } = useDrawer()
  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.mappingNode.copyExistingValueChain' })}
      subTitle={formatMessage({ id: 'schemas.mappingNode.copyExistingValueChainDescription' })}
      {...props}
      buttons={[
        {
          label: formatMessage({ id: 'general.cancel' }),
          onClick: goBack,
        },
        {
          label: formatMessage({ id: 'general.continue' }),
          variant: 'contained',
          disabled: !selectedProduct,
          onClick: () => {
            if (selectedProduct === null) return
            openDrawer(
              <DrawerViewReviewProductValueChainToCopyToOtherProduct
                targetProduct={targetProduct}
                productToCopy={selectedProduct}
                onSuccess={closeDrawer}
              />,
            )
          },
        },
      ]}
    >
      <Box p={2}>
        <SelectProductForm
          productIdToExclude={targetProduct.id}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          productIndustry={productIndustry}
        />
      </Box>
    </DrawerView>
  )
}
export default DrawerViewSelectProductToCopyToOtherProduct
