import { Components, alpha } from '@mui/material'
import { palette } from '@app/src/theme/palette'

const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    root: {
      backgroundColor: alpha('#000', 0.08),
      borderRadius: '0.75rem',
      fontWeight: 500,
    },
    colorError: {
      backgroundColor: palette.error.dark,
    },
    colorInfo: {
      backgroundColor: palette.info.dark,
    },
    colorWarning: {
      backgroundColor: palette.warning.dark,
    },
    colorSuccess: {
      backgroundColor: palette.success.dark,
    },
    outlined: {
      background: 'white',
    },
  },
}

export default MuiChip
