import { Components } from '@mui/material'

const MuiToggleButton: Components['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      borderRadius: 10,
    },
    sizeSmall: {
      fontSize: '1.625rem',
    },
    sizeMedium: {
      fontSize: '1.75rem',
    },
    sizeLarge: {
      fontSize: '1.875rem',
    },
  },
}

export default MuiToggleButton
