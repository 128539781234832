import { QuestionStepRenderPropParams } from '@app/src/components/CreateNewRequestModal/Generic/QuestionStep'
import ControlledDateField from '@app/src/components/Form/ControlledDateField'
import { useMultipleStepsFormContext } from '@app/src/context/MultipleStepsFormContext'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

type Props = QuestionStepRenderPropParams

export interface SelectDeadlineStepFormData {
  deadline: Date | null
}

const SelectDeadlineStep: React.FC<Props> = ({ enableNext }) => {
  const { control, watch, setValue } = useFormContext<SelectDeadlineStepFormData>()
  const deadlineWatch = watch('deadline')
  const { values } = useMultipleStepsFormContext()
  const { formatMessage } = useIntl()

  useEffect(() => {
    enableNext(Boolean(deadlineWatch))
  }, [deadlineWatch])

  useEffect(() => {
    if (values['deadline']) {
      setValue('deadline', values['deadline'])
    }
  }, [values])

  return (
    <form>
      <ControlledDateField
        disablePast
        defaultValue={null}
        fieldLabel={formatMessage({ id: 'form.createRequest.selectDeadlineLabel' })}
        control={control}
        name="deadline"
        slotProps={{ textField: { fullWidth: true } }}
      />
    </form>
  )
}

export default SelectDeadlineStep
