import { TableRow } from '@mui/material'
import React from 'react'

import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import MappingRequestStatusCell from '@app/src/components/Table/Cells/MappingRequestStatusCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ProductMappingRequest } from '@app/src/types/product'
import paths from '@app/src/wf-constants/paths'
import { generatePath, useHistory } from 'react-router-dom'

interface SentMappingRequestRowProps {
  row: ProductMappingRequest
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, request: ProductMappingRequest) => void
  selectedRequestsIds?: Array<number>
}

const SentMappingRequestRow: React.FC<SentMappingRequestRowProps> = ({
  row: mappingRequest,
  selectedRequestsIds,
  onCheckboxChange,
}) => {
  const history = useHistory()
  const isChecked = Boolean(selectedRequestsIds?.find(requestId => requestId === mappingRequest.id))

  return (
    <TableRow selected={isChecked}>
      <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, mappingRequest)} />
      <MappingRequestStatusCell value={mappingRequest?.status} dueDate={mappingRequest?.deadline} />
      <ValueCell
        sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
        onClick={() => history.push(generatePath(paths.product, { id: mappingRequest.product?.id }))}
        value={mappingRequest?.product?.name}
      />
      <CompanyCell onClick={e => e.preventDefault()} drawer company={mappingRequest?.responderOrganization} />
      <DateCell value={mappingRequest?.deadline} />
      <ValueCell value={mappingRequest.periodName} />
    </TableRow>
  )
}

export default SentMappingRequestRow
