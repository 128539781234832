import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewInvitationForm from '@app/src/components/Drawer/Views/DrawerViewInvitationForm'
import LinkButton from '@app/src/components/LinkButton'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { ProductMappingRequest } from '@app/src/types/product'
import { InquiriesByTemplate, Inquiry, InquiryStatus } from '@app/src/types/resourceExplorer'
import { isPassedDeadline } from '@app/src/utils'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { PersonAdd, TrendingUp } from '@mui/icons-material'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import OpenRequestsCard from './OpenRequestsCard'
import OverdueRequestsCard from './OverdueRequestsCard'
import PendingRequests from './PendingRequests'
import RecommendationsSection from './RecommendationsSection'
import ReportingProgressCard from './ReportingProgressCard'
import RequestKpiCard from './RequestKpiCard'

const TransparencyDashboard = () => {
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { trackEvent, account } = useAmplitude()
  const { openDrawer } = useDrawer()

  const { items: inquiriesByTemplate, isLoading } = useFetchCollectionWithPost<InquiriesByTemplate>({
    key: FetchKey.OpenRequests,
    endpoint: endpoints.inquiriesGroupedByTemplate,
    payload: {
      filter: [
        {
          name: 'status',
          filters: [
            {
              value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
              operator: Operators.In,
            },
          ],
        },
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 99999,
      },
      sort: {
        target: 'deadline',
        order: SortOrder.ASCENDING,
      },
      include: ['creatorOrganization', 'template', 'template.image', 'provider.organization'],
    },
  })

  const { items: pendingInquires, isLoading: pendingInquiresIsLoading } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.InquiryOpenRequests,
    endpoint: endpoints.inquiriesCollection,
    payload: {
      filter: [
        {
          name: 'status',
          filters: [
            {
              value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
              operator: Operators.In,
            },
          ],
        },
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 99999,
      },
      include: [],
    },
  })

  const overdueInquiriesCount = pendingInquires.filter(inquiry => isPassedDeadline(inquiry.deadline)).length

  const { items: pendingMappingRequests, isLoading: pendingProductMappingRequestsIsLoading } =
    useFetchCollectionWithPost<ProductMappingRequest>({
      key: FetchKey.PendingMappingRequests,
      endpoint: endpoints.mappingRequestCollection,
      payload: {
        filter: [
          {
            name: 'status',
            filters: [
              {
                value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
                operator: Operators.In,
              },
            ],
          },
          {
            name: 'responderOrganizationId',
            filters: [{ value: account?.organization?.id, operator: Operators.EqualTo }],
          },
        ],
        pagination: {
          pageNumber: 1,
          itemsPerPage: 99999,
        },
        include: ['product', 'creatorOrganization', 'creatorOrganization.image'],
      },
      options: {
        enabled: hasPermission(Permissions.PRODUCT_MAPPING_RESPOND_ACCESS),
      },
    })

  const overdueProductMappingRequestsCount = pendingMappingRequests.filter(request =>
    isPassedDeadline(request.deadline),
  ).length

  const totalInquiryCount = inquiriesByTemplate.reduce((acc, curr) => acc + curr.inquiryInfos.length, 0)

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Dashboard.Overview.ViewedPage,
    })
  }, [])

  return (
    <Stack spacing={2} my={1} height="100%">
      <Stack>
        <Helmet>
          <title>{formatMessage({ id: 'navbar.dashboard' })}</title>
        </Helmet>
        <Box px={4} py={2}>
          <Box display="flex" justifyContent="space-between">
            <Stack>
              <Typography variant="h1">{formatMessage({ id: 'navbar.dashboard' })}</Typography>
              <Typography variant="body1">
                {formatMessage({ id: 'solutionSelector.headerName' }, { name: account?.user?.givenName })}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <LinkButton startIcon={<TrendingUp />} to={paths.transparencyProviderProgress}>
                <Typography variant="button">{formatMessage({ id: 'dashboard.yourProgress' })}</Typography>
              </LinkButton>
              <Button variant="text" startIcon={<PersonAdd />} onClick={() => openDrawer(<DrawerViewInvitationForm />)}>
                {formatMessage({ id: 'dashboard.investment.inviteColleagues' })}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
      <Stack px={4}>
        {hasPermission(Permissions.PRODUCT_MAPPING_RESPOND_ACCESS) ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RequestKpiCard
                title={formatMessage({ id: 'resourceTypes.productMappingRequest' })}
                helperText={formatMessage({ id: 'transparency.productMappingRequestsHelperText' })}
                totalCount={pendingMappingRequests.length}
                overdueCount={overdueProductMappingRequestsCount}
                isLoading={pendingProductMappingRequestsIsLoading}
                actionLink={generatePath(paths.mappingRequestCollection)}
              />
            </Grid>
            <Grid item xs={6}>
              <RequestKpiCard
                title={formatMessage({ id: 'resourceTypes.questionnaireRequest' })}
                helperText={formatMessage({ id: 'transparency.questionnaireRequestsHelperText' })}
                totalCount={pendingInquires.length}
                overdueCount={overdueInquiriesCount}
                isLoading={pendingInquiresIsLoading}
                actionLink={generatePath(paths.manageRequest, { view: ViewTypeName.Requests })}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <OverdueRequestsCard />
            </Grid>
            <Grid item xs={3}>
              <OpenRequestsCard pendingRequestsCount={totalInquiryCount} isLoading={isLoading} />
            </Grid>
            <Grid item xs={6}>
              <ReportingProgressCard />
            </Grid>
          </Grid>
        )}
      </Stack>
      <PendingRequests
        inquiriesByTemplate={inquiriesByTemplate}
        productMappingRequests={pendingMappingRequests}
        isLoading={isLoading}
      />
      <RecommendationsSection />
    </Stack>
  )
}

export default TransparencyDashboard
