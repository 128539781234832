import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { SortOrder } from '@app/src/types/filter'
import { ProviderActivity } from '@app/src/types/organizations'
import React from 'react'
import { useIntl } from 'react-intl'
import ProviderRiskAssessmentHeader from './ProviderRiskAssessmentHeader'
import ProviderRiskAssessmentRow from './ProviderRiskAssessmentRow'

type ProviderRiskAssessmentCollectionProps = {
  providerId: number
  embedded: boolean
}

const ProviderRiskAssessmentCollection: React.FC<ProviderRiskAssessmentCollectionProps> = ({
  providerId,
  embedded,
}) => {
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { formatMessage } = useIntl()
  const { items, count, isLoading, isError } = useFetchCollectionWithPost<ProviderActivity>({
    key: FetchKey.ProviderActivity,
    endpoint: endpoints.providerActivity,
    payload: {
      filter: [
        {
          name: 'providerId',
          filters: [
            {
              value: providerId,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      sort: {
        target: 'createdAt',
        order: SortOrder.DESCENDING,
      },
      include: ['comments', 'creatorUser'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <ResourceCollectionScene title={formatMessage({ id: 'resourceTypes.providerActivity' })} embedded={embedded}>
      <Table<ProviderActivity>
        RowComponent={ProviderRiskAssessmentRow}
        HeaderComponent={ProviderRiskAssessmentHeader}
        data={items}
        page={page}
        pageSize={pageSize}
        noPagination
        isLoading={isLoading}
        count={count}
        isError={isError}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollectionScene>
  )
}

export default ProviderRiskAssessmentCollection
