import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Button, Checkbox, FormControlLabel, Grid } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import TemplatePreview from '@app/src/components/CreateNewRequestModal/Common/TemplatePreview'

type TemplateSelectorProps = {
  template: QuestionnaireTemplate
  chip?: React.ReactNode
  formKey: string
}
type SelectedTemplateType = { id: number; title: string }
const SelectableTemplateWithPreview: React.FC<TemplateSelectorProps> = ({ template, chip, formKey }) => {
  const [showPreview, setShowPreview] = useState(false)
  const { setValue, watch } = useFormContext()
  const selectedTemplates = watch(formKey)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue(formKey, [...selectedTemplates, { id: template.id, title: template.title }])
    } else {
      setValue(
        formKey,
        selectedTemplates.filter((selectedTemplate: SelectedTemplateType) => selectedTemplate.id !== template.id),
      )
    }
  }

  const isChecked = Boolean(
    selectedTemplates.find((selectedTemplate: SelectedTemplateType) => selectedTemplate.id === template.id),
  )

  return (
    <Box key={template.id} mt={2}>
      <Grid container>
        <Grid item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} color="primary" />}
            label={template.title}
          />
        </Grid>

        <Grid item xs={4}>
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            {chip}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box height="100%" display="flex" alignItems="center" justifyContent="flex-end">
            <Button startIcon={<Visibility />} onClick={() => setShowPreview(true)}>
              Preview
            </Button>
          </Box>
        </Grid>

        <TemplatePreview showPreview={showPreview} onBack={() => setShowPreview(false)} template={template} />
      </Grid>
    </Box>
  )
}
export default SelectableTemplateWithPreview
