import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SimpleOrganization } from '@app/src/types/organizations'
import { Box, TextField as MuiTextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import OrganizationHeader from './OrganizationHeader'
import OrganizationRow from './OrganizationRow'

const OrganizationCollection: React.FC = () => {
  const { formatMessage } = useIntl()
  const [organizationSearch, setOrganizationSearch] = useState('')
  const [page, pageSize, setPage, setPageSize] = usePagination()

  const { items, count, isLoading, isError } = useFetchCollectionWithPost<SimpleOrganization>({
    key: [FetchKey.OrganizationCollection, page, pageSize, organizationSearch],
    endpoint: endpoints.organizationsCollection,
    payload: {
      filter: [
        {
          name: 'name',
          filters: [{ value: organizationSearch, operator: Operators.Contains }],
        },
      ],
      include: [],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <ConfigurationCollection
      secondaryTitle={<Typography variant="body1">{formatMessage({ id: 'organizationConfig.subtitle' })}</Typography>}
    >
      <Box flexGrow={1} display="flex" flexDirection="column" height={100} mb={2}>
        <MuiTextField
          fullWidth
          variant="outlined"
          size="small"
          label={formatMessage({ id: 'organizationConfig.searchLabel' })}
          value={organizationSearch}
          onChange={e => setOrganizationSearch(e.target.value)}
          sx={{ mb: 4, pr: 4 }}
        />
        <Table<SimpleOrganization>
          RowComponent={OrganizationRow}
          HeaderComponent={OrganizationHeader}
          data={items}
          isLoading={isLoading}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </Box>
    </ConfigurationCollection>
  )
}

export default OrganizationCollection
