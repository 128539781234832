import InfoIconMarkdownCell from '@app/src/components/Table/Cells/InfoIconMarkdownCell'
import QuestionLinkCell from '@app/src/components/Table/Cells/QuestionLinkCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import QuestionCheckboxCell, {
  QuestionCheckboxCellProps,
} from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/QuestionCheckboxCell'
import { Question } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'

interface QuestionRowProps {
  row: Question
  selectionProps?: Omit<QuestionCheckboxCellProps, 'question'>
}

const CustomQuestionRow: React.FC<QuestionRowProps> = ({ row: question, selectionProps }) => (
  <TableRow>
    {selectionProps ? (
      <>
        <QuestionCheckboxCell question={question} {...selectionProps} />
        <ValueCell value={question.questionText} maxRows={1} />
      </>
    ) : (
      <QuestionLinkCell question={question} />
    )}
    <InfoIconMarkdownCell value={question.guidance} />
  </TableRow>
)

export default CustomQuestionRow
