import TableRow from '@mui/material/TableRow'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProductRequestHeader = () => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'schemas.mappingRequest.status' })} minWidth={TableCellWidth.SMALL} />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.company' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.respondedDate' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.mappingStatus' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'resourceCollections.general.action' })}
        minWidth={TableCellWidth.SMALL}
      />
    </TableRow>
  )
}

export default ProductRequestHeader
