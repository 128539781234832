import DateDisplay from '@app/src/components/DateDisplay'
import EmptyState from '@app/src/components/EmptyState'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import RoundIcon from '@mui/icons-material/FiberManualRecord'
import NotificationsIcon from '@mui/icons-material/Notifications'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Theme,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import LoadingButton from '@app/src/components/LoadingButton'
import { NotificationTypes, useNotificationData } from '@app/src/pages/Notifications'
import { NotificationItem as NotificationItemType } from './NotificationsScene'

const useStyles = makeStyles((theme: Theme) => ({
  notification: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
  },
  seenIcon: {
    minWidth: 0,
  },
  unseenIcon: {
    minWidth: theme.spacing(4),
  },
  detail: {
    paddingLeft: theme.spacing(4),
    '& span span': {},
  },
  notificationDetails: {
    flexGrow: 'inherit',
  },
  notificationDate: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  buttonSpinning: {
    animation: `$rotation 1000ms infinite linear`,
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
}))

interface NotificationProps {
  notification: NotificationItemType
}

const NotificationItem = ({ notification }: NotificationProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { seen } = notification
  const { getNotificationAction, getNotificationTitle, isActionButtonLoading } = useNotificationData()

  const getLinkProps = (action: string | (() => Promise<void>) | null) => {
    if (!action) return {}
    if (typeof action === 'string') return { component: Link, to: action ?? '' }
    return { component: Button, onClick: action }
  }

  const notificationAction = getNotificationAction(notification)

  const isDownloadable =
    notification.sourceEventType.toLowerCase() === NotificationTypes.ExcelExport ||
    notification.sourceEventType.toLowerCase() === NotificationTypes.Import

  const linkProps = getLinkProps(notificationAction)

  return (
    <Box pl={2}>
      <ListItem className={classes.notification} button {...linkProps}>
        <ListItemIcon classes={{ root: seen ? classes.seenIcon : classes.unseenIcon }}>
          {!seen && <RoundIcon color="secondary" />}
        </ListItemIcon>
        <ListItemText className={classes.notificationDetails}>
          <Typography variant="caption" className={classes.notificationDate}>
            <DateDisplay withTime value={notification.createdAt} />
          </Typography>
        </ListItemText>
        <ListItemText className={classes.detail} primary={getNotificationTitle(notification)} />
        {notificationAction && (
          <ListItemText
            className={classes.notificationDetails}
            secondary={
              <LoadingButton loading={isActionButtonLoading} variant="outlined">
                {formatMessage({ id: isDownloadable ? 'schemas.notification.download' : 'schemas.notification.view' })}
              </LoadingButton>
            }
          />
        )}
      </ListItem>
    </Box>
  )
}

interface Props {
  notifications: NotificationItemType[]
  canLoadMore: boolean
  loadMore: () => void
  showLoadMore: boolean
  loading: boolean
}

const NotificationList = ({ notifications, canLoadMore, loadMore, showLoadMore, loading }: Props): JSX.Element => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  if (loading && notifications?.length === 0)
    return (
      <>
        {Array.from({ length: 4 }).map((_, index) => (
          <Stack spacing={2} key={index}>
            <Skeleton height={40} />
          </Stack>
        ))}
      </>
    )

  if (notifications?.length === 0)
    return (
      <Paper elevation={0}>
        <EmptyState
          title={formatMessage({ id: 'schemas.notification.noNotifications' })}
          description={formatMessage({ id: 'schemas.notification.noNotificationsInfo' })}
          iconComponent={NotificationsIcon}
          sx={{ padding: 27 }}
        />
      </Paper>
    )

  const handleClick = (): void => {
    if (!loading) {
      loadMore()
    }
  }

  return (
    <>
      <List component={Paper} elevation={0} sx={{ width: '100%' }}>
        {notifications?.map((notification, i) => (
          <React.Fragment key={i}>
            <NotificationItem notification={notification} />
            {i < notifications?.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>

      {showLoadMore && canLoadMore && (
        <Box my={2} textAlign="center" flexGrow={1}>
          <LoadingButton
            onClick={handleClick}
            endIcon={<AutorenewIcon className={classNames({ [classes.buttonSpinning]: loading })} />}
            loading={loading}
          >
            {formatMessage({ id: 'general.loadMore' })}
          </LoadingButton>
        </Box>
      )}
    </>
  )
}

export default NotificationList
