import React, { createContext, useContext } from 'react'

export enum ReportType {
  NONE,
  WF,
  COMPANY,
  EDCI,
  SFDR_WITHOUT_COMPANIES,
  SFDR_WITH_COMPANIES,
}

export interface CreationModalProgressContextProps {
  activeStep: number
  totalSteps: number
  progress: number
  setTotalSteps: React.Dispatch<React.SetStateAction<number>>
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  allowsNextWithoutData: boolean
  setAllowsNextWithoutData: React.Dispatch<React.SetStateAction<boolean>>
  enableEmptyState: boolean
  setEnableEmptyState: React.Dispatch<React.SetStateAction<boolean>>
}

const CreationModalProgressContext = createContext<undefined | CreationModalProgressContextProps>(undefined)

export const useCreationModalProgress = (): CreationModalProgressContextProps => {
  const context = useContext(CreationModalProgressContext)
  if (context === undefined) {
    throw new Error('useCreationModalProgress must be used within CreationModalProgressContextProvider')
  }
  return context
}

export default CreationModalProgressContext
