import { ActivationType } from '@app/src/types/flags'
import { getDaysBetweenDates } from '@app/src/utils'
import { NotificationItem, RawNotificationItem } from './NotificationsScene'

export const refineNotification = (rawNotification: RawNotificationItem): NotificationItem => {
  switch (rawNotification.details?.type) {
    case 'Request':
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details.title,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: Number(rawNotification.details.id),
        days: getDaysBetweenDates(rawNotification.createdAt, rawNotification.details.dueAt, true),
      }
    case 'Response':
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details.request?.title ?? '',
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: Number(rawNotification.details.targetRequestId),
        responseId: rawNotification.details.id,
        providerName: rawNotification.creatorOrganization?.name,
      }
    case 'Invitation': {
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details.name,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: 0, // requestId: rawNotification.details.targetRequestId,
        responseId: '', // responseId: rawNotification.details.id,
        role: rawNotification.details.role,
        solutionType: rawNotification.details.solutionType,
      }
    }
    case 'File': {
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details.name,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: 0, // requestId: rawNotification.details.targetRequestId,
        responseId: '', // responseId: rawNotification.details.id,
        role: rawNotification.details.role,
        solutionType: rawNotification.details.solutionType,
        originalFileName: rawNotification.details.originalFileName,
        targetObjectId: rawNotification.targetObjectId,
      }
    }
    case 'Import': {
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details.name,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: 0, // requestId: rawNotification.details.targetRequestId,
        responseId: '', // responseId: rawNotification.details.id,
        role: rawNotification.details.role,
        solutionType: rawNotification.details.solutionType,
        originalFileName: rawNotification.details.outputFile?.originalFileName,
        targetObjectId: rawNotification?.details?.outputFile?.id,
        successCount: rawNotification.details.successCount,
        errorCount: rawNotification.details.errorCount,
      }
    }
    case 'Supplier':
    case 'Investee':
    case 'Company': {
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details.name,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: 0,
        responseId: '',
        providerId: rawNotification.targetObjectId,
        providerName: rawNotification.details.name,
        role: rawNotification.details.role,
        solutionType: rawNotification.details.solutionType,
        providerType: rawNotification.details.type,
        creatorOrganizationName: rawNotification.creatorOrganization.name,
        creatorOrganizationId: rawNotification.creatorOrganization.id,
      }
    }
    case 'Inquiry':
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details?.template?.title ?? '',
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: rawNotification.details.requestId,
        responseId: '',
        creatorOrganizationName: rawNotification.creatorOrganization.name,
        days: getDaysBetweenDates(rawNotification.createdAt, rawNotification.details.deadline, true),
        templateId: rawNotification.details.questionnaireTemplateId,
        periodStartsAt: rawNotification.details.periodStartsAt,
        periodEndsAt: rawNotification.details.periodEndsAt,
        targetObjectId: rawNotification.targetObjectId,
      }
    case 'RequestSubscription':
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details.request?.title ?? '',
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: rawNotification.details.requestId,
        providerName: rawNotification.creatorOrganization.name,
        responseId: String(rawNotification.details?.request?.responses?.[0]?.id),
        creatorOrganizationName: rawNotification.creatorOrganization.name,
      }
    case 'ReportFlagRule':
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: rawNotification.details?.requestTemplateItem?.description ?? '',
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: 0,
        activationType: rawNotification.details?.activationType
          ? ActivationType[rawNotification.details?.activationType as keyof typeof ActivationType]
          : undefined,
      }
    case 'ProductMappingRequest':
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: '',
        productName: rawNotification.details.product.name,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: Number(rawNotification.details.id),
      }
    case 'ProductMappingResponse':
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: '',
        productName: rawNotification.details.productMappingRequest.product.name,
        productId: rawNotification.details.productMappingRequest.product.id,
        responseId: rawNotification.details.id,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: 0,
        creatorOrganizationName: rawNotification.creatorOrganization.name,
      }
    case 'AnswerExpirationNotification':
      return {
        id: rawNotification.id,
        requestId: rawNotification.details.requestId,
        createdAt: rawNotification.createdAt,
        objectTitle: rawNotification.details.requestName ?? '',
        seen: rawNotification.seen,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        periodStartsAt: rawNotification.details.periodStartsAt,
        periodEndsAt: rawNotification.details.periodEndsAt,
        requestName: rawNotification.details.requestName,
        providerName: rawNotification.details.providerName,
      }
    case 'AssessmentLevelNotification':
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: '',
        oldTotalLevel: rawNotification.details?.oldTotalLevel,
        newTotalLevel: rawNotification.details?.totalLevel,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: 0,
        providerName: rawNotification.details?.providerName,
        providerId: rawNotification.details?.providerId,
      }
    default:
      return {
        id: rawNotification.id,
        service: rawNotification.serviceType,
        sourceEventType: rawNotification.sourceEventType,
        objectTitle: '', // objectTitle: rawNotification.details.request?.title,
        seen: rawNotification.seen,
        createdAt: rawNotification.createdAt,
        requestId: 0, // requestId: rawNotification.details.targetRequestId,
        responseId: '', // responseId: rawNotification.details.id,
      }
  }
}
