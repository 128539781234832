import _ContactSchema from '@app/src/schemas/contact'
import _EvaluationsSchema from '@app/src/schemas/evaluation'
import _InquirySchema from '@app/src/schemas/inquiry'
import _MappingNodeSchema from '@app/src/schemas/mappingNode'
import _OrganizationSchema from '@app/src/schemas/organization'
import _ReportsSchema from '@app/src/schemas/report'
import _ResponseSchema from '@app/src/schemas/response'
import _RiskScreeningSchema from '@app/src/schemas/risk-screening'
import { Schema } from '@app/src/types/schemas'
import { capitalize } from '@app/src/utils/helpers'
import { ResourceTypes } from '@app/src/wf-constants'
import getResponseItemSchema from '@app/src/schemas/responseItem'

export const ContactSchema = _ContactSchema
export const OrganizationSchema = _OrganizationSchema
export const InquirySchema = _InquirySchema
export const ResponseSchema = _ResponseSchema
export const EvaluationSchema = _EvaluationsSchema
export const ReportSchema = _ReportsSchema
export const RiskScreeningSchema = _RiskScreeningSchema
export const MappingNodeSchema = _MappingNodeSchema

const Schemas: { [schema: string]: Schema } = {
  ContactSchema,
  OrganizationSchema,
  RequestSchema: InquirySchema,
  ResponseSchema,
  EvaluationSchema,
  ReportSchema,
  RiskScreeningSchema,
  MappingNodeSchema,
}

export const getSchemaByResourceType = (type: string, solutionType?: string): Schema | undefined => {
  if (type.toLowerCase() === ResourceTypes.ResponseItem.toLowerCase()) return getResponseItemSchema(solutionType)

  return Schemas[
    `${type
      .split('-')
      .map(part => capitalize(part))
      .join('')}Schema`
  ]
}

export default Schemas
