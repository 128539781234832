import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets, useFetchResource } from '@app/src/api/fetchHooks'
import SimpleBreadcrumbs from '@app/src/components/SimpleBreadcrumbs'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import AssessmentRiskIndex from '@app/src/pages/Assessments/AssessmentExplore/AssessmentRiskIndex'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection'
import StandardAssessmentFilters from '@app/src/pages/ResourceCollection/Filters/StandardAssessmentFilters'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { RiskTableView } from '@app/src/types/organizations'
import { AssessmentTemplate, InquiryStatus, OrganizationRiskSetting } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import { Box, Grid, Paper, Stack } from '@mui/material'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath } from 'react-router'
import ResponseRateSummary from '../../ResourceCollection/Collections/BaseAssessment/ResponseRateSummary'
import { ResourceCollectionSceneProps } from '../../ResourceCollection/ResourceCollectionScene'
import AssessmentOverviewGraph from '../AssessmentOverviewGraph'

type AssessmentInsightsSceneProps = {
  assessmentTemplateId: string
  tabs?: ResourceCollectionSceneProps['tabs']
  activePeriod: string
}

const allowedFilters: string[] = [
  'provider.id',
  'provider.name',
  'provider.country.id',
  'provider.createdAt',
  'provider.ownerUserId',
  'provider.websiteAddress',
  'provider.vatNumber',
  'provider.registrationNumber',
  'provider.linkStatus',
  'provider.categoryOptions.id',
  'provider.activityStatus',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'provider.organization.id',
  'provider.mappingNodes.actorTypeModel.id',
  'provider.mappingNodes.tier',
  'periodName',
  'totalLevel',
]

const AssessmentInsightsScene: React.FC<AssessmentInsightsSceneProps> = ({
  assessmentTemplateId,
  tabs,
  activePeriod,
}) => {
  const previousFilter = useGetApiQueryFilters(allowedFilters)

  const userFilters: FilterGroup[] = useMemo(() => {
    if (Array.isArray(previousFilter) && previousFilter.length > 0) {
      const hasPeriodNameFilter = previousFilter.some(group => group.name === 'periodName')

      if (!hasPeriodNameFilter) {
        return [
          ...previousFilter,
          {
            name: 'periodName',
            filters: [{ value: activePeriod, operator: Operators.EqualTo }],
          },
        ]
      }
      return previousFilter
    }
    return [
      {
        name: 'periodName',
        filters: [{ value: activePeriod, operator: Operators.EqualTo }],
      },
    ]
  }, [previousFilter, activePeriod])

  const periodFilterValue = useMemo(() => {
    return userFilters?.find(group => group.name === 'periodName')?.filters[0]?.value?.toString() || activePeriod
  }, [userFilters, activePeriod])

  const handlePeriodChange = (newPeriod: string) => {
    activePeriod = newPeriod
  }
  const { formatMessage } = useIntl()
  useQueryClient()

  const { assessmentQuestionnaireLinks, isLoadingAssessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentTemplateId: Number(assessmentTemplateId),
    includeAssessment: false,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [assessmentTemplateId])

  const { items } = useFetchCollectionWithPost<AssessmentTemplate>({
    endpoint: endpoints.assessmentTemplateCollection,
    key: FetchKey.AssessmentTemplateCollection,
    payload: {
      filter: [
        {
          name: 'id',
          filters: [
            {
              value: assessmentTemplateId,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: [],
    },
    options: {
      staleTime: 60000,
    },
  })

  const assessmentTemplate = items[0]

  const questionnaireTemplateId = assessmentTemplate?.questionnaireTemplateId

  const { data: relatedRiskSettings, isFetching: isRelatedRiskSettingsFetching } = useFetchResource<
    OrganizationRiskSetting[]
  >({
    key: [FetchKey.AssessmentTemplateRiskIndices, assessmentTemplateId],
    endpoint: endpoints.assessmentTemplateRiskIndices(assessmentTemplateId),
    options: {
      enabled: Boolean(assessmentTemplate),
    },
  })

  const {
    facets: [providerFacet],
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'levels'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      ...userFilters,
      {
        name: 'assessmentTemplateId',
        filters: [{ value: assessmentTemplate?.id, operator: Operators.EqualTo }],
      },
    ],
    options: {
      enabled: Boolean(assessmentTemplate),
      staleTime: 60000,
    },
  })

  const providerIds: number[] = providerFacet?.map(x => parseInt(x.value.toString()))

  const userFiltersIncludesNonProviderFilters = useMemo(
    () => userFilters.some(x => x.name === 'totalLevel'),
    [userFilters],
  )

  const inquiryProviderFilters = useMemo(() => userFilters.filter(x => x.name.startsWith('provider.')), [userFilters])

  const responseProviderFilters = useMemo(
    () =>
      inquiryProviderFilters.map(x => ({ ...x, name: x.name.replace('provider.', 'request.subscriptions.target.') })),
    [inquiryProviderFilters],
  )
  const riskScreeningFilters = useMemo(
    () => inquiryProviderFilters.map(x => ({ ...x, name: x.name.replace('provider.', '') })),
    [inquiryProviderFilters],
  )

  riskScreeningFilters.push({ name: 'id', filters: [{ operator: Operators.In, value: providerIds }] })

  const { items: riskScreeningItems } = useFetchCollectionWithPost<RiskTableView>({
    key: [FetchKey.RiskScreening],
    endpoint: endpoints.riskScreeningAllRisks,
    payload: {
      filter: riskScreeningFilters,
      include: [
        'country.risks.riskType',
        'organization.organizationsCountriesExposure',
        'organization.organizationsCountriesExposure.country',
      ],
      sort: {
        order: SortOrder.ASCENDING,
        target: 'name',
      },
      pagination: {
        pageNumber: 1,
        itemsPerPage: 9999,
      },
    },
    options: {
      enabled: Boolean(assessmentTemplate && providerIds?.length),
      staleTime: 60000,
    },
  })

  const getFilteredRiskScreeningItems = useCallback(
    (riskTypeId: number) => riskScreeningItems.filter(item => item.risks?.find(risk => risk.riskTypeId === riskTypeId)),
    [riskScreeningItems],
  )

  const linkedQuestionnaireTemplateIds = assessmentQuestionnaireLinks?.map(x => x.questionnaireTemplateId) ?? []

  const {
    facets: [inquiryStatusFacets],
    count: totalInquriesSent,
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status', isEnum: true }],
    filter: [
      ...inquiryProviderFilters,
      ...insertIf(userFiltersIncludesNonProviderFilters, {
        name: 'provider.id',
        filters: [{ value: providerIds, operator: Operators.In }],
      }),
      {
        name: 'questionnaireTemplateId',
        filters: [
          {
            value: linkedQuestionnaireTemplateIds,
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'periodName',
        filters: [
          {
            value: periodFilterValue,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'provider.deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
    ],
    options: { enabled: Boolean(periodFilterValue) && Boolean(assessmentQuestionnaireLinks?.length) },
  })

  const { count: totalResponsesReceived, isLoading } = useFetchFacets({
    key: FetchKey.ResponseFacets,
    endpoint: endpoints.responsesWithFacets,
    facetsParam: [{ name: 'request.requestStatus', isEnum: true }],
    filter: [
      ...responseProviderFilters,
      ...insertIf(userFiltersIncludesNonProviderFilters, {
        name: 'request.subscriptions.target.id',
        filters: [{ value: providerIds, operator: Operators.In }],
      }),
      {
        name: 'request.questionnaireTemplateId',
        filters: [
          {
            value: linkedQuestionnaireTemplateIds,
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'isLatestSubmitted',
        filters: [
          {
            value: true,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'request.periodName',
        filters: [
          {
            value: periodFilterValue,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'request.subscriptions.target.deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
    ],
    options: { enabled: Boolean(periodFilterValue) && Boolean(assessmentQuestionnaireLinks?.length) },
  })

  const submittedInquiryCount = inquiryStatusFacets
    ?.filter(status => status.label !== InquiryStatus.Requested)
    ?.reduce((total, current) => total + (current.count ?? 0), 0)

  const sharedResponses = totalResponsesReceived - submittedInquiryCount

  if (!assessmentTemplate) return null

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'resourceTypes.assessments' })}</title>
      </Helmet>
      <Stack px={4} pt={2}>
        <SimpleBreadcrumbs
          crumbs={[
            {
              label: formatMessage({
                id: 'resourceTypes.assessments',
              }),
              to: generatePath(paths.assessments),
            },
            {
              label: assessmentTemplate.name,
            },
          ]}
        />
      </Stack>
      <ResourceCollectionScene
        enableScroll
        title={assessmentTemplate.name}
        filter={
          <StandardAssessmentFilters
            allowedFilters={allowedFilters}
            assessmentTemplate={assessmentTemplate}
            selectedPeriod={periodFilterValue}
            onPeriodChange={handlePeriodChange}
          />
        }
        disableLeftPadding
        tabs={tabs}
      >
        {(totalInquriesSent > 0 || totalResponsesReceived > 0) && (
          <Box px={4} mt={3}>
            <ResponseRateSummary
              totalAssessmentResponded={submittedInquiryCount}
              totalInquriesSent={totalInquriesSent}
              questionnaireTemplateIds={
                Array.isArray(questionnaireTemplateId) ? questionnaireTemplateId : [questionnaireTemplateId]
              }
              sharedResponses={sharedResponses}
              periodFilterValue={periodFilterValue}
              isLoading={isLoading || isLoadingAssessmentQuestionnaireLink}
            />
          </Box>
        )}
        <Box px={4} my={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper elevation={0} sx={{ padding: 3, borderRadius: 1, height: '100%' }}>
                <AssessmentOverviewGraph assessmentTemplate={assessmentTemplate} userFilters={userFilters} />
              </Paper>
            </Grid>
            {!isRelatedRiskSettingsFetching &&
              relatedRiskSettings &&
              relatedRiskSettings.map(riskSetting => (
                <Grid item xs={12} md={6} key={riskSetting.riskType.id}>
                  <Paper elevation={0} sx={{ padding: 3, borderRadius: 1, minHeight: 480 }}>
                    <AssessmentRiskIndex
                      assessmentTemplate={assessmentTemplate}
                      riskType={riskSetting.riskType}
                      riskSetting={riskSetting}
                      riskScreeningItems={getFilteredRiskScreeningItems(riskSetting.riskType.id)}
                      userFilters={userFilters}
                    />
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </Box>
      </ResourceCollectionScene>
    </>
  )
}

export default AssessmentInsightsScene
