import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResource } from '@app/src/api/fetchHooks'
import SimpleBreadcrumbs from '@app/src/components/SimpleBreadcrumbs'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import {
  AssessmentTemplate,
  AssessmentTemplateWithClassificationRules,
  OrganizationRiskSetting,
} from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import AnswerClassificationExplanation from './AnswerClassificationExplanation'
import AssessmentMethodologyCard from './AssessmentMethodologyCard'
import PotentialRiskMethodology from './PotentialRiskMethodology'
import QuestionClassification from './QuestionClassification'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'

type AssessmentMethodologySceneProps = {
  assessmentTemplateId: string
  tabs?: ResourceCollectionSceneProps['tabs']
}

const AssessmentMethodologyScene: React.FC<AssessmentMethodologySceneProps> = ({ assessmentTemplateId, tabs }) => {
  const { formatMessage } = useIntl()

  const { items: assessmentTemplates, isFetched: isAssessmentTemplatesFetched } =
    useFetchCollectionWithPost<AssessmentTemplate>({
      endpoint: endpoints.assessmentTemplateCollection,
      key: [FetchKey.AssessmentCollection, assessmentTemplateId],
      payload: {
        filter: [
          {
            name: 'id',
            filters: [
              {
                operator: Operators.EqualTo,
                value: assessmentTemplateId,
              },
            ],
          },
        ],
        include: ['rules'],
      },
      options: {
        staleTime: 60000,
      },
    })

  const assessmentTemplate = assessmentTemplates[0]
  const assessmentRules = assessmentTemplate?.rules ?? []

  const { data: assessmentTemplateWithClassificationRules, isFetched: isAnswerClassificationRulesFetched } =
    useFetchResource<AssessmentTemplateWithClassificationRules>({
      key: [FetchKey.AssessmentTemplateWithClassificationRules, assessmentTemplateId],
      endpoint: endpoints.assessmentTemplateWithClassificationRules(assessmentTemplateId),
      options: {
        staleTime: 60000,
      },
    })

  const { data: assessmentRiskSettings, isFetched: isAssessmentRiskSettingsFetched } = useFetchResource<
    OrganizationRiskSetting[]
  >({
    key: [FetchKey.AssessmentTemplateRiskIndicesCollection, assessmentTemplateId],
    endpoint: endpoints.getAssessmentTemplateRiskIndices(assessmentTemplateId),
    options: {
      staleTime: 60000,
    },
  })

  const questions = assessmentTemplateWithClassificationRules?.questions ?? []
  const isLoading =
    !isAssessmentTemplatesFetched || !isAnswerClassificationRulesFetched || !isAssessmentRiskSettingsFetched

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'resourceTypes.assessments' })}</title>
      </Helmet>
      <Stack px={4} pt={2}>
        <SimpleBreadcrumbs
          crumbs={[
            {
              label: formatMessage({
                id: 'resourceTypes.assessments',
              }),
              to: generatePath(paths.assessments),
            },
            {
              label: formatMessage({ id: 'assessment.tabs.methodology' }),
            },
          ]}
        />
      </Stack>
      <ResourceCollectionScene enableScroll title={assessmentTemplate.name} tabs={tabs} disableLeftPadding>
        <Box px={4} my={3}>
          <Paper elevation={0} sx={{ padding: 3, borderRadius: 2 }}>
            <AssessmentMethodologyCard assessmentTemplate={assessmentTemplate} />
            <Typography variant="h5" gutterBottom>
              {formatMessage({ id: 'assessments.methodology.transparentMethodology' })}
            </Typography>
            <Typography variant="body1" paragraph>
              {formatMessage({ id: 'assessments.methodology.transparentMethodologyDescription' })}
            </Typography>

            {questions.map(question => (
              <QuestionClassification key={question.id} question={question} assessmentRules={assessmentRules} />
            ))}
            <Box mt={4}>
              <AnswerClassificationExplanation />
            </Box>
            {Boolean(assessmentRiskSettings?.length) && (
              <PotentialRiskMethodology assessmentRiskSettings={assessmentRiskSettings} />
            )}
          </Paper>
        </Box>
      </ResourceCollectionScene>
    </>
  )
}

export default AssessmentMethodologyScene
