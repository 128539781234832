import { TrackEvent, useAmplitude } from '@app/src/context/AmplitudeContext'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

interface useAmplitudeTimeSpentTrackingProps {
  timerStarted?: boolean
  eventName: string
  eventProps?: TrackEvent['eventProps']
  eventOptions?: TrackEvent['eventOptions']
}

const mapToTrackEvent = (
  enteredTimestamp: number,
  eventName: string,
  eventProps: TrackEvent['eventProps'],
  eventOptions: TrackEvent['eventOptions'],
) => {
  const timeDifferenceSeconds = Math.round((Date.now() - (enteredTimestamp ?? 0)) / 1000)
  const event: TrackEvent = {
    name: eventName,
    eventProps: { ...eventProps, time_spent_seconds: timeDifferenceSeconds },
    eventOptions,
  }
  return event
}

export const useAmplitudeTimeSpentTracking = ({
  timerStarted,
  eventName,
  eventProps,
  eventOptions,
}: useAmplitudeTimeSpentTrackingProps) => {
  const history = useHistory()
  const { trackEvent, trackEventCloseBrowser } = useAmplitude()
  const [enteredTimestamp, setEnteredTimestamp] = useState<number>()

  const onCloseBrowser = useCallback(() => {
    if (!enteredTimestamp) return

    trackEventCloseBrowser(mapToTrackEvent(enteredTimestamp, eventName, eventProps, eventOptions))
  }, [enteredTimestamp, eventName, eventProps, eventOptions, trackEventCloseBrowser])

  useEffect(() => {
    if (timerStarted === false || enteredTimestamp) return
    setEnteredTimestamp(Date.now())
  }, [timerStarted])

  useEffect(() => {
    if (!enteredTimestamp) return
    const unregisterHistoryListen = history.listen(location => {
      const event = mapToTrackEvent(enteredTimestamp, eventName, eventProps, eventOptions)
      trackEvent(event)
    })

    window.addEventListener('pagehide', onCloseBrowser)

    return () => {
      unregisterHistoryListen()
      window.removeEventListener('pagehide', onCloseBrowser)
    }
  }, [enteredTimestamp])
}
