import { Auth0User } from '@app/src/pages/Configurations/ConfigurationsPages/Users/UsersConfig'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import paths from '@app/src/wf-constants/paths'
import { Box, Card, Chip, Typography, useTheme } from '@mui/material'
import React from 'react'
import { generatePath, useHistory } from 'react-router'

export interface Props {
  user: Auth0User
  organizationId: number
}

const UserCard: React.FC<Props> = ({ user, organizationId }) => {
  const { palette, spacing } = useTheme()
  const history = useHistory()

  const navigateToUser = (user: Auth0User) => {
    history.push(
      generatePath(paths.configurations, {
        configurationsPage: CONFIGURATION_PAGE_IDS.Users,
        configurationsSubPage: user.appMetadata.id,
      }),
    )
  }

  return (
    <Card
      onClick={() => navigateToUser(user)}
      sx={{
        padding: spacing(2),
        marginBottom: spacing(4),
        '&  span.MuiTypography-caption': {
          color: palette.error.dark,
        },
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.grey[300],
        '&:hover': {
          backgroundColor: 'rgba(28, 28, 28, 0.04)',
          cursor: 'pointer',
        },
      }}
      elevation={0}
    >
      <Typography variant="h6">{user?.email}</Typography>
      <Box mt={4}>
        {user.appMetadata.accounts
          .filter(a => a.organizationId === organizationId)
          .map((account, index) => (
            <Chip
              sx={{
                mr: 1,
                ...(account.role === 'WorldfavorEmployeeAdmin' && {
                  borderColor: palette.error.dark,
                }),
              }}
              size="medium"
              variant="outlined"
              key={index}
              label={
                <>
                  <b>{account.solution}</b>: {account.role}
                </>
              }
            />
          ))}
      </Box>
    </Card>
  )
}

export default UserCard
