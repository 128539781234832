import { Clear } from '@mui/icons-material'
import { Box, Button, CircularProgress, IconButton, List, Stack, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewAddProviderReferral from '@app/src/components/Drawer/Views/DrawerViewAddProviderReferral'
import ProviderListCard from '@app/src/components/Form/AddProvider/ProviderListCard'
import TextField from '@app/src/components/Ui/TextField'
import { capitalize } from 'lodash'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'
import { useDebounce } from 'use-debounce'
import DrawerViewAddOrganizationAsProvider from './DrawerViewAddOrganizationAsProvider'

type DrawerViewFindProviderProps = {
  providerType: string
} & Omit<DrawerViewProps, 'title'>

const DrawerViewFindProvider: React.FC<DrawerViewFindProviderProps> = ({ providerType, ...props }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, 500)

  const { data, isLoading } = useFetchResource<Provider[]>({
    endpoint: endpoints.findOrganization(query),
    key: [FetchKey.FindOrganization, query],
    options: {
      enabled: debouncedQuery.length > 2 && query.length > 1,
    },
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!e.target.value) {
      setQuery('')
    } else {
      setQuery(e.target.value)
    }
  }

  return (
    <DrawerView
      title={formatMessage(
        { id: 'resourceCollections.create.findProvider' },
        {
          provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 2 }).toLowerCase(),
        },
      )}
      {...props}
    >
      <Stack px={2}>
        <Typography paragraph>
          {formatMessage(
            { id: 'resourceCollections.create.findProviderSubtitle' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 2 }).toLowerCase() },
          )}
        </Typography>
        <TextField
          hoveringLabel
          onChange={handleChange}
          label={formatMessage({ id: 'schemas.organization.providerName' }, { provider: capitalize(providerType) })}
          placeholder={formatMessage({ id: 'textFieldPlaceholders.searchForEntity' }, { entity: providerType })}
          fullWidth
          value={query}
          data-testid="searchfield-find-providers"
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                onClick={() =>
                  handleChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
                }
              >
                <Clear />
              </IconButton>
            ),
          }}
        />
        <List disablePadding>
          {isLoading && (
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
              <CircularProgress size={24} />
            </Box>
          )}
          {data?.map(provider => (
            <ProviderListCard
              key={provider.id}
              provider={provider}
              onClickProvider={() =>
                openDrawer(<DrawerViewAddOrganizationAsProvider provider={provider} providerType={providerType} />)
              }
            />
          ))}

          {Boolean(query.length > 2) && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={3}
              mt={2}
              border={theme => `1px dashed ${theme.palette.grey[200]}`}
            >
              <Typography variant="subtitle1">
                {formatMessage(
                  { id: 'resourceCollections.create.cantFindSupplier' },
                  {
                    provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }).toLowerCase(),
                  },
                )}
              </Typography>
              <Typography variant="caption" paragraph>
                {formatMessage({ id: 'resourceCollections.create.cantFindSupplierInvite' })}
              </Typography>
              <Button
                variant="outlined"
                onClick={() => openDrawer(<DrawerViewAddProviderReferral providerType={providerType} />)}
              >
                {formatMessage(
                  { id: 'resourceCollections.create.cantFindSupplierInviteButton' },
                  {
                    provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }).toLowerCase(),
                  },
                )}
              </Button>
            </Box>
          )}
        </List>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewFindProvider
