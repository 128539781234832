import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import EmptyState from '@app/src/components/EmptyState'
import LinkButton from '@app/src/components/LinkButton'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { RiskTableView } from '@app/src/types/organizations'
import { Country } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { TuneOutlined } from '@mui/icons-material'
import {
  Box,
  Chip,
  Divider,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import StatisticsCard from '../../StatisticsCard'
import CountryRiskMap from './CountryRiskMap'
import CountryRiskStats from './CountryRiskStatsType'

export enum LocationTypes {
  HQ = 'Head quarters',
  OWNED_OPERATIONS = 'Owned Operations',
  SUB_SUPPLIERS = 'Sub-suppliers',
}

const CountryRiskSection: React.FC = () => {
  const { activeRiskSettings, isLoadingRiskSettings } = useRiskSettings()
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const hasFullDashboardAccess = hasPermission(Permissions.FULL_DASHBOARD_ACCESS)

  const [activeRiskId, setActiveRiskId] = useState<number>()
  const [selectedLocationType, setSelectedLocationType] = useState<string>(LocationTypes.HQ)

  const { items: countries, isLoading: isLoadingCountries } = useFetchCollectionWithPost<Country>({
    key: FetchKey.Country,
    endpoint: endpoints.countries,
    payload: {
      filter: [],
      include: ['risks.riskType'],
    },
  })

  const { items: riskScreeningItems } = useFetchCollectionWithPost<RiskTableView>({
    key: FetchKey.RiskScreening,
    endpoint: endpoints.riskScreening,
    payload: {
      filter: [],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 9999,
      },
      include: [
        'locations',
        'country',
        'organization.*',
        'organization.locations',
        'organization.organizationsCountriesExposure',
        'organization.organizationsCountriesExposure.country',
      ],
    },
  })

  const filteredScreeningItems = useMemo(
    () => riskScreeningItems.filter(item => item.risks?.find(risk => risk.riskTypeId === activeRiskId)),
    [riskScreeningItems, activeRiskId],
  )
  const activeRisk = useMemo(
    () => activeRiskSettings?.find(risk => risk.id === activeRiskId),
    [activeRiskSettings, activeRiskId],
  )

  useEffect(() => {
    if (activeRiskSettings?.[0]) {
      setActiveRiskId(activeRiskSettings[0].id)
    }
  }, [activeRiskSettings])

  if (isLoadingCountries || isLoadingRiskSettings) {
    return <Skeleton variant="rounded" height={360} />
  }

  if (!activeRiskSettings?.length || !activeRiskId) {
    return (
      <StatisticsCard title={formatMessage({ id: 'dashboard.sourcing.countryRiskMap.title' })}>
        <Box py={4}>
          <EmptyState
            iconComponent={TuneOutlined}
            title={formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.title' })}
            description={formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.description' })}
          >
            <LinkButton
              variant="outlined"
              to={generatePath(paths.configurations, { configurationsPage: CONFIGURATION_PAGE_IDS.RiskScreening })}
            >
              {formatMessage({ id: 'dashboard.sourcing.countryRiskMap.emptyState.action' })}
            </LinkButton>
          </EmptyState>
        </Box>
      </StatisticsCard>
    )
  }

  return (
    <StatisticsCard
      title={formatMessage({ id: 'dashboard.sourcing.countryRiskMap.title' })}
      action={
        hasFullDashboardAccess ? (
          <Stack direction="row" alignItems="center" flexGrow={1}>
            <Box ml={2}>
              <MuiTextField
                select
                defaultValue={activeRiskId}
                size="small"
                variant="standard"
                onChange={e => setActiveRiskId(Number(e.target.value))}
                value={activeRiskId}
              >
                {activeRiskSettings?.map(risk => (
                  <MenuItem key={risk.source} value={risk.id}>
                    <Chip
                      key={risk.id}
                      label={formatMessage({ id: `schemas.risk.riskIndexes.${risk.source}.label` })}
                      size="small"
                      color="default"
                    />
                  </MenuItem>
                ))}
              </MuiTextField>
            </Box>

            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" flexGrow={1}>
              <Typography color="primary" variant="body1">
                {formatMessage({ id: 'dashboard.sourcing.countryRiskMap.operations' })}
              </Typography>
              <Box>
                <MuiTextField
                  select
                  defaultValue={selectedLocationType}
                  size="small"
                  variant="standard"
                  onChange={e => setSelectedLocationType(e.target.value)}
                  value={selectedLocationType}
                >
                  {Object.entries(LocationTypes).map(([key, locationType]) => (
                    <MenuItem key={locationType} value={locationType}>
                      <Chip
                        key={locationType}
                        label={formatMessage({ id: `dashboard.sourcing.countryRiskMap.${key}` })}
                        size="small"
                        color="default"
                      />
                    </MenuItem>
                  ))}
                </MuiTextField>
              </Box>
            </Stack>
          </Stack>
        ) : undefined
      }
    >
      <Grid container>
        <Grid item xs={12} lg={9}>
          <Box position="relative" display={{ xs: 'block', lg: 'flex' }}>
            <CountryRiskMap
              activeRisk={activeRisk}
              riskScreening={filteredScreeningItems}
              countries={countries}
              selectedRiskId={activeRiskId}
              selectedLocationType={selectedLocationType}
            />
            <Box>
              <Divider orientation="vertical" sx={{ display: { xs: 'none', lg: 'block' } }} />
              <Divider sx={{ display: { xs: 'block', lg: 'none' } }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={3} display="flex">
          <CountryRiskStats
            riskScreening={filteredScreeningItems}
            activeRiskType={activeRisk}
            countries={countries}
            selectedRiskId={activeRiskId}
            selectedLocationType={selectedLocationType}
          />
        </Grid>
      </Grid>
    </StatisticsCard>
  )
}

export default CountryRiskSection
