import { Question } from '@app/src/types/resourceExplorer'
import { Box, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import ClassificationRow, { sortClassificationBasedOnRuleType } from './ClassificationRow'

type AnswerClassificationPreviewCardProps = {
  questionAnswerClassifications?: Question['questionAnswerClassificationRules']
  showTitle?: boolean
}

const AnswerClassificationPreviewCard: React.FC<AnswerClassificationPreviewCardProps> = ({
  questionAnswerClassifications,
  showTitle = true,
}) => {
  const { formatMessage } = useIntl()
  const { answerClassifications, numberClassifications, optionClassifications } = useMemo(
    () => sortClassificationBasedOnRuleType(questionAnswerClassifications),
    [questionAnswerClassifications],
  )

  return (
    <Stack>
      {showTitle && (
        <Typography variant="subtitle1" paragraph>
          {formatMessage({ id: 'templateBuilder.answerClassifications.title' })}
        </Typography>
      )}

      <Box mt={2}>
        {optionClassifications.map(item => (
          <ClassificationRow
            key={item?.rank}
            item={item}
            translationKey="templateBuilder.answerClassifications.optionValue"
          />
        ))}

        {numberClassifications.map(item => (
          <ClassificationRow
            key={item?.rank}
            item={item}
            translationKey="templateBuilder.answerClassifications.numberValue"
          />
        ))}

        {answerClassifications.map(item => (
          <ClassificationRow
            key={item?.rank}
            item={item}
            translationKey={`templateBuilder.answerClassifications.classificationsValues.${item?.value}`}
          />
        ))}
      </Box>
    </Stack>
  )
}

export default AnswerClassificationPreviewCard
