import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import RiskIndexInfoDrawerView from '@app/src/components/Drawer/Views/RiskIndexInfoDrawerView'
import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { OrganizationRiskSetting } from '@app/src/types/resourceExplorer'
import { ListItem, ListItemAvatar, ListItemText, TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import RiskIndexIcon from './RiskIndexIcons/RiskIndexIcon'
import useRiskSettingActivation from '@app/src/hooks/useRiskSettingActivation'
import SwitchCell from '@app/src/components/Table/Cells/SwitchCell'

interface RiskIndexIconProps {
  row: OrganizationRiskSetting
}

export const RiskSettingsRow: React.FC<RiskIndexIconProps> = ({ row: riskSetting }: RiskIndexIconProps) => {
  const [isRiskIndexActivated, setIsRiskIndexActivated] = useState(riskSetting.isActivated)
  const { toggleRiskActivationAsync, isSaving } = useRiskSettingActivation(riskSetting, {
    onToggled: isActivated => {
      setIsRiskIndexActivated(isActivated)
    },
  })
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const onChange = async () => {
    if (!isSaving) {
      setIsRiskIndexActivated(!isRiskIndexActivated)
      await toggleRiskActivationAsync()
    }
  }

  return (
    <>
      <TableRow>
        <SwitchCell checked={isRiskIndexActivated} onChange={onChange} />
        <TableCell>
          <ListItem component="div">
            <ListItemAvatar>
              <Avatar
                size={AvatarSize.XXL}
                sx={({ palette }) => ({
                  backgroundColor: 'white',
                  border: `1px solid ${palette.secondary.main}`,
                  mr: 2,
                })}
              >
                <RiskIndexIcon source={riskSetting.riskType.source} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: `schemas.risk.riskIndexes.${riskSetting.riskType.source}.label` })}
              secondary={formatMessage({ id: `schemas.risk.riskIndexes.${riskSetting.riskType.source}.source` })}
            />
          </ListItem>
        </TableCell>
        <CategoryCell
          value={formatMessage({ id: `schemas.risk.esgArea.${riskSetting.riskType.sustainabilityArea}` })}
        />
        <CategoryCell value={formatMessage({ id: `schemas.risk.indexType.${riskSetting.riskType.riskIndexType}` })} />
        <ButtonCell
          label={formatMessage({ id: 'general.showMore' })}
          onClick={() => openDrawer(<RiskIndexInfoDrawerView riskType={riskSetting.riskType} />)}
        />
      </TableRow>
    </>
  )
}

export default RiskSettingsRow
