import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as Sentry from '@sentry/react'
import { RouteWithPermission, Routes } from './appRoutes'
import GlobalError, { logError } from '@app/src/components/GlobalError'
import WfLoader from '@app/src/components/WfLoader'
import { Config } from './config'
import { useAccount } from '@app/src/context/AccountContext'
import RequestError from '@app/src/errors/RequestError'
import useIntercom from '@app/src/hooks/intercom'
import useSolutionAutoLogin from '@app/src/hooks/solutionAutoLogin'
import useLaunchDarkly from '@app/src/hooks/useLaunchDarkly'
import React, { Suspense, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom'
import InactivityCheck from './components/InactivityCheck'
import Navbar from './components/Navbar'

const SentryRoute = Sentry.withSentryRouting(Route)

const HAS_RELOADED_COOKIE = 'reloaded-for-missing-chunks'

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  snackbar: {
    top: theme.spacing(8),
  },
}))

const IntercomUpdater = (): null => {
  // updates intercom for every route
  const { pathname } = useLocation()

  useEffect(() => {
    if (!window.Intercom) {
      return
    }
    window.Intercom('update')
  }, [pathname])

  return null
}

const RouteWithErrorBoundary = (props: RouteProps) => {
  const history = useHistory()
  const titlePrefix = Config.FRONT_ENV !== 'prod' ? `[${Config.FRONT_ENV}] ` : ''
  const titleTemplate = `${titlePrefix}%s | Worldfavor`
  const [cookies, setCookies] = useCookies([HAS_RELOADED_COOKIE])

  return (
    <>
      <Helmet titleTemplate={titleTemplate} defaultTitle={`${titlePrefix}Worldfavor`} />
      <ErrorBoundary
        FallbackComponent={GlobalError}
        onError={(error, info): void => {
          if (error.name === 'ChunkLoadError') {
            // forcing the browser to reload if a chunk is missing, most likely due to a recent deployment
            // setting a cookie to avoid an infinite loop in case the chunk is really missing
            if (!cookies[HAS_RELOADED_COOKIE]) {
              setCookies(HAS_RELOADED_COOKIE, 1, { maxAge: 60 /*seconds*/ })
              window.location.reload()
              return
            }
          }
          logError(error, info)

          if (error instanceof RequestError && error.statusCode === 401) {
            history.replace('/logout')
          }
        }}
      >
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Suspense
            fallback={
              <>
                <WfLoader />
              </>
            }
          >
            <SentryRoute {...props} />
          </Suspense>
        </Box>
      </ErrorBoundary>
    </>
  )
}

export const App: React.FC = () => {
  useIntercom()
  const classes = useStyles()
  const { hasPermission } = useAccount()

  useSolutionAutoLogin()
  useLaunchDarkly()

  return (
    <Box className={classes.box}>
      <Switch>
        <SentryRoute path="/">
          <InactivityCheck />
          <Navbar />
          <Route path="/" component={IntercomUpdater} />
          <Switch>
            {Routes?.map((route: RouteWithPermission) => {
              if (route.permission && !hasPermission(route.permission)) return null

              return (
                <RouteWithErrorBoundary
                  exact={route.exact}
                  key={route.name}
                  path={route.path}
                  component={route.component}
                />
              )
            })}
          </Switch>
        </SentryRoute>
      </Switch>
    </Box>
  )
}

export default App
