import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { ConnectProvidersFormData } from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/hooks/inviteEmail'
import { Organization, Provider } from '@app/src/types/organizations'
import { br } from '@app/src/utils/translationMarkup'
import { BusinessOutlined, NotificationsNoneOutlined } from '@mui/icons-material'
import { Alert, Box, Button, Divider, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

export interface EmailPreviewProps {
  provider: Provider | Organization
  isReminderEmail?: boolean
  header: string
  description: string
  ctaText: string
}

function EmailPreview({ provider, isReminderEmail, header, description, ctaText }: EmailPreviewProps) {
  const { account } = useAccount()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { formatMessage } = useIntl()
  const { watch } = useFormContext<ConnectProvidersFormData>()

  const customMessageWatch = watch('customMessage')
  const contactPersonInfoWatch = watch('contactPersonInfo')

  const currentOrg = accessibleOrganizations.find(
    org => org?.organization?.id === account?.organization?.id,
  )?.organization

  const currentOrgName = currentOrg?.name

  return (
    <>
      <Alert variant="filled" severity="info">
        {formatMessage({ id: 'form.connectProviders.emailPreview.previewTitle' })}
      </Alert>
      <Stack
        flexGrow={1}
        py={4}
        maxWidth={480}
        maxHeight={{ md: 'calc(100vh - 120px)' }}
        alignSelf="center"
        overflow="auto"
      >
        <ListItem sx={{ pb: 2 }}>
          <ListItemAvatar>
            <Avatar variant="circular"> </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography>
                {formatMessage(
                  {
                    id: isReminderEmail
                      ? 'form.requestsReminder.emailTitle'
                      : 'form.connectProviders.emailPreview.emailTitle',
                  },
                  { currentOrgName },
                )}
              </Typography>
            }
            secondary="info@worldfavor.com"
          />
        </ListItem>
        <Stack p={3} bgcolor="common.white" spacing={4}>
          <Stack direction="row">
            <Avatar size={AvatarSize.XXL} sx={{ zIndex: 2 }} variant="rounded" src={currentOrg?.image?.url}>
              <BusinessOutlined />
            </Avatar>
            {isReminderEmail && (
              <Avatar size={AvatarSize.XXL} sx={{ bgcolor: 'error.main', color: 'common.black', ml: -2, zIndex: 1 }}>
                <NotificationsNoneOutlined fontSize="large" color="inherit" />
              </Avatar>
            )}
          </Stack>
          <Typography variant="h5">{header}</Typography>
          <Typography>{description}</Typography>
          {!isReminderEmail && (
            <Stack
              border={({ palette }) => `1px solid ${palette.grey[300]}`}
              bgcolor="grey.100"
              borderRadius={2}
              p={3}
              spacing={1}
            >
              <Stack direction="row" spacing={1}>
                <Typography>{formatMessage({ id: 'form.connectProviders.emailPreview.yourCompany' })}</Typography>
                <Typography variant="subtitle1">{provider.name}</Typography>
              </Stack>
              {provider.country && (
                <Stack direction="row" spacing={1}>
                  <Typography>{formatMessage({ id: 'form.connectProviders.emailPreview.country' })}</Typography>
                  <Typography variant="subtitle1">{provider.country.name}</Typography>
                </Stack>
              )}
            </Stack>
          )}
          <Box>
            <Button variant="contained">{ctaText}</Button>
          </Box>
          <Stack direction="row" spacing={0.5}>
            <Typography>{formatMessage({ id: 'form.connectProviders.emailPreview.orVisit' })}</Typography>
            <Typography sx={{ textDecoration: 'underline' }}>https://app.worldfavor.com</Typography>
          </Stack>
          {(customMessageWatch || contactPersonInfoWatch) && (
            <Stack border={({ palette }) => `1px solid ${palette.grey[300]}`} borderRadius={2} p={3} spacing={2}>
              {customMessageWatch && (
                <Stack spacing={1}>
                  <Typography variant="subtitle1">
                    {formatMessage({ id: 'form.connectProviders.emailPreview.messageFrom' }, { currentOrgName })}
                  </Typography>
                  <Typography>{customMessageWatch}</Typography>
                </Stack>
              )}
              {customMessageWatch && contactPersonInfoWatch && <Divider />}
              {contactPersonInfoWatch && (
                <Stack spacing={1}>
                  <Typography variant="subtitle1">
                    {formatMessage({ id: 'form.connectProviders.emailPreview.contactPerson' })}
                  </Typography>
                  <Stack>
                    <Typography>{contactPersonInfoWatch.name}</Typography>
                    <Typography>{contactPersonInfoWatch.email}</Typography>
                    <Typography>{contactPersonInfoWatch.phoneNumber}</Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
        {isReminderEmail ? (
          <Stack width="100%" p={4} bgcolor="grey.200" spacing={2}>
            <Typography variant="subtitle1">{formatMessage({ id: 'form.requestsReminder.needHelpTitle' })}</Typography>
            <Typography>{formatMessage({ id: 'form.requestsReminder.needHelpDescription' })}</Typography>
          </Stack>
        ) : (
          <Stack bgcolor="grey.200" width="100%" p={6} spacing={6}>
            <Stack direction="row" justifyContent="center">
              <Box
                sx={{
                  backgroundImage: `url('/img/trusted-companies.png')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
                height={70}
                width="100%"
              />
            </Stack>
            <Typography>{formatMessage({ id: 'form.connectProviders.emailPreview.wfInfo' })}</Typography>
            <Box>
              <Button>{formatMessage({ id: 'form.connectProviders.emailPreview.learnMore' })}</Button>
            </Box>
          </Stack>
        )}

        <Stack bgcolor="common.black" width="100%" p={4}>
          <Typography sx={{ color: 'common.white' }}>
            {formatMessage({ id: 'form.connectProviders.emailPreview.footer' }, { br })}
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}

export default EmailPreview
