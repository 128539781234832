import { FlagOutlined } from '@mui/icons-material'
import { Box, ButtonBase } from '@mui/material'
import Avatar, { AvatarProps } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { insertObjIf } from '@app/src/utils/helpersTs'

type FlagAvatarProps = {
  isEnabled?: boolean
  onClick?: () => void
} & AvatarProps

const FlagAvatar: React.FC<FlagAvatarProps> = ({ size, isEnabled = true, onClick }) => (
  <Box component={onClick ? ButtonBase : undefined} onClick={onClick}>
    <Avatar
      size={size}
      variant="rounded"
      sx={{
        color: isEnabled ? 'error.contrastText' : 'common.black',
        backgroundColor: isEnabled ? 'error.main' : 'grey.400',
        ...insertObjIf(Boolean(onClick), { '&:hover': { backgroundColor: 'error.dark' } }),
      }}
    >
      <FlagOutlined />
    </Avatar>
  </Box>
)

export default FlagAvatar
