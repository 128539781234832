import { useCallback, useReducer } from 'react'
import {
  Pagination,
  PaginationAction,
  ResetPage,
  SetPage,
  SetPageSize,
  SET_PAGE,
  SET_PAGE_SIZE,
} from '@app/src/types/filter'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { DEFAULT_TABLE_PAGE_SIZE } from '@app/src/wf-constants'

const INITIAL_STATE = {
  page: 1,
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
}

function usePaginationReducer(state: Pagination = INITIAL_STATE, action: PaginationAction): Pagination {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      }
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      }
    default:
      return state
  }
}

const usePagination = (initialState = INITIAL_STATE): [number, number, SetPage, SetPageSize, ResetPage] => {
  const [{ page, pageSize }, dispatch] = useReducer(usePaginationReducer, initialState)

  const resetPage = () => setPage(1)

  const setPage: SetPage = useCallback(
    (page): void => {
      dispatch({ type: SET_PAGE, page })
    },
    [dispatch],
  )

  const setPageSize: SetPageSize = useCallback(
    (pageSize): void => {
      dispatch({ type: SET_PAGE_SIZE, pageSize })
    },
    [dispatch],
  )

  useDeepCompareEffect(() => {
    // reset page when filters change
    setPage(1)
  }, [setPage, pageSize])

  return [page, pageSize, setPage, setPageSize, resetPage]
}

export default usePagination
