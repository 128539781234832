import { SvgIcon } from '@mui/material'
import React from 'react'
import { IntervalChartProps } from '../ThreeIntervals/IntervalChartIconOne'

export const IntervalChartIconTwo: React.FC<IntervalChartProps> = ({ large }) => {
  const iconSize = large ? { width: 40, height: 40 } : {}
  return (
    <SvgIcon sx={iconSize}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <circle cx="8" cy="8" r="7" fill="#E0E0E0" stroke="white" strokeWidth="2" />
        <mask
          id="mask0_5431_2356"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="12"
          height="12"
        >
          <circle cx="8" cy="8" r="6" fill="#E0E0E0" />
        </mask>
        <g mask="url(#mask0_5431_2356)">
          <circle cx="8" cy="8" r="6" fill="#E0E0E0" />
          <mask
            id="path-4-outside-1_5431_2356"
            maskUnits="userSpaceOnUse"
            x="7"
            y="1"
            width="8"
            height="11"
            fill="black"
          >
            <rect fill="white" x="7" y="1" width="8" height="11" />
            <path d="M8 2C9.29183 2 10.5615 2.33214 11.6922 2.96447C12.8229 3.5968 13.7804 4.5103 14.4721 5.62959C15.1638 6.74887 15.5692 8.03678 15.6465 9.36696C15.7238 10.6971 15.4702 12.0249 14.9068 13.2279L8 8V2Z" />
          </mask>
          <path
            d="M8 2C9.29183 2 10.5615 2.33214 11.6922 2.96447C12.8229 3.5968 13.7804 4.5103 14.4721 5.62959C15.1638 6.74887 15.5692 8.03678 15.6465 9.36696C15.7238 10.6971 15.4702 12.0249 14.9068 13.2279L8 8V2Z"
            fill="#FA7A5D"
          />
          <path
            d="M8 2C9.29183 2 10.5615 2.33214 11.6922 2.96447C12.8229 3.5968 13.7804 4.5103 14.4721 5.62959C15.1638 6.74887 15.5692 8.03678 15.6465 9.36696C15.7238 10.6971 15.4702 12.0249 14.9068 13.2279L8 8V2Z"
            stroke="white"
            strokeWidth="2"
            mask="url(#path-4-outside-1_5431_2356)"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default IntervalChartIconTwo
