import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import HoverDialog from '@app/src/components/HoverDialog'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderTierValues } from '@app/src/wf-constants'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import GraphLegend from '../../GraphLegend'
import { companiesListAllowedFilters } from './CompaniesList'
import CompaniesStatisticGraph from './CompaniesStatisticGraph'
import { handleFilterValue } from './CompaniesStatistics'

const TiersGraph = () => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const [highlightedTier, setHighlightedTier] = useState<string | number>()
  const filters = useGetApiQueryFilters(companiesListAllowedFilters)

  const {
    facets: [providerTier],
  } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'tier' }],
    filter: filters,
  })

  const notSelectedCount = providerTier?.find(company => company.value === ProviderTierValues.NotSelected)?.count ?? 0
  const tier1Count = providerTier?.find(company => company.value === ProviderTierValues.Tier1)?.count ?? 0
  const tier2Count = providerTier?.find(company => company.value === ProviderTierValues.Tier2)?.count ?? 0
  const tier3Count = providerTier?.find(company => company.value === ProviderTierValues.Tier3)?.count ?? 0
  const tier4Count = providerTier?.find(company => company.value === ProviderTierValues.Tier4)?.count ?? 0

  const totalCompanies = notSelectedCount + tier1Count + tier2Count + tier3Count + tier4Count

  return (
    <HoverDialog
      content={
        <Box p={2} onMouseEnter={() => setHighlightedTier(undefined)}>
          <Stack spacing={1}>
            <Typography variant="overline" color="textSecondary" noWrap>
              {formatMessage({ id: 'dashboard.sourcing.companyStatistics.companiesByTier' })}
            </Typography>
            <Filter operator={Operators.In} name="tier">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedTier === ProviderTierValues.Tier1 ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedTier(ProviderTierValues.Tier1)}
                  onMouseLeave={() => setHighlightedTier(undefined)}
                  onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.Tier1)))}
                >
                  <GraphLegend
                    color={palette.visualization[2]}
                    variant="body1"
                    legend={`${formatMessage({ id: 'schemas.provider.tierValues' }, { tier: 1 })} (${tier1Count})`}
                  />
                  {highlightedTier === ProviderTierValues.Tier1 && (
                    <Button onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.Tier1)))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="tier">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedTier === ProviderTierValues.Tier2 ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedTier(ProviderTierValues.Tier2)}
                  onMouseLeave={() => setHighlightedTier(undefined)}
                  onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.Tier2)))}
                >
                  <GraphLegend
                    color={palette.visualization[3]}
                    variant="body1"
                    legend={`${formatMessage({ id: 'schemas.provider.tierValues' }, { tier: 2 })} (${tier2Count})`}
                  />
                  {highlightedTier === ProviderTierValues.Tier2 && (
                    <Button onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.Tier2)))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="tier">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedTier === ProviderTierValues.Tier3 ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedTier(ProviderTierValues.Tier3)}
                  onMouseLeave={() => setHighlightedTier(undefined)}
                  onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.Tier3)))}
                >
                  <GraphLegend
                    color={palette.visualization[7]}
                    variant="body1"
                    legend={`${formatMessage({ id: 'schemas.provider.tierValues' }, { tier: 3 })} (${tier3Count})`}
                  />
                  {highlightedTier === ProviderTierValues.Tier3 && (
                    <Button onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.Tier3)))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="tier">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedTier === ProviderTierValues.Tier4 ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedTier(ProviderTierValues.Tier4)}
                  onMouseLeave={() => setHighlightedTier(undefined)}
                  onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.Tier4)))}
                >
                  <GraphLegend
                    color={palette.visualization[10]}
                    variant="body1"
                    legend={`${formatMessage({ id: 'schemas.provider.tierValues' }, { tier: 4 })} (${tier4Count})`}
                  />
                  {highlightedTier === ProviderTierValues.Tier4 && (
                    <Button onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.Tier4)))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="tier">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedTier === ProviderTierValues.NotSelected ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedTier(ProviderTierValues.NotSelected)}
                  onMouseLeave={() => setHighlightedTier(undefined)}
                  onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.NotSelected)))}
                >
                  <GraphLegend
                    color={palette.grey[300]}
                    variant="body1"
                    legend={`${formatMessage(
                      { id: 'schemas.provider.tierValues' },
                      { tier: 0 },
                    )} (${notSelectedCount})`}
                  />
                  {highlightedTier === ProviderTierValues.NotSelected && (
                    <Button onClick={() => onChange(handleFilterValue(value, String(ProviderTierValues.NotSelected)))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
          </Stack>
        </Box>
      }
    >
      <Box>
        <CompaniesStatisticGraph
          highlightSetter={setHighlightedTier}
          title={formatMessage({ id: `dashboard.sourcing.companyStatistics.tier` })}
          data={[
            { value: tier1Count, name: ProviderTierValues.Tier1 },
            { value: tier2Count, name: ProviderTierValues.Tier2 },
            { value: tier3Count, name: ProviderTierValues.Tier3 },
            { value: tier4Count, name: ProviderTierValues.Tier4 },
            { value: notSelectedCount, name: ProviderTierValues.NotSelected },
          ]}
          colors={[
            palette.visualization[2],
            palette.visualization[3],
            palette.visualization[7],
            palette.visualization[10],
            palette.grey[300],
          ]}
          value={Math.ceil(100 - (notSelectedCount * 100) / totalCompanies)}
        />
      </Box>
    </HoverDialog>
  )
}

export default TiersGraph
