import { Settings, SettingsOutlined } from '@mui/icons-material'
import React from 'react'
import { useIntl } from 'react-intl'
import paths from '@app/src/wf-constants/paths'
import NavbarMenuItem from '@app/src/components/Navbar/NavbarItems/NavbarMenuItem'

const ConfigurationsMenuItem: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <NavbarMenuItem
      path={paths.configurations}
      title={formatMessage({ id: 'configurations.configurations' })}
      icon={SettingsOutlined}
      iconSelected={Settings}
    />
  )
}

export default ConfigurationsMenuItem
