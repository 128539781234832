import { Alert, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

type AlertTypes = 'success' | 'info' | 'warning' | 'error' | undefined

interface Props {
  value?: string
  small?: boolean
  large?: boolean
  severity?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    border: 'none',
    outline: 'none',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  message: {
    padding: theme.spacing(0),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0),
  },
  outlinedInfo: {
    color: theme.palette.info.main,
  },
  outlinedWarning: {
    color: theme.palette.warning.main,
  },
  outlinedError: {
    color: theme.palette.error.main,
  },
  outlinedSuccess: {
    color: theme.palette.success.main,
  },
}))

const EvaluationStatus = ({ value, small, large }: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  const getAlertState = (): AlertTypes => {
    switch (value) {
      case 'Medium':
      case 'MediumRisk':
      case 'UnderEvaluation':
        return 'warning'

      case 'Low':
      case 'LowRisk':
      case 'ApprovedWithException':
      case 'ApprovedWithoutException':
      case 'Signed':
      case 'MeetsRequirement':
        return 'success'

      case 'Extreme':
      case 'High':
      case 'HighRisk':
      case 'NotApproved':
      case 'NotSigned':
      case 'DoesNotMeetRequirement':
        return 'error'

      default:
        return 'info'
    }
  }
  const severity = getAlertState()
  const classes = useStyles({ small, large, severity })

  if (!value) {
    return <>{formatMessage({ id: 'general.none' })}</>
  }

  return (
    <Alert
      classes={{
        root: classes.chip,
        icon: classes.icon,
        message: classes.message,
        outlinedInfo: classes.outlinedInfo,
        outlinedWarning: classes.outlinedWarning,
        outlinedError: classes.outlinedError,
        outlinedSuccess: classes.outlinedSuccess,
      }}
      variant="outlined"
      severity={severity}
    >
      <Typography variant="subtitle1">{value}</Typography>
    </Alert>
  )
}

export default EvaluationStatus
