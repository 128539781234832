import { TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProviderRiskHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.providerRisk.companyName' })}
        sorting={{ name: 'provider.name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.providerRisk.riskStatus' })}
        sorting={{ name: 'riskStatus', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.providerRisk.environmental' })}
        sorting={{ name: 'environmentFlagCount', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.providerRisk.social' })}
        sorting={{ name: 'socialFlagCount', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.providerRisk.governance' })}
        sorting={{ name: 'governanceFlagCount', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.providerRisk.customFlags' })}
        sorting={{ name: 'customFlagCount', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.providerRisk.assessment' })}
        sorting={{ name: 'provider.finalRiskRating', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM}
      />
    </TableRow>
  )
}

export default ProviderRiskHeader
