import { Box, Typography } from '@mui/material'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ProviderFinalRiskRatingValues } from '@app/src/wf-constants'

const FinalRiskRatingUpdateDrawerContent: React.FC = () => {
  const { formatMessage } = useIntl()
  const { register, control } = useFormContext()
  const options = [
    ProviderFinalRiskRatingValues.NotSelected,
    ProviderFinalRiskRatingValues.LowRisk,
    ProviderFinalRiskRatingValues.MediumRisk,
    ProviderFinalRiskRatingValues.HighRisk,
    ProviderFinalRiskRatingValues.ExtremeRisk,
  ].map(o => ({
    label: formatMessage({ id: `schemas.provider.finalRiskRatingValues.${o}` }),
    value: o,
  }))

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box mt={2}>
        <Select
          name="newFinalRiskRating"
          control={control}
          required
          options={options}
          findSelectedValue={(value, option) => option.value === value}
          objectToOption={object => ({
            label: formatMessage({ id: `schemas.provider.finalRiskRatingValues.${object}` }),
            value: object,
          })}
          fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
        />
      </Box>
      <Box mt={2}>
        <Typography>{formatMessage({ id: 'schemas.provider.internalComment' })}</Typography>
        <TextField inputRef={register} multiline rows={5} fullWidth name="comment" />
      </Box>
    </Box>
  )
}
export default FinalRiskRatingUpdateDrawerContent
