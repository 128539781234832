import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { Organization } from '@app/src/types/organizations'
import { AssignSolution } from '@app/src/types/resourceExplorer'
import { getSolutionTitle } from '@app/src/utils'
import { br } from '@app/src/utils/translationMarkup'
import { AccountType, CreationLimits } from '@app/src/wf-constants'
import { Delete, InfoOutlined } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Drawer,
  FormControlLabel,
  FormLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Auth0User } from '../Users/UsersConfig'
import OrganizationDeleteDialog from './OrganizationDeleteDialog'
import UserCard from './UserCard'

type Props = {
  id: string
}

const OrganizationDetails: React.FC<Props> = ({ id }) => {
  const [isDrawerOpen, openDrawer, closeDrawer] = useDialogState(false)
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const { control, register, handleSubmit, errors } = useForm<AssignSolution>()
  const { mutateAsync, isLoading: isAssingSolutionLoading } = useCreateResource<AssignSolution>()
  const solutionsThatCanBeAdded = [
    AccountType.SOURCING,
    AccountType.SOURCINGSTART,
    AccountType.INVESTMENT,
    AccountType.FINANCE,
  ]

  const copyToClipBoard = async (text: string) => {
    await navigator.clipboard.writeText(text)
  }

  const onSubmit = async (values: AssignSolution) => {
    if (organization && organization.id) {
      await mutateAsync(
        {
          url: endpoints.assignSolution,
          body: {
            organizationId: organization.id,
            accountType: values.accountType,
            isPayingAccessor: values.isPayingAccessor,
          },
        },
        {
          onSuccess: () => {
            showSnackbar({ message: 'Success!', severity: 'success' })
            closeDrawer()
          },
          onError: error => {
            showErrorNotification({ requestError: error })
          },
        },
      )
    }
  }

  const {
    data: organization,
    isLoading,
    isError,
  } = useFetchResource<Organization>({
    key: [FetchKey.Organization, id],
    endpoint: endpoints.organization(id),
  })

  const { data: usersWithAccessToOrganization } = useFetchResource<Auth0User[]>({
    key: [FetchKey.Users, id],
    endpoint: `${endpoints.userManagement}/byOrganizationId/${id}`,
  })

  if (isLoading)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <CircularProgress />
      </Box>
    )

  if (!organization || isError) return <Box>organization Not Found</Box>

  return (
    <>
      <Box width="100%" px={5} py={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack marginBottom={3} paddingLeft={1} display="flex" alignItems="center">
            <Typography variant="h1">{organization.name}</Typography>
          </Stack>
          <Stack direction="row" alignItems="start" spacing={2}>
            <Button variant="contained" color="error" startIcon={<Delete />} onClick={openDeleteDialog}>
              Delete Organization
            </Button>
            <Button variant="contained" startIcon={<AddIcon />} onClick={openDrawer}>
              Add access to a solution
            </Button>
          </Stack>
        </Stack>
        <Box marginBottom={4} paddingLeft={1} display="flex">
          <Box marginRight={1}>
            <Tooltip title="Worldfavor Id">
              <Chip onClick={() => copyToClipBoard(organization.id.toString())} label={organization.id} />
            </Tooltip>
          </Box>
          {organization.assignedSolutions?.map(assignedSolution => (
            <Box key={assignedSolution.id} marginRight={1}>
              <Chip label={assignedSolution.accountType} />
            </Box>
          ))}
        </Box>
        <Stack>
          {usersWithAccessToOrganization?.map(user => (
            <UserCard key={user.auth0Id} user={user} organizationId={parseInt(id)} />
          ))}
        </Stack>
      </Box>
      <Drawer open={isDrawerOpen} onClose={closeDrawer} anchor="right">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box pb={2}>
            <Typography variant="h3">Grant access to solution</Typography>
          </Box>

          <Select<AccountType, AccountType>
            control={control}
            error={errors.accountType?.message}
            rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
            name="accountType"
            fieldLabel="Solution"
            options={solutionsThatCanBeAdded.map(solution => ({
              value: solution,
              label: getSolutionTitle(solution),
            }))}
          />
          <FormControlLabel
            control={
              <Box display="flex" alignItems="center">
                <Switch color="primary" name="isPayingAccessor" defaultChecked={false} inputRef={register()} />
              </Box>
            }
            labelPlacement="end"
            label={
              <Box display="flex" alignItems="center">
                <FormLabel component="legend">Paying Accessor</FormLabel>
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="body2" paragraph fontWeight="bold">
                        {formatMessage({ id: 'payingAccessorInfo.title' })}
                      </Typography>
                      <Typography variant="body2" paragraph>
                        {formatMessage(
                          { id: 'payingAccessorInfo.description' },
                          { categoryOptionLimit: CreationLimits.CategoryOptions, br },
                        )}
                      </Typography>
                      <Typography variant="body2" paragraph fontWeight="bold">
                        {formatMessage({ id: 'payingAccessorInfo.conclusionBold' })}
                      </Typography>
                      <Typography variant="body2" paragraph>
                        {formatMessage({ id: 'payingAccessorInfo.conclusion' })}
                      </Typography>
                    </Box>
                  }
                >
                  <InfoOutlined fontSize="small" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            }
            sx={{ mb: 1 }}
          />

          <LoadingButton
            loading={isAssingSolutionLoading}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            size="large"
            fullWidth
            sx={{ mt: 2 }}
          >
            Add
          </LoadingButton>
        </form>
      </Drawer>
      <OrganizationDeleteDialog
        isOpen={isDeleteDialogOpen}
        closeDialog={closeDeleteDialog}
        organization={organization}
      />
    </>
  )
}

export default OrganizationDetails
