import { TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProductMappingContactHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.contact.name' })}
        sorting={{ name: 'user.givenName', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.contact.organization' })}
        sorting={{ name: 'creatorOrganization.name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.contact.position' })}
        sorting={{ name: 'position', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.contact.phone' })}
        sorting={{ name: 'phoneNumber', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.contact.email' })}
        sorting={{ name: 'user.email', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
    </TableRow>
  )
}

export default ProductMappingContactHeader
