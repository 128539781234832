import { Drawer, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
//import Drawer from '@app/src/components/Drawer'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { useIntl } from 'react-intl'
import { formattedStringify } from '@app/src/utils/helpers'

interface Props {
  open: boolean
  closeDrawer: () => void
  oldCode?: object
  newCode?: object
}

const DiffViewer: React.FC<Props> = ({ open, closeDrawer, oldCode, newCode }) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  const diffStyle = useMemo(
    () => ({
      variables: {
        light: {
          diffViewerBackground: theme.palette.background.default,
          codeFoldBackground: theme.palette.background.paper,
        },
      },
    }),
    [],
  )

  return (
    <Drawer
      //classes={{ paper: classes.drawer, content: classes.content }}
      open={open}
      //closeDrawer={closeDrawer}
      onClose={closeDrawer}
      anchor="right"
      title={formatMessage({ id: 'schemas.auditLog.snapshot' })}
    >
      <ReactDiffViewer
        oldValue={formattedStringify(oldCode)}
        newValue={formattedStringify(newCode)}
        styles={diffStyle}
        splitView
        hideLineNumbers
        compareMethod={DiffMethod.WORDS}
      />
    </Drawer>
  )
}

export default DiffViewer
