import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import LinkButton from '@app/src/components/LinkButton'
import Markdown from '@app/src/components/Markdown'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAccount } from '@app/src/context/AccountContext'
import { QuestionnaireConfigTabs } from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/QuestionnaireConfig'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ConditionType, ReportFlagRule } from '@app/src/types/flags'
import { QuestionnaireTemplate, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { AddOutlined, DataObjectOutlined, FlagOutlined } from '@mui/icons-material'
import { Alert, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

interface CustomFlagDrawerContentProps {
  flagRules?: ReportFlagRule[]
}

const CustomFlagDrawerContent: React.FC<CustomFlagDrawerContentProps> = ({ flagRules }) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()

  const isWorldfavorFlag = flagRules?.some(flagRule => flagRule.creatorOrganizationId === WF_ORGANIZATION_ID)
  const isFlagCreator = flagRules?.every(flagRule => flagRule.creatorOrganizationId === account?.organization?.id)

  const { items: questionnaireTemplates, isLoading } = useFetchCollectionWithPost<QuestionnaireTemplate>({
    key: FetchKey.RequestTemplate,
    endpoint: endpoints.requestTemplates,
    options: { enabled: isFlagCreator },
    payload: {
      filter: [
        {
          name: 'sections.questions.id',
          filters: [{ value: flagRules?.[0]?.questionId, operator: Operators.EqualTo }],
        },
      ],
      include: [],
      pagination: {
        itemsPerPage: 1,
        pageNumber: 1,
      },
    },
  })

  const template = questionnaireTemplates?.[0]
  const templateId = questionnaireTemplates?.[0]?.id

  const getContent = (flagRule: ReportFlagRule) => {
    if (isWorldfavorFlag || flagRule?.content) return null

    return (
      <>
        <Box bgcolor="grey.100" p={3} borderRadius={2}>
          <EmptyState
            title=""
            description={formatMessage(
              { id: isFlagCreator ? 'automations.noDescriptionInfoCreator' : 'automations.noDescriptionInfo' },
              { owner: flagRule?.creatorOrganization?.name },
            )}
            iconComponent={DataObjectOutlined}
          >
            {isFlagCreator && Boolean(templateId || isLoading) && (
              <LinkButton
                variant="contained"
                disabled={isLoading}
                startIcon={<AddOutlined />}
                to={generatePath(paths.sustainabilityLibrary, {
                  configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questionnaires,
                  configurationsSubPage: templateId,
                  activeTabParam: QuestionnaireConfigTabs.FlagRisks,
                  activeTabSubId: flagRule?.questionId,
                })}
                size="small"
              >
                {formatMessage({ id: 'automations.addDescription' })}
              </LinkButton>
            )}
          </EmptyState>
        </Box>
      </>
    )
  }

  return (
    <>
      {!isWorldfavorFlag && (
        <Alert severity="info" sx={{ alignItems: 'flex-start', borderRadius: 0 }}>
          {formatMessage({
            id:
              template?.questionnaireTemplateType === QuestionnaireTypeEnum.Shared
                ? isFlagCreator
                  ? 'automations.internalNoteOwnerOrganization'
                  : 'automations.internalNoteSharedOrganization'
                : 'automations.internalNoteYourOrganizationOnly',
          })}
        </Alert>
      )}

      {flagRules?.map(flagRule => (
        <Box p={2} key={flagRule.id}>
          <Box display="flex" alignItems="center">
            <Avatar size={AvatarSize.Medium} sx={{ bgcolor: 'error.main', color: 'common.black', mr: 1 }}>
              <FlagOutlined />
            </Avatar>

            <Typography variant="subtitle1">
              {flagRule?.contentTitle || flagRule?.question?.description || formatMessage({ id: 'automations.flag' })}
            </Typography>
          </Box>

          <Stack ml={5} mt={1}>
            {flagRule?.conditionType === ConditionType.Options ? (
              <Typography variant="subtitle1" color="textSecondary">
                {formatMessage(
                  { id: 'automations.flagAnswerOptions' },
                  { options: flagRule?.checkValue?.map(item => item.value).join(', ') },
                )}
              </Typography>
            ) : (
              <Typography variant="subtitle1" color="textSecondary">
                {formatMessage({ id: 'automations.flagAnswerCannotAnswer' })}
              </Typography>
            )}

            <Typography variant="body1" color="textSecondary">
              <Markdown>{flagRule?.content ?? ''}</Markdown>
            </Typography>
          </Stack>

          <Box mt={2}>{getContent(flagRule)}</Box>
        </Box>
      ))}
    </>
  )
}

export default CustomFlagDrawerContent
