import DateCell from '@app/src/components/Table/Cells/DateCell'
import ExternalVerificationStatusCell from '@app/src/components/Table/Cells/ExternalVerificationStatusCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ExternalVerificationStatus, Response } from '@app/src/types/resourceExplorer'
import { distinctBy } from '@app/src/utils/helpersTs'
import { reporting } from '@app/src/wf-constants/paths'
import { TableRow } from '@mui/material'
import qs from 'qs'
import React from 'react'
import { useHistory } from 'react-router'

interface ResponseRowProps {
  row: Response
}

export const ProviderResponseRow = ({ row: response }: ResponseRowProps): JSX.Element => {
  const history = useHistory()

  const uniqueSubscriberOrganizations = distinctBy(
    response.request.subscriptions.map(subscription => subscription.creatorOrganization),
    org => org.id,
  )

  return (
    <TableRow>
      <ExternalVerificationStatusCell
        responseStatus={response?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet}
        draftStatus={response?.draftStatus}
      />
      <RequestCell
        title={response.request?.title}
        imageUrl={response.request?.template?.image?.url}
        onClick={() =>
          history.push({
            pathname: reporting,
            search: qs.stringify({ requestId: response.request.id, responseId: response.id }),
          })
        }
      />
      <ValueCell value={response.request.periodName} />
      <ValueCell value={uniqueSubscriberOrganizations.map(org => org.name).join(', ')} />
      <DateCell value={response?.submittedAt} />
    </TableRow>
  )
}

export default ProviderResponseRow
