import { Dialog } from '@mui/material'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useUnsetReferral } from '@app/src/hooks/referral'
import React, { useState } from 'react'
import { Solution } from '@app/src/wf-constants'
import AcceptReferralWithExistingOrg from './AcceptReferralWithExistingOrg'
import ReferralInvitation from './ReferralInvitation'

interface AcceptReferralDialogProps {
  open: boolean
  referralCode: string
  closeDialog: () => void
  openClaimDialog: () => void
}

const AcceptReferralDialog: React.FC<AcceptReferralDialogProps> = ({
  open,
  referralCode,
  closeDialog,
  openClaimDialog,
}) => {
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const [invitationAccepted, setInvitationAccepted] = useState(false)
  const unsetReferral = useUnsetReferral()

  const accessibleOrganizationsWithTransparency = accessibleOrganizations.filter(org =>
    org.solution.map(s => s.solution.toLowerCase()).includes(Solution.TRANSPARENCY.toLowerCase()),
  )

  const onClose = () => {
    unsetReferral()
    closeDialog()
  }

  const handleAcceptReferral = () => {
    if (accessibleOrganizationsWithTransparency.length) {
      setInvitationAccepted(true)
      return
    }
    openClaimDialog()
    closeDialog()
  }

  const handleOnBack = () => {
    setInvitationAccepted(false)
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      {!invitationAccepted ? (
        <ReferralInvitation
          referralCode={referralCode}
          onClose={onClose}
          onAccept={handleAcceptReferral}
          onReferralExpired={onClose}
        />
      ) : (
        <AcceptReferralWithExistingOrg
          closeDialog={closeDialog}
          referralCode={referralCode}
          openClaimDialog={openClaimDialog}
          onBack={handleOnBack}
          onReferralExpired={onClose}
        />
      )}
    </Dialog>
  )
}

export default AcceptReferralDialog
