import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import FinalRiskRatingCell from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import UserCell from '@app/src/components/Table/Cells/UserCell'
import TextField from '@app/src/components/Ui/TextField'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { ProviderActivity, ProviderActivityType } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents, ProviderFinalRiskRatingValues } from '@app/src/wf-constants'
import { Box, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface FinalRiskRatingViewProps {
  providerIds: number[]
}

const FinalRiskRatingView: React.FC<FinalRiskRatingViewProps> = ({ providerIds }) => {
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()
  const { register, control } = useFormContext()
  const { trackEvent } = useAmplitude()

  const { data: activities } = useFetchResource<ProviderActivity[]>({
    key: FetchKey.ProviderActivity,
    endpoint: endpoints.providerActivityByType(providerIds[0], ProviderActivityType.CHANGE_RISK_ASSESSMENT),
    options: {
      enabled: providerIds.length === 1,
      cacheTime: 0,
    },
  })

  const options = Object.values(ProviderFinalRiskRatingValues).map(o => ({
    label: formatMessage({ id: `schemas.provider.finalRiskRatingValues.${o}` }),
    value: o,
  }))

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.RiskAssessment.Opened,
    })
  }, [])

  return (
    <Box px={2}>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box mt={2}>
          <Select
            name="newFinalRiskRating"
            control={control}
            required
            options={options}
            findSelectedValue={(value, option) => option.value === value}
            objectToOption={object => ({
              label: formatMessage({ id: `schemas.provider.finalRiskRatingValues.${object}` }),
              value: object,
            })}
            fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
          />
        </Box>
        <Box mt={2}>
          <Typography>{formatMessage({ id: 'schemas.provider.internalComment' })}</Typography>
          <TextField inputRef={register} multiline rows={5} fullWidth name="comment" />
        </Box>
      </Box>

      <Box flexGrow={1} mt={2} overflow="auto">
        {activities?.map(p => (
          <Stack mt={2} key={p.id}>
            <FinalRiskRatingCell disableCell value={p.to} />
            <Typography mt={2} variant="body1">
              {p?.comments?.map(c => `${c.text}`)}
            </Typography>
            <Box mt={2} display="flex" color={theme => theme.palette.text.secondary}>
              <UserCell disableCell user={p.creatorUser} />
              <Typography ml={1}>
                {formatMessage(
                  { id: 'general.valueWithDotSeparatorBefore' },
                  {
                    value: formatValueToDate({ value: p.createdAt, withTime: true }),
                  },
                )}
              </Typography>
            </Box>
            <Divider
              sx={{
                mt: 2,
              }}
            />
          </Stack>
        ))}
      </Box>
    </Box>
  )
}
export default FinalRiskRatingView
