import { LinkOutlined } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewAddProviderReferral from '@app/src/components/Drawer/Views/DrawerViewAddProviderReferral'
import DrawerViewFindProvider from '@app/src/components/Drawer/Views/DrawerViewFindProvider'
import useProviderType from '@app/src/hooks/useProviderType'
import React from 'react'
import { useIntl } from 'react-intl'

type Props = Omit<DrawerViewProps, 'title'>

const DrawerViewAddProviderMenu: React.FC<Props> = ({ ...props }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const providerType = useProviderType()

  return (
    <DrawerView title={formatMessage({ id: 'schemas.mappingNode.addSupplier' })} {...props}>
      <List>
        <ListItemButton onClick={() => openDrawer(<DrawerViewFindProvider providerType={providerType} />)}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'resourceCollections.create.findACompany' })}
            secondary={formatMessage(
              { id: 'resourceCollections.create.findACompanyDescription' },
              { provider: formatMessage({ id: `general.Supplier` }, { count: 1 }).toLowerCase() },
            )}
          />
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => openDrawer(<DrawerViewAddProviderReferral providerType={providerType} />)}>
          <ListItemIcon>
            <LinkOutlined />
          </ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'resourceCollections.create.invitation' })} />
        </ListItemButton>
      </List>
    </DrawerView>
  )
}
export default DrawerViewAddProviderMenu
