import * as Sentry from '@sentry/react'

// Refer to https://developers.arcgis.com/rest/geocode/api-reference/geocoding-category-filtering.htm
// for complete documentation about category filtering

const BASE_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World'

const request = async (url: string) => {
  try {
    const res = await fetch(`${BASE_URL}${url}`)
    return res.json()
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const suggest = async (query: string, maxSuggestions = 5) => {
  const url = `/GeocodeServer/suggest?f=json&text=${query}&maxSuggestions=${maxSuggestions}`
  return await request(url)
}

export const geocode = async (magicKey: string) => {
  const url = `/GeocodeServer/findAddressCandidates?f=json&outFields=*&magicKey=${magicKey}`
  return await request(url)
}

export const reverseGeocode = async (latitude: string, longitude: string, featureTypes = []) => {
  const url = `/GeocodeServer/reverseGeocode?f=json&featureTypes=${featureTypes.join(
    ',',
  )}&location=${longitude},${latitude}&outFields=*`
  return await request(url)
}
