export interface QuestionCategory {
  name: string
  translationKey: string
  customSort?: (a: string, b: string) => number
}

const ESG_AREAS_IN_ORDER = ['environment', 'social', 'governance', 'other']

const sortEsgAreasInOrder = (a: string, b: string) =>
  ESG_AREAS_IN_ORDER.indexOf(a?.toLowerCase()) - ESG_AREAS_IN_ORDER.indexOf(b?.toLowerCase())

export const BASIC_QUESTION_CATEGORIES: QuestionCategory[] = [
  {
    name: 'ESG',
    translationKey: 'schemas.question.area',
    customSort: sortEsgAreasInOrder,
  },
  {
    name: 'Legislation',
    translationKey: 'schemas.question.legislation',
  },
  {
    name: 'Topic',
    translationKey: 'schemas.question.topic',
  },
]

export const REGULAR_QUESTION_CATEGORIES: QuestionCategory[] = [
  ...BASIC_QUESTION_CATEGORIES,
  { name: 'Industry', translationKey: 'schemas.question.industry' },
  { name: 'Purpose', translationKey: 'schemas.question.purpose' },
  { name: 'Assessment', translationKey: 'schemas.question.assessment' },
  { name: 'Associated risk index', translationKey: 'schemas.question.associatedRiskIndex' },
]

export const ESRS_CATEGORIES: QuestionCategory[] = [
  { name: 'ESRS - ESRS topic', translationKey: 'schemas.question.esrsTopic' },
  { name: 'ESRS - Disclosure Requirement (DR)', translationKey: 'schemas.question.esrsDisclosureRequirement' },
  { name: 'ESRS Data point', translationKey: 'schemas.question.esrsDataPoint' },
  {
    name: 'ESRS - Application Requirement (AR)',
    translationKey: 'schemas.question.esrsApplicationRequirement',
  },
  { name: 'ESRS - Mandatory/voluntary', translationKey: 'schemas.question.esrsMandatoryVoluntary' },
]
