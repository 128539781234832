import { ExportColumn } from '@app/src/types/export'

export const BaseAssessmentsExportColumnsAccessor: ExportColumn[] = [
  {
    translationId: 'general.id',
    exportPath: 'id',
  },
  {
    translationId: 'baseAssessment.export.name',
    exportPath: 'provider.name',
  },
  {
    translationId: 'baseAssessment.export.level',
    exportPath: 'totalLevel',
  },
  {
    translationId: 'baseAssessment.export.levelScore',
    exportPath: 'totalScore',
  },
  {
    translationId: 'baseAssessment.export.generalLevel',
    exportPath: 'section1Level',
  },
  {
    translationId: 'baseAssessment.export.generalScore',
    exportPath: 'section1Score',
  },
  {
    translationId: 'baseAssessment.export.environmentalLevel',
    exportPath: 'section2Level',
  },
  {
    translationId: 'baseAssessment.export.environmentalScore',
    exportPath: 'section2Score',
  },
  {
    translationId: 'baseAssessment.export.socialLevel',
    exportPath: 'section3Level',
  },
  {
    translationId: 'baseAssessment.export.socialScore',
    exportPath: 'section3Score',
  },
  {
    translationId: 'baseAssessment.export.governanceLevel',
    exportPath: 'section4Level',
  },
  {
    translationId: 'baseAssessment.export.governanceScore',
    exportPath: 'section4Score',
  },
  {
    translationId: 'baseAssessment.export.supplyChainLevel',
    exportPath: 'section5Level',
  },
  {
    translationId: 'baseAssessment.export.supplyChainScore',
    exportPath: 'section5Score',
  },
  {
    translationId: 'baseAssessment.export.priority',
    exportPath: 'provider.providerPriorityValue',
  },
]
