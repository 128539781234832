import { TableRow } from '@mui/material'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import EmailCell from '@app/src/components/Table/Cells/EmailCell'
import UserCell from '@app/src/components/Table/Cells/UserCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { Contact } from '@app/src/types/resourceExplorer'

interface ContactRowProps {
  row: Contact
}

export const ContactRow: React.FC<ContactRowProps> = ({ row: contact }) => (
  <TableRow>
    <UserCell user={contact.user} drawer />
    <CompanyCell company={contact?.creatorOrganization} drawer />
    <ValueCell value={contact.user?.position} maxRows={1} />
    <ValueCell value={contact.user?.phoneNumber} />
    <EmailCell mail={contact.user.email} />
  </TableRow>
)

export default ContactRow
