import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderLinkStatus } from '@app/src/types/organizations'
import React from 'react'
import { ResourceCollectionSceneProps } from '../../ResourceCollectionScene'
import BaseProviderScene from './BaseProviderScene'

type ConnectedProvidersSceneProps = {
  relatedContentFilter?: FilterGroup[]
  allowedFilters: string[]
} & Omit<ResourceCollectionSceneProps, 'title'>

const ConnectedProvidersScene: React.FC<ConnectedProvidersSceneProps> = ({
  relatedContentFilter = [],
  allowedFilters,
  ...props
}) => {
  const implicitFilters = [
    {
      name: 'linkStatus',
      filters: [
        {
          operator: Operators.EqualTo,
          value: ProviderLinkStatus.Connected,
        },
      ],
    },
    ...relatedContentFilter,
  ]

  return (
    <BaseProviderScene
      implicitFilters={implicitFilters}
      allowedFilters={allowedFilters}
      isConnectedProvidersPage
      {...props}
    />
  )
}
export default ConnectedProvidersScene
