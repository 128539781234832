import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'
import { Question } from '@app/src/types/resourceExplorer'
import Markdown from '@app/src/components/Markdown'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'

type DrawerViewAccessorQuestionGuidanceProps = {
  question: Question
} & Omit<DrawerViewProps, 'title' | 'subtitle'>

const DrawerViewAccessorQuestionGuidance: React.FC<DrawerViewAccessorQuestionGuidanceProps> = ({
  question,
  ...props
}) => {
  const { closeDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const { kpiName, questionText, guidance, accessorGuidance } = question

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.question.guidance' })}
      buttons={[
        {
          label: formatMessage({ id: 'general.close' }),
          variant: 'text',
          onClick: closeDrawer,
        },
      ]}
      {...props}
    >
      <Box px={3}>
        {kpiName && (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              {formatMessage({ id: 'baseAssessment.accessorQuestionDrawer.kpiNameHeader' })}
            </Typography>
            <Typography variant="body1" paragraph>
              {kpiName}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            {formatMessage({ id: 'general.question' })}
          </Typography>
          <Typography variant="body1" paragraph>
            {questionText}
          </Typography>
        </Box>
        {guidance && (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              {formatMessage({ id: 'baseAssessment.accessorQuestionDrawer.providerGuidanceHeader' })}
            </Typography>
            <Typography variant="body1" paragraph>
              <Markdown>{guidance}</Markdown>
            </Typography>
          </Box>
        )}
        {accessorGuidance && (
          <Box pt={1}>
            <Box bgcolor="communication.light" borderRadius={2} p={3}>
              <Typography variant="subtitle1" gutterBottom>
                {formatMessage({ id: 'baseAssessment.accessorQuestionDrawer.accessorGuidanceHeader' })}
              </Typography>
              <Typography variant="body1">
                <Markdown>{accessorGuidance}</Markdown>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </DrawerView>
  )
}

export default DrawerViewAccessorQuestionGuidance
