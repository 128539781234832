import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { useAutocompleteStyles } from '@app/src/components/Form/Select/SimpleSelect'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Box, Button, Chip, Tooltip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import StatusOption from './StatusOption'

const SentMappingRequestFilters: React.FC<FiltersProps> = ({ allowedFilters, resetPage, implicitFilters }) => {
  const { formatMessage } = useIntl()
  const autocompleteClasses = useAutocompleteStyles()
  const { openDrawer } = useDrawer()

  return (
    <Box display="flex">
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Box flexGrow={1} marginRight={1}>
                <Filter name="product.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      size="small"
                      multiple
                      implicitFilters={implicitFilters}
                      facetsParam={{
                        key: [FetchKey.MappingRequestFacets, filterName],
                        endpoint: endpoints.mappingRequestsWithFacets,
                        facetsParam: [{ name: 'product.name' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.mappingRequest.productName' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Box>

              <Box>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2} mt={2}>
                            <Filter name="periodName" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  implicitFilters={implicitFilters}
                                  size="small"
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.MappingRequestFacets, filterName],
                                    endpoint: endpoints.mappingRequestsWithFacets,
                                    facetsParam: [{ name: filterName, isEnum: true }],
                                    sort: { target: 'label', order: SortOrder.DESCENDING },
                                  }}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.mappingRequest' })}>
                              <Filter name="product.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    size="small"
                                    multiple
                                    implicitFilters={implicitFilters}
                                    facetsParam={{
                                      key: [FetchKey.MappingRequestFacets, filterName],
                                      endpoint: endpoints.mappingRequestsWithFacets,
                                      facetsParam: [{ name: 'product.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.mappingRequest.productName' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="status" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.MappingRequestFacets, filterName],
                                      endpoint: endpoints.mappingRequestsWithFacets,
                                      facetsParam: [{ name: filterName }],
                                    }}
                                    renderOption={(props, option, { selected }): JSX.Element => (
                                      <StatusOption
                                        key={option.label}
                                        props={props}
                                        option={option}
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Tooltip
                                              key={option?.value?.toString() || index}
                                              title={formatMessage({ id: `reporting.statuses.${option.label}` })}
                                              classes={{ tooltipPlacementBottom: autocompleteClasses.tooltip }}
                                              arrow
                                            >
                                              <Chip
                                                label={formatMessage({ id: `reporting.statuses.${option.label}` })}
                                                size="small"
                                                {...getTagProps({ index })}
                                              />
                                            </Tooltip>
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.IncludeRequestStatus' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Box>
            </>
          )
        }}
      </Filters>
    </Box>
  )
}

export default SentMappingRequestFilters
