import { InfoOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactNode } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  infoTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

interface Props {
  title: ReactNode
  actions?: JSX.Element
  children?: ReactNode
}

const InfoBox: React.FC<Props> = ({ title, actions, children }: Props) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.infoTitleContainer}>
        <InfoOutlined fontSize="medium" className={classes.icon} />
        <Typography variant="subtitle1">{title}</Typography>
      </Box>

      {children && <Box>{children}</Box>}
      {actions && <Box mt={2}>{actions}</Box>}
    </Box>
  )
}

export default InfoBox
