import { TableRow } from '@mui/material'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProviderPublicContactHeader: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'schemas.contact.name' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.contact.position' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.contact.phone' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.contact.email' })} />
    </TableRow>
  )
}

export default ProviderPublicContactHeader
