import { SvgIcon } from '@mui/material'
import React from 'react'
import { IntervalChartProps } from '../ThreeIntervals/IntervalChartIconOne'

export const IntervalChartIconFour: React.FC<IntervalChartProps> = ({ large }) => {
  const iconSize = large ? { width: 40, height: 40 } : {}
  return (
    <SvgIcon sx={iconSize}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <circle cx="8" cy="8" r="7" fill="#E0E0E0" stroke="white" strokeWidth="2" />
        <mask
          id="mask0_5431_2358"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="12"
          height="12"
        >
          <circle cx="8" cy="8" r="6" fill="#E0E0E0" />
        </mask>
        <g mask="url(#mask0_5431_2358)">
          <circle cx="8" cy="8" r="6" fill="#E0E0E0" />
          <mask
            id="path-4-outside-1_5431_2358"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="14"
            height="14"
            fill="black"
          >
            <rect fill="white" x="1" y="1" width="14" height="14" />
            <path d="M8 2A6 6 0 1 1 1.9932 6.271L8 8V2Z" />
          </mask>
          <path d="M8 2A6 6 0 1 1 1.9932 6.271L8 8V2Z" fill="#97DAAE" />
          <path
            d="M8 2A6 6 0 1 1 1.9932 6.271L8 8V2Z"
            stroke="white"
            strokeWidth="2"
            mask="url(#path-4-outside-1_5431_2358)"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default IntervalChartIconFour
