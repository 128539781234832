import React from 'react'

export const OurWorldInDataPrivateCivilLibertiesLogoIcon = () => {
  return (
    <img
      style={{ width: 45, border: 'none' }}
      src="/img/risk-indices/private-civil-liberties.png"
      alt="Worldfavor Logo"
    />
  )
}

export default OurWorldInDataPrivateCivilLibertiesLogoIcon
