import { Language } from '@mui/icons-material'
import { Avatar, ListItem, ListItemAvatar, ListItemText, ListItemTextProps, Switch } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type ShareWithWfNetworkListItemProps = {
  onClick?: () => void
  isChecked?: boolean
  secondaryText?: ListItemTextProps['secondary']
}

const ShareWithWfNetworkListItem: React.FC<ShareWithWfNetworkListItemProps> = ({
  onClick,
  isChecked,
  secondaryText,
}) => {
  const { formatMessage } = useIntl()

  return (
    <ListItem
      secondaryAction={<Switch color="primary" checked={isChecked} disabled={!onClick} onClick={onClick} />}
      sx={{ py: 2 }}
      disablePadding
    >
      <ListItemAvatar>
        <Avatar sx={({ palette }) => ({ bgcolor: palette.info.dark, color: palette.info.contrastText })}>
          <Language />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={formatMessage({ id: 'reporting.sharing.visibleToWfNetwork' })} secondary={secondaryText} />
    </ListItem>
  )
}

export default ShareWithWfNetworkListItem
