import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { Permissions, usePermissions } from '@app/src/auth/permissions'
import LinkButton from '@app/src/components/LinkButton'
import RouteLeavingGuard from '@app/src/components/RouteLeavingGuard'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useBetterQueryParams } from '@app/src/hooks/queryParams'
import { QuestionnairesConfigTabs } from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/QuestionnairesConfig'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection'
import PublishUnpublishDialog from '@app/src/pages/TemplateBuilder/PublishUnpublishDialogProps'
import PublishedStatusChip from '@app/src/pages/TemplateBuilder/PublishedStatusChip'
import {
  QuestionnaireTemplate,
  QuestionnaireTemplateSection,
  QuestionnaireTypeEnum,
  Request,
} from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { ArrowBack, RemoveRedEyeOutlined } from '@mui/icons-material'
import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory, useParams } from 'react-router'
import EditTemplateContent from './EditTemplateContent'
import EditTemplateSettings from './EditTemplateSettings'
import TemplatePreview from './TemplatePreview'

enum ViewTypeName {
  Content = 'content',
  Settings = 'settings',
}

interface TemplateBuilderParams {
  templateId: string
}

interface TemplateBuilderQueryParams {
  preview: string
}

export interface FormData {
  id: number
  title: string
  description: string
  sections: Array<FormDataSection>
  tags: FormDataTags
  sharedTemplates: Array<{
    organizationId: number
    solutionType: string
  }>
  questionnaireTemplateType: QuestionnaireTypeEnum
}

export type FormDataTags = Array<{
  tag: string
}>

export type FormDataSection = Pick<QuestionnaireTemplateSection, 'title' | 'description'> & {
  questions: Array<FormDataItem>
  sectionId: string
}

export type FormDataItem = {
  questionId: string
  isRequired: boolean
}

export type FormDataSubmitFriendly = Omit<FormData, 'sections' | 'tags'> & {
  sections: Array<FormDataSectionSubmitFriendly>
  tags: Array<string>
}

export type FormDataSectionSubmitFriendly = Omit<FormDataSection, 'sectionId' | 'questions'> & {
  id: number
  questions: Array<FormDataItemSubmitFriendly>
}

export type FormDataItemSubmitFriendly = {
  questionId: number
  isRequired: boolean
}

const TemplateBuilder = () => {
  const { formatMessage } = useIntl()
  const { templateId } = useParams<TemplateBuilderParams>()
  const queryClient = useQueryClient()
  const history = useHistory()
  const { preview } = useBetterQueryParams<TemplateBuilderQueryParams>()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { mutateAsync: saveAsync, isLoading: isSubmitting } = useUpdateResource<Request, FormDataSubmitFriendly>()
  const [isPublishDialogOpen, openPublishDialog, closePublishDialog] = useDialogState(false)
  const formMethods = useForm<FormData>({ mode: 'onChange', shouldUnregister: false })
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true)
  const {
    formState: { isDirty, isValid },
    getValues,
    trigger,
  } = formMethods

  const [activeTab, setActiveTab] = useState(ViewTypeName.Content)
  const [showPreview, setShowPreview] = useState(preview === 'true')
  const { hasPermission } = usePermissions()

  const { data: template, isFetching } = useFetchResource<QuestionnaireTemplate>({
    endpoint: endpoints.templateById(templateId),
    key: [FetchKey.OrganizationOwnedTemplates, templateId],
    options: {
      enabled: Boolean(templateId),
    },
  })

  const actionTabs = [
    {
      type: ViewTypeName.Content,
      url: ViewTypeName.Content,
      label: formatMessage({ id: 'templateBuilder.content' }),
    },
    {
      type: ViewTypeName.Settings,
      url: ViewTypeName.Settings,
      label: formatMessage({ id: 'templateBuilder.settings' }),
    },
  ]

  const handleTabChange = (tab: string) => {
    setActiveTab(actionTabs.find(t => t.type === tab)?.type ?? ViewTypeName.Content)
  }

  //We have to do this because of a limitation in the current react-hook-form when using UseFieldArray.
  //The id property is used internally by react-hook-form and we need rename it to avoid conflicts.
  const renameIdPropsToSectionIdAndItemId = (template: QuestionnaireTemplate) => {
    const mappedSections =
      template?.sections?.map(section => {
        const mappedItems =
          section?.questions?.map(item => {
            const mappedItem: FormDataItem = {
              questionId: item.id.toString(),
              isRequired: item.isRequired,
            }
            return mappedItem
          }) ?? []
        const mappedSection: FormDataSection = {
          sectionId: section.id.toString(),
          title: section.title,
          description: section.description,
          questions: mappedItems,
        }
        return mappedSection
      }) ?? []
    return { ...template, sections: mappedSections }
  }

  const mapTagsToRequestTags = (template: QuestionnaireTemplate) => ({ tags: template.tags.map(tag => ({ tag: tag })) })

  const mapSharedTemplates = (template: QuestionnaireTemplate) => ({
    sharedTemplates:
      template.sharedTemplates?.map(org => ({
        organizationId: org.organizationId,
        solutionType: org.solutionType,
      })) ?? [],
  })

  const mapTagsToSubmitFriendly = (template: FormData): Array<string> => {
    return template?.tags?.map(item => item.tag) ?? []
  }

  const renameIdPropsToId = (template: FormData): FormDataSubmitFriendly['sections'] => {
    return (
      template?.sections?.map(section => {
        const { sectionId, ...mappedSection } = {
          ...section,
          id: Number.parseInt(section.sectionId),
          questions: section?.questions.map(q => ({
            questionId: Number.parseInt(q.questionId),
            isRequired: q.isRequired,
          })),
        }
        return mappedSection
      }) ?? []
    )
  }

  const mapValuesToSubmitObject = (values: FormData): FormDataSubmitFriendly => {
    const mappedSections = renameIdPropsToId(values)
    const mappedTags = mapTagsToSubmitFriendly(values)

    return { ...values, sections: mappedSections, tags: mappedTags }
  }

  const invalidateTemplate = async () => {
    await queryClient.invalidateQueries(FetchKey.OrganizationOwnedTemplates)
    await queryClient.invalidateQueries([FetchKey.OrganizationOwnedTemplates, templateId])
  }

  const onSubmit = async (values: FormData, beforePublish = false) => {
    if (template) {
      values.id = template.id
    }
    setShouldBlockNavigation(false)
    const mappedValues = mapValuesToSubmitObject(values)

    await saveAsync(
      {
        url: endpoints.saveTemplate,
        body: mappedValues,
      },
      {
        onSuccess: async data => {
          if (!beforePublish) {
            await invalidateTemplate()
          }

          showSnackbar({
            message: formatMessage(
              { id: 'templateBuilder.success' },
              { b: (chunks: React.ReactNode) => <b>{chunks}</b>, templateName: values.title },
            ),
            severity: 'success',
          })
          if (!templateId) {
            history.push(
              generatePath(paths.editTemplate, {
                templateId: data?.id,
              }),
            )
          }
          if (beforePublish) {
            openPublishDialog()
          }
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const handlePreviewBack = () => {
    if (preview) {
      history.goBack()
    } else {
      setShowPreview(false)
    }
  }

  const saveTemplateBeforePublish = () => {
    return onSubmit(getValues(), true)
  }

  useEffect(() => {
    if (!isFetching && template) {
      const mappedSectionsTemplate = renameIdPropsToSectionIdAndItemId(template)
      const mappedTagsTemplate = mapTagsToRequestTags(template)
      const mappedSharedTemplates = mapSharedTemplates(template)

      formMethods.reset({ ...mappedSectionsTemplate, ...mappedTagsTemplate, ...mappedSharedTemplates })
    }
  }, [template, isFetching])

  if (isFetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <Helmet>
        <title>{formatMessage({ id: 'templateBuilder.title' })}</title>
      </Helmet>

      <Box mt={2} mx={5}>
        <LinkButton
          startIcon={<ArrowBack color="primary" />}
          to={generatePath(paths.sustainabilityLibrary, {
            configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questionnaires,
            configurationsSubPage: QuestionnairesConfigTabs.Standard,
            activeTabParam: QuestionnairesConfigTabs.Custom,
          })}
        >
          {formatMessage({ id: 'general.back' })}
        </LinkButton>
      </Box>

      <Box component="form" onSubmit={formMethods.handleSubmit(values => onSubmit(values))} flexGrow={1}>
        <ResourceCollectionScene
          title={
            <Box display="flex" alignItems="center" gap={1}>
              {template?.title || formatMessage({ id: 'templateBuilder.newQuestionnaire' })}
              <PublishedStatusChip isPublished={Boolean(template?.isPublished)} />
            </Box>
          }
          actionButtons={[
            {
              label: formatMessage({ id: 'templateBuilder.preview' }),
              startIcon: <RemoveRedEyeOutlined />,
              onClick: () => setShowPreview(true),
              disabled: isSubmitting,
            },
            {
              label: formatMessage({
                id: template?.isPublished ? 'templateBuilder.unpublish' : 'templateBuilder.publish',
              }),
              onClick: async () => {
                await trigger()
                if (isDirty) {
                  await formMethods.handleSubmit(values => onSubmit(values, true))()
                } else {
                  if (isValid) openPublishDialog()
                }
              },
              variant: 'outlined',
              loading: isSubmitting,
            },
            {
              label: formatMessage({ id: 'templateBuilder.save' }),
              variant: 'contained',
              type: 'submit',
              loading: isSubmitting,
            },
          ]}
          tabs={
            hasPermission(Permissions.WF_EMAIL_ACCESS)
              ? { actionTabs, activeTabParam: activeTab, onTabChange: handleTabChange }
              : undefined
          }
          enableScroll
        >
          {activeTab === ViewTypeName.Content && <EditTemplateContent template={template} />}
          {activeTab === ViewTypeName.Settings && <EditTemplateSettings />}
        </ResourceCollectionScene>

        <TemplatePreview
          getTemplate={() => mapValuesToSubmitObject(getValues())}
          showPreview={showPreview}
          onBack={handlePreviewBack}
        />

        <PublishUnpublishDialog
          template={template}
          isDirty={isDirty}
          isPublishDialogOpen={isPublishDialogOpen}
          closePublishDialog={closePublishDialog}
          saveTemplate={saveTemplateBeforePublish}
        />
      </Box>
      <RouteLeavingGuard
        when={isDirty && shouldBlockNavigation}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => isDirty && shouldBlockNavigation}
        ignoreSearchParamChanges
      />
    </FormProvider>
  )
}

export default TemplateBuilder
