import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewInvitation from '@app/src/components/Drawer/Views/DrawerViewInvitation'
import DrawerViewUser, { DrawerViewUserProps } from '@app/src/components/Drawer/Views/DrawerViewUser'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { Invitation, User } from '@app/src/types/resourceExplorer'
import { ResourceTypes } from '@app/src/wf-constants'
import { ButtonBase, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import TextTruncate, { TextTruncateProps } from 'react-text-truncate'
import ParentCellOrFragment from './ParentCellOrFragment'

export type UserCellProps = {
  user?: User | Invitation
  maxRows?: TextTruncateProps['line']
  drawer?: boolean
  disableCell?: boolean
  isDisabled?: boolean
} & Omit<DrawerViewUserProps, 'user'>

const isUserType = (user: UserCellProps['user']): user is User => user?.type.toLowerCase() === ResourceTypes.User

const UserCell: React.FC<UserCellProps> = ({ user, maxRows, drawer, disableCell, isDisabled, ...props }) => {
  const { openDrawer } = useDrawer()
  const [truncated, setTruncated] = useState(false)

  if (!user) {
    return (
      <ParentCellOrFragment disableCell={disableCell}>
        <Typography variant="body1">-</Typography>
      </ParentCellOrFragment>
    )
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip title={truncated ? user.name : undefined}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          component={drawer ? ButtonBase : 'span'}
          onClick={
            drawer
              ? () =>
                  openDrawer(
                    isUserType(user) ? (
                      <DrawerViewUser user={user} {...props} />
                    ) : (
                      <DrawerViewInvitation invitation={user} />
                    ),
                  )
              : undefined
          }
          width="100%"
        >
          <PersonAvatar name={user.name} size={AvatarSize.Small} disabled={isDisabled} />
          <Typography
            variant="body1"
            align="left"
            sx={{ '&:hover': { textDecoration: drawer ? 'underline' : 'none' } }}
            flexGrow={1}
            component="span"
          >
            <TextTruncate
              line={maxRows}
              text={user.name}
              onTruncated={() => setTruncated(true)}
              data-testid="user-name"
            />
          </Typography>
        </Stack>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default UserCell
