import { createContext, useContext } from 'react'
import { OrganizationRiskSetting, Risk, RiskType, SustainabilityArea } from '@app/src/types/resourceExplorer'

export type RiskSettingsContextProps = {
  riskSettings: OrganizationRiskSetting[]
  activeRiskSettings: RiskType[]
  isLoadingRiskSettings: boolean
  isErrorRiskSettings: boolean
  getCountryRisksForSustainabilityArea: (
    countryRisks: Risk[] | undefined,
    sustainabilityArea: SustainabilityArea,
  ) => Risk[]
}

const RiskSettingsContext = createContext<RiskSettingsContextProps>({
  riskSettings: [],
  activeRiskSettings: [],
  isLoadingRiskSettings: true,
  isErrorRiskSettings: false,
  getCountryRisksForSustainabilityArea: () => [],
})

export const useRiskSettings = (): RiskSettingsContextProps => {
  return useContext(RiskSettingsContext)
}

export default RiskSettingsContext
