import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { Auth0User } from '@app/src/pages/Configurations/ConfigurationsPages/Users/UsersConfig'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { Logos } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { generatePath } from 'react-router'

interface UserRowProps {
  row: Auth0User
}

export const UserRow = ({ row: user }: UserRowProps): JSX.Element => {
  return (
    <TableRow>
      <TableCell>
        {user.email.endsWith('@worldfavor.com') && (
          <PersonAvatar
            name={user.userMetadata.givenName}
            imageUrl={Logos.WorldfavorLogoSquareFilledBlack}
            size={AvatarSize.Small}
          />
        )}
      </TableCell>
      <ButtonCell
        to={generatePath(paths.configurations, {
          configurationsPage: CONFIGURATION_PAGE_IDS.Users,
          configurationsSubPage: user.appMetadata.id,
        })}
        label={`${user.userMetadata.givenName} ${user?.userMetadata.familyName}`}
      />
      <ValueCell value={user.email} />
      <ValueCell value={user.appMetadata.id} />
      <ValueCell value={user.auth0Id} />
    </TableRow>
  )
}

export default UserRow
