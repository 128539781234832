import { Box, Stack, Tooltip, Typography } from '@mui/material'
import LevelBar from '@app/src/components/LevelBar'
import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import React from 'react'
import { useIntl } from 'react-intl'
import Assessment from '@app/src/types/assessment'

interface LevelProps {
  value: Assessment['totalLevel']
}

interface LevelCellProps extends LevelProps {
  disableCell?: boolean
  disabled?: boolean
}

export const Level: React.FC<LevelProps> = ({ value }) => {
  const { formatMessage } = useIntl()
  return (
    <Stack alignItems="flex-start" whiteSpace="nowrap">
      <Typography variant="subtitle1">{formatMessage({ id: 'baseAssessment.totalLevel' }, { value })}</Typography>
      <LevelBar value={value} />
    </Stack>
  )
}

const LevelCell: React.FC<LevelCellProps> = ({ disableCell, disabled, value }) => {
  const { formatMessage } = useIntl()

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 400,
            },
          },
        }}
        title={
          <Box px={1}>
            <Typography variant="overline" noWrap paragraph>
              {formatMessage({ id: 'baseAssessment.levelN' }, { value })}
              {formatMessage(
                { id: 'general.valueWithDotSeparatorBefore' },
                {
                  value: formatMessage({ id: `baseAssessment.levelDefinitions.level${value}Title` }),
                },
              )}
            </Typography>

            <Stack direction="row" alignItems="center" justifyContent="space-between" borderRadius={2}>
              <Typography variant="body2" lineHeight="2.5rem">
                {formatMessage({ id: `baseAssessment.levelDefinitions.level${value}HelperText` })}
              </Typography>
            </Stack>
          </Box>
        }
      >
        <Box sx={({ palette }) => ({ opacity: disabled ? palette.action.disabledOpacity : 1 })} display="inline flex">
          <Level value={value} />
        </Box>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default LevelCell
