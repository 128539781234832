import { TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const AuditLogHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.auditLog.createdAt' })}
        sorting={{
          name: 'createdAt',
          toggleSorting,
          activeSorting,
        }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.auditLog.serviceType' })}
        sorting={{
          name: 'serviceType',
          toggleSorting,
          activeSorting,
        }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.auditLog.sourceObjectType' })}
        sorting={{
          name: 'sourceObjectType',
          toggleSorting,
          activeSorting,
        }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.auditLog.sourceEventType' })}
        sorting={{
          name: 'sourceEventType',
          toggleSorting,
          activeSorting,
        }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.auditLog.creatorUser' })}
        sorting={{
          name: 'creatorUser.name',
          toggleSorting,
          activeSorting,
        }}
      />
      <TableHeaderCell minWidth={TableCellWidth.EMPTY} label="" />
    </TableRow>
  )
}

export default AuditLogHeader
