import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewReferralContact from '@app/src/components/Drawer/Views/DrawerViewReferralContact'
import { EmailPurpose, useConnectProvidersModal } from '@app/src/context/ConnectProvidersModalContext'
import { Provider, ProviderLinkStatus } from '@app/src/types/organizations'
import React from 'react'
import { useIntl } from 'react-intl'
import ButtonCell from './ButtonCell'
import ChipCell, { ChipCellProps } from './ChipCell'

export type ProviderConnectedCellProps = {
  provider: Provider
  disableCell?: boolean
} & ChipCellProps

const ProviderConnectedCell: React.FC<ProviderConnectedCellProps> = ({ provider, disableCell, ...props }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { handleOpenConnectDialog } = useConnectProvidersModal()

  if (provider.linkStatus === ProviderLinkStatus.Connected) {
    return (
      <ChipCell
        label={formatMessage({ id: 'referral.inviteSent.connected' })}
        color="success"
        variant="filled"
        size="small"
        {...props}
      />
    )
  }

  if (provider.linkStatus === ProviderLinkStatus.Invited) {
    return (
      <ChipCell
        label={formatMessage({ id: 'referral.inviteSent.title' })}
        variant="filled"
        size="small"
        onClick={() => openDrawer(<DrawerViewReferralContact provider={provider} />)}
        {...props}
      />
    )
  }

  return (
    <ButtonCell
      size="small"
      label={formatMessage({ id: 'general.connect' })}
      variant="outlined"
      onClick={() => handleOpenConnectDialog([provider], EmailPurpose.InitialInvitation)}
      disableCell={disableCell}
    />
  )
}

export default ProviderConnectedCell
