import _IntervalChartIconFive from '@app/src/pages/Assessments/icons/FiveIntervals/IntervalChartIconFive'
import _IntervalChartIconFour from '@app/src/pages/Assessments/icons/FiveIntervals/IntervalChartIconFour'
import _IntervalChartIconOne from '@app/src/pages/Assessments/icons/FiveIntervals/IntervalChartIconOne'
import _IntervalChartIconThree from '@app/src/pages/Assessments/icons/FiveIntervals/IntervalChartIconThree'
import _IntervalChartIconTwo from '@app/src/pages/Assessments/icons/FiveIntervals/IntervalChartIconTwo'
import { FC } from 'react'

interface IntervalChartIconProps {
  large?: boolean
}

export const FiveIntervalChartIconOne: FC<IntervalChartIconProps> = _IntervalChartIconOne
export const FiveIntervalChartIconTwo: FC<IntervalChartIconProps> = _IntervalChartIconTwo
export const FiveIntervalChartIconThree: FC<IntervalChartIconProps> = _IntervalChartIconThree
export const FiveIntervalChartIconFour: FC<IntervalChartIconProps> = _IntervalChartIconFour
export const FiveIntervalChartIconFive: FC<IntervalChartIconProps> = _IntervalChartIconFive
