import { formatNumber } from '@app/src/utils/helpersTs'
import { useTheme } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { TextCommonOption } from 'echarts/types/src/util/types'
import React from 'react'
import { useIntl } from 'react-intl'

interface ChartEventCallbacks<T> {
  click: ({ data }: { data: T }) => void
  [key: string]: ({ data }: { data: T }) => void
}

type RichXAxisData = { value: string; companiesAnswered: number }

interface MarkLineDataItem {
  yAxis?: number
  label: {
    show: boolean
    position: string
    formatter: string
    color: string
    backgroundColor: string
    borderRadius: number
    padding: number[]
    margin: number[]
  }
  lineStyle: {
    width: number
    color: string
  }
}

type MarkLineData = MarkLineDataItem[]

interface LineChartProps<T> {
  data: T[]
  xAxisData: string[] | RichXAxisData[]
  markLineData?: MarkLineData
  events?: ChartEventCallbacks<T>
  seriesLabel: unknown
  showMarkLine: boolean
  grid?: {
    left?: string
    right?: string
    top?: string
    bottom?: string
    containLabel?: boolean
  }
  symbol?: string | null
  isProviderProgressView?: boolean
}

export function LineChart<T>({
  data,
  xAxisData,
  markLineData,
  events,
  showMarkLine,
  grid,
  symbol,
  isProviderProgressView,
}: LineChartProps<T>) {
  const { palette, typography } = useTheme()
  const { formatMessage } = useIntl()
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)

  const isRichXAxisData = (data: string | RichXAxisData): data is RichXAxisData => typeof data !== 'string'

  const filteredXAxisData = (xAxisData as Array<string | RichXAxisData>).filter((item: string | RichXAxisData) => {
    if (isRichXAxisData(item)) {
      return item.companiesAnswered !== 0
    }
    return true
  })

  const filteredData = data.filter((_, index) => {
    const item = xAxisData[index]

    if (isRichXAxisData(item)) {
      return item.companiesAnswered !== 0
    }
    return true
  })

  const filteredMarkLineData = markLineData?.filter((_, index) => {
    const item = xAxisData[index]
    if (isRichXAxisData(item)) {
      return item.companiesAnswered !== 0
    }
    return true
  })

  const lineOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid,
    xAxis: {
      type: 'category',
      data: filteredXAxisData,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        position: 'bottom',
        show: true,
        formatter: (value: string, index: number) => {
          const companiesAnsweredCount = (xAxisData[index] as RichXAxisData)?.companiesAnswered
          return `{periodFormat|${value}}${
            Boolean(companiesAnsweredCount) && !isProviderProgressView
              ? `\n\n {amountFormat|${formatMessage(
                  { id: 'statistics.reportOverview.responseCompanies' },
                  { count: (xAxisData[index] as RichXAxisData).companiesAnswered },
                )}}`
              : ''
          }`
        },
        rich: {
          periodFormat: {
            fontFamily: typography.fontFamily,
            fontWeight: typography.subtitle2.fontWeight,
            fontSize: typography.subtitle2.fontSize,
          } as TextCommonOption,
          amountFormat: {
            fontFamily: typography.fontFamily,
            fontWeight: typography.caption.fontWeight,
            fontSize: typography.caption.fontSize,
            color: '#6E6E71',
          } as TextCommonOption,
        },
      },
    },
    yAxis: {
      name: symbol,
      nameLocation: 'end',
      nameTextStyle: {
        color: '#333',
        fontSize: 14,
        fontWeight: 'bold',
        padding: [0, 20, 10, -15],
        align: 'left',
      },
      splitLine: {
        color: palette.visualization[2],
      },
      splitNumber: 2,
      type: 'value',
      axisLabel: {
        formatter: (value: number) => {
          const formattedNumber = formatNumber(value) ?? ''
          if (!symbol) return formattedNumber
          return `${formattedNumber} ${symbol}`
        },
      },
    },
    series: [
      {
        data: filteredData,
        hoverAnimation: false,
        type: 'line',
        cursor: 'default',
        smooth: true,
        symbolSize: 8,
        areaStyle: {
          color: palette.visualization[10],
          emphasis: {
            color: palette.visualization[10],
          },
        },
        lineStyle: {
          width: 4,
        },
        symbol: 'emptyCircle',
        itemStyle: {
          color: palette.visualization[2],
          borderRadius: 4,
          borderWidth: 5,
          cursor: 'default',
        },
        backgroundStyle: {
          color: palette.grey[200],
        },
        markLine: showMarkLine
          ? {
              silent: true,
              symbol: 'none',
              data: filteredMarkLineData,
            }
          : undefined,
      },
    ],
  }

  return (
    <ReactEChartsCore
      option={lineOptions}
      echarts={echarts}
      onEvents={events}
      style={{ width: '100%' }}
      ref={eChartsRef}
    />
  )
}

export default LineChart
