import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import ActionButtons, { ActionButton } from '@app/src/components/ActionButtons'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import useOrganizationSettings from '@app/src/hooks/useOrganizationSettings'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import ConfigurationSkeleton from '@app/src/pages/Configurations/ConfigurationsSkeleton'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Unit } from '@app/src/types/resourceExplorer'
import { OrganizationSettings } from '@app/src/wf-constants'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

type SettingsChanged = Pick<OrganizationSettings, 'currencyUnitId'>

const UNITS_TO_SHOW = ['USD', 'GBP', 'SEK', 'EUR']
const UNIT_CACHE_TIME = 12 * 60 * 60 * 1000

const OrganizationPreferencesConfig: React.FC = () => {
  const { isFetching, get, set, isSaving } = useOrganizationSettings()

  const formMethods = useForm<SettingsChanged>()
  const {
    control,
    handleSubmit,
    errors,
    reset,
    formState: { isDirty },
  } = formMethods
  const { formatMessage } = useIntl()
  const [isEditing, setIsEditing] = useState(false)
  const confirm = useConfirm()

  const { items: units, isLoading: isLoadingUnits } = useFetchCollectionWithPost<Unit>({
    key: FetchKey.Unit,
    endpoint: endpoints.units,
    payload: {
      filter: [
        {
          name: 'symbol',
          filters: [{ value: UNITS_TO_SHOW, operator: Operators.In }],
        },
      ],
      include: [],
    },
    options: { refetchOnMount: false, cacheTime: UNIT_CACHE_TIME, staleTime: UNIT_CACHE_TIME },
  })

  const currencyUnitId = get('currencyUnitId') as number

  const cancelEdit = () => {
    if (isDirty) {
      confirm({
        hideCancelButton: false,
        confirmationText: formatMessage({ id: 'general.confirm' }),
        confirmationButtonProps: { variant: 'text' },
        cancellationButtonProps: { variant: 'text' },
        title: formatMessage({ id: 'general.youHaveUnsavedChanges' }),
        content: formatMessage({ id: 'general.exitWithoutSaving' }),
      })
        .then(() => {
          setIsEditing(false)
        })
        .catch(() => {
          // absorb error and stay on page
        })
    } else {
      setIsEditing(false)
    }
  }

  const onSubmit = async (values: SettingsChanged) => {
    await set('currencyUnitId', values.currencyUnitId)
    setIsEditing(false)
  }

  const getButtons = (): ActionButton[] => {
    if (isEditing) {
      return [
        {
          label: formatMessage({ id: 'general.cancel' }),
          variant: 'text',
          size: 'large',
          onClick: cancelEdit,
          loading: isSaving,
        },
        {
          label: formatMessage({ id: 'general.save' }),
          variant: 'contained',
          size: 'large',
          onClick: handleSubmit(onSubmit),
          loading: isSaving,
        },
      ]
    }

    return [
      {
        label: formatMessage({ id: 'general.edit' }),
        variant: 'contained',
        size: 'large',
        onClick: () => {
          setIsEditing(true)
        },
      },
    ]
  }

  useEffect(() => {
    if (!isFetching && !isLoadingUnits) {
      reset({ currencyUnitId: currencyUnitId })
    }
  }, [isFetching, isLoadingUnits])

  if (isFetching || isLoadingUnits) return <ConfigurationSkeleton />

  return (
    <ConfigurationCollection actionButtons={getButtons()}>
      <FormProvider {...formMethods}>
        <form>
          <Box bgcolor="common.white" mr={4} p={3} borderRadius={1}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Stack spacing={3}>
                  <Stack>
                    {!isEditing && <Typography variant="body2">{formatMessage({ id: 'general.currency' })}</Typography>}
                    {isEditing ? (
                      <Select<number>
                        fullWidth
                        hoveringLabel
                        name="currencyUnitId"
                        options={units.map(u => ({
                          value: u.id,
                          label: u.symbol ?? u.name,
                        }))}
                        error={errors?.currencyUnitId?.message}
                        fieldLabel={formatMessage({ id: 'general.currency' })}
                        control={control}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {units.find(u => u.id === currencyUnitId)?.symbol}
                      </Typography>
                    )}
                  </Stack>
                  {isEditing && (
                    <Stack flexDirection="row" justifyContent="flex-end">
                      <ActionButtons buttons={getButtons()} />
                    </Stack>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </ConfigurationCollection>
  )
}

export default OrganizationPreferencesConfig
