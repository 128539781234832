import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewOrganizationForm from '@app/src/components/Drawer/Views/DrawerViewOrganizationForm'
import { Provider } from '@app/src/types/organizations'
import { LabelOutlined } from '@mui/icons-material'
import { Box, Button, Divider, Stack } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import OrganizationInformation from './OrganizationInformation'
import ProviderCategories from './ProviderCategories'
import ProviderContacts from './ProviderContacts'
import ProviderSpends from './ProviderSpends'

interface ProviderDetailsProps {
  provider: Provider
  openEditDrawer?: () => void
}

const ProviderDetails: React.FC<ProviderDetailsProps> = ({ provider }): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { providerSpend } = useFlags()

  return (
    <Stack flexGrow={1}>
      <Box textAlign="center" mb={2}>
        <Button
          startIcon={<LabelOutlined color="primary" />}
          onClick={() => openDrawer(<DrawerViewOrganizationForm provider={provider} />)}
          variant="outlined"
        >
          {formatMessage({ id: 'general.categorize' })}
        </Button>
      </Box>
      <Box height={100} flexGrow={1} overflow="scroll" px={3} pb={3}>
        <OrganizationInformation provider={provider} />
        <Divider sx={{ my: 3 }} />
        <ProviderContacts provider={provider} />
        <Divider sx={{ my: 3 }} />
        <ProviderCategories provider={provider} />
        {providerSpend && (
          <>
            <Divider sx={{ my: 3 }} />
            <ProviderSpends provider={provider} />
          </>
        )}
      </Box>
    </Stack>
  )
}

export default ProviderDetails
