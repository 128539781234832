import { EmailOutlined, GroupAddOutlined, PendingOutlined, PersonOffOutlined } from '@mui/icons-material'
import { List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import Avatar, { AvatarProps } from '@app/src/components/Ui/Avatar'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import DrawerView, { DrawerViewProps } from '../../DrawerView'

type DrawerViewAddProviderContactInformationProps = Omit<DrawerViewProps, 'title'>

type InformationListItemProps = {
  title: string
  icon: ReactNode
  description: string
  avatarSx?: AvatarProps['sx']
}

const InformationListItem: React.FC<InformationListItemProps> = ({ title, icon, description, avatarSx }) => {
  return (
    <ListItem sx={{ alignItems: 'flex-start' }}>
      <ListItemAvatar>
        <Avatar color="primary" sx={avatarSx || { bgcolor: 'grey.200' }}>
          {icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="subtitle1">{title}</Typography>}
        secondary={description}
        sx={{ m: 0 }}
      />
    </ListItem>
  )
}

const DrawerViewAddProviderContactInformation: React.FC<DrawerViewAddProviderContactInformationProps> = ({
  ...props
}) => {
  const { formatMessage } = useIntl()

  return (
    <DrawerView title={formatMessage({ id: 'schemas.addProviderContact.information.addContactInfo' })} {...props}>
      <Stack p={2} spacing={3}>
        <List>
          <InformationListItem
            title={formatMessage({ id: 'schemas.addProviderContact.information.addContact.title' })}
            description={formatMessage({ id: 'schemas.addProviderContact.information.addContact.description' })}
            icon={<GroupAddOutlined color="primary" />}
          />
          <InformationListItem
            title={formatMessage({ id: 'schemas.addProviderContact.information.pendingReview.title' })}
            description={formatMessage({ id: 'schemas.addProviderContact.information.pendingReview.description' })}
            icon={<PendingOutlined color="primary" />}
          />

          <InformationListItem
            title={formatMessage({ id: 'schemas.addProviderContact.information.invitedStatus.title' })}
            description={formatMessage({ id: 'schemas.addProviderContact.information.invitedStatus.description' })}
            icon={<EmailOutlined color="primary" />}
          />
          <InformationListItem
            title={formatMessage({ id: 'schemas.addProviderContact.information.declinedStatus.title' })}
            description={formatMessage({ id: 'schemas.addProviderContact.information.declinedStatus.description' })}
            icon={<PersonOffOutlined color="primary" />}
            avatarSx={{ bgcolor: 'error.light' }}
          />
        </List>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddProviderContactInformation
