import TextField from '@app/src/components/Ui/TextField'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import EditQuestionInTemplate from '@app/src/pages/TemplateBuilder/EditQuestionInTemplate'
import { MoveAndDeleteControls } from '@app/src/pages/TemplateBuilder/MoveAndDeleteControls'
import SelectQuestionsModal from '@app/src/pages/TemplateBuilder/SelectQuestionsModal'
import { QuestionnaireTemplateSection } from '@app/src/types/resourceExplorer'
import { Add } from '@mui/icons-material'
import { Box, Button, Chip, Divider, Grid } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useMemo } from 'react'
import { get, useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { FormData, FormDataItem, FormDataSection } from './TemplateBuilder'

type EditSectionProps = {
  section: Partial<FormDataSection>
  templateSection?: QuestionnaireTemplateSection
  index: number
  removeSection: () => void
  moveUp: () => void
  moveDown: () => void
  sectionCount: number
  templateTitle?: string
}

const EditSection = ({
  section,
  templateSection,
  index,
  removeSection,
  moveUp,
  moveDown,
  sectionCount,
  templateTitle,
}: EditSectionProps): JSX.Element => {
  const { register, control, getValues, errors } = useFormContext<FormData>()
  const { formatMessage } = useIntl()
  const [isSelectQuestionsDialogOpen, openSelectQuestionsDialog, closeSelectQuestionsDialog] = useDialogState(false)
  const confirm = useConfirm()

  const startOfName = `sections[${index}].`

  const {
    fields,
    append,
    remove: removeItem,
    move,
  } = useFieldArray<FormDataItem>({
    control,
    name: `${startOfName}questions`,
  })

  const questionDialogSelectionConfirmed = (addedQuestionIds: Array<number>) => {
    addedQuestionIds.forEach(questionId => {
      append({ questionId: questionId.toString(), isRequired: true })
    })
    closeSelectQuestionsDialog()
  }

  const questionIdsAlreadyInQuestionnaire = useMemo(() => {
    if (!isSelectQuestionsDialogOpen) return []
    const totalData = getValues()
    return totalData.sections.flatMap(s => s.questions).map(q => parseInt(q.questionId.toString()))
  }, [isSelectQuestionsDialogOpen])

  const confirmRemoveSection = () => {
    if (fields.length === 0) {
      removeSection()
      return
    }

    confirm({
      description: formatMessage({ id: 'templateBuilder.fields.confirmDeleteSectionWithQuestions' }),
      confirmationText: formatMessage(
        { id: 'templateBuilder.fields.controls.delete' },
        { type: formatMessage({ id: 'templateBuilder.fields.controls.type.section' }) },
      ),
    }).then(removeSection)
  }

  return (
    <>
      <SelectQuestionsModal
        isOpen={isSelectQuestionsDialogOpen}
        confirmDialogSelection={questionDialogSelectionConfirmed}
        cancelDialogSelection={closeSelectQuestionsDialog}
        questionIdsAlreadyInQuestionnaire={questionIdsAlreadyInQuestionnaire}
        templateTitle={templateTitle}
      />
      <Box my={2} py={5} px={6} bgcolor="white" borderRadius={2}>
        <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
          <Chip
            variant="outlined"
            label={formatMessage(
              { id: 'templateBuilder.sectionNumber' },
              { number: index + 1, totalSections: sectionCount },
            )}
          />
          <MoveAndDeleteControls
            onDelete={confirmRemoveSection}
            moveUp={moveUp}
            moveDown={moveDown}
            index={index}
            totalCount={sectionCount}
            typeName={formatMessage({ id: 'templateBuilder.fields.controls.type.section' })}
          />
        </Box>
        <Divider sx={{ marginBottom: 4 }} />

        <input
          type="hidden"
          name={`${startOfName}sectionId`}
          defaultValue={section?.sectionId ?? ''}
          ref={register()}
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              placeholder={formatMessage({ id: 'textFieldPlaceholders.sectionTitle' })}
              hoveringLabel
              fullWidth
              name={`${startOfName}title`}
              defaultValue={section?.title}
              label="Title"
              size="small"
              error={Boolean(get(errors, `${startOfName}title`))}
              helperText={get(errors, `${startOfName}title`)?.message}
              inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
              inputProps={{
                maxLength: 500,
              }}
              InputLabelProps={{ required: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              hoveringLabel
              placeholder={formatMessage({ id: 'textFieldPlaceholders.sectionDescription' })}
              fullWidth
              name={`${startOfName}description`}
              defaultValue={section?.description}
              label="Description"
              size="small"
              inputRef={register()}
              multiline
              minRows={4}
              maxRows={8}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>

        {fields?.map((item, requestItemIndex) => (
          <EditQuestionInTemplate
            key={item.id}
            item={item}
            questionFromTemplate={templateSection?.questions?.find(
              q => q.id === Number.parseInt(item.questionId ?? '0'),
            )}
            requestItemIndex={requestItemIndex}
            sectionIndex={index}
            removeItem={() => removeItem(requestItemIndex)}
            moveUp={() => move(requestItemIndex, requestItemIndex - 1)}
            moveDown={() => move(requestItemIndex, requestItemIndex + 1)}
            questionCount={fields.length}
          />
        ))}

        <Box textAlign="center" mt={2}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => openSelectQuestionsDialog()}
            data-testid="add-question-button"
          >
            {formatMessage({ id: 'templateBuilder.addQuestion' })}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default EditSection
