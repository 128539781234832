import DatePicker from '@app/src/components/Form/DatePicker'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Box } from '@mui/material'
import React from 'react'

interface RangeFilterProps {
  uniqueIdFrom: string
  uniqueIdTo: string
  name: string
  fieldLabelFrom: string
  fieldLabelTo: string
}

const RangeFilter: React.FC<RangeFilterProps> = ({ uniqueIdFrom, uniqueIdTo, name, fieldLabelFrom, fieldLabelTo }) => (
  <Box display="flex" justifyContent="space-between">
    <Box width="50%" mr={1}>
      <Filter operator={Operators.GreaterThanOrEqual} uniqueId={uniqueIdFrom} name={name}>
        {({ value, onChange }) => (
          <DatePicker
            value={[value].flat()?.[0]}
            onChange={onChange}
            label={fieldLabelFrom}
            slotProps={{ textField: { size: 'small' } }}
            sx={{ width: '100%' }}
          />
        )}
      </Filter>
    </Box>
    <Box width="50%" ml={1}>
      <Filter operator={Operators.LowerThanOrEqual} uniqueId={uniqueIdTo} name={name}>
        {({ value, onChange }) => (
          <DatePicker
            value={[value].flat()?.[0]}
            onChange={onChange}
            label={fieldLabelTo}
            slotProps={{ textField: { size: 'small' } }}
            sx={{ width: '100%' }}
          />
        )}
      </Filter>
    </Box>
  </Box>
)

export default RangeFilter
