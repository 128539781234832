import { Stack, IconButton, Typography } from '@mui/material'
import React from 'react'
import { QuestionType } from '@app/src/types/reporting'
import { AssessmentRule, ResponseItem } from '@app/src/types/resourceExplorer'
import AssessedFileResponse from './AssessedResponse/AssessedFileResponse'
import AssessedOptionResponse from './AssessedResponse/AssessedOptionResponse'
import { InfoOutlined } from '@mui/icons-material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAccessorQuestionGuidance from '@app/src/components/Drawer/Views/DrawerViewAccessorQuestionGuidance'

interface AssessmentSectionResponseItemRowProps {
  questionId: number
  responseItemMap: Map<number, ResponseItem>
  questionsRuleMap: Map<number, AssessmentRule[]>
}

const AssessmentSectionResponseItemRow = ({
  questionId,
  responseItemMap,
  questionsRuleMap,
}: AssessmentSectionResponseItemRowProps) => {
  const responseItem = responseItemMap.get(questionId)
  const rulesForQuestion = questionsRuleMap.get(questionId)
  const { openDrawer } = useDrawer()

  const question = responseItem?.requestItem?.template

  if (!question) return null

  return (
    <Stack
      direction="row"
      bgcolor="background.paper"
      justifyContent="space-between"
      justifyItems="right"
      alignItems="center"
      p={2}
    >
      <Stack direction="row" alignItems="center" pr={2}>
        <IconButton
          onClick={() => openDrawer(<DrawerViewAccessorQuestionGuidance question={question} />, { anchor: 'left' })}
        >
          <InfoOutlined />
        </IconButton>
        <Typography variant="body1">{question.kpiName ?? question.questionText}</Typography>
      </Stack>
      {question.questionTypeId === QuestionType.Options && (
        <AssessedOptionResponse responseItem={responseItem} rules={rulesForQuestion} />
      )}
      {question.questionTypeId === QuestionType.File && (
        <AssessedFileResponse responseItem={responseItem} rules={rulesForQuestion} />
      )}
    </Stack>
  )
}

export default AssessmentSectionResponseItemRow
