import { OrganizationRiskSetting } from '@app/src/types/resourceExplorer'
import endpoints from '@app/src/api/endpoints'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { useQueryClient } from 'react-query'
import { useIntl } from 'react-intl'
import { useAmplitude } from '@app/src/context/AmplitudeContext'

interface RiskSettingsSaveModel {
  riskIndexId: number
  isActivated: boolean
}

export const useRiskSettingActivation = (
  riskSetting: OrganizationRiskSetting,
  options?: {
    onToggled?: (isActivated: boolean) => void
    onError?: () => void
  },
) => {
  const { formatMessage } = useIntl()
  const { mutateAsync, isLoading: isSaving } = useUpdateResource<RiskSettingsSaveModel>()
  const { showSnackbar } = useSnackbar()
  const { trackEvent } = useAmplitude()
  const queryClient = useQueryClient()
  const { onToggled } = options ?? {}

  const toggleRiskActivationAsync = async () => {
    await mutateAsync(
      {
        url: endpoints.organizationRiskSettings,
        body: {
          riskIndexId: riskSetting.riskType.id,
          isActivated: !riskSetting.isActivated,
        },
      },
      {
        onSuccess: data => {
          if (onToggled) onToggled(data.isActivated)

          showSnackbar({
            message: formatMessage(
              {
                id: data.isActivated
                  ? 'notifications.successfullyEnabledRiskIndex'
                  : 'notifications.successfullyDisabledRiskIndex',
              },
              { name: formatMessage({ id: `schemas.risk.riskIndexes.${riskSetting.riskType.source}.label` }) },
            ),
            severity: NotificationSeverity.success,
          })

          trackEvent({
            name: AmplitudeTrackingEvents.Organization.RiskIndex.Toggled,
            eventProps: {
              name: riskSetting.riskType.name,
              enabled: !riskSetting.isActivated,
            },
          })

          queryClient.invalidateQueries(FetchKey.AssessmentTemplateRiskIndicesCollection)
          queryClient.invalidateQueries(FetchKey.OrganizationRiskSettings)
          queryClient.removeQueries(FetchKey.RiskScreening)
          queryClient.removeQueries([FetchKey.FilterFacet, 'riskStatus'])
        },
        onError: () => {
          showSnackbar({
            message: formatMessage({
              id: 'notifications.toggleRiskIndexFailed',
            }),
            severity: NotificationSeverity.error,
          })
        },
      },
    )
  }

  return {
    toggleRiskActivationAsync,
    isSaving,
  }
}

export default useRiskSettingActivation
