import { AutocompleteRenderOptionState, Stack, Typography } from '@mui/material'
import { OptionIcon } from '@app/src/components/Form/Select'
import React from 'react'

type OptionWithLabelProps = {
  props: React.HTMLAttributes<HTMLLIElement>
  selected: AutocompleteRenderOptionState['selected']
  label: string
  multiple?: boolean
}

const OptionWithLabel: React.FC<OptionWithLabelProps> = ({ props, selected, label, multiple }) => {
  return (
    <li {...props}>
      <OptionIcon selected={selected} multiple={multiple} />
      <Stack>
        <Typography>{label}</Typography>
      </Stack>
    </li>
  )
}

export default OptionWithLabel
