import { Stack } from '@mui/material'
import * as Sentry from '@sentry/react'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import TextField from '@app/src/components/Ui/TextField'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { Provider } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'

export type DrawerViewProviderCommentProps = {
  provider: Provider
} & Omit<DrawerViewProps, 'title'>

type ProviderCommentPayload = {
  providerId: number
  text: string
}

const DrawerViewProviderComment: React.FC<DrawerViewProviderCommentProps> = ({ provider, ...props }) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const { account, trackEvent } = useAmplitude()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { handleSubmit, register, setValue, errors } = useForm<Omit<ProviderCommentPayload, 'providerId'>>({
    defaultValues: {
      text: '',
    },
  })
  const { mutate: mutateAddComment, isLoading } = useUpdateResource<ProviderCommentPayload>()

  const onSubmit = (values: ProviderCommentPayload) => {
    mutateAddComment(
      {
        url: endpoints.providerComments,
        body: { ...values, providerId: provider.id },
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'resourceExplorer.activity.successfullySavedComment' }),
            severity: 'success',
          })
          trackEvent({
            name: AmplitudeTrackingEvents.Accessor.ProviderOrganization.AdditionalInformation,
            eventProps: {
              information_type: 'comment',
              added_or_deleted: 'added',
            },
          })
          queryClient.invalidateQueries(FetchKey.CommentCollection)
          closeDrawer()
        },
        onError: error => {
          Sentry.captureException(error, {
            tags: { event: 'Error saving a provider comment' },
            user: { id: String(account?.user?.id) },
            extra: { errorDetails: error.message, providerId: String(provider.id) },
          })
          showErrorNotification({
            requestError: error,
            specificFallbackErrorMessage: formatMessage({ id: 'reporting.uploadFileError' }),
          })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'resourceExplorer.activity.commentTitle' })}
      subTitle={provider.name}
      buttons={[
        {
          label: formatMessage({ id: 'general.cancel' }),
          variant: 'text',
          disabled: isLoading,
          onClick: closeDrawer,
        },
        {
          label: formatMessage({ id: 'resourceExplorer.activity.commentTitle' }),
          variant: 'contained',
          type: 'submit',
          loading: isLoading,
        },
      ]}
      stackButtons
      onFormSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Stack px={2} flexGrow={1}>
        <TextField
          multiline
          minRows={25}
          maxRows={25}
          hoveringLabel
          placeholder={formatMessage({ id: 'textFieldPlaceholders.writeHere' })}
          fullWidth
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          name="text"
          label={formatMessage({ id: 'resourceExplorer.activity.internalComment' })}
          error={Boolean(errors?.text)}
          type="text"
          helperText={errors?.text?.message}
          onClear={(): void => setValue('text', '')}
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewProviderComment
