import { HistoryToggleOff } from '@mui/icons-material'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import DateDisplay from '@app/src/components/DateDisplay'
import EmptyState from '@app/src/components/EmptyState'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ProductMappingResponse } from '@app/src/types/product'
import { ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import DrawerViewReviewResponseValueChain from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewResponseValueChain'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'

interface Props {
  responses?: ProductMappingResponse[]
}

const MappingResponseHistoryLog: React.FC<Props> = ({ responses }) => {
  const { formatMessage } = useIntl()
  const sortedResponses = useMemo(() => responses?.sort((a, b) => b.id - a.id), [responses])
  const { openDrawer } = useDrawer()
  return (
    <Box>
      <Tabs value={0} centered variant="fullWidth">
        <Tab label={formatMessage({ id: 'schemas.mappingRequest.historyLog' })} />
      </Tabs>
      {!sortedResponses?.length ? (
        <Box mt={4} mb={6}>
          <EmptyState
            iconComponent={HistoryToggleOff}
            title={formatMessage({ id: 'schemas.mappingRequest.noHistory.title' })}
            description={formatMessage({ id: 'schemas.mappingRequest.noHistory.description' })}
          />
        </Box>
      ) : (
        <Box>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {sortedResponses?.map((response, i) => (
              <TimelineItem key={response.id}>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" />
                  {i !== sortedResponses?.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Box
                    component="button"
                    sx={{
                      border: 'none',
                      background: 'transparent',
                      cursor: response.status !== ResponseDraftStatus.Draft ? 'pointer' : 'inherit',
                      textAlign: 'left',
                      '&:hover': {
                        textDecoration: response.status !== ResponseDraftStatus.Draft ? 'underline' : 'none',
                      },
                    }}
                    onClick={() => {
                      if (response.status !== ResponseDraftStatus.Draft) {
                        openDrawer(<DrawerViewReviewResponseValueChain mappingResponse={response} />)
                      }
                    }}
                  >
                    <Typography>
                      {formatMessage({ id: `schemas.mappingRequest.historyLogStatus.response.${response.status}` })}
                    </Typography>
                    {response.status === ResponseDraftStatus.Draft ? (
                      <DateDisplay value={response.createdAt} withTime />
                    ) : (
                      <DateDisplay value={response.submittedAt} withTime />
                    )}
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      )}
    </Box>
  )
}

export default MappingResponseHistoryLog
