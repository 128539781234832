import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { SortOrder } from '@app/src/types/filter'
import {
  AssessmentRuleType,
  QuestionAnswerClassificationRule,
  QuestionAnswerRank,
  RequestCollectionPicker,
} from '@app/src/types/resourceExplorer'
import { Grid } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Select from '../../../components/Form/Select/ControlledSelect'
import { Operators } from '../../ResourceCollection/Filters/useFilters'
import AdvancedFeatures from '../AdvancedFeatures'

export type QuestionOptionClassificationRuleSaveModel = QuestionAnswerClassificationRule & { disabled?: boolean }

const OptionsClassification: React.FC = () => {
  const { formatMessage } = useIntl()
  const { control, setValue, watch } = useFormContext()

  const startOfName = 'questionOptionClassificationRules'

  const pickerId = watch(`requestCollectionId`)
  const watchQuestionOptionClassificationRules: Array<QuestionOptionClassificationRuleSaveModel | undefined> =
    watch(startOfName)

  const { items } = useFetchCollectionWithPost<RequestCollectionPicker>({
    endpoint: endpoints.pickers,
    key: FetchKey.Picker,
    payload: {
      include: [],
      filter: [
        {
          name: 'id',
          filters: [
            {
              operator: Operators.EqualTo,
              value: pickerId,
            },
          ],
        },
      ],
      sort: { target: 'name', order: SortOrder.ASCENDING },
    },
  })

  const presentValues = useMemo(
    () =>
      Object.values(watchQuestionOptionClassificationRules ?? {})
        .map(item => item)
        .filter(Boolean)
        .flat(),
    [watchQuestionOptionClassificationRules],
  )

  return (
    <Grid item xs={12}>
      <AdvancedFeatures hideWarningBanner>
        <Grid container bgcolor="common.white" spacing={2}>
          {Object.values(QuestionAnswerRank)
            .filter(item => item !== QuestionAnswerRank.NotSet)
            .map(item => (
              <Grid item xs={12} key={item}>
                <Select<QuestionOptionClassificationRuleSaveModel>
                  name={`${startOfName}.${item}`}
                  control={control}
                  fieldLabel={formatMessage({
                    id: `templateBuilder.answerClassifications.classificationsRanks.${item}`,
                  })}
                  multiple
                  options={
                    items?.[0]?.permittedOptions?.map(option => ({
                      label: option,
                      value: {
                        rank: item,
                        ruleType: AssessmentRuleType.SelectedValue,
                        value: option,
                      },
                      disabled: presentValues.some(present => present?.value === option),
                    })) ?? []
                  }
                  size="small"
                  findSelectedValue={(value, option) => value?.value === option?.value?.value}
                  onInputChange={(_e, value) => {
                    if (!value) setValue(`${startOfName}.${item}.rank`, undefined)
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </AdvancedFeatures>
    </Grid>
  )
}

export default OptionsClassification
