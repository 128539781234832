import CertificateScreeningItem from '@app/src/pages/Questionnaire/CertificateScreeningItem'
import { FileScreeningResult, ScreeningResult } from '@app/src/types/resourceExplorer'
import { AutoAwesomeOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'

interface CertificateScreeningResultProps {
  fileScreeningResult?: FileScreeningResult[]
}

const CertificateScreeningResult: React.FC<CertificateScreeningResultProps> = ({ fileScreeningResult }) => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const { showFileScreeningResult } = useFlags()

  if (!showFileScreeningResult) return null
  if (!fileScreeningResult?.length) return null

  const passedResults = fileScreeningResult
    .filter(riv => riv.result === ScreeningResult.Passed)
    .sort((a, b) => a.checkName.localeCompare(b.checkName))
  const failedResults = fileScreeningResult
    .filter(riv => riv.result !== ScreeningResult.Passed)
    .sort((a, b) => a.checkName.localeCompare(b.checkName))

  const allPassed = failedResults.length === 0

  return (
    <Alert severity="info" icon={<AutoAwesomeOutlined />} sx={{ mt: 2, backgroundColor: palette.communication.main }}>
      <AlertTitle>{formatMessage({ id: 'certificateScreeningResult.title' })}</AlertTitle>
      <Box mt={2}>
        <Typography variant="subtitle1">
          {formatMessage({
            id: allPassed ? 'certificateScreeningResult.allPassed' : 'certificateScreeningResult.someFailed',
          })}
        </Typography>
      </Box>
      <Box>
        {allPassed ? (
          passedResults.map(result => <CertificateScreeningItem key={result.checkName} fileScreeningResult={result} />)
        ) : (
          <>
            <Typography variant="subtitle1" mt={2}>
              {formatMessage({ id: 'certificateScreeningResult.errorCount' }, { count: failedResults.length })}
            </Typography>
            {failedResults.map(result => (
              <CertificateScreeningItem key={result.checkName} fileScreeningResult={result} />
            ))}
            {passedResults.length > 0 && (
              <>
                <Typography variant="subtitle1" mt={2}>
                  {formatMessage({ id: 'certificateScreeningResult.otherDataIdentified' })}
                </Typography>
                {passedResults.map(result => (
                  <CertificateScreeningItem key={result.checkName} fileScreeningResult={result} />
                ))}
              </>
            )}
          </>
        )}
      </Box>
    </Alert>
  )
}

export default CertificateScreeningResult
