import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import UserRoleDrawerContent, {
  UserRoleDrawerView,
} from '@app/src/pages/Configurations/ConfigurationsPages/Users/UserRoleDrawerContent'
import { Auth0Account, Auth0User } from '@app/src/pages/Configurations/ConfigurationsPages/Users/UsersConfig'
import { Organization, UsersOrganization } from '@app/src/types/organizations'
import { insertIf } from '@app/src/utils/helpersTs'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Card, Chip, Drawer, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useState } from 'react'

const useStyles = makeStyles(({ spacing, palette }) => ({
  accountCard: {
    padding: spacing(2),
    marginBottom: spacing(4),
    '&  span.MuiTypography-caption': {
      color: palette.error.dark,
    },
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: palette.grey[300],
  },
  solutionChip: {
    marginRight: spacing(1),
  },
  wfAdminChip: {
    borderColor: palette.error.dark,
  },
  solutionList: {
    marginTop: spacing(4),
  },
}))
export interface AccountCardProps {
  organization: UsersOrganization
  user: Auth0User
  getUpdatedUser: (updatedAccount: Auth0Account, deleteAccount?: boolean) => Auth0User
}

const AccountCard: React.FC<AccountCardProps> = ({ organization, user, getUpdatedUser }) => {
  const classes = useStyles()
  const [isDrawerOpen, openDrawer, closeDrawer] = useDialogState(false)
  const [dialogView, setDialogView] = useState<UserRoleDrawerView>(UserRoleDrawerView.ChangeRole)

  const [updatedAccount, updateAccount] = useState<Auth0Account | null>(null)

  const openRoleDialog = (currentAccount: Auth0Account, drawerView: UserRoleDrawerView): void => {
    updateAccount(currentAccount)
    setDialogView(drawerView)
    openDrawer()
  }

  const {
    data: orgData,
    isError,
    isLoading,
  } = useFetchResource<Organization>({
    endpoint: endpoints.organization(organization.organizationId),
    key: [FetchKey.Organization, organization.organizationId],
    showSnackbarOn404: false,
    options: {
      retry: false,
    },
  })
  if (isLoading) return null

  if (isError) {
    return (
      <Card className={classes.accountCard} elevation={0}>
        <Typography variant="caption">
          The organization with the ID {organization.organizationId} was not found in our database
        </Typography>
      </Card>
    )
  }

  const solutionsUserDoesntHaveAccessTo =
    [...(orgData?.assignedSolutions ?? []), ...insertIf(orgData?.id === 1, { accountType: 'Default' })]?.reduce<
      string[]
    >((missingSolutions, current) => {
      if (!organization.solutions.some(solution => solution.type === current.accountType)) {
        missingSolutions.push(current.accountType)
      }
      return missingSolutions
    }, []) ?? []

  return (
    <Card className={classes.accountCard} elevation={0}>
      <Typography variant="h6">{orgData?.name}</Typography>
      <Box className={classes.solutionList}>
        {organization.solutions.map(solution => (
          <Chip
            size="medium"
            className={classNames(classes.solutionChip, {
              [classes.wfAdminChip]: solution.role === 'WorldfavorEmployeeAdmin',
            })}
            variant="outlined"
            key={solution.type + solution.role}
            label={
              <>
                <b>{solution.type}</b>: {solution.role}
              </>
            }
            data-testid="admin-users-solution-delete"
            onClick={() =>
              openRoleDialog(
                {
                  solution: solution.type,
                  role: solution.role,
                  organizationId: organization.organizationId,
                },
                UserRoleDrawerView.ChangeRole,
              )
            }
            onDelete={() =>
              openRoleDialog(
                {
                  solution: solution.type,
                  role: solution.role,
                  organizationId: organization.organizationId,
                },
                UserRoleDrawerView.DeleteRole,
              )
            }
          />
        ))}
        {Boolean(solutionsUserDoesntHaveAccessTo.length) && (
          <Button
            startIcon={<AddIcon />}
            onClick={() =>
              openRoleDialog(
                {
                  solution: '',
                  role: '',
                  organizationId: organization.organizationId,
                },
                UserRoleDrawerView.AddRole,
              )
            }
          >
            Add solution
          </Button>
        )}
      </Box>
      <Drawer open={isDrawerOpen} onClose={closeDrawer} anchor="right">
        <UserRoleDrawerContent
          getUpdatedUser={getUpdatedUser}
          availableSolutions={solutionsUserDoesntHaveAccessTo}
          closeDrawer={closeDrawer}
          user={user}
          drawerView={dialogView}
          updateAccount={updateAccount}
          updatedAccount={updatedAccount}
          orgData={orgData}
        />
      </Drawer>
    </Card>
  )
}

export default AccountCard
