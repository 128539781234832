import { SvgIcon } from '@mui/material'
import React from 'react'
import { IntervalChartProps } from '../ThreeIntervals/IntervalChartIconOne'

export const IntervalChartIconOne: React.FC<IntervalChartProps> = ({ large }) => {
  const iconSize = large ? { width: 40, height: 40 } : {}
  return (
    <SvgIcon sx={iconSize}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <circle cx="8" cy="8" r="7" fill="#E0E0E0" stroke="white" strokeWidth="2" />
        <mask
          id="mask0_5431_2355"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="12"
          height="12"
        >
          <circle cx="8" cy="8" r="6" fill="#E0E0E0" />
        </mask>
        <g mask="url(#mask0_5431_2355)">
          <circle cx="8" cy="8" r="6" fill="#E0E0E0" />
          <mask
            id="path-4-outside-1_5431_2355"
            maskUnits="userSpaceOnUse"
            x="7"
            y="1"
            width="8"
            height="11"
            fill="black"
          >
            <rect fill="white" x="7" y="1" width="8" height="11" />
            <path d="M8 2C8.94686 2 9.88028 2.22409 10.7239 2.65396C11.5676 3.08383 12.2976 3.70726 12.8541 4.47329C13.4107 5.23932 13.778 6.12619 13.9261 7.06139L8 8V2Z" />
          </mask>
          <path
            d="M8 2C8.94686 2 9.88028 2.22409 10.7239 2.65396C11.5676 3.08383 12.2976 3.70726 12.8541 4.47329C13.4107 5.23932 13.778 6.12619 13.9261 7.06139L8 8V2Z"
            fill="#9E0202"
          />
          <path
            d="M8 2C8.94686 2 9.88028 2.22409 10.7239 2.65396C11.5676 3.08383 12.2976 3.70726 12.8541 4.47329C13.4107 5.23932 13.778 6.12619 13.9261 7.06139L8 8V2Z"
            stroke="white"
            strokeWidth="2"
            mask="url(#path-4-outside-1_5431_2355)"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default IntervalChartIconOne
