import ReactEChartsCore from 'echarts-for-react/lib/core'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

export const useEchartsContainerWidth = (echartsRef: React.MutableRefObject<ReactEChartsCore | null>) => {
  const [containerWidth, setContainerWidth] = useState<number>()
  useEffect(() => {
    const initialValue = echartsRef.current?.getEchartsInstance()?.getWidth()
    setContainerWidth(initialValue)

    const debouncedResizeHandler = debounce(
      () => setContainerWidth(echartsRef.current?.getEchartsInstance()?.getWidth()),
      100,
    )
    window.addEventListener('resize', debouncedResizeHandler)
    return () => window.removeEventListener('resize', debouncedResizeHandler)
  }, [])
  return containerWidth
}
