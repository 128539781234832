import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'

type RequestItemSkeletonProps = {
  howMany?: number
}

const RequestItemSkeleton = () => {
  return (
    <Box mt={5} display="flex" flexGrow={1} alignSelf="stretch">
      <Box mr={3} flexGrow={1}>
        <Typography variant="subtitle1">
          <Skeleton variant="text" />
        </Typography>
      </Box>
      <Box mr={3} flexGrow={9}>
        <Typography variant="subtitle1">
          <Skeleton variant="text" />
        </Typography>
      </Box>
    </Box>
  )
}

const RequestItemSkeletonWrapper = ({ howMany = 1 }: RequestItemSkeletonProps): JSX.Element => {
  if (howMany > 1) {
    return (
      <>
        {Array.from({ length: howMany }, (_, idx) => (
          <RequestItemSkeleton key={idx} />
        ))}
      </>
    )
  }

  return <RequestItemSkeleton />
}

export default RequestItemSkeletonWrapper
