import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import FinalRiskRatingView from '@app/src/pages/ResourceCollection/Collections/Provider/FinalRiskRatingView'
import { FinalRiskRating } from '@app/src/types/categories'
import { UpdateProviderFinalRiskRating } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../DrawerContext'

type DrawerViewRiskAssessmentProps = {
  providerIds: number[]
  finalRiskRating?: FinalRiskRating
} & Omit<DrawerViewProps, 'title' | 'subtitle'>

const DrawerViewRiskAssessment: React.FC<DrawerViewRiskAssessmentProps> = ({
  providerIds,
  finalRiskRating,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { mutate: saveResource, isLoading: isUpdating } = useCreateResource<UpdateProviderFinalRiskRating>()
  const { trackEvent } = useAmplitude()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { closeDrawer } = useDrawer()

  const formMethods = useForm<Omit<UpdateProviderFinalRiskRating, 'providerIds'>>({
    mode: 'onChange',
    defaultValues: {
      newFinalRiskRating: finalRiskRating,
      comment: '',
    },
  })

  const handleSaveFinalRiskRating = async (values: Omit<UpdateProviderFinalRiskRating, 'providerIds'>) => {
    saveResource(
      { url: endpoints.providerRiskRating, body: { ...values, providerIds } },
      {
        onError: requestError => {
          showErrorNotification({ requestError })
        },
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulFinalRiskAssessment' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          queryClient.invalidateQueries(FetchKey.Provider)
          queryClient.invalidateQueries(FetchKey.ProviderRisk)
          queryClient.invalidateQueries(FetchKey.ProviderActivity)

          closeDrawer()
        },
        onSettled: () => {
          trackEvent({
            name: AmplitudeTrackingEvents.Analyze.RiskAssessment.Saved,
          })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.providerRisk.assessment' })}
      buttons={[
        {
          loading: isUpdating,
          label: formatMessage({ id: 'general.update' }),
          variant: 'contained',
          color: 'primary',
          type: 'submit',
        },
      ]}
      onFormSubmit={formMethods.handleSubmit(handleSaveFinalRiskRating)}
      {...props}
    >
      <FormProvider {...formMethods}>
        <FinalRiskRatingView providerIds={providerIds} />
      </FormProvider>
    </DrawerView>
  )
}

export default DrawerViewRiskAssessment
