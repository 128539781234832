declare module '@mui/material/styles' {
  interface PaletteOptions {
    semantic: {
      error: string
    }
    communication: {
      main: string
      dark: string
      light: string
      contrastText: string
    }
    brand: {
      monsteraGreen: string
      oyster: string
      coral: string
      cherryBlossom: string
    }
    brandDecorative: {
      darkPeach: string
      emerald: string
      sunsetSky: string
      quartz: string
      midnightSky: string
    }
    visualization: {
      1: string
      2: string
      3: string
      4: string
      5: string
      6: string
      7: string
      8: string
      9: string
      10: string
      11: string
    }
  }

  interface Palette {
    semantic: {
      error: string
    }
    communication: {
      main: string
      dark: string
      light: string
      contrastText: string
    }
    brand: {
      monsteraGreen: string
      oyster: string
      coral: string
      cherryBlossom: string
    }
    brandDecorative: {
      darkPeach: string
      emerald: string
      sunsetSky: string
      quartz: string
      midnightSky: string
    }
    visualization: {
      1: string
      2: string
      3: string
      4: string
      5: string
      6: string
      7: string
      8: string
      9: string
      10: string
      11: string
    }
  }
}

//TODO: Figure out why we can't type this with PaletteOptions..
export const palette = {
  background: {
    default: '#fff',
    paper: '#fff',
  },
  semantic: {
    error: '#9E0202',
  },
  primary: {
    main: '#1C1C1C',
    dark: '#0C0000',
    light: '#212B36',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#074037',
    dark: '#06332C',
    light: '#2C5D55',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#FF9A84',
    dark: '#FA7A5D',
    light: '#FFEAE5',
    contrastText: '#000000',
  },
  warning: {
    main: '#FFD597',
    dark: '#FFC774',
    light: '#FFF2E0',
    contrastText: '#000000',
  },
  info: {
    main: '#BCD8F2',
    dark: '#9DCBF7',
    light: '#EBF3FB',
    contrastText: '#000000',
  },
  success: {
    main: '#BDE5CB',
    dark: '#97DAAE',
    light: '#EBF7EF',
    contrastText: '#000000',
  },
  communication: {
    main: '#C6C4F1',
    dark: '#4641B2',
    light: '#F4F3FC',
    contrastText: '#000000',
  },
  brand: {
    monsteraGreen: '#074037',
    oyster: '#F7F5F2',
    coral: '#F19F89',
    cherryBlossom: '#FFC8BC',
  },
  brandDecorative: {
    darkPeach: '#FF8165',
    emerald: '#28947D',
    sunsetSky: '#FEC15E',
    quartz: '#C6C4F1',
    midnightSky: '#272F6C',
  },
  visualization: {
    1: '#00168D',
    2: '#0029AA',
    3: '#003CC5',
    4: '#0050DE',
    5: '#1964F4',
    6: '#4B77FF',
    7: '#6C8DFF',
    8: '#89A2FF',
    9: '#A3B8FF',
    10: '#BCCDFF',
    11: '#D5E3FF',
  },
  action: {
    disabledBackground: '#d7d7d7',
  },
}

/*
Override the alert component?

  alert: {
    errorContent: '#000000',
    warningContent: '#000000',
    infoContent: '#000000',
    successContent: '#000000',
    /*errorFill, warningFill, infoFill & successFill should be main semantic colors with 60% #FFF on top (Not 90% opac as default by MUI)
  },
  */

/*
  highlight: {
    main: '#3B3CA7',
    dark: '#101043',
    light: '#6468EA',
    contrastText: '#FFFFFF',

    /* Not sure if these are needed:
    hoverOpacity: 0.04,
    selectedOpacity: 0.08,
    disabledOpacity: 0.38,
    focusOpacity: 0.12,
  },
  visualization: {
    neutralBlue: 'TBD',
    neutralTeal: 'TBD',
    neutralPurple: 'TBD',
    neutralYellow: 'TBD',
    negative: 'TBD',
    positive: 'TBD',
  },
*/
