import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

type ReviewTableHeaderProps = {
  reports: Array<{
    id: number
    title: string
  }>
  companies: Array<{
    id: number
    name: string
    organizationId: number
  }>
}

const ReviewTableHeader: React.FC<ReviewTableHeaderProps> = ({ reports, companies }) => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'form.createRequest.tableHeaders.templates' })}
        guidance={reports.map(report => report.title).join(', ')}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'form.createRequest.tableHeaders.period' })}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'form.createRequest.tableHeaders.companies' })}
        guidance={companies.map(company => company.name).join(', ')}
      />
      <TableHeaderCell label={formatMessage({ id: 'form.createRequest.tableHeaders.deadline' })} />
    </TableRow>
  )
}

export default ReviewTableHeader
