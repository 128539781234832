import { Badge, Box, Button, Chip, Paper, Theme, Typography, darken, lighten, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactElement, useState } from 'react'
import { useIntl } from 'react-intl'
import { Organization } from '@app/src/types/organizations'

import GradientOverlay from '@app/src/components/GradientOverlay'
import Image from '@app/src/components/Image'
import LoadingButton from '@app/src/components/LoadingButton'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { Solution } from '@app/src/context/AccessibleOrganizationsContext'
import useLogin from '@app/src/hooks/login'
import { useQueryClient } from 'react-query'
import { getSolutionTitle } from '@app/src/utils'
import { DefaultImages } from '@app/src/wf-constants'

interface Props {
  organization: Pick<Organization, 'image' | 'id' | 'name'>
  solutions?: Solution[]
  currentSolution?: string | null
  redirect?: (solution: string) => void
  backgroundImage?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  organizationContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(12),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'center',
    minHeight: 215,
  },
  contentSection: {
    flexGrow: 1,
    marginTop: -90,
  },
  orgName: {
    textAlign: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  solutionSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minHeight: 511,
  },
  solutionCard: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.background.paper, 0.4)
        : darken(theme.palette.background.paper, 0.4),
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    transition: 'background-color 0.1s',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor:
        theme.palette.mode === 'light'
          ? darken(theme.palette.background.paper, 0.005)
          : darken(theme.palette.background.paper, 0.35),
    },
  },
  buttonWrapper: {
    flexGrow: 'inherit',
  },
  solutionLabel: {
    maxWidth: 120,
    display: 'flex',
    alignItems: 'center',
  },
  pendingContainer: {
    padding: theme.spacing(3),
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.background.paper, 0.4)
        : darken(theme.palette.background.paper, 0.4),
    '& > p': {
      textAlign: 'center',
      paddingTop: theme.spacing(2),
      '&:last-child': {
        color: theme.palette.grey[600],
        marginTop: theme.spacing(2),
      },
    },
  },
  pendingTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  pendingSection: {},
  pending: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
  },
  lockedSolutionContainer: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: theme.palette.grey[400],
    backgroundColor: 'none',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor:
        theme.palette.mode === 'light'
          ? lighten(theme.palette.background.paper, 0.4)
          : darken(theme.palette.background.paper, 0.4),
    },
  },
  lockedSolution: {
    maxWidth: 120,
    opacity: 0.5,
  },
  chip: {
    marginLeft: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loginButton: {
    boxShadow: 'none',
  },
}))

interface SolutionSlotProps {
  solution: string
  onClick?: () => void
  action?: JSX.Element
  role?: string
  unseenNotificationsCount?: number
  openTeaser?: () => void
}

const SolutionSlot = ({
  solution,
  onClick,
  role,
  action,
  openTeaser,
  unseenNotificationsCount,
}: SolutionSlotProps): JSX.Element => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  if (openTeaser || !role) {
    return (
      <>
        <Paper elevation={0} className={classes.lockedSolutionContainer} onClick={openTeaser}>
          <Typography variant="subtitle1" className={classes.lockedSolution}>
            {getSolutionTitle(solution)}
          </Typography>
          <Chip className={classes.chip} label="LOCKED 🔒" size="small" />
        </Paper>
      </>
    )
  }

  return (
    <Paper elevation={0} data-testid={`${solution}-solution`} className={classes.solutionCard} onClick={onClick}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" mr={1}>
          <Typography variant="subtitle1">{getSolutionTitle(solution)}</Typography>
          <Typography variant="caption">{formatMessage({ id: `roles.${role.toLowerCase()}` })}</Typography>
        </Box>
        <Badge badgeContent={unseenNotificationsCount} color="secondary" max={99} overlap="rectangular">
          <Box>{action}</Box>
        </Badge>
      </Box>
    </Paper>
  )
}

const OrganizationCard = ({
  organization,
  solutions,
  currentSolution,
  redirect,
  backgroundImage,
}: Props): JSX.Element => {
  const classes = useStyles()
  const [clickedSolution, setClickedSolution] = useState<string>()
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const logIn = useLogin()

  const handleLogin = (solution: string, role?: string): void => {
    // if we are already logged in to the solution, redirect to the solution homepage or the redirect url
    if (solution.toLowerCase() === currentSolution) {
      redirect && redirect(solution)
    }
    // if we are not logged in to the solution, log in and redirect to the solution homepage or the redirect url
    if (!clickedSolution && solution.toLowerCase() !== currentSolution?.toLowerCase() && logIn && role) {
      setClickedSolution(solution)
      queryClient.clear()
      logIn(role, solution, organization.id, redirect)
      return
    }
  }

  const renderSolutionAction = (solution: string): ReactElement => {
    if (solution?.toLocaleLowerCase() === currentSolution) {
      return <Button variant="text">{formatMessage({ id: 'general.Current' })}</Button>
    }

    return (
      <LoadingButton
        disabled={solution === clickedSolution}
        variant="contained"
        color="primary"
        loading={solution === clickedSolution}
        className={classes.loginButton}
      >
        {formatMessage({ id: 'general.login' })}
      </LoadingButton>
    )
  }

  return (
    <div data-testid="organization-card">
      <Paper elevation={1} className={classes.paper}>
        <Image
          src={backgroundImage}
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          position="center top"
          overlay={<GradientOverlay />}
        >
          <Box className={classes.organizationContainer}>
            <Image
              style={{
                borderRadius: 5,
                height: 55,
                width: 55,
                minWidth: 55,
                border: '4px solid white',
                marginBottom: theme.spacing(2),
                backgroundColor: theme.palette.background.default,
              }}
              size="cover"
              src={organization.image?.url}
              placeholder={DefaultImages.Organization}
            />
            <ValueCell value={organization.name} variant="h5" disableCell maxRows={2} />
          </Box>

          <Paper className={classes.contentSection}>
            <div className={classes.solutionSection}>
              {solutions ? (
                <>
                  {solutions?.map(solution => (
                    <React.Fragment key={solution.solution}>
                      <SolutionSlot
                        solution={solution.solution.toLowerCase()}
                        onClick={(): void => handleLogin(solution.solution, solution.role)}
                        role={solution.role}
                        unseenNotificationsCount={solution.unseenNotificationsCount}
                        action={renderSolutionAction(solution.solution)}
                      />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <Paper elevation={0} className={classes.pendingContainer}>
                  <Typography variant="body1" className={classes.pendingTitle}>
                    {formatMessage({ id: 'claim.pending.card.title' })}
                  </Typography>
                  <Typography variant="body1">
                    {formatMessage({ id: 'claim.pending.card.reviewed' })} <br />
                    {formatMessage({ id: 'claim.pending.card.notLong' })}
                  </Typography>
                  <Typography variant="body1">{formatMessage({ id: 'claim.pending.card.support' })}</Typography>
                </Paper>
              )}
            </div>
          </Paper>
        </Image>
      </Paper>
    </div>
  )
}

export default OrganizationCard
