import { Badge, BadgeProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { Prettify } from '@app/src/types/utils'

type Props = { badgeContent?: string | number; expiresAt?: string }

type WithBadgeProps = Prettify<PropsWithChildren<Props> & BadgeProps>

const useStyles = makeStyles(({ spacing, palette }) => ({
  badgeRoot: {
    backgroundColor: palette.communication.main,
    padding: `${spacing(1.3)} ${spacing(1)}`,
    fontWeight: 10,
    lineHeight: 15,
  },
}))

export const WithBadge = ({
  badgeContent,
  expiresAt,
  children,
  classes: badgeClasses = {},
  ...badgeProps
}: WithBadgeProps): JSX.Element => {
  const classes = useStyles()

  if (expiresAt) {
    const today = new Date()
    const expireDate = new Date(expiresAt)

    if (today >= expireDate) {
      return <>{children}</>
    }
  }

  if (badgeContent) {
    return (
      <Badge
        badgeContent={badgeContent}
        classes={{ ...badgeClasses, badge: classNames(classes.badgeRoot, badgeClasses.badge) }}
        overlap="rectangular"
        color="primary"
        {...badgeProps}
      >
        {children}
      </Badge>
    )
  }
  return <>{children}</>
}

export default WithBadge
