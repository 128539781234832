import { Close } from '@mui/icons-material'
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import DataInsightsModalTable from './DataInsightsModalTable'
import DatahubBreadcrumbs from './DatahubBreadcrumbs'

interface DataInsightsModalProps {
  isOpen: boolean
  closeModal: () => void
}

const DataInsightsModal: React.FC<DataInsightsModalProps> = ({ isOpen, closeModal }) => {
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        name: AmplitudeTrackingEvents.Analyze.DataHub.Insights.ViewedInsightsModal,
      })
    }
  }, [isOpen, trackEvent])

  return (
    <Dialog onClose={closeModal} open={isOpen} fullScreen sx={{ m: 10 }} PaperProps={{ sx: { borderRadius: 2 } }}>
      <Box height="100%" margin={0} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" py={2} px={4}>
          <Stack>
            <Typography variant="h6">{formatMessage({ id: 'schemas.responseItem.responseItems' })}</Typography>
          </Stack>

          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>

        <Box px={4} bgcolor="background.default">
          <Box mb={2}>
            <DatahubBreadcrumbs />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" flexGrow={1} height={10} pl={4} bgcolor="grey.100">
          <DataInsightsModalTable />
        </Box>
      </Box>
    </Dialog>
  )
}

export default DataInsightsModal
