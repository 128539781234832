import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewSuggestedContact from '@app/src/components/Drawer/Views/DrawerViewSuggestedContact'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { SuggestedContact } from '@app/src/types/resourceExplorer'
import { ButtonBase, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import TextTruncate, { TextTruncateProps } from 'react-text-truncate'
import ParentCellOrFragment from './ParentCellOrFragment'

type SuggestedContactCellProps = {
  contact: SuggestedContact
  maxRows?: TextTruncateProps['line']
  drawer?: boolean
  disableCell?: boolean
  isDisabled?: boolean
}

const SuggestedContactCell: React.FC<SuggestedContactCellProps> = ({
  contact,
  maxRows,
  drawer,
  disableCell,
  isDisabled,
}) => {
  const { openDrawer } = useDrawer()
  const [truncated, setTruncated] = useState(false)

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip title={truncated ? contact.name : undefined}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          component={drawer ? ButtonBase : 'span'}
          onClick={drawer ? () => openDrawer(<DrawerViewSuggestedContact contact={contact} />) : undefined}
          width="100%"
        >
          <PersonAvatar name={contact.name} size={AvatarSize.Small} disabled={isDisabled} />
          <Typography variant="body1" align="left" flexGrow={1} component="span">
            <TextTruncate line={maxRows} text={contact.name} onTruncated={() => setTruncated(true)} />
          </Typography>
        </Stack>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default SuggestedContactCell
