import { Box, TableCell } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import React from 'react'
import { SortLabel } from '@app/src/components/Table/Cells/TableHeaderCell'
import RatingCell from '@app/src/components/Table/Cells/RatingCell'

type TableHeaderCellProps = {
  minWidth?: TableCellWidth

  sorting?: {
    name: string
  } & SortableHeaderProps
  value: number
}

const AssessmentOverviewHeaderCell: React.FC<TableHeaderCellProps> = ({
  minWidth = TableCellWidth.MEDIUM,
  sorting,
  value,
}) => {
  return (
    <TableCell
      sx={{ minWidth, backgroundColor: 'grey.100' }}
      sortDirection={sorting?.activeSorting.order === 'ascending' ? 'asc' : 'desc'}
      size="medium"
    >
      <Box display="flex" alignItems="center">
        <SortLabel sorting={sorting}>
          <Box mr={2}>
            <RatingCell disableCell label="" value={value} />
          </Box>
        </SortLabel>
      </Box>
    </TableCell>
  )
}

export default AssessmentOverviewHeaderCell
