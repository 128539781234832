import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import EmptyState from '@app/src/components/EmptyState'
import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { CommentsDisabledOutlined } from '@mui/icons-material'
import { Box, Divider, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const VerificationMessages: React.FC = () => {
  const { response } = useReport()
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()

  return (
    <>
      {response?.verifications?.length ? (
        response.verifications.map(verification => (
          <Box key={verification.id} pt={2}>
            <StatusCell value={verification.externalVerificationStatus} disableCell />

            <ListItem alignItems="flex-start" disablePadding sx={{ py: 2 }}>
              <ListItemAvatar sx={({ spacing }) => ({ minWidth: spacing(5) })}>
                <CompanyAvatar
                  imageUrl={verification.creatorOrganization?.image?.url}
                  organizationName={verification.creatorOrganization?.name ?? ''}
                  size={AvatarSize.Small}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Stack direction="row" alignItems="center">
                    <Typography>{verification.creatorOrganization?.name}</Typography>
                    <Typography variant="caption" color="textSecondary" ml={1}>
                      {formatMessage(
                        { id: 'general.valueWithDotSeparatorBefore' },
                        { value: formatValueToDate({ value: verification.createdAt }) },
                      )}
                    </Typography>
                  </Stack>
                }
                secondary={verification.externalVerificationComment}
              />
            </ListItem>

            <Divider variant="fullWidth" />
          </Box>
        ))
      ) : (
        <EmptyState
          iconComponent={CommentsDisabledOutlined}
          title={formatMessage({ id: 'reporting.messagesEmptyState.title' })}
          sx={{ my: 8 }}
          description={formatMessage({ id: 'reporting.messagesEmptyState.description' })}
        />
      )}
    </>
  )
}

export default VerificationMessages
