import { CheckOutlined } from '@mui/icons-material'
import { Alert, Snackbar, Typography } from '@mui/material'
import React, { ReactNode, useEffect, useState } from 'react'

import SnackbarContext, { SnackbarProps } from './SnackbarContext'

type SnackbarProviderProps = {
  children: ReactNode
}

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }: SnackbarProviderProps) => {
  const [snackbarStack, setSnackbarStack] = useState<SnackbarProps[]>([])
  const [activeSnackbar, setActiveSnackbar] = useState<SnackbarProps>()
  const [open, setOpen] = useState(false)

  const showSnackbar = (snackbarOptions: SnackbarProps): void => {
    setSnackbarStack(prev => [...prev, snackbarOptions])
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    if (!open && snackbarStack.length) {
      // Set a new snack when we don't have an active one
      setActiveSnackbar({ ...snackbarStack[0] })
      setSnackbarStack(prev => prev.slice(1))
      setOpen(true)
    }
  }, [snackbarStack, open])

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        sx={{ top: 8 }}
        key={activeSnackbar?.message?.toString()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={activeSnackbar?.disableAutoClose ? null : 10000}
        onClose={handleClose}
        onAnimationEnd={(): void => setActiveSnackbar(undefined)}
      >
        <Alert
          onClose={handleClose}
          severity={activeSnackbar?.severity}
          elevation={2}
          variant="filled"
          iconMapping={{
            success: <CheckOutlined fontSize="inherit" />,
          }}
        >
          {activeSnackbar?.message && <Typography variant="body1">{activeSnackbar.message}</Typography>}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
