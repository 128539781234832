import { createContext, useContext } from 'react'
import { Organization } from '@app/src/types/organizations'

export type Solution = {
  solution: string
  role: string
  unseenNotificationsCount?: number
}

export type AccessibleOrganizationsContextProps = {
  accessibleOrganizations: Array<{
    organization: Organization
    solution: Solution[]
  }>
  isLoading: boolean
}

const AccessibleOrganizationsContext = createContext<AccessibleOrganizationsContextProps>({
  accessibleOrganizations: [],
  isLoading: true,
})

export const useAccessibleOrganizations = (): AccessibleOrganizationsContextProps => {
  return useContext(AccessibleOrganizationsContext)
}

export default AccessibleOrganizationsContext
