import { Box, Stack, useTheme } from '@mui/material'
import RouterLink from '@app/src/components/RouterLink'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useLocation, useParams, useRouteMatch } from 'react-router'
import { ConfigItems, ConfigurationsParams } from './ConfigurationsScene'

type ConfigurationMenuProps = {
  configItems: ConfigItems
}

const ConfigurationMenu: React.FC<ConfigurationMenuProps> = ({ configItems }) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const { path } = useRouteMatch()
  const { configurationsPage } = useParams<ConfigurationsParams>()
  const { pathname } = useLocation()

  if (!configItems.length) return null

  return (
    <Stack maxWidth={240} flexGrow={1}>
      <Box width={240} flexGrow={1}>
        {configItems.map(item => {
          const isActive = pathname.startsWith(generatePath(path, { configurationsPage: item.configId }))

          return (
            <Box
              key={item.configId}
              textAlign="right"
              py={1}
              px={2}
              borderRight={isActive ? `3px solid ${theme.palette.primary.main}` : '3px solid transparent'}
              boxSizing="border-box"
            >
              <RouterLink
                to={generatePath(path, {
                  configurationsPage: item.configId,
                })}
                color={configurationsPage === item.configId ? 'secondary' : 'primary'}
                variant="body1"
                align="right"
                fontWeight={isActive ? theme.typography.fontWeightBold : theme.typography.body1.fontWeight}
              >
                {formatMessage({ id: `configurations.${item.configId}` })}
              </RouterLink>
            </Box>
          )
        })}
      </Box>
    </Stack>
  )
}

export default ConfigurationMenu
