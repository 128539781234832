import endpoints from '@app/src/api/endpoints'
import Select, { Option } from '@app/src/components/Form/Select/ControlledSelect'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { QuestionCategoryOption } from '@app/src/types/resourceExplorer'
import { QuestionCategory } from '@app/src/wf-constants/questionCategories'
import { Box } from '@mui/material'
import React from 'react'
import { Control } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface QuestionCategoryOptionSelectorProps {
  questionCategory: QuestionCategory
  index: number
  control: Control
}

const QuestionCategoryOptionSelector: React.FC<QuestionCategoryOptionSelectorProps> = ({
  questionCategory,
  index,
  control,
}) => {
  const { formatMessage } = useIntl()
  const customSort = questionCategory.customSort
  const optionCustomSort = customSort
    ? (a: Option<number>, b: Option<number>) => customSort(a.label, b.label)
    : undefined
  return (
    <Box mb={2}>
      <Select<number, QuestionCategoryOption>
        name={`categoryOptions[${index}]`}
        multiple
        control={control}
        fieldLabel={formatMessage({ id: questionCategory.translationKey })}
        objectToOption={option => ({
          label: option.name,
          value: option.id,
        })}
        customSort={optionCustomSort}
        forceFetch
        navigation={{
          url: endpoints.questionCategoryOptionCollection,
          type: 'post',
          postObject: {
            include: [],
            filter: [
              {
                name: 'category.name',
                filters: [
                  {
                    value: questionCategory.name,
                    operator: Operators.EqualTo,
                  },
                ],
              },
            ],
            pagination: {
              itemsPerPage: 10000,
              pageNumber: 1,
            },
          },
        }}
      />
    </Box>
  )
}

export default QuestionCategoryOptionSelector
