import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import { ArrowDownwardOutlined, ArrowUpwardOutlined, InfoOutlined, SwapVertOutlined } from '@mui/icons-material'
import { Box, Checkbox, CheckboxProps, IconButton, TableCell, TableSortLabel, Tooltip, Typography } from '@mui/material'
import React, { PropsWithChildren, ReactNode, useState } from 'react'
import TextTruncate from 'react-text-truncate'

type TableHeaderCellProps = {
  label: string
  subLabel?: string
  guidance?: ReactNode
  onInfoClick?: () => void
  minWidth?: TableCellWidth
  maxRows?: number
  checkbox?: CheckboxProps
  sorting?: {
    name: string
  } & SortableHeaderProps
}

export type SortLabelProps = Pick<TableHeaderCellProps, 'sorting'>

export const SortLabel: React.FC<PropsWithChildren<SortLabelProps>> = ({ sorting, children }) => {
  if (sorting) {
    const { name, activeSorting, toggleSorting } = sorting

    return (
      <TableSortLabel
        direction={activeSorting.order === 'ascending' ? 'asc' : 'desc'}
        active={activeSorting.target === name}
        onClick={() => toggleSorting(name)}
        IconComponent={() =>
          activeSorting.target === name ? (
            activeSorting.order === 'ascending' ? (
              <ArrowUpwardOutlined fontSize="small" color="primary" />
            ) : (
              <ArrowDownwardOutlined fontSize="small" color="primary" />
            )
          ) : (
            <SwapVertOutlined fontSize="small" color="secondary" />
          )
        }
        //Dont remove the letter-spacing, it will cause this to stop working 😂
        sx={{ letterSpacing: '0.02rem' }}
      >
        {children}
      </TableSortLabel>
    )
  }

  return <>{children}</>
}

const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  label,
  subLabel,
  maxRows = 1,
  minWidth = TableCellWidth.MEDIUM,
  checkbox,
  sorting,
  guidance,
  onInfoClick,
}) => {
  const [truncated, setTruncated] = useState(false)

  const handleTruncate = () => {
    setTruncated(true)
  }

  return (
    <TableCell
      sx={[{ minWidth, backgroundColor: 'grey.100' }]}
      sortDirection={sorting?.activeSorting.order === 'ascending' ? 'asc' : 'desc'}
      size="medium"
    >
      <Box display="flex" alignItems="center">
        <Tooltip title={truncated ? label : undefined}>
          <Box>
            <SortLabel sorting={sorting}>
              {checkbox && <Checkbox size="medium" sx={{ p: 0, mx: 0.5 }} {...checkbox} />}
              <Typography variant="inherit" component="span">
                <TextTruncate line={maxRows} text={label} onTruncated={handleTruncate} />
              </Typography>
              {subLabel && (
                <Typography variant="caption" component="span">
                  <TextTruncate line={maxRows} text={subLabel} onTruncated={handleTruncate} />
                </Typography>
              )}
            </SortLabel>
          </Box>
        </Tooltip>

        {guidance && (
          <Tooltip title={guidance}>
            <InfoOutlined fontSize="small" sx={{ ml: 1 }} />
          </Tooltip>
        )}

        {onInfoClick && (
          <IconButton onClick={onInfoClick} size="small" sx={{ ml: 1 }}>
            <InfoOutlined fontSize="small" />
          </IconButton>
        )}
      </Box>
    </TableCell>
  )
}

export default TableHeaderCell
