import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewRiskAssessment from '@app/src/components/Drawer/Views/DrawerViewRiskAssessment'
import FinalRiskRatingCell from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import RiskChipCell from '@app/src/components/Table/Cells/RiskChipCell'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import { Operators, RESPONSE_ITEM_LATEST_SUBMITTED_FILTER } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FinalRiskRating } from '@app/src/types/categories'
import { SuggestedGuidanceTypeOfRisk } from '@app/src/types/flags'
import {
  InquiryStatus,
  ProviderRiskStatus,
  RiskPrioritizationSummary,
  SustainabilityArea,
} from '@app/src/types/resourceExplorer'
import { Add } from '@mui/icons-material'
import { Box, Button, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import RiskAccordionWithFetching from './RiskAccordionWithFetching'
import RiskSection from './RiskSection'

interface ProviderRiskCardProps {
  riskPrioritizationSummary?: RiskPrioritizationSummary
}

const ProviderRiskCard: React.FC<ProviderRiskCardProps> = ({ riskPrioritizationSummary }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { getCountryRisksForSustainabilityArea } = useRiskSettings()

  const organizationId = riskPrioritizationSummary?.riskPrioritization.provider.organizationId
  const periodName = riskPrioritizationSummary?.riskPrioritization.periodName
  const countryRisks = riskPrioritizationSummary?.hqCountry?.risks

  const environmentalRisks = getCountryRisksForSustainabilityArea(countryRisks, SustainabilityArea.Environment)
  const socialRisks = getCountryRisksForSustainabilityArea(countryRisks, SustainabilityArea.Social)
  const governanceRisks = getCountryRisksForSustainabilityArea(countryRisks, SustainabilityArea.Governance)

  const defaultFilters = [
    {
      name: 'response.request.subscriptions.target.id',
      filters: [{ operator: Operators.In, value: [riskPrioritizationSummary?.riskPrioritization.providerId ?? 0] }],
    },
    { name: 'isAnswered', filters: [{ operator: Operators.EqualTo, value: true }] },
    { name: 'flag', filters: [{ operator: Operators.NotEqualTo, value: 'NotSet' }] },
    { name: 'response.request.periodName', filters: [{ operator: Operators.EqualTo, value: periodName }] },
    RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
  ]

  const {
    facets: [inquiries],
    isLoading: isRequestCountLoading,
    count: totalRequests,
  } = useFetchFacets({
    key: [FetchKey.InquiryStatus, organizationId],
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status' }],
    filter: [
      {
        name: 'providerId',
        filters: [
          {
            value: riskPrioritizationSummary?.riskPrioritization.providerId,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'periodName',
        filters: [
          {
            value: periodName,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
  })

  const requestedRequests = inquiries?.find(i => i.value === InquiryStatus.Requested)?.count ?? 0
  const correctionNeededRequests = inquiries?.find(i => i.value === InquiryStatus.CorrectionNeeded)?.count ?? 0
  const pendingRequests = requestedRequests + correctionNeededRequests

  return (
    <>
      <Stack bgcolor="grey.100" py={1} px={2} borderRadius={2} spacing={2}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Stack alignItems="flex-start" bgcolor="common.white" p={2} spacing={2} borderRadius={1} height="100%">
              <Typography variant="overline">
                {formatMessage({ id: 'schemas.providerRisk.riskStatusWithYear' }, { year: periodName })}
              </Typography>
              <RiskChipCell disableCell size="small" risk={riskPrioritizationSummary?.riskPrioritization.riskStatus} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack alignItems="flex-start" bgcolor="common.white" p={2} spacing={2} borderRadius={1} height="100%">
              <Typography variant="overline">
                {formatMessage({ id: 'schemas.providerRisk.riskCard.yourRiskAssessment' })}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                {riskPrioritizationSummary?.riskPrioritization.provider.finalRiskRating !==
                  FinalRiskRating.NotSelected && (
                  <FinalRiskRatingCell
                    value={riskPrioritizationSummary?.riskPrioritization.provider.finalRiskRating}
                    disableCell
                  />
                )}
                {riskPrioritizationSummary?.riskPrioritization.provider.finalRiskRating ===
                  FinalRiskRating.NotSelected && (
                  <Button
                    startIcon={<Add />}
                    onClick={() =>
                      openDrawer(
                        <DrawerViewRiskAssessment
                          providerIds={[riskPrioritizationSummary?.riskPrioritization.provider.id]}
                          subTitle={riskPrioritizationSummary?.riskPrioritization.provider.name}
                          finalRiskRating={
                            riskPrioritizationSummary?.riskPrioritization.provider.finalRiskRating ??
                            FinalRiskRating.NotSelected
                          }
                        />,
                      )
                    }
                    variant="text"
                  >
                    {formatMessage({ id: 'general.add' })}
                  </Button>
                )}
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="overline" color="textSecondary">
              {formatMessage({ id: 'schemas.providerRisk.riskCard.requestProgress' })}
            </Typography>
            {isRequestCountLoading ? (
              <Skeleton width={100} />
            ) : (
              <Typography variant="subtitle2" color="textSecondary">
                {formatMessage(
                  { id: 'schemas.providerRisk.completionStatus' },
                  { completedCount: totalRequests - pendingRequests, totalCount: totalRequests },
                )}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Box mt={1}>
        <RiskAccordionWithFetching
          label={formatMessage({ id: 'schemas.providerRisk.customFlags' })}
          riskStatus={
            riskPrioritizationSummary?.riskPrioritization.customRiskStatus ?? ProviderRiskStatus.NotApplicable
          }
          fetchData={{
            key: [FetchKey.ResponseItemCustomFlags, organizationId, periodName],
            endpoint: endpoints.responseItemsCollection,
            payload: {
              filter: [
                ...defaultFilters,
                {
                  name: 'flagRule.suggestedRiskGuidance.typeOfRiskDescription',
                  filters: [
                    {
                      operator: Operators.NotIn,
                      value: [
                        SuggestedGuidanceTypeOfRisk.Environmental,
                        SuggestedGuidanceTypeOfRisk.Governance,
                        SuggestedGuidanceTypeOfRisk.Social,
                      ],
                    },
                  ],
                },
              ],
              include: [
                'flagRule.suggestedRiskGuidance',
                'flagRule.requestTemplateItem',
                'response.request.template',
                'response.request.tags',
              ],
            },
          }}
        />
      </Box>

      <RiskSection
        label={formatMessage({ id: 'schemas.providerRisk.environmental' })}
        riskStatus={
          riskPrioritizationSummary?.riskPrioritization.environmentRiskStatus ?? ProviderRiskStatus.NotApplicable
        }
        risks={environmentalRisks}
        riskCountryName={riskPrioritizationSummary?.hqCountry?.name}
        fetchData={{
          key: [
            FetchKey.ResponseItemStandardFlags,
            organizationId,
            periodName,
            SuggestedGuidanceTypeOfRisk.Environmental,
          ],
          endpoint: endpoints.responseItemsCollection,
          payload: {
            filter: [
              ...defaultFilters,
              {
                name: 'flagRule.suggestedRiskGuidance.typeOfRiskDescription',
                filters: [
                  {
                    operator: Operators.EqualTo,
                    value: SuggestedGuidanceTypeOfRisk.Environmental,
                  },
                ],
              },
            ],
            include: ['flagRule.suggestedRiskGuidance', 'response.request.template', 'response.request.tags'],
          },
        }}
      />

      <RiskSection
        label={formatMessage({ id: 'schemas.providerRisk.social' })}
        riskStatus={riskPrioritizationSummary?.riskPrioritization.socialRiskStatus ?? ProviderRiskStatus.NotApplicable}
        risks={socialRisks}
        riskCountryName={riskPrioritizationSummary?.hqCountry?.name}
        fetchData={{
          key: [FetchKey.ResponseItemStandardFlags, organizationId, periodName, SuggestedGuidanceTypeOfRisk.Social],
          endpoint: endpoints.responseItemsCollection,
          payload: {
            filter: [
              ...defaultFilters,
              {
                name: 'flagRule.suggestedRiskGuidance.typeOfRiskDescription',
                filters: [
                  {
                    operator: Operators.EqualTo,
                    value: SuggestedGuidanceTypeOfRisk.Social,
                  },
                ],
              },
            ],
            include: ['flagRule.suggestedRiskGuidance', 'response.request.template', 'response.request.tags'],
          },
        }}
      />

      <RiskSection
        label={formatMessage({ id: 'schemas.providerRisk.governance' })}
        riskStatus={
          riskPrioritizationSummary?.riskPrioritization.governanceRiskStatus ?? ProviderRiskStatus.NotApplicable
        }
        risks={governanceRisks}
        riskCountryName={riskPrioritizationSummary?.hqCountry?.name}
        fetchData={{
          key: [FetchKey.ResponseItemStandardFlags, organizationId, periodName, SuggestedGuidanceTypeOfRisk.Governance],
          endpoint: endpoints.responseItemsCollection,
          payload: {
            filter: [
              ...defaultFilters,
              {
                name: 'flagRule.suggestedRiskGuidance.typeOfRiskDescription',
                filters: [
                  {
                    operator: Operators.EqualTo,
                    value: SuggestedGuidanceTypeOfRisk.Governance,
                  },
                ],
              },
            ],
            include: ['flagRule.suggestedRiskGuidance', 'response.request.template', 'response.request.tags'],
          },
        }}
      />
    </>
  )
}

export default ProviderRiskCard
