import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'

const ContactsFilters = ({ allowedFilters, resetPage, implicitFilters }: FiltersProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <Grid container spacing={1}>
      <Filters resetPage={resetPage} allowedFilters={allowedFilters}>
        {({ filters, inDrawerFiltersCount, clearQueryFilter }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item md={4} sm={8}>
                <Filter operator={Operators.In} name="user.id">
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.Provider, filterName],
                        endpoint: endpoints.contactsWithFacets,
                        facetsParam: [{ name: 'user.name' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.contact.name' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>

              <Grid item md={4} sm={8}>
                <Filter operator={Operators.In} name="creatorOrganization.id">
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.ContactFacets, filterName],
                        endpoint: endpoints.contactsWithFacets,
                        facetsParam: [{ name: 'creatorOrganization.name' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.contact.organization' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>

              <Grid item md={3}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.contacts' })}>
                              <Filter operator={Operators.In} name="user.id">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.contactsWithFacets,
                                      facetsParam: [{ name: 'user.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.contact.name' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter operator={Operators.In} name="creatorOrganization.id">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ContactFacets, filterName],
                                      endpoint: endpoints.contactsWithFacets,
                                      facetsParam: [{ name: 'creatorOrganization.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.contact.organization' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.contact.information' })}>
                              <Filter operator={Operators.In} name="user.email">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.contactsWithFacets,
                                      facetsParam: [{ name: 'user.email', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.contact.email' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter operator={Operators.In} name="user.phoneNumber">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.Provider, filterName],
                                      endpoint: endpoints.contactsWithFacets,
                                      facetsParam: [{ name: 'user.phoneNumber', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.contact.phone' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter operator={Operators.In} name="user.position">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ContactFacets, filterName],
                                      endpoint: endpoints.contactsWithFacets,
                                      facetsParam: [{ name: 'user.position', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.contact.position' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default ContactsFilters
