import { Box } from '@mui/material'
import React from 'react'

import { RequestItem as RequestItemType, ResponseItem } from '@app/src/types/resourceExplorer'
import ResponseValue from './ResponseValue'

type RequestItemProps = {
  item: RequestItemType
  responseItem?: ResponseItem
}

const RequestItem: React.FC<RequestItemProps> = ({ item, responseItem }) => {
  const questionType = item?.questionType?.name?.toLowerCase() || ''

  return (
    <Box flexGrow={1} data-testid="request" pb={2}>
      {responseItem && (
        <ResponseValue item={responseItem} questionType={questionType} unitSymbol={item?.unit?.symbol} />
      )}
    </Box>
  )
}

export default RequestItem
