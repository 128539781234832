import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport, { getExportColumns } from '@app/src/components/Drawer/Views/DrawerViewExport'
import EvaluationsWizard from '@app/src/components/EvaluationsWizard'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { EvaluationSchema } from '@app/src/schemas'
import { DataPoint } from '@app/src/types/resourceExplorer'
import { ResourceTypes } from '@app/src/wf-constants'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import EvaluationsFilters from '../Filters/EvaluationsFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import EvaluationHeader from './Evaluation/EvaluationHeader'
import EvaluationRow from './Evaluation/EvaluationRow'

interface Props {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
}

const allowedFilters = [
  'indicator.section.framework.id',
  'indicator.section.id',
  'indicator.id',
  'value',
  'targetObjectType',
  'targetObjectId',
  'targetObject.country.name',
  'targetObject.supplierUsage',
  'targetObject.tier',
  'evidence',
  'createdAt',
  'targetObject.ownerUserId',
  'targetObject.providerApprovalStatus',
  'targetObject.priority',
  'targetObject.finalRiskRating',
  'targetObject.activityStatus',
  'targetObject.tier',
  'targetObject.categoryOptions.id',
  'periodName',
]

const EvaluationsCollection = ({ relatedContentFilter = [], embedded }: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const [isCreateFormOpen, setCreateFormOpen] = useState(false)
  const { openDrawer } = useDrawer()

  const openCreateForm = (): void => setCreateFormOpen(true)
  const closeCreateForm = (): void => setCreateFormOpen(false)

  const userFilters = apiFilters
  const rawExportPayload = {
    filter: relatedContentFilter,
    sort: sorting,
    include: [
      'indicator',
      'indicator.section',
      'indicator.section.framework',
      'indicator.unit',
      'indicator.frameworkOptionCollection',
      'targetObject',
    ],
  }

  const payload = {
    ...rawExportPayload,
    filter: [...rawExportPayload.filter, ...userFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<DataPoint>({
    key: FetchKey.DataPoint,
    endpoint: endpoints.evaluationsCollection,
    payload,
  })

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'resourceTypes.datapoint' })}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.DataPoint}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={getExportColumns(EvaluationSchema)}
                  rawExportPayload={rawExportPayload}
                />,
              ),
            disabled: isLoading || count === 0,
          },
          {
            label: formatMessage({ id: `resourceCollections.create.${ResourceTypes.DataPoint}` }),
            variant: 'contained',
            onClick: openCreateForm,
            disabled: isLoading || isFetching,
          },
        ]}
        filter={
          <EvaluationsFilters
            allowedFilters={allowedFilters}
            implicitFilters={relatedContentFilter}
            resetPage={resetPage}
          />
        }
        embedded={embedded}
      >
        <Table<DataPoint>
          HeaderComponent={() => <EvaluationHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
          RowComponent={EvaluationRow}
          count={count}
          data={items}
          isLoading={isLoading || isFetching}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ResourceCollectionScene>

      <EvaluationsWizard isOpen={isCreateFormOpen} onClose={closeCreateForm} />
    </>
  )
}
export default EvaluationsCollection
