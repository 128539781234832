import { Components } from '@mui/material'
import { palette } from '@app/src/theme/palette'

const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      backgroundColor: '#FFF',
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.semantic.error,
      },
    },
  },
}

export default MuiOutlinedInput
