import { Box, Skeleton, Stack, Typography, alpha } from '@mui/material'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import React from 'react'

interface CompanyCardProps {
  isLoading?: boolean
  mainText?: string
  secondaryText?: string
  avatarAlt?: string
  action?: React.ReactNode
}

const CompanyCard: React.FC<CompanyCardProps> = ({ isLoading, avatarAlt, mainText, secondaryText, action }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      borderRadius={1}
      border={({ palette }) => `solid 1px ${alpha(palette.common.black, 0.1)}`}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {isLoading ? (
          <Skeleton width={40} height={70} />
        ) : (
          <Avatar
            size={AvatarSize.Large}
            sx={({ palette }) => ({ backgroundColor: palette.secondary.dark, color: palette.secondary.contrastText })}
          >
            {avatarAlt}
          </Avatar>
        )}
        <Stack textAlign="left">
          {isLoading ? <Skeleton width={80} height={30} /> : <Typography variant="subtitle2">{mainText}</Typography>}
          {isLoading ? (
            <Skeleton width={100} height={25} />
          ) : (
            <Typography variant="body2" color="textSecondary">
              {secondaryText}
            </Typography>
          )}
        </Stack>
      </Stack>
      {action && <Box>{action}</Box>}
    </Stack>
  )
}

export default CompanyCard
