import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import RiskIndexInfoDrawerView from '@app/src/components/Drawer/Views/RiskIndexInfoDrawerView'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import RiskIndexIcon from '@app/src/pages/Configurations/ConfigurationsPages/RiskScreening/RiskIndexIcons/RiskIndexIcon'
import { OrganizationRiskSetting } from '@app/src/types/resourceExplorer'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import useRiskSettingActivation from '@app/src/hooks/useRiskSettingActivation'

interface RiskCardMethodologyProps {
  risks?: OrganizationRiskSetting[]
}

const RiskCard: React.FC<{ riskSetting: OrganizationRiskSetting }> = ({ riskSetting }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const [isRiskIndexActivated, setIsRiskIndexActivated] = useState(riskSetting.isActivated)
  const { toggleRiskActivationAsync, isSaving } = useRiskSettingActivation(riskSetting, {
    onToggled: isActivated => {
      setIsRiskIndexActivated(isActivated)
    },
  })

  const onChange = async () => {
    if (!isSaving) {
      setIsRiskIndexActivated(!isRiskIndexActivated)
      await toggleRiskActivationAsync()
    }
  }

  return (
    <Box
      height={80}
      display="flex"
      alignItems="center"
      gap={2}
      px={2}
      border="1px solid rgba(0, 0, 0, 0.12)"
      borderRadius={1}
      bgcolor={!isRiskIndexActivated ? 'grey.200' : ''}
    >
      <Switch checked={isRiskIndexActivated} onChange={onChange} />
      <Avatar
        size={AvatarSize.Large}
        sx={({ palette }) => ({
          backgroundColor: 'white',
          border: `1px solid ${palette.secondary.main}`,
        })}
      >
        <RiskIndexIcon source={riskSetting.riskType.source} />
      </Avatar>
      <Box flexGrow={1} ml={2} overflow="hidden">
        <Typography variant="subtitle2">
          {formatMessage({ id: `schemas.risk.riskIndexes.${riskSetting.riskType.source}.label` })}
        </Typography>
        <Tooltip title={formatMessage({ id: `schemas.risk.riskIndexes.${riskSetting.riskType.source}.source` })}>
          <Typography variant="body2" color="textSecondary" noWrap>
            {formatMessage({ id: `schemas.risk.riskIndexes.${riskSetting.riskType.source}.source` })}
          </Typography>
        </Tooltip>
      </Box>
      <IconButton size="small">
        <InfoOutlinedIcon onClick={() => openDrawer(<RiskIndexInfoDrawerView riskType={riskSetting.riskType} />)} />
      </IconButton>
    </Box>
  )
}

const RiskCardMethodology: React.FC<RiskCardMethodologyProps> = ({ risks }) => {
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      {risks?.map(risk => (
        <Grid item xs={12} sm={6} md={4} key={risk.riskType.id}>
          <RiskCard riskSetting={risk} />
        </Grid>
      ))}
    </Grid>
  )
}

export default RiskCardMethodology
