import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewImport from '@app/src/components/Drawer/Views/DrawerViewImport'
import DrawerViewImportSpend from '@app/src/components/Drawer/Views/DrawerViewImportSpend'
import { ArrowForwardIosOutlined, EuroOutlined, UploadFileOutlined } from '@mui/icons-material'
import { Box, Divider, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewImportInitialStepProps = {
  solution?: string
  providerType: string
  allowedFilters: string[]
} & Omit<DrawerViewProps, 'title'>

const AVATAR_STYLING = {
  minWidth: 'unset',
  mr: 2,
  bgcolor: 'secondary.main',
  width: 40,
  height: 40,
  color: 'common.white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const DrawerViewImportInitialStep: React.FC<DrawerViewImportInitialStepProps> = ({
  solution,
  providerType,
  allowedFilters,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <DrawerView title={formatMessage({ id: 'import.import' })} {...props}>
      <Box px={2} mt={2}>
        <Box>
          <ListItemButton
            onClick={() => openDrawer(<DrawerViewImport providerType={providerType} solution={solution} />)}
          >
            <ListItemAvatar sx={{ ...AVATAR_STYLING }}>
              <UploadFileOutlined color="inherit" />
            </ListItemAvatar>
            <ListItemText primary="Import suppliers (Excel)" secondary="Add suppliers in bulk and connect later" />
            <ArrowForwardIosOutlined />
          </ListItemButton>
        </Box>
        <Divider />
        <Box>
          <ListItemButton
            onClick={() =>
              openDrawer(<DrawerViewImportSpend providerType={providerType} allowedFilters={allowedFilters} />)
            }
          >
            <ListItemAvatar sx={{ ...AVATAR_STYLING }}>
              <EuroOutlined />
            </ListItemAvatar>
            <ListItemText primary="Import spend (Excel)" secondary="Add spend in bulk for multiple periods" />
            <ArrowForwardIosOutlined />
          </ListItemButton>
        </Box>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewImportInitialStep
