import WifiOffIcon from '@mui/icons-material/WifiOff'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { SortOrder } from '@app/src/types/filter'
import { PeriodDetails, ProviderProgressView, ResponseItem } from '@app/src/types/resourceExplorer'
import sortDistinctPeriodLabels from '@app/src/utils/sortDistinctPeriodLabels'
import { QuestionTypes } from '@app/src/wf-constants'
import ProviderProgressFilter from '../Filters/ProviderProgressFilter'
import {
  FilterGroup,
  Operators,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
  RESQUEST_ITEM_IS_DELETED_FILTER,
} from '../Filters/useFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import ProviderProgressHeader from './ProviderProgress/ProviderProgressHeader'
import ProviderProgressRow from './ProviderProgress/ProviderProgressRow'

type Props = {
  relatedContentFilter?: FilterGroup[]
  supplierId?: number | undefined
  organizationId?: number
}

export const allowedFilters = ['response.request.template.id', 'response.request.periodName']

const SourcingProviderProgressCollection: React.FC<Props> = ({ relatedContentFilter = [], organizationId }) => {
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(allowedFilters)

  const baseFilters = [
    RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
    RESQUEST_ITEM_IS_DELETED_FILTER,
    { name: 'isAnswered', filters: [{ operator: Operators.EqualTo, value: true }] },
    { name: 'creatorOrganizationId', filters: [{ operator: Operators.EqualTo, value: organizationId }] },
  ]

  const {
    facets: [allAvailablePeriods],
    isLoading: isLoadingPeriods,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets],
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: Boolean(userFilters.length) },
    facetsParam: [{ name: 'response.request.periodName' }],
    filter: [...userFilters, ...baseFilters, ...relatedContentFilter],
  })

  const basePayload = {
    filter: [...userFilters, ...baseFilters, ...relatedContentFilter],
    sort: {
      target: 'requestItem.questionId',
      order: SortOrder.DESCENDING,
    },
    include: [
      'response.request',
      'response.request.template',
      'requestItem.unit',
      'requestItem.questionType',
      'requestItem.requestCollection',
    ],
  }

  const payload = {
    ...basePayload,
    filter: [...basePayload.filter],
    pagination: {
      pageNumber: 1,
      itemsPerPage: 1000,
    },
  }

  const { items, count, isLoading, isError } = useFetchCollectionWithPost<ResponseItem>({
    key: FetchKey.ProviderProgressPage,
    endpoint: endpoints.responseItemsCollection,
    payload,
  })

  const getAnswer = (item: ResponseItem): string | number | undefined => {
    switch (item.requestItem.questionType.name) {
      case QuestionTypes.File:
        return item.cannotAnswer
          ? formatMessage({ id: 'schemas.providerProgress.fileUpload.weDoNotHaveThis' })
          : formatMessage({ id: 'schemas.providerProgress.fileUpload.uploaded' })
      case QuestionTypes.Options:
      case QuestionTypes.Number:
      case QuestionTypes.Text:
        return item.cannotAnswer
          ? formatMessage({ id: 'schemas.providerProgress.fileUpload.weDoNotHaveThis' })
          : item.answer
    }
  }

  const transformData = (data?: ResponseItem[]) => {
    const groupedData = data?.reduce<Record<number, ProviderProgressView>>((acc, item) => {
      const questionId = item.requestItem.questionId

      if (!acc[questionId]) {
        acc[questionId] = {
          id: questionId,
          templateId: item.response.request.questionnaireTemplateId,
          requestId: item.response.request.id,
          responseId: item.responseId,
          parentObject: {
            id: questionId,
            title: item.requestItem.description,
            questionType: item.requestItem.questionType.name,
            unit: item.requestItem.unit,
          },
          periodDetails: [],
        }
      }

      let collectionResponse

      if (item.isCollection) {
        collectionResponse = item.requestItem.allowMultiChoice
          ? `${item.selectedOptions.length} / ${item.requestItem.requestCollection.items.length}`
          : item.selectedOptions[0]
      }

      const periodDetails: PeriodDetails = {
        periodName: item.response.request.periodName,
        answer: getAnswer(item),
        cannotAnswer: item.cannotAnswer,
        collectionResponse,
        isMultiSelectOptionQuestion: Boolean(item.requestItem.allowMultiChoice),
      }

      acc[questionId].periodDetails.push(periodDetails)
      return acc
    }, {})

    return Object.values(groupedData ?? [])
  }

  const transformedData = useMemo(() => transformData(items), [items])
  const sortedDistinctAvailablePeriodLabels = sortDistinctPeriodLabels(allAvailablePeriods)

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'resourceTypes.supplierProgress' })}
      filter={
        <ProviderProgressFilter
          allowedFilters={allowedFilters}
          implicitFilters={[...baseFilters, ...relatedContentFilter]}
        />
      }
    >
      <Table<ProviderProgressView>
        RowComponent={({ row }): JSX.Element => (
          <ProviderProgressRow
            periods={sortedDistinctAvailablePeriodLabels}
            row={row}
            organizationId={organizationId}
          />
        )}
        HeaderComponent={() => <ProviderProgressHeader periods={sortedDistinctAvailablePeriodLabels} />}
        data={transformedData}
        page={1}
        pageSize={count}
        noPagination
        isLoading={isLoading || isLoadingPeriods}
        count={count}
        isError={isError}
        emptyState={
          <EmptyState
            iconComponent={WifiOffIcon}
            title={formatMessage({ id: 'schemas.providerProgress.emptySourcingQuestionnaireState.title' })}
            description={formatMessage({
              id: 'schemas.providerProgress.emptySourcingQuestionnaireState.description',
            })}
            sx={{ my: 2 }}
          />
        }
      />
    </ResourceCollectionScene>
  )
}

export default SourcingProviderProgressCollection
