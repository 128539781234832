import ResourceCollectionScene from '@app/src/pages/ResourceCollection'
import ProvidersBaseAssessment from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/ProvidersBaseAssessment'
import DataHubScene from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import EvaluationsCollection from '@app/src/pages/ResourceCollection/Collections/EvaluationsCollection'
import AccessorRequestsScene from '@app/src/pages/ResourceCollection/Collections/ManageRequests/AccessorRequestsScene'
import AccessorResponseScene from '@app/src/pages/ResourceCollection/Collections/ManageRequests/AccessorResponseScene'
import MappingNodeCollection from '@app/src/pages/ResourceCollection/Collections/MappingNodeCollection'
import ActivityOverview from '@app/src/pages/ResourceCollection/Collections/Provider/ProviderActivity/ActivityOverview'
import ProviderContactsOverview from '@app/src/pages/ResourceCollection/Collections/ProviderContactsOverview'
import ProvidersOverview from '@app/src/pages/ResourceCollection/Collections/ProvidersOverview'
import RiskScreeningAllRisksScene from '@app/src/pages/ResourceCollection/Collections/RiskScreening/RiskScreeningAllRisksScene'
import SourcingProviderProgressCollection from '@app/src/pages/ResourceCollection/Collections/SourcingProviderProgressCollection'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Provider } from '@app/src/types/organizations'
import { RiskStatus } from '@app/src/types/resourceExplorer'
import { RelatedContentFilter, Schema } from '@app/src/types/schemas'
import get from 'lodash/get'
import React, { useMemo } from 'react'

export enum AllTabs {
  Contact = 'contact',
  Overview = 'overview',
  Request = 'request',
  Response = 'response',
  ResponseItem = 'responseitem',
  Evaluation = 'evaluation',
  RiskScreening = 'risk-screening',
  ProviderProgress = 'providerProgress',
  MappingNode = 'mapping-node',
  Activity = 'activity',
  BaseAssessment = 'base-assessment',
}

interface ContentProps {
  parentResourceType: string
  provider?: Provider
  activeTabParam: AllTabs
  schema?: Schema
}

const getRelatedContentFilters = (filtersList: RelatedContentFilter[], provider?: Provider): FilterGroup[] => {
  return filtersList?.map(filter => {
    const accessor = filter.accessor
    const name = filter.name
    const operator = filter.operator
    const value = get(provider, accessor)
    return {
      name,
      filters: [
        {
          value,
          operator,
        },
      ],
    }
  })
}

const Content: React.FC<ContentProps> = ({ parentResourceType, schema, provider, activeTabParam }) => {
  const relatedContentFilter = useMemo(
    () => (schema ? getRelatedContentFilters(schema.relatedContentFilters[parentResourceType], provider) : []),
    [schema, parentResourceType, provider],
  )

  if (!provider) {
    return null
  }

  switch (activeTabParam) {
    case AllTabs.Contact:
      return <ProviderContactsOverview />
    case AllTabs.Overview:
      return (
        <ResourceCollectionScene title="" embedded enableScroll>
          <ProvidersOverview provider={provider} />
        </ResourceCollectionScene>
      )
    case AllTabs.BaseAssessment:
      return (
        <ResourceCollectionScene title="" embedded enableScroll>
          <ProvidersBaseAssessment provider={provider} />
        </ResourceCollectionScene>
      )

    case AllTabs.Request:
      return <AccessorRequestsScene embedded relatedContentFilter={relatedContentFilter} />
    case AllTabs.Response:
      return <AccessorResponseScene embedded relatedContentFilter={relatedContentFilter} />
    case AllTabs.ResponseItem:
      return <DataHubScene embedded relatedContentFilter={relatedContentFilter} />
    case AllTabs.Evaluation:
      return <EvaluationsCollection embedded relatedContentFilter={relatedContentFilter} />
    case AllTabs.RiskScreening:
      return (
        <RiskScreeningAllRisksScene
          embedded
          relatedContentFilter={relatedContentFilter}
          riskStatusPreSelectedValues={[RiskStatus.Extreme, RiskStatus.High, RiskStatus.Medium, RiskStatus.Low]}
        />
      )
    case AllTabs.ProviderProgress:
      return (
        <SourcingProviderProgressCollection
          relatedContentFilter={relatedContentFilter}
          supplierId={provider?.id}
          organizationId={provider.organizationId}
        />
      )
    case AllTabs.MappingNode:
      return <MappingNodeCollection embedded relatedContentFilter={relatedContentFilter} />
    case AllTabs.Activity:
      return <ActivityOverview provider={provider} />
    default:
      return null
  }
}

export default Content
