import ChartTypeSelector, {
  ChartType,
} from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { QuestionTypes } from '@app/src/wf-constants'
import React, { useEffect } from 'react'
import { StatisticsForQuestionPerPeriod } from '../../DataHubScene'

interface ChartTypeSelectorProps {
  selectedChartType: ChartType
  setSelectedChartType: React.Dispatch<React.SetStateAction<ChartType>>
  statistics: StatisticsForQuestionPerPeriod
  isSummaryView?: boolean
}

const getPossibleChartTypes = (statistics: StatisticsForQuestionPerPeriod, isSummaryView: boolean) => {
  const barChartTypes = [ChartType.Vertical, ChartType.Horizontal]
  const isSinglePeriod = statistics.resultForPeriods.length === 1

  if (isSummaryView) {
    return { possible: barChartTypes, default: ChartType.Vertical }
  }

  const isNumberQuestion = statistics.questionType.toLowerCase() === QuestionTypes.Number.toLowerCase()
  if (isNumberQuestion && !isSinglePeriod)
    return { possible: [ChartType.Line, ...barChartTypes], default: ChartType.Line }

  const isOptionQuestion = statistics.questionType.toLowerCase() === QuestionTypes.Options.toLowerCase()
  if (!isOptionQuestion) return { possible: barChartTypes, default: ChartType.Vertical }

  if (statistics.isMultiChoice) return { possible: barChartTypes, default: ChartType.Horizontal }

  if (isSinglePeriod) return { possible: [ChartType.Pie, ...barChartTypes], default: ChartType.Pie }

  return { possible: barChartTypes, default: ChartType.Vertical }
}

const DataHubChartTypeSelector: React.FC<ChartTypeSelectorProps> = ({
  selectedChartType,
  setSelectedChartType,
  statistics,
  isSummaryView = false,
}) => {
  const { possible: possibleChartTypes, default: defaultChartType } = getPossibleChartTypes(statistics, isSummaryView)

  useEffect(() => {
    setSelectedChartType(defaultChartType)
  }, [defaultChartType, setSelectedChartType])

  return (
    <ChartTypeSelector
      setSelectedChartType={setSelectedChartType}
      selectedChartType={selectedChartType}
      allowedChartTypes={possibleChartTypes}
    />
  )
}

export default DataHubChartTypeSelector
