import { FileCopyOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { useCreateResource } from '@app/src/api/updateHooks'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import TextField from '@app/src/components/Ui/TextField'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Referral } from '@app/src/types/resourceExplorer'
import { FormData } from './SfdrReportWithoutCompanies'

const GenerateReferralLink = () => {
  const { showSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const { getValues } = useFormContext<FormData>()
  const { mutateAsync, isLoading, isError } = useCreateResource<Partial<Referral>>()
  const [referralCode, setReferralCode] = useState('')
  const ref = useRef<HTMLInputElement>()
  const values = getValues()

  const referralPayload = values.templateIds.map(template => ({
    templateId: template.id,
    periodType: values.dateType,
    periodStartsAt: `${values.dateYear}-01-01 00:00:00`,
    periodEndsAt: `${values.dateYear}-12-31 23:59:59`,
    dueAt: formatDate(values.deadline),
  }))

  useEffect(() => {
    const generateReferral = async () => {
      try {
        const data = await mutateAsync({
          url: endpoints.generateReferralCode,
          body: {
            scheduledRequests: referralPayload,
          },
        })
        if (!isError && data.code) {
          setReferralCode(data.code)
          return
        }
        throw new Error()
      } catch (error) {
        showSnackbar({
          message: formatMessage({ id: 'form.createRequest.sfdr.generateLinkError' }),
          severity: 'error',
        })
      }
    }
    generateReferral()
  }, [])

  const handleCopy = () => {
    if (ref.current) {
      navigator.clipboard.writeText(ref.current.value)
      showSnackbar({ message: formatMessage({ id: 'form.createRequest.sfdr.copyTextSuccess' }), severity: 'success' })
      return
    }
    showSnackbar({
      message: formatMessage({ id: 'form.createRequest.sfdr.copyTextError' }),
      severity: 'error',
    })
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={295}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box bgcolor="grey.100" p={1.5} borderRadius="8px">
      <Button size="large" variant="contained" startIcon={<FileCopyOutlined />} onClick={handleCopy}>
        Copy
      </Button>

      <Box mt={3}>
        <TextField
          inputRef={ref}
          multiline
          fullWidth
          minRows={4}
          defaultValue={`Hi there, hope you’re doing great!

As your investor, we want to make sure that we are all doing our part to contribute to sustainable and responsible business practices. To that end, I would like to introduce you to Worldfavor, the ESG platform that enables us to measure and improve our sustainability performance.

Please help us out by creating an account in Worldfavor and start reporting on the requested ESG data.

Link: ${window.location.origin}?referral=${referralCode}

If you have any questions or concerns, please do not hesitate to contact me.`}
        />
      </Box>
    </Box>
  )
}

export default GenerateReferralLink
