import { Add } from '@mui/icons-material'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import { Chip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FinalRiskRating } from '@app/src/types/categories'
import ButtonCell, { ButtonCellProps } from './ButtonCell'
import ParentCellOrFragment from './ParentCellOrFragment'
import ValueCell, { ValueCellProps } from './ValueCell'

type Props = {
  value?: FinalRiskRating | string
  disableCell?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>
  size?: ButtonCellProps['size']
} & Omit<ValueCellProps, 'value'>

const getRiskRatingColor = (value: Props['value']): string => {
  switch (value) {
    case 'C_Yellow':
      return 'warning.main'

    case 'B_Green':
      return 'info.main'

    case 'D_Orange':
      return 'error.main'

    case 'E_Red':
      return 'semantic.error'

    default:
      return ''
  }
}

export const FinalRiskRatingIcon: React.FC<Pick<Props, 'value'>> = ({ value }) => {
  return value === FinalRiskRating.NotSelected ? null : <StopRoundedIcon sx={{ color: getRiskRatingColor(value) }} />
}

const FinalRiskRatingCell: React.FC<Props> = ({ value, disableCell, size, onClick, ...props }) => {
  const { formatMessage } = useIntl()

  if (value === FinalRiskRating.NotSelected) {
    return onClick ? (
      <ButtonCell
        label={formatMessage({ id: 'general.add' })}
        startIcon={<Add />}
        onClick={onClick}
        disableCell={disableCell}
        size={size}
      />
    ) : (
      <ValueCell
        value={formatMessage({ id: `schemas.provider.finalRiskRatingValues.${value}` })}
        disableCell={disableCell}
        onClick={onClick}
        {...props}
      />
    )
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Chip
        clickable={Boolean(onClick)}
        onClick={onClick}
        sx={{ bgcolor: 'white', borderWidth: 2, borderColor: 'grey.300', '& .MuiChip-label': { paddingLeft: 0 } }}
        variant="outlined"
        size="small"
        icon={<FinalRiskRatingIcon value={value} />}
        label={formatMessage({ id: `schemas.provider.finalRiskRatingValues.${value}` })}
      />
    </ParentCellOrFragment>
  )
}

export default FinalRiskRatingCell
