import { createContext, useContext } from 'react'
import { Provider } from '../types/organizations'

export enum EmailPurpose {
  InitialInvitation = 'InitialInvitation',
  ExtraReminder = 'ExtraReminder',
}

export interface ConnectProvidersModalContextProps {
  isConnectDialogOpen: boolean
  handleCloseConnectDialog: () => void
  handleOpenConnectDialog: (providers: Provider[], emailPurpose: EmailPurpose) => void
  providersToConnect: Provider[]
  emailPurpose: EmailPurpose
  updateProviderToConnect: (provider: Provider) => void
}

const ConnectProvidersModalContext = createContext<undefined | ConnectProvidersModalContextProps>(undefined)

export const useConnectProvidersModal = (): ConnectProvidersModalContextProps => {
  const context = useContext(ConnectProvidersModalContext)
  if (context === undefined) {
    throw new Error('useConnectProvidersModal must be used within ConnectProvidersModalContextProvider')
  }
  return context
}

export default ConnectProvidersModalContext
