import { Components } from '@mui/material'

const MuiSelect: Components['MuiSelect'] = {
  defaultProps: {},
  styleOverrides: {
    select: {
      padding: 0,
    },
  },
}

export default MuiSelect
