import DateDisplay from '@app/src/components/DateDisplay'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import RiskIndexIcon from '@app/src/pages/Configurations/ConfigurationsPages/RiskScreening/RiskIndexIcons/RiskIndexIcon'
import { RiskType } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { OpenInNew } from '@mui/icons-material'
import { Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface RiskIndexInfoDrawerViewProps extends Omit<DrawerViewProps, 'title' | 'buttons'> {
  riskType: RiskType
}

const RiskIndexInfoDrawerView: React.FC<RiskIndexInfoDrawerViewProps> = ({ riskType, ...props }) => {
  const { formatMessage } = useIntl()

  return (
    <DrawerView
      buttons={[
        {
          label: formatMessage({ id: 'schemas.risk.officialWebsite' }),
          variant: 'outlined',
          size: 'large',
          target: '_blank',
          href: formatMessage({ id: `schemas.risk.riskIndexes.${riskType.source}.sourceLink` }),
          startIcon: <OpenInNew />,
        },
      ]}
      title={formatMessage({ id: 'schemas.risk.indexDetail' })}
      {...props}
    >
      <Box px={2}>
        <ListItem disablePadding sx={{ mb: 2 }}>
          <ListItemAvatar>
            <Avatar
              size={AvatarSize.XXL}
              sx={({ palette }) => ({ backgroundColor: 'white', border: `1px solid ${palette.secondary.main}`, mr: 2 })}
            >
              <RiskIndexIcon source={riskType.source} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: `schemas.risk.riskIndexes.${riskType.source}.label` })}
            secondary={formatMessage({ id: `schemas.risk.riskIndexes.${riskType.source}.source` })}
          />
        </ListItem>

        <Typography mb={2}>
          {formatMessage(
            { id: `schemas.risk.riskIndexes.${riskType.source}.sourceDescription` },
            {
              br,
            },
          )}
        </Typography>

        <Typography mb={2}>
          {formatMessage(
            { id: `schemas.risk.riskIndexes.${riskType.source}.description` },
            {
              br,
            },
          )}
        </Typography>

        <Typography variant="subtitle2">{formatMessage({ id: `schemas.risk.riskIndexes.reviewedAt` })}</Typography>

        <Typography variant="body2" color="text.secondary">
          <DateDisplay value={riskType.reviewedAt} />
        </Typography>
      </Box>
    </DrawerView>
  )
}

export default RiskIndexInfoDrawerView
