import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { EmailOutlined } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface SendReminderReviewProps {
  respondersCount: number
  descriptionText: string
}

function SendReminderReview({ respondersCount, descriptionText }: SendReminderReviewProps) {
  const { formatMessage } = useIntl()
  return (
    <Stack spacing={2} textAlign="center" alignItems="center">
      <Avatar variant="circular" size={AvatarSize.XXXL} sx={{ bgcolor: 'grey.100' }}>
        <EmailOutlined sx={{ fontSize: 48, color: 'common.black' }} />
      </Avatar>
      <Typography variant="subtitle1" pt={4}>
        {formatMessage({ id: 'form.requestsReminder.reviewTitle' }, { respondersCount })}
      </Typography>
      <Typography color="textSecondary">{descriptionText}</Typography>
    </Stack>
  )
}

export default SendReminderReview
