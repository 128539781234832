import TextField from '@app/src/components/Ui/TextField'
import { Stack } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { GOAL_TRACKER_QUESTION_KEYS, GoalTrackerFormType } from './CreateGoalTrackerModal'

const TITLE_CHARACTER_LIMIT = 50
const DESCRIPTION_CHARACTER_LIMIT = 100

const WriteGoalSummaryStep = () => {
  const { formatMessage } = useIntl()
  const { watch, setValue, register } = useFormContext()

  const watchTitle: GoalTrackerFormType['title'] = watch(GOAL_TRACKER_QUESTION_KEYS.TITLE)
  const watchDescription: GoalTrackerFormType['description'] = watch(GOAL_TRACKER_QUESTION_KEYS.DESCRIPTION)

  return (
    <Stack spacing={5}>
      <TextField
        name={GOAL_TRACKER_QUESTION_KEYS.TITLE}
        inputRef={register}
        inputProps={{
          maxLength: TITLE_CHARACTER_LIMIT,
        }}
        required
        helperText={`${watchTitle?.length ?? 0}/${TITLE_CHARACTER_LIMIT}`}
        label={formatMessage({ id: 'schemas.goalTracker.goalSummary.titleLabel' })}
        multiline
        onChange={e => {
          if (!e.target.value) {
            setValue(GOAL_TRACKER_QUESTION_KEYS.TITLE, undefined)
          } else {
            setValue(GOAL_TRACKER_QUESTION_KEYS.TITLE, e.target.value)
          }
        }}
        fullWidth
        InputLabelProps={{ shrink: true }}
        minRows={1}
        placeholder={formatMessage({ id: 'schemas.goalTracker.goalSummary.titlePlaceholder' })}
      />

      <TextField
        name={GOAL_TRACKER_QUESTION_KEYS.DESCRIPTION}
        inputRef={register}
        inputProps={{
          maxLength: DESCRIPTION_CHARACTER_LIMIT,
        }}
        helperText={`${watchDescription?.length ?? 0}/${DESCRIPTION_CHARACTER_LIMIT}`}
        label={formatMessage({ id: 'schemas.goalTracker.goalSummary.descriptionLabel' })}
        multiline
        onChange={e => {
          if (!e.target.value) {
            setValue(GOAL_TRACKER_QUESTION_KEYS.DESCRIPTION, undefined)
          } else {
            setValue(GOAL_TRACKER_QUESTION_KEYS.DESCRIPTION, e.target.value)
          }
        }}
        fullWidth
        InputLabelProps={{ shrink: true }}
        minRows={3}
        placeholder={formatMessage({ id: 'schemas.goalTracker.goalSummary.descriptionPlaceholder' })}
      />
    </Stack>
  )
}

export default WriteGoalSummaryStep
