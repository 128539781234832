import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import DiffViewer from '@app/src/components/DiffViewer'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { AuditLog } from '@app/src/types/resourceExplorer'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import AuditLogHeader from './AuditLogHeader'
import AuditLogRow from './AuditLogRow'

export type SelectedItemSnapshots = Pick<AuditLog, 'afterSnapshot' | 'beforeSnapshot'>

const AuditLogConfig: React.FC = () => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const [openSnapshotDrawer, setOpenSnapshotDrawer] = useState(false)
  const [selectedItemSnapshots, setSelectedItemSnapshots] = useState<SelectedItemSnapshots | null>()
  const { auditLogs } = endpoints

  const { items, count, isLoading, isError } = useFetchCollectionWithPost<AuditLog>({
    key: FetchKey.AuditLog,
    endpoint: auditLogs,
    payload: {
      filter: [],
      sort: sorting,
      include: ['creatorOrganization', 'creatorUser', 'creatorUser.image'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  const handleOpenSnapshotDrawer = ({ beforeSnapshot, afterSnapshot }: SelectedItemSnapshots): void => {
    setSelectedItemSnapshots({ beforeSnapshot, afterSnapshot })
    setOpenSnapshotDrawer(true)
  }

  const handleCloseSnapshotDrawer = (): void => {
    setSelectedItemSnapshots(null)
    setOpenSnapshotDrawer(false)
  }

  return (
    <>
      <ConfigurationCollection>
        <Box flexGrow={1} display="flex" flexDirection="column" height={100}>
          <Table<AuditLog>
            HeaderComponent={() => <AuditLogHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
            RowComponent={({ row }) => <AuditLogRow handleOpenSnapshotDrawer={handleOpenSnapshotDrawer} row={row} />}
            data={items}
            count={count}
            isLoading={isLoading}
            isError={isError}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        </Box>
      </ConfigurationCollection>
      <DiffViewer
        open={openSnapshotDrawer}
        closeDrawer={handleCloseSnapshotDrawer}
        oldCode={selectedItemSnapshots?.beforeSnapshot}
        newCode={selectedItemSnapshots?.afterSnapshot}
      />
    </>
  )
}

export default AuditLogConfig
