import { SvgIcon } from '@mui/material'
import React from 'react'

export const WriLogoIcon = () => {
  return (
    <SvgIcon fontSize="large">
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1944_23815)">
          <path d="M9.06855 2.62249L7.27551 4.41553L8.96954 6.10956L10.7626 4.31653L9.06855 2.62249Z" fill="#FDB913" />
          <path d="M21.0637 18.2094L27.5508 24.6965L29.3554 22.8919L22.8683 16.4048L21.0637 18.2094Z" fill="#FDB913" />
          <path
            d="M15.93 0.0390625L7.04712 8.9219L8.83613 10.7109L16.0077 3.55486L18.559 6.10615L20.348 4.31714L16.07 0.0390625H15.93Z"
            fill="#FDB913"
          />
          <path d="M27.55 7.24064L25.8553 8.93408L27.6478 10.7277L29.3424 9.0343L27.55 7.24064Z" fill="#FDB913" />
          <path d="M13.7683 21.026L7.2782 27.5161L9.07123 29.3091L15.5614 22.819L13.7683 21.026Z" fill="#FDB913" />
          <path d="M23.1721 11.609L16.4509 18.3301L18.244 20.1231L24.9651 13.402L23.1721 11.609Z" fill="#FDB913" />
          <path
            d="M21.2504 8.79731L28.4064 15.9689L25.8707 18.5202L27.6597 20.3092L32 15.9689L23.0394 7.0083L21.2504 8.79731Z"
            fill="#FDB913"
          />
          <path d="M4.33843 21.2135L2.6554 22.8965L4.44843 24.6895L6.13146 23.0065L4.33843 21.2135Z" fill="#FDB913" />
          <path d="M22.941 2.609L16.4509 9.09912L18.244 10.8922L24.7341 4.40204L22.941 2.609Z" fill="#FDB913" />
          <path
            d="M0 15.9691L8.96062 24.9298L10.7496 23.1407L3.59358 15.9691L6.14487 13.4023L4.35586 11.6133L0 15.9691Z"
            fill="#FDB913"
          />
          <path d="M13.4503 7.01068L11.6572 8.80371L18.3784 15.5248L20.1714 13.7318L13.4503 7.01068Z" fill="#FDB913" />
          <path d="M23.0334 25.8212L21.2404 27.6143L22.9344 29.3083L24.7274 27.5153L23.0334 25.8212Z" fill="#FDB913" />
          <path d="M4.46064 7.23187L2.6676 9.0249L9.15772 15.515L10.9508 13.722L4.46064 7.23187Z" fill="#FDB913" />
          <path d="M13.6354 16.4164L11.8424 18.2095L18.5635 24.9306L20.3566 23.1376L13.6354 16.4164Z" fill="#FDB913" />
          <path
            d="M16.0078 28.383L13.4565 25.8317L11.652 27.6208L16.0078 31.9611L24.9685 23.0004L23.1794 21.2114L16.0078 28.383Z"
            fill="#FDB913"
          />
          <path d="M13.7654 11.794L7.04431 18.5151L8.83734 20.3082L15.5585 13.587L13.7654 11.794Z" fill="#FDB913" />
        </g>
        <defs>
          <clipPath id="clip0_1944_23815">
            <rect width="32" height="31.9222" fill="white" transform="translate(0 0.0390625)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default WriLogoIcon
