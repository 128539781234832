import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Subscription } from '@app/src/types/resourceExplorer'
import { DEFAULT_TABLE_PAGE_SIZE } from '@app/src/wf-constants'
import ResourceCollectionScene from '../ResourceCollectionScene'
import SubscriptionHeader from './Subscription/SubscriptionHeader'
import SubscriptionRow from './Subscription/SubscriptionRow'

const SubscriptionsCollection = (): JSX.Element => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_TABLE_PAGE_SIZE)
  const { formatMessage } = useIntl()

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Subscription>({
    key: FetchKey.Subscription,
    endpoint: endpoints.subscriptions,
    payload: {
      filter: [],
      include: ['creatorOrganization.image', 'request'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <ResourceCollectionScene title={formatMessage({ id: 'resourceTypes.subscription' })}>
      <Table<Subscription>
        RowComponent={SubscriptionRow}
        HeaderComponent={SubscriptionHeader}
        count={count}
        data={items}
        isLoading={isLoading || isFetching}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollectionScene>
  )
}

export default SubscriptionsCollection
