import SupplierStatisticsFilterContext from '@app/src/context/CompaniesStatisticsFilterContext'
import React, { useState } from 'react'

export enum Ownership {
  All = 'all',
  MyCompanies = 'myCompanies',
}

interface Props {
  children: JSX.Element
}

const CompaniesStatisticsFilterContextProvider = ({ children }: Props): JSX.Element => {
  const [selectedOwnership, setSelectedOwnership] = useState<Ownership>(Ownership.All)
  return (
    <SupplierStatisticsFilterContext.Provider value={{ selectedOwnership, setSelectedOwnership }}>
      {children}
    </SupplierStatisticsFilterContext.Provider>
  )
}

export default CompaniesStatisticsFilterContextProvider
