import { TableRow } from '@mui/material'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ReviewTableHeader: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'form.createRequest.tableHeaders.period' })} />
      <TableHeaderCell label={formatMessage({ id: 'form.createRequest.tableHeaders.companies' })} />
      <TableHeaderCell label={formatMessage({ id: 'form.createRequest.tableHeaders.deadline' })} />
    </TableRow>
  )
}

export default ReviewTableHeader
