import GraphHoverDialog from '@app/src/components/GraphHoverDialog'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import StatisticsBar from '@app/src/pages/Dashboards/StatisticsBar'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { QuestionnaireRequestProgressStatistics } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { generatePath, useHistory } from 'react-router'
import { ViewTypeName } from '../ManageRequestsScene'
import OverviewRequestProgressDialog from './OverviewRequestProgressDialog'

interface TopicProgressSectionProps {
  statistics: QuestionnaireRequestProgressStatistics
  userFilters: FilterGroup[]
}

const TopicProgressSection: React.FC<TopicProgressSectionProps> = ({ statistics, userFilters }) => {
  const { palette } = useTheme()
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  return (
    <Box py={1}>
      <Grid container>
        <Grid item xs={5} display="flex" alignItems="center">
          <Typography variant="caption">{statistics.templateTitle}</Typography>
        </Grid>
        <Grid item xs={7}>
          <GraphHoverDialog
            content={
              <OverviewRequestProgressDialog
                requestedCount={statistics.totalNumberOfRequestsSent - statistics.numberOfRespondedRequests}
                submittedCount={statistics.numberOfRespondedRequests}
                requestName={statistics.templateTitle}
                templateId={statistics.templateId}
                userFilters={userFilters}
              />
            }
          >
            <span>
              <StatisticsBar
                value={statistics.numberOfRespondedRequests}
                max={statistics.totalNumberOfRequestsSent}
                color={palette.visualization[1]}
                percent
                barWidth={32}
                showBackground
                onBarClick={() =>
                  history.push(
                    stringifyQueryFilters({
                      url: generatePath(paths.manageRequest, {
                        view: ViewTypeName.Requests,
                      }),
                      queryParams: {
                        filters: [
                          {
                            name: 'template.id',
                            value: statistics.templateId,
                            operator: Operators.EqualTo,
                          },
                        ],
                      },
                      includeCurrentFilters: true,
                    }),
                  )
                }
              />
            </span>
          </GraphHoverDialog>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TopicProgressSection
