import { OrganizationRiskSetting } from '@app/src/types/resourceExplorer'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import RiskCardMethodology from './RiskCardMethodology'

interface PotentialRiskMethodologyProps {
  assessmentRiskSettings?: OrganizationRiskSetting[]
}

const PotentialRiskMethodology: React.FC<PotentialRiskMethodologyProps> = ({ assessmentRiskSettings }) => {
  const { formatMessage } = useIntl()
  return (
    <Box mt={4}>
      <Typography variant="h5" gutterBottom>
        {formatMessage({ id: 'assessments.methodology.potentialRisksMethodology' })}
      </Typography>
      <Typography variant="body1" paragraph>
        {formatMessage({ id: 'assessments.methodology.potentialRisksMethodologyDescription' })}
      </Typography>
      <RiskCardMethodology risks={assessmentRiskSettings} />
    </Box>
  )
}

export default PotentialRiskMethodology
