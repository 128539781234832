import { toSnakeCase } from '@app/src/utils/helpers'
import { Grid } from '@mui/material'
import React from 'react'
import RequestTypeCard, { RequestTypeCardProps } from './RequestTypeCard'

type RequestTypeSelectionProps = {
  requestTypeCards: RequestTypeCardProps[]
}

const RequestTypeSelection: React.FC<RequestTypeSelectionProps> = ({ requestTypeCards }) => {
  return (
    <Grid container spacing={2}>
      {requestTypeCards.map(requestTypeCard => (
        <Grid
          item
          xs={4}
          key={requestTypeCard.title}
          data-testid={toSnakeCase(requestTypeCard.title) + '-request-type'}
        >
          <RequestTypeCard {...requestTypeCard} />
        </Grid>
      ))}
    </Grid>
  )
}

export default RequestTypeSelection
