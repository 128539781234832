import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Alert, Box, Button, Collapse, Link } from '@mui/material'
import React, { PropsWithChildren, useState } from 'react'
import { useIntl } from 'react-intl'

type AdvancedFeaturesProps = {
  hideWarningBanner?: boolean
}

const AdvancedFeatures: React.FC<PropsWithChildren<AdvancedFeaturesProps>> = ({ children, hideWarningBanner }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { formatMessage } = useIntl()

  return (
    <>
      <Button
        onClick={() => setIsExpanded(isExpanded => !isExpanded)}
        startIcon={
          isExpanded ? (
            <ExpandLessIcon color="inherit" fontSize="small" />
          ) : (
            <ExpandMoreIcon color="inherit" fontSize="small" />
          )
        }
      >
        {formatMessage({ id: 'templateBuilder.advanced.title' })}
      </Button>
      <Collapse in={isExpanded}>
        <Box mt={1} flexGrow={1}>
          {!hideWarningBanner && (
            <Alert severity="warning" variant="filled">
              {formatMessage(
                { id: 'templateBuilder.advanced.warning' },
                {
                  link: (chunks: React.ReactNode) => (
                    <Link
                      href="https://www.notion.so/worldfavor/Template-builder-cc3d4d7474fe441390a5329dc753dae7?pvs=4#05ed4ba98b944bfa8b951603fb16a631"
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="always"
                    >
                      {chunks}
                    </Link>
                  ),
                },
              )}
            </Alert>
          )}

          <Box mt={2} width="100%">
            {children}
          </Box>
        </Box>
      </Collapse>
    </>
  )
}

export default AdvancedFeatures
