import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import ProviderContactCard from '@app/src/components/ProviderContactCard'
import { getOrganizationDisplayName } from '@app/src/components/Table/Cells/CompanyCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Provider } from '@app/src/types/organizations'
import { Divider, Stack, Typography } from '@mui/material'
import React from 'react'

export type DrawerViewProviderProps = {
  provider: Provider
} & Omit<DrawerViewProps, 'title'>

const DrawerViewProvider: React.FC<DrawerViewProviderProps> = ({ provider, ...props }) => {
  const providerName = getOrganizationDisplayName(provider)
  return (
    <DrawerView title="" {...props}>
      <Stack py={2} flexGrow={1}>
        <Stack alignItems="center">
          <CompanyAvatar
            organizationName={providerName}
            imageUrl={provider?.organization?.image?.url}
            size={AvatarSize.XXL}
          />
          <Typography variant="h5" mt={2} color="textPrimary">
            {providerName}
          </Typography>
        </Stack>

        <Stack>
          <Divider sx={{ m: 2 }} />
          <ProviderContactCard provider={provider} />
        </Stack>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewProvider
