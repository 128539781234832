import { Flag, FlagOutlined } from '@mui/icons-material'
import { alpha, Chip } from '@mui/material'
import * as CSS from 'csstype'
import React from 'react'

interface FlagIndicatorProps {
  label: number
  bgcolor: CSS.Property.BackgroundColor
  disabled?: boolean
  focusEffect?: boolean
}

export const FlagIndicator: React.FC<FlagIndicatorProps> = ({ bgcolor, label, disabled, focusEffect }) => {
  return (
    <Chip
      variant="outlined"
      label={label}
      size="medium"
      disabled={disabled}
      icon={React.createElement(focusEffect ? Flag : FlagOutlined, {
        color: 'primary',
        sx: {
          bgcolor: disabled ? 'white' : bgcolor,
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          border: disabled ? `2px solid ${alpha('#000', 0.12)}` : `2px solid transparent`,
          borderRight: 'none',
          '&.MuiChip-icon': {
            marginLeft: 0,
            marginRight: 0,
            paddingRight: '2px',
          },
        },
      })}
      sx={{
        height: 'auto',
        bgcolor: 'white',
        border: 'none',
        cursor: 'inherit',

        '& .MuiChip-label': {
          lineHeight: '20px',
          border: `2px solid ${alpha('#000', 0.12)}`,
          borderRadius: 1,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 'none',
          px: 0.5,
          minWidth: 24,
          textAlign: 'center',
        },
      }}
    />
  )
}
