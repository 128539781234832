import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport, { getExportColumns } from '@app/src/components/Drawer/Views/DrawerViewExport'
import DrawerViewGuidanceReport, { emptyPayload } from '@app/src/components/Drawer/Views/DrawerViewGuidanceReport'
import Table from '@app/src/components/Table'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import DataHubFiltersAllAnswers from '@app/src/pages/ResourceCollection/Filters/DataHubFiltersAllAnswers'
import {
  FilterGroup,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import getResponseItemSchema from '@app/src/schemas/responseItem'
import { ExportTemplate } from '@app/src/types/export'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { ResourceTypes } from '@app/src/wf-constants'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ResponseItemCompanyHeader from './ResponseItemCompanyHeader'
import ResponseItemCompanyRow from './ResponseItemCompanyRow'
import ResponseItemHeader from './ResponseItemHeader'
import ResponseItemRow from './ResponseItemRow'

interface DataHubAnswersTableProps {
  relatedContentFilter?: FilterGroup[]
  allowedFilters: string[]
  userFilters: FilterGroup[]
  tabs?: ResourceCollectionSceneProps['tabs']
  embedded?: ResourceCollectionSceneProps['embedded']
  showCompanyCell?: boolean
}

const DataHubAnswersTable: React.FC<DataHubAnswersTableProps> = ({
  relatedContentFilter = [],
  allowedFilters,
  userFilters,
  tabs,
  embedded,
  showCompanyCell,
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { openDrawer } = useDrawer()
  const { solution } = useAuthentication().scope
  const responseItemSchema = getResponseItemSchema(solution)

  const [onlyLatest, setOnlyLatest] = useState(true)
  const [allowGuidanceReport, setAllowGuidanceReport] = useState(false)

  const basePayload = {
    filter: relatedContentFilter,
    sort: sorting,
    include: [
      'requestItem',
      'response.request',
      'verifications',
      'creatorOrganization',
      'response.request.creatorOrganization',
      'response.request.responderOrganization',
      'response.request.subscriptions.target',
      'response.request.subscriptions.target.country.name',
      'requestItem.unit',
      'requestItem.questionType',
      'requestItem.section',
      'files',
      'flagRule.suggestedRiskGuidance',
      'fileScreeningResults',
      'response.request.target.organization',
    ],
  }

  const rawExportPayload = {
    ...basePayload,
    include: [
      ...basePayload.include,
      'response.request.target',
      'response.request.subscriptions.target.categoryOptions',
      'response.verifications',
      'response.request.subscriptions.target.country.name',
    ],
  }

  const payload = {
    ...basePayload,
    filter: [...basePayload.filter, ...userFilters, ...insertIf(onlyLatest, RESPONSE_ITEM_LATEST_SUBMITTED_FILTER)],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<ResponseItem>({
    key: FetchKey.Answer,
    endpoint: endpoints.responseItemsCollection,
    payload,
  })

  // Only after we've fetched items we call exportTemplateCollection to determine the value for allowGuidanceReport
  const { data: exportTemplates } = useFetchCollectionWithPost<ExportTemplate>({
    key: FetchKey.ExportTemplate,
    endpoint: endpoints.exportTemplateCollection,
    payload: emptyPayload,
  })

  useEffect(() => {
    if (exportTemplates?.items.length) {
      setAllowGuidanceReport(true)
    }
  }, [exportTemplates])

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'schemas.responseItem.responseItems' })}
        actionButtons={[
          ...insertIf<ActionButton>(allowGuidanceReport, {
            label: formatMessage({ id: 'resourceCollections.general.guidanceReport' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewGuidanceReport
                  resourceType={ResourceTypes.ResponseItem}
                  count={count}
                  rawExportPayload={rawExportPayload}
                  userFilter={userFilters}
                  schema={responseItemSchema}
                  onlyLatest={onlyLatest}
                />,
              ),
            disabled: isLoading || count === 0,
          }),
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Answer}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={getExportColumns(responseItemSchema)}
                  rawExportPayload={rawExportPayload}
                  onlyLatest={onlyLatest}
                />,
              ),
            disabled: isLoading || count === 0,
          },
        ]}
        tabs={tabs}
        filter={
          <DataHubFiltersAllAnswers
            implicitFilters={relatedContentFilter}
            allowedFilters={allowedFilters}
            onlyLatest={onlyLatest}
            setOnlyLatest={setOnlyLatest}
          />
        }
        embedded={embedded}
      >
        <Table<ResponseItem>
          RowComponent={showCompanyCell ? ResponseItemCompanyRow : ResponseItemRow}
          HeaderComponent={() =>
            showCompanyCell ? (
              <ResponseItemCompanyHeader toggleSorting={toggleSorting} activeSorting={sorting} />
            ) : (
              <ResponseItemHeader toggleSorting={toggleSorting} activeSorting={sorting} />
            )
          }
          data={items}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={1}
        />
      </ResourceCollectionScene>
    </>
  )
}

export default DataHubAnswersTable
