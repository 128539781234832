import { Components } from '@mui/material'

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  defaultProps: {
    slotProps: {
      popper: {
        sx: {
          zIndex: 1400,
        },
      },
    },
  },
}

export default MuiAutocomplete
