import React from 'react'

const RegulationsIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.657 2.642c-2.762-1.058-5.518-.328-8.183.379-2.893.767-5.63 1.492-8.389.122v-.995c.437-.147.752-.56.752-1.045C4.837.495 4.342 0 3.734 0a1.104 1.104 0 0 0-.352 2.148v21.5a.352.352 0 1 0 .703 0V3.92c2.893 1.285 5.776.522 8.57-.219 2.674-.708 5.2-1.378 7.75-.402.157.06.262.212.262.378v10.961c0 .005 0 .012-.01.018a.021.021 0 0 1-.021.003c-2.755-1.046-5.503-.318-8.162.387-2.464.653-5.472 1.41-8.472-.045-.692-.335-.381.1-.434.287a.352.352 0 0 0 .242.434c.776.22 1.192.779 3.655.915 1.753 0 3.491-.461 5.19-.911 2.668-.708 5.188-1.375 7.731-.41a.726.726 0 0 0 .984-.678V3.676c0-.455-.287-.871-.713-1.034ZM3.734.703a.4.4 0 1 1-.001.8.4.4 0 0 1 0-.8Z"
        fill="#131315"
      />
    </svg>
  )
}

export default RegulationsIcon
