import { Box } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import React, { useState } from 'react'
import { SortOrder } from '@app/src/types/filter'
import { User } from '@app/src/types/resourceExplorer'
import { DEFAULT_TABLE_PAGE_SIZE } from '@app/src/wf-constants'
import ColleagueHeader from './ColleagueHeader'
import ColleagueRow from './ColleagueRow'

const ColleaguesTable: React.FC = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_TABLE_PAGE_SIZE)

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<User>({
    key: FetchKey.Colleague,
    endpoint: endpoints.colleagues,
    payload: {
      filter: [],
      include: ['*'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
      sort: {
        order: SortOrder.ASCENDING,
        target: 'name',
      },
    },
  })

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" height={100}>
      <Table<User>
        HeaderComponent={ColleagueHeader}
        RowComponent={ColleagueRow}
        count={count}
        data={items}
        isLoading={isLoading || isFetching}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </Box>
  )
}

export default ColleaguesTable
