import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import DatePicker from '@app/src/components/Form/DatePicker'
import { useAutocompleteStyles } from '@app/src/components/Form/Select/SimpleSelect'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Box, Button, Chip, Grid, Tooltip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import AllFiltersButton from './AllFiltersButton'
import RangeFilter from './RangeFilter'
import StatusOption from './StatusOption'

const ProviderRequestsFilters = ({ allowedFilters, resetPage, implicitFilters }: FiltersProps): JSX.Element => {
  const autocompleteClasses = useAutocompleteStyles()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <Grid container spacing={1}>
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item md={4}>
                <Filter name="template.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.inquiryWithFacets,
                        facetsParam: [{ name: 'template.title' }],
                      }}
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'schemas.filter.requestName' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={2}>
                <Filter name="status" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.inquiryWithFacets,
                        facetsParam: [{ name: filterName }],
                      }}
                      renderOption={(props, option, { selected }): JSX.Element => (
                        <StatusOption key={option.label} props={props} option={option} selected={selected} />
                      )}
                      renderTags={(value, getTagProps) =>
                        value?.map(
                          (option, index) =>
                            option && (
                              <Tooltip
                                key={option?.value?.toString() || index}
                                title={formatMessage({ id: `reporting.statuses.transparency.${option.label}` })}
                                classes={{ tooltipPlacementBottom: autocompleteClasses.tooltip }}
                                arrow
                              >
                                <Chip
                                  label={formatMessage({ id: `reporting.statuses.transparency.${option.label}` })}
                                  size="small"
                                  {...getTagProps({ index })}
                                />
                              </Tooltip>
                            ),
                        )
                      }
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.filter.IncludeRequestStatus' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={3}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.requests' })}>
                              <Filter name="template.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'template.title' }],
                                    }}
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.requestName' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="status" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: filterName }],
                                    }}
                                    renderOption={(props, option, { selected }): JSX.Element => (
                                      <StatusOption
                                        key={option.label}
                                        props={props}
                                        option={option}
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Tooltip
                                              key={option?.value?.toString() || index}
                                              title={formatMessage({
                                                id: `reporting.statuses.transparency.${option.label}`,
                                              })}
                                              classes={{ tooltipPlacementBottom: autocompleteClasses.tooltip }}
                                              arrow
                                            >
                                              <Chip
                                                label={formatMessage({
                                                  id: `reporting.statuses.transparency.${option.label}`,
                                                })}
                                                size="small"
                                                {...getTagProps({ index })}
                                              />
                                            </Tooltip>
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.IncludeRequestStatus' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.requestInformation' })}>
                              <Filter name="creatorOrganization.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'creatorOrganization.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.request.sender' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.requestVerification' })}>
                              <Filter name="request.responseExternalStatus" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: filterName }],
                                    }}
                                    renderOption={(props, option, { selected }): JSX.Element => (
                                      <StatusOption
                                        key={option.label}
                                        props={props}
                                        option={option}
                                        selected={selected}
                                        verification
                                      />
                                    )}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.response.externalVerificationStatus' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.requestTimeline' })}>
                              <Filter name="deadline" operator={Operators.LowerThanOrEqual}>
                                {({ value, onChange }) => (
                                  <DatePicker
                                    value={[value].flat()?.[0]}
                                    onChange={onChange}
                                    label={formatMessage({ id: 'schemas.request.deadline' })}
                                    slotProps={{ textField: { size: 'small' } }}
                                  />
                                )}
                              </Filter>
                              <RangeFilter
                                uniqueIdFrom="activatedFrom"
                                uniqueIdTo="activatedTo"
                                name="activatedAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.request.transparencyActivatedAtFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.request.transparencyActivatedAtTo' })}
                              />
                            </FilterSection>
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
              <Filter name="creatorUserId" operator={Operators.NotEqualTo}>
                {({ value, onChange }) => (
                  <>
                    {value && (
                      <Grid item xs={12}>
                        <Chip
                          label={formatMessage({ id: 'reporting.statuses.Requested' })}
                          onDelete={() => onChange(null)}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Filter>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default ProviderRequestsFilters
