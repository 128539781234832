import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import Dialog from '@app/src/components/Dialog'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { Request, SendRequestResult } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import ConfigDialogContent from './ConfigDialogContent'

type ConfigDialogProps = {
  request: Request
  isOpen: boolean
  closeDialog: () => void
}

export type EdciAccessorProviderConfig = {
  id: number
  createdAt: string
  accessorOrganizationId: number
  providerOrganizationId: number
  year: string
  companyId: string
  fundId: string
  percentageGpOwnership: number
  percentageFundOwnership: number
  initialInvestmentYear: string
  companyStructure: string
  growthStage: string
}

export type FormData = Omit<EdciAccessorProviderConfig, 'id' | 'createdAt' | 'accessorOrganizationId'>

const ConfigDialog: React.FC<ConfigDialogProps> = ({ request, closeDialog, isOpen }) => {
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { mutateAsync: createEdciConfiguration, isLoading: isUpdating } = useCreateResource<
    SendRequestResult,
    unknown
  >()
  const { showErrorNotification } = useErrorNotification()
  const formMethods = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { data, isLoading } = useFetchResource<EdciAccessorProviderConfig[]>({
    endpoint: endpoints.edciProviderConfiguration(request.responderOrganizationId),
    key: [FetchKey.EdciProviderConfigs, request.responderOrganizationId],
    options: {
      enabled: Boolean(isOpen && request.responderOrganizationId),
    },
  })

  const currentConfig = useMemo(() => data?.find(item => item.year === request.periodName), [data])

  const handleSubmitEdciConfig = async (values: FormData) => {
    const body = {
      id: currentConfig?.id,
      providerOrganizationId: request.responderOrganizationId,
      year: request.periodName,
      companyId: values.companyId,
      fundId: values.fundId,
      percentageGpOwnership: Number.isInteger(Number(values.percentageGpOwnership))
        ? Number(values.percentageGpOwnership) / 100
        : Number(values.percentageGpOwnership),
      percentageFundOwnership: Number.isInteger(Number(values.percentageFundOwnership))
        ? Number(values.percentageFundOwnership) / 100
        : Number(values.percentageFundOwnership),
      initialInvestmentYear: values.initialInvestmentYear.toString(),
      companyStructure: values.companyStructure,
      growthStage: values.growthStage,
    }

    await createEdciConfiguration(
      { url: endpoints.saveEdciProviderConfiguration, body },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([FetchKey.EdciProviderConfigs, request.responderOrganizationId])

          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceSave' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          closeDialog()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  useEffect(() => {
    if (currentConfig) {
      formMethods.reset(currentConfig)
    }
  }, [currentConfig])

  return (
    <FormProvider {...formMethods}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={closeDialog}
        title={formatMessage({ id: 'form.createRequest.edci.updateDialog.title' })}
        onFormSubmit={formMethods.handleSubmit(handleSubmitEdciConfig)}
        content={<ConfigDialogContent isLoading={isLoading} />}
        buttons={[
          {
            label: formatMessage({ id: 'general.close' }),
            variant: 'outlined',
            onClick: closeDialog,
            disabled: isUpdating,
          },
          {
            label: formatMessage({ id: 'general.update' }),
            variant: 'contained',
            type: 'submit',
            loading: isUpdating,
          },
        ]}
      />
    </FormProvider>
  )
}

export default ConfigDialog
