import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { usePatchUpdateResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { Organization } from '@app/src/types/organizations'
import { NotificationSeverity } from '@app/src/wf-constants'
import { BusinessOutlined } from '@mui/icons-material'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import OrganizationInfoForm from './OrganizationInfoForm'

function OrganizationInfoConfig({ organization }: { organization: Organization }) {
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const [isEditing, setIsEditing] = useState(false)
  const confirm = useConfirm()
  const formMethods = useForm<Organization & { imageUrl: string }>({
    defaultValues: { ...organization, imageUrl: organization?.image?.url },
  })

  const {
    handleSubmit,
    watch,
    setError,
    formState: { isDirty },
  } = formMethods

  const locationCountryCodeWatch = watch('locations[0].countryCode')

  const { mutate: updateOrganization, isLoading: isUpdatingOrganization } = usePatchUpdateResource<
    Organization,
    Partial<Organization>
  >({
    options: {
      onSuccess: () => {
        showSnackbar({
          message: formatMessage({ id: 'schemas.organization.update' }),
          severity: NotificationSeverity.success,
        })
        queryClient.invalidateQueries(FetchKey.GetMyOrganization)
        queryClient.invalidateQueries(FetchKey.Account)
        queryClient.invalidateQueries(FetchKey.AccessibleOrganizations)
        setIsEditing(false)
      },

      onError: error => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const onSubmit = async (values: Partial<Organization>) => {
    if (!locationCountryCodeWatch) {
      setError('locations[0].address', {
        type: 'manual',
        message: formatMessage({ id: 'claim.createClaim.orgInfoStep.addressErrorMessage' }),
      })
      return
    }

    const payload = {
      ...values,
      id: organization.id,
    }

    updateOrganization({
      url: endpoints.updateOwnOrganization,
      body: payload,
    })
  }

  const cancelEdit = () => {
    if (isDirty) {
      confirm({
        hideCancelButton: false,
        confirmationText: formatMessage({ id: 'general.confirm' }),
        confirmationButtonProps: { variant: 'text' },
        cancellationButtonProps: { variant: 'text' },
        title: formatMessage({ id: 'general.youHaveUnsavedChanges' }),
        content: formatMessage({ id: 'general.exitWithoutSaving' }),
      })
        .then(() => {
          setIsEditing(false)
        })
        .catch(() => {
          // absorb error and stay on page
        })
    } else {
      setIsEditing(false)
    }
  }

  const getButtons = (): ActionButton[] => {
    if (isEditing) {
      return [
        {
          label: formatMessage({ id: 'general.cancel' }),
          variant: 'text',
          size: 'large',
          onClick: cancelEdit,
          loading: isUpdatingOrganization,
        },
        {
          label: formatMessage({ id: 'general.save' }),
          variant: 'contained',
          size: 'large',
          onClick: handleSubmit(onSubmit),
          loading: isUpdatingOrganization,
        },
      ]
    }

    return [
      {
        label: formatMessage({ id: 'general.edit' }),
        variant: 'contained',
        size: 'large',
        onClick: () => {
          setIsEditing(true)
        },
      },
    ]
  }

  return (
    <ConfigurationCollection actionButtons={getButtons()}>
      <FormProvider {...formMethods}>
        <Box bgcolor="common.white" mr={4} p={3} borderRadius={1}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              {isEditing ? (
                <OrganizationInfoForm
                  organization={organization}
                  cancelEdit={cancelEdit}
                  isUpdatingOrganization={isUpdatingOrganization}
                  onSubmit={onSubmit}
                />
              ) : (
                <Stack spacing={3}>
                  <Stack direction="row" spacing={2}>
                    <Avatar size={AvatarSize.XL} variant="rounded" src={organization.image?.url}>
                      <BusinessOutlined />
                    </Avatar>
                    <Stack>
                      <Typography variant="body2">
                        {`${formatMessage({ id: 'general.Organization' })} ${formatMessage({
                          id: 'schemas.organization.name',
                        }).toLowerCase()}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {organization.name}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Typography variant="body2">{formatMessage({ id: 'schemas.organization.address' })}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {organization.locations?.[0]?.address || '-'}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">
                      {formatMessage({ id: 'schemas.organization.websiteAddress' })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {organization.websiteAddress}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">{formatMessage({ id: 'schemas.organization.industry' })}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {organization.industryNaceCode?.description || '-'}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">{formatMessage({ id: 'schemas.organization.subIndustry' })}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {organization.subIndustryNaceCode?.description || '-'}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">{formatMessage({ id: 'schemas.organization.employees' })}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {organization?.companySizeRange
                        ? formatMessage({ id: `claim.companySize.${organization.companySizeRange}` })
                        : '-'}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">{formatMessage({ id: 'schemas.organization.revenue' })}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {organization?.revenueRange
                        ? formatMessage({ id: `claim.companyRevenue.${organization.revenueRange}` })
                        : '-'}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">{formatMessage({ id: 'schemas.organization.vatNumber' })}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {organization.vatNumber || '-'}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">
                      {formatMessage({ id: 'schemas.organization.registrationNumber' })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {organization.registrationNumber || '-'}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </ConfigurationCollection>
  )
}

export default OrganizationInfoConfig
