import { AutocompleteRenderOptionState } from '@mui/material'
import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import React from 'react'
import { AllStatuses } from '@app/src/types/resourceExplorer'
import { FacetItem } from '..'

type StatusOptionProps = {
  props: React.HTMLAttributes<HTMLLIElement>
  option: FacetItem
  selected: AutocompleteRenderOptionState['selected']
  verification?: boolean
}

const StatusOption: React.FC<StatusOptionProps> = ({ props, option, selected, verification }) => {
  return (
    <li {...props}>
      <OptionIcon selected={selected} multiple />
      <StatusCell value={option.label as AllStatuses} verification={verification} disableCell />
      <OptionAdornment option={option} />
    </li>
  )
}

export default StatusOption
