import AssessmentCell from '@app/src/components/Table/Cells/AssessmentCell'
import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import { StructuredProviderAssessmentData } from '@app/src/pages/Assessments/useOrganizedAssessmentData'
import { Provider } from '@app/src/types/organizations'
import { TableRow } from '@mui/material'
import React from 'react'

interface AssessmentCompareRowProps {
  row: Provider
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => void
  selectedProviders: Array<number>
  providerAssessmentData: StructuredProviderAssessmentData[]
}

export const AssessmentCompareRow: React.FC<AssessmentCompareRowProps> = ({
  row: provider,
  onCheckboxChange,
  selectedProviders,
  providerAssessmentData,
}) => {
  const isChecked = Boolean(selectedProviders?.some(selectedProviderId => selectedProviderId === provider.id))
  const assessmentByProvider = providerAssessmentData.find(pr => pr.provider === provider)?.assessments

  return (
    <TableRow>
      <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, provider)} />
      <CompanyCell company={provider} drawer />
      {assessmentByProvider?.map(assessments => (
        <AssessmentCell key={assessments.key} assessment={assessments.value} />
      ))}
    </TableRow>
  )
}
export default AssessmentCompareRow
