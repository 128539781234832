import React from 'react'

const LeftFrontBlob = () => {
  return (
    <svg width="164" height="47" viewBox="0 0 164 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M115.079 33.9453C105.569 -6.28561 0 -23.8467 0 56.6151C46.708 76.1984 144.055 111.853 159.779 97.8038C179.435 80.2427 124.59 74.1762 115.079 33.9453Z"
        fill="#2C5D55"
      />
    </svg>
  )
}

export default LeftFrontBlob
