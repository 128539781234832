import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import TextField from '@app/src/components/Ui/TextField'
import { useAccount } from '@app/src/context/AccountContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { EmailPurpose, useConnectProvidersModal } from '@app/src/context/ConnectProvidersModalContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Provider } from '@app/src/types/organizations'
import { ReferralContact } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { EMAIL_REGEX } from '@app/src/wf-constants/validationRegexes'
import { MailOutline } from '@mui/icons-material'
import { Stack } from '@mui/material'
import * as Sentry from '@sentry/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../DrawerContext'
import DrawerView from '../DrawerView'
import DrawerViewLinkProvider from './DrawerViewLinkProvider'

interface DrawerViewCreateReferralContactProps {
  provider: Provider
  onlySave?: boolean
}

enum SubmitActions {
  Save = 'save',
  ContinueToInvite = 'continueToInvite',
}

const DrawerViewCreateReferralContact: React.FC<DrawerViewCreateReferralContactProps> = ({
  provider,
  onlySave = false,
}) => {
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onChange' })
  const { formatMessage } = useIntl()
  const { openDrawer, closeDrawer } = useDrawer()
  const { mutateAsync, isLoading: isSavingReferralContact } = useCreateResource<ReferralContact>()
  const { handleOpenConnectDialog, updateProviderToConnect } = useConnectProvidersModal()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { account } = useAccount()
  const { trackEvent } = useAmplitude()
  const [submitAction, setSubmitAction] = useState<SubmitActions>(SubmitActions.Save)

  const onSubmit = async (data: ReferralContact) => {
    const body = {
      ...data,
      providerId: provider.id,
    }
    await mutateAsync(
      {
        url: endpoints.saveReferralContact,
        body,
      },
      {
        onSuccess: newReferralContact => {
          showSnackbar({
            message: formatMessage({ id: 'referral.contact.addContactPersonSuccess' }),
            severity: 'success',
          })
          trackEvent({
            name: AmplitudeTrackingEvents.Onboarding.SendInvite.ReferralContactAdded,
            eventProps: {
              provider_id: String(provider.id),
            },
          })
          queryClient.invalidateQueries(FetchKey.ReferralContactCollection)
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          queryClient.invalidateQueries(FetchKey.ProviderReferralContactFacets)

          if (onlySave) {
            updateProviderToConnect({ ...provider, referralContact: newReferralContact })
            return closeDrawer()
          }
          if (submitAction === SubmitActions.Save) {
            return openDrawer(<DrawerViewLinkProvider provider={provider} />)
          }
          handleOpenConnectDialog(
            [{ ...provider, referralContact: newReferralContact }],
            EmailPurpose.InitialInvitation,
          )
          closeDrawer()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
          Sentry.captureException(error, {
            tags: { event: 'Error creating referral contact' },
            user: { id: String(account?.user?.id) },
            extra: { errorDetails: error.message, providerId: String(provider.id) },
          })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'referral.sendInvite.addContact' })}
      subTitle={provider.name}
      showBackButton
      stackButtons
      onFormSubmit={handleSubmit(onSubmit)}
      buttons={[
        {
          label: formatMessage({ id: 'referral.sendInvite.save' }),
          variant: onlySave ? 'contained' : 'text',
          color: 'primary',
          onClick: () => setSubmitAction(SubmitActions.Save),
          type: 'submit',
          loading: isSavingReferralContact,
        },
        ...insertIf<ActionButton>(!onlySave, {
          label: formatMessage({ id: 'referral.sendInvite.continueToInvite' }),
          startIcon: <MailOutline />,
          variant: 'contained',
          color: 'primary',
          onClick: () => setSubmitAction(SubmitActions.ContinueToInvite),
          type: 'submit',
          loading: isSavingReferralContact,
        }),
      ]}
    >
      <Stack spacing={2} p={2}>
        <TextField
          hoveringLabel
          placeholder="E.g. John"
          label={formatMessage({ id: 'schemas.user.givenName' })}
          name="firstName"
          error={Boolean(errors?.firstName)}
          helperText={errors?.firstName?.message}
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
        />
        <TextField
          hoveringLabel
          placeholder="E.g. Doe"
          label={formatMessage({ id: 'schemas.user.familyName' })}
          name="lastName"
          error={Boolean(errors?.lastName)}
          helperText={errors?.lastName?.message}
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
        />
        <TextField
          hoveringLabel
          placeholder="Add contact person email"
          label={formatMessage({ id: 'schemas.user.email' })}
          name="email"
          error={Boolean(errors?.email)}
          helperText={errors?.email?.message}
          inputRef={register({
            required: formatMessage({ id: 'form.validation.required' }),
            pattern: {
              value: EMAIL_REGEX,
              message: formatMessage({ id: 'form.validation.emailNotValid' }),
            },
          })}
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewCreateReferralContact
