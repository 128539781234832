import ControlledLocationField, { SearchResult } from '@app/src/components/Form/ControlledLocationField'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { validURL } from '@app/src/utils/helpers'
import { ArrowBack, CheckCircleOutline } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ClaimFormData, ClaimFormFields } from './ClaimDialog'

interface OrgInfoStepProps {
  onPreviousStep: () => void
  onNextStep: () => void
}

const OrgInfoStep: React.FC<OrgInfoStepProps> = ({ onPreviousStep, onNextStep }) => {
  const { formatMessage } = useIntl()
  const { register, errors, handleSubmit, setValue, setError, watch } = useFormContext<ClaimFormData>()
  const [isLoading, setIsLoading] = useState(false)

  const locationWatch = watch(ClaimFormFields.Location)

  const handleLocationValue = (searchResult: SearchResult) =>
    setValue(ClaimFormFields.Location, {
      address: searchResult.candidates[0].attributes.LongLabel,
      country: searchResult.candidates[0].attributes.Country,
      region: searchResult.candidates[0].attributes.Region,
      subRegion: searchResult.candidates[0].attributes.Subregion,
      city: searchResult.candidates[0].attributes.City,
      district: searchResult.candidates[0].attributes.District,
      lat: searchResult.candidates[0].location.y,
      lng: searchResult.candidates[0].location.x,
    })

  const handleNextStep = async (values: ClaimFormData): Promise<void> => {
    if (!locationWatch?.country) {
      setError(ClaimFormFields.Address, {
        type: 'manual',
        message: formatMessage({ id: 'claim.createClaim.orgInfoStep.addressErrorMessage' }),
      })
      return
    }

    setIsLoading(true)

    try {
      const res = await fetch(`https://company.clearbit.com/v2/companies/find?domain=${values?.websiteAddress}`, {
        headers: new Headers({
          Authorization: `Bearer sk_6dfbd8ebfd74b3dfa3f628085c564a84`, //TODO: This should probably not be here 😬
        }),
      })
      const clearbitResponse = await res.json()
      setValue(ClaimFormFields.Logo, clearbitResponse.logo)
    } catch (e) {
      console.warn('could not fetch info from clearbit')
    } finally {
      setIsLoading(false)
      onNextStep()
    }
  }

  return (
    <OnboardingForm
      preHeader={formatMessage({ id: 'claim.createClaim.orgInfoStep.preHeader' })}
      header={formatMessage({ id: 'claim.createClaim.orgInfoStep.header' })}
      subheader={formatMessage({ id: 'claim.createClaim.orgInfoStep.subHeader' })}
    >
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.organizationName' })}
        label={formatMessage({ id: 'claim.createClaim.orgInfoStep.name' })}
        type="text"
        name={ClaimFormFields.Name}
        inputRef={register({
          required: formatMessage({ id: 'form.validation.required' }),
          minLength: {
            value: 2,
            message: formatMessage({ id: 'errorMessages.general.minLength' }, { count: 2 }),
          },
        })}
        required
        error={Boolean(errors?.name)}
        helperText={errors?.name?.message}
        onClear={(): void => setValue(ClaimFormFields.Name, '')}
        data-testid="claim-form-field-name"
      />
      <TextField
        fullWidth
        hoveringLabel
        placeholder={formatMessage({ id: 'textFieldPlaceholders.website' })}
        label={formatMessage({ id: 'claim.createClaim.orgInfoStep.website' })}
        type="text"
        name={ClaimFormFields.WebsiteAddress}
        inputRef={register({
          required: formatMessage({ id: 'form.validation.required' }),
          validate: (value: string): string | true =>
            validURL(value) || formatMessage({ id: 'notifications.errorInvalidWebsite' }),
        })}
        required
        error={Boolean(errors?.websiteAddress)}
        helperText={errors?.websiteAddress?.message}
        onClear={(): void => setValue(ClaimFormFields.WebsiteAddress, '')}
        data-testid="claim-form-field-website"
      />
      <ControlledLocationField
        label={formatMessage({ id: 'claim.createClaim.orgInfoStep.address' })}
        locationName={ClaimFormFields.Location}
        addressName={ClaimFormFields.Address}
        rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
        valueSetter={handleLocationValue}
        fieldErrors={errors?.location?.address}
      />
      <Stack spacing={2}>
        <Typography variant="button">
          {formatMessage({ id: 'claim.createClaim.orgInfoStep.addressInfoHeader' })}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <CheckCircleOutline />
          <Typography variant="body1">
            {formatMessage({ id: 'claim.createClaim.orgInfoStep.addressInfoStepOne' })}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <CheckCircleOutline />
          <Typography variant="body1">
            {formatMessage({ id: 'claim.createClaim.orgInfoStep.addressInfoStepTwo' })}
          </Typography>
        </Stack>
      </Stack>

      <Stack pt={2} direction="row" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onPreviousStep}>
          {formatMessage({ id: 'general.back' })}
        </Button>
        <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit(handleNextStep)}>
          {formatMessage({ id: 'general.continue' })}
        </LoadingButton>
      </Stack>
    </OnboardingForm>
  )
}

export default OrgInfoStep
