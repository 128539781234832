import { Chip } from '@mui/material'
import React from 'react'
import { FileScreeningResult, ScreeningResult } from '@app/src/types/resourceExplorer'
import { useTheme } from '@mui/styles'
import { AutoAwesomeOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface CertificateScreeningChipProps {
  fileScreeningResults?: FileScreeningResult[]
}

const CertificateScreeningChip: React.FC<CertificateScreeningChipProps> = ({ fileScreeningResults }) => {
  const { palette, spacing } = useTheme()
  const { formatMessage } = useIntl()
  const { showFileScreeningResult } = useFlags()

  if (!showFileScreeningResult) return null
  if (!fileScreeningResults?.length) return null

  const failedCount = fileScreeningResults.filter(result => result.result !== ScreeningResult.Passed).length

  return (
    <Chip
      icon={<AutoAwesomeOutlined />}
      sx={{
        backgroundColor: palette.communication.main,
        marginLeft: spacing(1),
      }}
      disabled={failedCount === 0}
      label={formatMessage({ id: 'certificateScreeningResult.inconsistenciesCount' }, { count: failedCount })}
    />
  )
}
export default CertificateScreeningChip
