import { TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    kpi: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    kpi?: React.CSSProperties
  }
}
declare module '@mui/material/Typography' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyPropsVariantOverrides {
    kpi: true
  }
}

export const typography: TypographyOptions = {
  fontFamily: '"Poppins","Roboto","Helvetica","Arial",sans-serif',
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  htmlFontSize: 8,
  h1: {
    fontWeight: 700,
    fontSize: '4rem',
    lineHeight: '5rem',
    letterSpacing: 0,
  },
  h2: {
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: '4rem',
    letterSpacing: 0,
  },
  h3: {
    fontWeight: 700,
    fontSize: '3rem',
    lineHeight: '4rem',
    letterSpacing: 0,
  },
  h4: {
    fontWeight: 700,
    fontSize: '2.75rem',
    lineHeight: '3rem',
    letterSpacing: 0,
  },
  h5: {
    fontWeight: 700,
    fontSize: '2.5rem',
    lineHeight: '3rem',
    letterSpacing: 0,
  },
  h6: {
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: '3rem',
    letterSpacing: 0,
  },
  body1: {
    fontWeight: 400,
    fontSize: '1.75rem',
    lineHeight: '3rem',
    letterSpacing: 0,
  },
  body2: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '3rem',
    letterSpacing: 0,
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '3rem',
    letterSpacing: 0,
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '3rem',
    letterSpacing: 0,
  },
  overline: {
    fontWeight: 600,
    fontSize: '1.625rem',
    lineHeight: '2rem',
    letterSpacing: '0.05rem',
    testTransform: 'uppercase',
  },
  caption: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '0.05rem',
  },
  button: {
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '3rem',
    letterSpacing: '0.05rem',
    textTransform: 'none',
  },
  kpi: {
    fontSize: '4rem',
    lineHeight: '5rem',
    fontWeight: 500,
    letterSpacing: 0,
  },
}
