import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useMemo } from 'react'
import { ResultForPeriod, StatisticsForQuestionPerPeriod } from '../../DataHubScene'
import { useDataInsightsModal } from './DataInsightsModalContext'
import { EchartClickItemData } from './OptionMultiPeriodChart'
import { useTextAndFileMultiPeriodChartConfigs } from './useTextAndFileMultiPeriodChartConfigs'

interface FileMultiPeriodChartProps {
  statistics: StatisticsForQuestionPerPeriod
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  legendTitle: string
  selectedChartType: ChartType
}

export const TextAndFileMultiPeriodChart: React.FC<FileMultiPeriodChartProps> = ({
  statistics,
  height,
  echartsRef,
  legendTitle,
  selectedChartType,
}) => {
  const resultsForPeriod: ResultForPeriod[] = statistics.resultForPeriods
  const options = useTextAndFileMultiPeriodChartConfigs({ resultsForPeriod, legendTitle, selectedChartType })
  const { openDataInsightsModal } = useDataInsightsModal()

  const events = useMemo(
    () => ({
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          period: data.name,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }),
    [openDataInsightsModal, statistics],
  )

  return (
    <ReactEChartsCore
      onEvents={events}
      option={options}
      echarts={echarts}
      style={{ minWidth: '100%', height }}
      ref={echartsRef}
    />
  )
}
