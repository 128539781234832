import { ChevronRightOutlined } from '@mui/icons-material'
import { ListItem, ListItemButton, ListItemText } from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFlagRule from '@app/src/components/Drawer/Views/DrawerViewFlagRule'
import React from 'react'
import { ReportFlagRule } from '@app/src/types/flags'

type FlagRuleItemProps = {
  title: string
  reportFlagRules: ReportFlagRule[]
}

const FlagRuleItem: React.FC<FlagRuleItemProps> = ({ title, reportFlagRules }) => {
  const { openDrawer } = useDrawer()

  return (
    <ListItem disableGutters disablePadding>
      <ListItemButton onClick={() => openDrawer(<DrawerViewFlagRule flagRules={reportFlagRules} />)}>
        <ListItemText primary={title} />
        <ChevronRightOutlined color="primary" />
      </ListItemButton>
    </ListItem>
  )
}

export default FlagRuleItem
