import { Box, SxProps, Typography } from '@mui/material'
import RatingDots from '@app/src/components/RatingDots'
import React from 'react'

interface AssessmentSectionRatingProps {
  sectionName: string
  level?: number
  sx?: SxProps
}

const AssessmentSectionRating = ({ sectionName, level = 0 }: AssessmentSectionRatingProps) => {
  return (
    <Box py={1}>
      <Typography variant="subtitle2">{sectionName}</Typography>
      <RatingDots level={level} />
    </Box>
  )
}

export default AssessmentSectionRating
