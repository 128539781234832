import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import QuestionCategoryFilter from '@app/src/pages/ResourceCollection/Filters/QuestionCategoryFilter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import {
  BASIC_QUESTION_CATEGORIES,
  ESRS_CATEGORIES,
  QuestionCategory,
  REGULAR_QUESTION_CATEGORIES,
} from '@app/src/wf-constants/questionCategories'
import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FacetItem, FiltersProps } from '..'
import AllFiltersButton from './AllFiltersButton'
import TextFieldFilter from './TextFieldFilter'

const getCustomSort = (questionCategory: QuestionCategory) => {
  const customSort = questionCategory.customSort
  if (!customSort) return undefined
  return (a: FacetItem, b: FacetItem) => customSort(a.label, b.label)
}

const StandardQuestionFilters = ({ allowedFilters, implicitFilters, resetPage }: FiltersProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <Grid container spacing={1}>
      <Filters resetPage={resetPage} allowedFilters={allowedFilters}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item md={3} sm={8}>
                <Filter operator={Operators.Contains} name="questionText">
                  {({ filterName, value, operator }) => (
                    <TextFieldFilter
                      fullWidth
                      size="small"
                      defaultValue={value}
                      label={formatMessage({ id: 'schemas.question.name' })}
                      filterName={filterName}
                      operator={operator}
                      name="questionName"
                    />
                  )}
                </Filter>
              </Grid>
              {BASIC_QUESTION_CATEGORIES.map(cf => (
                <Grid item md={2} sm={8} key={cf.name}>
                  <QuestionCategoryFilter
                    fieldLabelId={cf.translationKey}
                    categoryName={cf.name}
                    implicitFilters={implicitFilters}
                    customSort={getCustomSort(cf)}
                  />
                </Grid>
              ))}
              <Grid item md={3}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'configurations.sustainabilityLibrary' })}>
                              {REGULAR_QUESTION_CATEGORIES.map(cf => (
                                <QuestionCategoryFilter
                                  key={cf.name}
                                  fieldLabelId={cf.translationKey}
                                  categoryName={cf.name}
                                  implicitFilters={implicitFilters}
                                  customSort={getCustomSort(cf)}
                                />
                              ))}
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.question.esrs' })}>
                              {ESRS_CATEGORIES.map(cf => (
                                <QuestionCategoryFilter
                                  key={cf.name}
                                  fieldLabelId={cf.translationKey}
                                  categoryName={cf.name}
                                  implicitFilters={implicitFilters}
                                  customSort={getCustomSort(cf)}
                                />
                              ))}
                            </FilterSection>
                          </Box>
                        </DrawerViewFilters>,
                        { showAboveDialogs: true },
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default StandardQuestionFilters
