import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Provider } from '@app/src/types/organizations'
import { Inquiry, InquiryStatus } from '@app/src/types/resourceExplorer'
import { capitalize } from '@app/src/utils/helpers'
import { PieChartOutlined } from '@mui/icons-material'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  provider: Provider
}

const useStyles = makeStyles(({ spacing }) => ({
  box: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyState: {
    color: 'rgba(0, 0, 0, 0.38)',
    background: 'rgba(0, 0, 0, 0.09);',
    borderRadius: 80,
    padding: 16,
    width: 70,
    height: 70,
    display: 'flex',
    marginBottom: spacing(2),
  },
  overdueLegendItem: {
    backgroundImage:
      'linear-gradient(135deg, #b43c21 25%, #ffffff 25%, #ffffff 50%, #b43c21 50%, #b43c21 75%, #ffffff 75%, #ffffff 100%)',
    backgroundSize: '8.49px 8.49px',
  },
}))

const QuestionnaireChart: React.FC<Props> = ({ provider }) => {
  const formattedDate = formatDate(new Date()) ?? ''
  const { palette } = useTheme()
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const { items: inquiries } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.InquiryByProvider,
    endpoint: endpoints.inquiriesCollection,
    payload: {
      filter: [
        {
          name: 'providerId',
          filters: [
            {
              operator: Operators.EqualTo,
              value: provider.id,
            },
          ],
        },
      ],
      include: ['request.responses'],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 100,
      },
    },
  })

  const overdueInquiries = inquiries.filter(i => i.deadline < formattedDate && i.status === InquiryStatus.Requested)
  const responsesCount = inquiries.filter(i => i.request?.responses?.length).length

  const overdue = overdueInquiries.length
  const requested = inquiries.length - overdueInquiries.length - responsesCount

  const requestsCount = inquiries.length

  const percentage = (value: number, maxValue: number) => {
    return `${Math.floor((100 * value) / maxValue)}%`
  }

  const options: EChartsOption = {
    title: {
      text: formatMessage({ id: 'schemas.filter.reports' }),
      subtext: `${inquiries.length} ${formatMessage({ id: 'general.sent' })}`,
      left: 'left',
      textStyle: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
      },
      subtextStyle: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
      },
    },
    type: 'bar',
    stack: 'chart',
    barCategoryGap: '30%',
    itemStyle: {
      top: 0,
      bottom: 0,
    },
    xAxis: {
      show: false,

      splitLine: {
        show: false,
      },
      triggerEvent: true,
    },
    yAxis: {
      inverse: true,
      type: 'category',

      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      triggerEvent: true,
      axisLabel: {
        show: false,
      },
    },
    grid: {
      top: '-100',
      bottom: 9,
      right: '5%',
      containLabel: false,
      show: false,
      left: '5%',
    },
    series: [
      {
        name: capitalize(formatMessage({ id: 'general.completed' })),
        type: 'bar',
        stack: 'x',
        barWidth: '11%',
        bottom: 0,
        top: 0,
        left: 0,
        data: [{ value: responsesCount }],

        emphasis: {
          disabled: true,
        },

        itemStyle: {
          color: palette.secondary.main,
          borderType: 'solid',
          borderRadius: requested === 0 ? [5, 5, 5, 5] : [5, 0, 0, 5],
        },
      },

      {
        name: formatMessage({ id: 'dashboard.investment.overdue' }),
        type: 'bar',
        stack: 'x',
        data: [{ value: overdue }],

        emphasis: {
          disabled: true,
        },
        itemStyle: {
          color: palette.grey['100'],
          borderType: 'solid',
          borderRadius: overdue && !responsesCount ? [0, 0, 0, 0] : [5, 0, 0, 5],

          decal: {
            show: true,
            color: '#C32E0D',
            dashArrayX: [1, 0],
            dashArrayY: [4, 3],
            rotation: -Math.PI / 4,
          },
        },
      },
      {
        name: formatMessage({ id: 'reporting.statuses.Requested' }),
        type: 'bar',
        stack: 'x',
        data: [{ value: requested }],

        emphasis: {
          disabled: true,
        },
        itemStyle: {
          color: palette.grey['100'],
          borderType: 'solid',
          borderRadius: !responsesCount && !overdue ? [0, 5, 5, 0] : [5, 5, 5, 5],
        },
      },
    ],
  }

  return (
    <>
      {requestsCount ? (
        <>
          <Box minWidth={{ sm: 480 }}>
            <Box height={140}>
              <ReactEcharts option={options} />
            </Box>
            <Box display="flex" justifyContent="space-evenly" px={2} paddingBottom={3}>
              <Box display="flex" alignItems="center">
                <Box height={16} width={16} bgcolor={palette.secondary.main} borderRadius="4px" />
                <Box ml={2}>
                  <Typography variant="subtitle1">{formatMessage({ id: 'reporting.statuses.Submitted' })}</Typography>
                  <Box display="flex " justifyContent="space-around">
                    <Typography variant="caption">{responsesCount}</Typography>
                    <Typography variant="caption">{percentage(responsesCount, requestsCount)}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                {overdueInquiries.length !== 0 ? (
                  <>
                    <Box height={16} width={16} className={classes.overdueLegendItem} borderRadius="4px" />
                    <Box ml={2}>
                      <Typography variant="subtitle1">{formatMessage({ id: 'reporting.statuses.Overdue' })}</Typography>
                      <Box display="flex " justifyContent="space-around">
                        <Typography variant="caption">{overdue}</Typography>
                        <Typography variant="caption">{percentage(overdue, requestsCount)}</Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  ''
                )}
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box height={16} width={16} bgcolor={palette.grey['200']} borderRadius="4px" />
                <Box ml={2}>
                  <Typography variant="subtitle1">{formatMessage({ id: 'reporting.statuses.Requested' })}</Typography>
                  <Box display="flex " justifyContent="space-around">
                    <Typography variant="caption">{requested}</Typography>
                    <Typography variant="caption">{percentage(requested, requestsCount)}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box mx={3}>
              <Divider color="black" />
            </Box>
          </Box>
        </>
      ) : (
        <Box className={classes.box}>
          <PieChartOutlined className={classes.emptyState} />
          <Typography>
            {formatMessage({ id: 'resourceExplorer.provider.overview.questionnaireChartEmptyState' })}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default QuestionnaireChart
