import { Download, ListAltOutlined } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, Divider, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import useBaselineAssessmentToPDF from '@app/src/components/ExportToPDF/useBaselineAssessmentToPDF'
import LevelBar from '@app/src/components/LevelBar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useMenuState } from '@app/src/hooks/mui-hooks'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import Assessment from '@app/src/types/assessment'
import { Provider } from '@app/src/types/organizations'
import { AssessmentType, InquiryStatus, Response } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, Assessments } from '@app/src/wf-constants'
import { Operators } from '../../Filters/useFilters'
import AssessmentSectionRating from './AssessmentSectionRating'
import DetailedReview from './DetailedReview'
import { SortOrder } from '@app/src/types/filter'

interface ProvidersBaseAssessmentProps {
  provider: Provider
}

const ProvidersBaseAssessment: React.FC<ProvidersBaseAssessmentProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [isPeriodMenuOpen, anchorRef, openMenu, closeMenu] = useMenuState()
  const ref = useRef<HTMLDivElement>(null)
  const { generatePDF, isLoading } = useBaselineAssessmentToPDF(ref, provider)
  const { assessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentType: AssessmentType.BaselineAssessment,
  })

  const {
    facets: [requestedPeriods],
    isLoading: requestedPeriodsIsLoading,
  } = useFetchFacets({
    key: [FetchKey.ResponseFacets, 'periods'],
    endpoint: endpoints.responsesWithFacets,
    facetsParam: [{ name: 'request.periodName' }],
    filter: [
      {
        name: 'request.questionnaireTemplateId',
        filters: [
          {
            value: assessmentQuestionnaireLink?.questionnaireTemplateId,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'draftStatus',
        filters: [
          {
            value: InquiryStatus.Submitted,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'request.ResponderOrganizationId',
        filters: [
          {
            value: provider.organizationId,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
    options: { enabled: Boolean(assessmentQuestionnaireLink?.questionnaireTemplateId) },
  })

  const availablePeriods = useMemo(
    () => requestedPeriods?.map(facet => facet.label).sort((a, b) => parseInt(b) - parseInt(a)) || [],
    [requestedPeriods],
  )

  const {
    items: [assessment],
    isLoading: assessmentIsLoading,
  } = useFetchCollectionWithPost<Assessment>({
    key: [FetchKey.AssessmentCollection, selectedPeriod],
    endpoint: endpoints.assessmentCollection,
    payload: {
      filter: [
        {
          name: 'provider.id',
          filters: [
            {
              value: provider.id,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'periodName',
          filters: [
            {
              value: selectedPeriod,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'assessmentTemplate.name',
          filters: [
            {
              operator: Operators.EqualTo,
              value: Assessments.BaselineAssessment,
            },
          ],
        },
      ],
      include: [
        'provider.organization.SubIndustryNaceCode',
        'assessmentTemplate.sections.rules',
        'response.items.requestItem.template.RequestCollection',
        'response.items.files',
      ],
      sort: {
        target: 'createdAt',
        order: SortOrder.DESCENDING,
      },
    },
    options: {
      enabled: Boolean(selectedPeriod),
    },
  })

  const {
    items: [response],
    isLoading: responseIsLoading,
  } = useFetchCollectionWithPost<Response>({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCollection,
    payload: {
      filter: [
        {
          name: 'id',
          filters: [
            {
              value: assessment?.responseId,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: ['items.requestItem.template.RequestCollection', 'items.files'],
    },
    options: {
      enabled: Boolean(assessment?.responseId),
    },
  })

  const handleExportToPdf = async () => {
    await generatePDF()
  }

  useEffect(() => {
    const shouldSetLatestPeriod = Boolean(availablePeriods?.length) && !selectedPeriod
    if (shouldSetLatestPeriod) {
      setSelectedPeriod(availablePeriods[0])
    }
  }, [availablePeriods, requestedPeriodsIsLoading, selectedPeriod])

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.Assessment.ViewedPage,
      eventProps: {
        page: 'provider',
      },
    })
  }, [])

  if (assessmentIsLoading || responseIsLoading) {
    return (
      <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center" flexDirection="column">
        <CircularProgress />
      </Box>
    )
  }

  if (!assessment || !response) {
    return (
      <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center" flexDirection="column">
        <EmptyState
          iconComponent={ListAltOutlined}
          title={formatMessage({ id: 'baseAssessment.providerPage.noResponses' })}
          description={formatMessage({ id: 'baseAssessment.providerPage.noResponseDescription' })}
        />
      </Box>
    )
  }

  return (
    <Box ref={ref}>
      <Paper elevation={0} sx={{ px: 3, py: 2, mt: 2, mr: 5 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="overline" color="text.secondary">
              {formatMessage({ id: 'baseAssessment.providerPage.overview' })}
            </Typography>
            <Box>
              <Typography variant="button" mr={1}>
                {formatMessage({ id: 'reporting.period' })}
              </Typography>
              <Button endIcon={<ArrowDropDownIcon />} size="small" onClick={openMenu}>
                {selectedPeriod}
              </Button>
              <Button
                startIcon={<Download />}
                variant="outlined"
                size="small"
                onClick={handleExportToPdf}
                sx={{ ml: 2 }}
                disabled={isLoading}
              >
                {formatMessage({ id: 'resourceCollections.general.exportToPdf' })}
              </Button>
              <Menu
                open={isPeriodMenuOpen}
                anchorEl={anchorRef}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                elevation={1}
                onClose={closeMenu}
              >
                {availablePeriods?.map(period => (
                  <MenuItem
                    key={period}
                    onClick={() => {
                      closeMenu()
                      setSelectedPeriod(period)
                    }}
                  >
                    {period}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Stack>
          <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h2" mr={2}>
                {formatMessage({ id: 'baseAssessment.totalLevel' }, { value: assessment.totalLevel })}
              </Typography>
              <LevelBar value={assessment.totalLevel} size="large" />
            </Box>
            <Typography mr={2} mt={1} variant="subtitle1">
              {formatMessage({ id: `baseAssessment.levelDefinitions.level${assessment.totalLevel}Title` })}
            </Typography>
            <Typography mr={2}>
              {formatMessage({ id: `baseAssessment.levelDefinitions.level${assessment.totalLevel}HelperText` })}
            </Typography>
            <Box mt={1}>
              <Chip
                size="small"
                label={formatMessage(
                  { id: `baseAssessment.providerPage.subIndustry` },
                  { subIndustry: assessment.provider.organization?.subIndustryNaceCode?.description ?? '' },
                )}
                sx={{ mr: 1, mt: 1 }}
              />
              <Chip
                size="small"
                label={formatMessage(
                  { id: 'baseAssessment.providerPage.revenueRange' },
                  {
                    revenue: assessment.provider.organization?.revenueRange
                      ? formatMessage({ id: `claim.companyRevenue.${assessment.provider.organization?.revenueRange}` })
                      : '-',
                  },
                )}
                sx={{ mr: 1, mt: 1 }}
              />
              <Chip
                size="small"
                label={formatMessage(
                  { id: 'baseAssessment.providerPage.employeeRange' },
                  {
                    revenue: assessment.provider.organization?.companySizeRange
                      ? formatMessage({
                          id: `claim.companySize.${assessment.provider.organization?.companySizeRange}`,
                        })
                      : '-',
                  },
                )}
                sx={{ mr: 1, mt: 1 }}
              />
            </Box>
          </Box>
          <Stack direction="row" flexGrow={1} width="100%" maxWidth={700} justifyContent="space-between">
            <AssessmentSectionRating
              sectionName={formatMessage({ id: 'baseAssessment.section1' })}
              level={assessment.section1Level}
            />
            <Divider flexItem orientation="vertical" />
            <AssessmentSectionRating
              sectionName={formatMessage({ id: 'baseAssessment.section2' })}
              level={assessment.section2Level}
            />
            <Divider flexItem orientation="vertical" />
            <AssessmentSectionRating
              sectionName={formatMessage({ id: 'baseAssessment.section3' })}
              level={assessment.section3Level}
            />
            <Divider flexItem orientation="vertical" />
            <AssessmentSectionRating
              sectionName={formatMessage({ id: 'baseAssessment.section4' })}
              level={assessment.section4Level}
            />
            <Divider flexItem orientation="vertical" />
            <AssessmentSectionRating
              sectionName={formatMessage({ id: 'baseAssessment.section5' })}
              level={assessment.section5Level}
            />
          </Stack>
        </Stack>
      </Paper>
      <DetailedReview latestResponse={response} assessment={assessment} />
    </Box>
  )
}

export default ProvidersBaseAssessment
