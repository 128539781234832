import endpoints from '@app/src/api/endpoints'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { Option } from '@app/src/components/Form/Select/SimpleSelect'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import ProductMappingStatusField from '@app/src/pages/Product/ProductMappingStatusField'
import ProductStatusField from '@app/src/pages/Product/ProductStatusField'
import ProviderField from '@app/src/pages/Product/ProviderField'
import { SortOrder } from '@app/src/types/filter'
import { ProductIndustry, ProductMappingStatus, ProductStatus } from '@app/src/types/product'
import { Country } from '@app/src/types/resourceExplorer'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface Props {
  isEdit?: boolean
  onCancel: () => void
  isSavingProduct?: boolean
  onSubmitProduct: () => void
}

const ProductForm: React.FC<Props> = ({ isEdit, isSavingProduct, onSubmitProduct, onCancel }) => {
  const { formatMessage } = useIntl()
  const { register, control, errors } = useFormContext()
  const { productIndustry } = useFlags()

  return (
    <Box display="flex" flexDirection="column">
      <input type="hidden" name="id" ref={register()} />
      {productIndustry ? (
        <FormControl sx={{ mb: 2 }} required>
          <FormLabel sx={{ display: 'flex' }}>
            <Typography variant="subtitle1">{formatMessage({ id: 'schemas.product.productType' })}</Typography>
          </FormLabel>
          <Controller
            name="industry"
            control={control}
            defaultValue={null}
            rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
            render={({ onChange, value }) => (
              <RadioGroup onChange={onChange} value={value} row>
                <FormControlLabel
                  disabled={isEdit}
                  value={ProductIndustry.Beverage}
                  name="industry"
                  control={<Radio />}
                  label={formatMessage({ id: `schemas.product.productIndustry.${ProductIndustry.Beverage}` })}
                />
                <FormControlLabel
                  disabled={isEdit}
                  value={ProductIndustry.Textile}
                  name="industry"
                  control={<Radio />}
                  label={formatMessage({ id: `schemas.product.productIndustry.${ProductIndustry.Textile}` })}
                />
              </RadioGroup>
            )}
          />
          <FormHelperText sx={{ color: 'semantic.error' }}>{errors?.industry?.message}</FormHelperText>
        </FormControl>
      ) : (
        <input type="hidden" name="industry" value={ProductIndustry.Beverage} ref={register()} />
      )}

      <TextField
        sx={{ mb: 2 }}
        name="name"
        label={formatMessage({ id: 'schemas.product.productName' })}
        required
        inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
        error={Boolean(errors?.name)}
        helperText={errors?.name?.message}
      />
      <TextField
        sx={{ mb: 2 }}
        name="gtin"
        label={formatMessage({ id: 'schemas.product.gtin' })}
        inputRef={register()}
        error={Boolean(errors?.gtin)}
        helperText={errors?.gtin?.message}
      />
      <TextField
        sx={{ mb: 2 }}
        name="productNumber"
        label={formatMessage({ id: 'schemas.product.productNumber' })}
        inputRef={register()}
      />
      <TextField
        sx={{ mb: 2 }}
        name="productCustomId"
        label={formatMessage({ id: 'schemas.product.productCustomId' })}
        inputRef={register()}
        helperText={formatMessage({ id: 'schemas.product.productCustomIdHelperText' })}
      />
      <TextField
        sx={{ mb: 2 }}
        name="purchaseOrder"
        label={formatMessage({ id: 'schemas.product.purchaseOrder' })}
        inputRef={register()}
      />
      <Box sx={{ mb: 2 }}>
        <Select<number, Country>
          name="countryId"
          fieldLabel={formatMessage({ id: 'schemas.organization.country' })}
          control={control}
          defaultValue={null}
          forceFetch
          enableAutoSelect
          navigation={{
            url: endpoints.countries,
            type: 'post',
            postObject: {
              include: [],
              filter: [],
              sort: { target: 'name', order: SortOrder.ASCENDING },
            },
          }}
          objectToOption={(country: Country): Option<number> => {
            return {
              label: country.name,
              value: country.id,
            }
          }}
          error={errors?.countryId?.message}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Controller
          name="provider"
          defaultValue={null}
          control={control}
          render={({ onChange, value }) => <ProviderField onChange={onChange} value={value} showNotSelected />}
          helperText={errors?.provider?.message}
          error={Boolean(errors?.provider)}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Controller
          name="productStatus"
          defaultValue={null}
          render={({ onChange, value }) => (
            <ProductStatusField onChange={opt => onChange((opt as Option<ProductStatus>)?.value)} value={value} />
          )}
          helperText={errors?.productStatus?.message}
          error={Boolean(errors?.productStatus)}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Controller
          name="mappingStatus"
          defaultValue={null}
          render={({ onChange, value }) => (
            <ProductMappingStatusField
              onChange={opt => onChange((opt as Option<ProductMappingStatus>)?.value)}
              value={value}
            />
          )}
          helperText={errors?.mappingStatus?.message}
          error={Boolean(errors?.mappingStatus)}
        />
      </Box>
      <Box display="flex" justifyContent="center" m={2}>
        <Box mr={2}>
          <Button disabled={isSavingProduct} variant="outlined" size="large" onClick={onCancel}>
            {formatMessage({ id: 'general.cancel' })}
          </Button>
        </Box>
        <Box>
          <LoadingButton size="large" variant="contained" onClick={() => onSubmitProduct()} loading={isSavingProduct}>
            {formatMessage({ id: 'general.save' })}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  )
}

export default ProductForm
