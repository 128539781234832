import { Alert, Button, Dialog, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type RiskScreeningInfoDialogProps = {
  open: boolean
  closeDialog: () => void
}

const RiskScreeningInfoDialog: React.FC<RiskScreeningInfoDialogProps> = ({ open, closeDialog }) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
      <Stack p={6}>
        <Typography variant="h5" textAlign="center">
          {formatMessage({ id: 'schemas.riskSetting.infoDialog.infoButton' })}
        </Typography>

        <Typography variant="subtitle1" mt={4}>
          {formatMessage({ id: 'schemas.riskSetting.infoDialog.riskIndexTitle' })}
        </Typography>
        <Typography>{formatMessage({ id: 'schemas.riskSetting.infoDialog.riskIndexDescription' })}</Typography>

        <Typography variant="subtitle1" mt={3}>
          {formatMessage({ id: 'schemas.riskSetting.infoDialog.countryRiskTitle' })}
        </Typography>
        <Typography>{formatMessage({ id: 'schemas.riskSetting.infoDialog.countryRiskDescription' })}</Typography>

        <Typography variant="subtitle1" mt={3}>
          {formatMessage({ id: 'schemas.riskSetting.infoDialog.transparencyMethodologyTitle' })}
        </Typography>
        <Typography>
          {formatMessage({ id: 'schemas.riskSetting.infoDialog.transparencyMethodologyDescription' })}
        </Typography>

        <Alert variant="filled" severity="info" sx={{ mt: 4, bgcolor: 'common.white' }}>
          {formatMessage({ id: 'schemas.riskSetting.infoDialog.feedbackText' })}
        </Alert>

        <Button fullWidth sx={{ mt: 4 }} onClick={closeDialog}>
          {formatMessage({ id: 'general.close' })}
        </Button>
      </Stack>
    </Dialog>
  )
}

export default RiskScreeningInfoDialog
