import React, { FC, ReactNode } from 'react'

import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import AccessibleOrganizationsContext, { AccessibleOrganizationsContextProps } from './AccessibleOrganizationsContext'
import { useAuthentication } from './AuthenticationContext'

type AccessibleOrganizationsContextProviderProps = {
  children: ReactNode
}

const AccessibleOrganizationsContextProvider: FC<AccessibleOrganizationsContextProviderProps> = ({
  children,
}: AccessibleOrganizationsContextProviderProps) => {
  const { scope } = useAuthentication()

  const { data: accessibleOrganizations, isLoading } = useFetchResource<
    AccessibleOrganizationsContextProps['accessibleOrganizations']
  >({
    key: [FetchKey.AccessibleOrganizations, scope.role, scope.solution],
    endpoint: endpoints.accessibleOrganizations,
  })

  return (
    <AccessibleOrganizationsContext.Provider
      value={{ accessibleOrganizations: accessibleOrganizations ?? [], isLoading }}
    >
      {children}
    </AccessibleOrganizationsContext.Provider>
  )
}

export default AccessibleOrganizationsContextProvider
