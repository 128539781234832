import { useSpendPeriodOptions } from '@app/src/components/ResourceDetails/spendPeriodOptions'
import useOrganizationCurrency from '@app/src/hooks/organizationCurrency'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { getCurrencyIcon } from '@app/src/utils/currencyUtils'
import { ArrowDropDown } from '@mui/icons-material'
import { Avatar, Box, Button, Checkbox, Chip, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import Filter from './Filter'
import { useFiltersContext } from './Filters'
import TextFieldFilter from './TextFieldFilter'
import { Operators } from './useFilters'

interface SpendFilterProps {
  spendFilterNamePrefix?: string
  allowedFilters?: string[]
}

enum SpendUniqueIds {
  Max = 'spendMax',
  Min = 'spendMin',
}

const SpendFilter = ({ spendFilterNamePrefix, allowedFilters = [] }: SpendFilterProps) => {
  const { formatMessage } = useIntl()
  const filters = useGetApiQueryFilters(allowedFilters)
  const { providerSpend } = useFlags()
  const { SPEND_PERIOD_OPTIONS } = useSpendPeriodOptions()
  const { organizationCurrency } = useOrganizationCurrency()
  const [isPeriodMenuOpen, setIsPeriodMenuOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { setQueryFilter } = useFiltersContext()

  const spendAmountFilterName = `${spendFilterNamePrefix ? `${spendFilterNamePrefix}.` : ''}spends.amount`
  const spendPeriodFilterName = `${spendFilterNamePrefix ? `${spendFilterNamePrefix}.` : ''}spends.periodName`

  const spendPeriodFilterValue =
    (filters.find(filter => filter.name === spendPeriodFilterName)?.filters[0].value as string[]) ?? []

  const hasSpendPeriodFilter = Boolean(spendPeriodFilterValue?.length)

  const getValueDisplay = (value: string | string[]) => {
    if (!value || !Array.isArray(value)) return
    switch (value.length) {
      case 0:
        return
      case 1:
        return <Chip size="small" label={[value].flat()} />

      default:
        return (
          <Avatar variant="circular" sx={{ width: 20, height: 20, bgcolor: 'common.black' }}>
            <Typography variant="caption">{value.length}</Typography>
          </Avatar>
        )
    }
  }

  useEffect(() => {
    if (!hasSpendPeriodFilter) {
      setQueryFilter(spendAmountFilterName, '', Operators.GreaterThanOrEqual, SpendUniqueIds.Min)
      setQueryFilter(spendAmountFilterName, '', Operators.LowerThanOrEqual, SpendUniqueIds.Max)
    }
  }, [hasSpendPeriodFilter])

  if (!providerSpend) return null

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box>
          <Filter operator={Operators.In} name={spendPeriodFilterName}>
            {({ value, onChange }) => {
              return (
                <>
                  <Button
                    endIcon={<ArrowDropDown />}
                    size="small"
                    onClick={() => setIsPeriodMenuOpen(true)}
                    ref={anchorRef}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography variant="subtitle1">
                        {formatMessage({ id: 'schemas.supplier.annualSpend.spend' })}
                      </Typography>
                      {getValueDisplay(value)}
                    </Stack>
                  </Button>
                  <Menu
                    open={isPeriodMenuOpen}
                    anchorEl={anchorRef.current}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    elevation={1}
                    onClose={() => setIsPeriodMenuOpen(false)}
                  >
                    {SPEND_PERIOD_OPTIONS?.map(period => (
                      <MenuItem
                        key={period.value}
                        onClick={() => {
                          onChange(
                            value.includes(String(period.value))
                              ? (value as string[]).filter(v => v !== String(period.value))
                              : [...value, String(period.value)],
                          )
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Checkbox
                            checked={value ? (value as string[])?.some(value => value === String(period.value)) : false}
                          />
                          {period.label}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )
            }}
          </Filter>
        </Box>
      </Stack>
      <Tooltip
        title={formatMessage({ id: 'schemas.supplier.annualSpend.selectSpendTooltip' })}
        disableHoverListener={hasSpendPeriodFilter}
        disableFocusListener={hasSpendPeriodFilter}
        disableTouchListener={hasSpendPeriodFilter}
      >
        <Stack direction="row" spacing={2}>
          <Filter operator={Operators.GreaterThanOrEqual} name={spendAmountFilterName} uniqueId={SpendUniqueIds.Min}>
            {({ value, operator, filterName }) => (
              <TextFieldFilter
                disabled={!hasSpendPeriodFilter}
                defaultValue={value}
                value={value}
                filterName={filterName}
                operator={operator}
                size="small"
                label={formatMessage({ id: 'general.from' })}
                isSearchField={false}
                type="number"
                uniqueId="spendMin"
                InputProps={{
                  startAdornment: getCurrencyIcon(organizationCurrency),
                }}
              />
            )}
          </Filter>
          <Filter operator={Operators.LowerThanOrEqual} name={spendAmountFilterName} uniqueId={SpendUniqueIds.Max}>
            {({ value, operator, filterName }) => (
              <TextFieldFilter
                disabled={!hasSpendPeriodFilter}
                defaultValue={value}
                value={value}
                filterName={filterName}
                operator={operator}
                size="small"
                label={formatMessage({ id: 'general.to' })}
                isSearchField={false}
                type="number"
                uniqueId="spendMax"
                InputProps={{
                  startAdornment: getCurrencyIcon(organizationCurrency),
                }}
              />
            )}
          </Filter>
        </Stack>
      </Tooltip>
    </Stack>
  )
}

export default SpendFilter
