import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, IconButton, Typography, TypographyProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(({ palette }) => ({
  backButton: {
    color: palette.grey[500],
  },
}))

type CategoryHeaderProps = {
  name: string
  onClick?: () => void
  onCancel?: () => void
  variant?: TypographyProps['variant']
  classes?: { title?: string; root?: string }
  isStandardCategory?: boolean
}

export const CategoryHeader: React.FC<CategoryHeaderProps> = ({
  name,
  onClick,
  onCancel,
  variant = 'h5',
  isStandardCategory = false,
  ...props
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <Box
      padding={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      className={props.classes?.root}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        {onCancel && (
          <IconButton onClick={onCancel} size="medium" sx={{ mr: 1 }}>
            <ArrowBackIosSharpIcon fontSize="small" className={classes.backButton} />
          </IconButton>
        )}

        <Typography className={props.classes?.title} component="h2" variant={variant}>
          {name}
        </Typography>
      </Box>
      {!isStandardCategory && (
        <Button startIcon={<EditIcon />} onClick={onClick}>
          {formatMessage({ id: 'category.edit' })}
        </Button>
      )}
    </Box>
  )
}
