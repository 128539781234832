import { Chip, alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { ChipsValue } from './EsgLibrary'

const useStyles = makeStyles(({ palette }) => ({
  activeChip: {
    backgroundColor: palette.common.black,
    color: palette.common.white,
    '&:hover': {
      backgroundColor: alpha(palette.common.black, 0.8),
    },
    '&:focus': {
      backgroundColor: alpha(palette.common.black, 0.8),
    },
  },
  chip: {
    borderColor: palette.common.black,
  },
}))

interface FilterChipProps {
  activeChips: ChipsValue[]
  value: ChipsValue
  onClick: (value: ChipsValue) => void
  disabled?: boolean
  className?: string
}

const FilterChip: React.FC<FilterChipProps> = ({ activeChips, value, onClick, disabled, className }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  return (
    <Chip
      disabled={disabled}
      className={classNames(classes.chip, className, { [classes.activeChip]: activeChips.includes(value) })}
      size="small"
      label={formatMessage({ id: `transparencyOverview.tags.${value}` })}
      onClick={() => onClick(value)}
      variant={activeChips.includes(value) ? 'filled' : 'outlined'}
    />
  )
}

export default FilterChip
