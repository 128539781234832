import { Alert, AlertTitle, Avatar, Box, Typography, alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ActionButtons, { ActionButtonsProps } from '@app/src/components/ActionButtons'
import React, { ReactNode } from 'react'

type GuidanceBannerProps = {
  title: string
  customIcon?: ReactNode
  description?: ReactNode
  buttons?: ActionButtonsProps['buttons']
  disableBorderRadius?: boolean
}

const useStyles = makeStyles(({ palette }) => ({
  avatar: {
    background: alpha(palette.common.white, 0.3),
    height: 48,
    width: 48,
  },
}))

const GuidanceBanner: React.FC<GuidanceBannerProps> = ({
  title,
  customIcon,
  description,
  buttons,
  disableBorderRadius,
}) => {
  const classes = useStyles()

  return (
    <Alert
      action={
        buttons && (
          <Box display="flex" alignItems="center" height="100%">
            <ActionButtons buttons={buttons} />
          </Box>
        )
      }
      icon={
        <Avatar variant="square" className={classes.avatar}>
          {customIcon ? (
            customIcon
          ) : (
            <Typography variant="h3">
              <span role="img" aria-label="Hand waving">
                👋
              </span>
            </Typography>
          )}
        </Avatar>
      }
      sx={({ spacing }) => ({
        backgroundColor: 'communication.main',
        borderRadius: disableBorderRadius ? 0 : spacing(1),
        color: 'communication.contrastText',
      })}
    >
      <AlertTitle>{title}</AlertTitle>
      {description}
    </Alert>
  )
}

export default GuidanceBanner
