import { Typography } from '@mui/material'
import React from 'react'
import { FileScreeningResult } from '@app/src/types/resourceExplorer'
import { useIntl } from 'react-intl'

interface CertificateScreeningResultProps {
  fileScreeningResult: FileScreeningResult
}

const CertificateScreeningItem: React.FC<CertificateScreeningResultProps> = ({ fileScreeningResult }) => {
  const { formatMessage, messages } = useIntl()

  const id = `certificateScreeningResult.checks.${
    fileScreeningResult.checkName
  }.${fileScreeningResult.result.toLowerCase()}`
  const noTextStringExists = !messages[id]
  if (noTextStringExists) return null

  const value = fileScreeningResult.value ?? formatMessage({ id: 'certificateScreeningResult.noMatch' })
  const message = formatMessage({ id: id }, { value: value })

  return <Typography>{message}</Typography>
}

export default CertificateScreeningItem
