import GraphHoverDialogContent, { GraphHoverDialogItem } from '@app/src/components/GraphHoverDialogContent'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import { useTheme } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

interface CompanyProgressDialogProps {
  requestName: string
  submittedCount: number
  requestedCount: number
  templateId: number
  userFilters: FilterGroup[]
}

const submittedStatuses = [InquiryStatus.Submitted, InquiryStatus.Approved, InquiryStatus.CorrectionNeeded]

const OverviewRequestProgressDialog: React.FC<CompanyProgressDialogProps> = ({
  requestName,
  submittedCount,
  requestedCount,
  templateId,
  userFilters,
}) => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  const totalCount = submittedCount + requestedCount

  const countByStatus = [
    ...insertIf(submittedCount > 0, { status: InquiryStatus.Submitted, statusCount: submittedCount }),
    ...insertIf(requestedCount > 0, { status: InquiryStatus.Requested, statusCount: requestedCount }),
  ]

  const userFiltersToExclude = ['status', 'questionnaireTemplateid']
  const relevantUserFilters = userFilters
    .filter(f => !userFiltersToExclude.includes(f.name.toLowerCase()))
    .map(f => ({ name: f.name, operator: f.filters?.[0].operator, value: f.filters?.[0].value }))

  const dialogItems: GraphHoverDialogItem[] = countByStatus.map(s => {
    const location = stringifyQueryFilters({
      url: generatePath(paths.manageRequest, {
        view: ViewTypeName.Requests,
      }),
      queryParams: {
        filters: [
          ...relevantUserFilters,
          {
            name: 'status',
            value: s.status === InquiryStatus.Submitted ? submittedStatuses : [s.status],
            operator: Operators.In,
          },
          {
            name: 'template.id',
            value: [templateId],
            operator: Operators.In,
          },
        ],
      },
    })

    const percentage = ((s.statusCount / totalCount) * 100).toFixed()
    return {
      title: formatMessage({ id: `reporting.statuses.${s.status}` }),
      description: `${formatMessage({ id: 'general.countCompany' }, { count: s.statusCount })} (${percentage}%)`,
      dialogLink: {
        text: formatMessage({ id: 'general.seeAll' }),
        action: () => {
          history.push(location)
        },
      },
      chipColor: s.status === InquiryStatus.Submitted ? palette.visualization[1] : 'rgba(180, 180, 180, 0.2)',
    }
  })

  return <GraphHoverDialogContent title={requestName} dialogItems={dialogItems} />
}

export default OverviewRequestProgressDialog
