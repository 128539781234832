import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { TableCellWidth } from '@app/src/components/Table'

export interface AssessmentOverviewRowProps {
  row: {
    section: string
    section1Level: number
    section2Level: number
    section3Level: number
    section4Level: number
    section5Level: number
  }
}

export const AssessmentOverviewRow: React.FC<AssessmentOverviewRowProps> = ({ row }) => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <ValueCell value={formatMessage({ id: `baseAssessment.${row.section}` })} maxWidth={TableCellWidth.EXTRA_SMALL} />
      <ValueCell value={row.section1Level} maxWidth={TableCellWidth.EXTRA_SMALL} />
      <ValueCell value={row.section2Level} maxWidth={TableCellWidth.EXTRA_SMALL} />
      <ValueCell value={row.section3Level} maxWidth={TableCellWidth.EXTRA_SMALL} />
      <ValueCell value={row.section4Level} maxWidth={TableCellWidth.EXTRA_SMALL} />
      <ValueCell value={row.section5Level} maxWidth={TableCellWidth.EXTRA_SMALL} />
    </TableRow>
  )
}

export default AssessmentOverviewRow
