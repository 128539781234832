import { MoreHorizOutlined } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, TableRow } from '@mui/material'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewDeleteResource from '@app/src/components/Drawer/Views/DrawerViewDeleteResource'
import DrawerViewEditUser from '@app/src/components/Drawer/Views/DrawerViewEditUser'
import DrawerViewEditUserTransparency from '@app/src/components/Drawer/Views/DrawerViewEditUserTransparency'
import { isCurrentUser } from '@app/src/components/Drawer/Views/DrawerViewUser'
import EmailCell from '@app/src/components/Table/Cells/EmailCell'
import IconCell from '@app/src/components/Table/Cells/IconCell'
import RoleCell from '@app/src/components/Table/Cells/RoleCell'
import UserCell from '@app/src/components/Table/Cells/UserCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { useAccount } from '@app/src/context/AccountContext'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { User } from '@app/src/types/resourceExplorer'
import { Solutions } from '@app/src/wf-constants'

interface ColleagueRowProps {
  row: User
}

export const ColleagueRow: React.FC<ColleagueRowProps> = ({ row: colleague }) => {
  const { formatMessage } = useIntl()
  const { account, hasPermission } = useAccount()
  const { openDrawer } = useDrawer()
  const [dialogAnchorEl, setDialogAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(dialogAnchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDialogAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setDialogAnchorEl(null)
  }

  return (
    <>
      <TableRow data-testid="colleagues-row">
        <UserCell user={colleague} drawer canDelete canEdit />
        <ValueCell value={colleague.position} />
        <RoleCell
          value={colleague.role}
          isPublicContact={colleague.isPublicContact}
          data-testid={`${colleague.role}-${colleague.isPublicContact}`}
        />
        <EmailCell mail={colleague.email} data-testid="email-cell" />
        <IconCell
          icon={
            <IconButton size="small" onClick={handleClick} data-testid="three-dots-button">
              <MoreHorizOutlined color="action" />
            </IconButton>
          }
        />
      </TableRow>

      <Menu anchorEl={dialogAnchorEl} open={open} onClose={closeMenu}>
        <MenuItem
          disabled={
            !hasPermission(Permissions.TRANSPARENCY_USER) &&
            isCurrentUser(colleague.type.toLowerCase(), account?.user.id, colleague.id)
          }
          onClick={() => {
            openDrawer(
              hasPermission(Permissions.TRANSPARENCY_USER) ? (
                <DrawerViewEditUserTransparency user={colleague} />
              ) : (
                <DrawerViewEditUser user={colleague} />
              ),
            )
            closeMenu()
          }}
        >
          {formatMessage({ id: 'general.edit' })}
        </MenuItem>
        <MenuItem
          disabled={
            colleague.isPublicContact || isCurrentUser(colleague.type.toLowerCase(), account?.user.id, colleague.id)
          }
          onClick={() => {
            openDrawer(
              <DrawerViewDeleteResource
                fetchKey={FetchKey.Colleague}
                resourceId={colleague.id}
                solution={Solutions.Resources}
                resourceType={colleague.type.toLowerCase()}
                subTitle={colleague.name}
                invalidateTimeoutMS={1000}
              />,
            )
            closeMenu()
          }}
        >
          {formatMessage({ id: 'general.delete' })}
        </MenuItem>
      </Menu>
    </>
  )
}

export default ColleagueRow
