import { TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProductMappingCompaniesHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.name' })}
        sorting={{ name: 'name', activeSorting, toggleSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.country' })}
        sorting={{ name: 'country.name', activeSorting, toggleSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.websiteAddress' })}
        sorting={{ name: 'websiteAddress', activeSorting, toggleSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.vatNumber' })}
        sorting={{ name: 'vatNumber', activeSorting, toggleSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.registrationNumber' })}
        sorting={{ name: 'registrationNumber', activeSorting, toggleSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.createdAt' })}
        sorting={{ name: 'createdAt', activeSorting, toggleSorting }}
        minWidth={TableCellWidth.SMALL}
      />
    </TableRow>
  )
}

export default ProductMappingCompaniesHeader
