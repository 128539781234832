import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import ListItemSkeletonWrapper from '@app/src/components/Skeleton/ListItem'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import SustainabilityLibraryCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/SustainabilityLibraryCollection'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { RadioGroupProps } from '@mui/material'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import AssessmentForm from './AssessmentForm'

interface CreateAssessmentFromQuestionnaireProps {
  questionnaireTemplateId: number
}

export interface AssessmentTemplateFormValues {
  name: string
  description: string
  level1Description: string
  level2Description: string
  level3Description: string
  level4Description: string
  level5Description: string
}

const CreateAssessmentFromQuestionnaire: React.FC<CreateAssessmentFromQuestionnaireProps> = ({
  questionnaireTemplateId,
}) => {
  const { data: questionnaireTemplate } = useFetchResourceWithPost<QuestionnaireTemplate>({
    endpoint: endpoints.templateById(questionnaireTemplateId, true),
    body: [
      'creatorOrganization.name',
      'sections.questions.questionType',
      'sections.questions.unit',
      'sections.questions.requestCollection',
      'sections.questions.reportFlagRules',
      'sections.questions.reportFlagRules.creatorOrganization',
      'sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskNaceCodes.naceCode',
      'sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskGuidanceLinks',
      'sections.questions.questionAnswerClassificationRules',
      'sections.questions.options.category',
      'sections.questions.categorizations',
    ],
    key: [FetchKey.OrganizationOwnedTemplates, questionnaireTemplateId],
    options: {
      enabled: Boolean(questionnaireTemplateId),
    },
  })
  const { formatMessage } = useIntl()
  const [intervals, setIntervals] = useState<number>(3)
  const formMethods = useForm<AssessmentTemplateFormValues>()
  const queryClient = useQueryClient()
  const { mutate: createAssessmentTemplate } = useCreateResource()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const questions = questionnaireTemplate?.sections?.flatMap(section => section.questions)
  const history = useHistory()

  const handleIntervalsChange: RadioGroupProps['onChange'] = event => {
    setIntervals(parseInt(event.target.value))
  }

  const onSubmit = (values: AssessmentTemplateFormValues) => {
    if (!questionnaireTemplate) return
    const assessmentTemplate = {
      ...values,
      questionnaireTemplateId: questionnaireTemplate.id,
      levels: intervals,
    }

    const body = {
      assessmentTemplate,
      questionIds: questions?.map(q => q.id) ?? [],
      questionnaireTemplateId: questionnaireTemplate.id,
    }

    createAssessmentTemplate(
      {
        url: endpoints.assessmentTemplate,
        body,
      },
      {
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.OrganizationSettings)
          showSnackbar({
            message: formatMessage({ id: 'assessments.successfullyCreated' }),
            severity: NotificationSeverity.success,
          })
          history.push(
            generatePath(paths.sustainabilityLibrary, {
              configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
            }),
          )
        },
      },
    )
  }

  return (
    <FormProvider {...formMethods}>
      <SustainabilityLibraryCollection
        title={formatMessage({ id: 'assessments.createAssessment' })}
        actionButtons={
          questionnaireTemplate
            ? [
                {
                  label: formatMessage({ id: 'general.cancel' }),
                  to: generatePath(paths.sustainabilityLibrary, {
                    configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
                  }),
                },
                {
                  label: formatMessage({ id: 'resourceCollections.general.create' }),
                  onClick: formMethods.handleSubmit(onSubmit),
                  variant: 'contained',
                },
              ]
            : undefined
        }
      >
        {!questionnaireTemplate ? (
          <ListItemSkeletonWrapper />
        ) : (
          <AssessmentForm intervals={intervals} onIntervalsChange={handleIntervalsChange} questions={questions} />
        )}
      </SustainabilityLibraryCollection>
    </FormProvider>
  )
}

export default CreateAssessmentFromQuestionnaire
