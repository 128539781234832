import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { FiltersProps } from '@app/src/pages/ResourceCollection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import React, { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'

type DrawerViewFiltersProps = Omit<DrawerViewProps, 'title' | 'subtitle'> & FiltersProps

const DrawerViewFilters: React.FC<PropsWithChildren<DrawerViewFiltersProps>> = ({
  allowedFilters,
  resetPage,
  children,
  ...props
}) => {
  const { closeDrawer } = useDrawer()
  const { formatMessage } = useIntl()

  return (
    <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
      {({ clearQueryFilter, inDrawerFiltersCount, isFiltered }) => (
        <DrawerView
          title={formatMessage({ id: 'filters.moreFilters' })}
          buttons={[
            {
              label: formatMessage({ id: 'filters.clearAll' }),
              onClick: () => {
                clearQueryFilter()
                closeDrawer()
              },
              variant: 'text',
              disabled: !isFiltered,
            },
            {
              label: formatMessage(
                { id: 'filters.applyFilter' },
                {
                  count: inDrawerFiltersCount,
                },
              ),
              onClick: closeDrawer,
              variant: 'contained',
              disabled: !inDrawerFiltersCount,
            },
          ]}
          {...props}
        >
          {children}
        </DrawerView>
      )}
    </Filters>
  )
}

export default DrawerViewFilters
