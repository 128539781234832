import endpoints from '@app/src/api/endpoints'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { Organization } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { ArrowBack, BusinessOutlined, LanguageOutlined, NumbersOutlined, PlaceOutlined } from '@mui/icons-material'
import { Button, List, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import OrganizationRequestSuccess from './OrganizationRequestSuccess'

type OrganizationRequestAccessProps = {
  organization: Organization
  onBack: () => void
  onClose: () => void
  referralCode?: string
}

type SuggestedContact = {
  firstName: string
  email: string
  lastName: string
  organizationId: number
  referralCode?: string
}

const OrganizationRequestAccess: React.FC<OrganizationRequestAccessProps> = ({
  organization: existingOrganization,
  onBack,
  onClose,
  referralCode,
}) => {
  const { formatMessage } = useIntl()
  const { account, trackEvent } = useAmplitude()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { mutate: mutateRequestAccess } = useUpdateResource<SuggestedContact>()
  const { showErrorNotification } = useErrorNotification()
  const confirm = useConfirm()
  const [success, setSuccess] = useState(false)

  const handleRequestAccess = () => {
    mutateRequestAccess(
      {
        url: endpoints.suggestedContact,
        body: {
          email: account?.user.email ?? '',
          firstName: account?.user.givenName ?? '',
          lastName: account?.user.familyName ?? '',
          organizationId: existingOrganization.id,
          referralCode: referralCode,
        },
      },
      {
        onSuccess: () => {
          trackEvent({
            name: AmplitudeTrackingEvents.Onboarding.AddOrganization.SubmittedAccessRequest,
            eventProps: {
              is_first_org: !accessibleOrganizations.length,
            },
          })
          setSuccess(true)
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const handleConfirm = () => {
    confirm({
      description: formatMessage({ id: 'claim.createClaim.requestAccessStep.confirmDescription' }, { br: <br /> }),
      confirmationButtonProps: { variant: 'text' },
      cancellationButtonProps: { variant: 'text' },
    }).then(handleRequestAccess)
  }

  if (success) {
    return <OrganizationRequestSuccess companyName={existingOrganization.name} onClose={onClose} />
  }

  return (
    <OnboardingForm header={existingOrganization.name} subheader={existingOrganization.country?.name}>
      <List disablePadding>
        <ListItem disableGutters disablePadding>
          <ListItemAvatar>
            <LanguageOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.websiteAddress' })}
            secondary={existingOrganization.websiteAddress}
          />
        </ListItem>
        <ListItem disableGutters disablePadding>
          <ListItemAvatar>
            <BusinessOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.industries' })}
            secondary={existingOrganization.industryNaceCode?.description || formatMessage({ id: 'general.notAdded' })}
          />
        </ListItem>
        <ListItem disableGutters disablePadding>
          <ListItemAvatar>
            <NumbersOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.vatNumber' })}
            secondary={existingOrganization.vatNumber || formatMessage({ id: 'general.notAdded' })}
          />
        </ListItem>
        <ListItem disableGutters disablePadding>
          <ListItemAvatar>
            <PlaceOutlined color="action" />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.organization.address' })}
            secondary={existingOrganization.locations?.[0]?.address || formatMessage({ id: 'general.notAdded' })}
          />
        </ListItem>
      </List>

      <Stack pt={2} direction="row" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onBack}>
          {formatMessage({ id: 'general.back' })}
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          {formatMessage({ id: 'claim.createClaim.existingOrganizationStep.requestAccess' })}
        </Button>
      </Stack>
    </OnboardingForm>
  )
}

export default OrganizationRequestAccess
