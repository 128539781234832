import ActionButtons, { ActionButtonsProps } from '@app/src/components/ActionButtons'
import Tabs, { TabsProps } from '@app/src/components/Tabs'
import ConfigurationBreadcrumbs from '@app/src/pages/Configurations/ConfigurationBreadcrumbs'
import { ConfigurationsParams } from '@app/src/pages/Configurations/ConfigurationsScene'
import { Box, Stack, Typography } from '@mui/material'
import React, { PropsWithChildren, ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { Redirect, generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'

const DEFAULT_PADDING: EnablePaddingProps = {
  top: true,
  right: false,
  bottom: true,
  left: true,
}

type EnablePaddingProps = {
  top?: boolean
  right?: boolean
  bottom?: boolean
  left?: boolean
}

type ConfigurationCollectionProps = {
  title?: string
  secondaryTitle?: ReactNode
  actionTabs?: TabsProps['tabs']
  actionButtons?: ActionButtonsProps['buttons']
  enablePadding?: EnablePaddingProps
}

const ConfigurationCollection: React.FC<PropsWithChildren<ConfigurationCollectionProps>> = ({
  title,
  secondaryTitle,
  actionTabs,
  actionButtons,
  children,
  enablePadding,
}) => {
  const paddingEnabled: EnablePaddingProps = {
    top: enablePadding?.top ?? DEFAULT_PADDING.top,
    right: enablePadding?.right ?? DEFAULT_PADDING.right,
    bottom: enablePadding?.bottom ?? DEFAULT_PADDING.bottom,
    left: enablePadding?.left ?? DEFAULT_PADDING.left,
  }
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { path } = useRouteMatch()
  const { search } = useLocation()
  const { configurationsPage, configurationsSubPage } = useParams<ConfigurationsParams>()

  const handleTabChange = (value: string): void => {
    const selectedTab = actionTabs?.find(t => t.type === value)

    if (selectedTab) {
      history.push({
        pathname: generatePath(path, {
          configurationsPage,
          configurationsSubPage: selectedTab.url,
        }),
        search: selectedTab.skipQueryParams ? undefined : search,
      })
    }
  }

  if (actionTabs && !configurationsSubPage) {
    return (
      <Redirect
        to={{
          pathname: generatePath(path, {
            configurationsPage,
            configurationsSubPage: actionTabs[0].url,
          }),
          search,
        }}
      />
    )
  }

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden" height="100%">
      <Helmet>
        <title>{title ?? formatMessage({ id: `configurations.${configurationsPage}` })}</title>
      </Helmet>

      <Box px={4} bgcolor="background.default">
        <ConfigurationBreadcrumbs
          path={path}
          subPageTitle={title}
          configurationsPage={configurationsPage}
          subPage={configurationsSubPage}
        />

        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h1">{title ?? formatMessage({ id: `configurations.${configurationsPage}` })}</Typography>
          <Stack direction="row" spacing={1}>
            <ActionButtons buttons={actionButtons ?? []} />
          </Stack>
        </Box>

        {secondaryTitle}

        {actionTabs && (
          <Box mt={1}>
            <Tabs
              activeTab={configurationsSubPage}
              tabs={actionTabs}
              handleChange={(_, value) => handleTabChange(value)}
            />
          </Box>
        )}
      </Box>

      <Box
        mt={!actionTabs ? 4 : 0}
        pl={paddingEnabled.left ? 4 : 0}
        pr={paddingEnabled.right ? 4 : 0}
        bgcolor="grey.100"
        flexGrow={1}
        display="flex"
        flexDirection="column"
      >
        <Box
          pt={paddingEnabled.top ? 4 : 0}
          pb={paddingEnabled.bottom ? 4 : 0}
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default ConfigurationCollection
