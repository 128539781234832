import { ThemeOptions } from '@mui/material/styles'
import MuiAlert from './MuiAlert'
import MuiAlertTitle from './MuiAlertTitle'
import MuiAutocomplete from './MuiAutocomplete'
import MuiAvatar from './MuiAvatar'
import MuiButton from './MuiButton'
import MuiButtonBase from './MuiButtonBase'
import MuiChip from './MuiChip'
import MuiDrawer from './MuiDrawer'
import MuiFormHelperText from './MuiFormHelperText'
import MuiInputBase from './MuiInputBase'
import MuiInputLabel from './MuiInputLabel'
import MuiMenuItem from './MuiMenuItem'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiPaper from './MuiPaper'
import MuiSelect from './MuiSelect'
import MuiTab from './MuiTab'
import MuiTableCell from './MuiTableCell'
import MuiTableContainer from './MuiTableContainer'
import MuiToggleButton from './MuiToggleButton'
import MuiTooltip from './MuiTooltip'

export const components: ThemeOptions['components'] = {
  MuiAutocomplete,
  MuiButtonBase,
  MuiButton,
  MuiToggleButton,
  MuiPaper,
  MuiDrawer,
  MuiChip,
  MuiAvatar,
  MuiTooltip,
  MuiAlert,
  MuiAlertTitle,
  MuiTableContainer,
  MuiTableCell,
  MuiInputLabel,
  MuiFormHelperText,
  MuiOutlinedInput,
  MuiMenuItem,
  MuiTab,
  MuiInputBase,
  MuiSelect,
}
