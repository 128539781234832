import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Unit } from '@app/src/types/resourceExplorer'
import { useMemo } from 'react'

export const convertValueToUnit = (value: number, originalUnit?: Unit, newUnit?: Unit) => {
  if (!originalUnit?.baseUnitFactor || !newUnit?.baseUnitFactor) return value
  return (value * originalUnit.baseUnitFactor) / newUnit.baseUnitFactor
}

export const useDisplayValueWithUnit = () => {
  const twelveHours = 12 * 60 * 60 * 1000

  const { items: units, isLoading } = useFetchCollectionWithPost<Unit>({
    key: FetchKey.Unit,
    endpoint: endpoints.units,
    payload: {
      filter: [
        {
          name: 'baseUnitId',
          filters: [{ operator: Operators.IsNotNull }],
        },
      ],
      include: [],
    },
    options: { refetchOnMount: false, cacheTime: twelveHours, staleTime: twelveHours },
  })

  const unitsPerBaseUnitIdSorted = useMemo(() => {
    const unitsPerBaseUnit = units.reduce((acc, unit) => {
      if (!unit?.baseUnitId || !unit?.baseUnitFactor) return acc

      if (!acc.has(unit.baseUnitId)) {
        acc.set(unit.baseUnitId, [unit])
      } else {
        acc.get(unit.baseUnitId)?.push(unit)
      }
      return acc
    }, new Map<number, Unit[]>())

    unitsPerBaseUnit.forEach((units, baseUnitId) => {
      unitsPerBaseUnit.set(
        baseUnitId,
        units.sort((a, b) => (b.baseUnitFactor ?? 1) - (a.baseUnitFactor ?? 1)),
      )
    })

    return unitsPerBaseUnit
  }, [isLoading])

  const getValueWithOptimalUnit = (value: number, originalUnit?: Unit) => {
    if (!originalUnit) return { value, unit: originalUnit }
    const bestUnit = getOptimalUnit(value, originalUnit)
    const valueInBestUnit = convertValueToUnit(value, originalUnit, bestUnit)
    return { value: valueInBestUnit, unit: bestUnit }
  }

  const getOptimalUnit = (value: number, originalUnit: Unit) => {
    if (!originalUnit?.baseUnitId || !originalUnit?.baseUnitFactor) return originalUnit

    const unitsPerBaseUnit = unitsPerBaseUnitIdSorted.get(originalUnit?.baseUnitId)
    if (!unitsPerBaseUnit) return originalUnit
    const valueInBaseUnit = value * (originalUnit.baseUnitFactor ?? 1)

    const largestUnitWhereValueIsMoreThanOne = unitsPerBaseUnit.find(un => valueInBaseUnit >= (un.baseUnitFactor ?? 1))
    const smallestUnit = unitsPerBaseUnit.at(-1)
    return largestUnitWhereValueIsMoreThanOne ?? smallestUnit ?? originalUnit
  }

  const getOptimalUnitForMultipleValues = (values: number[], originalUnit?: Unit) => {
    if (!originalUnit?.baseUnitId || !originalUnit?.baseUnitFactor) return originalUnit

    const bestUnitsPerValue = values.filter(v => v).map(value => getOptimalUnit(value, originalUnit))

    const sortedUnits = bestUnitsPerValue.sort((a, b) => (a.baseUnitFactor ?? 1) - (b.baseUnitFactor ?? 1))
    const medianIndex = Math.floor((sortedUnits.length - 1) / 2)
    return sortedUnits?.[medianIndex]
  }

  return {
    getValueWithOptimalUnit,
    getOptimalUnitForMultipleValues,
    isLoadingUnits: isLoading,
  }
}
