import SimpleSelect, { SimpleSelectProps } from '@app/src/components/Form/Select/SimpleSelect'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ProductStatus } from '@app/src/types/product'

interface Props {
  onChange: SimpleSelectProps<ProductStatus>['onChange']
  value: ProductStatus
}

const ProductStatusField: React.FC<Props> = ({ onChange, value }) => {
  const [isOpen, setOpen] = React.useState(false)
  const { formatMessage } = useIntl()
  const { errors } = useFormContext()

  const defaultvalue = {
    label: formatMessage({ id: 'schemas.product.productStatusValues.NotSelected' }),
    value: ProductStatus.NotSelected,
  }

  const options = useMemo(
    () => [
      defaultvalue,
      { label: formatMessage({ id: 'schemas.product.productStatusValues.Active' }), value: ProductStatus.Active },
      { label: formatMessage({ id: 'schemas.product.productStatusValues.Inactive' }), value: ProductStatus.Inactive },
    ],
    [],
  )

  return (
    <SimpleSelect<ProductStatus>
      multiple={false}
      onChange={onChange}
      open={isOpen}
      setOpen={setOpen}
      loading={false}
      options={options}
      fieldLabel={formatMessage({ id: 'schemas.product.productStatus' })}
      value={options.find(opt => opt.value === value) ?? defaultvalue}
      error={errors?.productStatus}
    />
  )
}

export default ProductStatusField
