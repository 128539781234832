import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { capitalize } from '@app/src/utils/helpers'

interface MoveAndDeleteControlsProps {
  onDelete: () => void
  moveUp: () => void
  moveDown: () => void
  index: number
  totalCount: number
  typeName: string
}

export const MoveAndDeleteControls: React.FC<MoveAndDeleteControlsProps> = ({
  onDelete,
  moveUp,
  moveDown,
  index,
  totalCount,
  typeName,
}) => {
  const { formatMessage } = useIntl()

  const isAtTheTop = index <= 0
  const isAtTheBottom = index + 1 >= totalCount
  return (
    <Box display="flex" gap={2}>
      <Tooltip title={formatMessage({ id: 'templateBuilder.fields.controls.delete' }, { type: typeName })}>
        <IconButton size="medium" onClick={onDelete}>
          <DeleteIcon color="primary" fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={capitalize(
          formatMessage(
            {
              id: `templateBuilder.fields.controls.${isAtTheTop ? 'moveUpAlreadyAtTop' : 'moveUp'}`,
            },
            { type: typeName },
          ),
        )}
      >
        <span>
          <IconButton size="medium" onClick={moveUp} disabled={isAtTheTop}>
            <ArrowUpwardIcon color={isAtTheTop ? 'disabled' : 'primary'} fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip
        title={capitalize(
          formatMessage(
            {
              id: `templateBuilder.fields.controls.${isAtTheBottom ? 'moveDownAlreadyAtBottom' : 'moveDown'}`,
            },
            { type: typeName },
          ),
        )}
      >
        <span>
          <IconButton size="medium" onClick={moveDown} disabled={isAtTheBottom}>
            <ArrowDownwardIcon color={isAtTheBottom ? 'disabled' : 'primary'} fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  )
}
