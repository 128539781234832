import { ErrorOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import SimpleRiskChipCell from '@app/src/components/Table/Cells/SimpleRiskChipCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { Risk, RiskStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { br } from '@app/src/utils/translationMarkup'

type FlagAccordionProps = {
  label: string
  risk?: Risk
  country?: string
  isLoading: boolean
}

const getSeverity = (riskStatus?: RiskStatus) => {
  switch (riskStatus) {
    case RiskStatus.Low:
      return 'info'
    case RiskStatus.Medium:
      return 'warning'
    case RiskStatus.High:
      return 'error'
    case RiskStatus.Extreme:
      return 'error'
    default:
      return 'info'
  }
}

const removeDividerLineSx = { '&:before': { position: 'unset' } }

const CountryRiskAccordion: React.FC<FlagAccordionProps> = ({ label, risk, country, isLoading }) => {
  const { formatMessage } = useIntl()

  if (isLoading) {
    return (
      <Box mt={1} display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <Skeleton variant="rounded" width={150} height={20} />
        <Skeleton variant="rounded" width={64} height={24} />
      </Box>
    )
  }

  if (!risk) {
    return null
  }

  return (
    <Accordion elevation={0} disableGutters sx={{ ...removeDividerLineSx, '&.Mui-expanded': { bgcolor: 'grey.100' } }}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined fontSize="medium" sx={{ mx: 1 }} />}
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="subtitle1">{label}</Typography>
          <SimpleRiskChipCell disableCell riskStatus={risk.status} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Alert
          severity={getSeverity(risk?.status)}
          variant="filled"
          icon={<ErrorOutlined />}
          sx={{
            '& .MuiAlert-icon': {
              alignSelf: 'flex-start',
            },
            ...insertIf(risk?.status === RiskStatus.Extreme, {
              bgcolor: 'semantic.error',
              color: 'common.white',
            }),
          }}
        >
          {formatMessage(
            { id: 'schemas.providerRisk.countryRiskInfo' },
            { country, riskStatus: formatMessage({ id: `schemas.risk.${risk.status}` }), riskType: label },
          )}
        </Alert>

        <Typography variant="body1" mt={2} paragraph>
          {formatMessage({ id: `schemas.risk.riskIndexes.${risk.riskType.source}.description` }, { br })}
        </Typography>

        <Link
          href={formatMessage({ id: `schemas.risk.riskIndexes.${risk.riskType.source}.sourceLink` })}
          target="_blank"
        >
          {formatMessage({ id: 'general.learnMore' })}
        </Link>
      </AccordionDetails>
    </Accordion>
  )
}

export default CountryRiskAccordion
