import { useEffect } from 'react'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useAccount } from '@app/src/context/AccountContext'

export default function useLaunchDarkly() {
  const { account } = useAccount()
  const user = account?.user
  const { scope } = useAuthentication()
  const ldClient = useLDClient()

  useEffect(() => {
    if (!user) {
      return
    }

    ldClient?.identify({
      kind: 'multi',
      user: {
        kind: 'user',
        key: `user-key-${user?.id}`,
        email: user?.email?.endsWith('worldfavor.com') ? user.email : '', //only passing worldfavor emails (GDPR)
      },
      organization: {
        kind: 'organization',
        key: `org-key-${scope.organization ? `${scope.organization}` : 'no-org'}`,
        id: scope.organization,
        name: account?.organization?.name,
      },
      solution: {
        kind: 'solution',
        key: `solution-key-${scope.solution}`,
        name: scope.solution,
      },
    })
  }, [user, ldClient, scope])
}
