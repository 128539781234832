import { DateTimeDisplayWithToday } from '@app/src/components/DateDisplay'
import { useReferralContact } from '@app/src/hooks/referralContact'
import { Provider } from '@app/src/types/organizations'
import { EmailType, getPastAndFutureReferralEmails } from '@app/src/utils/referralContactEmails'
import { MailOutlined, ScheduleSendOutlined } from '@mui/icons-material'

import { ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface InviteSentContentProps {
  provider: Provider
}

const toEmailTypeTextId = (emailType: EmailType) => {
  switch (emailType) {
    case EmailType.Invitation:
      return 'referral.invitationSchedule.invitationEmail'
    case EmailType.SentReminder:
    case EmailType.ScheduledReminder:
      return 'referral.invitationSchedule.invitationEmailReminder'
  }
}

const InviteSentContent: React.FC<InviteSentContentProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const { referralContact, isReferralContactsLoading } = useReferralContact(provider.id)
  const emails = getPastAndFutureReferralEmails(referralContact?.referralEmailHistory ?? [])

  if (isReferralContactsLoading) {
    return <Skeleton variant="rectangular" height={80} />
  }

  return (
    <>
      {emails.map(email => {
        const typographyProps = {
          color: email.type === EmailType.ScheduledReminder ? 'textSecondary' : undefined,
        }
        return (
          <ListItem key={email.date.toISOString()}>
            <ListItemIcon>
              {email.type === EmailType.ScheduledReminder ? (
                <ScheduleSendOutlined color="disabled" />
              ) : (
                <MailOutlined />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{}}
              primaryTypographyProps={typographyProps}
              secondaryTypographyProps={typographyProps}
              primary={formatMessage({ id: toEmailTypeTextId(email.type) })}
              secondary={formatMessage(
                {
                  id:
                    email.type === EmailType.ScheduledReminder
                      ? 'referral.invitationSchedule.emailScheduled'
                      : 'referral.invitationSchedule.emailSent',
                },
                { date: <DateTimeDisplayWithToday date={email.date} /> },
              )}
            />
          </ListItem>
        )
      })}
    </>
  )
}

export default InviteSentContent
