import Permissions, { usePermissions } from '@app/src/auth/permissions'
import type { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems/index'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { capitalize } from '@app/src/utils/helpers'
import { insertIf } from '@app/src/utils/helpersTs'
import navbarLinks from '@app/src/wf-constants/navbarLinks'
import MenuWrapper, { MenuWrapperProps } from './MenuWrapper'
import { useFetchAssessmentSettings } from '@app/src/pages/Assessments/useFetchAssessmentSettings'
import { AssessmentType } from '@app/src/types/resourceExplorer'

const AnalyzeMenu: React.FC<NavbarItemProps> = ({ closeMenuDrawer }) => {
  const { formatMessage } = useIntl()
  const { evaluations, assessments } = useFlags()
  const providerType = useCurrentProviderType()
  const { hasPermission } = usePermissions()
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)
  const { assessmentSettings } = useFetchAssessmentSettings()
  const baselineAssessmentIsEnabled =
    assessmentSettings?.find(as => as.assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment)
      ?.isActivated ?? false

  const menuItems: MenuWrapperProps['menuItems'] = [
    {
      title: formatMessage({ id: 'navbar.dataHub' }),
      link: navbarLinks.responseItems,
      permission: Permissions.DATA_HUB_ACCESS,
    },
    ...insertIf(evaluations, {
      title: formatMessage({ id: 'navbar.evaluations' }),
      link: navbarLinks.evaluations,
      permission: Permissions.EVALUATION_ACCESS,
    }),
    {
      title: formatMessage({ id: 'navbar.providerRisk' }, { providerType: capitalize(providerType) }),
      link: navbarLinks.providerRisk,
      permission: Permissions.PROVIDER_RISK_ACCESS,
    },
    {
      title: formatMessage({ id: 'navbar.riskScreening' }),
      link: navbarLinks.riskScreeningHq,
    },
    ...insertIf(canAccessAssessmentTemplate && baselineAssessmentIsEnabled, {
      title: formatMessage({ id: 'navbar.baseAssessment' }),
      link: navbarLinks.baseAssessment,
      permission: Permissions.ASSESSMENT_TEMPLATE_ACCESS,
    }),
    ...insertIf(canAccessAssessmentTemplate && assessments, {
      title: formatMessage({ id: 'navbar.assessments' }),
      link: navbarLinks.assessments,
      permission: Permissions.ASSESSMENT_TEMPLATE_ACCESS,
    }),
  ]

  return (
    <MenuWrapper
      closeMenuDrawer={closeMenuDrawer}
      menuItems={menuItems}
      title={formatMessage({ id: 'navbar.analyze' })}
    />
  )
}

export default AnalyzeMenu
