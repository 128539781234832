export interface Category {
  name: string
  id?: number
  options?: CategoryOption[]
}

export interface CategoryOption {
  name: string
  id: number
  categoryId: number
}

export interface StandardCategory {
  name: ProviderStandardCategoryNames
  id?: number
}

export enum ProviderStandardCategoryNames {
  ActivityStatus = 'activityStatus',
  FinalRiskRating = 'finalRiskRating',
  Priority = 'priority',
  ApprovalStatus = 'providerApprovalStatus',
  Tier = 'tier',
  OwnerUserId = 'ownerUserId',
  CategoryOptionIds = 'categoryOptionIds',
  SupplierUsage = 'supplierUsage',
}

export enum ProviderActivityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Closed = 'Closed',
  NotSelected = 'NotSelected',
}
export enum SupplierUsage {
  Direct = 'Direct',
  Indirect = 'Indirect',
  NotSelected = 'NotSelected',
}
export enum FinalRiskRating {
  NotSelected = 'A_NotSelected',
  Green = 'B_Green',
  Yellow = 'C_Yellow',
  Orange = 'D_Orange',
  Red = 'E_Red',
}
export enum ProviderPriority {
  NotSelected = 'NotSelected',
  NotAPriority = 'NotAPriority',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  VeryHigh = 'VeryHigh',
}

export enum ProviderApprovalStatus {
  Approved = 'Approved',
  ApprovedAfterConsideration = 'ApprovedAfterConsideration',
  NotApproved = 'NotApproved',
  UnderObservation = 'UnderObservation',
  NotSelected = 'NotSelected',
}

export const StandardCategoryOptions = {
  providerActivityStatus: Object.values(ProviderActivityStatus),
  finalRiskRating: Object.values(FinalRiskRating),
  providerApprovalStatus: Object.values(ProviderApprovalStatus),
  providerPriority: Object.values(ProviderPriority),
  supplierUsage: Object.values(SupplierUsage),
  tier: Array.from(Array(9).keys()),
}
