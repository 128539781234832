import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import endpoints from '@app/src/api/endpoints'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'

export const useOrganizationAssessmentSettingsCount = (assessmentTemplateId: number | undefined) => {
  const { count } = useFetchFacets({
    key: [FetchKey.OrganizationAssessmentSettingsFacets],
    endpoint: endpoints.organizationAssessmentSettingsFacet,
    facetsParam: [{ name: 'AssessmentTemplateId', isEnum: true }],
    filter: [
      {
        name: 'AssessmentTemplateId',
        filters: [
          {
            value: assessmentTemplateId,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
    options: { enabled: Boolean(assessmentTemplateId) },
  })

  return count
}
