import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import LinkButton from '@app/src/components/LinkButton'
import StatusChip from '@app/src/components/StatusChip'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ProviderTabs } from '@app/src/pages/ResourceCollection/Collections/Providers/ProvidersScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FinalRiskRating } from '@app/src/types/categories'
import { ProviderFinalRiskRatingValues } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Box, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import StatisticsCard from '../StatisticsCard'

const PROVIDERS_TO_DISPLAY = 20

interface FinalRiskAssessmentCardProps {
  isLoading: boolean
  totalCompanies: number
}

const FinalRiskAssessmentCard: React.FC<FinalRiskAssessmentCardProps> = ({ isLoading, totalCompanies }) => {
  const { formatMessage } = useIntl()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  const {
    facets: [companiesWithNoRiskRating],
    isLoading: companiesWithNoRiskRatingLoading,
  } = useFetchFacets({
    key: [FetchKey.ProvidersByRiskRating, FinalRiskRating.NotSelected],
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'name' }],
    filter: [
      { name: 'finalRiskRating', filters: [{ value: FinalRiskRating.NotSelected, operator: Operators.EqualTo }] },
    ],
  })

  const isStatsLoading = isLoading || companiesWithNoRiskRatingLoading
  const companiesWithNoRiskRatingCount = companiesWithNoRiskRating?.length ?? 0

  const emptyState = !isStatsLoading && (!totalCompanies || companiesWithNoRiskRatingCount === totalCompanies)

  return (
    <StatisticsCard
      title={formatMessage({ id: 'dashboard.sourcing.finalRiskAssessmentTitle' })}
      justifyContent={emptyState ? 'center' : 'left'}
      helperText={formatMessage({ id: 'dashboard.sourcing.finalRiskAssessmentHelperText' })}
      action={
        !emptyState && (
          <LinkButton
            size="small"
            to={stringifyQueryFilters({
              url: generatePath(paths.providers, { view: ProviderTabs.All }),
              queryParams: {
                filters: [
                  {
                    name: 'finalRiskRating',
                    value: [
                      ProviderFinalRiskRatingValues.LowRisk,
                      ProviderFinalRiskRatingValues.MediumRisk,
                      ProviderFinalRiskRatingValues.HighRisk,
                      ProviderFinalRiskRatingValues.ExtremeRisk,
                    ],
                    operator: Operators.In,
                  },
                ],
              },
            })}
          >
            {formatMessage({ id: 'dashboard.investment.seeAll' })}
          </LinkButton>
        )
      }
      loading={{
        isLoading: isStatsLoading,
        skeleton: (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              <Skeleton width={70} variant="rounded" />
            </Typography>

            <Skeleton width={200} />
          </Stack>
        ),
      }}
    >
      <>
        {emptyState ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={2}>
            <EmptyState
              title={formatMessage({ id: 'dashboard.sourcing.finalRiskAssessmentEmptyStateTitle' })}
              iconComponent={ErrorOutlineOutlinedIcon}
              variant={EmptyStateVariant.Small}
            />
          </Box>
        ) : (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              {formatMessage(
                { id: 'dashboard.sourcing.finalRiskAssessmentStatistic' },
                {
                  numberOfFinalRiskRating: totalCompanies - companiesWithNoRiskRatingCount,
                  totalCompanies: totalCompanies,
                },
              )}
            </Typography>
            <Tooltip
              disableHoverListener={!companiesWithNoRiskRatingCount}
              title={
                companiesWithNoRiskRating
                  ?.slice(0, PROVIDERS_TO_DISPLAY)
                  ?.map(provider => provider.label)
                  ?.join(', ') +
                `${
                  companiesWithNoRiskRatingCount > PROVIDERS_TO_DISPLAY &&
                  ` +${companiesWithNoRiskRatingCount - PROVIDERS_TO_DISPLAY} others`
                }`
              }
            >
              <Box sx={{ '&:hover': { backgroundColor: 'grey.100', borderRadius: 4 } }}>
                <StatusChip
                  transparent
                  color={companiesWithNoRiskRatingCount ? 'warning' : 'success'}
                  label={
                    companiesWithNoRiskRatingCount
                      ? formatMessage(
                          { id: 'dashboard.sourcing.companiesWORiskRating' },
                          { count: companiesWithNoRiskRatingCount },
                        )
                      : formatMessage({ id: 'dashboard.sourcing.allDone' })
                  }
                />
              </Box>
            </Tooltip>
          </Stack>
        )}
      </>
    </StatisticsCard>
  )
}

export default FinalRiskAssessmentCard
