import { Box, Divider, Paper } from '@mui/material'
import React, { ReactNode } from 'react'

interface SelectPaperProps {
  children: ReactNode
  modifiers?: JSX.Element
  actionButtons?: JSX.Element
}

const SelectPaper: React.FC<SelectPaperProps> = ({ children, modifiers, actionButtons }) => (
  <Paper elevation={2} square>
    <Box py={1}>
      {modifiers && (
        <>
          {modifiers}
          <Divider />
        </>
      )}
      {children}
      {actionButtons && (
        <>
          <Divider />
          {actionButtons}
        </>
      )}
    </Box>
  </Paper>
)

export default SelectPaper
