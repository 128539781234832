import DrawerView from '@app/src/components/Drawer/DrawerView'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import AnswerValue from '@app/src/pages/Questionnaire/Provider/Report/AnswerValue'
import SkippedQuestionCard from '@app/src/pages/Questionnaire/Provider/Report/SkippedQuestionCard'
import { AnswerStatus, RequestItem, ResponseItem } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { HistoryOutlined } from '@mui/icons-material'
import { alpha, Box, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

interface PreviousAnswerDrawerProps {
  previousAnswer: ResponseItem
  requestItem: RequestItem
  questionType: string
  onApply: () => Promise<void>
  closeDrawer: () => void
  sectionNumber: number
  itemNumber: number
}

const PreviousAnswerDrawer: React.FC<PreviousAnswerDrawerProps> = ({
  previousAnswer,
  requestItem,
  questionType,
  onApply,
  closeDrawer,
  sectionNumber,
  itemNumber,
}) => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const [isApplying, setIsApplying] = useState(false)
  const { trackEvent } = useAmplitude()

  return (
    <DrawerView
      title={formatMessage({ id: 'suggestedAnswers.drawerTitle' })}
      subTitle={`${sectionNumber}.${itemNumber} ${requestItem.description}`}
      stackButtons
      buttons={[
        {
          label: formatMessage({ id: 'suggestedAnswers.ignoreAnswer' }),
          variant: 'text',
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Provider.Questionnaire.PreviousSuggestedAnswers,
              eventProps: {
                action: 'Ignored',
              },
            })
            closeDrawer()
          },
        },
        {
          label: formatMessage({ id: 'suggestedAnswers.applySuggestion' }),
          variant: 'contained',
          loading: isApplying,
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Provider.Questionnaire.PreviousSuggestedAnswers,
              eventProps: {
                action: 'Accepted',
              },
            })

            setIsApplying(true)
            closeDrawer()
            onApply().finally(() => setIsApplying(false))
          },
        },
      ]}
    >
      <Box mx={3} mt={2}>
        <Box display="flex">
          <HistoryOutlined />
          <Typography variant="subtitle1" ml={2}>
            {formatMessage({ id: 'suggestedAnswers.previousAnswer' })}
          </Typography>
        </Box>
        <Box mt={2}>
          {previousAnswer?.answerStatus !== AnswerStatus.Answered ? (
            <SkippedQuestionCard item={previousAnswer} chipVariant="filled" />
          ) : (
            <Box
              sx={{
                py: 1,
                pl: 2,
                width: '100%',
                backgroundColor: alpha(palette.secondary.main, 0.08),
                borderRadius: 1,
              }}
            >
              <AnswerValue item={previousAnswer} questionType={questionType} unitSymbol={requestItem.unit?.symbol} />
            </Box>
          )}
        </Box>
        <Typography color="textSecondary" my={3}>
          {formatMessage(
            { id: 'suggestedAnswers.responseSourceText' },
            {
              requestName: previousAnswer.response.request?.title,
              date: formatDate(previousAnswer.response.submittedAt, true),
              period: previousAnswer.response.request.periodName,
            },
          )}
        </Typography>
      </Box>
    </DrawerView>
  )
}
export default PreviousAnswerDrawer
