import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import AssessmentOverviewHeaderCell from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/AssessmentOverviewHeaderCell'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const AssessmentHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'baseAssessment.topic' })} minWidth={TableCellWidth.EXTRA_SMALL} />
      <AssessmentOverviewHeaderCell
        value={1}
        sorting={{ name: 'section1Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <AssessmentOverviewHeaderCell
        value={2}
        sorting={{ name: 'section2Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <AssessmentOverviewHeaderCell
        value={3}
        sorting={{ name: 'section3Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <AssessmentOverviewHeaderCell
        value={4}
        sorting={{ name: 'section4Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <AssessmentOverviewHeaderCell
        value={5}
        sorting={{ name: 'section5Level', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
    </TableRow>
  )
}

export default AssessmentHeader
