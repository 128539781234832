import { Stack } from '@mui/material'
import React from 'react'

import LoadingButton from '@app/src/components/LoadingButton'
import ButtonSkeletonWrapper from '@app/src/components/Skeleton/Buttons'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import { HistoryStatus } from '@app/src/types/resourceExplorer'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useReport } from '../../ReportContext'
import useProviderQuestionnaire from '../../hooks/useProviderQuestionnaire'
import { FormData } from '../ProviderReport'
import SubmitButton from './SubmitButton'

const ReportButton: React.FC = () => {
  const {
    reportStatus,
    latestHistory,
    isRequestLoading,
    isResponseLoading,
    isRequestsPerPeriodLoading,
    setReuseAllQuestionsState,
    updateResponseIdParam,
  } = useReport()

  const {
    createDraft,
    updateDraft,
    reuseResponse,
    isLoadingCreateDraft,
    isLoadingDeleteDraft,
    isLoadingUpdateSaveDraft,
    isLoadingReuseResponse,
  } = useProviderQuestionnaire()

  const { formatMessage } = useIntl()
  const { getValues, reset } = useFormContext<FormData>()

  const createOrRedirectToDraft = () => {
    setReuseAllQuestionsState(ReuseAllQuestionsStates.Review)
    const existingDraft = latestHistory?.status === HistoryStatus.Draft

    if (existingDraft) {
      updateResponseIdParam(latestHistory.responseId)
    } else {
      createDraft()
    }
  }

  const createDraftFromResponse = async () => {
    await reuseResponse()
  }

  const handleUpdate = async () => {
    const values = getValues()
    await updateDraft(values)
    reset(values)
  }

  if (isRequestLoading || isResponseLoading || isRequestsPerPeriodLoading) {
    return <ButtonSkeletonWrapper />
  }

  switch (reportStatus) {
    case HistoryStatus.Draft:
      return (
        <Stack spacing={2}>
          <SubmitButton />
          <LoadingButton
            variant="outlined"
            onClick={handleUpdate}
            disabled={isLoadingDeleteDraft || isLoadingUpdateSaveDraft}
            loading={isLoadingUpdateSaveDraft}
          >
            {formatMessage({ id: 'questionnaire.actions.save' })}
          </LoadingButton>
        </Stack>
      )

    case HistoryStatus.Submitted:
      return (
        <LoadingButton
          fullWidth
          variant="outlined"
          onClick={createDraftFromResponse}
          loading={isLoadingReuseResponse}
          disabled={isLoadingReuseResponse}
        >
          {formatMessage({ id: 'questionnaire.actions.update' })}
        </LoadingButton>
      )

    default:
      return (
        <LoadingButton
          fullWidth
          variant="contained"
          onClick={createOrRedirectToDraft}
          loading={isLoadingCreateDraft}
          disabled={isLoadingCreateDraft || isLoadingReuseResponse}
        >
          {formatMessage({ id: 'questionnaire.actions.respond' })}
        </LoadingButton>
      )
  }
}

export default ReportButton
