import { Autocomplete } from '@mui/material'
import TextField from '@app/src/components/Ui/TextField'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React, { useEffect, useState } from 'react'
import { Controller, DeepMap, FieldError, FieldValues, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import { geocode, suggest } from '@app/src/utils/ArcGisApi'

interface LocationSuggestion {
  magicKey: string
  text: string
}

export interface SearchResult {
  candidates: {
    location: {
      x: number
      y: number
    }
    attributes: {
      LongLabel: string
      Country: string
      Region: string
      Subregion: string
      City: string
      District: string
    }
  }[]
}

interface ControlledLocationFieldProps {
  addressName: string
  locationName: string
  valueSetter: (searchResult: SearchResult) => void
  fieldErrors?: DeepMap<FieldValues, FieldError>
  rules?: { [key: string]: string }
  label?: string
}

const ControlledLocationField: React.FC<ControlledLocationFieldProps> = ({
  label,
  rules,
  addressName,
  locationName,
  valueSetter,
  fieldErrors,
}) => {
  const [searchResults, setSearchResults] = useState<LocationSuggestion[]>([])
  const [search, setSearch] = useState('')
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const { control, setValue, clearErrors, watch } = useFormContext()

  const locationWatch = watch(locationName)

  const fetchAddressSuggestions = useDebouncedCallback(async () => {
    const result = await suggest(search)

    if (result?.suggestions?.length) {
      setSearchResults(result.suggestions)
    } else {
      setSearchResults([])
    }
  }, 200)

  const fetchSelectedLocation = async (
    event: React.SyntheticEvent<Element, Event>,
    value: string | LocationSuggestion | null,
  ) => {
    clearErrors(addressName)
    // This clears the values if we clear the address input field
    if (!value || typeof value === 'string') {
      setValue(locationName, undefined)
      setValue(addressName, undefined)
      return
    }

    const result = await geocode(value.magicKey)

    // If the location search fails we set the address to the input value
    if (result.error) {
      showErrorNotification({ requestError: result.error })
      return
    }
    valueSetter(result)
  }

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (locationWatch?.country) {
      setValue(locationName, undefined)
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    if (search.length) {
      fetchAddressSuggestions()
    } else {
      setSearchResults([])
    }
  }, [search])

  return (
    <Controller
      control={control}
      name={addressName}
      rules={rules}
      render={({ onChange, value }) => (
        <Autocomplete
          freeSolo
          options={searchResults}
          getOptionLabel={option => (typeof option === 'string' ? option : option.text || option.address)}
          noOptionsText={formatMessage({ id: 'general.typeToSearch' })}
          onChange={fetchSelectedLocation}
          filterOptions={options => options}
          isOptionEqualToValue={(option, value) => option.text === value.address}
          value={value || ''}
          renderInput={params => (
            <TextField
              hoveringLabel
              placeholder={formatMessage({ id: 'textFieldPlaceholders.typeToSearch' })}
              label={label}
              type="text"
              required
              error={Boolean(fieldErrors)}
              helperText={fieldErrors?.message}
              onChange={e => {
                onSearchChange(e)
                onChange(e)
              }}
              {...params}
            />
          )}
        />
      )}
    />
  )
}

export default ControlledLocationField
