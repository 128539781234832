import { ReactNode, createContext, useContext } from 'react'
import { DrawerProps } from '@mui/material/Drawer/Drawer'

export type DrawerViewOptions = {
  showAboveDialogs: boolean
  fullScreen: boolean
  anchor: DrawerProps['anchor']
}

export const INITIAL_OPTIONS: DrawerViewOptions = {
  showAboveDialogs: false,
  fullScreen: false,
  anchor: 'right',
}

export type DrawerContextProps = {
  hasPreviousView: boolean
  openDrawer: (view: ReactNode, options?: Partial<DrawerViewOptions>) => void
  goBack: () => void
  closeDrawer: () => void
  options: DrawerViewOptions
  setOptions: (options: Partial<DrawerViewOptions>) => void
}

const DrawerContext = createContext<DrawerContextProps>({
  hasPreviousView: false,
  options: INITIAL_OPTIONS,
  openDrawer: () => undefined,
  goBack: () => undefined,
  closeDrawer: () => undefined,
  setOptions: () => undefined,
})

export const useDrawer = (): DrawerContextProps => {
  return useContext(DrawerContext)
}

export default DrawerContext
