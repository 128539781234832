import { ExportColumn } from '@app/src/types/export'

export const InquiryExportColumnsAccessor: ExportColumn[] = [
  {
    translationId: 'general.id',
    exportPath: 'id',
  },
  {
    translationId: 'schemas.request.requestStatus',
    exportPath: 'status',
  },
  {
    translationId: 'schemas.request.request',
    exportPath: 'template.title',
  },
  {
    translationId: 'schemas.request.periodDisplayName',
    exportPath: 'periodName',
  },
  {
    translationId: 'schemas.request.target',
    exportPath: 'provider.name',
  },
  {
    translationId: 'schemas.request.createdAt',
    exportPath: 'createdAt',
  },
  {
    translationId: 'schemas.request.deadline',
    exportPath: 'deadline',
  },
  {
    translationId: 'schemas.request.externalVerificationComment',
    exportPath: 'request.responses[0].verifications[0].externalVerificationComment',
  },
  {
    translationId: 'schemas.request.responseDate',
    exportPath: 'request.responses[0].submittedAt',
  },
  {
    translationId: 'schemas.request.responseInternalStatus',
    exportPath: 'request.responses[0].verifications[0].internalVerificationStatus',
  },
  {
    translationId: 'schemas.request.internalVerificationComment',
    exportPath: 'request.responses[0].verifications[0].internalVerificationComment',
  },
  {
    translationId: 'schemas.filter.linkStatus',
    exportPath: 'provider.linkStatus',
  },
  {
    translationId: 'schemas.organization.duns',
    exportPath: 'provider.duns',
  },
  {
    translationId: 'schemas.contact.contactName',
    exportPath: 'provider.organization.contacts[0].user.name',
  },
  {
    translationId: 'schemas.contact.contactEmail',
    exportPath: 'provider.organization.contacts[0].user.email',
  },
  {
    translationId: 'schemas.response.submittedByUser',
    exportPath: `request.responses[0].history[?(@.status=='Submitted')].creatorUser.name`,
  },
]

export const InquiryExportColumnsTransparency: ExportColumn[] = [
  {
    translationId: 'general.id',
    exportPath: 'id',
  },
  {
    translationId: 'schemas.request.sender',
    exportPath: 'creatorOrganization.name',
  },
  {
    translationId: 'schemas.request.request',
    exportPath: 'template.title',
  },
  {
    translationId: 'schemas.request.periodStartsAt',
    exportPath: 'periodStartsAt',
  },
  {
    translationId: 'schemas.request.periodEndsAt',
    exportPath: 'periodEndsAt',
  },
  {
    translationId: 'schemas.request.responseExternalStatus',
    exportPath: 'request.responseExternalStatus',
  },
  {
    translationId: 'schemas.request.createdAt',
    exportPath: 'createdAt',
  },
  {
    translationId: 'schemas.request.requestStatus',
    exportPath: 'status',
  },
  {
    translationId: 'schemas.request.activatedAt',
    exportPath: 'activatedAt',
  },
  {
    translationId: 'schemas.request.deadline',
    exportPath: 'deadline',
  },
  {
    translationId: 'schemas.request.responder',
    exportPath: 'provider.organization.name',
  },
]
