import { ListAltOutlined } from '@mui/icons-material'
import { Box, Stack, Typography, alpha } from '@mui/material'
import { FetchInfiniteCollectionParameters, useInfiniteFetchCollection } from '@app/src/api/fetchHooks'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import usePagination from '@app/src/hooks/pagination'
import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ProviderRiskStatus, ResponseItem, Risk } from '@app/src/types/resourceExplorer'
import CountryRiskAccordion from './CountryRiskAccordion'
import RiskAccordion, { RiskAccordionProps } from './RiskAccordion'

type RiskSectionProps = {
  risks: Risk[]
  riskCountryName?: string
  fetchData: FetchInfiniteCollectionParameters<ResponseItem>
} & Pick<RiskAccordionProps, 'label' | 'riskStatus'>

const RiskSection: React.FC<RiskSectionProps> = ({ label, risks, riskStatus, riskCountryName, fetchData }) => {
  const { formatMessage } = useIntl()
  const [page, pageSize, setPage] = usePagination({ page: 1, pageSize: 5 })

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, remove } =
    useInfiniteFetchCollection<ResponseItem>({
      ...fetchData,
      payload: {
        ...fetchData.payload,
        pagination: {
          pageNumber: page,
          itemsPerPage: pageSize,
        },
      },
    })

  const items = useMemo(() => data?.pages.flatMap(page => page.items).filter(item => item) ?? [], [data])
  const totalCount = data?.pages?.[0].totalResult ?? items.length
  const isNoData = riskStatus === ProviderRiskStatus.NotApplicable || !totalCount

  const loadMore = (): void => {
    fetchNextPage({ pageParam: page + 1 })
    setPage(page + 1)
  }

  useEffect(() => {
    return () => remove()
  }, [])

  return (
    <Box mt={2}>
      <Typography variant="h6" px={2}>
        {label}
      </Typography>

      {riskStatus !== ProviderRiskStatus.Pending && isNoData && !isLoading ? (
        <Stack alignItems="center" mt={3} mb={2} px={2}>
          <Avatar size={AvatarSize.Medium} sx={({ palette }) => ({ bgcolor: alpha(palette.secondary.main, 0.08) })}>
            <ListAltOutlined color="secondary" />
          </Avatar>
          <Typography mt={1}>{formatMessage({ id: 'schemas.providerRisk.noData' })}</Typography>
        </Stack>
      ) : (
        <RiskAccordion
          label={formatMessage({ id: 'schemas.providerRisk.suggestedRisk' })}
          riskStatus={riskStatus}
          count={totalCount}
          items={items}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          loadMore={loadMore}
        />
      )}

      {risks.map(risk => (
        <CountryRiskAccordion
          key={risk.riskType.source}
          label={formatMessage({
            id: `schemas.risk.riskIndexes.${risk.riskType.source}.label`,
          })}
          risk={risk}
          country={riskCountryName}
          isLoading={isLoading}
        />
      ))}
    </Box>
  )
}

export default RiskSection
