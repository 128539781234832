import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { Verification } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import { useReport } from '../ReportContext'

type UseAccessorQuestionnaire = {
  createVerification: (verification: Partial<Verification>) => void
  createVerificationResponseItem: (verification: Partial<Verification>) => void
  isLoadingCreateVerification: boolean
}

const useAccessorQuestionnaire = (): UseAccessorQuestionnaire => {
  const { request, response } = useReport()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()

  const { mutateAsync: mutateCreateVerification, isLoading: isLoadingCreateVerification } = useCreateResource()

  const createVerification: UseAccessorQuestionnaire['createVerification'] = async verification => {
    await mutateCreateVerification(
      {
        url: endpoints.verifyResponse,
        body: verification,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([FetchKey.Response, Number(response?.id)])
          queryClient.invalidateQueries([FetchKey.RequestHistory, Number(request?.id)])
          queryClient.invalidateQueries(FetchKey.RequestPerPeriod)

          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulVerification' }),
            severity: NotificationSeverity.success,
          })
        },
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  const createVerificationResponseItem: UseAccessorQuestionnaire['createVerificationResponseItem'] =
    async verification => {
      await mutateCreateVerification(
        {
          url: endpoints.verifyResponseItem,
          body: verification,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([FetchKey.Response, Number(response?.id)])
            queryClient.invalidateQueries([FetchKey.RequestHistory, Number(request?.id)])
            queryClient.invalidateQueries(FetchKey.RequestPerPeriod)

            showSnackbar({
              message: formatMessage({ id: 'notifications.successfulVerification' }),
              severity: NotificationSeverity.success,
            })
          },
          onError: error => showErrorNotification({ requestError: error }),
        },
      )
    }

  return { createVerification, createVerificationResponseItem, isLoadingCreateVerification }
}

export default useAccessorQuestionnaire
