import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { RESPONSE_ITEM_PERIOD_SMART_FILTER_SETTING } from '@app/src/pages/ResourceCollection/Filters/Common/SmartFilterSettings'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import SmartFilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/SmartFilterFacetSelect'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Button, Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import PreFetchedMultiFilterFacet from './PreFetchedMultiFilterFacet'

const ProviderProgressFilter: React.FC<FiltersProps> = ({ allowedFilters, implicitFilters, resetPage }) => {
  const { formatMessage } = useIntl()

  return (
    <Grid container spacing={1}>
      <Filters resetPage={resetPage} allowedFilters={allowedFilters}>
        {({ filters, clearQueryFilter }) => {
          const isFiltered = Object.keys(filters).length > 0

          return (
            <>
              <Grid item md={8} sm={12}>
                <Filter name="response.request.template.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <PreFetchedMultiFilterFacet
                      implicitFilters={implicitFilters}
                      endpoint={endpoints.responseItemsWithFacets}
                      filterName={filterName}
                      facetsName="response.request.template.title"
                      onChange={value => onChange(value, true)}
                      fieldLabel={formatMessage({ id: 'dataHub.reports' })}
                      value={[value].flat()}
                      sort={{ target: 'label', order: SortOrder.ASCENDING }}
                    />
                  )}
                </Filter>
              </Grid>

              <Grid item md={2} sm={8}>
                <Filter name="response.request.periodName" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <SmartFilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetSettings={{
                        key: [FetchKey.ResponseItemsFacets, filterName],
                        ...RESPONSE_ITEM_PERIOD_SMART_FILTER_SETTING,
                      }}
                      filterName={filterName}
                      onChange={(value: string[]) => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>

              <Grid item md={2} sm={4}>
                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default ProviderProgressFilter
