import { TableRow } from '@mui/material'
import { mapSuggestedContactStatusToSeverity } from '@app/src/components/Drawer/Views/DrawerViewSuggestedContactReview'
import ChipCell from '@app/src/components/Table/Cells/ChipCell'
import EmailCell from '@app/src/components/Table/Cells/EmailCell'
import SuggestedContactCell from '@app/src/components/Table/Cells/SuggestedContactCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { SuggestedContact } from '@app/src/types/resourceExplorer'

interface SuggestedContactRowProps {
  row: SuggestedContact
}

export const SuggestedContactRow: React.FC<SuggestedContactRowProps> = ({ row: suggestedContact }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <SuggestedContactCell contact={suggestedContact} isDisabled drawer />
      <EmailCell mail={suggestedContact.email} />
      <ChipCell
        value={formatMessage({ id: `suggestedContact.contactStatuses.${suggestedContact.status}` })}
        color={mapSuggestedContactStatusToSeverity(suggestedContact.status)}
        size="small"
      />
    </TableRow>
  )
}

export default SuggestedContactRow
