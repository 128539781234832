import { SvgIcon } from '@mui/material'
import React from 'react'

export const TransparencyInternationalLogoIcon = () => {
  return (
    <SvgIcon fontSize="large">
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1944_23932)">
          <path
            d="M18.6863 29.5566C17.3082 28.5349 16.6336 24.5734 16.6336 21.7349C16.6336 21.3011 16.6577 20.877 16.6963 20.4529H16.6674C14.6339 20.4529 12.6824 20.2505 10.8562 19.8795C10.6586 22.8192 11.6898 26.8529 12.6053 29.2915C12.4222 29.2433 12.2391 29.1999 12.0608 29.1469C10.7646 26.3614 9.95513 22.8674 9.8443 19.0554C11.7958 19.5807 13.9931 19.8409 16.3156 19.7638C23.8711 19.5132 29.9232 15.8023 30.2027 11.3638C30.5496 12.6072 30.7424 13.918 30.7424 15.277C30.7424 16.9445 30.4581 18.5397 29.9425 20.0337C28.2319 24.959 23.9482 28.6746 18.696 29.5662M5.04502 10.0867C4.3897 9.46982 3.91748 8.79512 3.6621 8.07705C5.28113 5.25295 7.82533 3.02162 10.8803 1.80235C8.29755 3.86018 6.2593 6.73247 5.04502 10.0867ZM1.74913 15.2722C1.74913 14.4048 1.83105 13.5614 1.97561 12.7373C2.23099 13.8939 2.87668 14.9638 3.83557 15.9036C3.80666 16.3517 3.78738 16.8048 3.78738 17.2674C3.78738 19.8264 4.24032 22.265 5.04984 24.4867C2.9875 21.9807 1.74432 18.771 1.74432 15.2722M24.3963 2.38066C24.3626 2.36138 24.3963 2.38548 24.3674 2.3662L23.9627 3.00717C23.9627 3.00717 23.9867 3.02162 24.0012 3.03126C25.0227 3.68186 25.9624 4.45295 26.7863 5.33488C27.2296 5.80235 27.6392 6.30355 28.0151 6.82885C28.0151 6.83849 28.0151 6.84813 28.0151 6.85777C28.0151 10.477 22.5123 13.4072 15.7278 13.4072C13.7811 13.4072 11.9356 13.1662 10.3021 12.7325C11.4392 6.66982 14.4219 2.05295 18.1033 0.901141C19.3417 1.06018 20.527 1.37825 21.6498 1.82644C21.6498 1.82644 22.016 1.19994 21.9774 1.16138C21.9437 1.12283 21.0185 0.799936 20.5222 0.650539C19.0863 0.226443 17.5684 -0.00488281 15.9976 -0.00488281C15.0483 -0.00488281 14.1184 0.0818642 13.2173 0.2409C5.70516 1.5662 0 8.1156 0 15.9999C0 21.9469 3.2477 27.1325 8.06144 29.8939C9.74311 30.8578 11.6127 31.5228 13.6028 31.8216C14.3834 31.9373 15.1833 31.9999 15.9976 31.9999C21.457 31.9999 26.2756 29.2626 29.1619 25.0843C30.9447 22.5011 31.9952 19.3734 31.9952 15.9951C31.9952 10.2361 28.9547 5.19512 24.3915 2.37584"
            fill="#3A94D1"
          />
        </g>
        <defs>
          <clipPath id="clip0_1944_23932">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default TransparencyInternationalLogoIcon
