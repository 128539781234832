import { TableRow } from '@mui/material'
import React from 'react'

import DateCell from '@app/src/components/Table/Cells/DateCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ReviewTableData } from '.'

interface ReviewTableRowProps {
  row: ReviewTableData
}

export const ReviewTableRow: React.FC<ReviewTableRowProps> = ({ row }) => {
  return (
    <TableRow>
      <ValueCell value={row.period} />
      <ValueCell value={row.company} />
      <DateCell value={row.deadline} />
    </TableRow>
  )
}
