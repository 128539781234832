import Tabs from '@app/src/components/Tabs'
import { Alert, Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import OrgInfoSection from '../../Dashboards/TransparencyDashboard/OrgInfoSection'
import SharedWithNetworkCard from '../../Dashboards/TransparencyDashboard/SharedWithNetworkCard'
import SubscriptionsCard from '../../Dashboards/TransparencyDashboard/SubscriptionsCard'
import EsgLibrary from './EsgLibrary'
import YourData from './YourData'

enum SustainabilityProfilePages {
  ESG_LIBRARY = 'esg-library',
  YOUR_DATA = 'your-data',
}

function SustainabilityProfilePage() {
  const [currentPage, setCurrentPage] = useState<SustainabilityProfilePages>(SustainabilityProfilePages.ESG_LIBRARY)
  const { formatMessage } = useIntl()

  const tabs = [
    {
      type: SustainabilityProfilePages.ESG_LIBRARY,
      label: formatMessage({ id: 'transparencyOverview.esgLibrary' }),
      url: '',
    },
    {
      type: SustainabilityProfilePages.YOUR_DATA,
      label: formatMessage({ id: 'transparencyOverview.yourData' }),
      url: '',
    },
  ]
  return (
    <Stack spacing={4} height="100%">
      <Alert variant="filled" severity="info">
        <Typography variant="subtitle1">
          {formatMessage({ id: 'transparencyOverview.sustainabilityProfileInfoTitle' })}
        </Typography>
        <Typography variant="body2">
          {formatMessage({ id: 'transparencyOverview.sustainabilityProfileInfoDescription' })}
        </Typography>
      </Alert>
      <Typography variant="h1" data-testid="resource-collection-title" px={4}>
        {formatMessage({ id: 'transparencyOverview.sustainabilityProfile' })}
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" px={4} spacing={2}>
        <OrgInfoSection />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <SubscriptionsCard />
          <SharedWithNetworkCard />
        </Stack>
      </Stack>
      <Stack flexGrow={1}>
        <Box px={4}>
          <Tabs
            activeTab={currentPage}
            tabs={tabs}
            handleChange={(_, value) => setCurrentPage(value as SustainabilityProfilePages)}
          />
        </Box>
        {currentPage === SustainabilityProfilePages.ESG_LIBRARY && <EsgLibrary />}
        {currentPage === SustainabilityProfilePages.YOUR_DATA && <YourData />}
      </Stack>
    </Stack>
  )
}

export default SustainabilityProfilePage
