import { Box, TableCell, TableCellProps } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export type ParentCellOrFragmentProps = {
  disableCell?: boolean
  width?: TableCellProps['width']
  onClick?: React.MouseEventHandler<HTMLDivElement>
  sx?: TableCellProps['sx']
} & React.HTMLAttributes<HTMLDivElement>

const ParentCellOrFragment: React.FC<PropsWithChildren<ParentCellOrFragmentProps>> = ({
  disableCell,
  width,
  onClick,
  sx = [],
  children,
  ...props
}) => {
  if (disableCell) {
    return (
      <Box onClick={onClick} sx={[{ cursor: onClick ? 'pointer' : 'default' }, ...[sx].flat()]} {...props}>
        {children}
      </Box>
    )
  }

  return (
    <TableCell
      width={width}
      onClick={onClick}
      sx={[
        {
          cursor: onClick ? 'pointer' : 'default',
        },
        ...[sx].flat(),
      ]}
      {...props}
    >
      {children}
    </TableCell>
  )
}

export default ParentCellOrFragment
