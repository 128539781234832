import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ListItem, ListItemAvatar, ListItemText, TableCell, TableRow } from '@mui/material'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import SwitchCell from '@app/src/components/Table/Cells/SwitchCell'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { AssessmentType, OrganizationAssessmentSettings } from '@app/src/types/resourceExplorer'

interface OrganizationAssessmentProps {
  row: OrganizationAssessmentSettings
  onToggleActivation?: (organizationAssessmentSettings: OrganizationAssessmentSettings) => Promise<void>
}

export const AssessmentSettingsRow: React.FC<OrganizationAssessmentProps> = ({ row, onToggleActivation }) => {
  const { formatMessage } = useIntl()
  const [isDisabled, setIsDisabled] = useState(false)
  const { assessments } = useFlags()

  if (row.assessmentTemplate.assessmentType === AssessmentType.StandardAssessment && !assessments) {
    return null
  }

  const handleChange = async () => {
    if (!onToggleActivation) return

    const newActivatedState = !row.isActivated
    setIsDisabled(true)

    onToggleActivation(row)
      .then(() => {
        if (newActivatedState) {
          setTimeout(() => setIsDisabled(false), 120000) // 2 minutes
        } else {
          setIsDisabled(false)
        }
      })
      .catch(() => setIsDisabled(false))
  }

  return (
    <TableRow>
      <SwitchCell
        sx={{ ml: 2 }}
        disabled={isDisabled}
        checked={row.isActivated}
        onChange={handleChange}
        label={formatMessage({ id: row.isActivated ? 'automations.on' : 'automations.off' })}
      />
      <TableCell>
        <ListItem component="div">
          <ListItemAvatar>
            <Avatar
              size={AvatarSize.XXL}
              sx={({ palette }) => ({
                backgroundColor: row.isActivated ? palette.secondary.main : palette.grey[400],
                color: row.isActivated ? palette.common.white : palette.grey[600],
                mr: 2,
              })}
            >
              <FactCheckOutlinedIcon fontSize="large" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={row.assessmentTemplate.name} secondary={row.assessmentTemplate.description} />
        </ListItem>
      </TableCell>
    </TableRow>
  )
}

export default AssessmentSettingsRow
