import Permissions from '@app/src/auth/permissions'
import type { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems/index'
import React from 'react'
import { useIntl } from 'react-intl'
import navbarLinks from '@app/src/wf-constants/navbarLinks'
import { MenuLinkWrapper, MenuLinkWrapperProps } from './MenuWrapper'

const DashboardLink: React.FC<NavbarItemProps> = ({ closeMenuDrawer }) => {
  const { formatMessage } = useIntl()

  const menuItems: MenuLinkWrapperProps['menuItems'] = {
    link: navbarLinks.dashboard,
    permission: [
      Permissions.TRANSPARENCY_USER,
      Permissions.SOURCING_USER,
      Permissions.FINANCE_USER,
      Permissions.INVESTMENT_USER,
    ],
  }

  return (
    <MenuLinkWrapper
      closeMenuDrawer={closeMenuDrawer}
      menuItems={menuItems}
      title={formatMessage({ id: 'general.home' })}
    />
  )
}

export default DashboardLink
