import { Check, Close } from '@mui/icons-material'
import { Box, Chip, Tooltip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { AssessmentRule, ResponseItem } from '@app/src/types/resourceExplorer'

interface AssessedOptionResponseProps {
  responseItem: ResponseItem
  rules?: AssessmentRule[]
}

const AssessedOptionResponse = ({ responseItem, rules }: AssessedOptionResponseProps) => {
  const { formatMessage } = useIntl()

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-end" gap={1}>
      {responseItem.requestItem.template?.requestCollection?.items.map(option => {
        const isSelected = responseItem.selectedOptions.includes(option.value)
        const matchingRules = rules?.filter(
          rule => rule.value.trim().toLowerCase() === option.value.trim().toLowerCase(),
        )
        const hasMatchingRules = Boolean(matchingRules?.length)
        const points = matchingRules?.reduce((a, b) => a + b.points, 0)

        return (
          <Tooltip key={option.value} title={formatMessage({ id: 'baseAssessment.providerPage.points' }, { points })}>
            <Chip
              disabled={!isSelected}
              icon={
                isSelected ? (
                  <Check sx={{ '&.MuiSvgIcon-root': { color: hasMatchingRules ? 'white' : 'primary.dark' } }} />
                ) : (
                  <Close />
                )
              }
              size="small"
              sx={{
                bgcolor: !isSelected ? 'default' : hasMatchingRules ? 'brandDecorative.emerald' : 'error.main',
                color: hasMatchingRules && isSelected ? 'white' : 'primary',
              }}
              label={option.value}
            />
          </Tooltip>
        )
      })}
    </Box>
  )
}

export default AssessedOptionResponse
