import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import AccountCard from '@app/src/pages/Configurations/ConfigurationsPages/Users/AccountCard'
import UserRoleDrawerContent, {
  UserRoleDrawerView,
} from '@app/src/pages/Configurations/ConfigurationsPages/Users/UserRoleDrawerContent'
import { Auth0Account, Auth0User } from '@app/src/pages/Configurations/ConfigurationsPages/Users/UsersConfig'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { UsersOrganization } from '@app/src/types/organizations'
import { Logos } from '@app/src/wf-constants'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Chip, CircularProgress, Drawer, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'

type UserDetailsProps = {
  id: string
}

const UserDetails: React.FC<UserDetailsProps> = ({ id }) => {
  const [isDrawerOpen, openDrawer, closeDrawer] = useDialogState(false)
  const [updatedAccount, updateAccount] = useState<Auth0Account | null>(null)

  const { data, isLoading, isError } = useFetchResource<Auth0User[]>({
    key: [FetchKey.Users, id],
    endpoint: `${endpoints.userManagement}/${id}`,
  })
  const user = data?.[0] ?? null

  if (isLoading)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <CircularProgress />
      </Box>
    )
  if (!user || isError) return <Box>User Not Found</Box>

  const organizations = user.appMetadata.accounts.reduce<UsersOrganization[]>((prev, current) => {
    const i = prev.findIndex(org => org.organizationId === current.organizationId)
    if (i > -1) {
      prev[i].solutions.push({ type: current.solution, role: current.role })
      return prev
    }
    prev.push({
      organizationId: current.organizationId,
      solutions: [
        {
          type: current.solution,
          role: current.role,
        },
      ],
    })
    return prev
  }, [])

  const copyToClipBoard = async (text: string) => {
    await navigator.clipboard.writeText(text)
  }

  const getUpdatedUser = (updatedAccount: Auth0Account, deleteAccount?: boolean): Auth0User => {
    const updatedUser = user
    const i = updatedUser.appMetadata.accounts.findIndex(
      account =>
        account.organizationId === updatedAccount.organizationId && account.solution === updatedAccount.solution,
    )

    if (i < 0 && (updatedAccount.role === '' || updatedAccount.solution === '')) {
      return user
    }
    if (i < 0) {
      updatedUser.appMetadata.accounts.push(updatedAccount)
    }
    if (deleteAccount) {
      updatedUser.appMetadata.accounts.splice(i, 1)
    } else {
      updatedUser.appMetadata.accounts[i] = updatedAccount
    }
    return updatedUser
  }

  return (
    <Box width="100%" px={5} py={2}>
      <Box display="flex" justifyContent="space-between">
        <Tooltip title="Worldfavor employee" placement="bottom-start">
          <Box marginBottom={3} paddingLeft={1} display="flex" alignItems="center">
            <Box marginRight={1}>
              {user.email.endsWith('@worldfavor.com') && (
                <PersonAvatar name={user.userMetadata.givenName} imageUrl={Logos.WorldfavorLogoSquareFilledBlack} />
              )}
            </Box>
            <Typography variant="h1">
              {user.userMetadata.givenName} {user.userMetadata.familyName}
            </Typography>
          </Box>
        </Tooltip>
        <Box>
          <Button variant="contained" startIcon={<AddIcon />} onClick={openDrawer}>
            Add access to an organization
          </Button>
        </Box>
      </Box>
      <Box marginBottom={4} paddingLeft={1} display="flex">
        <Box marginRight={1}>
          <Tooltip title="Email (Click to copy)">
            <Chip onClick={() => copyToClipBoard(user.email)} label={user.email} />
          </Tooltip>
        </Box>
        <Box marginRight={1}>
          <Tooltip title="Worldfavor ID (Click to copy)">
            <Chip onClick={() => copyToClipBoard(user.appMetadata.id.toString())} label={user.appMetadata.id} />
          </Tooltip>
        </Box>
        <Box marginRight={1}>
          <Tooltip title="Auth0 Id (Click to copy)">
            <Chip onClick={() => copyToClipBoard(user.auth0Id)} label={user.auth0Id} />
          </Tooltip>
        </Box>
      </Box>
      <Box>
        {organizations.map(org => (
          <AccountCard key={org.organizationId} organization={org} user={user} getUpdatedUser={getUpdatedUser} />
        ))}
      </Box>

      <Drawer open={isDrawerOpen} onClose={closeDrawer} anchor="right">
        <UserRoleDrawerContent
          closeDrawer={closeDrawer}
          getUpdatedUser={getUpdatedUser}
          user={user}
          drawerView={UserRoleDrawerView.AddOrganization}
          updateAccount={updateAccount}
          updatedAccount={updatedAccount}
        />
      </Drawer>
    </Box>
  )
}

export default UserDetails
