import AssessmentCell from '@app/src/components/Table/Cells/AssessmentCell'
import ChipCell from '@app/src/components/Table/Cells/ChipCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import Assessment from '@app/src/types/assessment'
import { TableRow } from '@mui/material'
import React from 'react'
import { RiskStatus } from '@app/src/types/resourceExplorer'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import SimpleRiskChipCell from '@app/src/components/Table/Cells/SimpleRiskChipCell'

type AssessmentDialogRowProps = {
  row: Assessment
  riskStatus?: RiskStatus
}

export const AssessmentDialogRow = ({ row: assessment, riskStatus }: AssessmentDialogRowProps): JSX.Element => {
  if (riskStatus)
    return (
      <TableRow>
        <CompanyCell company={assessment.provider} />
        <AssessmentCell assessment={assessment} />
        <CountryCell countryCode={assessment.provider.country?.countryCode} />
        <SimpleRiskChipCell riskStatus={riskStatus} />
      </TableRow>
    )

  return (
    <TableRow>
      <CompanyCell company={assessment.provider} />
      <ChipCell label={assessment.periodName} />
      <AssessmentCell assessment={assessment} />
      <DateCell value={assessment.updatedAt} />
    </TableRow>
  )
}

export default AssessmentDialogRow
