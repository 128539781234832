import { Box, Stack } from '@mui/material'
import React from 'react'
import { insertObjIf } from '@app/src/utils/helpersTs'

interface LevelProps {
  value?: number
  size?: 'small' | 'medium' | 'large'
  compressed?: boolean
}

const firstItemBorderRadius = { borderTopLeftRadius: 1, borderBottomLeftRadius: 1 }
const lastItemBorderRadius = { borderTopRightRadius: 1, borderBottomRightRadius: 1 }

const getColor = (i: number, value = 0) => {
  if (i > value) return 'grey.300'
  if (value < 2) return 'semantic.error'
  if (value < 3) return 'error.dark'
  if (value < 4) return 'warning.dark'
  if (value < 5) return 'success.dark'
  return 'brandDecorative.emerald'
}

const getSizeModifier = (size: 'small' | 'medium' | 'large'): number => {
  switch (size) {
    case 'small':
      return 1
    case 'medium':
      return 1.5
    case 'large':
      return 2
  }
}

const LevelBar: React.FC<LevelProps> = ({ value, size = 'small', compressed = false }) => {
  if (!Number.isFinite(value)) return null

  const sizeModfier = getSizeModifier(size)

  return (
    <Stack direction="row" justifyContent="center" aria-hidden="true">
      {Array.from({ length: 5 }, (_, i) => (
        <Box
          key={i}
          sx={{
            height: 6 * sizeModfier,
            width: compressed ? 4 : 8 * sizeModfier,
            mr: 0.125 * sizeModfier,
            backgroundColor: getColor(i + 1, value),
            ...insertObjIf(i === 0, firstItemBorderRadius),
            ...insertObjIf(i === 4, lastItemBorderRadius),
          }}
        />
      ))}
    </Stack>
  )
}

export default LevelBar
