import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import SelectPaper from '@app/src/components/Form/Select/SelectPaper'
import { useAutocompleteStyles } from '@app/src/components/Form/Select/SimpleSelect'
import TextField from '@app/src/components/Ui/TextField'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, AutocompleteGetTagProps, AutocompleteProps, Chip, TextFieldProps, Tooltip } from '@mui/material'
import React, { useCallback, useState } from 'react'

export interface Option {
  label: string
  value: string | number | boolean
  adornment?: string
  additionalText?: string
}

type OnChangeMultiple = (value: string[], option: Option[] | null, replace?: boolean) => void
type OnChangeSingle = (value: string | null, option: Option | null, replace?: boolean) => void

export interface FilterSelectCommonProps {
  options: Option[]
  fieldLabel: string
  required?: boolean
  groupBy?: AutocompleteProps<Option, false, undefined, undefined>['groupBy']
  size?: AutocompleteProps<never, never, never, never>['size']
  actionButtons?: JSX.Element
  modifiers?: JSX.Element
  renderOption?: AutocompleteProps<Option, boolean, false, false, 'div'>['renderOption']
  renderTags?: (value: Option[], getTagProps: AutocompleteGetTagProps) => JSX.Element[]
  variant?: TextFieldProps['variant']
}

interface SingleModeProps {
  multiple: false
  value: string
  onChange: OnChangeSingle
}

interface MultipleModeProps {
  multiple: true
  value: string[]
  onChange: OnChangeMultiple
}

export type FilterSelectTruncateProps = SingleModeProps | MultipleModeProps

export type FilterSelectProps = FilterSelectCommonProps & FilterSelectTruncateProps

const isMultipleValue = (value: Option | Option[] | null): value is Option[] => {
  return Array.isArray(value)
}

const isMultipleStringValue = (value: string | string[] | null): value is string[] => {
  return Array.isArray(value)
}

const isOption = (value: unknown): value is Option => {
  return typeof (value as Option)?.label !== 'undefined' && typeof (value as Option)?.value !== 'undefined'
}

const findValue = (options: Option[], flatValue: string): Option | undefined => {
  return options?.find((option): boolean => option.value.toString() === flatValue)
}

const getSingleSelectValue = (options: Option[], value: string): Option | null => {
  return findValue(options, value) || null
}

const getMultipleSelectValue = (options: Option[], value: string[] = []): Option[] =>
  value.map((flatValueItem: string) => findValue(options, flatValueItem)).filter<Option>(isOption)

export const optionToValue = (option?: Option | null): string => {
  if (!option) return ''
  return (option?.value ?? '').toString()
}

export const optionToValueArray = (option?: Option | Option[] | null): string[] => {
  if (!option) return []
  return [option].flat().map(option => (option?.value ?? '').toString())
}

function FilterSelect({
  value,
  options,
  fieldLabel,
  required,
  groupBy,
  onChange,
  multiple,
  size,
  modifiers,
  actionButtons,
  renderOption,
  renderTags,
  variant = 'outlined',
}: FilterSelectProps): JSX.Element {
  const classes = useAutocompleteStyles()
  const [open, setOpen] = useState(false)

  const defaultRenderOption: FilterSelectCommonProps['renderOption'] = (props, option, { selected }) => (
    <li {...props}>
      <OptionIcon selected={selected} multiple={multiple} />
      {option?.label || ''}
      <OptionAdornment option={option} />
    </li>
  )

  // here we are making sure we have the correct combination of types
  const truncateProps: { multiple: true; value: Option[] } | { multiple: false; value: Option | null } =
    isMultipleStringValue(value) && multiple
      ? {
          multiple: true,
          value: getMultipleSelectValue(options, value),
        }
      : {
          multiple: false,
          value: getSingleSelectValue(options, value as string),
        }

  const PaperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    ({ children }) => (
      <SelectPaper actionButtons={actionButtons} modifiers={modifiers}>
        {children}
      </SelectPaper>
    ),
    [actionButtons, modifiers],
  )

  return (
    <>
      <Autocomplete
        {...truncateProps}
        data-testid={`autocomplete-filter-${fieldLabel}`}
        size={size}
        clearOnBlur={false}
        limitTags={1}
        groupBy={groupBy}
        disableCloseOnSelect={multiple}
        options={options}
        open={open}
        onOpen={(): void => {
          setOpen(true)
        }}
        onClose={(): void => {
          setOpen(false)
        }}
        onFocus={(): void => {
          setOpen(true)
        }}
        onChange={(_event, option) => {
          if ((multiple || isMultipleValue(option)) && option !== null) {
            // let's just get rid of Autocomplete at some point, this is ridiculous
            const onChangeMultiple: OnChangeMultiple = onChange as OnChangeMultiple
            onChangeMultiple(optionToValueArray(option), [option].flat())
          } else {
            const onChangeSingle: OnChangeSingle = onChange as OnChangeSingle
            onChangeSingle(optionToValue(option), option)
          }
        }}
        getOptionLabel={option => option.label}
        renderInput={(params): JSX.Element => (
          <TextField variant={variant} label={fieldLabel} required={required} {...params} />
        )}
        renderOption={renderOption ?? defaultRenderOption}
        classes={{
          clearIndicator: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
          paper: classes.paper,
        }}
        popupIcon={<ExpandMoreIcon />}
        renderTags={
          renderTags ??
          ((value, getTagProps): JSX.Element[] =>
            value?.map(
              (option, index) =>
                option && (
                  <Tooltip
                    key={option?.value?.toString() || index}
                    title={option.label}
                    classes={{ tooltipPlacementBottom: classes.tooltip }}
                    arrow
                  >
                    <Chip label={option.label} size="small" {...getTagProps({ index })} />
                  </Tooltip>
                ),
            ))
        }
        PaperComponent={PaperComponent}
      />
    </>
  )
}

export default FilterSelect
