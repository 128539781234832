import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { ActionButtonsProps } from '@app/src/components/ActionButtons'
import DrawerTemplate from '@app/src/components/Drawer_DEPRECATED/DrawerTemplate'
import SimpleSelect from '@app/src/components/Form/Select/SimpleSelect'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { Option } from '@app/src/hooks/selectOptions'
import OrganizationSelect from '@app/src/pages/Configurations/ConfigurationsPages/Users/OrganizationSelect'
import { Auth0Account, Auth0User } from '@app/src/pages/Configurations/ConfigurationsPages/Users/UsersConfig'
import { Organization } from '@app/src/types/organizations'
import { insertIf } from '@app/src/utils/helpersTs'
import { NotificationSeverity, SolutionTitles } from '@app/src/wf-constants'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

export enum UserRoleDrawerView {
  ChangeRole,
  DeleteRole,
  AddRole,
  AddOrganization,
}
interface Props {
  drawerView: UserRoleDrawerView
  updateAccount: React.Dispatch<React.SetStateAction<Auth0Account | null>>
  orgData?: Organization
  user: Auth0User
  updatedAccount: Auth0Account | null
  closeDrawer: () => void
  availableSolutions?: string[]
  getUpdatedUser: (updatedAccount: Auth0Account, deleteAccount?: boolean) => Auth0User
}

const ROLE_OPTIONS = [
  { value: 'Contributor', label: 'Contributor' },
  { value: 'Admin', label: 'Admin' },
]

const UserRoleDrawerContent: React.FC<Props> = ({
  drawerView,
  updateAccount,
  orgData,
  user,
  updatedAccount,
  closeDrawer,
  getUpdatedUser,
  availableSolutions = [],
}) => {
  const [isRoleSelectOpen, openRoleSelect] = useState(false)
  const [isSolutionSelectOpen, openSolutionSelect] = useState(false)
  const [newOrganization, setNewOrganization] = useState<Organization | null>(null)
  const { mutate } = useUpdateResource<Auth0User>()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  // showing the WorldfavorAdmin role only if the user is a Worldfavor employee
  const isWorldfavorUser = user.email.endsWith('@worldfavor.com')
  const options: Option<string>[] = [
    ...ROLE_OPTIONS,
    ...insertIf(isWorldfavorUser && orgData?.id === 1, {
      value: 'WorldfavorEmployeeAdmin',
      label: 'Worldfavor Employee Admin',
    }),
  ]

  const changeRole = (deleteRole?: boolean): void => {
    if (updatedAccount) {
      if (updatedAccount.role === 'WorldfavorEmployeeAdmin' && updatedAccount.solution !== 'Default') {
        showSnackbar({
          message: 'Worldfavor Employee Admin role can only be assigned to the "Admin Tools" solution',
          severity: NotificationSeverity.error,
          disableAutoClose: true,
        })
        return
      }
      mutate(
        {
          url: endpoints.userManagement,
          body: getUpdatedUser(updatedAccount, deleteRole),
        },
        {
          onSuccess: () => {
            showSnackbar({
              message: 'The user has been updated',
              severity: NotificationSeverity.success,
              disableAutoClose: true,
            })
            queryClient.invalidateQueries(FetchKey.Users)
            updateAccount(null)
            closeDrawer()
          },
        },
      )
    }
  }

  const getDrawerContent = (): JSX.Element => {
    switch (drawerView) {
      case UserRoleDrawerView.ChangeRole:
        return (
          <>
            <Box marginBottom={4}>
              <Typography variant="body1">
                Do you want to change{' '}
                <b>
                  {user.userMetadata.givenName} {user.userMetadata.familyName}&apos;
                </b>
                s role in the
                <b> {updatedAccount?.solution ?? ''}</b> solution of the organization <b>{orgData?.name}</b>?
              </Typography>
            </Box>
            <SimpleSelect<string>
              onChange={value =>
                updateAccount(prev => {
                  if (prev) {
                    return { ...prev, role: (value as Option<string>).value }
                  }
                  return prev
                })
              }
              open={isRoleSelectOpen}
              setOpen={openRoleSelect}
              loading={false}
              options={options}
              fieldLabel="Role"
              value={options.find(r => r.value === updatedAccount?.role) ?? null}
            />
          </>
        )
      case UserRoleDrawerView.DeleteRole:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              Do you want to remove{' '}
              <b>
                {user.userMetadata.givenName} {user.userMetadata.familyName}&apos;
              </b>
              s access to the
              <b> {updatedAccount?.solution ?? ''}</b> solution of the organization <b>{orgData?.name}</b>?
            </Typography>
            <Typography variant="body1">
              The user won&apos;t have access to this solution, unless someone else invites them again.
            </Typography>
          </>
        )
      case UserRoleDrawerView.AddRole:
        return (
          <>
            <Box marginBottom={3}>
              <Typography variant="body1">
                Select which solution you want to give access to, as well as which role the user will have.
              </Typography>
            </Box>
            <Box marginBottom={2}>
              <SimpleSelect<string>
                onChange={value =>
                  updateAccount(prev => {
                    if (prev) {
                      return { ...prev, solution: (value as Option<string>).value }
                    }
                    return prev
                  })
                }
                open={isSolutionSelectOpen}
                setOpen={openSolutionSelect}
                loading={false}
                options={availableSolutions.map(solution => ({
                  label: SolutionTitles[solution as keyof typeof SolutionTitles],
                  value: solution,
                }))}
                fieldLabel="Solution"
                value={options.find(r => r.value === updatedAccount?.solution) ?? null}
              />
            </Box>
            <SimpleSelect<string>
              onChange={value =>
                updateAccount(prev => {
                  if (prev) {
                    return { ...prev, role: (value as Option<string>).value }
                  }
                  return prev
                })
              }
              open={isRoleSelectOpen}
              setOpen={openRoleSelect}
              loading={false}
              options={options}
              fieldLabel="Role"
              value={options.find(r => r.value === updatedAccount?.role) ?? null}
            />
          </>
        )
      case UserRoleDrawerView.AddOrganization:
        return (
          <>
            <Box marginTop={1} marginBottom={2}>
              <OrganizationSelect
                setValue={(org: Organization | null) => {
                  setNewOrganization(org)
                  updateAccount(prev => {
                    if (!org) return null
                    if (prev) {
                      return { ...prev, organizationId: org.id }
                    }
                    return {
                      organizationId: org.id,
                      solution: '',
                      role: '',
                    }
                  })
                }}
                value={newOrganization}
                user={user}
              />
            </Box>
            <Box marginBottom={2}>
              <SimpleSelect<string>
                onChange={value =>
                  updateAccount(prev => {
                    if (prev) {
                      return { ...prev, solution: (value as Option<string>)?.value }
                    }
                    return prev
                  })
                }
                open={isSolutionSelectOpen}
                setOpen={openSolutionSelect}
                loading={false}
                options={
                  newOrganization?.assignedSolutions?.map(solution => ({
                    label: solution.solutionType,
                    value: solution.solutionType,
                  })) ?? []
                }
                fieldLabel="Solution"
                value={
                  (
                    newOrganization?.assignedSolutions?.map(solution => ({
                      label: solution.solutionType,
                      value: solution.solutionType,
                    })) ?? []
                  ).find(r => r.value === updatedAccount?.solution) ?? null
                }
              />
            </Box>
            <SimpleSelect<string>
              onChange={value =>
                updateAccount(prev => {
                  if (prev) {
                    return { ...prev, role: (value as Option<string>)?.value }
                  }
                  return prev
                })
              }
              open={isRoleSelectOpen}
              setOpen={openRoleSelect}
              loading={false}
              options={options}
              fieldLabel="Role"
              value={options.find(r => r.value === updatedAccount?.role) ?? null}
            />

            {newOrganization && updatedAccount && (
              <Box marginTop={2}>
                {updatedAccount.organizationId && (
                  <>
                    <Typography variant="body1" gutterBottom>
                      You are about to give{' '}
                      <b>
                        {user.userMetadata.givenName} {user.userMetadata.familyName}
                      </b>{' '}
                      access to
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {' '}
                      the organization <b>{newOrganization?.name}</b>
                    </Typography>
                  </>
                )}
                {updatedAccount.solution !== '' ? (
                  <Typography variant="body1" gutterBottom>
                    in the <b>{updatedAccount.solution}</b> solution
                  </Typography>
                ) : (
                  <Typography variant="body1" gutterBottom>
                    You need to select a solution
                  </Typography>
                )}
                {updatedAccount.role !== '' ? (
                  <Typography variant="body1" gutterBottom>
                    with the role <b>{updatedAccount.role}</b>
                  </Typography>
                ) : (
                  <Typography variant="body1" gutterBottom>
                    You need to select a role
                  </Typography>
                )}
              </Box>
            )}
          </>
        )
      default:
        return (
          <Box>
            <Typography variant="body1">
              Oops! Something went a bit wrong, try again, but if it persists reach out to the tech team on{' '}
              <b>#tech-support-new-platform</b>
            </Typography>
          </Box>
        )
    }
  }

  const getButtons = (): ActionButtonsProps['buttons'] => {
    switch (drawerView) {
      case UserRoleDrawerView.ChangeRole:
        return [
          {
            variant: 'outlined',
            onClick: (): void => closeDrawer(),
            label: 'Cancel',
          },
          {
            variant: 'contained',
            onClick: (): void => {
              changeRole()
            },

            label: <>Change role to {options.find(r => r.value === updatedAccount?.role)?.label ?? 'null'}</>,
          },
        ]
      case UserRoleDrawerView.DeleteRole:
        return [
          {
            variant: 'outlined',
            onClick: (): void => closeDrawer(),
            label: 'Cancel',
          },
          {
            variant: 'contained',
            onClick: (): void => {
              changeRole(true)
            },
            disabled: !updatedAccount,
            label: <>Remove user from the {updatedAccount?.solution ?? ''} solution</>,
          },
        ]
      case UserRoleDrawerView.AddRole:
      case UserRoleDrawerView.AddOrganization:
        return [
          {
            variant: 'outlined',
            onClick: (): void => closeDrawer(),
            label: 'Cancel',
          },
          {
            variant: 'contained',
            onClick: (): void => {
              changeRole()
            },
            disabled: !updatedAccount,
            label: <>Give access to the {updatedAccount?.solution ?? ''} solution</>,
          },
        ]
      default:
        return []
    }
  }

  const getTitle = (): string => {
    switch (drawerView) {
      case UserRoleDrawerView.ChangeRole:
        return 'Change role'
      case UserRoleDrawerView.DeleteRole:
        return 'Remove access'
      case UserRoleDrawerView.AddRole:
        return 'Grant access to a solution'
      case UserRoleDrawerView.AddOrganization:
        return 'Grant access to an Organization'
      default:
        return ''
    }
  }

  return (
    <DrawerTemplate title={getTitle()} buttons={getButtons()} closeDrawer={closeDrawer}>
      {getDrawerContent()}
    </DrawerTemplate>
  )
}

export default UserRoleDrawerContent
