import { camelCase } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import { CollectionStyles } from '../Collections_Styles'

interface Props {
  periodTypeName: string
}

const PeriodTypeCell = ({ periodTypeName }: Props): JSX.Element => {
  const classes = CollectionStyles()
  const { formatMessage } = useIntl()
  const periodNameCamelCase = camelCase(periodTypeName)
  return (
    <div className={classes.cell}>{formatMessage({ id: `schemas.request.periodTypes.${periodNameCamelCase}` })}</div>
  )
}

export default PeriodTypeCell
