import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useQueryClient } from 'react-query'
import { AssignAccessorModel, Request } from '@app/src/types/resourceExplorer'

export type UpdateSharingOptions = {
  requestId: number
  organizationIdsToShareWith: number[]
  isAllAccess?: boolean
}

export const useUpdateSharing = () => {
  const { mutateAsync: mutateShareAsync, isLoading: isUpdatingSharing } = useUpdateResource<
    Request,
    AssignAccessorModel
  >()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()

  const updateSharing = async ({
    isAllAccess = false,
    organizationIdsToShareWith,
    requestId,
  }: UpdateSharingOptions) => {
    await mutateShareAsync(
      {
        url: endpoints.assignAccessors,
        body: {
          requestId: requestId,
          accessorOrganizationIds: organizationIdsToShareWith,
          isAllAccess: isAllAccess,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.Response)
          queryClient.invalidateQueries([FetchKey.ResponseSharing, Number(requestId)])
          queryClient.invalidateQueries(FetchKey.RequestPerPeriod)
          queryClient.invalidateQueries(FetchKey.InquiryOpenRequests)
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return { updateSharing, isUpdatingSharing }
}
