import {
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogTitle,
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
} from '@mui/material'
import ActionButtons, { ActionButtonsProps } from '@app/src/components/ActionButtons'
import React, { PropsWithChildren, ReactNode } from 'react'

export type DialogProps = {
  title: ReactNode
  content: ReactNode
  buttons?: ActionButtonsProps['buttons']
  buttonsSx?: DialogActionsProps['sx']
  onFormSubmit?: React.FormEventHandler<HTMLFormElement>
  onClose: () => void
} & Omit<MUIDialogProps, 'onClose' | 'title' | 'children' | 'content'>

const FormWrapper: React.FC<PropsWithChildren<Pick<DialogProps, 'onFormSubmit'>>> = ({ onFormSubmit, children }) => {
  if (onFormSubmit) {
    return <form onSubmit={onFormSubmit}>{children}</form>
  }

  return <>{children}</>
}

const Dialog: React.FC<DialogProps> = ({ title, content, buttons, onClose, onFormSubmit, buttonsSx, ...props }) => (
  <MUIDialog maxWidth="xs" fullWidth onClose={onClose} data-testid="dialog" data-cy="dialog" {...props}>
    <FormWrapper onFormSubmit={onFormSubmit}>
      <DialogTitle data-testid="dialog-title" id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent data-testid="dialog-content">{content}</DialogContent>
      <DialogActions sx={buttonsSx}>{buttons && <ActionButtons buttons={buttons} />}</DialogActions>
    </FormWrapper>
  </MUIDialog>
)

export default Dialog
