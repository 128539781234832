const shadows = [
  'none',
  '',
  '',
  '',
  '',
  '-2px 8px 18px rgba(145, 158, 171, 0.2), 0px 0px 2px rgba(145, 158, 171, 0.24)',
]

export default shadows
