import { useCallback, useReducer } from 'react'
import {
  CLEAN_SORTING,
  SET_SORTING,
  SetSorting,
  Sort,
  SortAction,
  SortOrder,
  ToggleSorting,
  UseSort,
} from '@app/src/types/filter'

const useSort = (defaultSorting = 'id'): UseSort => {
  const INITIAL_STATE: Sort = {
    target: defaultSorting,
    order: SortOrder.DESCENDING,
  }
  function useSortReducer(state: Sort = INITIAL_STATE, action: SortAction): Sort {
    switch (action.type) {
      case SET_SORTING:
        return {
          target: action.sortTarget,
          order: action.sortOrder,
        }
      case CLEAN_SORTING:
        return INITIAL_STATE
      default:
        return state
    }
  }
  const [sorting, dispatch] = useReducer(useSortReducer, INITIAL_STATE)

  const setSorting: SetSorting = useCallback(
    (sortTarget, sortOrder): void => {
      if (!sortOrder) {
        dispatch({ type: CLEAN_SORTING })
      } else {
        dispatch({ type: SET_SORTING, sortTarget, sortOrder })
      }
    },
    [dispatch],
  )

  const toggleOrder = (sortTarget: string): SortOrder | undefined => {
    if (sortTarget !== sorting.target) {
      return SortOrder.ASCENDING
    }
    switch (sorting.order) {
      case null:
        return SortOrder.ASCENDING
      case SortOrder.ASCENDING:
        return SortOrder.DESCENDING
      case SortOrder.DESCENDING:
        if (sortTarget === defaultSorting) {
          // createdAt can never be null as it's the default sorting prop
          return SortOrder.ASCENDING
        }
    }
  }

  const toggleSorting: ToggleSorting = (sortTarget: string): void => {
    const order = toggleOrder(sortTarget)
    setSorting(sortTarget, order)
  }

  return { sorting, setSorting, toggleSorting }
}

export default useSort
