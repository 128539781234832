import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { usePatchUpdateResource } from '@app/src/api/updateHooks'
import { Option } from '@app/src/components/Form/Select'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { ContactPersonInfo } from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/ConnectProvidersModal'
import { ConnectProvidersFormData } from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/hooks/inviteEmail'
import { User } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import { BusinessOutlined } from '@mui/icons-material'
import { Box, Divider, FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import { confirm } from 'material-ui-confirm'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import Select from '../Form/Select/ControlledSelect'
import ImageUpload from '../Image/ImageUpload'
import Avatar, { AvatarSize } from '../Ui/Avatar'
import TextField from '../Ui/TextField'

const MESSAGE_CHARACTER_LIMIT = 1000

function SendEmailCustomization() {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { control, errors, register, watch, setValue, getValues } = useFormContext<ConnectProvidersFormData>()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()

  const customMessageWatcher = watch('customMessage')
  const contactPersonInfoWatcher = getValues('contactPersonInfo')

  const [customMessageEnabled, setCustomMessageEnabled] = useState(Boolean(customMessageWatcher))
  const [contactInfoEnabled, setContactInfoEnabled] = useState(Boolean(contactPersonInfoWatcher))

  const currentOrg = accessibleOrganizations.find(
    org => org?.organization?.id === account?.organization?.id,
  )?.organization

  const handleCustomMessageToggle = () => {
    if (customMessageEnabled) {
      setValue('customMessage', undefined)
    }
    setCustomMessageEnabled(!customMessageEnabled)
  }

  const handleContactInfoToggle = () => {
    if (contactInfoEnabled) {
      setValue('contactPersonInfo', undefined)
    }
    setContactInfoEnabled(!contactInfoEnabled)
  }

  const { mutate: updateOrganizationImage, isLoading: isUpdatingOrganizationImage } = usePatchUpdateResource<
    null,
    { imageUrl: string }
  >({
    options: {
      onSuccess: () => {
        showSnackbar({
          message: formatMessage({ id: 'schemas.organization.update' }),
          severity: NotificationSeverity.success,
        })
        queryClient.invalidateQueries(FetchKey.GetMyOrganization)
        queryClient.invalidateQueries(FetchKey.Account)
        queryClient.invalidateQueries(FetchKey.AccessibleOrganizations)
      },

      onError: error => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const handleUpdateOrganizationImage = (imageUrl: string) => {
    confirm({
      title: formatMessage({ id: 'form.connectProviders.emailPreview.setNewOrgLogoInfo.title' }),
      description: (
        <Stack spacing={2}>
          <Box display="flex" py={1}>
            <Stack p={2} borderRadius={2} sx={({ palette }) => ({ border: `1px solid ${palette.grey[300]}` })}>
              <Avatar size={AvatarSize.XL} variant="rounded" src={imageUrl}>
                <BusinessOutlined />
              </Avatar>
            </Stack>
          </Box>
          <Typography>
            {formatMessage({ id: 'form.connectProviders.emailPreview.setNewOrgLogoInfo.description' })}
          </Typography>
        </Stack>
      ),
      confirmationButtonProps: { disabled: isUpdatingOrganizationImage },
    }).then(() =>
      updateOrganizationImage({
        url: endpoints.updateOwnOrganizationImage,
        body: {
          imageUrl,
        },
      }),
    )
  }

  return (
    <Stack spacing={4}>
      <ImageUpload
        onSuccess={imageUrl => handleUpdateOrganizationImage(imageUrl)}
        currentImageSrc={currentOrg?.image?.url}
      />
      <Divider />
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={handleCustomMessageToggle}
          sx={{ cursor: 'pointer' }}
        >
          <Typography variant="subtitle1">
            {formatMessage({ id: 'form.connectProviders.emailPreview.addCustomMessage' })}
          </Typography>
          <Stack direction="row" alignItems="center" mr={2}>
            <FormControlLabel
              control={<Switch checked={customMessageEnabled} onChange={handleCustomMessageToggle} />}
              label=""
            />
            <Typography ml={-2}>{formatMessage({ id: `general.${customMessageEnabled ? 'yes' : 'no'}` })}</Typography>
          </Stack>
        </Stack>
        {customMessageEnabled && (
          <TextField
            hoveringLabel
            label={formatMessage({ id: 'form.connectProviders.emailPreview.message' })}
            name="customMessage"
            inputRef={register()}
            inputProps={{
              maxLength: MESSAGE_CHARACTER_LIMIT,
            }}
            helperText={formatMessage(
              { id: 'form.connectProviders.emailPreview.messageHelperText' },
              { currentCount: customMessageWatcher?.length ?? 0, limit: MESSAGE_CHARACTER_LIMIT },
            )}
            multiline
            rows={4}
            fullWidth
          />
        )}
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={handleContactInfoToggle}
          sx={{ cursor: 'pointer' }}
        >
          <Typography variant="subtitle1">
            {formatMessage({ id: 'form.connectProviders.emailPreview.displayContactDetails' })}
          </Typography>
          <Stack direction="row" alignItems="center" mr={2}>
            <FormControlLabel
              control={<Switch checked={contactInfoEnabled} onChange={e => e.stopPropagation()} />}
              label=""
            />
            <Typography ml={-2}>{formatMessage({ id: `general.${contactInfoEnabled ? 'yes' : 'no'}` })}</Typography>
          </Stack>
        </Stack>
        {contactInfoEnabled && (
          <Box>
            <Select<ContactPersonInfo, User>
              name="contactPersonInfo"
              fieldLabel={formatMessage({ id: 'schemas.provider.contact' })}
              control={control}
              forceFetch
              navigation={{
                url: endpoints.colleagues,
                type: 'post',
                postObject: {
                  pagination: {
                    itemsPerPage: 9999,
                    pageNumber: 1,
                  },
                  include: [],
                  filter: [],
                },
              }}
              objectToOption={(colleague: User): Option<ContactPersonInfo> => ({
                label: colleague.name,
                value: {
                  name: colleague.name,
                  email: colleague.email,
                  phoneNumber: colleague.phoneNumber,
                },
              })}
              error={errors?.contactPersonInfo}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default SendEmailCustomization
