import { ReferralEmailHistory } from '@app/src/types/resourceExplorer'
import { getDateWithoutTimeFromString, insertIf } from '@app/src/utils/helpersTs'

export enum EmailType {
  Invitation = 'Invitation',
  SentReminder = 'SentReminder',
  ScheduledReminder = 'ScheduledReminder',
}

interface ReferralEmail {
  date: Date
  type: EmailType
}

const UTC_HOUR_TO_SEND_REMINDER = 6

const isPassedDate = (date: Date) => date <= new Date()

const getReminderDate = (date: Date, days: number): Date =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + days, UTC_HOUR_TO_SEND_REMINDER))

const calculatePlannedEmails = (referralEmailHistories: ReferralEmailHistory[]): ReferralEmail[] => {
  const baseDate = referralEmailHistories?.[0]?.createdAt
    ? getDateWithoutTimeFromString(referralEmailHistories[0].createdAt)
    : new Date()
  const firstReminderDate = getReminderDate(baseDate, 7)
  const secondReminderDate = getReminderDate(baseDate, 14)

  const scheduledSentEmailCount = referralEmailHistories.filter(e => e.isScheduled).length

  return [
    ...insertIf(!isPassedDate(secondReminderDate) && scheduledSentEmailCount < 2, {
      date: secondReminderDate,
      type: EmailType.ScheduledReminder,
    }),
    ...insertIf(!isPassedDate(firstReminderDate) && scheduledSentEmailCount < 1, {
      date: firstReminderDate,
      type: EmailType.ScheduledReminder,
    }),
  ]
}

export const getPastAndFutureReferralEmails = (referralEmailHistories: ReferralEmailHistory[]): ReferralEmail[] => {
  const sentEmails = referralEmailHistories
    .map((r, i) => ({
      date: new Date(r.createdAt),
      type: i === 0 ? EmailType.Invitation : EmailType.SentReminder,
    }))
    .toReversed()

  const scheduledPlannedEmails = calculatePlannedEmails(referralEmailHistories)
  return [...scheduledPlannedEmails, ...sentEmails]
}

export const getSentEmailCountOutOfTotalPlanned = (referralEmailHistories: ReferralEmailHistory[]) => {
  const pastAndFutureEmails = getPastAndFutureReferralEmails(referralEmailHistories)
  const sentEmailCount = pastAndFutureEmails.filter(e => e.type !== EmailType.ScheduledReminder).length
  return { sentCount: sentEmailCount, totalCount: pastAndFutureEmails.length }
}
