import TextField from '@app/src/components/Ui/TextField'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface EditTagProps {
  name: string
  defaultValue?: string
}

const EditTag: React.FC<EditTagProps> = ({ defaultValue, name }) => {
  const { formatMessage } = useIntl()
  const { register } = useFormContext()

  return (
    <TextField
      fullWidth
      name={name}
      defaultValue={defaultValue}
      label={formatMessage({ id: 'templateBuilder.advanced.tagTitle' })}
      size="small"
      inputRef={register()}
      inputProps={{
        maxLength: 50,
      }}
    />
  )
}

export default EditTag
