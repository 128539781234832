import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useAccount } from '@app/src/context/AccountContext'
import { useCompaniesStatisticsFilter } from '@app/src/context/CompaniesStatisticsFilterContext'
import { Ownership } from '@app/src/context/CompaniesStatisticsFilterContextProvider'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import StatisticsCard from '@app/src/pages/Dashboards/StatisticsCard'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Box, Button, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import ApprovalStatusGraph from './ApprovalStatusGraph'
import CompaniesList, { companiesListAllowedFilters } from './CompaniesList'
import FiltersSection from './FiltersSection'
import FinalRiskRatingGraph from './FinalRiskRatingGraph'
import PriorityGraph from './PriorityGraph'
import SupplierUsageGraph from './SupplierUsageGraph'
import TiersGraph from './TiersGraph'

export const handleFilterValue = (value: string | string[], newValue: string) => {
  if (!Array.isArray(value)) {
    return [newValue]
  }
  if (!value.includes(newValue)) {
    return [...value, newValue]
  }
  return value.filter(v => v !== newValue)
}

const CompaniesStatistics = () => {
  const { formatMessage } = useIntl()
  const { selectedOwnership, setSelectedOwnership } = useCompaniesStatisticsFilter()
  const filters = useGetApiQueryFilters(companiesListAllowedFilters)
  const [isOwnershipMenuOpen, setIsOwnershipMenuOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { account } = useAccount()
  const { hasPermission } = usePermissions()
  const hasFullDashboardAccess = hasPermission(Permissions.FULL_DASHBOARD_ACCESS)

  return (
    <Filters allowedFilters={companiesListAllowedFilters}>
      {() => (
        <StatisticsCard
          title={formatMessage({ id: 'dashboard.sourcing.companyStatistics.title' })}
          action={
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography color="primary" variant="body1">
                {formatMessage({ id: 'dashboard.sourcing.companyStatistics.ownership' })}
              </Typography>
              <Box>
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  size="small"
                  onClick={() => setIsOwnershipMenuOpen(true)}
                  ref={anchorRef}
                >
                  {formatMessage({ id: `dashboard.sourcing.companyStatistics.${selectedOwnership}` })}
                </Button>
                <Filter operator={Operators.EqualTo} name="ownerUserId">
                  {({ onChange }) => (
                    <Menu
                      open={isOwnershipMenuOpen}
                      anchorEl={anchorRef.current}
                      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                      elevation={1}
                      onClose={() => setIsOwnershipMenuOpen(false)}
                    >
                      <MenuItem
                        onClick={() => {
                          setSelectedOwnership(Ownership.All)
                          onChange(null)
                          setIsOwnershipMenuOpen(false)
                        }}
                      >
                        {formatMessage({ id: `dashboard.sourcing.companyStatistics.all` })}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setSelectedOwnership(Ownership.MyCompanies)
                          onChange(String(account?.user.id))
                          setIsOwnershipMenuOpen(false)
                        }}
                      >
                        {formatMessage({ id: `dashboard.sourcing.companyStatistics.myCompanies` })}
                      </MenuItem>
                    </Menu>
                  )}
                </Filter>
              </Box>
            </Stack>
          }
        >
          <Grid container spacing={4} justifyContent="center" py={hasFullDashboardAccess ? 3 : 0}>
            {hasFullDashboardAccess && (
              <>
                {Boolean(filters.length) && (
                  <Grid item xs={12}>
                    <FiltersSection />
                  </Grid>
                )}
                <Grid item xs={4} lg={2.4}>
                  <PriorityGraph />
                </Grid>
                <Grid item xs={4} lg={2.4}>
                  <TiersGraph />
                </Grid>
                <Grid item xs={4} lg={2.4}>
                  <SupplierUsageGraph />
                </Grid>
                <Grid item xs={6} lg={2.4}>
                  <FinalRiskRatingGraph />
                </Grid>
                <Grid item xs={6} lg={2.4}>
                  <ApprovalStatusGraph />
                </Grid>
              </>
            )}

            <Grid item lg={12}>
              <CompaniesList />
            </Grid>
          </Grid>
        </StatisticsCard>
      )}
    </Filters>
  )
}

export default CompaniesStatistics
