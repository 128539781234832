import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import Dialog from '@app/src/components/Dialog'
import OverTimeLineChart from '@app/src/components/OverTimeCharts/OverTimeLineChart'
import PieChart from '@app/src/components/OverTimeCharts/PieChart'
import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderProgressView } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { BarChart } from '@mui/icons-material'
import { Grid } from '@mui/material'
import qs from 'qs'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { ResponseItemStatisticsByTemplateModel } from '../DataHubScene'

interface TrendCellProps {
  questionType: string
  providerProgressViewData: ProviderProgressView
  periods: string[]
  isMultiSelectOptionQuestion: boolean | undefined
  organizationId?: number
}

export interface StatisticsByQuestionsQuery {
  filters: FilterGroup[]
  targetOptionResult: string
}

const TrendCell: React.FC<TrendCellProps> = ({
  questionType,
  isMultiSelectOptionQuestion,
  providerProgressViewData,
  periods,
  organizationId,
}) => {
  const { formatMessage } = useIntl()
  const [isDialogOpen, openDialog, closeDialog] = useDialogState(false)

  const {
    data: overTimeStatistics,
    isLoading,
    refetch,
  } = useFetchPost<ResponseItemStatisticsByTemplateModel[], StatisticsByQuestionsQuery>({
    payload: {
      filters: [
        {
          name: 'response.request.template.id',
          filters: [
            {
              value: [providerProgressViewData.templateId],
              operator: Operators.In,
            },
          ],
        },
        {
          name: 'requestItem.template.id',
          filters: [
            {
              value: [providerProgressViewData.id.toString()],
              operator: Operators.In,
            },
          ],
        },
        {
          name: 'response.request.periodName',
          filters: [
            {
              value: periods,
              operator: Operators.In,
            },
          ],
        },
        ...(organizationId
          ? [{ name: 'creatorOrganizationId', filters: [{ operator: Operators.EqualTo, value: organizationId }] }]
          : []),
      ],
      targetOptionResult: '',
    },
    endpoint: endpoints.responseItemsWithStatisticsByQuestions,
    key: [FetchKey.RequestTemplateOnOverTimePage],
    options: {
      enabled: false,
    },
  })

  const showTrend = providerProgressViewData.periodDetails.every(
    detail => !detail.cannotAnswer && (detail.answer || Boolean(detail.collectionResponse?.length)),
  )

  useEffect(() => {
    if (isDialogOpen) {
      refetch()
    }
  }, [isDialogOpen])

  if ((questionType === QuestionTypes.Number || isMultiSelectOptionQuestion) && showTrend) {
    return (
      <>
        <ButtonCell
          label={formatMessage({ id: 'general.showTrend' })}
          startIcon={<BarChart />}
          onClick={openDialog}
          loading={isLoading}
        />

        <Dialog
          open={isDialogOpen && !isLoading}
          maxWidth="md"
          fullWidth
          onClose={closeDialog}
          title={overTimeStatistics?.[0]?.title}
          content={
            <Grid container spacing={2}>
              {questionType === QuestionTypes.Options ? (
                overTimeStatistics?.map(statistic => (
                  <Grid item xs={12} sm={overTimeStatistics.length > 1 ? 6 : 12} key={statistic.id}>
                    <PieChart pieChartData={statistic} organizationId={organizationId} />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} sm={12}>
                  <OverTimeLineChart groupedStatistics={overTimeStatistics ?? []} organizationId={organizationId} />
                </Grid>
              )}
            </Grid>
          }
          buttons={[
            {
              label: formatMessage({ id: 'schemas.providerProgress.viewResponse' }),
              to: {
                pathname: paths.reporting,
                search: qs.stringify({
                  requestId: providerProgressViewData.requestId,
                  responseId: providerProgressViewData.responseId,
                }),
              },
            },
            {
              label: formatMessage({ id: 'general.close' }),
              variant: 'contained',
              onClick: closeDialog,
            },
          ]}
        />
      </>
    )
  }

  return <ValueCell value="" />
}

export default TrendCell
