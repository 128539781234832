import { FormControlLabel, Switch, SwitchProps, TableCellProps } from '@mui/material'
import React, { ReactNode } from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'

type SwitchCellProps = {
  label?: ReactNode
  disableCell?: boolean
  sx?: TableCellProps['sx']
} & SwitchProps

const SwitchCell: React.FC<SwitchCellProps> = ({ label, disableCell, ...props }) => {
  return (
    <ParentCellOrFragment disableCell={disableCell} width={20}>
      <FormControlLabel control={<Switch color="primary" size="medium" {...props} />} label={label} />
    </ParentCellOrFragment>
  )
}

export default SwitchCell
