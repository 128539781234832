import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Tabs from '@app/src/components/Tabs'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import StatisticsCard from '@app/src/pages/Dashboards/StatisticsCard'
import { SortOrder } from '@app/src/types/filter'
import { QuestionnaireRequestProgressStatistics } from '@app/src/types/resourceExplorer'
import { Box, Divider, Stack, TablePagination } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import TopicProgressSection from './TopicProgressSection'

enum OverviewRequestProgressViews {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}

interface OverviewRequestProgressProps {
  allowedFilters?: string[]
}

const OverviewRequestProgressSection: React.FC<OverviewRequestProgressProps> = ({ allowedFilters }) => {
  const { formatMessage } = useIntl()
  const [activeView, setActiveView] = useState(OverviewRequestProgressViews.IN_PROGRESS)
  const userFilters = useGetApiQueryFilters(allowedFilters ?? [])

  const [page, pageSize, setPage] = usePagination({ page: 1, pageSize: 20 })

  const { data: inProgressTopics, isLoading: inProgressTopicsIsLoading } =
    useFetchCollectionWithPost<QuestionnaireRequestProgressStatistics>({
      key: [FetchKey.QuestionnaireRequestStatistics, OverviewRequestProgressViews.IN_PROGRESS],
      endpoint: endpoints.requestOverviewStatisticsInProgress,
      payload: {
        filter: userFilters,
        include: [],
        pagination: {
          pageNumber: page,
          itemsPerPage: pageSize,
        },
        sort: {
          target: 'templateTitle',
          order: SortOrder.DESCENDING,
        },
      },
    })

  const { data: completedTopics, isLoading: completedTopicsIsLoading } =
    useFetchCollectionWithPost<QuestionnaireRequestProgressStatistics>({
      key: [FetchKey.QuestionnaireRequestStatistics, OverviewRequestProgressViews.COMPLETED],
      endpoint: endpoints.requestOverviewStatisticsCompleted,
      payload: {
        filter: userFilters,
        include: [],
        pagination: {
          pageNumber: page,
          itemsPerPage: pageSize,
        },
        sort: {
          target: 'templateTitle',
          order: SortOrder.DESCENDING,
        },
      },
    })

  const tabs = [
    {
      type: OverviewRequestProgressViews.IN_PROGRESS,
      label: formatMessage({ id: 'dashboard.inProgressWithCount' }, { count: inProgressTopics?.totalResult ?? 0 }),
      url: '',
    },
    {
      type: OverviewRequestProgressViews.COMPLETED,
      label: formatMessage({ id: 'dashboard.completedWithCount' }, { count: completedTopics?.totalResult ?? 0 }),
      url: '',
    },
  ]

  if (inProgressTopicsIsLoading || completedTopicsIsLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <StatisticsCard title={formatMessage({ id: 'statistics.reportProgressOverview' })}>
      <Stack width="100%" height="100%" justifyContent="space-between">
        <Stack width="100%">
          <Tabs
            activeTab={activeView}
            tabs={tabs}
            handleChange={(_, value) => {
              setActiveView(value as OverviewRequestProgressViews)
              setPage(1)
            }}
          />
          <Divider />
          <Stack>
            {Boolean(inProgressTopics?.items?.length) && activeView === OverviewRequestProgressViews.IN_PROGRESS && (
              <>
                {inProgressTopics?.items?.map((statistics, i) => (
                  <React.Fragment key={statistics.templateId}>
                    <TopicProgressSection statistics={statistics} userFilters={userFilters} />
                    {i !== inProgressTopics.items?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </>
            )}
            {Boolean(completedTopics?.items?.length) && activeView === OverviewRequestProgressViews.COMPLETED && (
              <Box>
                {completedTopics?.items?.map((statistics, i) => (
                  <React.Fragment key={statistics.templateId}>
                    <TopicProgressSection statistics={statistics} userFilters={userFilters} />
                    {i !== completedTopics.items?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Box>
            )}
          </Stack>
        </Stack>
        {(activeView === OverviewRequestProgressViews.IN_PROGRESS
          ? Boolean(inProgressTopics?.items?.length)
          : Boolean(completedTopics?.items?.length)) && (
          <TablePagination
            colSpan={3}
            count={
              activeView === OverviewRequestProgressViews.IN_PROGRESS
                ? inProgressTopics?.totalResult ?? 0
                : completedTopics?.totalResult ?? 0
            }
            rowsPerPage={20}
            page={page - 1}
            onPageChange={(e, page) => setPage(page + 1)}
            component="span"
            rowsPerPageOptions={[20]}
          />
        )}
      </Stack>
    </StatisticsCard>
  )
}

export default OverviewRequestProgressSection
