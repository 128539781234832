import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Report from '@app/src/pages/Questionnaire/Provider/Report'
import ReportContext from '@app/src/pages/Questionnaire/ReportContext'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Question, QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { mapToReportContextPropsForPreview } from '@app/src/utils/previewMapper'
import { CloseOutlined } from '@mui/icons-material'
import { Box, Divider, IconButton, Dialog as MUIDialog, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { FormDataSubmitFriendly } from './TemplateBuilder'

type TemplatePreviewProps = {
  getTemplate: () => FormDataSubmitFriendly
  showPreview: boolean
  onBack: () => void
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({ showPreview, getTemplate, onBack }) => {
  const { formatMessage } = useIntl()
  const [template, setTemplate] = React.useState<FormDataSubmitFriendly | null>(null)

  const questionIds = template?.sections?.flatMap(s => s.questions ?? []).map(q => q.questionId) ?? []
  const canFetchDataForPreview = showPreview && questionIds.length > 0

  React.useEffect(() => {
    if (!showPreview) return
    setTemplate(getTemplate())
  }, [showPreview, getTemplate])

  const { items: questions, isFetching: isFetchingQuestions } = useFetchCollectionWithPost<Question>({
    key: FetchKey.Questions,
    endpoint: endpoints.questionCollection,
    payload: {
      filter: [{ name: 'id', filters: [{ value: questionIds, operator: Operators.In }] }],
      include: ['requestCollection', 'unit', 'questionType'],
    },
    options: { enabled: canFetchDataForPreview },
  })

  const templateAsQuestionnaireTemplate = useMemo(() => {
    if (template === null || isFetchingQuestions) return undefined
    const questionsById = new Map<number, Question>(questions?.map(q => [q.id, q]) ?? [])

    return {
      ...template,
      sections:
        template?.sections.map(s => ({
          ...s,
          questions:
            s.questions?.map(q => {
              return { ...questionsById.get(q.questionId), isRequired: q.isRequired }
            }) ?? [],
        })) ?? [],
    } as unknown as QuestionnaireTemplate
  }, [isFetchingQuestions, Boolean(template)])

  if (!template) {
    return null
  }

  return (
    <MUIDialog open={showPreview} onClose={onBack} maxWidth="md" fullWidth>
      {isFetchingQuestions || !templateAsQuestionnaireTemplate ? (
        <Box height={150} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <ReportContext.Provider value={mapToReportContextPropsForPreview(templateAsQuestionnaireTemplate)}>
          <Box py={4} px={1} display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} px={5}>
              <Typography variant="h6">{formatMessage({ id: 'templateBuilder.preview' })}</Typography>
              <IconButton size="small" onClick={onBack}>
                <CloseOutlined />
              </IconButton>
            </Box>

            <Divider light sx={{ mx: 5 }} />
            <Report />
          </Box>
        </ReportContext.Provider>
      )}
    </MUIDialog>
  )
}

export default TemplatePreview
