import React, { createContext, useContext } from 'react'
import { FieldValues, UnpackNestedValue } from 'react-hook-form'

export interface MultipleStepsFormContextProps {
  values: UnpackNestedValue<FieldValues>
  setValues: React.Dispatch<React.SetStateAction<FieldValues>>
}

const MultipleStepsFormContext = createContext<undefined | MultipleStepsFormContextProps>(undefined)

export function useMultipleStepsFormContext(): MultipleStepsFormContextProps {
  const context = useContext(MultipleStepsFormContext)
  if (context === undefined) {
    throw new Error('useMultipleStepsFormContext must be used within MultipleStepsFormContextProvider')
  }
  return context
}

export default MultipleStepsFormContext
