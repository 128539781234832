import ControlledDateField from '@app/src/components/Form/ControlledDateField'
import { AnswerStatus } from '@app/src/types/resourceExplorer'
import { CheckOutlined } from '@mui/icons-material'
import { Alert, Checkbox, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { FC, default as React } from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import SkipQuestionCommentField from './SkipQuestionCommentField'

interface SkipQuestionOptionProps {
  requestItemId: number
  cannotAnswer: string
  answerStatus: string
  answerStatusComment: string
  hasError: boolean
}

const SkipQuestionOptions: FC<SkipQuestionOptionProps> = ({
  requestItemId,
  cannotAnswer,
  answerStatusComment,
  answerStatus,
  hasError,
}) => {
  const { control, errors, watch, clearErrors, setValue } = useFormContext()
  const { formatMessage } = useIntl()

  const planOnHavingWatch = watch(`${requestItemId}.planOnHaving`)
  const estimatedAnswerAtWatch = watch(`${requestItemId}.estimatedAnswerAt`)
  const answerStatusWatch = watch(answerStatus)

  const handleChange = (value: string) => {
    if (hasError) clearErrors(`${requestItemId}`)
    setValue(answerStatus, value)
    setValue(answerStatusComment, '')
    setValue(`${requestItemId}.estimatedAnswerAt`, null)
    setValue(`${requestItemId}.planOnHaving`, false)
    if (value !== AnswerStatus.Answered) {
      setValue(cannotAnswer, true)
    }
  }

  return (
    <>
      <Stack bgcolor="common.white" p={2} spacing={2}>
        <Controller
          defaultValue={answerStatus}
          name={answerStatus}
          control={control}
          render={({ value }): JSX.Element => (
            <RadioGroup
              onChange={e => handleChange(e.target.value)}
              value={value?.toString()}
              data-testid="skip-question-card"
            >
              <FormControlLabel
                value={AnswerStatus.NotApplicable}
                control={<Radio />}
                label={formatMessage({ id: 'reporting.answerStatus.NotApplicable' })}
                sx={{
                  pb: answerStatusWatch === AnswerStatus.NotApplicable ? 2 : undefined,
                }}
              />
              {answerStatusWatch === AnswerStatus.NotApplicable && (
                <SkipQuestionCommentField
                  requestItemId={requestItemId}
                  answerStatusComment={answerStatusComment}
                  hasError={hasError}
                  label={formatMessage({ id: 'reporting.skipQuestion.addDescription' })}
                />
              )}
              <FormControlLabel
                value={AnswerStatus.NotAvailable}
                control={<Radio />}
                label={formatMessage({ id: 'reporting.answerStatus.NotAvailable' })}
                sx={{
                  pt: 3,
                  pb: answerStatusWatch === AnswerStatus.NotAvailable ? 2 : undefined,
                }}
              />
              {answerStatusWatch === AnswerStatus.NotAvailable && (
                <>
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={({ palette }) => ({ border: `1px solid ${palette.grey[300]}`, borderRadius: 1 })}
                    p={2}
                    mb={2}
                  >
                    <FormControlLabel
                      sx={{ pl: 2 }}
                      control={
                        <Controller
                          control={control}
                          name={`${requestItemId}.planOnHaving`}
                          render={({ onChange, onBlur, value, ref }): JSX.Element => (
                            <Checkbox
                              onBlur={onBlur}
                              color="primary"
                              onChange={(e): void => {
                                onChange(e.target.checked)
                                setValue(`${requestItemId}.estimatedAnswerAt`, null)
                              }}
                              checked={Boolean(value)}
                              inputRef={ref}
                            />
                          )}
                        />
                      }
                      label={formatMessage({ id: 'reporting.planOnHaving' })}
                    />
                    {planOnHavingWatch && (
                      <>
                        <Stack sx={{ px: 10, pb: 2 }}>
                          <Typography gutterBottom variant="caption" color="textSecondary">
                            {formatMessage({ id: 'reporting.plannedDate' })}
                          </Typography>
                          <ControlledDateField
                            disablePast
                            label={formatMessage({ id: 'reporting.selectDate' })}
                            fieldLabel={estimatedAnswerAtWatch ? '' : formatMessage({ id: 'reporting.selectDate' })}
                            control={control}
                            name={`${requestItemId}.estimatedAnswerAt`}
                          />
                        </Stack>
                      </>
                    )}
                  </Stack>
                  <SkipQuestionCommentField
                    requestItemId={requestItemId}
                    answerStatusComment={answerStatusComment}
                    hasError={hasError}
                    label={formatMessage({ id: 'reporting.skipQuestion.addDescription' })}
                  />
                </>
              )}
              <FormControlLabel
                value={AnswerStatus.Other}
                control={<Radio />}
                label={formatMessage({ id: 'reporting.answerStatus.Other' })}
                sx={{
                  pt: 3,
                  pb: answerStatusWatch === AnswerStatus.Other ? 2 : undefined,
                }}
              />
              {answerStatusWatch === AnswerStatus.Other && (
                <SkipQuestionCommentField
                  requestItemId={requestItemId}
                  answerStatusComment={answerStatusComment}
                  hasError={hasError}
                  label={formatMessage({ id: 'reporting.skipQuestion.provideDescription' })}
                />
              )}
            </RadioGroup>
          )}
        />
      </Stack>
      {get(errors, `${requestItemId}.skippedQuestionRequiredInfo`) && (
        <Alert
          severity="error"
          variant="filled"
          iconMapping={{
            success: <CheckOutlined fontSize="inherit" />,
          }}
        >
          <Typography variant="body1">{formatMessage({ id: 'errorMessages.general.requiredInfo' })}</Typography>
        </Alert>
      )}
    </>
  )
}

export default SkipQuestionOptions
