import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import { Question } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import React from 'react'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'

interface QuestionLinkCellProps {
  question: Question
}

const QuestionLinkCell: React.FC<QuestionLinkCellProps> = ({ question }) => {
  const history = useHistory()
  return (
    <RequestCell
      hideAvatar
      title={question.questionText}
      onClick={() =>
        history.push({
          pathname: generatePath(paths.sustainabilityLibrary, {
            configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questions,
            configurationsSubPage: question.id,
          }),
        })
      }
    />
  )
}

export default QuestionLinkCell
