import { Components } from '@mui/material'

const MuiAlertTitle: Components['MuiAlertTitle'] = {
  styleOverrides: {
    root: {
      fontWeight: 500,
      fontSize: '1.75rem',
      lineHeight: '3rem',
      letterSpacing: '0.01875rem',
    },
  },
}

export default MuiAlertTitle
