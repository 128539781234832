import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import { ProviderRiskStatus, ProviderRiskStatusType } from '@app/src/types/resourceExplorer'
import {
  Error,
  ErrorOutline,
  RemoveCircle,
  RemoveCircleOutlineOutlined,
  WarningAmber,
  WarningAmberOutlined,
} from '@mui/icons-material'
import { alpha, Chip, ChipProps, SvgIconProps } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

export type RiskChipCellProps = {
  risk?: ProviderRiskStatusType
  size?: ChipProps['size']
  disabled?: boolean
  disableCell?: boolean
  icon?: React.FC<SvgIconProps>
  whiteBackground?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export const getColorBasedOnRisk = (risk?: ProviderRiskStatusType) => {
  switch (risk) {
    case ProviderRiskStatus.Low:
      return {
        iconBgColor: 'info.main',
        labelBgColor: 'info.light',
      }
    case ProviderRiskStatus.Medium:
      return {
        iconBgColor: 'warning.main',
        labelBgColor: 'warning.light',
      }
    case ProviderRiskStatus.High:
      return {
        iconBgColor: 'error.main',
        labelBgColor: 'error.light',
      }
    case ProviderRiskStatus.Extreme:
      return {
        iconBgColor: 'semantic.error',
        labelBgColor: 'common.white',
      }
    default:
      return {
        iconBgColor: alpha('#000', 0.04),
        labelBgColor: 'common.white',
        textColor: 'text.secondary',
      }
  }
}

export const IconBasedOnRisk: React.FC<
  { IconComponent?: React.FC<SvgIconProps>; risk?: ProviderRiskStatusType; focusEffect?: boolean } & SvgIconProps
> = ({ IconComponent, risk, focusEffect, ...props }) => {
  if (IconComponent) {
    return <IconComponent {...props} />
  }

  switch (risk) {
    case ProviderRiskStatus.Low:
      return focusEffect ? <RemoveCircle {...props} /> : <RemoveCircleOutlineOutlined {...props} />
    case ProviderRiskStatus.Medium:
      return focusEffect ? <WarningAmber {...props} /> : <WarningAmberOutlined {...props} />
    case ProviderRiskStatus.High:
    case ProviderRiskStatus.Extreme:
      return focusEffect ? <Error {...props} /> : <ErrorOutline {...props} />
  }

  return null
}

const RiskChipCell: React.FC<RiskChipCellProps> = ({
  risk,
  size = 'small',
  disabled,
  disableCell,
  icon,
  whiteBackground,
  onClick,
}) => {
  const { formatMessage } = useIntl()
  const { iconBgColor, labelBgColor, textColor } = useMemo(() => getColorBasedOnRisk(risk), [risk])
  const [isHovered, setIsHovered] = useState(false)

  return (
    <ParentCellOrFragment
      disableCell={disableCell}
      onClick={onClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
    >
      <Chip
        variant="outlined"
        label={formatMessage({ id: `schemas.risk.${risk ? risk : 'notAvailable'}` })}
        size={size}
        disabled={disabled}
        clickable={Boolean(onClick)}
        icon={
          <IconBasedOnRisk
            risk={risk}
            color="primary"
            IconComponent={icon}
            focusEffect={onClick && isHovered}
            sx={{
              bgcolor: iconBgColor,
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              border: `2px solid transparent`,
              borderRight: 'none',
              color: risk === ProviderRiskStatus.Extreme ? 'primary.contrastText' : 'primary.main',
              width: 24,
              height: 24,

              '&.MuiChip-iconMedium': {
                width: 32,
                height: 32,
              },
            }}
          />
        }
        sx={{
          height: 'auto',
          bgcolor: 'white',
          border: 'none',
          color: textColor,
          cursor: 'inherit',

          '& .MuiChip-icon': {
            marginLeft: 0,
            marginRight: 0,
            paddingRight: '2px',
          },

          '& .MuiChip-label': {
            lineHeight: '20px',
            bgcolor: whiteBackground ? 'common.white' : labelBgColor,
            border: '2px solid',
            borderColor: whiteBackground ? alpha('#000', 0.12) : labelBgColor,
            borderRadius: 1,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: 'none',
            px: 0.5,
            minWidth: 24,
            textAlign: 'center',
          },

          '& .MuiChip-labelMedium': {
            lineHeight: '28px',
          },
        }}
      />
    </ParentCellOrFragment>
  )
}

export default RiskChipCell
