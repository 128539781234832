import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { getYears } from '@app/src/utils'
import { SfdrRequestTags } from '@app/src/wf-constants'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { FormData, SFDR_REPORT_QUESTION_KEYS } from './SfdrReportWithoutCompanies'
import SfdrRequestTemplate from './SfdrRequestTemplate'

const PreviewAndSelect = () => {
  const { control, getValues, setValue } = useFormContext<FormData>()
  const { formatMessage } = useIntl()

  const getYearOptionLabel = (year: number) => {
    const isLastYear = year === new Date().getFullYear() - 1
    return `${String(year)}${isLastYear ? ` (${formatMessage({ id: `form.createRequest.lastReportingPeriod` })})` : ''}`
  }

  const DATE_YEAR_OPTIONS = useMemo(
    () =>
      getYears(10, -1).map(y => ({
        value: y,
        label: getYearOptionLabel(y),
      })),
    [],
  )

  const { items: sfdrPrivateTemplates, isLoading: isSfdrPrivateTemplatesLoading } =
    useFetchCollectionWithPost<QuestionnaireTemplate>({
      endpoint: endpoints.requestTemplates,
      key: FetchKey.SFDRPrivateTemplates,
      payload: {
        sort: { target: 'title', order: SortOrder.ASCENDING },
        filter: [
          {
            name: 'tags.tag',
            filters: [
              {
                value: [SfdrRequestTags.Sfdr],
                operator: Operators.In,
              },
            ],
          },
        ],
        include: ['tags', 'sections', 'sections.questions', 'sections.questions.requestCollection'],
      },
    })

  const { items: sfdrWfTemplates, isLoading: isSfdrWfTemplatesLoading } =
    useFetchCollectionWithPost<QuestionnaireTemplate>({
      endpoint: endpoints.worldfavorTemplates,
      key: FetchKey.SFDRWfTemplates,
      payload: {
        filter: [
          {
            name: 'tags.tag',
            filters: [
              {
                value: [SfdrRequestTags.Sfdr],
                operator: Operators.In,
              },
            ],
          },
        ],
        sort: { target: 'title', order: SortOrder.ASCENDING },
        include: ['tags', 'sections', 'sections.questions', 'sections.questions.requestCollection'],
        pagination: {
          pageNumber: 1,
          itemsPerPage: 9999,
        },
      },
    })

  const handleOrderWfTemplates = useMemo(
    () => (wfTemplates: QuestionnaireTemplate[]) => {
      const sfdrSusApproachIndex = wfTemplates.findIndex(obj => obj.tags.includes(SfdrRequestTags.SfdrSusApproach))

      if (sfdrSusApproachIndex > -1) {
        const sfdrSusApproachObj = wfTemplates.splice(sfdrSusApproachIndex, 1)[0]
        wfTemplates.unshift(sfdrSusApproachObj)
      }

      wfTemplates.forEach(() => {
        const sfdrArticle89Index = wfTemplates.findIndex(obj => obj.tags.includes(SfdrRequestTags.SfdrArticle8and9))

        if (sfdrArticle89Index > -1) {
          const sfdrArticle89Obj = wfTemplates.splice(sfdrArticle89Index, 1)[0]
          wfTemplates.push(sfdrArticle89Obj)
        }
      })

      return wfTemplates
    },
    [],
  )

  const sfdrTemplates = [...handleOrderWfTemplates(sfdrWfTemplates), ...sfdrPrivateTemplates]

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs="auto">
          <Box mr={2}>
            <Typography>{formatMessage({ id: 'form.createRequest.sfdr.reportingPeriod' })}</Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Select
            options={DATE_YEAR_OPTIONS}
            fieldLabel={formatMessage({ id: 'form.createRequest.selectPeriodLabel' })}
            onInputChange={(e, value) => {
              if (!value) setValue(SFDR_REPORT_QUESTION_KEYS.PERIOD_NAME, undefined)
            }}
            defaultValue={DATE_YEAR_OPTIONS[0].value}
            required
            enableAutoSelect
            size="small"
            fullWidth
            name={SFDR_REPORT_QUESTION_KEYS.PERIOD_NAME}
            control={control}
          />
        </Grid>
      </Grid>
      <Box mt={4}>
        {isSfdrWfTemplatesLoading || isSfdrPrivateTemplatesLoading ? (
          <Box height={150} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography color="textSecondary" variant="caption">
              {formatMessage({ id: 'form.createRequest.selected' }, { number: getValues().templateIds.length })}
            </Typography>
            {sfdrTemplates.map(template => (
              <SfdrRequestTemplate template={template} key={template.id} />
            ))}
          </>
        )}
      </Box>
    </>
  )
}

export default PreviewAndSelect
