import { Link, LinkProps } from '@mui/material'
import RouterLink, { RouterLinkProps } from '@app/src/components/RouterLink'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'

type LinkCellProps = {
  label?: string
  disableCell?: boolean
} & (Omit<LinkProps, 'component'> | RouterLinkProps)

const isRouterLink = (link: LinkProps | RouterLinkProps): link is RouterLinkProps =>
  Boolean((link as RouterLinkProps).to)

const LinkCell: React.FC<LinkCellProps> = ({ href, label, disableCell, ...props }) => {
  if (isRouterLink(props)) {
    return (
      <ParentCellOrFragment disableCell={disableCell}>
        <RouterLink {...props}>{label || props.to.toString()}</RouterLink>
      </ParentCellOrFragment>
    )
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Link
        href={href?.includes('://') ? href : `https://${href}`}
        underline="hover"
        rel="noopener noreferrer"
        target="_blank"
        {...props}
      >
        {label || href}
      </Link>
    </ParentCellOrFragment>
  )
}

export default LinkCell
