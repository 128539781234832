import RouteLeavingGuard from '@app/src/components/RouteLeavingGuard'
import { ResponseDraftStatus, ResponseItem, UploadReference } from '@app/src/types/resourceExplorer'
import { Box, Grid, Stack } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useReport } from '../ReportContext'
import useProviderQuestionnaire from '../hooks/useProviderQuestionnaire'
import ReportActions from './Actions'
import Report from './Report'
import ReportingHeader from './ReportingHeader'

type ResponseItemAnswer = Pick<
  ResponseItem,
  'id' | 'answer' | 'cannotAnswer' | 'estimatedAnswerAt' | 'expiresAt' | 'answerStatus' | 'comment'
> & {
  selectedOptions: Array<string>
  uploadReferences: UploadReference
  planOnHaving?: boolean
}

export type FormData = {
  [key: string]: ResponseItemAnswer
}

const AUTOSAVE_TIME = 1000 * 60 * 5 //5 Minutes

const ProviderReport: React.FC = () => {
  const history = useHistory()
  const headerRef = useRef<HTMLDivElement>(null)
  const {
    responseId,
    request,
    response,
    isResponseFetching,
    isLoadingAccessNudge,
    isRequestHistoryLoading,
    reportStatus,
    latestHistory,
    showAccessNudge,
  } = useReport()
  const { createDraft, updateDraft } = useProviderQuestionnaire()
  const [attemptSave, setAttemptSave] = useState(false)
  const intervalRef = useRef<NodeJS.Timeout>()

  const formMethods = useForm<FormData>({
    shouldUnregister: false,
    mode: 'onChange',
  })

  const defaultValues = useMemo(
    () =>
      response?.items.reduce<FormData>(
        (prev, curr) => ({
          ...prev,
          [curr.requestItemId]: {
            id: curr.id,
            answer: curr.answer,
            selectedOptions: curr.selectedOptions,
            cannotAnswer: curr.cannotAnswer,
            estimatedAnswerAt: curr.estimatedAnswerAt,
            uploadReferences: [curr.uploadReferences].flat()[0],
            expiresAt: curr.expiresAt,
            planOnHaving: Boolean(curr.estimatedAnswerAt),
            answerStatus: curr.answerStatus,
            comment: curr.comment,
          },
        }),
        {},
      ),
    [response?.items],
  )

  useEffect(() => {
    if (!isResponseFetching) {
      formMethods.reset(defaultValues)
    }
  }, [defaultValues, isResponseFetching])

  //Autosaving when in draft mode every 5 min (if the progress is not saved)
  useEffect(() => {
    if (reportStatus === ResponseDraftStatus.Draft && !intervalRef.current) {
      intervalRef.current = setInterval(() => setAttemptSave(true), AUTOSAVE_TIME)
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [reportStatus])

  useEffect(() => {
    if (formMethods.formState.isDirty && attemptSave) {
      const values = formMethods.getValues()
      updateDraft(values, true).then(() => formMethods.reset(values))
    }

    return () => setAttemptSave(false)
  }, [attemptSave])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (
      !request?.id ||
      responseId ||
      isRequestHistoryLoading ||
      isLoadingAccessNudge ||
      showAccessNudge ||
      latestHistory?.status
    ) {
      return
    }

    createDraft()
  }, [request?.id, isLoadingAccessNudge, isRequestHistoryLoading, showAccessNudge])

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" noValidate flexGrow={1}>
        <Box px={{ xs: 2, md: 5 }} mt={5} ref={headerRef}>
          <ReportingHeader />
        </Box>

        <Stack bgcolor="grey.100" px={{ xs: 2, md: 5 }} py={5} flexGrow={1}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={7} lg={8}>
              <Report />
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <ReportActions />
            </Grid>
          </Grid>
        </Stack>

        <RouteLeavingGuard
          when={formMethods.formState.isDirty}
          navigate={path => history.push(path)}
          shouldBlockNavigation={() => formMethods.formState.isDirty}
        />
      </Stack>
    </FormProvider>
  )
}

export default ProviderReport
