import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import useNotificationListener from '@app/src/hooks/notificationListener'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import paths from '@app/src/wf-constants/paths'
import { Notifications, NotificationsOutlined } from '@mui/icons-material'
import { Badge, IconButton, Tooltip, useTheme } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

const NotificationsMenuItem: React.FC = () => {
  const { formatMessage } = useIntl()
  const notificationsPath = generatePath(paths.notifications)
  const { pathname } = useLocation()
  const { palette } = useTheme()

  const { scope } = useAuthentication()
  const queryClient = useQueryClient()

  const {
    facets: [unseenNotifications],
  } = useFetchFacets({
    endpoint: endpoints.notificationsWithFacets,
    filter: [{ name: 'seen', filters: [{ operator: Operators.EqualTo, value: false }] }],
    facetsParam: [{ name: 'seen', isEnum: true }],
    key: [FetchKey.Notification, FetchKey.NotificationFacets],
    options: {
      enabled: Boolean(scope.solution && scope.solution !== 'default'),
    },
  })

  useNotificationListener(() => {
    queryClient.invalidateQueries(FetchKey.Notification)
  })

  return (
    <Tooltip arrow title={formatMessage({ id: 'resourceTypes.notification' })}>
      <IconButton
        sx={{
          p: 2,
          borderRadius: 0,
          height: '100%',
          ...(pathname.includes(notificationsPath) && {
            borderBottom: `2px solid ${palette.primary.main}`,
          }),
        }}
        size="medium"
        component={Link}
        to={notificationsPath}
      >
        <Badge badgeContent={unseenNotifications?.[0]?.count} color="error" max={99} overlap="rectangular">
          {pathname.includes(notificationsPath) ? (
            <Notifications fontSize="medium" color="primary" />
          ) : (
            <NotificationsOutlined fontSize="medium" />
          )}
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default NotificationsMenuItem
