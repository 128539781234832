import { Check, Close, DownloadOutlined } from '@mui/icons-material'
import { Box, Button, Chip, Tooltip } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { useFileDownload } from '@app/src/api/fetchHooks'
import React from 'react'
import { useIntl } from 'react-intl'
import { AssessmentRule, AssessmentRuleType, ResponseItem } from '@app/src/types/resourceExplorer'

interface AssessedFileResponseProps {
  responseItem: ResponseItem
  rules?: AssessmentRule[]
}

const AssesssedFileResponse = ({ responseItem, rules }: AssessedFileResponseProps) => {
  const { formatMessage } = useIntl()
  const { downloadFile, isFileDownloading } = useFileDownload()

  const handleFileDownload = async (id: number) => {
    await downloadFile(endpoints.fileDownload(id))
  }

  const hasFiles = Boolean(responseItem.uploadReferences?.length)

  const matchingRules = rules?.filter(
    r =>
      r.ruleType === AssessmentRuleType.FileUploaded &&
      ((r.value === '1' && hasFiles) || (r.value === '0' && !hasFiles)),
  )

  const hasMatchingRules = Boolean(matchingRules?.length)
  const points = matchingRules?.reduce((a, b) => a + b.points, 0)

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
      <Tooltip title={formatMessage({ id: 'baseAssessment.providerPage.points' }, { points })}>
        <Chip
          disabled={!hasMatchingRules}
          icon={hasMatchingRules ? <Check sx={{ '&.MuiChip-icon': { color: 'white' } }} /> : <Close />}
          size="small"
          sx={{
            bgcolor: hasMatchingRules ? 'brandDecorative.emerald' : 'default',
            color: hasMatchingRules ? 'white' : 'primary',
            maxWidth: 200,
          }}
          label={hasFiles ? responseItem.answer : formatMessage({ id: 'baseAssessment.providerPage.noFileUploaded' })}
        />
      </Tooltip>

      {hasFiles && (
        <Button
          size="small"
          onClick={() => handleFileDownload(responseItem.uploadReferences[0].id)}
          sx={{
            minWidth: 'auto',
          }}
          disabled={isFileDownloading}
          id="downloadFileButton"
        >
          <DownloadOutlined fontSize="small" />
        </Button>
      )}
    </Box>
  )
}

export default AssesssedFileResponse
