import { AvatarSize } from '@app/src/components/Ui/Avatar'
import CompanyAvatar, {
  CompanyAvatarProps,
} from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Box } from '@mui/material'
import React from 'react'

const OrganizationImage: React.FC<CompanyAvatarProps> = props => {
  return (
    <Box display="flex" justifyContent="center">
      <CompanyAvatar size={AvatarSize.XXL} {...props} />
    </Box>
  )
}

export default OrganizationImage
