import { useAccount } from '@app/src/context/AccountContext'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Box, Typography } from '@mui/material'
import React from 'react'

const OrgInfoSection = () => {
  const { account } = useAccount()

  if (!account) {
    return null
  }

  const { organization } = account

  return (
    <Box>
      <Box>
        <Box display="flex" alignItems="center" mb={2} mr={2}>
          <CompanyAvatar
            organizationName={organization?.name ?? ''}
            alt={organization?.name}
            imageUrl={organization?.image?.url}
            sx={{ mr: 2 }}
            variant="square"
          />
          <Typography variant="kpi">{organization?.name}</Typography>
        </Box>
      </Box>
      <Typography variant="body1" gutterBottom>
        {organization?.vatNumber}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {organization?.websiteAddress}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {organization?.country?.name}
      </Typography>
    </Box>
  )
}

export default OrgInfoSection
