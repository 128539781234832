import { Alert, Stack, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import RouterLink from '@app/src/components/RouterLink'
import TextField from '@app/src/components/Ui/TextField'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { Invitation } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewInvitationFormProps = {
  invitation?: Invitation
  title?: DrawerViewProps['title']
} & Omit<DrawerViewProps, 'title'>

type InvitationPayload = Pick<Invitation, 'name' | 'email' | 'role'>

const DrawerViewInvitationForm: React.FC<DrawerViewInvitationFormProps> = ({ invitation, ...props }) => {
  const { closeDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { handleSubmit, control, formState, setError, register, setValue, errors } = useForm({
    defaultValues: {
      name: invitation?.name,
      email: invitation?.email,
      role: invitation?.role.toLowerCase(),
    },
  })
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { mutate, isLoading: isSubmitting } = useUpdateResource<InvitationPayload>()

  const onSubmit = async (values: InvitationPayload): Promise<void> => {
    const invitation: InvitationPayload = {
      name: values.name,
      email: values.email,
      role: values.role,
    }
    mutate(
      { url: `${endpoints.invitations}/save`, body: invitation },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage(
              { id: 'notifications.successfulCreateInvitation' },
              { b: (chunks: React.ReactNode) => <b>{chunks}</b> },
            ),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.Invitation)
          closeDrawer?.()
        },
        onError: error => {
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
            return
          }
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'resourceCollections.create.colleague' })}
      buttons={[
        {
          label: formatMessage({ id: 'reporting.submit' }),
          variant: 'contained',
          loading: isSubmitting,
          type: 'submit',
        },
      ]}
      onFormSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Stack px={2} spacing={2}>
        {Object.keys(formState.errors)?.length > 0 && (
          <Alert variant="filled" severity="error">
            {formatMessage({ id: 'form.validation.error' })}
          </Alert>
        )}

        <Typography variant="body1">
          {formatMessage(
            { id: 'schemas.invitation.description' },
            {
              link: (
                <RouterLink to="/" target="_blank">
                  app.worldfavor.com
                </RouterLink>
              ),
            },
          )}
        </Typography>

        <TextField
          hoveringLabel
          placeholder={formatMessage({ id: 'textFieldPlaceholders.userName' })}
          fullWidth
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          required
          name="name"
          label={formatMessage({ id: 'schemas.invitation.name' })}
          error={Boolean(errors?.name)}
          type="text"
          helperText={errors?.name?.message}
          onClear={(): void => setValue('name', '')}
        />

        <TextField
          hoveringLabel
          placeholder={formatMessage({ id: 'textFieldPlaceholders.colleagueEmail' })}
          fullWidth
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          required
          name="email"
          label={formatMessage({ id: 'schemas.invitation.email' })}
          error={Boolean(errors?.email)}
          type="email"
          helperText={errors?.email?.message}
          onClear={(): void => setValue('email', '')}
          sx={{ display: invitation ? 'none' : 'block' }}
        />

        <Select
          hoveringLabel
          name="role"
          fieldLabel={formatMessage({ id: 'schemas.invitation.role' })}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          control={control}
          required
          enableAutoSelect
          options={[
            { value: 'admin', label: 'Admin' },
            { value: 'contributor', label: 'Contributor' },
          ]}
          error={errors?.role?.message}
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewInvitationForm
