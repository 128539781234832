import { UseFormMethods } from 'react-hook-form'
import { ErrorResponse } from '@app/src/types/api'

class RequestError extends Error {
  statusCode: number
  body: ErrorResponse | null
  isValidationError: boolean

  constructor(statusCode: number, body: ErrorResponse | null, msg: string) {
    super(msg)
    this.statusCode = statusCode
    this.body = body
    this.isValidationError = statusCode === 400
    // Set the prototype explicitly. see https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, RequestError.prototype)
  }

  setFormValidationErrors = (setError: UseFormMethods['setError']): boolean => {
    this.body?.validation?.items?.forEach((item): void => {
      setError(item.name, {
        type: 'manual',
        message: item.message,
      })
    })
    return this.isValidationError
  }
}

export default RequestError
