import { TableRow } from '@mui/material'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const EdciHeader: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'schemas.edci.title' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.edci.period' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.edci.status' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.edci.actions' })} />
    </TableRow>
  )
}

export default EdciHeader
