import React, { ReactNode } from 'react'

import DrawerContextProvider from '@app/src/components/Drawer/DrawerContextProvider'
import AccessibleOrganizationsContextProvider from '@app/src/context/AccessibleOrganizationsContextProvider'
import AccountContextProvider from '@app/src/context/AccountContextProvider'
import AmplitudeContextProvider from '@app/src/context/AmplitudeContextProvider'
import AuthenticationContextProvider from '@app/src/context/AuthenticationContextProvider'
import RiskSettingsContextProvider from '@app/src/context/RiskSettingsContextProvider'
import SignalRContextProvider from '@app/src/context/SignalRContextProvider'
import SnackbarProvider from '@app/src/context/SnackbarContextProvider'
import { ConfirmProvider } from 'material-ui-confirm'
import { useIntl } from 'react-intl'
import ConnectProvidersModalContextProvider from './context/ConnectProvidersModalContextProvider'

type ApplicationContextsProps = {
  children: ReactNode
}

const ApplicationContexts: React.FC<ApplicationContextsProps> = ({ children }: ApplicationContextsProps) => {
  const { formatMessage } = useIntl()
  return (
    <SnackbarProvider>
      <AuthenticationContextProvider>
        <SignalRContextProvider>
          <AccountContextProvider>
            <AccessibleOrganizationsContextProvider>
              <AmplitudeContextProvider>
                <RiskSettingsContextProvider>
                  <ConnectProvidersModalContextProvider>
                    <ConfirmProvider
                      defaultOptions={{
                        title: formatMessage({ id: 'general.areYouSure' }),
                        cancellationButtonProps: { variant: 'outlined' },
                        confirmationButtonProps: { variant: 'contained' },
                        cancellationText: formatMessage({ id: 'general.cancel' }),
                        confirmationText: formatMessage({ id: 'general.confirm' }),
                      }}
                    >
                      <DrawerContextProvider>{children}</DrawerContextProvider>
                    </ConfirmProvider>
                  </ConnectProvidersModalContextProvider>
                </RiskSettingsContextProvider>
              </AmplitudeContextProvider>
            </AccessibleOrganizationsContextProvider>
          </AccountContextProvider>
        </SignalRContextProvider>
      </AuthenticationContextProvider>
    </SnackbarProvider>
  )
}

export default ApplicationContexts
