import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Schema } from '@app/src/types/schemas'

const ResponseSchema: Schema = {
  name: 'response',
  defaultImage: '',
  items: [],
  relatedContentFilters: {
    supplier: [
      {
        accessor: 'organizationId',
        name: 'request.responderOrganizationId',
        operator: Operators.EqualTo,
      },
    ],
    investee: [
      {
        accessor: 'organizationId',
        name: 'request.responderOrganizationId',
        operator: Operators.EqualTo,
      },
    ],
    company: [
      {
        accessor: 'organizationId',
        name: 'request.responderOrganizationId',
        operator: Operators.EqualTo,
      },
    ],
    contact: [
      {
        accessor: 'creatorOrganizationId',
        name: 'request.responderOrganizationId',
        operator: Operators.EqualTo,
      },
    ],
  },
}

export default ResponseSchema
