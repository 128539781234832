import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProviderProgressHeaderWithSource: React.FC<{ period: string }> = ({ period }) => {
  return <TableHeaderCell label={period} data-cy={`table-header-${period}`} minWidth={TableCellWidth.SMALL} />
}

interface ProviderProgressHeaderProps {
  periods: string[]
}

const ProviderProgressHeader: React.FC<ProviderProgressHeaderProps> = ({ periods }) => {
  const { formatMessage } = useIntl()

  const cells: React.ReactNode[] = [
    <TableHeaderCell
      key="question"
      label={formatMessage({ id: 'schemas.providerProgress.question' })}
      minWidth={TableCellWidth.LARGE}
    />,
  ]

  const rearCells: React.ReactNode[] = [
    <TableHeaderCell
      key="unit"
      label={formatMessage({ id: 'schemas.providerProgress.unit' })}
      minWidth={TableCellWidth.SMALL}
    />,
    <TableHeaderCell key="trend" label="" />,
  ]

  const periodCells: React.ReactNode[] = periods.flatMap(period => [
    <ProviderProgressHeaderWithSource key={`${period}`} period={period} />,
  ])

  return <TableRow>{[...cells, ...periodCells, ...rearCells]}</TableRow>
}

export default ProviderProgressHeader
