import Tabs from '@app/src/components/Tabs'
import SubscribersPanel from '@app/src/pages/Questionnaire/Provider/Actions/SubscribersPanel'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import HistoryLog from '../../HistoryLog'
import GiveAccessNudgeDialog from './GiveAccessNudgeDialog'
import ReportButton from './ReportButton'
import VerificationMessages from './VerificationMessages'

enum RightPanelTab {
  SUBSCRIBERS = 'subscribers',
  HISTORY_LOG = 'history-log',
  MESSAGES = 'messages',
}

const ReportActions: React.FC = () => {
  const { formatMessage } = useIntl()
  const { response, isRequestLoading, isResponseLoading } = useReport()

  const [activeTab, setActiveTab] = React.useState<RightPanelTab>(RightPanelTab.SUBSCRIBERS)

  const tabs = [
    {
      type: RightPanelTab.SUBSCRIBERS,
      label: formatMessage({ id: 'reporting.sharing.subscribers' }),
      url: '',
    },
    {
      type: RightPanelTab.HISTORY_LOG,
      label: formatMessage({ id: 'reporting.historyLog' }),
      url: '',
    },
    {
      type: RightPanelTab.MESSAGES,
      label: formatMessage({ id: 'reporting.messages' }),
      url: '',
      badgeText: response?.verifications?.length ?? 0,
      badgeSx: { '& .MuiBadge-badge': { xs: { right: 0 }, xl: { right: -5 } } },
    },
  ]

  if (isRequestLoading || isRequestLoading || isResponseLoading) {
    return (
      <Box position="sticky" top={80} display="flex">
        <Skeleton width="33%" height={48} sx={{ mr: 1 }} />
        <Skeleton width="33%" height={48} sx={{ mr: 1 }} />
        <Skeleton width="33%" height={48} />
      </Box>
    )
  }

  return (
    <>
      <Box position="sticky" top={80}>
        <Stack spacing={2}>
          <ReportButton />
          <Box>
            <Tabs
              variant="fullWidth"
              tabs={tabs}
              activeTab={activeTab}
              handleChange={(_, value) => setActiveTab(value as RightPanelTab)}
            />
            {activeTab === RightPanelTab.SUBSCRIBERS && <SubscribersPanel />}
            {activeTab === RightPanelTab.HISTORY_LOG && <HistoryLog />}
            {activeTab === RightPanelTab.MESSAGES && <VerificationMessages />}
          </Box>
        </Stack>
      </Box>
      <GiveAccessNudgeDialog />
    </>
  )
}

export default ReportActions
