import { Box } from '@mui/material'
import React from 'react'

import CO2eCalculatorLink from '@app/src/components/Navbar/NavbarItems/CO2eCalculatorLink'
import EngagedProvidersLink from '@app/src/components/Navbar/NavbarItems/EngagedProvidersLink'
import AnalyzeMenu from './AnalyzeMenu'
import DashboardLink from './DashboardLink'
import ProviderMenu from './ProviderMenu'
import RequestMenu from './RequestMenu'
import SubscriptionLink from './SubscriptionLink'

import ProductsMenu from './ProductsMenu'
import TransparencyProductMenu from './TransparencyProductMenu'

interface NavbarItemsProps {
  flexDirection?: 'row' | 'column'
  closeMenuDrawer?: () => void
}

export type NavbarItemProps = Pick<NavbarItemsProps, 'closeMenuDrawer'>

const NavbarItems = ({ flexDirection = 'row', closeMenuDrawer }: NavbarItemsProps): JSX.Element => (
  <Box height="100%" width="100%" display="flex" flexDirection={flexDirection}>
    <DashboardLink closeMenuDrawer={closeMenuDrawer} />
    <ProviderMenu closeMenuDrawer={closeMenuDrawer} />
    <RequestMenu closeMenuDrawer={closeMenuDrawer} />
    <AnalyzeMenu closeMenuDrawer={closeMenuDrawer} />
    <ProductsMenu closeMenuDrawer={closeMenuDrawer} />
    <SubscriptionLink closeMenuDrawer={closeMenuDrawer} />
    <TransparencyProductMenu closeMenuDrawer={closeMenuDrawer} />
    <CO2eCalculatorLink closeMenuDrawer={closeMenuDrawer} />
    <EngagedProvidersLink closeMenuDrawer={closeMenuDrawer} />
  </Box>
)

export default NavbarItems
