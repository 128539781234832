import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Schema } from '@app/src/types/schemas'

const InquirySchema: Schema = {
  name: 'inquiry',
  defaultImage: '',
  items: [],
  actions: ['open', 'edit', 'delete'],
  relatedContentFilters: {
    supplier: [
      {
        accessor: 'id',
        name: 'providerId',
        operator: Operators.EqualTo,
      },
    ],
    investee: [
      {
        accessor: 'id',
        name: 'providerId',
        operator: Operators.EqualTo,
      },
    ],
    company: [
      {
        accessor: 'id',
        name: 'providerId',
        operator: Operators.EqualTo,
      },
    ],
    contact: [
      {
        accessor: 'creatorOrganizationId',
        name: 'request.responderOrganizationId',
        operator: Operators.EqualTo,
      },
    ],
  },
}

export default InquirySchema
