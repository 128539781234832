import Permissions, { usePermissions } from '@app/src/auth/permissions'
import DateDisplay from '@app/src/components/DateDisplay'
import RenderFileDownload from '@app/src/components/RenderFileDownload'
import VerificationButton from '@app/src/pages/Questionnaire/Accessor/Actions/VerificationButton'
import CertificateScreeningResult from '@app/src/pages/Questionnaire/CertificateScreeningResult'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import {
  AnswerStatus,
  ExternalVerificationStatus,
  ResponseDraftStatus,
  ResponseItem,
} from '@app/src/types/resourceExplorer'
import { Box, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import SkippedQuestionCard from '../../Provider/Report/SkippedQuestionCard'
import VerificationStatus from '../../VerificationStatus'
import { getResponseValueTypes } from '../../utils'

const useStyles = makeStyles({
  value: {
    wordBreak: 'break-word',
  },
})

export interface ResponseValueProps {
  item: ResponseItem
  questionType: string
  unitSymbol: string | null
}

const ResponseValue = ({ item, questionType, unitSymbol }: ResponseValueProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { displayAsFile, displayAsOptions, selectedOptions, hasValue } = getResponseValueTypes(questionType, item)
  const { stpResponseResponseItemVerificationFilterLogic } = useFlags()
  const { response, request, latestHistory } = useReport()
  const { hasPermission } = usePermissions()
  const isLatestResponse = latestHistory?.responseId === response?.id

  const isSomeExternalStatusCorrectionNeeded = item.verifications?.some(
    x => x.externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded,
  )

  const isPrimarySubscriber = response?.request?.isPrimarySubscriber || request?.isPrimarySubscriber
  const isReviewEnabled =
    (!stpResponseResponseItemVerificationFilterLogic || isPrimarySubscriber) &&
    hasPermission(Permissions.WRITE_VERIFICATION) &&
    isLatestResponse &&
    response?.draftStatus === ResponseDraftStatus.Submitted

  const displayValue = (): React.ReactNode => {
    if (item?.answerStatus !== AnswerStatus.Answered) {
      return <SkippedQuestionCard item={item} />
    }

    if (displayAsFile) {
      return (
        <RenderFileDownload
          fileName={item.uploadReferences?.[0].originalFileName}
          id={item.uploadReferences?.[0].id ?? 0}
        >
          {item.expiresAt && (
            <Box display="flex" alignItems="flex-end" mr={2}>
              <Typography variant="caption">
                {formatMessage({ id: 'questionnaire.expiresAt' }, { date: <DateDisplay value={item.expiresAt} /> })}
              </Typography>
            </Box>
          )}
        </RenderFileDownload>
      )
    }

    if (displayAsOptions) {
      if (selectedOptions?.length) {
        return <Typography>{selectedOptions?.join(', ')}</Typography>
      }
    }

    if (hasValue) {
      return (
        <Typography className={classes.value}>
          {item.answer || ''} {unitSymbol || ''}
        </Typography>
      )
    }

    return <Typography>{formatMessage({ id: 'reporting.noResponse' })}</Typography>
  }

  return (
    <>
      <Stack px={3} py={4} bgcolor={isSomeExternalStatusCorrectionNeeded ? 'warning.light' : 'grey.100'}>
        <Stack mr={isReviewEnabled ? 5 : 0}>{displayValue()}</Stack>
        <Stack alignSelf="flex-end" mt={2}>
          <VerificationButton item={item} />
        </Stack>
      </Stack>

      <VerificationStatus {...item} />
      <CertificateScreeningResult fileScreeningResult={item.fileScreeningResults} />
    </>
  )
}

export default ResponseValue
