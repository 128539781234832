import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import Checkbox from '@mui/material/Checkbox'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface OptionIconProps {
  multiple?: boolean
  selected: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.primary.main,
  },
}))

export const OptionIcon = ({ multiple, selected }: OptionIconProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Checkbox
      icon={
        multiple ? (
          <CheckBoxOutlineBlankIcon className={classes.icon} />
        ) : (
          <RadioButtonUncheckedIcon className={classes.icon} />
        )
      }
      checkedIcon={
        multiple ? <CheckBoxIcon className={classes.icon} /> : <RadioButtonCheckedIcon className={classes.icon} />
      }
      style={{ marginRight: 8 }}
      checked={selected}
    />
  )
}

export default OptionIcon
