import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import CustomReportHeader from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/CustomReportHeader'
import CustomReportsRow from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/CustomReportsRow'
import { SortOrder } from '@app/src/types/filter'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { FormatListNumberedRtlOutlined } from '@mui/icons-material'
import React from 'react'
import { useIntl } from 'react-intl'

const CustomQuestionnaireCollection: React.FC = () => {
  const [page, pageSize, setPage, setPageSize] = usePagination({ page: 1, pageSize: 10 })
  const { formatMessage } = useIntl()

  const {
    items: reports,
    count,
    isLoading,
    isError,
  } = useFetchCollectionWithPost<QuestionnaireTemplate>({
    endpoint: endpoints.sharedAndOwnedTemplates,
    key: FetchKey.SharedAndOwnedTemplates,
    payload: {
      filter: [],
      include: ['sections.questions.reportFlagRules'],
      sort: { target: 'title', order: SortOrder.ASCENDING },
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <Table<QuestionnaireTemplate>
      RowComponent={CustomReportsRow}
      HeaderComponent={CustomReportHeader}
      emptyState={
        <EmptyState
          iconComponent={FormatListNumberedRtlOutlined}
          title={formatMessage({ id: 'questionnaireConfig.customEmptyState.title' })}
          description={formatMessage({ id: 'questionnaireConfig.customEmptyState.description' }, { br })}
        />
      }
      data={reports}
      page={page}
      isLoading={isLoading}
      count={count}
      isError={isError}
      setPage={setPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  )
}

export default CustomQuestionnaireCollection
