import RequestItem from '@app/src/pages/Questionnaire/Provider/Report/RequestItem'
import ReportContext from '@app/src/pages/Questionnaire/ReportContext'
import {
  Question,
  QuestionnaireTemplate,
  QuestionnaireTemplateSection,
  ResponseItemWithPreviousCorrectionNeededItem,
} from '@app/src/types/resourceExplorer'
import { mapToReportContextPropsForPreview, mapToRequestItem } from '@app/src/utils/previewMapper'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface QuestionPreviewProps {
  question: Question
}

const QuestionPreview: React.FC<QuestionPreviewProps> = ({ question }) => {
  const formMethods = useForm()

  const previewRequestItem = mapToRequestItem(question, true)
  const templateSection = { questions: [{ ...question, isRequired: true, order: 1 }] } as QuestionnaireTemplateSection
  const template = {
    sections: [templateSection],
  } as QuestionnaireTemplate

  return (
    <form>
      <ReportContext.Provider value={mapToReportContextPropsForPreview(template)}>
        <FormProvider {...formMethods}>
          <RequestItem
            requestItem={previewRequestItem}
            responseItem={{} as ResponseItemWithPreviousCorrectionNeededItem}
            itemNumber={1}
            sectionNumber={1}
          />
        </FormProvider>
      </ReportContext.Provider>
    </form>
  )
}

export default QuestionPreview
