import * as amplitude from '@amplitude/analytics-browser'
import { Config } from '@app/src/config'
import React, { FC, ReactNode, useEffect } from 'react'
import { useAccount } from './AccountContext'
import AmplitudeContext, { TrackEvent } from './AmplitudeContext'
import { useAuthentication } from './AuthenticationContext'

type AmplitudeContextProviderProps = {
  children: ReactNode
}

const AmplitudeContextProvider: FC<AmplitudeContextProviderProps> = ({ children }: AmplitudeContextProviderProps) => {
  const { account } = useAccount()
  const { scope } = useAuthentication()

  useEffect(() => {
    if (Config.AMPLITUDE_API_KEY) {
      amplitude.init(Config.AMPLITUDE_API_KEY, {
        defaultTracking: true,
        userId: account?.user?.id?.toString(),
        minIdLength: 1,
        serverZone: 'EU',
      })
    }
  }, [Config.AMPLITUDE_API_KEY])

  const trackEvent = ({ name, eventProps, eventOptions }: TrackEvent) => {
    const standardEvents = {
      user_id: account?.user.id,
      organization_id: account?.organization?.id,
      organization_name: account?.organization?.name,
      solution_type: scope.solution,
      user_role: scope.role,
      worldfavor_user: account?.user?.email?.endsWith('@worldfavor.com'),
    }

    amplitude.track(name, { ...standardEvents, ...eventProps }, eventOptions)
  }

  /** Used to track leaving-events when the user closes the browser/tab
   * When the browser is closing, we can't send requests with fetch, but we can send beacons */
  const trackEventCloseBrowser = (event: TrackEvent) => {
    amplitude.setTransport('beacon')
    trackEvent(event)
    amplitude.flush()
  }

  return (
    <AmplitudeContext.Provider value={{ trackEvent, trackEventCloseBrowser, account }}>
      {children}
    </AmplitudeContext.Provider>
  )
}

export default AmplitudeContextProvider
