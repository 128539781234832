import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProviderDocumentHeader: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'resourceExplorer.activity.fileName' })}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'resourceExplorer.activity.uploadedDate' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'resourceExplorer.activity.uploadedBy' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell label="" minWidth={TableCellWidth.EMPTY} />
    </TableRow>
  )
}

export default ProviderDocumentHeader
