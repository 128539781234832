import GraphLegend from '@app/src/pages/Dashboards/GraphLegend'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { palette } from '@app/src/theme/palette'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { OptionResultItemMultiPeriod, ResultForPeriod, StatisticsForQuestionPerPeriod } from '../../DataHubScene'
import { useDataInsightsModal } from './DataInsightsModalContext'
import { useOptionMultiPeriodChartConfigs } from './useOptionMultiPeriodChartConfigs'
import { SummaryChartDataTypes } from './useSummaryChartConfigs'

interface OptionBarProps {
  statistics: StatisticsForQuestionPerPeriod
  height: string
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  selectedChartType: ChartType
}

export interface EchartClickItemData {
  name: string
  value: number
  period: string
  summaryType?: SummaryChartDataTypes
  answersSelected: string[]
}

interface OptionsForPeriod {
  period: string
  options: OptionResultItemMultiPeriod[]
  notIncludedItems: string[]
}

const getOptionsForPeriod = (
  resultForPeriod: ResultForPeriod,
  selectedChartType: ChartType,
  otherText: string,
): OptionsForPeriod => {
  const optionResult = resultForPeriod.optionStatisticsViews
  const numberOfItemsInView = selectedChartType === ChartType.Vertical ? 6 : 3
  const optionStatisticsView = optionResult?.find(op => op.maxItemCount === numberOfItemsInView) ?? optionResult?.[0]
  const itemsInView = optionStatisticsView?.items ?? []

  const mappedItems = itemsInView.map(r => (r.isOther ? { ...r, name: otherText } : r))

  const notIncludedItems = optionStatisticsView?.valuesInOther ?? []

  return {
    period: resultForPeriod.period,
    options: mappedItems,
    notIncludedItems,
  }
}

export const OptionMultiPeriodChart: React.FC<OptionBarProps> = ({
  statistics,
  height,
  echartsRef,
  selectedChartType,
}) => {
  const { openDataInsightsModal } = useDataInsightsModal()
  const { formatMessage } = useIntl()

  echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, LegendComponent])

  const resultsForPeriods = statistics.resultForPeriods
  const optionItemResult = useMemo(
    () =>
      resultsForPeriods?.map(r => getOptionsForPeriod(r, selectedChartType, formatMessage({ id: 'general.other' }))) ??
      [],
    [formatMessage, resultsForPeriods, selectedChartType],
  )
  const events = useMemo(() => {
    return {
      click: ({ data }: { data: EchartClickItemData }) => {
        const userSelection = {
          period: data.period,
          answer: data.answersSelected,
        }
        openDataInsightsModal(statistics, userSelection)
      },
    }
  }, [openDataInsightsModal, statistics])

  const options = useOptionMultiPeriodChartConfigs({
    optionItemResult,
    selectedChartType,
    itemsInOther: optionItemResult[0].notIncludedItems,
    echartsRef,
  })

  return (
    <>
      <ReactEChartsCore
        onEvents={events}
        option={options}
        echarts={echarts}
        style={{ minWidth: '100%', height }}
        ref={echartsRef}
      />
      {optionItemResult.map((o, i) => (
        <GraphLegend
          key={i}
          color={Array.isArray(options.color) ? options.color[i].toString() : palette.visualization[5]}
          legend={o.period}
          disabled={o.options.every(op => !op.count)}
          onClick={() => openDataInsightsModal(statistics, { period: o.period })}
        />
      ))}
    </>
  )
}
