import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const CollectionStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1),
    backgroundColor: 'inherit',
  },
  cellAvatar: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
  },
  cell: {
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cellText: {
    minWidth: 0,
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  mainCellText: {
    minWidth: 0,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  mainClickableCellWrapper: {
    overflow: 'hidden',
  },
  mainClickableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  linkCell: {
    minWidth: 0,
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
  link: {
    minWidth: 0,
    color: theme.palette.info.main,
    textDecoration: 'none',
  },
  openText: {
    marginLeft: theme.spacing(1),
  },
  preHeader: {
    marginBottom: -5,
  },
  buttonCell: {
    margin: 0,
  },
  linkedBadge: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.secondary.main,
  },
}))
