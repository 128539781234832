import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { Check } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type OrganizationRequestSuccessProps = {
  companyName: string
  onClose: () => void
}

const OrganizationRequestSuccess: React.FC<OrganizationRequestSuccessProps> = ({ companyName, onClose }) => {
  const { formatMessage } = useIntl()

  return (
    <OnboardingForm>
      <Stack alignItems="center" justifyContent="center" textAlign="center" flexGrow={1} spacing={2}>
        <Avatar variant="circular" size={AvatarSize.XXXL} sx={{ bgcolor: 'success.main' }}>
          <Check color="primary" sx={{ fontSize: 64 }} />
        </Avatar>

        <Typography variant="subtitle1">
          {formatMessage({ id: 'claim.createClaim.requestAccessStep.successTitle' }, { companyName })}
        </Typography>
        <Typography>{formatMessage({ id: 'claim.createClaim.requestAccessStep.successDescription' })}</Typography>
        <Button variant="outlined" size="medium" onClick={onClose}>
          {formatMessage({ id: 'general.close' })}
        </Button>
      </Stack>
    </OnboardingForm>
  )
}

export default OrganizationRequestSuccess
