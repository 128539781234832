import { TableRow } from '@mui/material'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { Subscription } from '@app/src/types/resourceExplorer'

interface SubscriptionRowProps {
  row: Subscription
}

export const InvesteeRow = ({ row: subscription }: SubscriptionRowProps): JSX.Element => {
  return (
    <TableRow>
      <CompanyCell company={subscription.creatorOrganization} />
      <ValueCell value={subscription.request.title} />
      <DateCell value={subscription.createdAt} />
    </TableRow>
  )
}

export default InvesteeRow
