import { ArrowForward, Business } from '@mui/icons-material'
import { Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import Avatar from '@app/src/components/Ui/Avatar'
import React, { ReactNode, useState } from 'react'

const useStyles = makeStyles(({ spacing, palette }) => ({
  card: {
    height: '100%',
  },
  cardActionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: spacing(2),
  },
  forwardButton: {
    background: palette.common.white,
  },
  cardContent: {
    flexGrow: 1,
  },
}))

export interface RequestTypeCardProps {
  onClick: () => void
  title: string
  description: string
  logo?: string
  icon?: ReactNode
  whiteBg?: boolean
}

const RequestTypeCard: React.FC<RequestTypeCardProps> = ({ onClick, logo, icon, whiteBg, title, description }) => {
  const [isHovered, setHovered] = useState(false)
  const classes = useStyles()

  return (
    <Card className={classes.card} variant="outlined">
      <CardActionArea
        onClick={onClick}
        className={classes.cardActionArea}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <CardHeader
          avatar={
            <Avatar variant="square" src={logo} sx={whiteBg ? { bgcolor: 'common.white' } : {}}>
              {icon ?? <Business color="inherit" />}
            </Avatar>
          }
        ></CardHeader>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        </CardContent>
        <CardContent>
          <Avatar className={classNames({ [classes.forwardButton]: !isHovered })}>
            <ArrowForward color="inherit" />
          </Avatar>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default RequestTypeCard
