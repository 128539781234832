import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Schema } from '@app/src/types/schemas'
import { ConstVars } from '@app/src/wf-constants'

const MappingNodeSchema: Schema = {
  name: 'mappingNode',
  items: [
    {
      accessor: '*',
      translationId: 'schemas.mappingNode.productId',
      width: undefined,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'productId',
    },
    {
      accessor: '*',
      translationId: 'schemas.mappingNode.fromProviderId',
      width: undefined,
      display: {
        details: false,
        table: false,
        export: true,
      },
      exportPath: 'providerId',
    },
    {
      accessor: '*',
      translationId: 'schemas.mappingNode.toProviderId',
      width: undefined,
      display: {
        details: false,
        table: false,
        export: true,
      },
      exportPath: 'parentNode.providerId',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.mappingNode.product',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'product.name',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.product.gtin',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'product.gtin',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.product.productNumber',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'product.productNumber',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.product.productCustomId',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'product.productCustomId',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.product.purchaseOrder',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'product.purchaseOrder',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.mappingNode.tier',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'tier',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.mappingNode.from',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'name',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.mappingNode.activityFrom',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'actorTypeModel.name',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.mappingNode.to',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'parentNode.name',
    },
    {
      accessor: ConstVars.MappingNodeAccessors.MappingNode,
      translationId: 'schemas.mappingNode.activityTo',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'parentNode.actorTypeModel.name',
    },
  ],
  relatedContentFilters: {
    supplier: [
      {
        accessor: 'id',
        name: 'providerId',
        operator: Operators.EqualTo,
      },
    ],
  },
}

export default MappingNodeSchema
