import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import { useMenuState } from '@app/src/hooks/mui-hooks'
import useDeleteAssessmentDialog from '@app/src/hooks/useDeleteAssessmentDialog'
import { useOrganizationAssessmentSettingsCount } from '@app/src/hooks/useOrganizationAssessmentSettingsCount'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { Create as CreateIcon, Delete as DeleteIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'

export type AssessmentTemplateMoreMenuCellProps = {
  assessmentTemplate: AssessmentTemplate
}

const AssessmentTemplateMoreMenuCell: React.FC<AssessmentTemplateMoreMenuCellProps> = ({ assessmentTemplate }) => {
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()
  const organizationAssessmentSettingsCount = useOrganizationAssessmentSettingsCount(assessmentTemplate.id)
  const { confirmDelete } = useDeleteAssessmentDialog({ assessmentTemplateId: assessmentTemplate.id })

  const { formatMessage } = useIntl()

  return (
    <ParentCellOrFragment>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={closeMenu}>
        <MenuItem
          component={Link}
          to={generatePath(paths.sustainabilityLibrary, {
            configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
            configurationsSubPage: assessmentTemplate.id,
            activeTabParam: 'edit',
          })}
        >
          <ListItemIcon>
            <CreateIcon color="primary" fontSize="small" />
          </ListItemIcon>
          {formatMessage({ id: 'general.edit' })}
        </MenuItem>
        <MenuItem disabled={Boolean(organizationAssessmentSettingsCount)} onClick={confirmDelete}>
          <ListItemIcon>
            <DeleteIcon color="primary" fontSize="small" />
          </ListItemIcon>
          {formatMessage({ id: 'general.delete' })}
        </MenuItem>
      </Menu>
    </ParentCellOrFragment>
  )
}

export default AssessmentTemplateMoreMenuCell
