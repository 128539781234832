import Dialog, { DialogProps } from '@app/src/components/Dialog'
import FlagAvatar from '@app/src/components/Flags/FlagAvatar'
import FlagSuggestedRiskAvatar from '@app/src/components/Flags/FlagSuggestedRiskAvatar'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { QuestionInTemplate, QuestionnaireTemplateSection } from '@app/src/types/resourceExplorer'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface EnhancedRequestItem extends QuestionInTemplate {
  isWorldfavorRule: boolean
}

interface EnhancedRequestSection extends QuestionnaireTemplateSection {
  questions: EnhancedRequestItem[]
}

type DetailsFlagOverviewDialogProps = {
  title: string
  sections: EnhancedRequestSection[]
} & Omit<DialogProps, 'content'>

const DetailsFlagOverviewDialog: React.FC<DetailsFlagOverviewDialogProps> = ({
  title,
  sections,
  onClose,
  ...dialogProps
}) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      title={title}
      onClose={onClose}
      {...dialogProps}
      content={
        <>
          {sections?.map(section => (
            <Grid container key={section.order}>
              {section.questions.map((item, i) => (
                <React.Fragment key={item.id}>
                  <Grid item xs={12} key={i} mt={2}>
                    <Box display="flex">
                      <Box display="flex">
                        {item.isWorldfavorRule ? (
                          <FlagSuggestedRiskAvatar size={AvatarSize.Small} />
                        ) : (
                          <FlagAvatar size={AvatarSize.Small} />
                        )}
                      </Box>

                      <Typography variant="body1" display="flex" whiteSpace="pre-wrap" ml={2}>
                        {formatMessage(
                          { id: 'statistics.questionOrderAndTitle' },
                          {
                            sectionOrder: section.order,
                            itemOrder: item.order,
                            title: item.questionText,
                            b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                          },
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          ))}
        </>
      }
      buttons={[
        {
          label: formatMessage({ id: 'general.close' }),
          onClick: onClose,
        },
      ]}
    />
  )
}

export default DetailsFlagOverviewDialog
