import ChipCell from '@app/src/components/Table/Cells/ChipCell'
import FlagIndicatorCell from '@app/src/components/Table/Cells/FlagIndicatorCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import ReportMenuCell from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/ReportMenuCell'
import { countUniqueActiveItems } from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/StandardReportsRow'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import PublishedStatusChip from '@app/src/pages/TemplateBuilder/PublishedStatusChip'
import { QuestionnaireTemplate, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material'
import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'

interface CustomReportsRowProps {
  row: QuestionnaireTemplate
}

export const CustomReportsRow: React.FC<CustomReportsRowProps> = ({ row: requestTemplate }) => {
  const { formatMessage } = useIntl()
  const flagRules = requestTemplate.sections?.flatMap(s => s.questions).flatMap(q => q.reportFlagRules ?? []) ?? []
  const flagCount = countUniqueActiveItems(flagRules)
  const history = useHistory()

  return (
    <TableRow>
      <RequestCell
        hideAvatar
        title={requestTemplate.title || formatMessage({ id: 'general.noTitle' })}
        onClick={() =>
          history.push({
            pathname: generatePath(paths.sustainabilityLibrary, {
              configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questionnaires,
              configurationsSubPage: requestTemplate.id,
            }),
          })
        }
      />
      <TableCell>
        <PublishedStatusChip isPublished={Boolean(requestTemplate.isPublished)} />
      </TableCell>
      <FlagIndicatorCell count={flagCount} disabled={!flagCount} bgcolor="error.main" />
      <ChipCell
        tooltipText={formatMessage({
          id: `questionnaireConfig.${
            requestTemplate.questionnaireTemplateType === QuestionnaireTypeEnum.Regular
              ? 'regularQuestionnaireExplanation'
              : 'sharedQuestionnaireExplanation'
          }`,
        })}
        icon={
          requestTemplate.questionnaireTemplateType === QuestionnaireTypeEnum.Regular ? (
            <LockOutlined />
          ) : (
            <LockOpenOutlined />
          )
        }
        value={formatMessage({ id: `schemas.requestTemplate.${requestTemplate.questionnaireTemplateType}` })}
      />
      <ReportMenuCell questionnaireTemplate={requestTemplate} />
    </TableRow>
  )
}

export default CustomReportsRow
