import { getYears } from '@app/src/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export const useQuestionnairePeriodOptions = () => {
  const { lastYearPossibleToSendQuestionnairesFor } = useFlags()
  const { formatMessage } = useIntl()

  const getYearOptionLabel = (year: number) => {
    const isLastReportingPeriod = year === new Date().getFullYear() + lastYearPossibleToSendQuestionnairesFor
    return `${year}${
      isLastReportingPeriod ? ` (${formatMessage({ id: `form.createRequest.lastReportingPeriod` })})` : ''
    }`
  }

  const questionnaireYearOptions = useMemo(
    () =>
      getYears(10, lastYearPossibleToSendQuestionnairesFor).map(y => ({
        value: y,
        label: getYearOptionLabel(y),
      })),
    [lastYearPossibleToSendQuestionnairesFor],
  )

  return {
    questionnaireYearOptions,
  }
}
