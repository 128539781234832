import { TableRow } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export type ParentRowOrFragmentProps = {
  disableTableRow?: boolean
}

const ParentRowOrFragment: React.FC<PropsWithChildren<ParentRowOrFragmentProps>> = ({ disableTableRow, children }) => {
  if (disableTableRow) {
    return <>{children}</>
  }

  return <TableRow>{children}</TableRow>
}

export default ParentRowOrFragment
