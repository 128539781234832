import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import CreationModalStep, { CreationModalStepProps } from '@app/src/components/CreationModal/CreationModalStep'
import InviteProviderEmailPreview from '@app/src/components/ReferralContact/InviteProviderEmailPreview'
import SendEmailCustomization from '@app/src/components/ReferralContact/SendEmailCustomization'
import SendInviteTimeline from '@app/src/components/ReferralContact/SendInviteTimeline'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useConnectProvidersModal } from '@app/src/context/ConnectProvidersModalContext'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import {
  ConnectProvidersFormData,
  useSendInviteEmail,
} from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/hooks/inviteEmail'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { SendOutlined } from '@mui/icons-material'
import { Dialog, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import ConnectSuccessScreen from './ConnectSuccessScreen'
import ProvidersList from './ProvidersList'

export enum CONNECT_PROVIDERS_QUESTION_KEYS {
  PROVIDER_IDS = 'providerIds',
  OVERVIEW = 'overview',
}

type CreateAutomationModalProps = {
  open: boolean
  onClose: () => void
}

export type ContactPersonInfo = {
  name: string
  email: string
  phoneNumber?: string
}

const ConnectProvidersModal: React.FC<CreateAutomationModalProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const { providersToConnect } = useConnectProvidersModal()
  const { sendInvite, isSendingInvite } = useSendInviteEmail()
  const { activeStep, setActiveStep, setTotalSteps } = useCreationModalProgress()
  const formMethods = useForm<ConnectProvidersFormData>({
    shouldUnregister: false,
    defaultValues: {
      [CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS]: [],
    },
  })
  const { handleSubmit, reset, watch } = formMethods
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [selectedProviderIds, setSelectedProviderIds] = useState<Array<number>>([])

  const watchProviders = watch(CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS)

  const QUESTIONS: CreationModalStepProps[] = [
    {
      title: formatMessage({ id: 'form.connectProviders.invite.title' }),
      description: (
        <Typography>
          {formatMessage(
            { id: 'form.connectProviders.invite.description' },
            {
              count: selectedProviderIds.length,
              hasMissingContacts: Boolean(selectedProviderIds.length - watchProviders.length),
              missingCount: selectedProviderIds.length - watchProviders.length,
            },
          )}
        </Typography>
      ),
      fieldnames: [CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS],
      children: (
        <ProvidersList
          providers={providersToConnect}
          selectedProviderIds={selectedProviderIds}
          setSelectedProviderIds={setSelectedProviderIds}
        />
      ),
      disableInitialBack: true,
      overrideButtonTextId: 'general.continue',
    },
    {
      title: formatMessage({ id: 'form.connectProviders.emailPreview.title' }),
      description: formatMessage({ id: 'form.connectProviders.review.description' }, { count: watchProviders.length }),
      fieldnames: [],
      children: <SendEmailCustomization />,
      disableInitialBack: true,
      informationAlert: formatMessage({ id: 'form.connectProviders.review.informationAlert' }),
      splitView: {
        enabled: true,
        component: <InviteProviderEmailPreview provider={providersToConnect[0]} />,
      },
      overrideButtonTextId: 'general.continue',
    },
    {
      title: formatMessage({ id: 'form.connectProviders.review.title' }),
      description: formatMessage({ id: 'form.connectProviders.review.description' }, { count: watchProviders.length }),
      fieldnames: [CONNECT_PROVIDERS_QUESTION_KEYS.OVERVIEW],
      children: <SendInviteTimeline />,
      hideNextButton: true,
      disableInitialBack: true,
      overrideButtonTextId: 'referral.sendInvite.title',
      nextButtonProps: {
        startIcon: <SendOutlined />,
      },
      informationAlert: formatMessage({ id: 'form.connectProviders.review.informationAlert' }),
      isLoading: isSendingInvite,
    },
  ]

  const activeQuestion = useMemo(() => QUESTIONS[activeStep - 1], [activeStep, QUESTIONS, selectedProviderIds])

  const onSubmit = async (values: ConnectProvidersFormData) => {
    await sendInvite(
      {
        providerIds: values.providerIds,
        customMessage: values.customMessage,
        contactPersonInfo: values.contactPersonInfo,
      },
      () => {
        trackEvent({
          name: AmplitudeTrackingEvents.Accessor.InviteSupplier.InvitationSent,
          eventProps: {
            invitation_type: 'bulk',
            count: values.providerIds.length,
          },
        })

        setShowSuccessScreen(true)
      },
    )
  }

  useEffect(() => {
    if (providersToConnect.length === 1 && providersToConnect[0].referralContact) {
      setActiveStep(2)
    }
  }, [providersToConnect, setActiveStep])

  useEffect(() => {
    if (open) {
      trackEvent({
        name: AmplitudeTrackingEvents.Accessor.InviteSupplier.InvitationViewed,
        eventProps: {
          invitation_type: 'bulk',
        },
      })
    }
  }, [open])

  useEffect(() => {
    reset({
      [CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS]: providersToConnect
        .filter(provider => Boolean(provider.referralContact))
        .map(provider => provider.id),
    })
    setSelectedProviderIds(providersToConnect.map(provider => provider.id))

    return () => {
      setActiveStep(1)
      reset()
      setShowSuccessScreen(false)
    }
  }, [open])

  useEffect(() => {
    setSelectedProviderIds(prev => {
      const filteredProviders = providersToConnect.filter(provider => prev.includes(provider.id))

      reset({
        [CONNECT_PROVIDERS_QUESTION_KEYS.PROVIDER_IDS]: filteredProviders
          .filter(provider => Boolean(provider.referralContact))
          .map(provider => provider.id),
      })

      return filteredProviders.map(provider => provider.id)
    })
  }, [providersToConnect])

  useEffect(() => {
    if (!providersToConnect.length) {
      onClose()
    }
  }, [providersToConnect.length])

  useEffect(() => {
    setTotalSteps(QUESTIONS.length)
  }, [QUESTIONS.length])

  return (
    <Dialog open={open} onClose={onClose} fullScreen closeAfterTransition>
      {showSuccessScreen ? (
        <ConnectSuccessScreen
          missingContactProviderIds={providersToConnect
            .filter(provider => !provider.referralContact)
            .filter(provider => selectedProviderIds.includes(provider.id))
            .map(provider => provider.id)}
          invitedProviderIds={watchProviders}
          onClose={onClose}
        />
      ) : (
        <CreationModalContainer
          title={
            activeStep === 1
              ? formatMessage({ id: 'form.connectProviders.header.review' })
              : activeStep === 2
                ? formatMessage({ id: 'form.connectProviders.header.emailPreview' })
                : formatMessage({ id: 'form.connectProviders.header.overview' })
          }
          onClose={onClose}
        >
          <FormProvider {...formMethods}>
            <Stack component="form" flexGrow={1} alignItems="center" onSubmit={handleSubmit(onSubmit)}>
              {activeQuestion && (
                <CreationModalStep {...activeQuestion} widthOverride={584}>
                  {activeQuestion.children}
                </CreationModalStep>
              )}
            </Stack>
          </FormProvider>
        </CreationModalContainer>
      )}
    </Dialog>
  )
}

export default ConnectProvidersModal
