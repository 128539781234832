import { TableRow } from '@mui/material'
import CompanyCell, { CompanyCellProps } from '@app/src/components/Table/Cells/CompanyCell'
import React from 'react'
import ResponseItemRow, { ResponseItemRowProps } from './ResponseItemRow'

interface ResponseItemCompanyRowProps extends ResponseItemRowProps, Pick<CompanyCellProps, 'drawer'> {}

export const ResponseItemCompanyRow = ({
  row: responseItem,
  drawer = true,
}: ResponseItemCompanyRowProps): JSX.Element => {
  return (
    <TableRow>
      <CompanyCell
        company={{
          ...responseItem.response.request.targetAliasObject,
          organization: responseItem.response.request.targetObject,
        }}
        drawer={drawer}
      />
      <ResponseItemRow row={responseItem} disableTableRow />
    </TableRow>
  )
}

export default ResponseItemCompanyRow
