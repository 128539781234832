import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { EsgRequestTags } from '@app/src/wf-constants'
import { ChipsValue } from './EsgLibrary'
import FilterChip from './FilterChip'

const useStyles = makeStyles(({ spacing }) => ({
  chip: {
    marginRight: spacing(1),
  },
}))

interface YourDataFiltersProps {
  setTagsFilters: React.Dispatch<React.SetStateAction<string[] | undefined>>
  existingTags: string[]
}

const YourDataFilters: React.FC<YourDataFiltersProps> = ({ setTagsFilters, existingTags }) => {
  const { chip } = useStyles()
  const [activeChips, setActiveChips] = useState<ChipsValue[]>([ChipsValue.ALL])

  const handleChipClick = (value: ChipsValue) => {
    // When ALL is clicked the filters should be cleaned up, all the other selected tags should be cleaned up
    if (value === ChipsValue.ALL) {
      setTagsFilters(undefined)
      setActiveChips([value])
    }

    // If the chip clicked is already in the ActiveChips it should be removed except for ALL
    if (value !== ChipsValue.ALL && activeChips.includes(value)) {
      setActiveChips(curr => curr.filter(chip => chip !== value))
      setTagsFilters(curr => curr?.filter(tag => tag !== value))

      // If we're clearing the last chip it should go back to ALL
      if (activeChips.length === 1) {
        setActiveChips([ChipsValue.ALL])
        setTagsFilters(undefined)
      }
      return
    }

    // If any other chip is clicked it should add the corresponding filter and they can pile up
    if (value !== ChipsValue.ALL) {
      setActiveChips(curr => [...curr.filter(chip => chip !== ChipsValue.ALL), value])
      setTagsFilters(curr => [...(curr || []), value])
    }
  }

  return (
    <Box display="flex" mt={2}>
      <FilterChip className={chip} activeChips={activeChips} value={ChipsValue.ALL} onClick={handleChipClick} />
      <FilterChip
        className={chip}
        activeChips={activeChips}
        value={ChipsValue.ENVIRONMENT}
        onClick={handleChipClick}
        disabled={!existingTags.includes(EsgRequestTags.Environmental)}
      />

      <FilterChip
        className={chip}
        activeChips={activeChips}
        value={ChipsValue.SOCIAL}
        onClick={handleChipClick}
        disabled={!existingTags.includes(EsgRequestTags.Social)}
      />

      <FilterChip
        className={chip}
        activeChips={activeChips}
        value={ChipsValue.GOVERNANCE}
        onClick={handleChipClick}
        disabled={!existingTags.includes(EsgRequestTags.Governance)}
      />

      <FilterChip
        className={chip}
        activeChips={activeChips}
        value={ChipsValue.REGULATIONS}
        onClick={handleChipClick}
        disabled={!existingTags.includes(EsgRequestTags.Regulations)}
      />

      <FilterChip
        className={chip}
        activeChips={activeChips}
        value={ChipsValue.INITIATIVES}
        onClick={handleChipClick}
        disabled={!existingTags.includes(EsgRequestTags.Initiatives)}
      />

      <FilterChip
        className={chip}
        activeChips={activeChips}
        value={ChipsValue.SUPPLY_CHAIN}
        onClick={handleChipClick}
        disabled={!existingTags.includes(EsgRequestTags.SupplyChain)}
      />
    </Box>
  )
}

export default YourDataFilters
