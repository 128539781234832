import React, { useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Router, matchPath } from 'react-router-dom'

import ApplicationContexts from '@app/src/ApplicationContexts'
import GlobalError, { logError } from './components/GlobalError'
import App from './App'
import { getLocale, getMessages } from './i18n'
import getTheme from './theme'

import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import Authentication from './Authentication'
import { SimplifiedRoutes } from './appRoutes'
import { Config } from './config'
import { useLocale } from './context/LocaleContext'
import LocaleContextProvider from './context/LocaleContextProvider'
import { createBrowserHistory } from 'history'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

const history = createBrowserHistory()

const AppContainer: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  })

  const devMode =
    Config.FRONT_ENV === 'dev' ||
    Config.FRONT_ENV === 'test' ||
    (Config.FRONT_ENV === 'staging' && localStorage.getItem('dev:react-query-devtool'))

  if (Config.FRONT_ENV !== 'dev') {
    Sentry.init({
      dsn: 'https://732ae30322884cb386a4136bcc761157@o4504922429063168.ingest.sentry.io/4504922438828032',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, SimplifiedRoutes, matchPath),
        }),
      ],
      tracesSampleRate: 0.2,
      autoSessionTracking: false,
      environment: Config.FRONT_ENV,
    })
  }

  const theme = useMemo(() => getTheme, [])

  // rename this locale stuff
  const { locale } = useLocale()
  const language = getLocale(locale)

  return (
    <IntlProvider key={language} locale={language} messages={getMessages(language)}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary
            FallbackComponent={GlobalError}
            onError={(error, info): void => {
              logError(error, info)
            }}
          >
            <QueryClientProvider client={queryClient}>
              <ApplicationContexts>
                <App />
              </ApplicationContexts>
              {devMode && <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={{ style: { bottom: 70 } }} />}
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>
  )
}

const LDProvider = withLDProvider({
  clientSideID: Config.LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
  options: {
    /* ... */
  },
})(AppContainer)

const Provider: React.FC = () => {
  return (
    <LocaleContextProvider>
      <Router history={history}>
        <Authentication>
          <LDProvider />
        </Authentication>
      </Router>
    </LocaleContextProvider>
  )
}

export default Provider
