import { getSxByRank } from '@app/src/pages/QuestionEditor/AnswerClassification/ClassificationRow'
import { QuestionAnswerRank } from '@app/src/types/resourceExplorer'
import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AnswerClassificationExplanationRowProps {
  rank: QuestionAnswerRank
  chipLabel: string
  explanation: string
  points: string
}

const AnswerClassificationExplanationRow: React.FC<AnswerClassificationExplanationRowProps> = ({
  rank,
  chipLabel,
  explanation,
  points,
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      '&:last-child': { borderBottom: 'none' },
    }}
  >
    <Box width={184} display="flex" alignItems="center" px={2} py={1.5}>
      <Chip
        label={chipLabel}
        size="small"
        sx={({ palette }) => ({
          ...getSxByRank(palette, rank),
          fontWeight: 500,
          fontSize: 13,
          lineHeight: '18px',
          letterSpacing: 0.16,
          fontFamily: 'Poppins',
        })}
      />
    </Box>
    <Box flex={1} px={2} py={1}>
      <Typography variant="body2" sx={{ fontSize: 14, lineHeight: '24px', fontFamily: 'Poppins' }}>
        {explanation}
      </Typography>
    </Box>
    <Box width={120} display="flex" justifyContent="flex-end" px={2} py={1.5}>
      <Typography variant="body2" sx={{ fontSize: 14, lineHeight: '24px', fontFamily: 'Poppins' }}>
        {points}
      </Typography>
    </Box>
  </Box>
)

const AnswerClassificationExplanation: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="subtitle1" sx={{ fontSize: 14, fontWeight: 600, lineHeight: '24px', fontFamily: 'Poppins' }}>
        {formatMessage({ id: 'templateBuilder.answerClassifications.title' })}
      </Typography>
      <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 1, overflow: 'hidden' }}>
        <AnswerClassificationExplanationRow
          rank={QuestionAnswerRank.Neutral}
          chipLabel={formatMessage({ id: 'assessments.methodology.neutral' })}
          explanation={formatMessage({ id: 'assessments.methodology.neutralExplanation' })}
          points={formatMessage({ id: 'assessments.methodology.neutralPoints' })}
        />
        <AnswerClassificationExplanationRow
          rank={QuestionAnswerRank.Positive}
          chipLabel={formatMessage({ id: 'assessments.methodology.positive' })}
          explanation={formatMessage({ id: 'assessments.methodology.positiveExplanation' })}
          points={formatMessage({ id: 'assessments.methodology.positivePoints' })}
        />
        <AnswerClassificationExplanationRow
          rank={QuestionAnswerRank.Negative}
          chipLabel={formatMessage({ id: 'assessments.methodology.negative' })}
          explanation={formatMessage({ id: 'assessments.methodology.negativeExplanation' })}
          points={formatMessage({ id: 'assessments.methodology.negativePoints' })}
        />
        <AnswerClassificationExplanationRow
          rank={QuestionAnswerRank.NegativeAdverse}
          chipLabel={formatMessage({ id: 'assessments.methodology.negativeAdverse' })}
          explanation={formatMessage({ id: 'assessments.methodology.negativeAdverseExplanation' })}
          points={formatMessage({ id: 'assessments.methodology.negativeAdversePoints' })}
        />
      </Box>
    </Box>
  )
}

export default AnswerClassificationExplanation
