import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFileDownload } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { DeleteOutline, FilePresentOutlined } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { FileResult } from './FileRepository'

interface FileRepoRowProps {
  file: FileResult
}

export const FileRepoRow: React.FC<FileRepoRowProps> = ({ file }) => {
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { mutateAsync: deleteFileAsync, isLoading: isDeleting } = useDeleteResource()
  const { downloadFile, isFileDownloading } = useFileDownload()
  const [isWaitingToReload, setIsWaitingToReload] = useState(false)
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const { formatMessage } = useIntl()

  const handleFileDownload = async (fileName: string) => {
    if (isFileDownloading) return
    await downloadFile(endpoints.suggestionFile(fileName))
  }

  const handleDelete = async () => {
    setConfirmDeleteOpen(false)
    await deleteFileAsync(
      { url: endpoints.suggestionFile(file.name) },
      {
        onSuccess: () => {
          setIsWaitingToReload(true)
          queryClient.invalidateQueries(FetchKey.SuggestedResponse)
          setTimeout(() => {
            queryClient.invalidateQueries(FetchKey.FileRepository)
            setIsWaitingToReload(false)
          }, 2000)
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return (
    <TableRow>
      <TableCell>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={() => handleFileDownload(file.name)}
          color={isFileDownloading ? 'grey.400' : 'grey.700'}
        >
          <FilePresentOutlined color="inherit" />
          <Typography
            color={isFileDownloading ? undefined : 'textPrimary'}
            sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
          >
            {file.name}
          </Typography>
          {isFileDownloading && <CircularProgress size={16} />}
        </Stack>
      </TableCell>
      <DateCell value={file.createdAt} />
      <TableCell>
        <IconButton onClick={() => setConfirmDeleteOpen(true)} disabled={isDeleting || isWaitingToReload}>
          {isDeleting || isWaitingToReload ? <CircularProgress size={20} /> : <DeleteOutline color="primary" />}
        </IconButton>
      </TableCell>
      <Dialog open={isConfirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>{formatMessage({ id: 'general.areYouSure' })}</DialogTitle>
        <DialogContent>{formatMessage({ id: 'schemas.repositoryFile.deleteFileInfo' })}</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)}>{formatMessage({ id: 'general.cancel' })}</Button>
          <Button onClick={handleDelete}>{formatMessage({ id: 'general.delete' })}</Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  )
}

export default FileRepoRow
