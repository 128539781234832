import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Schema } from '@app/src/types/schemas'
import { ConstVars, Flows, Solutions } from '@app/src/wf-constants'

const getResponseItemSchema = (solution?: string) => {
  const ResponseItemSchema: Schema = {
    name: 'responseItem',
    defaultImage: '',
    items: [
      {
        accessor: ConstVars.ResponseItemsAccessors.Sender,
        translationId: 'schemas.responseItem.sender',
        width: 300,
        display: {
          table: false,
          details: true,
          export: false,
        },
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Target,
        translationId: 'schemas.responseItem.target',
        width: 300,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'creatorOrganization.name',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.RequestItemTemplateId,
        translationId: 'schemas.responseItem.requestItemTemplateId',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'requestItem.questionId',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Object,
        translationId: 'schemas.responseItem.object',
        width: 500,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'requestItem.description',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Section,
        translationId: 'schemas.responseItem.section',
        width: 350,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'requestItem.section.title',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Answer,
        translationId: 'schemas.responseItem.answer',
        width: 400,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'answer',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Comment,
        translationId: 'schemas.responseItem.comment',
        width: 400,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'comment',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Answer,
        translationId: 'schemas.responseItem.unit',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'requestItem.unit.symbol',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ExternalVerificationStatus,
        translationId: 'schemas.responseItem.externalVerificationStatus',
        width: 320,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'verifications[0].externalVerificationStatus',
        exclude: [Flows.Interaction],
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.InternalVerificationStatus,
        translationId: 'schemas.responseItem.internalVerificationStatus',
        width: 320,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'verifications[0].internalVerificationStatus',
        exclude: [Flows.Interaction, Flows.Overview],
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ExternalVerificationComment,
        translationId: 'schemas.responseItem.externalVerificationComment',
        width: 400,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'verifications[0].externalVerificationComment',
        exclude: [Flows.Interaction],
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.InternalVerificationComment,
        translationId: 'schemas.responseItem.internalVerificationComment',
        width: 400,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'verifications[0].internalVerificationComment',
        exclude: [Flows.Interaction, Flows.Overview],
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.RequestTemplate,
        translationId: 'schemas.responseItem.request',
        width: 300,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'response.request.title',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.PeriodStartsAt,
        translationId: 'schemas.responseItem.periodStartsAt',
        width: 200,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'requestItem.PeriodStartsAt',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.PeriodEndsAt,
        translationId: 'schemas.responseItem.periodEndsAt',
        width: 200,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'requestItem.PeriodEndsAt',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.QuestionType,
        translationId: 'schemas.responseItem.questionType',
        width: 300,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'requestItem.QuestionType.name',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Responder,
        translationId: 'schemas.responseItem.responder',
        width: 300,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'response.request.responderObject.name',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.HasAnswered,
        translationId: 'schemas.responseItem.hasAnswered',
        width: 200,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'isAnswered',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.AnswerSet,
        translationId: 'schemas.responseItem.answerSet',
        width: 200,
        display: {
          table: true,
          details: true,
          export: false,
        },
        exportPath: 'isValueSet',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.TargetType,
        translationId: 'schemas.responseItem.targetType',
        width: 300,
        display: {
          table: false,
          details: true,
          export: false,
        },
        exportPath: 'response.request.targetObjectType',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ResponseDate,
        translationId: 'schemas.responseItem.responseDate',
        width: 200,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'response.submittedAt',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ExpiresAt,
        translationId: 'schemas.responseItem.expiresAt',
        width: 200,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'expiresAt',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ResponseExternalVerificationStatus,
        translationId: 'schemas.responseItem.responseExternalVerificationStatus',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.verifications[0].externalVerificationStatus',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ResponseInternalVerificationStatus,
        translationId: 'schemas.responseItem.responseInternalVerificationStatus',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.verifications[0].internalVerificationStatus',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ResponseExternalVerificationComment,
        translationId: 'schemas.responseItem.responseExternalVerificationComment',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.verifications[0].externalVerificationComment',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ResponseInternalVerificationComment,
        translationId: 'schemas.responseItem.responseInternalVerificationComment',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.verifications[0].internalVerificationComment',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.LinkStatus,
        translationId: 'schemas.filter.linkStatus',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.request.subscriptions[0].target.linkStatus',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ActivityStatus,
        translationId: 'schemas.provider.activityStatus',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.request.subscriptions[0].target.providerActivityStatusValue',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.FinalRiskRating,
        translationId: 'schemas.provider.finalRiskRating',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.request.subscriptions[0].target.finalRiskRatingValue',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Priority,
        translationId: 'schemas.provider.priority',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.request.subscriptions[0].target.providerPriorityValue',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.ApprovalStatus,
        translationId: 'schemas.provider.providerApprovalStatus',
        display: {
          table: false,
          details: false,
          export: solution !== Solutions.Finance ? true : false,
        },
        exportPath: 'response.request.subscriptions[0].target.providerApprovalStatusValue',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Tier,
        translationId: 'schemas.provider.tier',
        display: {
          table: false,
          details: false,
          export: solution !== Solutions.Finance ? true : false,
        },
        exportPath: 'response.request.subscriptions[0].target.tier',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.SupplierUsage,
        translationId: 'schemas.provider.supplierUsage',
        display: {
          table: false,
          details: false,
          export: solution !== Solutions.Finance ? true : false,
        },
        exportPath: 'response.request.subscriptions[0].target.supplierUsageValue',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.CustomCategory,
        translationId: 'schemas.supplier.customCategories',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.request.subscriptions[0].target.categoryOptionsNames',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.CustomId,
        translationId: 'schemas.supplier.customId',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.request.subscriptions[0].target.customId',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Country,
        translationId: 'schemas.responseItem.country',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.request.subscriptions[0].target.country.name',
      },
      {
        accessor: ConstVars.ResponseItemsAccessors.Duns,
        translationId: 'schemas.organization.duns',
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'response.request.subscriptions[0].target.duns',
      },
    ],
    relatedContentFilters: {
      supplier: [
        {
          accessor: 'organizationId',
          name: 'response.request.responderOrganizationId',
          operator: Operators.EqualTo,
        },
      ],
      investee: [
        {
          accessor: 'organizationId',
          name: 'response.request.responderOrganizationId',
          operator: Operators.EqualTo,
        },
      ],
      company: [
        {
          accessor: 'organizationId',
          name: 'response.request.responderOrganizationId',
          operator: Operators.EqualTo,
        },
      ],
    },
  }

  return ResponseItemSchema
}

export default getResponseItemSchema
