import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { Config } from './config'
import React, { PropsWithChildren } from 'react'
import { useHistory } from 'react-router-dom'
import { getRedirectionParameters } from '@app/src/hooks/solutionAutoLogin'

const Authentication: React.FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory()

  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = (appState?: AppState) => {
    // grabbing the querystring from the appstate
    const [path, queryString] = (appState?.targetUrl ?? '').split(/\?(.*)/)

    const { redirectTo, loginParams } = getRedirectionParameters(path, queryString)

    history.push(
      appState && appState.targetUrl && (redirectTo !== '/' || loginParams !== '')
        ? `${window.location.pathname}?redirectTo=${encodeURIComponent(redirectTo)}${loginParams}`
        : window.location.pathname,
    )
  }

  return (
    <Auth0Provider
      domain={Config.AUTH0_DOMAIN || ''}
      clientId={Config.AUTH0_CLIENT_ID || ''}
      audience={Config.AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  )
}

export default Authentication
