import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const FileRepoHeader: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell
        minWidth={TableCellWidth.EXTRA_LARGE}
        label={formatMessage({ id: 'schemas.repositoryFile.name' })}
      />
      <TableHeaderCell
        minWidth={TableCellWidth.LARGE}
        label={formatMessage({ id: 'schemas.repositoryFile.uploadDate' })}
      />
      <TableHeaderCell minWidth={TableCellWidth.EMPTY} label="" />
    </TableRow>
  )
}

export default FileRepoHeader
