import { alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'

interface GradientOverlayProps {
  className?: string
}

const useStyles = makeStyles(theme => ({
  root: {
    background: alpha(theme.palette.common.black, 0.5),
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
}))

const GradientOverlay = ({ className }: GradientOverlayProps): JSX.Element => {
  const classes = useStyles()

  return <div className={classNames(classes.root, className)} />
}

export default GradientOverlay
