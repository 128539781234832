import React from 'react'

const RightFrontBlob = () => {
  return (
    <svg width="210" height="45" viewBox="0 0 210 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M126.254 10.8788C46.2475 -22.7819 -0.51897 23.7323 0.554104 122.077C67.2311 123.095 235.01 82.379 206.1 86.5132C163.007 92.6759 206.26 44.5395 126.254 10.8788Z"
        fill="#FFC2B5"
      />
    </svg>
  )
}

export default RightFrontBlob
