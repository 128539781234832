import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import GuidanceBanner from '@app/src/components/GuidanceBanner'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useBetterQueryParams } from '@app/src/hooks/queryParams'
import ReportFlagRules from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/Flags/ReportFlagRules'
import {
  SUSTAINABILITY_LIBRARY_PAGE_IDS,
  SustainabilityLibraryParams,
} from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import { QuestionnaireTemplate, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { ADMIN_ROLES } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { DeleteOutline, ModeEditOutline } from '@mui/icons-material'
import { Alert, Box, Chip, CircularProgress, Stack, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { Redirect, Route, Switch, generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router'
import SustainabilityLibraryCollection from '../SustainabilityLibraryCollection'
import { QuestionnairesConfigTabs } from './QuestionnairesConfig'
import TemplatePreview from './TemplatePreview'

type QuestionnaireConfigProps = {
  id: string
}

export type QuestionnaireQueryParams = {
  isStandard: boolean
}

export enum QuestionnaireConfigTabs {
  Questionnaire = 'questionnaire',
  FlagRisks = 'flag-risks',
}

const QuestionnaireConfig: React.FC<QuestionnaireConfigProps> = ({ id }) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { path } = useRouteMatch()
  const { search } = useLocation()
  const confirm = useConfirm()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { isStandard } = useBetterQueryParams<QuestionnaireQueryParams>()
  const { mutateAsync, isLoading: isDeleting } = useDeleteResource<QuestionnaireTemplate>()
  const { role } = useAuthentication().scope
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { configurationsPage, configurationsSubPage, activeTabParam } = useParams<SustainabilityLibraryParams>()
  const { questionnaireBuilder } = useFlags()
  const {
    data: template,
    isLoading,
    isError,
  } = useFetchResourceWithPost<QuestionnaireTemplate>({
    endpoint: endpoints.templateById(id, isStandard),
    body: [
      'creatorOrganization.name',
      'sections.questions.questionType',
      'sections.questions.unit',
      'sections.questions.requestCollection',
      'sections.questions.reportFlagRules',
      'sections.questions.reportFlagRules.creatorOrganization',
      'sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskNaceCodes.naceCode',
      'sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskGuidanceLinks',
    ],
    key: [FetchKey.OrganizationOwnedTemplates, id],
    options: {
      enabled: Boolean(id),
    },
  })

  const handleDelete = async () => {
    await mutateAsync(
      {
        url: endpoints.deleteTemplate(id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.SharedAndOwnedTemplates)
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
            severity: 'success',
          })
          history.push(
            generatePath(paths.sustainabilityLibrary, {
              configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questionnaires,
              configurationsSubPage: QuestionnairesConfigTabs.Standard,
              activeTabParam: QuestionnairesConfigTabs.Custom,
            }),
          )
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const isAdmin = ADMIN_ROLES.includes(role?.toLowerCase() ?? '')
  const isTemplateOwner = account?.organization?.id === template?.creatorOrganizationId
  const showAutomations = isTemplateOwner && !isStandard && isAdmin
  const canEditQuestionnaire = questionnaireBuilder && !isStandard && isAdmin && isTemplateOwner

  if (isLoading)
    return (
      <SustainabilityLibraryCollection enablePadding={{ right: true }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={4}>
          <CircularProgress />
        </Box>
      </SustainabilityLibraryCollection>
    )

  if (!template || isError)
    return (
      <SustainabilityLibraryCollection enablePadding={{ right: true }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={4}>
          <Typography variant="h3" gutterBottom>
            {formatMessage({ id: 'table.emptyStateTitle' })}
          </Typography>
          <Typography>{formatMessage({ id: 'table.errorTitle' })}</Typography>
        </Box>
      </SustainabilityLibraryCollection>
    )

  if (!showAutomations && !activeTabParam) {
    return (
      <Redirect
        to={{
          pathname: generatePath(path, {
            configurationsPage,
            configurationsSubPage,
            activeTabParam: QuestionnaireConfigTabs.Questionnaire,
          }),
          search,
        }}
      />
    )
  }

  return (
    <SustainabilityLibraryCollection
      title={template.title || formatMessage({ id: 'general.noTitle' })}
      titleTags={
        <Stack direction="row" spacing={1} alignItems="center">
          {!template.isPublished && <Chip label={formatMessage({ id: `general.draft` })} />}
          {template.isPublished && <Chip color="info" label={formatMessage({ id: `templateBuilder.published` })} />}
          <Chip label={formatMessage({ id: `schemas.requestTemplate.${template.questionnaireTemplateType}` })} />
          {template.questionnaireTemplateType === QuestionnaireTypeEnum.Shared && (
            <Typography variant="body2" color="textSecondary">
              {formatMessage(
                {
                  id: isTemplateOwner
                    ? 'questionnaireConfig.sharedWithTemplateOwner'
                    : 'questionnaireConfig.sharedWith',
                },
                {
                  count: template.sharedTemplates?.filter(
                    shared => shared.organizationId !== shared.creatorOrganizationId,
                  ).length,
                  organizationName: template?.creatorOrganization?.name,
                },
              )}
            </Typography>
          )}
        </Stack>
      }
      actionButtons={[
        ...insertIf<ActionButton>(canEditQuestionnaire, {
          label: formatMessage({ id: 'general.delete' }),
          startIcon: <DeleteOutline />,
          loading: isDeleting,
          onClick: () =>
            confirm({
              title: formatMessage({ id: 'general.areYouSure' }),
              content: formatMessage({ id: 'templateBuilder.delete' }, { br: <br /> }),
              confirmationText: formatMessage({ id: 'general.delete' }),
              confirmationButtonProps: { variant: 'text' },
              cancellationButtonProps: { variant: 'text' },
              buttonOrder: ['confirm', 'cancel'],
            }).then(handleDelete),
        }),
        ...insertIf<ActionButton>(canEditQuestionnaire, {
          label: formatMessage({ id: 'general.edit' }),
          variant: 'outlined',
          startIcon: <ModeEditOutline />,
          disabled: isDeleting,
          to: generatePath(paths.editTemplate, {
            templateId: template.id,
          }),
        }),
      ]}
      actionTabs={
        showAutomations
          ? [
              {
                type: QuestionnaireConfigTabs.Questionnaire,
                url: QuestionnaireConfigTabs.Questionnaire,
                label: formatMessage({ id: 'questionnaireConfig.questionnaireTab' }),
              },
              {
                type: QuestionnaireConfigTabs.FlagRisks,
                url: QuestionnaireConfigTabs.FlagRisks,
                label: formatMessage({ id: 'questionnaireConfig.automationTab' }),
              },
            ]
          : undefined
      }
      enablePadding={{ right: activeTabParam !== QuestionnaireConfigTabs.FlagRisks }}
    >
      <Switch>
        <Route
          path={generatePath(path, {
            configurationsPage,
            configurationsSubPage,
            activeTabParam: QuestionnaireConfigTabs.Questionnaire,
          })}
        >
          {!isStandard && (
            <Box mb={1}>
              <GuidanceBanner
                title={formatMessage({ id: 'questionnaireConfig.guidanceTitle' })}
                description={formatMessage(
                  {
                    id: isTemplateOwner ? 'questionnaireConfig.guidanceInfo' : 'questionnaireConfig.guidanceInfoShared',
                  },
                  {
                    owner: template?.creatorOrganization?.name,
                  },
                )}
              />
            </Box>
          )}

          {!isStandard && !template.isPublished && (
            <Box mb={1}>
              <Alert severity="info">{formatMessage({ id: 'questionnaireConfig.draftState' })}</Alert>
            </Box>
          )}

          <TemplatePreview template={template} />
        </Route>

        {showAutomations && (
          <Route
            path={generatePath(path, {
              configurationsPage,
              configurationsSubPage,
              activeTabParam: QuestionnaireConfigTabs.FlagRisks,
            })}
          >
            <ReportFlagRules id={id} />
          </Route>
        )}
      </Switch>
    </SustainabilityLibraryCollection>
  )
}

export default QuestionnaireConfig
