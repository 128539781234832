import { Grid, Typography } from '@mui/material'
import React from 'react'

export const Detail = ({
  children,
  label,
  isSecondary,
}: {
  children: JSX.Element
  label: string
  isSecondary?: boolean
}): JSX.Element => {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} sx={{ alignContent: 'flex-end ' }}>
          <Typography color={isSecondary ? 'textSecondary' : ''} variant={isSecondary ? 'body2' : 'body1'}>
            {label}
          </Typography>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}
