import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import AppContainer from './AppContainer'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<AppContainer />)
