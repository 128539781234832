import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import { useAccount } from '@app/src/context/AccountContext'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import CustomQuestionHeader from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/CustomQuestionHeader'
import CustomQuestionRow from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/CustomQuestionRow'
import { QuestionSelectionProps } from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/StandardQuestionCollection'
import CustomQuestionFilters from '@app/src/pages/ResourceCollection/Filters/CustomQuestionFilters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollection from '@app/src/pages/ResourceCollection/index'
import { Question } from '@app/src/types/resourceExplorer'
import { FormatListNumberedRtlOutlined } from '@mui/icons-material'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

const allowedFilters: string[] = ['questionText']

interface CustomQuestionCollectionProps {
  selectionProps?: QuestionSelectionProps
}

const CustomQuestionCollection: FC<CustomQuestionCollectionProps> = ({ selectionProps }) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const { account } = useAccount()

  const implicitFilters = [
    { name: 'creatorOrganizationId', filters: [{ operator: Operators.EqualTo, value: account?.organization?.id }] },
  ]
  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Question>({
    key: FetchKey.Questions,
    endpoint: endpoints.questionCollection,
    payload: {
      sort: sorting,
      include: [],
      filter: [...implicitFilters, ...userFilters],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, question: Question) => {
    if (e.target.checked) {
      selectionProps?.setSelectedQuestionIds(prev => [...prev, question.id])
    } else {
      selectionProps?.setSelectedQuestionIds(prev => prev.filter(questionId => questionId !== question.id))
    }
  }

  return (
    <ResourceCollection
      title=""
      embedded
      filter={
        <CustomQuestionFilters
          allowedFilters={allowedFilters}
          resetPage={resetPage}
          implicitFilters={implicitFilters}
        />
      }
    >
      <Table<Question>
        RowComponent={({ row }) => (
          <CustomQuestionRow
            row={row}
            selectionProps={
              selectionProps && {
                selectedQuestionIds: selectionProps.selectedQuestionIds,
                onCheckboxChange: onCheckboxChange,
                questionIdsAlreadyInQuestionnaire: selectionProps.questionIdsAlreadyInQuestionnaire,
              }
            }
          />
        )}
        HeaderComponent={() => (
          <CustomQuestionHeader
            toggleSorting={toggleSorting}
            activeSorting={sorting}
            includeCheckboxHeader={Boolean(selectionProps)}
            selectionProps={
              selectionProps && {
                selectedQuestionIds: selectionProps.selectedQuestionIds,
                setSelectedQuestionIds: selectionProps.setSelectedQuestionIds,
                onCheckboxChange: onCheckboxChange,
                questionIdsAlreadyInQuestionnaire: selectionProps.questionIdsAlreadyInQuestionnaire,
              }
            }
            questionsInPage={items}
          />
        )}
        emptyState={
          <EmptyState
            iconComponent={FormatListNumberedRtlOutlined}
            title={formatMessage({ id: 'questionsConfig.customEmptyState.title' })}
            description={formatMessage({ id: 'questionsConfig.customEmptyState.description' })}
            data-testid="empty-state"
          />
        }
        data={items}
        isLoading={isLoading || isFetching}
        count={count}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollection>
  )
}

export default CustomQuestionCollection
