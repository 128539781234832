import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { InquiryExportColumnsAccessor } from '@app/src/export-columns/inquiry'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import AccessorInquiryHeader from '@app/src/pages/ResourceCollection/Collections/Request/AccessorInquiryHeader'
import RequestsFilters from '@app/src/pages/ResourceCollection/Filters/RequestsFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { GenericOrganization } from '@app/src/types/organizations'
import { Inquiry, InquiryStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { br } from '@app/src/utils/translationMarkup'
import { ResourceTypes } from '@app/src/wf-constants'
import { ArrowForwardOutlined, ListAltOutlined, NotificationsActiveOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { AccessorInquiryRow } from '../Request/AccessorInquiryRow'
import { ViewTypeName } from './ManageRequestsScene'
import RequestsReminderModal, { REQUEST_TYPES } from './RequestsReminder/RequestsReminderModal'

interface AccessorRequestsSceneProps {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
  setCreateDialogOpen?: () => void
}

const allowedFilters = [
  'template.id',
  'status',
  'periodType',
  'provider.websiteAddress',
  'provider.country.id',
  'provider.vatNumber',
  'provider.organization.id',
  'request.responseExternalStatus',
  'request.responseInternalStatus',
  'isActivated',
  'deadline',
  'createdAt',
  'activatedAt',
  'periodName',
  'provider.providerApprovalStatus',
  'provider.priority',
  'provider.finalRiskRating',
  'provider.activityStatus',
  'provider.ownerUserId',
  'provider.supplierUsage',
  'provider.tier',
  'provider.categoryOptions.id',
  'template.tags.tag',
  'provider.spends.amount',
  'provider.spends.periodName',
  'provider.assessments.totalLevel',
  'provider.assessments.intervalType',
  'provider.assessments.section1Level',
  'provider.assessments.section2Level',
  'provider.assessments.section3Level',
  'provider.assessments.section4Level',
  'provider.assessments.section5Level',
  'provider.assessments.assessmentTemplate.name',
]

export interface GroupedInquiries {
  responder: GenericOrganization
  requests: Inquiry[]
}

function groupInquiriesByResponder(requests: Inquiry[]): GroupedInquiries[] {
  const grouped = Object.groupBy(requests, request => request.provider.organizationId)

  return Object.entries(grouped).map(([organizationId, groupedRequests]) => ({
    responder: groupedRequests?.[0].provider.organization as GenericOrganization,
    requests: groupedRequests || [],
  }))
}

const AccessorRequestsScene: React.FC<AccessorRequestsSceneProps> = ({
  embedded,
  tabs,
  actionButtons = [],
  relatedContentFilter = [],
  setCreateDialogOpen,
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const { openDrawer } = useDrawer()
  const [isRequestsReminderDialogOpen, openRequestsReminderDialog, closeRequestsReminderDialog] = useDialogState(false)
  const [selectedInquiriesIds, setSelectedInquiriesIds] = useState<Array<number>>([])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, inquiry: Inquiry) => {
    if (e.target.checked) {
      setSelectedInquiriesIds(prev => [...prev, inquiry.id])
    } else {
      setSelectedInquiriesIds(prev => prev.filter(inquiryId => inquiryId !== inquiry.id))
    }
  }

  const basePayload = {
    filter: relatedContentFilter,
    sort: sorting,
    include: [
      'creatorOrganization',
      'request.responses',
      'request.responses.verifications',
      'template',
      'template.image',
      'provider.organization.contacts.user',
      'provider.ownerUser',
    ],
  }

  const payload = {
    ...basePayload,
    filter: [...basePayload.filter, ...apiFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: pageInquiries,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.Request,
    endpoint: endpoints.inquiriesCollection,
    payload,
  })

  const {
    items: allInquiries,
    isLoading: isLoadingAllInquiries,
    refetch: fetchAllInquiries,
  } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.AllInquiries,
    endpoint: endpoints.inquiriesCollection,
    payload: {
      ...payload,
      pagination: {
        itemsPerPage: count,
        pageNumber: 1,
      },
    },
    options: {
      enabled: false,
    },
  })

  const inquiries = allInquiries.length ? allInquiries : pageInquiries

  const unhandledRequests = useMemo(
    () =>
      inquiries
        .filter(inquiry => selectedInquiriesIds.includes(inquiry.id))
        .filter(inquiry => inquiry.status !== InquiryStatus.Submitted && inquiry.status !== InquiryStatus.Approved)
        .filter(inquiry => !inquiry.provider.deletedAt),
    [selectedInquiriesIds, inquiries],
  )

  const unhandledRequestsByResponder = groupInquiriesByResponder(unhandledRequests)

  const isAllSelected = () => {
    return allInquiries.length ? allInquiries.every(inquiry => selectedInquiriesIds.includes(inquiry.id)) : false
  }

  const handleSelectAll = async () => {
    if (allInquiries.length) {
      setSelectedInquiriesIds(allInquiries.map(inquiry => inquiry.id) ?? [])
      return
    }

    const { data } = await fetchAllInquiries()
    setSelectedInquiriesIds(data?.items.map(inquiry => inquiry.id) ?? [])
  }

  const handleCloseRequestReminder = () => {
    setSelectedInquiriesIds([])
    closeRequestsReminderDialog()
  }

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.manageRequests' })}
        tabs={tabs}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Inquiry}
                  count={count}
                  userFilter={apiFilters}
                  exportColumns={InquiryExportColumnsAccessor}
                  rawExportPayload={{
                    ...basePayload,
                    include: [...basePayload.include, 'target', 'request.responses.history.creatorUser'],
                  }}
                />,
              ),
            disabled: tabs?.activeTabParam === ViewTypeName.Overview || isLoading || count === 0,
          },
          ...actionButtons,
        ]}
        filter={
          <RequestsFilters
            implicitFilters={relatedContentFilter}
            allowedFilters={allowedFilters}
            resetPage={resetPage}
          />
        }
        embedded={embedded}
        buttonRow={
          selectedInquiriesIds.length
            ? [
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.sendRequestReminder' },
                    { count: unhandledRequests.length },
                  ),
                  startIcon: <NotificationsActiveOutlined />,
                  onClick: openRequestsReminderDialog,
                  disabled: !unhandledRequests.length || isLoading || isFetching || isLoadingAllInquiries,
                },
                ...insertIf<ActionButton>(count > pageSize, {
                  label: formatMessage({ id: 'resourceCollections.general.selectAllRequests' }, { count }),
                  variant: 'text',
                  onClick: handleSelectAll,
                  disabled: isLoading || isFetching || isAllSelected(),
                  loading: isLoadingAllInquiries,
                }),
              ]
            : undefined
        }
      >
        <Table<Inquiry>
          RowComponent={({ row }) => (
            <AccessorInquiryRow
              row={row}
              onCheckboxChange={handleCheckboxChange}
              selectedInquiriesIds={selectedInquiriesIds}
            />
          )}
          HeaderComponent={() => (
            <AccessorInquiryHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              selectedInquiriesIds={selectedInquiriesIds}
              setSelectedInquiriesIds={setSelectedInquiriesIds}
              inquiriesInPage={pageInquiries}
            />
          )}
          data={pageInquiries}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          emptyState={
            <EmptyState
              iconComponent={ListAltOutlined}
              title={formatMessage({ id: 'table.manageRequests.requestsEmptyState.title' })}
              description={formatMessage({ id: 'table.manageRequests.requestsEmptyState.description' }, { br })}
              sx={{ my: 2 }}
            >
              {setCreateDialogOpen && (
                <Button
                  variant="outlined"
                  endIcon={<ArrowForwardOutlined fontSize="small" />}
                  onClick={setCreateDialogOpen}
                >
                  {formatMessage({ id: 'table.manageRequests.requestsEmptyState.action' })}
                </Button>
              )}
            </EmptyState>
          }
          stickyColumnIndex={2}
        />
      </ResourceCollectionScene>
      <CreationModalProgressContextProvider>
        <RequestsReminderModal
          onClose={handleCloseRequestReminder}
          open={isRequestsReminderDialogOpen}
          unhandledRequests={unhandledRequests}
          unhandledRequestsByResponder={unhandledRequestsByResponder}
          requestType={REQUEST_TYPES.Questionnaire}
        />
      </CreationModalProgressContextProvider>
    </>
  )
}

export default AccessorRequestsScene
