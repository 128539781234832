import React from 'react'

const SustainableApproachIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" fill="#131315">
        <path d="M22.688 9.697H21.61l-.706-1.451a.328.328 0 0 0-.295-.185h-2.85V1.312a.33.33 0 0 0-.4-.32L6.497 3.425a.328.328 0 0 0-.256.32V8.06H3.39a.328.328 0 0 0-.295.185l-.706 1.45H1.312a.328.328 0 0 0-.328.329v12.662a.328.328 0 0 0 .329.329h21.375a.328.328 0 0 0 .328-.328V10.024a.328.328 0 0 0-.328-.328Zm-2.285-.98.477.98h-3.12v-.98h2.643ZM6.897 4.007l10.206-2.285V22.36h-2.586v-5.068a.328.328 0 0 0-.328-.329H9.811a.328.328 0 0 0-.328.329v5.068H6.897V4.008Zm6.964 18.352h-3.722v-4.74h3.722v4.74ZM3.597 8.717H6.24v.98H3.12l.477-.98ZM1.64 10.353h4.6V22.36h-4.6V10.353ZM22.359 22.36h-4.6V10.353h4.6V22.36Z" />
        <path d="M9.2 13.201h1.612a.328.328 0 0 0 .328-.328v-1.612a.328.328 0 0 0-.328-.329H9.199a.328.328 0 0 0-.328.329v1.612a.328.328 0 0 0 .328.328Zm.327-1.612h.957v.956h-.957v-.956ZM13.188 13.201H14.8a.328.328 0 0 0 .328-.328v-1.612a.328.328 0 0 0-.328-.329h-1.613a.328.328 0 0 0-.328.329v1.612a.328.328 0 0 0 .329.328Zm.328-1.612h.956v.956h-.956v-.956ZM9.2 8.717h1.612a.328.328 0 0 0 .328-.328V6.777a.326.326 0 0 0-.328-.328H9.199a.328.328 0 0 0-.328.328v1.612a.328.328 0 0 0 .328.328Zm.327-1.612h.957v.956h-.957v-.956ZM13.188 8.717H14.8a.328.328 0 0 0 .328-.328V6.777a.326.326 0 0 0-.328-.328h-1.613a.328.328 0 0 0-.328.328v1.612a.328.328 0 0 0 .329.328Zm.328-1.612h.956v.956h-.956v-.956Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SustainableApproachIcon
