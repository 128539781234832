import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const ProviderInquiryHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.requestStatus' })} minWidth={TableCellWidth.EMPTY} />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.request' })}
        sorting={{ name: 'template.title', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.periodDisplayName' })}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.sender' })}
        sorting={{ name: 'creatorOrganization.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.deadline' })}
        sorting={{ name: 'deadline', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.request.transparencyActivatedAt' })}
        sorting={{ name: 'activatedAt', toggleSorting, activeSorting }}
      />
    </TableRow>
  )
}

export default ProviderInquiryHeader
