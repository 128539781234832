import NavbarMenuItem from '@app/src/components/Navbar/NavbarItems/NavbarMenuItem'
import paths from '@app/src/wf-constants/paths'
import { LocalLibrary, LocalLibraryOutlined } from '@mui/icons-material'
import React from 'react'
import { useIntl } from 'react-intl'

const SustainabilityProfileMenuItem: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <NavbarMenuItem
      path={paths.sustainabilityProfile}
      title={formatMessage({ id: 'navbar.sustainabilityProfile' })}
      icon={LocalLibraryOutlined}
      iconSelected={LocalLibrary}
    />
  )
}

export default SustainabilityProfileMenuItem
