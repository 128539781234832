import { Chip, ChipProps, Tooltip } from '@mui/material'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'
import ValueCell from './ValueCell'

export type ChipCellProps = {
  value?: string | number
  defaultValue?: string
  disableCell?: boolean
  tooltipText?: string
} & ChipProps

const ChipCell: React.FC<ChipCellProps> = ({ value, defaultValue, disableCell, tooltipText, ...props }) => {
  if (value && value === defaultValue) {
    return <ValueCell value={value} disableCell={disableCell} color="action.disabled" />
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip title={tooltipText}>
        <Chip label={value?.toString() ?? '-'} {...props} />
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default ChipCell
