import { HistoryToggleOff } from '@mui/icons-material'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import DateDisplay from '@app/src/components/DateDisplay'
import EmptyState from '@app/src/components/EmptyState'
import { useBetterQueryParams } from '@app/src/hooks/queryParams'
import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { Product, ProductMappingRequest, ProductMappingResponse } from '@app/src/types/product'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewReviewResponseValueChainToCopyToProduct from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewResponseValueChainToCopyToProduct'

interface Props {
  requests: ProductMappingRequest[]
  product: Product
}

const sortByCreatedAtDate = (
  a: ProductMappingRequest | ProductMappingResponse,
  b: ProductMappingRequest | ProductMappingResponse,
) => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
}

const isProductMappingResponse = (
  item: ProductMappingRequest | ProductMappingResponse,
): item is ProductMappingResponse => {
  return (item as ProductMappingResponse).submittedAt !== undefined
}

const MappingRequestHistoryLog: React.FC<Props> = ({ requests, product }) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { responseId: responseIdString } = useBetterQueryParams<{ responseId: string }>()
  const responseId = Number(responseIdString)
  const { openDrawer } = useDrawer()

  const sortedTimelineItems: Array<ProductMappingRequest | ProductMappingResponse> = useMemo(
    () =>
      requests
        .flatMap<ProductMappingRequest | ProductMappingResponse>(request => {
          const mappingResponsesWithProvider =
            request.productMappingResponses?.map((response, i) => ({
              ...response,
              creatorOrganization: request.responderOrganization,
              isUpdate: Boolean(i < request.productMappingResponses.length - 1),
            })) ?? []

          return [request, ...mappingResponsesWithProvider]
        })
        .sort(sortByCreatedAtDate),
    [requests],
  )

  const handleAddToValueChain = (productMappingResponse: ProductMappingResponse) => {
    openDrawer(
      <DrawerViewReviewResponseValueChainToCopyToProduct
        organization={productMappingResponse.creatorOrganization}
        mappingResponse={productMappingResponse}
        targetProduct={product}
      />,
    )
  }

  // Open drawer if responseId is in the query params
  useEffect(() => {
    if (sortedTimelineItems.length > 0 && responseId) {
      const responses = sortedTimelineItems.filter(item => isProductMappingResponse(item)) as ProductMappingResponse[]
      const foundResponse = responses.find(response => response.id === responseId)

      if (foundResponse) handleAddToValueChain(foundResponse)

      // Remove 'responseId' from the query params
      const searchParams = new URLSearchParams(history.location.search)
      searchParams.delete('responseId')

      history.replace({
        search: searchParams.toString(),
      })
    }
  }, [sortedTimelineItems, responseId])

  return (
    <>
      <Box>
        <Tabs value={0} centered variant="fullWidth">
          <Tab label={formatMessage({ id: 'schemas.mappingRequest.historyLog' })} />
        </Tabs>
        <Box></Box>
      </Box>
      {!requests?.length ? (
        <Box mt={4} mb={6}>
          <EmptyState
            iconComponent={HistoryToggleOff}
            title={formatMessage({ id: 'schemas.mappingRequest.noHistory.title' })}
            description={formatMessage({ id: 'schemas.mappingRequest.noHistory.description' })}
          />
        </Box>
      ) : (
        <>
          <Box>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {sortedTimelineItems.map((item, i) => (
                <TimelineItem key={item.id}>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" />
                    {i !== sortedTimelineItems?.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box
                      component="button"
                      sx={{
                        border: 'none',
                        background: 'transparent',
                        cursor: isProductMappingResponse(item) ? 'pointer' : 'inherit',
                        textAlign: 'left',
                        '&:hover': {
                          textDecoration: isProductMappingResponse(item) ? 'underline' : 'none',
                        },
                      }}
                      onClick={() => {
                        if (isProductMappingResponse(item)) handleAddToValueChain(item)
                      }}
                    >
                      {isProductMappingResponse(item) ? (
                        <>
                          <Typography>
                            {formatMessage(
                              {
                                id: `schemas.mappingRequest.historyLogStatus.${
                                  item.isUpdate ? 'updateReceived' : 'responseReceived'
                                }`,
                              },
                              { organization: item.creatorOrganization?.name },
                            )}
                          </Typography>
                          <DateDisplay value={item.submittedAt} withTime />
                        </>
                      ) : (
                        <>
                          <Typography>
                            {formatMessage(
                              { id: `schemas.mappingRequest.historyLogStatus.requestSent` },
                              { organization: item.responderOrganization?.name },
                            )}
                          </Typography>
                          <DateDisplay value={item.createdAt} withTime />
                        </>
                      )}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Box>
        </>
      )}
    </>
  )
}

export default MappingRequestHistoryLog
