import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import SimpleSelect from '@app/src/components/Form/Select/SimpleSelect'
import { LanguageInfo, useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { TranslateOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

const ReportTranslation = () => {
  const { isRequestLoading, setLocale, locale } = useReport()
  const [isOpen, setOpen] = useState<boolean>(false)
  const { formatMessage } = useIntl()
  const { data, isLoading } = useFetchResource<LanguageInfo[]>({
    endpoint: endpoints.availableLanguageTranslation,
    key: FetchKey.Languages,
    options: {
      enabled: isOpen,
    },
  })

  return (
    <Box minWidth={220}>
      <SimpleSelect<string>
        fullWidth
        InputProps={{
          startAdornment: <TranslateOutlined fontSize="small" color="action" sx={{ mr: 1 }} />,
        }}
        size="small"
        options={data?.map(l => ({ label: l.name, value: l.code })) ?? []}
        disabled={isRequestLoading}
        value={locale ? { value: locale.code, label: locale.name } : null}
        loading={isLoading}
        fieldLabel={formatMessage({ id: 'general.language' })}
        onChange={opt => {
          const option = [opt].flat()?.[0]
          setLocale(option ? { code: option.value, name: option.label } : null)
        }}
        open={isOpen}
        setOpen={setOpen}
      />
    </Box>
  )
}

export default ReportTranslation
