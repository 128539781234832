import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import DrawerViewAddProviderMenu from '@app/src/components/Drawer/Views/DrawerViewAddProviderMenu'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import ActorTypeField from '@app/src/pages/Product/ActorTypeField'
import ParentNodeField from '@app/src/pages/Product/ParentNodeField'
import ProviderField, { ProviderFieldValue } from '@app/src/pages/Product/ProviderField'
import { ActorTypeModel, MappingNode, NodeType, Product } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Box } from '@mui/material'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../../DrawerContext'
import DrawerView, { DrawerViewProps } from '../../DrawerView'

export interface MappingNodeFormValues {
  provider: ProviderFieldValue
  actorTypeId?: number
  name: string
  parentNodeId: number | null
  nodeType: NodeType
}

type DrawerViewAddMappingNodeProps = {
  mappingNode?: MappingNode
  product: Product
  selectableActorTypes: ActorTypeModel[]
} & Omit<DrawerViewProps, 'title'>

const DrawerViewAddMappingNode: React.FC<DrawerViewAddMappingNodeProps> = ({
  product,
  mappingNode,
  selectableActorTypes,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { openDrawer, closeDrawer } = useDrawer()
  const { mutate, isLoading: isCreatingMappingNode } = useCreateResource<MappingNode, Partial<MappingNode>>()

  const formMethods = useForm<MappingNodeFormValues>({
    mode: 'onChange',
    defaultValues: {},
  })

  const { register, handleSubmit, errors, control } = formMethods

  const onSubmit = (values: MappingNodeFormValues) => {
    const { provider, name, ...mappingNode } = values
    const body = {
      ...mappingNode,
      name: provider?.name ?? name,
      organizationId: provider?.organizationId,
      providerId: provider?.id,
      productId: product.id,
    }

    mutate(
      {
        url: endpoints.mappingNode,
        body,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([FetchKey.Product, product.id])
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfullyCreatedNode' }, { nodeName: body.name }),
            severity: NotificationSeverity.success,
          })
          closeDrawer()
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        title={formatMessage({ id: 'schemas.mappingNode.createNewValueChain' })}
        subTitle={formatMessage({ id: 'schemas.mappingNode.createNewValueChainDescription' })}
        buttons={[
          {
            loading: isCreatingMappingNode,
            label: formatMessage({ id: 'general.save' }),
            variant: 'contained',
            color: 'primary',
            type: 'submit',
          },
        ]}
        onFormSubmit={handleSubmit(onSubmit)}
        {...props}
      >
        <Box px={2} mt={2}>
          <input type="hidden" name="nodeType" ref={register()} value={NodeType.OrganizationNode} />

          <Box mt={2} data-testid="responsible-supplier">
            <Controller
              name="provider"
              defaultValue={null}
              control={control}
              render={({ onChange, value }) => (
                <ProviderField
                  onChange={onChange}
                  value={value}
                  disabled={Boolean(mappingNode)}
                  onAddProviderClick={() => openDrawer(<DrawerViewAddProviderMenu />)}
                />
              )}
              rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
              helperText={errors?.provider}
              error={Boolean(errors?.provider)}
            />
          </Box>

          <Box mt={2} data-testid="actor-type">
            <Controller
              name="actorTypeId"
              defaultValue={null}
              control={control}
              render={({ onChange, value }) => (
                <ActorTypeField onChange={onChange} value={value} actorTypes={selectableActorTypes} />
              )}
              rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
              helperText={errors?.actorTypeId?.message}
              error={Boolean(errors?.actorTypeId)}
            />
          </Box>

          <Box mt={2} data-testid="supplying-to">
            <Controller
              name="parentNodeId"
              defaultValue={null}
              control={control}
              rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
              render={({ onChange, value }) => (
                <ParentNodeField
                  onChange={onChange}
                  value={value}
                  mappingNodes={product.mappingNodes}
                  disabled={Boolean(mappingNode)}
                />
              )}
              helperText={errors?.parentNodeId?.message}
              error={Boolean(errors?.parentNodeId)}
            />
          </Box>
        </Box>
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewAddMappingNode
