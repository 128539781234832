import { Components, alpha } from '@mui/material'
import { palette } from '@app/src/theme/palette'

const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      color: '#000000',
      borderRadius: 100,

      '&.Mui-focusVisible': {
        border: '2px solid #FFFFFF',
        outline: '2px solid #000000',
      },
    },
    sizeSmall: {
      fontSize: '1.625rem',
    },
    sizeMedium: {
      fontSize: '1.75rem',
    },
    sizeLarge: {
      fontSize: '1.875rem',
    },

    //CONTAINED
    containedPrimary: {
      background: palette.primary?.main ?? '#000',
      color: '#FFFFFF',

      '&:hover': {
        background: palette.primary.light,
      },
    },
    containedSizeLarge: {
      padding: '8px 24px',
      '&.Mui-focusVisible': {
        padding: '6px 22px',
      },
    },
    containedSizeMedium: {
      padding: '6px 16px',
      '&.Mui-focusVisible': {
        padding: '4px 14px',
      },
    },
    containedSizeSmall: {
      padding: '4px 12px',
      '&.Mui-focusVisible': {
        padding: '2px 10px',
      },
    },

    //OUTLINED
    outlinedPrimary: {
      '&.Mui-focusVisible': {
        backgroundColor: alpha(palette.primary.main, 0.12),
      },
    },
    outlinedError: {
      '&.Mui-focusVisible': {
        backgroundColor: alpha(palette.error.main, 0.3),
      },
    },
    outlinedSizeLarge: {
      padding: '7px 24px',
      '&.Mui-focusVisible': {
        padding: '6px 23px',
      },
    },
    outlinedSizeMedium: {
      padding: '5px 14px',
      '&.Mui-focusVisible': {
        padding: '4px 13px',
      },
    },
    outlinedSizeSmall: {
      padding: '3px 10px',
      '&.Mui-focusVisible': {
        padding: '2px 9px',
      },
    },

    //TEXT
    textPrimary: {
      '&.Mui-focusVisible': {
        backgroundColor: alpha(palette.primary.main, 0.12), //primary.main
      },
    },
    textError: {
      '&.Mui-focusVisible': {
        backgroundColor: alpha(palette.error.main, 0.3),
      },
    },
    textSizeLarge: {
      padding: '8px 11px',
      '&.Mui-focusVisible': {
        padding: '6px 9px',
      },
    },
    textSizeMedium: {
      padding: '6px 8px',
      '&.Mui-focusVisible': {
        padding: '4px 6px',
      },
    },
    textSizeSmall: {
      padding: '4px 5px',
      '&.Mui-focusVisible': {
        padding: '2px 3px',
      },
    },
  },
}

export default MuiButton
