import { convertValueToUnit } from '@app/src/hooks/useDisplayValueWithUnit'
import { SelectedMetric } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/NumberMetricSelector'
import { palette } from '@app/src/theme/palette'
import { Unit } from '@app/src/types/resourceExplorer'
import { formatNumber } from '@app/src/utils/helpersTs'
import { Palette } from '@mui/material/styles'
import { max, upperFirst } from 'lodash'

export const numberToString = (value: number, originalUnit?: Unit, newUnit?: Unit) => {
  const valueWithNewValue = convertValueToUnit(value, originalUnit, newUnit)
  return formatNumber(valueWithNewValue) ?? ''
}

export const numberToStringWithUnit = (value: number, originalUnit?: Unit, newUnit?: Unit) => {
  const numberAsString = numberToString(value, originalUnit, newUnit)
  if (!newUnit) return numberAsString
  return `${numberAsString} ${newUnit.symbol}`
}

export const getUnitDisplayText = (optimalUnit: Unit | undefined, fallbackText: string) => {
  if (!optimalUnit || optimalUnit.name.toLowerCase() === 'numbers') return fallbackText
  return upperFirst(optimalUnit.name)
}

export const shouldShowSum = (unit: Unit | undefined) =>
  unit?.quantityType?.toLowerCase() !== 'fraction' && unit?.name?.toLowerCase() !== 'percentage'

const roundTo2SignificantFigures = (value: number) => parseFloat(value.toPrecision(2))

export const getMinMaxOnYAxisNumber = (values: number[], selectedMetric: SelectedMetric, unit?: Unit) => {
  if (unit?.name?.toLowerCase() === 'percentage' && values.every(v => v >= 0 && v <= 100)) return { min: 0, max: 100 }

  const isSinglePositiveValue = values.length === 1 && values[0] > 0
  if (!isSinglePositiveValue) return { min: undefined, max: undefined }
  const value = values[0]
  const partOfContainerThatShouldBeFilled = selectedMetric === SelectedMetric.Average ? 0.4 : 0.6
  const maxValue = value / partOfContainerThatShouldBeFilled
  return { min: 0, max: roundTo2SignificantFigures(maxValue) }
}

export const getMinMaxOnYAxisCountCharts = (values: number[]) => {
  const maximumValue = max(values) ?? 0
  if (maximumValue < 4) {
    return { min: 0, max: maximumValue + 2 }
  }
  return { min: undefined, max: undefined }
}

export const getVisualizationColorsMaxContrast = (numberOfColors: number) => {
  if (numberOfColors === 1) return [palette.visualization[5]]

  const step = 10 / (numberOfColors - 1)
  return Array.from({ length: numberOfColors }, (_, i) => {
    const index = Math.round(1 + i * step) as keyof Palette['visualization']
    return palette.visualization[index]
  })
}
