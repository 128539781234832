import { useAccount } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import { useIntl } from 'react-intl'
import { NotificationSeverity, WF_EMAIL_DOMAIN } from '@app/src/wf-constants'

export type ErrorNotificationProps = {
  requestError: RequestError | null
  specificFallbackErrorMessage?: string
  disableAutoClose?: boolean
}

const useErrorNotification = (): {
  showErrorNotification: (props: ErrorNotificationProps) => void
} => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const defaultErrorMessage = formatMessage({ id: 'notifications.error' })
  const { account } = useAccount()

  const getFallbackErrorMessage = (requestError: RequestError | null, specificFallbackErrorMessage?: string) => {
    const shouldShowErrorCode = account?.user?.email?.endsWith(WF_EMAIL_DOMAIN) ?? true

    const fallbackErrorMessage = specificFallbackErrorMessage ?? defaultErrorMessage
    const sentryId = requestError?.body?.sentryId
    if (!sentryId || !shouldShowErrorCode) return fallbackErrorMessage
    return `${fallbackErrorMessage} Error code: ${sentryId}`
  }

  const getErrorMessage = (requestError: RequestError | null, specificFallbackErrorMessage?: string) => {
    const fallbackErrorMessage = getFallbackErrorMessage(requestError, specificFallbackErrorMessage)

    const errorCode = requestError?.body?.errorCode
    if (!errorCode) return fallbackErrorMessage

    const stringId = `errorMessages.${errorCode}`
    const errorMessage = formatMessage({ id: stringId })
    const errorMessageNotFound = errorMessage === stringId
    if (errorMessageNotFound) return fallbackErrorMessage
    return errorMessage
  }

  const showErrorNotification = ({
    requestError,
    disableAutoClose,
    specificFallbackErrorMessage,
  }: ErrorNotificationProps): void => {
    const errorMessage = getErrorMessage(requestError, specificFallbackErrorMessage)
    showSnackbar({
      message: errorMessage,
      severity: NotificationSeverity.error,
      disableAutoClose,
    })
  }

  return { showErrorNotification }
}

export default useErrorNotification
