import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewInvitationForm from '@app/src/components/Drawer/Views/DrawerViewInvitationForm'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import ColleaguesTable from '@app/src/pages/Configurations/ConfigurationsPages/Colleague/ColleagueTable'
import InvitationsTable from '@app/src/pages/Configurations/ConfigurationsPages/Colleague/Invitation/InvitationsTable'
import { ConfigurationsParams } from '@app/src/pages/Configurations/ConfigurationsScene'
import { ResourceTypes } from '@app/src/wf-constants'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useLocation, useParams, useRouteMatch } from 'react-router'
import { Route, Switch } from 'react-router-dom'

export enum ColleaguesConfigTabs {
  Connected = 'connected',
  Invited = 'invited',
}

const ColleaguesConfig: React.FC = () => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { path } = useRouteMatch()
  const { configurationsPage } = useParams<ConfigurationsParams>()
  const location = useLocation<{ openCreateDialog: boolean }>()

  useEffect(() => {
    if (location.state?.openCreateDialog) {
      openDrawer(<DrawerViewInvitationForm />)
    }
  }, [])

  return (
    <ConfigurationCollection
      actionButtons={[
        {
          label: formatMessage({ id: `resourceCollections.create.${ResourceTypes.Colleague}` }),
          variant: 'contained',
          onClick: () => openDrawer(<DrawerViewInvitationForm />),
        },
      ]}
      actionTabs={[
        {
          type: ColleaguesConfigTabs.Connected,
          url: ColleaguesConfigTabs.Connected,
          label: formatMessage({ id: 'schemas.colleagues.connected' }),
        },
        {
          type: ColleaguesConfigTabs.Invited,
          url: ColleaguesConfigTabs.Invited,
          label: formatMessage({ id: 'schemas.colleagues.invited' }),
        },
      ]}
      enablePadding={{ top: false, bottom: false }}
    >
      <Switch>
        <Route
          path={generatePath(path, {
            configurationsPage,
            configurationsSubPage: ColleaguesConfigTabs.Connected,
          })}
        >
          <ColleaguesTable />
        </Route>

        <Route
          path={generatePath(path, {
            configurationsPage,
            configurationsSubPage: ColleaguesConfigTabs.Invited,
          })}
        >
          <InvitationsTable />
        </Route>
      </Switch>
    </ConfigurationCollection>
  )
}

export default ColleaguesConfig
