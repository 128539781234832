import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { alpha, Checkbox, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  ProviderUpdateType,
  SetProvidersUpdate,
} from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import React, { Dispatch, SetStateAction } from 'react'
import { useIntl } from 'react-intl'
import { ProviderStandardCategoryNames, StandardCategory } from '@app/src/types/categories'
import { capitalize } from '@app/src/utils/helpers'
import { ResourceTypes } from '@app/src/wf-constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  iconSelected: {
    color: palette.primary.main,
  },
  icon: {
    color: alpha(palette.primary.main, 0.54),
  },
  row: {
    '&:hover': {
      backgroundColor: alpha(palette.primary.main, 0.05),
    },
  },
  paddingLeft: {
    paddingLeft: spacing(1),
  },
  checkBox: {
    marginRight: 8,
  },
}))

interface CategoryOptionListItemProps {
  option: string | number
  providersUpdate?: ProviderUpdateType[]
  setProvidersUpdate?: SetProvidersUpdate
  setIsUnsavedChanges: Dispatch<SetStateAction<boolean>>
  category: StandardCategory
  renderPrimaryText?: (text: string, option: string | number) => string | JSX.Element
}

const StandardCategoryListItem = ({
  option,
  providersUpdate,
  category,
  setIsUnsavedChanges,
  setProvidersUpdate,
  renderPrimaryText: renderStylingForItemPrimaryText,
}: CategoryOptionListItemProps): JSX.Element => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const categoryName = `${category?.name}` as keyof ProviderUpdateType
  const appliedToAllProviders =
    providersUpdate?.every(p => p[categoryName] === option || (p[categoryName] === undefined && option === 0)) ?? false

  const appliedToNProviders =
    providersUpdate?.filter(p => p[categoryName] === option || (p[categoryName] === undefined && option === 0))
      .length ?? 0
  const appliedToSomeProviders = appliedToNProviders > 0 && !appliedToAllProviders

  const getTranslatedCategoryOption = () => {
    if (category.name === ProviderStandardCategoryNames.OwnerUserId) {
      return ''
    }
    return typeof option === 'number'
      ? formatMessage({ id: `schemas.provider.${category?.name}Values` }, { tier: option })
      : formatMessage({ id: `schemas.provider.${category?.name}Values.${option}` })
  }

  const translatedCategoryOption = getTranslatedCategoryOption()
  const primaryText = renderStylingForItemPrimaryText
    ? renderStylingForItemPrimaryText(translatedCategoryOption, option)
    : translatedCategoryOption
  const handleOnClick = () => {
    if (!setProvidersUpdate) return
    setIsUnsavedChanges(true)
    setProvidersUpdate(category.name, option)
  }

  return (
    <ListItem dense key={option.toString()} disableGutters className={classes.row} onClick={handleOnClick} button>
      <ListItemIcon>
        <Checkbox
          icon={<RadioButtonUncheckedIcon className={classes.icon} />}
          checkedIcon={<RadioButtonCheckedIcon className={classes.iconSelected} />}
          className={classes.checkBox}
          checked={appliedToAllProviders}
        />
      </ListItemIcon>
      <ListItemText
        className={classes.paddingLeft}
        primary={primaryText}
        secondary={
          appliedToSomeProviders &&
          formatMessage(
            { id: 'category.categoryOptions.appliedToSome' },
            {
              provider: formatMessage(
                { id: `general.${capitalize(ResourceTypes.Company)}` },
                { count: appliedToNProviders },
              ).toLowerCase(),
              amount: appliedToNProviders,
            },
          )
        }
      />
    </ListItem>
  )
}

export default StandardCategoryListItem
