import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewPriorty from '@app/src/components/Drawer/Views/DrawerViewPriorty'
import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import PriorityCell from '@app/src/components/Table/Cells/PriorityCell'
import SimpleRiskChipCell from '@app/src/components/Table/Cells/SimpleRiskChipCell'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Provider, RiskTableView } from '@app/src/types/organizations'
import { Risk, RiskType } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import RiskScreeningInfoDialog, { RiskScreeningInfoDialogProps } from './RiskScreeningInfoDialog'

interface RiskScreeningHqRowProps {
  row: RiskTableView
  enabledRiskTypes: RiskType[]
  activeFilters: FilterGroup[]
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => void
  selectedProviders: Array<number>
}

const getRisk = (row: RiskTableView, riskType: RiskType): Risk | undefined => {
  return row.risks.find(r => r.headquarter?.riskType.id === riskType.id)?.headquarter
}

const RiskScreeningHqRow: React.FC<RiskScreeningHqRowProps> = ({
  row,
  enabledRiskTypes,
  onCheckboxChange,
  selectedProviders,
  activeFilters,
}) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const [riskDialogContent, setRiskDialogContent] = useState<RiskScreeningInfoDialogProps['riskDialogContent']>()

  const riskFilters = activeFilters.find(f => f.name === 'riskStatus')?.filters.flatMap(f => f.value)
  const isChecked = Boolean(selectedProviders?.some(selectedProviderId => selectedProviderId === row.parentObject.id))

  return (
    <>
      <TableRow>
        <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, row.parentObject)} />
        <CompanyCell company={row.parentObject} drawer />
        <CountryCell countryCode={row.parentObject.country?.countryCode} />
        {enabledRiskTypes.map((riskType, index) => {
          const risk = getRisk(row, riskType)

          return (
            <SimpleRiskChipCell
              key={index}
              riskStatus={risk?.status}
              disabled={!riskFilters?.includes(risk?.status)}
              onClick={() => {
                setRiskDialogContent({
                  providerName: row.parentObject.name,
                  countryName: row.parentObject.country.name,
                  risk,
                  location: formatMessage({ id: 'riskScreening.subLabels.headquarter' }),
                })
              }}
            />
          )
        })}
        <PriorityCell
          value={row.parentObject.priority}
          onClick={() => openDrawer(<DrawerViewPriorty provider={row.parentObject} />)}
          size="small"
        />
      </TableRow>

      <RiskScreeningInfoDialog riskDialogContent={riskDialogContent} setRiskDialogContent={setRiskDialogContent} />
    </>
  )
}

export default RiskScreeningHqRow
