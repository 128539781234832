import { Chip } from '@mui/material'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'
import ValueCell, { ValueCellProps } from './ValueCell'

export interface CategoryCellProps {
  value?: ValueCellProps['value']
  defaultValue?: string
  disableCell?: boolean
}

// This component is supposed to be used when displaying a category field.
// It will render the value in a chip if the provided value is different than the default
const CategoryCell = ({ value, defaultValue, disableCell }: CategoryCellProps): JSX.Element => {
  if (value === defaultValue) {
    return <ValueCell value={value} disableCell={disableCell} color="action.disabled" />
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Chip variant="filled" size="small" label={value} />
    </ParentCellOrFragment>
  )
}

export default CategoryCell
