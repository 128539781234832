import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource, useDeleteResource, useUpdateResource } from '@app/src/api/updateHooks'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Assertion } from '@app/src/types/reporting'
import { Response, ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { FormData } from '../Provider/ProviderReport'
import { useReport } from '../ReportContext'

type UseProviderQuestionnaire = {
  createDraft: () => Promise<void>
  updateDraft: (values: FormData, autoSave?: boolean) => Promise<void>
  deleteDraft: () => Promise<void>
  reuseResponse: (responseId?: number) => Promise<void>
  submitResponse: (values: FormData, assertions: Assertion[], onSuccessCallback: () => void) => Promise<void>
  isLoadingCreateDraft: boolean
  isLoadingDeleteDraft: boolean
  isLoadingReuseResponse: boolean
  isLoadingUpdateSaveDraft: boolean
  isLoadingSubmitDraft: boolean
}

const useProviderQuestionnaire = (): UseProviderQuestionnaire => {
  const { request, response, reportStatus, updateResponseIdParam, locale } = useReport()
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { trackEvent } = useAmplitude()

  const trackResponseAction = (name: string) => {
    trackEvent({
      name,
      eventProps: {
        response_id: response?.id,
        request_id: request?.id,
      },
    })
  }

  const { mutateAsync: mutateCreateDraft, isLoading: isLoadingCreateDraft } = useCreateResource<Response, {}>()
  const { mutateAsync: mutateUpdateDraft, isLoading: isLoadingUpdateSaveDraft } = useUpdateResource<Response, {}>()
  const { mutateAsync: mutateSubmitDraft, isLoading: isLoadingSubmitDraft } = useCreateResource<Response, {}>()
  const { mutateAsync: mutateDeleteDraft, isLoading: isLoadingDeleteDraft } = useDeleteResource<Response, {}>()
  const { mutateAsync: mutateReuseResponse, isLoading: isLoadingReuseResponse } = useUpdateResource<Response, {}>()

  const createDraft: UseProviderQuestionnaire['createDraft'] = async () => {
    if (reportStatus === ResponseDraftStatus.Draft || isLoadingCreateDraft) {
      return
    }

    await mutateCreateDraft(
      { url: endpoints.createResponseDraft, body: { requestId: request?.id } },
      {
        onSuccess: data => {
          updateResponseIdParam(data.id)
          queryClient.invalidateQueries(FetchKey.RequestHistory)
          queryClient.invalidateQueries([FetchKey.Response, Number(response?.id), locale?.code])
          trackResponseAction(AmplitudeTrackingEvents.Provider.Questionnaire.ResponseStarted)
        },
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  const updateDraft: UseProviderQuestionnaire['updateDraft'] = async (values, autoSave) => {
    const responseItems = Object.entries(values).map(([key, value]) => ({
      id: value.id,
      requestItemId: key,
      answer: value.answer ?? '',
      selectedOptions: [value.selectedOptions ?? []].flat(),
      estimatedAnswerAt: value.estimatedAnswerAt,
      cannotAnswer: value.cannotAnswer,
      expiresAt: value.expiresAt,
      answerStatus: value.answerStatus,
      comment: value.comment,
    }))

    const body = {
      id: response?.id,
      requestId: request?.id,
      items: responseItems,
    }

    await mutateUpdateDraft(
      { url: endpoints.updateResponseDraft, body },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({
              id: autoSave ? 'notifications.successfulResponseAutoSave' : 'notifications.successfulDraftSave',
            }),
            severity: 'success',
          })
          queryClient.invalidateQueries(FetchKey.RequestHistory)
          queryClient.invalidateQueries([FetchKey.Response, Number(response?.id), locale?.code])
        },
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  const submitResponse: UseProviderQuestionnaire['submitResponse'] = async (values, assertions, onSuccessCallback) => {
    const responseItems = Object.entries(values).map(([key, value]) => ({
      id: value.id,
      requestItemId: key,
      answer: value.answer ?? '',
      selectedOptions: [value.selectedOptions ?? []].flat(),
      estimatedAnswerAt: value.estimatedAnswerAt,
      cannotAnswer: Boolean(value.cannotAnswer),
      expiresAt: value.expiresAt,
      answerStatus: value.answerStatus,
      comment: value.comment,
    }))

    const body = {
      id: response?.id,
      requestId: request?.id,
      items: responseItems,
      responseStatus: ResponseDraftStatus.Submitted,
      assertions,
    }

    await mutateSubmitDraft(
      { url: endpoints.submitResponseDraft, body },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([FetchKey.Response, Number(response?.id)])
          queryClient.invalidateQueries([FetchKey.RequestHistory, Number(request?.id)])
          queryClient.invalidateQueries(FetchKey.ResponseSharing)
          queryClient.invalidateQueries(FetchKey.RequestPerPeriod)
          queryClient.invalidateQueries(FetchKey.AccessorOrganization)
          queryClient.invalidateQueries(FetchKey.InquiryOpenRequests)
          queryClient.invalidateQueries(FetchKey.Request)

          onSuccessCallback()

          trackResponseAction(AmplitudeTrackingEvents.Provider.Questionnaire.ResponseSubmitted)
        },
        onError: error => {
          if (error.isValidationError) {
            showSnackbar({ message: formatMessage({ id: 'notifications.submitValidationError' }), severity: 'warning' })
            return
          }
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const deleteDraft: UseProviderQuestionnaire['deleteDraft'] = async () => {
    await mutateDeleteDraft(
      {
        url: endpoints.discardResponse(response?.id),
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([FetchKey.RequestHistory, Number(request?.id)])
          await queryClient.invalidateQueries(FetchKey.RequestPerPeriod)

          updateResponseIdParam(undefined)

          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResponseDiscard' }),
            severity: NotificationSeverity.success,
          })

          trackResponseAction(AmplitudeTrackingEvents.Provider.Questionnaire.ResponseDiscarded)
        },
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  const reuseResponse: UseProviderQuestionnaire['reuseResponse'] = async (responseId = response?.id) => {
    await mutateReuseResponse(
      { url: endpoints.reuseResponse(request?.id, responseId), body: undefined },
      {
        onSuccess: data => {
          updateResponseIdParam(data.id)
          queryClient.invalidateQueries([FetchKey.RequestHistory, Number(request?.id)])
        },
        onError: error => showErrorNotification({ requestError: error }),
      },
    )
  }

  return {
    createDraft,
    updateDraft,
    deleteDraft,
    reuseResponse,
    submitResponse,
    isLoadingCreateDraft,
    isLoadingDeleteDraft,
    isLoadingReuseResponse,
    isLoadingUpdateSaveDraft,
    isLoadingSubmitDraft,
  }
}

export default useProviderQuestionnaire
