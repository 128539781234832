import { toSnakeCase } from '@app/src/utils/helpers'
import { KeyboardArrowDown } from '@mui/icons-material'
import { alpha, Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Permissions, { usePermissions, UsePermissionsType } from '@app/src/auth/permissions'
import classNames from 'classnames'
import LinkButton from '@app/src/components/LinkButton'
import { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems/index'
import React, { useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const useStyles = makeStyles(({ palette, spacing, wfShadows }) => ({
  navbarButtonWrapper: {
    height: '100%',
    borderBottom: `4px solid transparent`,
  },
  navbarButton: {
    height: spacing(7),
    borderRadius: 0,
    paddingLeft: spacing(2),
    paddingRight: spacing(1.5),

    '&:hover': {
      background: alpha('#000', 0.05),
      textDecoration: 'none',
    },
  },
  navbarButtonInactive: {
    color: palette.text.secondary,
  },
  active: {
    borderBottom: `2px solid ${palette.primary.main}`,
  },
  menuPaper: {
    minWidth: 250,
    borderRadius: 4,
    boxShadow: wfShadows[5],
    marginTop: spacing(0.5),
  },
}))

type MenuLinkItem = {
  link: string
  permission?: Permissions | Permissions[]
}

type MenuItems = {
  title: string
  link: string
  permission?: Permissions | Permissions[]
}

export type MenuLinkWrapperProps = {
  title: string
  menuItems: MenuLinkItem
  external?: boolean
  closeMenuDrawer?: NavbarItemProps['closeMenuDrawer']
}

export type MenuWrapperProps = {
  title: string
  menuItems: MenuItems[]
  closeMenuDrawer?: NavbarItemProps['closeMenuDrawer']
}

const isActive = (menuItems: MenuLinkItem | MenuItems[], pathname: string) => {
  return [menuItems].flat().find(item => pathname.includes(item.link))
}

const hasPermissionToShow = (
  menuItems: MenuLinkItem | MenuItems[],
  hasPermission: UsePermissionsType['hasPermission'],
) => {
  const permissions = [menuItems].flat().filter(item => item.permission)

  if (!permissions.length) {
    return true
  }

  return permissions.some(item => {
    const permissions = [item.permission].flat()
    return permissions.length ? permissions.some(permission => hasPermission(permission as Permissions)) : true
  })
}

export const MenuLinkWrapper = ({
  title,
  menuItems,
  external,
  closeMenuDrawer,
}: MenuLinkWrapperProps): JSX.Element | null => {
  const { hasPermission } = usePermissions()
  const { pathname } = useLocation()
  const classes = useStyles()

  const menuItem = [menuItems].flat()[0]

  if (!hasPermissionToShow(menuItem, hasPermission)) {
    return null
  }

  const active = isActive(menuItem, pathname)

  return (
    <Box
      className={classNames(classes.navbarButtonWrapper, {
        [classes.active]: active,
      })}
    >
      <LinkButton
        fullWidth
        target={external ? '_blank' : undefined}
        to={menuItem.link}
        onClick={closeMenuDrawer}
        className={classNames(classes.navbarButton, { [classes.navbarButtonInactive]: !active })}
        size="large"
        sx={{ '&.MuiButtonBase-root': { pr: 2 } }}
      >
        <Typography variant="subtitle1" color="text.primary">
          {title}
        </Typography>
      </LinkButton>
    </Box>
  )
}

const MenuWrapper = ({ title, menuItems, closeMenuDrawer }: MenuWrapperProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(false)
  const { hasPermission } = usePermissions()
  const { pathname } = useLocation()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const classes = useStyles()

  const handleOpen = (): void => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>): void => {
    if (anchorRef.current && anchorRef.current.contains(event.currentTarget)) {
      return
    }
    setOpen(false)
  }

  if (!hasPermissionToShow(menuItems, hasPermission)) {
    return null
  }

  const active = isActive(menuItems, pathname)

  return (
    <Box
      className={classNames(classes.navbarButtonWrapper, {
        [classes.active]: active,
      })}
    >
      <Button
        fullWidth
        data-cy="menu-btn"
        onClick={handleOpen}
        ref={anchorRef}
        endIcon={<KeyboardArrowDown />}
        className={classNames(classes.navbarButton, { [classes.navbarButtonInactive]: !active })}
        size="large"
      >
        <Typography variant="subtitle1" color="text.primary">
          {title}
        </Typography>
      </Button>

      <Menu
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        elevation={1}
        onClose={handleClose}
        classes={{ paper: classes.menuPaper }}
      >
        {[menuItems].flat().map(item => (
          <MenuItem
            to={item.link}
            key={item.link}
            component={Link}
            onClick={() => {
              handleOpen()
              closeMenuDrawer?.()
            }}
            data-testid={`menu-item-${toSnakeCase(item.title)}`}
            sx={theme => ({
              borderLeft: item.link === pathname ? `3px solid ${theme.palette.primary.main}` : 'none',
            })}
          >
            <Box display="flex" alignItems="center" py={1}>
              <Typography variant="subtitle1" color="text.primary">
                {item.title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default MenuWrapper
