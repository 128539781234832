import InfoIcon from '@mui/icons-material/Info'
import { Box, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface LatestAnswersOnlyProps {
  onlyLatestAnswers: boolean
  setOnlyLatestAnswers: React.Dispatch<React.SetStateAction<boolean>>
}

export const LatestAnswersOnly: React.FC<LatestAnswersOnlyProps> = ({ onlyLatestAnswers, setOnlyLatestAnswers }) => {
  const { formatMessage } = useIntl()
  return (
    <Box display="flex" width="100%" justifyContent="flex-end">
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={onlyLatestAnswers}
            onChange={(): void => setOnlyLatestAnswers(latestAnswers => !latestAnswers)}
          />
        }
        labelPlacement="end"
        label={
          <Box component="span" display="flex" alignItems="center">
            <Typography variant="body1">
              {formatMessage({
                id: 'filters.latestOnly',
              })}
            </Typography>
            <Tooltip
              title={formatMessage({
                id: 'filters.latestOnlyInfo',
              })}
            >
              <Box ml={1} display="flex" alignItems="center">
                <InfoIcon fontSize="small" />
              </Box>
            </Tooltip>
          </Box>
        }
      />
    </Box>
  )
}
