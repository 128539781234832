import { Box, Typography } from '@mui/material'
import SimpleSelect, { Option } from '@app/src/components/Form/Select/SimpleSelect'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ActorType, MappingNode } from '@app/src/types/product'

interface Props {
  onChange: (parentNodeId: number) => void
  value: number | null
  mappingNodes: MappingNode[]
  disabled?: boolean
}

const ParentNodeField: React.FC<Props> = ({ onChange, value, mappingNodes, disabled }) => {
  const [isOpen, setOpen] = React.useState(false)
  const { formatMessage } = useIntl()
  const { errors } = useFormContext()

  const options: Option<MappingNode>[] =
    mappingNodes?.map(node => ({
      label: node.name,
      value: node,
    })) ?? []

  return (
    <>
      <SimpleSelect<MappingNode>
        disabled={disabled}
        multiple={false}
        onChange={optionSelected => onChange((optionSelected as Option<MappingNode>)?.value.id ?? null)}
        open={isOpen}
        setOpen={setOpen}
        loading={false}
        options={options}
        fieldLabel={formatMessage({ id: 'schemas.valueChain.supplyingTo' })}
        value={options.find(opt => opt.value.id === value) ?? null}
        error={errors?.parentNodeId}
        renderOption={(props, option) => (
          <li {...props} key={option.value.id}>
            <Box display="flex" flexDirection="column" padding={1}>
              <Typography variant="body1">{option.label}</Typography>
              <Typography variant="body1">
                {option.value.actorTypeModel
                  ? formatMessage({
                      id: `schemas.mappingNode.actorTypeValues.${
                        option.value.actorTypeModel?.name ?? ActorType.NotSet
                      }`,
                    })
                  : '-'}
              </Typography>
            </Box>
          </li>
        )}
      />
    </>
  )
}

export default ParentNodeField
