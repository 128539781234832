import { useBetterQueryParams } from '@app/src/hooks/queryParams'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import qs from 'qs'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { setUniqueFilters, useGetQueryFilters } from './queryFilters'

export interface StringifyFilterValue {
  name: string
  [key: string]: QueryParamType
  operator: Operators
  uniqueId?: string
}
export type QueryParamType = undefined | string | number | boolean | { [key: string]: unknown } | Array<QueryParamType>
export type StringifyQueryParamsType = {
  url?: string
  queryParams: {
    [key: string]: Array<StringifyFilterValue>
  }
  includeCurrentFilters?: boolean
}

export function useQueryState<T extends QueryParamType = QueryParamType>(
  queryParamName: string,
  defaultValue?: QueryParamType,
): [T, (value: QueryParamType, replace?: boolean) => void] {
  const history = useHistory()
  const params = useBetterQueryParams()

  const setQuery = useCallback(
    (value: QueryParamType, replace?: boolean) => {
      const queryString = qs.stringify({ ...params, [queryParamName]: value }, { encodeValuesOnly: true })

      if (replace) {
        history.replace(`${window.location.pathname}?${queryString}`)
      } else {
        history.push(`${window.location.pathname}?${queryString}`)
      }
    },
    [history, params, queryParamName],
  )

  return [(params[queryParamName] ?? defaultValue) as T, setQuery]
}

export const useStringifyQueryFilters = (): { stringifyQueryFilters: (params: StringifyQueryParamsType) => string } => {
  const { getQueryFilters } = useGetQueryFilters()

  const stringifyQueryFilters = ({ url, queryParams, includeCurrentFilters }: StringifyQueryParamsType) => {
    let filters = queryParams['filters'] ?? []
    if (includeCurrentFilters) {
      const currentFilters = getQueryFilters()

      filters = filters.reduce((prev, curr) => {
        return setUniqueFilters(prev, curr.name, curr.value as string | string[], curr.operator, curr.uniqueId)
      }, currentFilters)
    }

    const queryString = qs.stringify({ filters }, { encodeValuesOnly: true })
    return `${url ?? origin}?${queryString}`
  }

  return {
    stringifyQueryFilters,
  }
}
