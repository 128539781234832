import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import DrawerViewRiskAssessment from '@app/src/components/Drawer/Views/DrawerViewRiskAssessment'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import EmptyState from '@app/src/components/EmptyState'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import { StandardAssessmentsExportColumnsAccessor } from '@app/src/export-columns/standardAssessments'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import Assessment from '@app/src/types/assessment'
import { SortOrder } from '@app/src/types/filter'
import { Provider } from '@app/src/types/organizations'
import { br } from '@app/src/utils/translationMarkup'
import { ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import ChatIcon from '@mui/icons-material/Chat'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import AssessmentCompareHeader from '../ResourceCollection/Collections/Assessments/AssessmentCompareHeader'
import AssessmentCompareRow from '../ResourceCollection/Collections/Assessments/AssessmentCompareRow'
import AssessmentFilters from '../ResourceCollection/Filters/AssessmentFilters'
import { Operators } from '../ResourceCollection/Filters/useFilters'
import { useOrganizedAssessmentData } from './useOrganizedAssessmentData'

type AssessmentCompareSceneProps = Pick<ResourceCollectionSceneProps, 'actionButtons' | 'tabs'>

const allowedFilters = [
  'assessments.id',
  'assessments.periodName',
  'assessments.totalLevel',
  'assessments.percentage',
  'assessments.assessmentTemplate.name',
  'assessments.assessmentTemplate.id',
  'id',
  'name',
  'provider.country.id',
  'provider.createdAt',
  'provider.ownerUserId',
  'provider.websiteAddress',
  'provider.vatNumber',
  'provider.registrationNumber',
  'provider.linkStatus',
  'categoryOptions.id',
  'provider.activityStatus',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'organization.id',
  'provider.mappingNodes.actorTypeModel.id',
  'provider.mappingNodes.tier',
  'assessmentTemplate.id',
  'periodName',
  'provider.id',
  'provider.categoryOptions.id',
  'totalLevel',
  'percentage',
  'assessmentTemplate.name',
  'section1Level',
  'section2Level',
  'section3Level',
  'section4Level',
  'section5Level',
  'intervalType',
]
const AssessmentCompareScene: React.FC<AssessmentCompareSceneProps> = ({ ...props }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const [selectedProviderIds, setSelectedProviderIds] = useState<Array<number>>([])
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(false)
  const { openDrawer } = useDrawer()

  const basePayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.NotEqualTo,
            value: 0,
          },
        ],
      },
    ],
    sort: sorting,
    include: ['assessmentTemplate', 'provider.country.risks.riskType', 'provider.organization.contacts.user'],
  }

  const {
    facets: [enabledAssessmentTemplates = []],
  } = useFetchFacets({
    key: [FetchKey.FilterFacet, 'enabled.assessmentTemplate'],
    endpoint: endpoints.organizationAssessmentSettingsFacet,
    facetsParam: [{ name: 'AssessmentTemplateId', isEnum: true }],
    filter: [],
    options: { enabled: canAccessAssessmentTemplate },
  })

  const payload = {
    filter: [
      ...userFilters,
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.In,
            value: enabledAssessmentTemplates.flatMap(a => a.value).map(Number),
          },
        ],
      },
    ],
    sort: sorting,
    include: ['provider', 'assessmentTemplate'],
  }

  const {
    facets: [calculatedAssessmentsTemplateName = []],
  } = useFetchFacets({
    key: [FetchKey.FilterFacet, 'assessmentTemplate.name'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'assessmentTemplate.name' }],
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.In,
            value: enabledAssessmentTemplates.flatMap(a => a.value).map(Number),
          },
        ],
      },
    ],
    sort: {
      order: SortOrder.ASCENDING,
      target: 'value',
    },
    options: { enabled: Boolean(enabledAssessmentTemplates) },
  })

  const {
    facets: [calculatedAssessmentsPeriodName = []],
  } = useFetchFacets({
    key: [FetchKey.FilterFacet, 'periodName'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.In,
            value: enabledAssessmentTemplates.flatMap(a => a.value).map(Number),
          },
        ],
      },
    ],
    sort: {
      order: SortOrder.DESCENDING,
      target: 'value',
    },
    options: { enabled: Boolean(enabledAssessmentTemplates) },
  })

  const {
    items: assessments,
    isLoading,
    isFetching,
    count,
    isError,
  } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload,
    options: { enabled: Boolean(enabledAssessmentTemplates) },
  })

  const { uniqueProviders, uniqueSortedAssessmentTemplateAndPeriod, structuredData } =
    useOrganizedAssessmentData(assessments)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => {
    if (e.target.checked) {
      setSelectedProviderIds(prev => [...prev, provider.id])
    } else {
      setSelectedProviderIds(prev => prev.filter(providerId => providerId !== provider.id))
    }
  }
  return (
    <>
      <ResourceCollectionScene
        enableScroll
        title={formatMessage({ id: 'navbar.assessments' })}
        filter={
          <AssessmentFilters
            allowedFilters={allowedFilters}
            preSelectedTemplates={calculatedAssessmentsTemplateName.map(template => Number(template.value))}
            preSelectedPeriodName={
              calculatedAssessmentsPeriodName.length > 0 ? [Number(calculatedAssessmentsPeriodName[0].value)] : []
            }
          />
        }
        actionButtons={[
          {
            label: formatMessage({ id: 'assessments.setAssessments' }),
            variant: 'outlined',
            to: generatePath(paths.configurations, {
              configurationsPage: CONFIGURATION_PAGE_IDS.Assessments,
            }),
          },
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Assessment}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={StandardAssessmentsExportColumnsAccessor}
                  rawExportPayload={basePayload}
                />,
              ),
            disabled: isLoading || count === 0,
          },
        ]}
        buttonRow={
          selectedProviderIds.length
            ? [
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.categorize' },
                    { count: selectedProviderIds.length },
                  ),
                  onClick: openUpdateDrawer,
                  disabled: isLoading || isFetching,
                },
                {
                  label: formatMessage({ id: 'assessments.yourAssessment' }, { count: selectedProviderIds.length }),
                  onClick: () => openDrawer(<DrawerViewRiskAssessment providerIds={selectedProviderIds} />),
                  disabled: isLoading || isFetching,
                },
              ]
            : undefined
        }
        {...props}
      >
        <Table<Provider>
          RowComponent={({ row }): JSX.Element => (
            <AssessmentCompareRow
              row={row}
              onCheckboxChange={handleCheckboxChange}
              selectedProviders={selectedProviderIds}
              providerAssessmentData={structuredData}
            />
          )}
          HeaderComponent={() => (
            <AssessmentCompareHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              selectedProviders={selectedProviderIds}
              setSelectedProviders={setSelectedProviderIds}
              providersInPage={uniqueProviders}
              assessmentTemplateAndPeriod={uniqueSortedAssessmentTemplateAndPeriod}
            />
          )}
          data={uniqueProviders}
          count={count}
          isLoading={isLoading || isFetching}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          noPagination
          stickyColumnIndex={2}
          emptyState={
            <EmptyState
              title={formatMessage({ id: 'assessments.compare.emptyStateTitle' }, { br })}
              description={formatMessage({ id: 'assessments.compare.emptyStateDescription' })}
              iconComponent={ChatIcon}
            />
          }
        />
      </ResourceCollectionScene>

      <UpdateProvidersDrawer
        selectedProviders={uniqueProviders
          .filter(provider => selectedProviderIds.includes(provider.id))
          .map(provider => ({
            providerId: provider.id,
            name: provider.name,
            activityStatus: provider.activityStatus,
            finalRiskRating: provider.finalRiskRating,
            priority: provider.priority,
            providerApprovalStatus: provider.providerApprovalStatus,
            categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
            ownerUserId: provider.ownerUserId,
            tier: provider.tier,
            supplierUsage: provider.supplierUsage,
          }))}
        setSelectedProviders={setSelectedProviderIds}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
      />
    </>
  )
}
export default AssessmentCompareScene
