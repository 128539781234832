import PeriodField from '@app/src/components/CreateNewRequestModal/PeriodField'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import { useQuestionnairePeriodOptions } from '@app/src/hooks/questionnairePeriodOptions'
import {
  AUTOMATION_QUESTION_KEYS,
  AutomationFormData,
} from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const AutomationPeriod: React.FC = () => {
  const { formatMessage } = useIntl()
  const { setValue, control, getValues, watch } = useFormContext<AutomationFormData>()
  const { questionnaireYearOptions } = useQuestionnairePeriodOptions()
  const reportType = watch(AUTOMATION_QUESTION_KEYS.REPORT_TYPE) as ReportType

  const initialValues: AutomationFormData | undefined = useMemo(() => getValues(), [])

  if (reportType === ReportType.COMPANY)
    return (
      <PeriodField
        defaultValues={
          initialValues && {
            dateYear: parseInt(initialValues.dateYear),
            datePeriod: initialValues.datePeriod,
            dateType: initialValues.dateType,
          }
        }
      />
    )

  return (
    <Select
      options={questionnaireYearOptions}
      fieldLabel={formatMessage({ id: 'form.createRequest.selectPeriodLabel' })}
      onInputChange={(e, value) => {
        if (!value) setValue(AUTOMATION_QUESTION_KEYS.DATE_YEAR, undefined)
      }}
      required
      enableAutoSelect
      name={AUTOMATION_QUESTION_KEYS.DATE_YEAR}
      control={control}
      defaultValue={initialValues.dateYear ? parseInt(initialValues.dateYear) : undefined}
      findSelectedValue={(value, option) => option.value?.toString() === value?.toString()}
    />
  )
}
export default AutomationPeriod
