import BrokenImage from '@mui/icons-material/BrokenImage'
import InsertPhoto from '@mui/icons-material/InsertPhoto'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles({
  imageOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  placeholder: {
    marginLeft: -7,
  },
})

interface Props {
  src: string | null | undefined
  size: string
  style?: object
  noPlaceholder?: boolean
  placeholder?: string
  placeholderComponent?: JSX.Element
  position?: string
  repeat?: string
  onLoad?: () => void
  onError?: () => void
  children?: React.ReactNode
  overlay?: JSX.Element
  alt?: string
  domain?: string
  className?: string
}

const CLEARBIT_LOGO = '//logo.clearbit.com'

const Image = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const {
    src,
    noPlaceholder,
    placeholder,
    placeholderComponent,
    size,
    position,
    repeat,
    style,
    onLoad,
    onError,
    children,
    overlay,
    alt,
    domain,
    className,
  } = props
  const [loaded, setLoaded] = useState(false)
  const [errorPlaceholder, setErrorPlaceholder] = useState(false)
  const clearbit = useMemo(() => `${CLEARBIT_LOGO}/${domain?.replace(/^https?:\/\//, '')}`, [domain])

  const _onLoad = (): void => {
    setLoaded(true)
    onLoad && onLoad()
  }

  const _onError = (): void => {
    setErrorPlaceholder(true)
    onError && onError()
  }

  if ((!src && !domain) || errorPlaceholder) {
    if (children) {
      return <>{children}</>
    }

    if (placeholder || placeholderComponent || noPlaceholder) {
      return (
        <div
          style={
            placeholderComponent || noPlaceholder
              ? {}
              : {
                  backgroundImage: `url('${placeholder}')`,
                  backgroundSize: size,
                  backgroundPosition: position,
                  backgroundRepeat: repeat,
                  ...style,
                }
          }
        >
          {noPlaceholder ? null : placeholderComponent}
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...style,
        }}
      >
        {errorPlaceholder ? (
          <BrokenImage className={clsx(classes.placeholder, className)} />
        ) : (
          <InsertPhoto className={clsx(classes.placeholder, className)} />
        )}
      </div>
    )
  }

  return (
    <>
      <div
        className={className}
        data-testid="image-component"
        data-cy="image-component"
        style={{
          backgroundImage: `url('${src || clearbit}')`,
          backgroundSize: size,
          backgroundPosition: position,
          backgroundRepeat: repeat,
          position: 'relative',
          zIndex: overlay ? 0 : 'auto',
          ...style,
        }}
      >
        {overlay && <div className={classes.imageOverlay}>{overlay}</div>}
        {children}
      </div>

      {!loaded && (
        <img style={{ display: 'none' }} src={src || clearbit} onLoad={_onLoad} onError={_onError} alt={alt} />
      )}
    </>
  )
}

Image.defaultProps = {
  size: 'contain',
  position: 'center center',
  repeat: 'no-repeat',
}

export default Image
