import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router'
import { useConfirm } from 'material-ui-confirm'
import { Delete } from '@mui/icons-material'

import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import endpoints from '@app/src/api/endpoints'
import { NotificationSeverity } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React from 'react'

type UseDeleteAssessmentDialogProps = {
  assessmentTemplateId: number
}

const useDeleteAssessmentDialog = ({ assessmentTemplateId }: UseDeleteAssessmentDialogProps) => {
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const history = useHistory()
  const confirm = useConfirm()
  const { mutate: deleteResource } = useDeleteResource()

  const performDelete = (id: number) => {
    deleteResource(
      { url: endpoints.deleteAssessmentTemplate(id) },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'assessmentTemplate.delete.success' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.AssessmentCollection)
          queryClient.invalidateQueries(FetchKey.AssessmentTemplateCollection)
          history.push(generatePath(paths.assessmentTemplateCollection))
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const confirmDelete = async () => {
    confirm({
      title: formatMessage({ id: 'assessmentTemplate.delete.title' }),
      content: formatMessage({ id: 'assessmentTemplate.delete.description' }, { br: <br /> }),
      confirmationText: formatMessage({ id: 'assessmentTemplate.delete.buttonText' }),
      confirmationButtonProps: { color: 'error', startIcon: <Delete /> },
    })
      .then(() => performDelete(assessmentTemplateId))
      .catch(() => {
        /* Do nothing if cancelled */
      })
  }

  return { confirmDelete }
}

export default useDeleteAssessmentDialog
