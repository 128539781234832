import { CollectionResponseType } from '@app/src/api/fetchHooks'
import { ObjectToOptionType } from '@app/src/components/Form/Select/ControlledSelect'
import useApi from '@app/src/hooks/api'
import { FacetResponse } from '@app/src/pages/ResourceCollection'
import { Navigation } from '@app/src/types/schemas'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'

const MAX_ITEMS = 500

export interface Option<T = number | string> {
  label: string
  value: T
  adornment?: string
}

export interface UseAutocompleteOptionsParams<T, O> {
  isOpen: boolean
  navigation?: Navigation
  defaultOptions?: Option<T>[]
  objectToOption?: ObjectToOptionType<T, O>
  fetchBeforeOpening?: boolean
}

export function useSelectOptions<T = number | string, O = unknown>({
  isOpen,
  navigation,
  defaultOptions,
  objectToOption,
  fetchBeforeOpening,
}: UseAutocompleteOptionsParams<T, O>): {
  options: Option<T>[]
  loading: boolean
  error?: string | null
} {
  const { formatMessage } = useIntl()
  const navigationMethod = navigation?.type ?? 'get'
  const navigationUrl = navigation?.url ?? ''
  const navigationFilter = useMemo(() => navigation?.postObject?.filter || [], [navigation])
  const navigationFacets = navigation?.postObject?.facets
  const navigationSort = navigation?.postObject?.sort
  const navigationInclude = useMemo(() => navigation?.postObject?.include || [] || [], [navigation])
  const navigationRiskIds = navigation?.postObject.riskIds
  const navigationRiskStatus = navigation?.postObject.riskStatus

  const isFacetResponse = (response: CollectionResponseType<unknown> | FacetResponse): response is FacetResponse => {
    return Boolean((response as FacetResponse).facets)
  }

  const { request } = useApi()
  const fetchOptions = async (): Promise<Option<T>[]> => {
    try {
      const data = await request<CollectionResponseType<O> | FacetResponse, unknown>(navigationMethod, {
        url: navigationUrl,
        body: {
          filter: navigationFilter,
          include: navigationInclude,
          facets: navigationFacets,
          sort: navigationSort,
          riskIds: navigationRiskIds,
          riskStatus: navigationRiskStatus,
          pagination: {
            pageNumber: 1,
            itemsPerPage: navigation?.postObject?.pagination?.itemsPerPage ?? MAX_ITEMS,
          },
        },
      })

      if (isFacetResponse(data)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // todo typing: find a way to make it work with Facets, in some places we convert facets to Option
        return (objectToOption ? data.facets?.[0]?.items?.map(objectToOption) : data.facets?.[0]?.items) as Option<T>[]
      } else {
        return (objectToOption ? data.items?.map(objectToOption) : data.items) as Option<T>[]
      }
    } catch (e) {
      throw formatMessage({ id: 'general.somethingWentWrong' })
    }
  }

  const { data, isLoading, error } = useQuery<Option<T>[], string>(
    [
      navigationUrl,
      navigationFilter,
      navigationFacets,
      navigationSort,
      navigationInclude,
      ...(navigation?.fetchKey ? [navigation.fetchKey] : []),
    ],
    () => fetchOptions(),
    {
      keepPreviousData: true,
      enabled: Boolean((isOpen || fetchBeforeOpening) && navigationUrl && !defaultOptions?.length),
    },
  )

  return { options: defaultOptions || data || [], loading: isLoading, error }
}

export default useSelectOptions
