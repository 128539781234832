import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { File } from '@app/src/types/resourceExplorer'
import { InsertDriveFileOutlined } from '@mui/icons-material'
import React from 'react'
import { useIntl } from 'react-intl'
import ProviderDocumentHeader from './ProviderDocumentHeader'
import ProviderDocumentRow from './ProviderDocumentRow'

type ProviderDocumentCollectionProps = {
  providerId: number
  embedded: boolean
}

type FilePayload = {
  type: string
  sourceIds: number[]
}

const ProviderDocumentCollection: React.FC<ProviderDocumentCollectionProps> = ({ providerId, embedded }) => {
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { formatMessage } = useIntl()
  const {
    data = [],
    isLoading,
    isError,
  } = useFetchPost<File[], FilePayload>({
    key: FetchKey.FileCollection,
    endpoint: endpoints.filesBySourceObject,
    payload: {
      sourceIds: [providerId],
      type: 'Provider',
    },
  })

  return (
    <ResourceCollectionScene title={formatMessage({ id: 'resourceTypes.providerActivity' })} embedded={embedded}>
      <Table<File>
        RowComponent={ProviderDocumentRow}
        HeaderComponent={ProviderDocumentHeader}
        data={data}
        page={page}
        pageSize={pageSize}
        noPagination
        isLoading={isLoading}
        count={data.length}
        isError={isError}
        setPage={setPage}
        setPageSize={setPageSize}
        emptyState={
          <EmptyState
            iconComponent={InsertDriveFileOutlined}
            title={formatMessage({ id: 'resourceExplorer.activity.fileEmptyStateTitle' })}
            description={formatMessage({ id: 'resourceExplorer.activity.fileEmptyStateDescription' })}
            sx={{ my: 2 }}
          />
        }
      />
    </ResourceCollectionScene>
  )
}

export default ProviderDocumentCollection
