import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProviderRiskAssessmentHeader: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'resourceCollections.general.date' })}
        minWidth={TableCellWidth.EXTRA_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'resourceCollections.general.user' })}
        minWidth={TableCellWidth.MEDIUM}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'resourceCollections.general.action' })}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'resourceCollections.general.comment' })}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
    </TableRow>
  )
}

export default ProviderRiskAssessmentHeader
