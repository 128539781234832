import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Button, Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import TextFieldFilter from './TextFieldFilter'

const CustomQuestionFilters = ({ allowedFilters, resetPage }: FiltersProps): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <Grid container spacing={1}>
      <Filters resetPage={resetPage} allowedFilters={allowedFilters}>
        {({ filters, clearQueryFilter }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item sm={8}>
                <Filter operator={Operators.Contains} name="questionText">
                  {({ filterName, value, operator }) => (
                    <TextFieldFilter
                      fullWidth
                      size="small"
                      defaultValue={value}
                      label={formatMessage({ id: 'schemas.question.name' })}
                      filterName={filterName}
                      operator={operator}
                      name="questionName"
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={3}>
                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default CustomQuestionFilters
