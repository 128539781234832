import TextField, { TextFieldProps } from '@app/src/components/Ui/TextField'
import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

type ControlledNumberFieldProps = {
  control: Control
  name: string
  label: string
  required?: boolean
  asString?: boolean
  fieldError?: FieldError
  onClear?: () => void
} & Omit<TextFieldProps, 'ref' | 'defaultValue' | 'type'> &
  Omit<NumberFormatProps, 'customInput'>

const ControlledNumberField: React.FC<ControlledNumberFieldProps> = ({
  control,
  name,
  required,
  label,
  onClear,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, name, value }) => (
        <NumberFormat
          customInput={TextField}
          fullWidth
          name={name}
          value={value}
          required={required}
          label={label}
          onChange={onChange}
          onClear={onClear}
          {...props}
        />
      )}
    />
  )
}

export default ControlledNumberField
