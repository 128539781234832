import { Box, RatingProps, Tooltip, useTheme } from '@mui/material'
import RatingDots from '@app/src/components/RatingDots'
import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import React from 'react'
import { useIntl } from 'react-intl'

interface RatingCellProps extends RatingProps {
  value?: number
  disableCell?: boolean
  label: string
}

const RatingCell: React.FC<RatingCellProps> = ({ disableCell, value, label }) => {
  const { formatMessage } = useIntl()
  const { spacing } = useTheme()

  if (!value) return null
  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              top: spacing(-1.5),
            },
          },
        }}
        title={formatMessage({ id: 'baseAssessment.assessedLevel' }, { value })}
      >
        <Box display="inline flex" py={1.5}>
          <RatingDots level={value} />
        </Box>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default RatingCell
