import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResource } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import Assessment from '@app/src/types/assessment'
import { AssessmentTemplateWithClassificationRules, Question, ResponseItem } from '@app/src/types/resourceExplorer'
import { Box } from '@mui/material'
import { maxBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import AssessmentsDetailsHeader from '../../ResourceCollection/Collections/Assessments/AssessmentsDetailsHeader'
import AssessmentsDetailsRow from '../../ResourceCollection/Collections/Assessments/AssessmentsDetailsRow'

type AssessmentInsightsDetailsProps = {
  assessmentTemplateId: string
  userFilters?: FilterGroup[]
  periodName?: string
}

type AssessmentWithResponses = Assessment & {
  responseItems: Array<ResponseItem | undefined>
}

const staleTime = 60000

const AssessmentInsightsDetails: React.FC<AssessmentInsightsDetailsProps> = ({
  assessmentTemplateId,
  userFilters,
  periodName,
}) => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination({ page: 1, pageSize: 50 })
  const [questions, setQuestions] = useState<Question[]>()
  const [questionIds, setQuestionIds] = useState<number[]>()
  const [combinedData, setCombinedData] = useState<AssessmentWithResponses[]>([])

  const { data: assessmentTemplateWithClassificationRules, isFetching } =
    useFetchResource<AssessmentTemplateWithClassificationRules>({
      key: [FetchKey.AssessmentTemplateWithClassificationRules, assessmentTemplateId],
      endpoint: endpoints.assessmentTemplateWithClassificationRules(assessmentTemplateId),
      options: {
        staleTime: staleTime,
      },
    })

  useEffect(() => {
    if (assessmentTemplateWithClassificationRules) {
      const questions = assessmentTemplateWithClassificationRules?.questions?.map(question => question) ?? []
      const questionIds = questions.map(question => question.id)

      setQuestions(questions)
      setQuestionIds(questionIds)
    }
  }, [assessmentTemplateWithClassificationRules])

  const {
    items: assessments,
    count,
    isFetched: isAssessmentFetched,
  } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload: {
      filter: [
        ...(userFilters ?? []),
        {
          name: 'assessmentTemplateId',
          filters: [{ operator: Operators.EqualTo, value: assessmentTemplateId }],
        },
        {
          name: 'periodName',
          filters: [{ operator: Operators.EqualTo, value: periodName }],
        },
      ],
      sort: sorting,
      include: ['provider.organization', 'assessmentTemplate'],
      pagination: {
        itemsPerPage: pageSize,
        pageNumber: page,
      },
    },
    options: { enabled: !isFetching && !!periodName, staleTime: staleTime },
  })

  const providerIds = assessments.map(assessment => assessment.providerId)

  const { assessmentQuestionnaireLinks, isLoadingAssessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentTemplateId: Number(assessmentTemplateId),
    includeAssessment: false,
  })

  const linkedQuestionnaireTemplateIds = assessmentQuestionnaireLinks.map(x => x.questionnaireTemplateId) as number[]

  const {
    items: responseItems,
    isFetched: isResponseItemFetched,
    isError,
  } = useFetchCollectionWithPost<ResponseItem>({
    endpoint: endpoints.responseItemsCollection,
    key: FetchKey.ResponseItemsCollection,
    payload: {
      filter: [
        {
          name: 'response.isLatestSubmitted',
          filters: [{ operator: Operators.EqualTo, value: true }],
        },
        {
          name: 'response.request.periodName',
          filters: [{ operator: Operators.EqualTo, value: periodName }],
        },
        {
          name: 'requestItem.questionId',
          filters: [{ operator: Operators.In, value: questionIds }],
        },
        {
          name: 'response.request.subscriptions.target.id',
          filters: [{ operator: Operators.In, value: providerIds }],
        },
        {
          name: 'response.request.questionnaireTemplateId',
          filters: [{ operator: Operators.In, value: linkedQuestionnaireTemplateIds }],
        },
      ],
      include: [
        'response.request',
        'requestItem.template',
        'requestItem.unit.symbol',
        'response.request.subscriptions.target',
        'creatorOrganization',
        'questionAnswerClassificationResults',
      ],
    },
    options: {
      enabled: Boolean(
        !!periodName && providerIds?.length && questionIds?.length && !isLoadingAssessmentQuestionnaireLink,
      ),
      staleTime: staleTime,
    },
  })

  const responseItemsMap = useMemo(() => {
    // Group responseItems by organization and question
    const responseItemsGroupedByOrgAndQuestion = Object.groupBy(
      responseItems,
      ri => `${ri.creatorOrganizationId}|${ri.requestItem.questionId}`,
    )

    // Get only the latest submitted responseItems per group
    const latestResponseItemGroupEntries = Object.entries(responseItemsGroupedByOrgAndQuestion).map(
      ([key, responseItems]) => {
        return {
          key,
          responseItem:
            responseItems?.length === 1
              ? responseItems[0]
              : maxBy(responseItems ?? [], ri => new Date(ri.response.submittedAt).getTime()),
        }
      },
    )

    return new Map(latestResponseItemGroupEntries.map(g => [g.key, g.responseItem]))
  }, [responseItems])

  useEffect(() => {
    if (isResponseItemFetched && !isError) {
      const assessmentsWithResponseItems = assessments.map(assessment => {
        const matchingResponseItems =
          questionIds?.map(questionId => responseItemsMap.get(`${assessment.provider.organizationId}|${questionId}`)) ??
          []

        return {
          ...assessment,
          responseItems: matchingResponseItems,
        }
      })
      setCombinedData(assessmentsWithResponseItems)
    }
  }, [assessments, questionIds, responseItemsMap, isResponseItemFetched, isError])

  return (
    <Box ml={4}>
      <Table<AssessmentWithResponses>
        HeaderComponent={() => (
          <AssessmentsDetailsHeader
            questions={questions}
            toggleSorting={toggleSorting}
            activeSorting={sorting}
            userFilters={userFilters}
          />
        )}
        RowComponent={({ row }) => <AssessmentsDetailsRow assessment={row} responseItems={row.responseItems} />}
        count={count}
        data={combinedData}
        isLoading={!isResponseItemFetched || !isAssessmentFetched}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        stickyColumnIndex={1}
      />
    </Box>
  )
}

export default AssessmentInsightsDetails
