import useSelectOptions from '@app/src/hooks/selectOptions'
import { Navigation } from '@app/src/types/schemas'
import { AutocompleteInputChangeReason } from '@mui/material'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types'
import SimpleSelect, { PassedThroughProps, SimpleSelectTruncateProps } from './SimpleSelect'

export interface Option<T = number | string> {
  label: string
  value: T
  adornment?: string
  //Not used unless renderOption is used
  additionalText?: string
  disabled?: boolean
}

export type ObjectToOptionType<T, O> = (object: O) => Option<T>

interface SingleModeProps<T = number | string> {
  multiple?: false
  defaultValue?: T | null
}

interface MultipleModeProps<T = number | string> {
  multiple: true
  defaultValue?: T[]
}

export type SelectTruncateProps<T> = SingleModeProps<T> | MultipleModeProps<T>

interface CommonProps<T = number | string, O = unknown> {
  name: string
  navigation?: Navigation
  hoveringLabel?: boolean
  options?: Option<T>[]
  objectToOption?: ObjectToOptionType<T, O>
  control: Control
  required?: boolean
  forceFetch?: boolean
  onClick?: () => void
  findSelectedValue?: (value: T, option: Option<T>) => boolean
  rules?: object
  enableAutoSelect?: boolean
  onInputChange?: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void
  customSort?: (a: Option<T>, b: Option<T>) => number
}

function isOption<T>(item: Option<T> | undefined): item is Option<T> {
  return typeof item !== 'undefined'
}

export type Props<T = number | string, O = unknown> = CommonProps<T, O> & SelectTruncateProps<T>

export function Select<T = string | number, O = T>({
  navigation,
  options: optionsProp,
  objectToOption,
  control,
  required,
  name,
  defaultValue,
  multiple,
  enableSelectAll,
  forceFetch,
  onClick,
  enableAutoSelect,
  findSelectedValue = (flatValue, option): boolean => option.value === flatValue,
  rules,
  customSort,
  ...selectProps
}: Props<T, O> & PassedThroughProps<T>): JSX.Element {
  const [isOpen, setOpen] = useState<boolean>(false)

  const {
    options,
    loading,
    error: optionsError,
  } = useSelectOptions<T, O>({
    isOpen,
    navigation,
    defaultOptions: optionsProp,
    objectToOption,
    fetchBeforeOpening: Boolean(defaultValue) || forceFetch,
  })

  const sortedOptions = customSort ? options.sort(customSort) : options

  const findValue = (flatValue: T): Option<T> | undefined => {
    return options.find((option): boolean => findSelectedValue(flatValue, option))
  }
  const findDefaultValue = (): T[] | T | null | undefined => {
    if (!defaultValue && enableAutoSelect && options.length === 1) {
      return multiple ? [sortedOptions[0].value] : sortedOptions[0].value
    }
    return defaultValue
  }

  if (defaultValue && sortedOptions?.length === 0) return <>Loading...</>

  const getSingleSelectValue = (value: T): Option<T> | null => {
    return findValue(value) || null
  }

  const getMultipleSelectValue = (value: T[] = []): Option<T>[] => {
    return value?.map((flatValueItem: T) => findValue(flatValueItem)).filter<Option<T>>(isOption)
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={findDefaultValue()}
        rules={rules}
        render={({ onChange, value }): JSX.Element => {
          // here we are making sure we have the correct combination of types

          const truncateProps: SimpleSelectTruncateProps<T> = multiple
            ? {
                multiple: true,
                enableSelectAll: Boolean(enableSelectAll),
                value: getMultipleSelectValue(value),
              }
            : {
                multiple: false,
                enableSelectAll: false,
                value: getSingleSelectValue(value),
              }
          return (
            <SimpleSelect<T>
              {...selectProps}
              {...truncateProps}
              onChange={(value: Option<T> | Option<T>[] | null) => {
                if (!value) return
                if (multiple) {
                  onChange([value].flat()?.map((val: Option<T>) => val.value))
                } else {
                  onChange((value as Option<T>)?.value)
                }
              }}
              size={selectProps.size}
              onClick={onClick}
              options={sortedOptions}
              loading={loading}
              open={isOpen}
              required={required}
              setOpen={setOpen}
              optionsError={optionsError}
              name={name}
            />
          )
        }}
      />
    </>
  )
}

export default Select
