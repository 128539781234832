import VerificationButton from '@app/src/pages/Questionnaire/Accessor/Actions/VerificationButton'
import AnswerValue from '@app/src/pages/Questionnaire/Provider/Report/AnswerValue'
import { AnswerStatus, ExternalVerificationStatus, HistoryStatus, ResponseItem } from '@app/src/types/resourceExplorer'
import { Box } from '@mui/material'
import React from 'react'
import { useReport } from '../../ReportContext'
import VerificationStatus from '../../VerificationStatus'
import SkippedQuestionCard from './SkippedQuestionCard'

export interface AnswerDisplayProps {
  item: ResponseItem
  questionType: string
  unitSymbol: string | null
}

const AnswerDisplay = ({ item, questionType, unitSymbol }: AnswerDisplayProps): JSX.Element => {
  const { reportStatus } = useReport()

  const isSomeExternalStatusCorrectionNeeded = item.verifications?.some(
    x => x.externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded,
  )

  const displayValue = (): React.ReactNode => {
    if (item?.answerStatus !== AnswerStatus.Answered) {
      return <SkippedQuestionCard item={item} />
    }

    return <AnswerValue item={item} questionType={questionType} unitSymbol={unitSymbol} />
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        px={isSomeExternalStatusCorrectionNeeded ? 2 : 0}
        py={isSomeExternalStatusCorrectionNeeded ? 1 : 0}
        bgcolor={
          isSomeExternalStatusCorrectionNeeded
            ? 'warning.light'
            : reportStatus === HistoryStatus.Submitted || reportStatus === HistoryStatus.Approved
              ? 'grey.200'
              : 'common.white'
        }
        borderRadius={1}
      >
        <Box flexGrow={1}>{displayValue()}</Box>
        <VerificationButton item={item} />
      </Box>

      <VerificationStatus {...item} />
    </>
  )
}

export default AnswerDisplay
