import { Box, Grid } from '@mui/material'
import React from 'react'
import EditRequestTags from './EditRequestTags'
import QuestionnaireType from './QuestionnaireType'

const EditTemplateSettings: React.FC = () => {
  return (
    <Box my={5} pr={5} flexGrow={1}>
      <Grid container justifyContent="center">
        <Grid container item xs={6} bgcolor="common.white" p={5} borderRadius={2}>
          <Grid item xs={12}>
            <QuestionnaireType />
          </Grid>
          <Grid item xs={12} mt={5}>
            <EditRequestTags />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditTemplateSettings
