import { TableCell, TableRow } from '@mui/material'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { ProviderProgressView } from '@app/src/types/resourceExplorer'
import ProviderProgressCell from './ProviderProgressCell'
import TrendCell from './TrendCell'

interface ProviderProgressRowProps {
  row: ProviderProgressView
  periods: string[]
  organizationId?: number
}

const ProviderProgressRow: React.FC<ProviderProgressRowProps> = ({
  row: providerProgressView,
  periods,
  organizationId,
}) => {
  return (
    <TableRow data-cy="provider-progress-row">
      <TableCell>{providerProgressView?.parentObject.title}</TableCell>

      {periods.map(period => (
        <ProviderProgressCell
          key={`${providerProgressView.parentObject?.id}-${period}`}
          periodDetail={providerProgressView.periodDetails.find(detail => detail.periodName === period)}
        />
      ))}

      <ValueCell value={providerProgressView.parentObject?.unit?.symbol ?? ''} />
      <TrendCell
        questionType={providerProgressView.parentObject?.questionType}
        isMultiSelectOptionQuestion={providerProgressView.periodDetails[0]?.isMultiSelectOptionQuestion}
        providerProgressViewData={providerProgressView}
        periods={periods}
        organizationId={organizationId}
      />
    </TableRow>
  )
}

export default ProviderProgressRow
