import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { Category, SupplierUsage } from '@app/src/types/categories'
import { Provider, Supplier } from '@app/src/types/organizations'
import { ResourceTypes } from '@app/src/wf-constants'
import { Box, Chip, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import FinalRiskRatingCell from '../Table/Cells/FinalRiskRatingCell'
import TagsCell from '../Table/Cells/TagsCell'

interface ProviderCategoriesProps {
  provider: Provider
}

const ProviderCategories: React.FC<ProviderCategoriesProps> = ({ provider }) => {
  const { formatMessage } = useIntl()

  const isSupplierType = (provider: Provider): provider is Supplier =>
    provider?.type.toLowerCase() === ResourceTypes.Supplier

  const { data: categories } = useFetchResource<Category[]>({
    key: FetchKey.Category,
    endpoint: endpoints.category,
  })

  const amountOfTagsShow = provider.categoryOptions?.length ?? 0

  return (
    <>
      {Boolean(amountOfTagsShow) && (
        <>
          <Typography variant="subtitle1" color="textSecondary" mb={2}>
            {formatMessage({ id: 'schemas.supplier.customCategories' })}
          </Typography>
          <Stack spacing={2}>
            {categories?.map(category => {
              const currentCategoryOptions = provider.categoryOptions?.filter(
                catOpt => catOpt.categoryId === category.id,
              )
              return (
                <React.Fragment key={category.id}>
                  {Boolean(currentCategoryOptions?.length) && (
                    <Stack>
                      <Typography variant="body2">{category.name}</Typography>
                      <TagsCell
                        amountOfTagsShow={amountOfTagsShow}
                        tags={
                          provider.categoryOptions
                            ?.filter(catOpt => catOpt.categoryId === category.id)
                            ?.map(catOpt => catOpt.name) ?? []
                        }
                        disableCell
                      />
                    </Stack>
                  )}
                </React.Fragment>
              )
            })}
          </Stack>
          <Divider sx={{ my: 3 }} />
        </>
      )}
      <Typography variant="subtitle1" color="textSecondary" mb={2}>
        {formatMessage({ id: 'resourceTypes.categories' })}
      </Typography>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.provider.activityStatus' })}</Typography>
          <Box>
            <Chip
              variant="outlined"
              size="small"
              disabled={!provider.activityStatus || provider.activityStatus === 'NotSelected'}
              label={formatMessage({ id: `schemas.provider.activityStatusValues.${provider.activityStatus}` })}
            />
          </Box>
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.provider.finalRiskRating' })}</Typography>
          <FinalRiskRatingCell color="textSecondary" value={provider.finalRiskRating} disableCell />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.provider.priority' })}</Typography>
          <Box>
            <Chip
              variant="outlined"
              size="small"
              disabled={!provider.priority || provider.priority === 'NotSelected'}
              label={formatMessage({ id: `schemas.provider.priorityValues.${provider.priority}` })}
            />
          </Box>
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}</Typography>
          <Box>
            <Chip
              variant="outlined"
              size="small"
              disabled={!provider.providerApprovalStatus || provider.providerApprovalStatus === 'NotSelected'}
              label={formatMessage({
                id: `schemas.provider.providerApprovalStatusValues.${provider.providerApprovalStatus}`,
              })}
            />
          </Box>
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.provider.ownerUser' })}</Typography>
          <Box>
            <Chip
              disabled={!provider.ownerUser}
              variant="outlined"
              size="small"
              label={provider.ownerUser?.name || formatMessage({ id: 'general.notSelected' })}
            />
          </Box>
        </Stack>
        {isSupplierType(provider) && (
          <>
            <Stack>
              <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.supplierUsage' })}</Typography>
              <Box>
                <Chip
                  variant="outlined"
                  size="small"
                  disabled={!provider.supplierUsage || provider.supplierUsage === 'NotSelected'}
                  label={formatMessage({
                    id: `schemas.provider.supplierUsageValues.${provider.supplierUsage ?? SupplierUsage.NotSelected}`,
                  })}
                />
              </Box>
            </Stack>
            <Stack>
              <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.tier' })}</Typography>
              <Box>
                <Chip
                  variant="outlined"
                  size="small"
                  disabled={!provider.tier}
                  label={
                    provider.tier
                      ? formatMessage({ id: 'schemas.supplier.tierNumber' }, { number: provider.tier })
                      : formatMessage({ id: 'general.notSelected' })
                  }
                />
              </Box>
            </Stack>
          </>
        )}
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.organization.duns' })}</Typography>
          <Box>
            <Chip
              disabled={!provider.duns}
              variant="outlined"
              size="small"
              label={provider.duns || formatMessage({ id: 'general.notAdded' })}
            />
          </Box>
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.organization.customId' })}</Typography>
          <Box>
            <Chip
              disabled={!provider.customId}
              variant="outlined"
              size="small"
              label={provider.customId || formatMessage({ id: 'general.notAdded' })}
            />
          </Box>
        </Stack>
      </Stack>
    </>
  )
}

export default ProviderCategories
