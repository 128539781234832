import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Markdown from '@app/src/components/Markdown'
import React from 'react'

interface StatisticsCardProps {
  title: string
  children: React.ReactNode
  isEmptyState?: boolean
  withBorder?: boolean
  justifyContent?: 'center' | 'left' | 'right'
  action?: React.ReactNode
  helperText?: React.ReactNode
  loading?: {
    isLoading: boolean
    skeleton: React.ReactNode
  }
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({
  title,
  action,
  children,
  isEmptyState,
  withBorder,
  justifyContent = 'center',
  helperText,
  loading,
}) => {
  const { palette } = useTheme()

  return (
    <Box
      py={2}
      px={3}
      bgcolor={isEmptyState ? 'secondary.main' : 'background.default'}
      borderRadius="8px"
      height="100%"
      width="100%"
      border={withBorder ? `1px ${palette.grey[300]} solid` : 'none'}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" justifyContent="space-between" color="common.white">
        <Stack direction="row" spacing={1} py={0.5} alignItems="center">
          <Typography variant="overline" color={isEmptyState ? 'inherit' : 'textSecondary'}>
            <Markdown>{title}</Markdown>
          </Typography>
          {helperText && (
            <Tooltip title={helperText} arrow>
              <InfoOutlinedIcon
                sx={({ palette }) => ({
                  color: palette.text.secondary,
                  '&:hover': { backgroundColor: 'grey.200', borderRadius: 4, cursor: 'pointer' },
                })}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Stack>

        {action}
      </Box>
      <Box display="flex" justifyContent={justifyContent} flexGrow={1}>
        {loading?.isLoading ? loading.skeleton : children}
      </Box>
    </Box>
  )
}

export default StatisticsCard
