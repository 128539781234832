import { makeStyles } from '@mui/styles'
import React, { ReactNode, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useQueryClient } from 'react-query'

import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import CreationModalStep from '@app/src/components/CreationModal/CreationModalStep'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { GOAL_TRACKER_QUESTION_KEYS } from '@app/src/pages/Dashboards/GoalTracker/CreateGoalTrackerModal'
import { useFiltersContext } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { SendRequestResult } from '@app/src/types/resourceExplorer'
import { NotificationSeverity, ResourceTypes } from '@app/src/wf-constants'
import { useIntl } from 'react-intl'
import { COMPANY_REPORT_QUESTION_KEYS, FormData as FormDataCompany } from './CompanyReport'
import { EDCI_REPORT_QUESTION_KEYS, FormData as FormDataEdci } from './EdciReport'
import { Periods } from './PeriodField'
import { SFDR_REPORT_QUESTION_KEYS } from './SfdrReport/SfdrReportWithoutCompanies'
import { FormData as FormDataWf, WF_REPORT_QUESTION_KEYS } from './WfReport'
import { calculateStartsAndEndsAtDate } from './utils'

type RequestQuestionStepProps = {
  title: string
  fieldnames: Array<
    | WF_REPORT_QUESTION_KEYS
    | COMPANY_REPORT_QUESTION_KEYS
    | EDCI_REPORT_QUESTION_KEYS
    | SFDR_REPORT_QUESTION_KEYS
    | GOAL_TRACKER_QUESTION_KEYS
  >
  description?: ReactNode
  hasSkipButton?: boolean
  children: ReactNode
  onClose: () => void
  onBack: () => void
  onSubmitEdci?: (values: FormDataEdci) => void
  onSubmit?: () => void
  disableInitialBack?: boolean
  generateInvitationLinkFlow?: boolean
  createGoalTrackerFlow?: boolean
  isLoading?: boolean
  editMode?: boolean
}

const useStyles = makeStyles({
  form: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
})

export type CombinedFormData = FormDataWf | FormDataCompany | FormDataEdci

export function isFormDataEdci(values: CombinedFormData): values is FormDataEdci {
  return typeof (values as FormDataEdci).companyId !== 'undefined'
}

const RequestQuestionStep: React.FC<RequestQuestionStepProps> = ({
  title,
  fieldnames,
  description,
  children,
  hasSkipButton,
  onClose,
  onBack,
  onSubmitEdci,
  onSubmit,
  disableInitialBack = false,
  generateInvitationLinkFlow = false,
  createGoalTrackerFlow = false,
  isLoading,
  editMode,
}) => {
  const { activeStep, totalSteps } = useCreationModalProgress()
  const { mutateAsync: createRequest, isLoading: isCreateRequestLoading } = useUpdateResource<
    SendRequestResult,
    unknown
  >()
  const { clearQueryFilter } = useFiltersContext()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const { handleSubmit } = useFormContext<CombinedFormData>()
  const queryClient = useQueryClient()
  const classes = useStyles()

  const isLastStep = useMemo(() => activeStep === totalSteps, [activeStep, totalSteps])

  const handleCreateRequestResult = async (result: SendRequestResult, values: CombinedFormData) => {
    if (result.duplicateCount > 0 && result.successCount === 0) {
      showSnackbar({
        message: formatMessage({ id: 'form.createRequest.requestsAlreadyExist' }),
        severity: NotificationSeverity.warning,
        disableAutoClose: true,
      })
      return
    }

    const message =
      result.duplicateCount > 0
        ? formatMessage(
            { id: 'form.createRequest.requestCreatedWithDuplicates' },
            { duplicateCount: result.duplicateCount },
          )
        : formatMessage({ id: 'form.createRequest.requestCreatedInfo' })

    showSnackbar({
      message: message,
      severity: NotificationSeverity.success,
      disableAutoClose: true,
    })

    if (isFormDataEdci(values)) {
      await onSubmitEdci?.(values)
    }

    queryClient.invalidateQueries(FetchKey.Request)
    queryClient.invalidateQueries(FetchKey.InquiryOpenRequests)
    queryClient.invalidateQueries(FetchKey.OverdueRequests)
    queryClient.invalidateQueries(FetchKey.RequestsOverdueWithinAWeek)
    queryClient.invalidateQueries(FetchKey.RequestsOverdueWithinAWeek)
    queryClient.invalidateQueries(FetchKey.InquiryByProvider)
    queryClient.invalidateQueries(FetchKey.SFDRRequests)
    queryClient.invalidateQueries(FetchKey.InquiryStatus)
    onClose()
  }

  const handleFormSubmit = async (values: CombinedFormData) => {
    if (onSubmit) {
      onSubmit()
      clearQueryFilter()
      return
    }
    if (!values.templateIds?.length) {
      showErrorNotification({ requestError: new RequestError(400, null, 'Missing template ids') })
      return
    }

    const { periodEndsAt, periodStartsAt } = calculateStartsAndEndsAtDate({
      dateType: values.dateType,
      datePeriod: values.datePeriod,
      dateYear: values.dateYear,
    })

    const body = {
      templateIds: values.templateIds.map(template => template.id),
      responderProviderIds: [values.responders].flat().map(responder => responder.id),
      periodType: Periods.Yearly,
      periodStartsAt,
      periodEndsAt,
      dueAt: values.deadline,
    }

    await createRequest(
      { url: endpoints.saveResourceInBulk(ResourceTypes.Request), body },
      {
        onSuccess: result => handleCreateRequestResult(result, values),
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const getOverriddenButtonText = () => {
    if (!isLastStep) {
      if (generateInvitationLinkFlow || createGoalTrackerFlow) return 'general.next'
      return undefined
    }

    if (createGoalTrackerFlow) {
      if (editMode) return 'schemas.goalTracker.editGoal'
      return 'schemas.goalTracker.addGoal'
    }

    return generateInvitationLinkFlow ? formatMessage({ id: 'general.done' }) : undefined
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate className={classes.form}>
      <CreationModalStep
        title={title}
        description={description}
        disableInitialBack={disableInitialBack}
        fieldnames={fieldnames}
        onBack={onBack}
        hasSkipButton={hasSkipButton}
        overrideButtonTextId={getOverriddenButtonText()}
        isLoading={isCreateRequestLoading || isLoading}
        widthOverride={isLastStep && !createGoalTrackerFlow ? 900 : undefined}
      >
        {children}
      </CreationModalStep>
    </form>
  )
}

export default RequestQuestionStep
