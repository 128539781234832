import { Stack, Typography } from '@mui/material'
import React from 'react'

import FlagIndicator from '@app/src/components/Flags/FlagIndicator'
import Markdown from '@app/src/components/Markdown'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import RequestItemGuidance from '@app/src/pages/Questionnaire/RequestItemGuidance'
import { ActivationType, ReportFlagRule, ReportFlagType } from '@app/src/types/flags'
import { RequestItem as RequestItemType, ResponseItem } from '@app/src/types/resourceExplorer'

type RequestItemTitleProps = {
  item: RequestItemType
  responseItem?: ResponseItem
  sectionNumber: number
  itemNumber: number
}

const getFlagRules = (
  item: RequestItemType,
  responseItem: ResponseItem | undefined,
  isPreview: boolean,
): ReportFlagRule[] => {
  if (isPreview)
    return item.template?.reportFlagRules?.filter(rule => rule.activationType === ActivationType.Active) ?? []
  if (responseItem?.flag === ReportFlagType.NotSet || !responseItem?.flagRule) return []
  return [responseItem.flagRule]
}

const RequestItemTitle: React.FC<RequestItemTitleProps> = ({ item, responseItem, sectionNumber, itemNumber }) => {
  const { isPreview } = useReport()
  const flagRules = getFlagRules(item, responseItem, isPreview ?? false)

  return (
    <Stack direction="row" pt={4} pb={2} px={4} spacing={2}>
      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle1">{`${sectionNumber}.${itemNumber}`}</Typography>
        {Boolean(flagRules.length) && <FlagIndicator flagRules={flagRules} />}
      </Stack>
      <Stack direction="row" sx={{ flexGrow: 1 }} justifyContent="space-between">
        <Typography variant="subtitle1">
          <Markdown>{item.description}</Markdown>
        </Typography>
        <Stack sx={{ ml: 1 }}>
          <RequestItemGuidance item={item} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default RequestItemTitle
