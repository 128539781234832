import React from 'react'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useIntl } from 'react-intl'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FileDownloadOutlined } from '@mui/icons-material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { useMenuState } from '@app/src/hooks/mui-hooks'

interface DataHubInsightsCardMenuProps {
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
  title: string
  useTitleAsIs?: boolean
}

const downloadURI = (uri: string, name: string) => {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const DataHubInsightsCardMenu: React.FC<DataHubInsightsCardMenuProps> = ({ echartsRef, title, useTitleAsIs }) => {
  const { formatMessage } = useIntl()
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()

  const exportGraph = () => {
    const res = echartsRef?.current?.getEchartsInstance().getDataURL({
      backgroundColor: '#FFFFFF',
      type: 'png',
      pixelRatio: 2,
    })
    if (!res) return

    const charactersThatAreNotLetterNumberOrSpaceRegex = /[^\p{L}\p{N} ]/giu
    const safeFileName = title.replace(charactersThatAreNotLetterNumberOrSpaceRegex, '')
    const fileName = useTitleAsIs
      ? safeFileName
      : formatMessage({ id: 'statistics.dataInsights.exportFileTitle' }, { title: safeFileName })
    downloadURI(res, fileName)
    closeMenu()
  }

  return (
    <>
      <IconButton onClick={openMenu} sx={{ paddingTop: 0.5 }}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={closeMenu}>
        <MenuItem onClick={exportGraph} disabled={!echartsRef?.current}>
          <ListItemIcon>
            <FileDownloadOutlined />
          </ListItemIcon>
          {formatMessage({ id: 'resourceCollections.general.export' })}
        </MenuItem>
      </Menu>
    </>
  )
}

export default DataHubInsightsCardMenu
