import FilterListIcon from '@mui/icons-material/FilterList'
import { Badge, Button } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AllFiltersButtonProps {
  inDrawerFiltersCount: number
  openDrawer: () => void
  height?: number
  disabled?: boolean
}

const AllFiltersButton: React.FC<AllFiltersButtonProps> = ({ inDrawerFiltersCount, openDrawer, height, disabled }) => {
  const { formatMessage } = useIntl()

  return (
    <Badge
      color="secondary"
      overlap="rectangular"
      badgeContent={inDrawerFiltersCount}
      invisible={!inDrawerFiltersCount}
    >
      <Button
        variant="text"
        disabled={disabled}
        onClick={openDrawer}
        style={{ ...(height ? { height: height } : {}) }}
        startIcon={<FilterListIcon />}
      >
        {formatMessage({ id: 'filters.moreFilters' })}
      </Button>
    </Badge>
  )
}

export default AllFiltersButton
