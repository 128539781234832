import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import EmptyState from '@app/src/components/EmptyState'
import LinkButton from '@app/src/components/LinkButton'
import StatusChip from '@app/src/components/StatusChip'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import FinalRiskRatingCell from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState, useMenuState } from '@app/src/hooks/mui-hooks'
import { useProvidersByPendingInquiries } from '@app/src/hooks/providersByPendingInquiries'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { ProviderTabs } from '@app/src/pages/ResourceCollection/Collections/Providers/ProvidersScene'
import { Company } from '@app/src/types/organizations'
import {
  ProviderApprovalStatusValues,
  ProviderFinalRiskRatingValues,
  ProviderPriorityValues,
  ProviderTierValues,
  ProviderUsageValues,
} from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import GolfCourseOutlinedIcon from '@mui/icons-material/GolfCourseOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TablePagination,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'

export const companiesListAllowedFilters = [
  'providerApprovalStatus',
  'finalRiskRating',
  'supplierUsage',
  'tier',
  'priority',
  'ownerUserId',
]

const CompaniesList = () => {
  const { formatMessage } = useIntl()
  const [isOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()
  const [page, setPage] = useState(1)
  const filters = useGetApiQueryFilters(companiesListAllowedFilters)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const history = useHistory()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const [isCreateDialogOpen, setCreateDialogOpen, closeCreateDialog] = useDialogState()
  const { providersWithPendingInquiries, providersWithOverdueInquiries, providersWithInquiriesOverdueWithinAWeek } =
    useProvidersByPendingInquiries()

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const { items: companies, count } = useFetchCollectionWithPost<Company>({
    key: [FetchKey.Organization, FetchKey.Company, 'statistics'],
    endpoint: endpoints.providersCollection,
    payload: {
      filter: filters,
      include: ['categoryOptions', 'organization'],
      pagination: {
        pageNumber: page,
        itemsPerPage: rowsPerPage,
      },
    },
  })

  const data = companies.map(company => ({
    ...company,
    pendingRequests: providersWithPendingInquiries?.find(inquiry => inquiry.value === company.id)?.count || 0,
    overdueRequests: providersWithOverdueInquiries?.find(inquiry => inquiry.value === company.id)?.count || 0,
    dueSoonRequests:
      providersWithInquiriesOverdueWithinAWeek?.find(inquiry => inquiry.value === company.id)?.count || 0,
  }))

  const emptyState = !data.length && !filters.length
  const noResults = !data.length && Boolean(filters.length)

  return (
    <>
      {emptyState ? (
        <Box display="flex" justifyContent="center" alignItems="center" py={8}>
          <EmptyState
            title={formatMessage({ id: `dashboard.sourcing.companyStatistics.emptyState.title` })}
            description={formatMessage({ id: `dashboard.sourcing.companyStatistics.emptyState.description` })}
            iconComponent={GolfCourseOutlinedIcon}
          >
            <LinkButton to={generatePath(paths.providers, { view: ProviderTabs.All })} variant="contained">
              {formatMessage({ id: `dashboard.sourcing.companyStatistics.emptyState.action` })}
            </LinkButton>
          </EmptyState>
        </Box>
      ) : (
        <>
          <Grid container mt={4}>
            <Grid item xs={3.5}>
              <Typography variant="subtitle1" color="textSecondary" fontWeight={500}>
                {formatMessage({ id: `dashboard.sourcing.companyStatistics.listHeaders.companies` })}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" color="textSecondary" fontWeight={500}>
                {formatMessage({ id: `dashboard.sourcing.companyStatistics.listHeaders.categories` })}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" color="textSecondary" fontWeight={500}>
                {formatMessage({ id: `dashboard.sourcing.companyStatistics.listHeaders.finalRiskRating` })}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" color="textSecondary" fontWeight={500}>
                {formatMessage({ id: `dashboard.sourcing.companyStatistics.listHeaders.pendingRequests` })}
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography variant="subtitle1" color="textSecondary" fontWeight={500}>
                {formatMessage({ id: `dashboard.sourcing.companyStatistics.listHeaders.actions` })}
              </Typography>
            </Grid>
          </Grid>
          {noResults ? (
            <Box display="flex" justifyContent="center" alignItems="center" py={8}>
              <EmptyState
                title={formatMessage({ id: `dashboard.sourcing.companyStatistics.noResults.title` })}
                description={formatMessage({ id: `dashboard.sourcing.companyStatistics.noResults.description` })}
                iconComponent={GolfCourseOutlinedIcon}
              />
            </Box>
          ) : (
            data.map(company => (
              <React.Fragment key={company.id}>
                <Box py={2}>
                  <Grid container>
                    <Grid item xs={3.5} display="flex" alignItems="center">
                      <CompanyCell company={company} disableCell drawer sx={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={3} display="flex" alignItems="center" flexWrap="wrap">
                      {company.priority !== ProviderPriorityValues.NotSelected && (
                        <Box mr={1} my={0.5}>
                          <Chip
                            label={formatMessage({
                              id: `schemas.provider.priorityValues.${company.priority}`,
                            })}
                            size="small"
                          />
                        </Box>
                      )}
                      {company.tier !== ProviderTierValues.NotSelected && (
                        <Box mr={1} my={0.5}>
                          <Chip
                            label={formatMessage({ id: 'schemas.provider.tierValues' }, { tier: company.tier })}
                            size="small"
                          />
                        </Box>
                      )}
                      {company.supplierUsage !== ProviderUsageValues.NotSelected && (
                        <Box mr={1} my={0.5}>
                          <Chip
                            label={formatMessage({
                              id: `schemas.provider.supplierUsageValues.${company.supplierUsage}`,
                            })}
                            size="small"
                          />
                        </Box>
                      )}
                      {company.providerApprovalStatus !== ProviderApprovalStatusValues.NotSelected && (
                        <Box mr={1} my={0.5}>
                          <Chip
                            label={formatMessage({
                              id: `schemas.provider.providerApprovalStatusValues.${company.providerApprovalStatus}`,
                            })}
                            size="small"
                          />
                        </Box>
                      )}
                      {company.categoryOptions?.map(category => (
                        <Box key={category.id} mr={1} my={0.5}>
                          <Chip label={category.name} size="small" />
                        </Box>
                      ))}
                    </Grid>
                    <Grid item xs={2} display="flex" alignItems="center">
                      {company.finalRiskRating !== ProviderFinalRiskRatingValues.NotSelected && (
                        <FinalRiskRatingCell value={company.finalRiskRating} disableCell />
                      )}
                    </Grid>
                    <Grid item xs={3} display="flex" alignItems="center">
                      <Stack direction="row" flexWrap="wrap">
                        {company.overdueRequests > 0 && (
                          <Box mr={1} my={0.5}>
                            <StatusChip
                              label={formatMessage(
                                { id: `dashboard.sourcing.companyStatistics.requestsOverdue` },
                                { count: company.overdueRequests },
                              )}
                              size="small"
                              color="error"
                            />
                          </Box>
                        )}
                        {company.dueSoonRequests > 0 && (
                          <Box mr={1} my={0.5}>
                            <StatusChip
                              label={formatMessage(
                                { id: `dashboard.sourcing.companyStatistics.requestsDueSoon` },
                                { count: company.dueSoonRequests },
                              )}
                              size="small"
                              color="warning"
                            />
                          </Box>
                        )}
                        {company.pendingRequests - company.overdueRequests - company.dueSoonRequests > 0 && (
                          <Box mr={1} my={0.5}>
                            <StatusChip
                              label={formatMessage(
                                { id: `dashboard.sourcing.companyStatistics.pendingRequests` },
                                { count: company.pendingRequests - company.overdueRequests - company.dueSoonRequests },
                              )}
                              size="small"
                            />
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={0.5} display="flex" alignItems="center" justifyContent="flex-end">
                      <IconButton
                        onClick={e => {
                          setSelectedCompany(company)
                          openMenu(e)
                        }}
                        size="small"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </React.Fragment>
            ))
          )}
          <TablePagination
            sx={{ width: '100vw' }}
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="span"
          />
        </>
      )}

      <Menu anchorEl={menuAnchorElement} open={isOpen} onClose={closeMenu} elevation={8}>
        <MenuItem
          onClick={() =>
            history.push(
              stringifyQueryFilters({
                url: generatePath(paths.providers, { view: ProviderTabs.All }),
                queryParams: {
                  filters: [],
                },
                includeCurrentFilters: true,
              }),
              {
                fromDashboardCategorizeButton: true,
                selectedCompany: {
                  providerId: selectedCompany?.id,
                  activityStatus: selectedCompany?.activityStatus,
                  finalRiskRating: selectedCompany?.finalRiskRating,
                  priority: selectedCompany?.priority,
                  providerApprovalStatus: selectedCompany?.providerApprovalStatus,
                  categoryOptionIds: selectedCompany?.categoryOptions?.map(catOpt => catOpt.id),
                  ownerUserId: selectedCompany?.ownerUserId,
                  tier: selectedCompany?.tier,
                  companyUsage: selectedCompany?.supplierUsage,
                },
              },
            )
          }
        >
          <ListItemIcon>
            <LocalOfferOutlinedIcon />
          </ListItemIcon>
          <Typography variant="body1">
            {formatMessage({ id: `dashboard.sourcing.companyStatistics.listHeaders.categorize` })}
          </Typography>
        </MenuItem>
        <MenuItem onClick={setCreateDialogOpen}>
          <ListItemIcon>
            <SendOutlinedIcon />
          </ListItemIcon>
          <Typography variant="body1">
            {formatMessage({ id: `dashboard.sourcing.companyStatistics.listHeaders.sendRequest` })}
          </Typography>
        </MenuItem>
      </Menu>
      <CreationModalProgressContextProvider>
        <CreateNewRequestModal open={isCreateDialogOpen} onClose={closeCreateDialog} />
      </CreationModalProgressContextProvider>
    </>
  )
}

export default CompaniesList
