import { Components } from '@mui/material'

const MuiInputBase: Components['MuiInputBase'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      '&.MuiInput-root:before': {
        border: 'none',
        content: 'unset',
      },
      '&.MuiInput-root:after': {
        border: 'none',
        content: 'unset',
      },
    },
  },
}

export default MuiInputBase
