import DateCell from '@app/src/components/Table/Cells/DateCell'
import LinkCell from '@app/src/components/Table/Cells/LinkCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import PublishedStatusChip from '@app/src/pages/TemplateBuilder/PublishedStatusChip'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { generatePath } from 'react-router'
import AssessmentTemplateMoreMenuCell from './AssessmentTemplateMoreMenuCell'

interface AssessmentTemplateRowProps {
  row: AssessmentTemplate
}

export const AssessmentTemplateRow: React.FC<AssessmentTemplateRowProps> = ({ row: assessment }) => {
  return (
    <TableRow>
      <LinkCell
        label={assessment.name}
        to={generatePath(paths.sustainabilityLibrary, {
          configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
          configurationsSubPage: assessment.id,
          activeTabParam: 'edit',
        })}
      />
      <ValueCell value={assessment.description} />
      <TableCell>
        <PublishedStatusChip isPublished={Boolean(assessment.isPublished)} />
      </TableCell>
      <DateCell value={assessment.createdAt} />
      <AssessmentTemplateMoreMenuCell assessmentTemplate={assessment} />
    </TableRow>
  )
}

export default AssessmentTemplateRow
