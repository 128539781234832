import { palette } from '@app/src/theme/palette'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import AssessmentMethodologyPieChart from './AssessmentMethodologyPieChart'

interface AssessmentMethodologyCardProps {
  assessmentTemplate: AssessmentTemplate
}

const AssessmentMethodologyCard: React.FC<AssessmentMethodologyCardProps> = ({ assessmentTemplate }) => {
  const levels = assessmentTemplate?.levels ?? 5
  const { formatMessage } = useIntl()

  const colorSchemes = {
    3: [
      { background: palette.error.light },
      { background: 'rgba(255, 199.10, 115.81, 0.30)' },
      { background: 'rgba(189, 229, 203, 0.30)' },
    ],
    5: [
      { background: 'rgba(158, 2, 2, 0.20)' },
      { background: palette.error.light },
      { background: 'rgba(255, 199.10, 115.81, 0.30)' },
      { background: 'rgba(189, 229, 203, 0.30)' },
      { background: 'rgba(79, 141, 115, 0.30)' },
    ],
  }

  const getLevelDescription = (level: number) => {
    switch (level) {
      case 1:
        return assessmentTemplate.level1Description
      case 2:
        return assessmentTemplate.level2Description
      case 3:
        return assessmentTemplate.level3Description
      case 4:
        return assessmentTemplate.level4Description
      case 5:
        return assessmentTemplate.level5Description
    }
  }

  return (
    <Grid container spacing={1} sx={{ mb: 4 }}>
      {Array.from({ length: levels }).map((_, index) => (
        <Grid item xs={12} md={levels === 3 ? 4 : 2.4} key={`level-${index}`}>
          <Box
            width="100%"
            height="100%"
            pt={3}
            pb={4}
            px={3}
            bgcolor={colorSchemes[levels as 3 | 5][index].background}
            borderRadius={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={1}
          >
            <Box width={40} height={40} display="flex" justifyContent="center" alignItems="center" mb={1}>
              <AssessmentMethodologyPieChart assessmentTemplate={assessmentTemplate} level={index + 1} />
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: 18,
                fontWeight: 700,
                lineHeight: '24px',
                mb: 1,
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              {formatMessage({ id: `assessments.levels.${levels}.level${index + 1}` })}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: 12,
                lineHeight: '24px',
                mb: 1,
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              {formatMessage({ id: `assessments.methodology.levels.${levels}.level${index + 1}Percentage` })}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: 14,
                lineHeight: '24px',
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              {getLevelDescription(index + 1)}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default AssessmentMethodologyCard
