import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const ReviewItem = ({ label, value }: { label: string; value?: string | number | JSX.Element }): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <Grid item key={label} container xs={4}>
      <Grid item xs={12}>
        <Typography variant="caption">{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{value || formatMessage({ id: 'general.none' })}</Typography>
      </Grid>
    </Grid>
  )
}

export default ReviewItem
