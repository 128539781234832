import { alpha, Box, ListItem, ListItemText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'

const useStyles = makeStyles(theme => ({
  listItem: {
    background: theme.palette.mode === 'light' ? theme.palette.grey[50] : alpha(theme.palette.grey[500], 0.1),
    margin: theme.spacing(2, 0),
    borderRadius: theme.spacing(1),
  },
  hover: {
    '& #button:last-child': { display: 'none' },
    '&:hover #button:last-child': { display: 'inline' },
  },
}))

export type ProviderListCardProps = {
  provider: Provider
  onClickProvider: (provider: Provider) => void
}

const ProviderListCard = ({ provider, onClickProvider }: ProviderListCardProps): JSX.Element => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <ListItem
      {...(!provider.alreadyLinkedOrganization ? ({ button: true } as { button: true }) : ({} as {}))} // doing this madness because of the following issue: https://github.com/mui/material-ui/issues/14971
      className={classes.listItem}
      onClick={provider.alreadyLinkedOrganization ? undefined : () => onClickProvider(provider)}
    >
      <ListItemText
        className={classnames({ [classes.hover]: !provider.alreadyLinkedOrganization })}
        primary={
          <Box display="flex" justifyContent="space-between" data-testid="provider-list-card-primary">
            <Typography variant="subtitle1" noWrap>
              {provider.name}
            </Typography>
            {provider.alreadyLinkedOrganization && (
              <Typography variant="caption">
                {formatMessage({ id: 'resourceCollections.create.alreadyAdded' })}
              </Typography>
            )}
            {!provider.alreadyLinkedOrganization && (
              <Typography variant="caption" id="button">
                {formatMessage({ id: 'general.select' })}
              </Typography>
            )}
          </Box>
        }
        secondary={
          <Box data-testid="provider-list-card-secondary">
            <Box>
              <Typography variant="caption">{provider.registrationNumber}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="caption">{provider.websiteAddress}</Typography>
              <Typography variant="caption">・</Typography>
              <Typography variant="caption" data-testid="provider-list-card-country">
                {provider?.country?.name}
              </Typography>
            </Box>
          </Box>
        }
        data-testid="listItem"
      />
    </ListItem>
  )
}

export default ProviderListCard
