import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import ComponentAvatar from '@app/src/components/Ui/Avatar'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Provider } from '@app/src/types/organizations'
import { Contact } from '@app/src/types/resourceExplorer'
import { ChevronRight, PersonOffOutlined } from '@mui/icons-material'
import {
  AvatarGroup,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import PersonAvatar from '../pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { useDrawer } from './Drawer/DrawerContext'
import DrawerViewUser from './Drawer/Views/DrawerViewUser'
import DrawerViewUserGroup from './Drawer/Views/DrawerViewUserGroup'
import ListItemSkeleton from './Skeleton/ListItem'

interface Props {
  provider: Provider
}

const ProviderContactCard: React.FC<Props> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const { items: contacts, isLoading } = useFetchCollectionWithPost<Contact>({
    key: FetchKey.Contact,
    endpoint: endpoints.contactsCollection,
    payload: {
      filter: [
        {
          name: 'creatorOrganizationId',
          filters: [{ operator: Operators.EqualTo, value: provider.organizationId || provider.id }],
        },
      ],
      include: ['user'],
      sort: {
        order: SortOrder.ASCENDING,
        target: 'user.name',
      },
    },
    options: { enabled: provider.linked },
  })

  if (isLoading) {
    return (
      <>
        <Typography variant="subtitle1" color="text.secondary" ml={2}>
          {formatMessage({ id: 'schemas.provider.contacts' })}
        </Typography>
        <ListItemSkeleton avatar avatarProps={{ variant: 'circular' }} primary secondary />
      </>
    )
  }

  if (!contacts.length) {
    return (
      <>
        <Typography variant="subtitle1" color="text.secondary" ml={2}>
          {formatMessage({ id: 'schemas.provider.contacts' })}
        </Typography>

        <ListItem>
          <ListItemAvatar>
            <ComponentAvatar variant="circular">
              <PersonOffOutlined />
            </ComponentAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'schemas.contact.information' })}
            secondary={formatMessage({ id: 'schemas.contact.notAddedBy' }, { name: provider.name })}
          />
        </ListItem>
      </>
    )
  }

  if (contacts.length === 1) {
    return (
      <Stack px={2}>
        <Typography variant="subtitle1" color="text.secondary">
          {formatMessage({ id: 'schemas.provider.contacts' })}
        </Typography>

        <ListItem disablePadding sx={{ mt: 2 }}>
          <ListItemButton onClick={() => openDrawer(<DrawerViewUser user={contacts[0].user} />)} dense disableGutters>
            <ListItemAvatar sx={{ minWidth: 'unset', mr: 2 }}>
              <PersonAvatar name={contacts[0].user.name ?? ''} />
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: 'schemas.provider.contact' })}
              secondary={contacts[0].user.name}
            />
            <IconButton>
              <ChevronRight />
            </IconButton>
          </ListItemButton>
        </ListItem>
      </Stack>
    )
  }

  return (
    <Stack px={2}>
      <Typography variant="subtitle1" color="text.secondary">
        {formatMessage({ id: 'schemas.provider.contacts' })}
      </Typography>

      <ListItem disablePadding sx={{ mt: 2 }}>
        <ListItemButton
          onClick={() => openDrawer(<DrawerViewUserGroup users={contacts.map(contact => contact.user)} />)}
          dense
          disableGutters
        >
          <ListItemAvatar sx={{ minWidth: 'unset', mr: 2 }}>
            <AvatarGroup max={2}>
              {contacts.map(contact => (
                <PersonAvatar key={contact.id} name={contact.user.name ?? ''} />
              ))}
            </AvatarGroup>
          </ListItemAvatar>
          <ListItemText
            primary={formatMessage({ id: 'general.contacts' }, { count: contacts.length ?? 0 })}
            secondary={formatMessage({ id: 'general.viewAll' })}
          />
          <IconButton>
            <ChevronRight />
          </IconButton>
        </ListItemButton>
      </ListItem>
    </Stack>
  )
}

export default ProviderContactCard
