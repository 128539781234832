import React from 'react'
import TagsCell from '@app/src/components/Table/Cells/TagsCell'
import { QuestionCategorization } from '@app/src/types/resourceExplorer'

export type QuestionCategoryCellProps = {
  categorizations: QuestionCategorization[]
  categoryName: string
}
const QuestionCategoryCell: React.FC<QuestionCategoryCellProps> = ({ categorizations, categoryName }) => {
  const categoryValues = categorizations.find(c => c.categoryName === categoryName)?.values.map(v => v.name) ?? []
  return <TagsCell tags={categoryValues} amountOfTagsShow={3} />
}

export default QuestionCategoryCell
