import { Button, ButtonProps } from '@mui/material'
import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

export type LinkButtonProps = ButtonProps & RouterLinkProps

const LinkButton = ({ to, ...props }: LinkButtonProps): JSX.Element => (
  <Button component={RouterLink} to={to} {...props}>
    {props.children}
  </Button>
)

export default LinkButton
