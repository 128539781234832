import { alpha, Box, BoxProps, ButtonBase, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'

type ProviderOptionBoxProps = {
  title: JSX.Element | string | null
  icon?: JSX.Element | null
  description?: JSX.Element | string
  hasBackground?: boolean
  onClick: () => void
  small?: boolean
} & Omit<BoxProps, 'title'>

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 2,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.mode === 'light' ? '#F7F2EF' : alpha(theme.palette.grey[500], 0.1),
  },
  hover: {
    '&:hover': {
      background: theme.palette.mode === 'light' ? '#F2EAE4' : alpha(theme.palette.grey[500], 0.2),
    },
  },
  small: {
    flexGrow: 1,
  },
}))

const OptionBox = ({
  title = null,
  icon = null,
  description,
  onClick,
  small,
  ...props
}: ProviderOptionBoxProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Box
      onClick={onClick}
      component={ButtonBase}
      {...props}
      className={classNames(classes.box, classes.hover, { [classes.small]: small })}
    >
      {icon}
      {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
      {description && <Typography variant="caption">{description}</Typography>}
    </Box>
  )
}

export default OptionBox
