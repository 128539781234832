import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { getDateInDays } from '@app/src/utils'

interface UseProvidersByPendingInquiries {
  providersWithPendingInquiries: FacetItem[]
  providersWithPendingInquiriesCount: number
  providersWithInquiriesWithNoDeadline: FacetItem[]
  providersWithInquiriesWithNoDeadlineCount: number
  providersWithOverdueInquiries: FacetItem[]
  providersWithOverdueInquiriesCount: number
  providersWithInquiriesOverdueWithinAWeek: FacetItem[]
  providersWithInquiriesOverdueWithinAWeekCount: number
  providersWithInquiriesOverdueWithinOverAWeek: FacetItem[]
  providersWithInquiriesOverdueWithinOverAWeekCount: number
  isLoading: boolean
}

export const useProvidersByPendingInquiries = (additionalFilters?: FilterGroup[]): UseProvidersByPendingInquiries => {
  const today = new Date()
  const inOneWeek = getDateInDays(7)
  const formattedTodaysDate = formatDate(today)?.split(' ')?.[0] ?? ''
  const formattedWithinAWeeksDate = formatDate(inOneWeek)?.split(' ')?.[0] ?? ''

  const {
    facets: [providersWithPendingInquiries],
    count: providersWithPendingInquiriesCount,
    isLoading: providersWithPendingInquiriesIsLoading,
  } = useFetchFacets({
    key: FetchKey.InquiryByProvider,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
      ...(additionalFilters || []),
    ],
  })

  const {
    facets: [providersWithInquiriesWithNoDeadline],
    count: providersWithInquiriesWithNoDeadlineCount,
    isLoading: providersWithInquiriesWithNoDeadlineIsLoading,
  } = useFetchFacets({
    key: FetchKey.OverdueRequests,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'deadline',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
      {
        name: 'deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
      ...(additionalFilters || []),
    ],
  })

  const {
    facets: [providersWithOverdueInquiries],
    count: providersWithOverdueInquiriesCount,
    isLoading: providersWithOverdueInquiriesIsLoading,
  } = useFetchFacets({
    key: FetchKey.OverdueRequests,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'deadline',
        filters: [
          {
            value: formattedTodaysDate,
            operator: Operators.LowerThan,
          },
        ],
      },
      {
        name: 'deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
      ...(additionalFilters || []),
    ],
  })

  const {
    facets: [providersWithInquiriesOverdueWithinAWeek],
    count: providersWithInquiriesOverdueWithinAWeekCount,
    isLoading: providersWithInquiriesOverdueWithinAWeekIsLoading,
  } = useFetchFacets({
    key: FetchKey.RequestsOverdueWithinAWeek,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'deadline',
        filters: [
          {
            value: formattedWithinAWeeksDate,
            operator: Operators.LowerThanOrEqual,
          },
        ],
      },
      {
        name: 'deadline',
        filters: [
          {
            value: formattedTodaysDate,
            operator: Operators.GreaterThanOrEqual,
          },
        ],
      },
      {
        name: 'deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
      ...(additionalFilters || []),
    ],
  })

  const {
    facets: [providersWithInquiriesOverdueWithinOverAWeek],
    count: providersWithInquiriesOverdueWithinOverAWeekCount,
    isLoading: providersWithInquiriesOverdueWithinOverAWeekIsLoading,
  } = useFetchFacets({
    key: FetchKey.RequestsOverdueWithinOverAWeek,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
            operator: Operators.In,
          },
        ],
      },
      {
        name: 'deadline',
        filters: [
          {
            value: formattedWithinAWeeksDate,
            operator: Operators.GreaterThan,
          },
        ],
      },
      {
        name: 'deletedAt',
        filters: [
          {
            operator: Operators.IsNull,
          },
        ],
      },
      ...(additionalFilters || []),
    ],
  })

  const isLoading =
    providersWithInquiriesWithNoDeadlineIsLoading ||
    providersWithOverdueInquiriesIsLoading ||
    providersWithInquiriesOverdueWithinAWeekIsLoading ||
    providersWithInquiriesOverdueWithinOverAWeekIsLoading ||
    providersWithPendingInquiriesIsLoading

  return {
    providersWithPendingInquiries,
    providersWithPendingInquiriesCount,
    providersWithInquiriesWithNoDeadline,
    providersWithInquiriesWithNoDeadlineCount,
    providersWithOverdueInquiries,
    providersWithOverdueInquiriesCount,
    providersWithInquiriesOverdueWithinAWeek,
    providersWithInquiriesOverdueWithinAWeekCount,
    providersWithInquiriesOverdueWithinOverAWeek,
    providersWithInquiriesOverdueWithinOverAWeekCount,
    isLoading,
  }
}
