import { Box, Divider } from '@mui/material'
import React from 'react'

import SectionSkeleton from '@app/src/components/Skeleton/Section'
import ReportTranslation from '@app/src/pages/Questionnaire/ReportTranslation'
import ReportingDescription from '@app/src/pages/Questionnaire/ReportingDescription'
import DownloadResponse from '../../DownloadResponse'
import { useReport } from '../../ReportContext'
import Section from './Section'

const Report: React.FC = () => {
  const { request, isRequestLoading } = useReport()

  return (
    <Box bgcolor="common.white" py={3} px={5} borderRadius={4}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <ReportTranslation />
        <DownloadResponse />
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Box mb={5}>
        <ReportingDescription />
      </Box>

      {isRequestLoading ? (
        <SectionSkeleton howMany={2} />
      ) : (
        request?.sections?.map((section, index) => (
          <Section key={section?.id ?? index} section={section} sectionNumber={index + 1} />
        ))
      )}
    </Box>
  )
}

export default Report
