import { Typography, TypographyProps } from '@mui/material'
import DateDisplay, { DateDisplayProps } from '@app/src/components/DateDisplay'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'

type ValueCellProps = {
  value?: DateDisplayProps['value']
  withTime?: DateDisplayProps['withTime']
  disableCell?: boolean
} & TypographyProps

const DateCell: React.FC<ValueCellProps> = ({ value, disableCell, withTime, ...props }) => (
  <ParentCellOrFragment disableCell={disableCell}>
    <Typography variant="body1" {...props}>
      {value ? <DateDisplay value={value} withTime={withTime} /> : '-'}
    </Typography>
  </ParentCellOrFragment>
)

export default DateCell
