import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import React from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'
import { User } from '@app/src/types/resourceExplorer'
import { Operators } from '../Filters/useFilters'
import ResourceCollectionScene, { ResourceCollectionSceneProps } from '../ResourceCollectionScene'
import ProviderPublicContactHeader from './ProviderPublicContact/ProviderPublicContactHeader'
import ProviderPublicContactRow from './ProviderPublicContact/ProviderPublicContactRow'

type ProviderPublicContactCollectionProps = {
  providerId?: string
  tabs?: ResourceCollectionSceneProps['tabs']
}

const ProviderPublicContactCollection: React.FC<ProviderPublicContactCollectionProps> = ({ providerId, tabs }) => {
  const { formatMessage } = useIntl()
  const [page, pageSize, setPage, setPageSize] = usePagination()

  const { items, isLoading, isError } = useFetchCollectionWithPost<Provider>({
    key: [FetchKey.Provider, providerId],
    endpoint: endpoints.providersCollection,
    payload: {
      filter: [
        {
          filters: [
            {
              operator: Operators.EqualTo,
              value: providerId,
            },
          ],
          name: 'id',
        },
      ],
      include: ['organization.contacts.user'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  const providerContacts = items[0]?.organization?.contacts?.map(contact => contact.user) ?? []

  return (
    <ResourceCollectionScene title={formatMessage({ id: 'configurations.suggested-contacts' })} embedded tabs={tabs}>
      <Table<User>
        RowComponent={ProviderPublicContactRow}
        HeaderComponent={ProviderPublicContactHeader}
        count={providerContacts.length}
        data={providerContacts}
        isLoading={isLoading}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollectionScene>
  )
}

export default ProviderPublicContactCollection
