import { Box, FormControlLabel, Paper, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import TextField from '@app/src/components/Ui/TextField'
import { AccountContextProps, NavbarUser } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { br } from '@app/src/utils/translationMarkup'
import { ExternalLinks, Logos, NotificationSeverity, ResourceTypes, Solutions } from '@app/src/wf-constants'

interface Props {
  account: AccountContextProps['account']
}

enum FormFields {
  givenName = 'givenName',
  familyName = 'familyName',
  position = 'position',
}

const NameAndTAndCDialog: React.FC<Props> = ({ account }) => {
  const { formatMessage } = useIntl()
  const { register, errors, setValue, handleSubmit, setError } = useForm<{
    [FormFields.givenName]: string
    [FormFields.familyName]: string
    [FormFields.position]: string
  }>({
    defaultValues: account?.user,
  })
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { mutate, isLoading: isSubmitting } = useUpdateResource<Partial<NavbarUser>>()
  const queryClient = useQueryClient()
  const user = account?.user
  const [hasAcceptedTAndC, setHasAcceptedTAndC] = useState(false)

  const handleUserSettingsSubmit = async (values: {
    [key: string]: Partial<NavbarUser>
  }): Promise<void | JSX.Element> => {
    const editedUser = { ...(user || {}), ...values, hasAcceptedTAndC } //the backend needs the whole user object along with the edited values

    mutate(
      { url: endpoints.saveResource(Solutions.Resources, ResourceTypes.User), body: editedUser },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([FetchKey.Account])
          showSnackbar({
            message: formatMessage({ id: 'userSettings.settingsUpdated' }),
            severity: NotificationSeverity.success,
          })
        },
        onError: error => {
          showErrorNotification({ requestError: error })
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
          }
        },
      },
    )
  }

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(handleUserSettingsSubmit)}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Box
        component={Paper}
        elevation={0}
        maxWidth={400}
        p={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Avatar
          src={Logos.WorldfavorLogoSquareFilledBlack}
          size={AvatarSize.XL}
          sx={{ backgroundColor: 'common.white', marginBottom: 4 }}
        />

        <Typography mb={2} component="h1" variant="h5" textAlign="center">
          {formatMessage({ id: 'TAndCDialog.title' })}
        </Typography>

        <Typography mb={4} variant="body1" textAlign="center">
          {formatMessage({ id: 'TAndCDialog.description' })}
        </Typography>

        <Box mb={2} width="100%">
          <TextField
            fullWidth
            inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
            required
            name={FormFields.givenName}
            label={formatMessage({ id: `schemas.user.${FormFields.givenName}` })}
            error={Boolean(errors?.givenName)}
            type="text"
            onClear={(): void => setValue(FormFields.givenName, '')}
            helperText={errors?.givenName?.message}
          />
        </Box>

        <Box mb={4} width="100%">
          <TextField
            fullWidth
            inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
            required
            name={FormFields.familyName}
            label={formatMessage({ id: `schemas.user.${FormFields.familyName}` })}
            error={Boolean(errors?.familyName)}
            type="text"
            onClear={(): void => setValue(FormFields.familyName, '')}
            helperText={errors?.familyName?.message}
          />
        </Box>
        <Box mb={4} width="100%">
          <TextField
            fullWidth
            inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
            required
            name={FormFields.position}
            label={formatMessage({ id: `schemas.user.${FormFields.position}` })}
            error={Boolean(errors?.position)}
            type="text"
            onClear={(): void => setValue(FormFields.position, '')}
            helperText={errors?.position?.message}
          />
        </Box>
        <Box mb={4} width="100%">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="tAndCAccepted"
                checked={hasAcceptedTAndC}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHasAcceptedTAndC(event.target.checked)
                }}
              />
            }
            label={
              <Typography>
                {formatMessage(
                  { id: 'TAndCDialog.checkboxDescription' },
                  {
                    br,
                    tAndCLink: (
                      <a rel="noreferrer noopener" href={ExternalLinks.TermsAndConditions} target="_blank">
                        {formatMessage({ id: 'TAndCDialog.tAndC' })}
                      </a>
                    ),
                    privacyPolicyLink: (
                      <a rel="noreferrer noopener" href={ExternalLinks.PrivacyPolicy} target="_blank">
                        {formatMessage({ id: 'TAndCDialog.privacyPolicy' })}
                      </a>
                    ),
                  },
                )}
              </Typography>
            }
          />
        </Box>

        <Box marginBottom={2} display="flex" width="100%" justifyContent="flex-end">
          <LoadingButton
            fullWidth
            variant="contained"
            disabled={!hasAcceptedTAndC}
            type="submit"
            loading={isSubmitting}
            size="large"
          >
            {formatMessage({ id: 'general.confirm' })}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  )
}
export default NameAndTAndCDialog
