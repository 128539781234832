import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { RequestItem } from '@app/src/types/resourceExplorer'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ResponseItemContext from './ResponseItemContext'

export enum ResponseItemView {
  ShowSuggestedAnswer = 'ShowSuggestedAnswer',
  SuggestedAnswerAccepted = 'SuggestedAnswerAccepted',
  SkipQuestion = 'SkipQuestion',
}

interface Props {
  children: JSX.Element
  requestItem: RequestItem
  cannotAnswer: boolean
  sectionNumber: number
  itemNumber: number
}

const ResponseItemContextProvider = ({
  children,
  requestItem,
  cannotAnswer,
  sectionNumber,
  itemNumber,
}: Props): JSX.Element => {
  const [responseItemView, setResponseItemView] = useState<ResponseItemView>(ResponseItemView.ShowSuggestedAnswer)
  const { previouslyRespondedQuestions } = useReport()
  const { setValue } = useFormContext()

  const previousAnswer = previouslyRespondedQuestions?.find(
    item => item.requestItem.questionId === requestItem.questionId,
  )

  const setQuestionSkippedFields = () => {
    if (!previousAnswer) return
    setValue(`${requestItem.id}.cannotAnswer`, true)
    setValue(`${requestItem.id}.planOnHaving`, Boolean(previousAnswer.estimatedAnswerAt))
    setValue(`${requestItem.id}.estimatedAnswerAt`, previousAnswer.estimatedAnswerAt)
    setValue(`${requestItem.id}.answerStatus`, previousAnswer.answerStatus)
    setValue(`${requestItem.id}.comment`, previousAnswer.comment)
  }

  return (
    <ResponseItemContext.Provider
      value={{
        responseItemView: responseItemView,
        setResponseItemView: setResponseItemView,
        previousAnswer,
        hideInputField: cannotAnswer,
        setQuestionSkippedFields,
        sectionNumber: sectionNumber,
        itemNumber: itemNumber,
      }}
    >
      {children}
    </ResponseItemContext.Provider>
  )
}

export default ResponseItemContextProvider
