import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import Table from '@app/src/components/Table'
import { ResponseExportColumnsTransparency } from '@app/src/export-columns/response'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import ProviderResponseHeader from '@app/src/pages/ResourceCollection/Collections/Response/ProviderResponseHeader'
import ProviderResponseRow from '@app/src/pages/ResourceCollection/Collections/Response/ProviderResponseRow'
import ProviderResponsesFilters from '@app/src/pages/ResourceCollection/Filters/ProviderResponsesFilters'
import { FilterGroup, RESPONSE_LATEST_SUBMITTED_FILTER } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { Response } from '@app/src/types/resourceExplorer'
import { ResourceTypes } from '@app/src/wf-constants'
import React from 'react'
import { useIntl } from 'react-intl'
import { ViewTypeName } from './ManageRequestsScene'

interface ProviderResponseSceneProps {
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
  relatedContentFilter?: FilterGroup[]
}

const allowedFilters = [
  'request.template.id',
  'request.subscriptions.creatorOrganization.name',
  'draftStatus',
  'request.creatorOrganizationId',
  'externalVerificationStatus',
  'externalVerificationComment',
  'submittedAt',
  'request.subscriptions.dueAt',
  'request.creatorUserId',
]

const ProviderResponseScene: React.FC<ProviderResponseSceneProps> = ({
  tabs,
  actionButtons = [],
  relatedContentFilter = [],
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const { openDrawer } = useDrawer()

  const basePayload = {
    filter: [...relatedContentFilter, RESPONSE_LATEST_SUBMITTED_FILTER],
    sort: sorting,
    include: [
      'creatorOrganization',
      'request.creatorOrganization',
      'request.responderOrganization',
      'request.template',
      'request.template.image',
      'request.target',
      'request.subscriptions.creatorOrganization',
      'verifications',
    ],
  }

  const payload = {
    ...basePayload,
    include: [...basePayload.include],
    filter: [...basePayload.filter, ...apiFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Response>({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCollection,
    payload,
  })

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'navbar.manageRequests' })}
      tabs={tabs}
      actionButtons={[
        {
          label: formatMessage({ id: 'resourceCollections.general.export' }),
          variant: 'outlined',
          onClick: () =>
            openDrawer(
              <DrawerViewExport
                resourceType={ResourceTypes.Response}
                count={count}
                userFilter={apiFilters}
                exportColumns={ResponseExportColumnsTransparency}
                rawExportPayload={basePayload}
                onlyLatest
              />,
            ),
          disabled: tabs?.activeTabParam === ViewTypeName.Overview || isLoading || count === 0,
        },
        ...actionButtons,
      ]}
      filter={
        <ProviderResponsesFilters
          implicitFilters={relatedContentFilter}
          allowedFilters={allowedFilters}
          resetPage={resetPage}
        />
      }
    >
      <Table<Response>
        RowComponent={({ row }) => <ProviderResponseRow row={row} />}
        HeaderComponent={() => <ProviderResponseHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
        data={items}
        isLoading={isLoading || isFetching}
        count={count}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollectionScene>
  )
}

export default ProviderResponseScene
