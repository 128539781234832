import React from 'react'

const SocialIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" fill="#131315">
        <path d="m18.918 15.515-4.104 1.586v-.02c.086-.763-.494-1.446-1.31-1.526l-4.298-.462-.494-.32c-1.29-.844-2.944-1.005-4.405-.443l-5.071 1.948a.365.365 0 0 0-.215.462c.086.18.301.26.494.2l5.071-1.947a4.155 4.155 0 0 1 3.674.361l.58.382c.044.04.108.06.172.06l4.405.462c.409.04.688.381.645.763-.043.381-.408.642-.817.602l-4.404-.462a.362.362 0 0 0-.409.322c-.021.18.13.361.323.381l4.404.462h.172c.537 0 .559-.14 5.866-2.148.366-.141.795.02.946.361a.664.664 0 0 1-.387.883l-6.038 2.35c-.473.18-.967.28-1.482.28H4.843c-.043 0-.107 0-.15.02L1.47 21.319a.365.365 0 0 0-.215.461c.086.181.3.262.494.201l3.18-1.225h7.327c.602 0 1.203-.1 1.762-.32l6.06-2.33c.751-.301 1.116-1.104.816-1.807-.344-.723-1.225-1.064-1.977-.783ZM23.97 7.254a.41.41 0 0 0-.495-.2l-5.079 1.944c-2.346.903-4.046-.661-4.26-.742a.255.255 0 0 0-.173-.06l-4.412-.46c-.408-.041-.688-.382-.645-.763.043-.381.409-.642.818-.602l4.411.462c.215.02.388-.12.409-.321.021-.18-.13-.361-.323-.381L9.81 5.67c-.345-.04-.56.04-.775.12L3.763 7.815c-.366.14-.797-.02-.947-.36a.663.663 0 0 1 .387-.883l6.069-2.346c.473-.18.968-.281 1.485-.281h7.402c.043 0 .108 0 .151-.02l3.228-1.243c.194-.08.28-.281.215-.462a.41.41 0 0 0-.495-.2l-3.163 1.243h-7.338c-.603 0-1.206.1-1.765.32L2.923 5.91c-.753.301-1.119 1.103-.817 1.805.322.702 1.183 1.043 1.936.762l4.11-1.584v.02c-.085.762.496 1.444 1.313 1.524l4.326.461c.172.06 2.173 1.825 4.906.762l5.079-1.945c.172-.08.28-.28.194-.461Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SocialIcon
