import RequestError from '@app/src/errors/RequestError'
import useApi from '@app/src/hooks/api'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Facet, FacetItem, FacetParam, FacetRequest, FacetResponse } from '@app/src/pages/ResourceCollection'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RequestParameters } from '@app/src/types/api'
import { Sort } from '@app/src/types/filter'
import { triggerDownloadFromResponse } from '@app/src/utils/helpersTs'
import { DEFAULT_TABLE_PAGE_SIZE } from '@app/src/wf-constants'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from 'react-query'

export enum FetchKey {
  Account = 'Account',
  AccessibleOrganizations = 'AccessibleOrganizations',
  Organization = 'Organization',
  Country = 'Country',
  CountryFacets = 'CountryFacets',
  Supplier = 'Supplier',
  Provider = 'Provider',
  ProviderRisk = 'ProviderRisk',
  ProviderCount = 'ProviderCount',
  ProvidersByRiskRating = 'ProvidersByRiskRating',
  ProviderCollection = 'ProviderCollection',
  AllProviders = 'AllProviders',
  PendingClaimsCollection = 'PendingClaimsCollection',
  Company = 'Company',
  Investee = 'Investee',
  Request = 'Request',
  RequestItem = 'RequestItem',
  RequestStatus = 'RequestStatus',
  RequestDueAt = 'RequestDueAt',
  RequestForAllTemplates = 'RequestForAllTemplates',
  RequestItemTemplates = 'RequestItemTemplates',
  Inquiry = 'Inquiry',
  AllInquiries = 'AllInquiries',
  AllMappingRequests = 'AllMappingRequests',
  InquiryFacets = 'InquiryFacets',
  InquiryOpenRequests = 'InquiryOpenRequests',
  InquiryOverDueOpenRequests = 'InquiryOverDueOpenRequests',
  InquiryResponses = 'InquiryResponses',
  InquiryStatus = 'InquiryStatus',
  InquiryOverdue = 'InquiryOverdue',
  InquiryByProvider = 'InquiryByProvider',
  InquiryCount = 'InquiryCount',
  RequestedInquiries = 'RequestedInquiries',
  Response = 'Response',
  ResponseItemsCollection = 'ResponseItemsCollection',
  NaceCodes = 'NaceCodes',
  ResponseSharing = 'ResponseSharing',
  Answer = 'Answer',
  Subscription = 'Subscription',
  SubscriptionFacets = 'SubscriptionFacets',
  SolutionOrganizations = 'SolutionOrganizations',
  Risk = 'Risk',
  RiskMap = 'RiskMap',
  RiskScreening = 'RiskScreening',
  RequestHistory = 'RequestHistory',
  RequestPerPeriod = 'RequestPerPeriod',
  AccessorOrganization = 'AccessorOrganization',

  Product = 'Product',
  ProductCollection = 'ProductCollection',
  ProductFacets = 'ProductFacets',
  MappingNode = 'MappingNode',
  MappingNodeCollection = 'MappingNodeCollection',
  MappingNodeFacets = 'MappingNodeFacets',
  MappingRequests = 'MappingRequests',
  ReceivedMappingRequests = 'ReceivedMappingRequests',
  MappingRequestFacets = 'MappingRequestFacets',
  MappingRequestsByProduct = 'MappingRequestsByProduct',
  PendingMappingRequests = 'MappingRequestsPending',
  MappingResponseConnections = 'MappingResponseConnections',
  MappingResponseCollection = 'MappingResponseCollection',
  MappingResponseFacets = 'MappingResponseFacets',
  ActorTypeCollection = 'ActorTypeCollection',
  ActorTypeFacets = 'ActorTypeFacets',

  GoalTracker = 'GoalTracker',
  OptionsMatchGoal = 'OptionsMatchGoal',
  FileUploadMatchGoal = 'FileUploadMatchGoal',
  ProvidersMatchingFilters = 'ProvidersMatchingFilters',
  ProvidersMatchingCount = 'ProvidersMatchingCount',

  Link = 'Link',
  Notification = 'Notification',
  Contact = 'Contact',
  Colleague = 'Colleague',
  Invitation = 'Invitation',
  Claim = 'Claim',
  RequestTemplate = 'RequestTemplate',
  RequestTemplateByItemId = 'RequestTemplateByItemId',
  OrganizationOwnedTemplates = 'OrganizationOwnedTemplates',
  SharedAndOwnedTemplates = 'SharedAndOwnedTemplates',
  Questions = 'Questions',
  AuditLog = 'AuditLog',
  Network = 'Network',
  DataPoint = 'DataPoint',
  Framework = 'Framework',
  Indicator = 'Indicator',
  OrganizationFacets = 'OrganizationFacets',
  ReportFlags = 'ReportFlags',
  RequestTemplateByItemIdAndDate = 'RequestTemplateByItemIdAndDate',
  RequestTemplateOnOverTimePage = 'RequestTemplateOnOverTimePage',
  ProviderProgressPage = 'ProviderProgressPage',
  ProviderActivity = 'ProviderActivityPage',

  QuestionFacets = 'QuestionFacets',
  ProviderFacets = 'ProviderFacets',
  ProviderReferralContactFacets = 'ProviderReferralContactFacets',
  ContactFacets = 'ContactFacets',
  RequestFacets = 'RequestFacets',
  AnswerFacets = 'AnswerFacets',
  ResponseFacets = 'ResponseFacets',
  RequestTemplateFacets = 'RequestTemplateFacets',
  NotificationFacets = 'NotificationFacets',
  RiskScreeningHqRiskStatusFacet = 'RiskScreeningHqRiskStatusFacet',
  RiskScreeningHqProviderFacet = 'RiskScreeningHqProviderFacet',
  ResponseItemCustomFlags = 'ResponseItemCustomFlags',
  ResponseItemStandardFlags = 'ResponseItemStandardFlags',
  ResponseItemsFacets = 'ResponseItemFacets',
  ResponseItemCount = 'ResponseItemCount',

  ReferralCode = 'ReferralCode',
  ReferralCodeCollection = 'ReferralCodeCollection',

  FindOrganization = 'FindOrganization',
  ExistingOrganization = 'ExistingOrganization',

  Category = 'Category',
  CategoryOption = 'CategoryOption',

  Automation = 'Automation',
  AutomationAffectedCount = 'AutomationAffectedCount',

  ExportTemplate = 'ExportTemplate',
  Picker = 'Picker',
  OrganizationCollection = 'OrganizationCollection',
  Users = 'Users',
  OpenRequests = 'OpenRequests',
  OverdueRequests = 'OverdueRequests',
  RequestsOverdueWithinAWeek = 'RequestsOverdueWithinAWeek',
  RequestsOverdueWithinOverAWeek = 'RequestsOverdueWithinOverAWeek',
  OverdueProviders = 'OverdueProviders',
  SFDRRequests = 'SFDRRequests',
  SFDRPrivateTemplates = 'SFDRPrivateTemplates',
  SFDRWfTemplates = 'SFDRWfTemplates',

  EdciProviderConfigs = 'EdciProviderConfigs',
  EdciAccessorConfigs = 'EdciAccessorConfigs',
  Languages = 'Languages',

  QuestionnaireRequestStatistics = 'QuestionnaireRequestStatistics',
  QuestionnaireCompanyStatistics = 'QuestionnaireCompanyStatistics',
  Unit = 'Unit',

  OrganizationSettings = 'OrganizationSettings',
  FileScreeningPresets = 'FileScreeningPresets',
  GetMyOrganization = 'GetMyOrganization',

  FilterFacet = 'FilterFacet',
  FileCollection = 'FileCollection',
  CommentCollection = 'CommentCollection',

  SuggestedResponse = 'SuggestedResponse',
  FileRepository = 'FileRepository',
  SuggestedFiles = 'SuggestedFiles',
  OrganizationRiskSettings = 'OrganizationRiskSettings',

  ReferralContactCollection = 'ReferralContactCollection',
  ReferralEmailHistoryCollection = 'ReferralEmailHistoryCollection',
  ReferralContactExists = 'ReferralContactExists',

  OrganizationAssessmentSettings = 'OrganizationAssessmentSettings',
  OrganizationAssessmentSettingsFacets = 'OrganizationAssessmentSettingsFacets',
  AssessmentCollection = 'AssessmentCollection',
  AssessmentQuestionnaireLink = 'AssessmentQuestionnaireLink',
  AssessmentFacets = 'AssessmentFacets',
  AssessmentTemplateCollection = 'AssessmentTemplateCollection',
  AssessmentTemplateWithClassificationRules = 'AssessmentTemplateWithClassificationRules',
  AssessmentTemplateRiskIndices = 'AssessmentTemplateRiskIndices',
  AssessmentTemplateRiskIndicesCollection = 'AssessmentTemplateRiskIndicesCollection',

  SuggestedContacts = 'SuggestedContacts',
  ResponseItemDataInsights = 'ResponseItemDataInsights',
  AssessmentTemplates = 'AssessmentTemplates',
  AccessorFeature = 'AccessorFeature',
}

export type FetchBaseParameters<T> = {
  endpoint: string
  key: FetchKey | [FetchKey, ...Array<FetchKey | string | number | undefined>]
  options?: UseQueryOptions<T, RequestError>
  config?: RequestParameters['config']
  showSnackbarOn404?: boolean
  shouldShowErrorNotification?: boolean
}

export interface FetchResourceWithBodyParameters<TReturn, TPayload> extends FetchBaseParameters<TReturn> {
  body: TPayload
}

export type FetchBaseInfiniteParameters<T> = {
  options?: UseInfiniteQueryOptions<T, RequestError>
} & FetchBaseParameters<T>

export interface FetchFacetsOptions extends FetchBaseParameters<FacetResponse> {
  filter?: FilterGroup[]
  facetsParam?: Array<FacetParam>
  skipNotSet?: boolean
  sort?: Sort
}

export interface FetchCollectionParameters<T> extends FetchBaseParameters<CollectionResponseType<T>> {
  payload: {
    filter: FilterGroup[]
    include: string[]
    sort?: Sort
    pagination?: { pageNumber: number; itemsPerPage: number }
    export?: {}
  }
}

export interface FetchParameters<TReturn, TPayload> extends FetchBaseParameters<TReturn> {
  payload: TPayload
}

export interface FetchInfiniteCollectionParameters<T> extends FetchBaseInfiniteParameters<CollectionResponseType<T>> {
  payload: {
    filter: FilterGroup[]
    include: string[]
    sort?: Sort
    pagination?: { pageNumber: number; itemsPerPage: number }
    export?: {}
  }
}

//--------FETCH RESOURCE COLLECTION--------//
export interface CollectionResponseType<T> {
  items: T[]
  totalResult: number
  itemsPerPage: number
  pageNumber: number
  pagesCount: number
}

export type UseFetchCollectionResultType<T, RequestError> = UseQueryResult<CollectionResponseType<T>, RequestError> & {
  items: T[]
  count: number
  pageNumber?: number
  pagesCount?: number
}

export const useFetchCollectionWithPost = <T>({
  key,
  endpoint,
  payload,
  config,
  options,
  shouldShowErrorNotification,
}: FetchCollectionParameters<T>): UseFetchCollectionResultType<T, RequestError> => {
  const { showErrorNotification } = useErrorNotification()
  const { post } = useApi()

  const queryResult = useQuery<CollectionResponseType<T>, RequestError>(
    [key, payload].flat(),
    () =>
      post<CollectionResponseType<T>, FetchCollectionParameters<T>['payload']>({
        url: endpoint,
        body: payload,
        config,
      }),
    options,
  )

  useEffect(() => {
    if (queryResult.isError) {
      Sentry.captureException(queryResult.error)
      if (shouldShowErrorNotification ?? true) showErrorNotification({ requestError: queryResult.error })

      if (queryResult.error.statusCode === 401) {
        throw queryResult.error
      }
    }
  }, [queryResult.isError])

  const { items = [], totalResult: count = 0, pageNumber, pagesCount } = queryResult.data ?? {}

  return { ...queryResult, items, count, pageNumber, pagesCount }
}

export const useInfiniteFetchCollection = <T>({
  key,
  endpoint,
  payload,
  config,
  options,
  shouldShowErrorNotification,
}: FetchInfiniteCollectionParameters<T>): UseInfiniteQueryResult<CollectionResponseType<T>, RequestError> => {
  const { showErrorNotification } = useErrorNotification()
  const { post } = useApi()

  const queryResult = useInfiniteQuery<CollectionResponseType<T>, RequestError>(
    key,
    ({ pageParam = 1 }) =>
      post<CollectionResponseType<T>, FetchCollectionParameters<T>['payload']>({
        url: endpoint,
        body: {
          ...payload,
          pagination: {
            itemsPerPage: payload.pagination?.itemsPerPage ?? DEFAULT_TABLE_PAGE_SIZE,
            pageNumber: Number(pageParam) ?? 1,
          },
        },
        config,
      }),
    { ...options, getNextPageParam: lastPage => lastPage.pageNumber < lastPage.pagesCount },
  )

  useEffect(() => {
    if (queryResult.isError) {
      Sentry.captureException(queryResult.error)
      if (shouldShowErrorNotification ?? true) showErrorNotification({ requestError: queryResult.error })

      if (queryResult.error.statusCode === 401) {
        throw queryResult.error
      }
    }
  }, [queryResult.isError])

  return queryResult
}

export const useFetchResourceWithPost = <TReturn, TPayload = Array<number | string>>({
  endpoint,
  key,
  options,
  body,
  showSnackbarOn404 = true,
  shouldShowErrorNotification,
}: FetchResourceWithBodyParameters<TReturn, TPayload>): UseQueryResult<TReturn, RequestError> => {
  const { post } = useApi()
  const { showErrorNotification } = useErrorNotification()

  const queryResult = useQuery<TReturn, RequestError>(
    [key, body].flat(),
    async () => await post({ url: endpoint, body }),
    options,
  )

  useEffect(() => {
    const is404 = queryResult.isError && queryResult.error.statusCode === 404

    if ((queryResult.isError && !is404) || (is404 && showSnackbarOn404)) {
      Sentry.captureException(queryResult.error)
      if (shouldShowErrorNotification ?? true) showErrorNotification({ requestError: queryResult.error })
    }

    if (queryResult?.error?.statusCode === 401) {
      Sentry.captureException(queryResult.error)
      throw queryResult.error
    }
  }, [queryResult.isError])

  return queryResult
}

export const useFetchResource = <T>({
  endpoint,
  key,
  options,
  showSnackbarOn404 = true,
  shouldShowErrorNotification,
}: FetchBaseParameters<T>): UseQueryResult<T, RequestError> => {
  const { get } = useApi()
  const { showErrorNotification } = useErrorNotification()

  const queryResult = useQuery<T, RequestError>(key, async () => await get<T>({ url: endpoint }), options)

  // TODO Move somewhere else?
  useEffect(() => {
    const is404 = queryResult.isError && queryResult.error.statusCode === 404

    if ((queryResult.isError && !is404) || (is404 && showSnackbarOn404)) {
      Sentry.captureException(queryResult.error)
      if (shouldShowErrorNotification ?? true) showErrorNotification({ requestError: queryResult.error })
    }

    if (queryResult?.error?.statusCode === 401) {
      Sentry.captureException(queryResult.error)
      throw queryResult.error
    }
  }, [queryResult.isError])

  return queryResult
}
//-------------------------------------//

//--------FETCH FACETS--------//

type UseFetchFacet = Omit<UseQueryResult, 'data' | 'error'> & {
  items: Facet[]
  facets: FacetItem[][]
  count: number
  isLoading: boolean
  isFetching: boolean
  error: RequestError | null
  isError: boolean
}

export const useFetchFacets = ({
  endpoint,
  filter = [],
  facetsParam = [],
  sort,
  key,
  options,
  skipNotSet = false,
}: FetchFacetsOptions): UseFetchFacet => {
  const { post } = useApi()
  const { data, isLoading, error, isFetching, isError, ...rest } = useQuery<FacetResponse, RequestError>(
    [key, filter, facetsParam].flat(),
    async () => {
      return await post<FacetResponse, FacetRequest>({
        url: endpoint,
        body: {
          filter,
          facets: facetsParam,
          sort,
        },
      })
    },
    options,
  )

  let items = data?.facets || []
  if (skipNotSet) {
    items = items.map(facet => {
      facet.items = facet.items.filter(item => item.label !== 'NotSet')
      return facet
    })
  }
  const count = data?.total || 0
  const facets = facetsParam?.map((facet, i) => items?.[i]?.items) // here we're assuming the backend will send facets back in the same order as they were requested. If we have bugs we need a fancier method to select the correct facet
  return { items, facets, count, isLoading, isFetching, error, isError, ...rest }
}

//-------------------------------------//

export const useFetchPost = <TReturn, TPayload>({
  key,
  endpoint,
  payload,
  config,
  options,
  shouldShowErrorNotification,
}: FetchParameters<TReturn, TPayload>): UseQueryResult<TReturn, RequestError> => {
  const { showErrorNotification } = useErrorNotification()
  const { post } = useApi()

  const queryResult = useQuery<TReturn, RequestError>(
    [key, payload].flat(),
    () =>
      post<TReturn, TPayload>({
        url: endpoint,
        body: payload,
        config,
      }),
    options,
  )

  useEffect(() => {
    if (queryResult.isError) {
      if (shouldShowErrorNotification ?? true) showErrorNotification({ requestError: queryResult.error })
      Sentry.captureException(queryResult.error)

      if (queryResult.error.statusCode === 401) {
        throw queryResult.error
      }
    }
  }, [queryResult.isError])

  return queryResult
}

export const useFetchCount = (fetchParameters: FetchParameters<number, FilterGroup[]>) => {
  const results = useFetchPost<number, FilterGroup[]>(fetchParameters)
  const { data, ...mappedResults } = { ...results, count: results.data }
  return mappedResults
}

export const useFileDownload = () => {
  const { get, post } = useApi()
  const { showErrorNotification } = useErrorNotification()
  const [isFileDownloading, setIsFileDownloading] = useState(false)

  const handleFileDownload = async (endpoint: string, fileExtension?: string) => {
    setIsFileDownloading(true)

    const headers = new Headers()
    headers.append('Content-Type', 'application/octet-stream')

    try {
      const { blob, fileName } = await get<{ blob: Blob; fileName: string }>(
        {
          url: endpoint,
          config: { headers },
        },
        { isBlob: true },
      )
      triggerDownloadFromResponse(blob, fileName, fileExtension)
    } catch (e) {
      if (e instanceof RequestError) {
        showErrorNotification({ requestError: e })
      }
    } finally {
      setIsFileDownloading(false)
    }
  }
  const handleFileDownloadWithFilters = async <T>(
    endpoint: string,
    payload: FetchCollectionParameters<T>['payload'],
    fileExtension?: string,
  ) => {
    setIsFileDownloading(true)

    const headers = new Headers()
    headers.append('Content-Type', 'application/octet-stream')

    try {
      const queryResult = await post<{ blob: Blob; fileName: string }, FetchCollectionParameters<T>['payload']>(
        {
          body: payload,
          url: endpoint,
          config: { headers },
        },
        { isBlob: true },
      )
      triggerDownloadFromResponse(queryResult.blob, queryResult.fileName, fileExtension)
    } catch (e) {
      if (e instanceof RequestError) {
        showErrorNotification({ requestError: e })
      }
    } finally {
      setIsFileDownloading(false)
    }
  }

  return {
    downloadFile: handleFileDownload,
    isFileDownloading,
    handleFileDownloadWithFilters: handleFileDownloadWithFilters,
  }
}
