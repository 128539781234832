import { Close } from '@mui/icons-material'
import {
  Box,
  ButtonProps,
  CardActions,
  CardContent,
  IconButton,
  Card as MUICard,
  CardProps as MUICardProps,
  Typography,
  TypographyVariant,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LinkButtonProps } from '@app/src/components/LinkButton'
import React, { PropsWithChildren, ReactNode, useState } from 'react'

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: theme.spacing(2),
  },
}))

export type CardProps = {
  title: string
  titleVariant?: TypographyVariant
  description: ReactNode
  closeable?: boolean
  button?: PropsWithChildren<ButtonProps | LinkButtonProps> | Array<PropsWithChildren<ButtonProps | LinkButtonProps>>
  extraClasses?: string
} & MUICardProps

const Card: React.FC<CardProps> = ({
  title,
  titleVariant = 'h1',
  description,
  closeable,
  button,
  extraClasses,
  ...props
}) => {
  const [open, setOpen] = useState(true)
  const classes = useStyles()

  if (!open) {
    return null
  }

  return (
    <MUICard {...props} className={extraClasses}>
      <CardContent>
        <Box display="flex">
          <Typography variant={titleVariant}>{title}</Typography>
          {closeable && (
            <Box flexGrow={1} textAlign="right">
              <IconButton onClick={(): void => setOpen(false)} size="small">
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
      </CardContent>
      {button && (
        <CardActions>
          <>{button}</>
        </CardActions>
      )}
    </MUICard>
  )
}

export default Card
