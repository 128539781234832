import { ExportColumn } from '@app/src/types/export'

export const ResponseExportColumnsAccessor: ExportColumn[] = [
  {
    translationId: 'general.id',
    exportPath: 'id',
  },
  {
    translationId: 'schemas.request.requestStatus',
    exportPath: 'request.inquiries[0].status',
  },
  {
    translationId: 'schemas.request.request',
    exportPath: 'request.title',
  },
  {
    translationId: 'schemas.request.periodDisplayName',
    exportPath: 'request.periodName',
  },
  {
    translationId: 'schemas.response.target',
    exportPath: 'request.targetObject.name',
  },
  {
    translationId: 'schemas.request.createdAt',
    exportPath: 'createdAt',
  },
  {
    translationId: 'schemas.request.deadline',
    exportPath: 'request.inquiries[0].deadline',
  },
  {
    translationId: 'schemas.response.externalVerificationComment',
    exportPath: 'verifications[0].externalVerificationComment',
  },
  {
    translationId: 'schemas.request.responseDate',
    exportPath: 'submittedAt',
  },
  {
    translationId: 'schemas.request.responseInternalStatus',
    exportPath: 'verifications[0].internalVerificationStatus',
  },
  {
    translationId: 'schemas.request.internalVerificationComment',
    exportPath: 'verifications[0].internalVerificationComment',
  },
  {
    translationId: 'schemas.filter.linkStatus',
    exportPath: 'request.targetAliasObject.linkStatus',
  },
  {
    translationId: 'schemas.organization.duns',
    exportPath: 'request.targetAliasObject.duns',
  },
  {
    translationId: 'schemas.response.submittedByUser',
    exportPath: `history[?(@.status=='Submitted')].creatorUser.name`,
  },
]

export const ResponseExportColumnsTransparency: ExportColumn[] = [
  {
    translationId: 'general.id',
    exportPath: 'id',
  },
  {
    translationId: 'schemas.response.request',
    exportPath: 'request.title',
  },
  {
    translationId: 'schemas.response.target',
    exportPath: 'request.targetObject.name',
  },
  {
    translationId: 'schemas.response.internalVerificationStatus',
    exportPath: 'verifications[0].internalVerificationStatus',
  },
  {
    translationId: 'schemas.response.externalVerificationComment',
    exportPath: 'verifications[0].externalVerificationComment',
  },
  {
    translationId: 'schemas.response.internalVerificationComment',
    exportPath: 'verifications[0].internalVerificationComment',
  },
  {
    translationId: 'schemas.response.responseStatus',
    exportPath: 'draftStatus',
  },
  {
    translationId: 'schemas.response.responseDate',
    exportPath: 'submittedAt',
  },
  {
    translationId: 'schemas.response.responder',
    exportPath: 'request.responderObject.name',
  },
  {
    translationId: 'schemas.response.targetType',
    exportPath: 'request.targetObjectType',
  },
]
