import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import OrganizationList from '@app/src/pages/Product/OrganizationList'
import { MappingNode, MappingResponseConnections, Product, ProductMappingResponse } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, List, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

type DrawerViewReviewProvidersConnectionsProps = {
  mappingResponse: ProductMappingResponse
  targetProduct: Product
} & Omit<DrawerViewProps, 'title'>

interface CopyValueChainFromResponseRequestBody {
  sourceResponseId: ProductMappingResponse['id']
  targetNodeId: MappingNode['id']
  productId: Product['id']
}

const DrawerViewReviewProvidersConnections: React.FC<DrawerViewReviewProvidersConnectionsProps> = ({
  targetProduct,
  mappingResponse,
  ...props
}) => {
  const { goBack, closeDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const confirm = useConfirm()

  const { mutate: mutateValueChainFromResponse } = useCreateResource<CopyValueChainFromResponseRequestBody>()
  const { data: mappingResponseConnections, isLoading } = useFetchResource<MappingResponseConnections>({
    endpoint: endpoints.mappingResponseConnections(mappingResponse.id),
    key: FetchKey.MappingResponseConnections,
  })

  const onReuseValueChainFromResponse = () => {
    if (targetProduct.mappingNodes.length > 1) {
      confirm({
        title: formatMessage({ id: 'schemas.valueChain.replaceExistingValueChain.title' }),
        description: formatMessage({ id: 'schemas.valueChain.replaceExistingValueChain.description' }, { br: <br /> }),
        confirmationText: formatMessage({ id: 'general.confirm' }),
        cancellationText: formatMessage({ id: 'general.cancel' }),
      }).then(() => reuseValueChainFromResponse())
      return
    }
    reuseValueChainFromResponse()
  }
  const reuseValueChainFromResponse = () => {
    const sourceResponseId = mappingResponse.id
    const targetNodeId = targetProduct.mappingNodes?.find(node => node.tier === 0)?.id

    if (!sourceResponseId || !targetNodeId) return
    mutateValueChainFromResponse(
      {
        url: endpoints.copyValueChainFromResponse,
        body: {
          sourceResponseId,
          targetNodeId,
          productId: targetProduct.id,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.Product)
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfullyCopiedValueChain' }),
            severity: NotificationSeverity.success,
          })
          closeDrawer()
        },
      },
    )
  }

  if (isLoading || !mappingResponseConnections) return null

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.valueChain.review' })}
      {...props}
      buttons={[
        {
          variant: 'outlined',
          label: formatMessage({ id: 'general.cancel' }),
          onClick: goBack,
        },
        {
          variant: 'contained',
          label: formatMessage({ id: 'general.continue' }),
          onClick: onReuseValueChainFromResponse,
        },
      ]}
    >
      <Box sx={{ height: '100%' }}>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Connected Companies ({mappingResponseConnections.providers.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OrganizationList organizations={mappingResponseConnections.providers} />
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} defaultExpanded={Boolean(mappingResponseConnections.unconnectedOrganizations.length)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography>
              {formatMessage({ id: 'schemas.valueChain.newCompanies' })} (
              {mappingResponseConnections.unconnectedOrganizations.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Alert variant="filled" severity="info">
              {formatMessage({ id: 'schemas.valueChain.newCompaniesDescription' })}
            </Alert>

            <List>
              <OrganizationList organizations={mappingResponseConnections.unconnectedOrganizations} />
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </DrawerView>
  )
}
export default DrawerViewReviewProvidersConnections
