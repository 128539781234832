import { FetchInfiniteCollectionParameters, useInfiniteFetchCollection } from '@app/src/api/fetchHooks'
import usePagination from '@app/src/hooks/pagination'
import React, { useEffect, useMemo } from 'react'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import RiskAccordion, { RiskAccordionProps } from './RiskAccordion'

export type RiskAccordionWithFetchingProps = {
  fetchData: FetchInfiniteCollectionParameters<ResponseItem>
} & Pick<RiskAccordionProps, 'label' | 'riskStatus'>

const RiskAccordionWithFetching: React.FC<RiskAccordionWithFetchingProps> = ({ label, riskStatus, fetchData }) => {
  const [page, pageSize, setPage] = usePagination({ page: 1, pageSize: 5 })

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, remove } =
    useInfiniteFetchCollection<ResponseItem>({
      ...fetchData,
      payload: {
        ...fetchData.payload,
        pagination: {
          pageNumber: page,
          itemsPerPage: pageSize,
        },
      },
    })

  const items = useMemo(() => data?.pages.flatMap(page => page.items).filter(item => item) ?? [], [data])
  const count = data?.pages?.[0].totalResult ?? items.length

  const loadMore = (): void => {
    fetchNextPage({ pageParam: page + 1 })
    setPage(page + 1)
  }

  useEffect(() => {
    return () => remove()
  }, [])

  return (
    <RiskAccordion
      label={label}
      riskStatus={riskStatus}
      count={count}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      items={items}
      loadMore={loadMore}
      hasNextPage={hasNextPage}
    />
  )
}

export default RiskAccordionWithFetching
