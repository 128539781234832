import qs from 'qs'
import React from 'react'
import { useLocation } from 'react-router-dom'

//from https://v5.reactrouter.com/web/example/query-parameters
export const useQueryParams = (): URLSearchParams => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function useBetterQueryParams<T = qs.ParsedQs>(): T {
  const { search: _search } = useLocation()
  const search = _search.substring(1) // removes `?`
  return qs.parse(search, { arrayLimit: Number.MAX_SAFE_INTEGER }) as T
}
