import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { Account, AccountContextProps } from '@app/src/context/AccountContext'
import { ExternalLinks } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import {
  BusinessCenterOutlined,
  ExpandLess,
  ExpandMore,
  InsertDriveFileOutlined,
  LockOpenOutlined,
  LogoutOutlined,
  TuneOutlined,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Collapse,
  Divider,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, generatePath, useLocation } from 'react-router-dom'

interface AccountMenuProps {
  account: AccountContextProps['account']
}

function getFirstLetterOfGivenNameFromAccount(account: Account | undefined): string {
  // Ensure that givenName is a string and has at least one character
  if (typeof account?.user?.givenName === 'string' && account.user.givenName.length > 0) {
    return account.user.givenName[0]
  }

  return ''
}

const AccountMenu = ({ account }: AccountMenuProps): JSX.Element | null => {
  const { formatMessage } = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { palette } = useTheme()
  const { pathname } = useLocation()

  const [open, setOpen] = useState<boolean>(false)
  const [groupOpen, setGroupOpen] = useState<boolean>(false)

  const user = account?.user

  const userFullName = user ? user.name || `${user.givenName} ${user.familyName}` : ''
  const userName = user?.givenName || userFullName
  const userEmail = user ? user.email : ''

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (): void => setOpen(false)
  const clickGroupHeader = (): void => setGroupOpen(!groupOpen)

  return (
    <>
      <Tooltip arrow title={formatMessage({ id: 'navbar.yourProfile' })}>
        <Button
          onClick={handleOpen}
          sx={{
            height: '100%',
            borderRadius: 0,
            pl: 2,
            pr: 1.5,
            ...(pathname.includes('/user/') && {
              borderBottom: `2px solid ${palette.primary.main}`,
            }),
          }}
          size="large"
          endIcon={<ExpandMore color="action" />}
        >
          <Stack direction="row" spacing={1}>
            <Avatar
              variant="circular"
              size={AvatarSize.Medium}
              sx={{ backgroundColor: palette.secondary.dark, color: palette.secondary.contrastText }}
            >
              {getFirstLetterOfGivenNameFromAccount(account)}
            </Avatar>
            {userName && account?.organization?.name && (
              <Stack textAlign="left">
                <Typography variant="caption" fontWeight={500}>
                  {userName}
                </Typography>
                <Typography variant="caption" fontWeight={500} color="textSecondary" mt="2px">
                  {account?.organization?.name}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Button>
      </Tooltip>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleClose}
        sx={{ minWidth: 300 }}
      >
        <MenuItem disableRipple sx={{ height: '64px', '&:hover': { backgroundColor: 'inherit' } }}>
          <ListItemAvatar>
            <Avatar
              variant="circular"
              size={AvatarSize.Large}
              sx={{ backgroundColor: palette.secondary.dark, color: palette.secondary.contrastText }}
            >
              {getFirstLetterOfGivenNameFromAccount(account)}
            </Avatar>
          </ListItemAvatar>

          <Box display="flex" flexDirection="column">
            <Typography color="text.primary">{userFullName}</Typography>
            <Typography variant="body2" color="textSecondary">
              {userEmail}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to={generatePath(paths.userSettings, { page: 'settings' })}>
          <ListItemIcon sx={{ pr: 4 }}>
            <TuneOutlined />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography color="text.primary">{formatMessage({ id: 'navbar.userSettings' })}</Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={clickGroupHeader}>
          <ListItemIcon sx={{ pr: 4 }}>
            <InsertDriveFileOutlined fontSize="medium" />
          </ListItemIcon>
          <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
            <Typography color="text.primary">{formatMessage({ id: 'accountMenu.legalMenu' })}</Typography>
            {groupOpen ? <ExpandLess color="action" /> : <ExpandMore color="action" />}
          </Box>
        </MenuItem>
        <Collapse in={groupOpen} timeout="auto" unmountOnExit>
          <Box pl={2}>
            <MenuItem onClick={handleClose} component={MuiLink} href={ExternalLinks.TermsAndConditions} target="_blank">
              <ListItemIcon sx={{ pr: 4 }}>
                <BusinessCenterOutlined fontSize="medium" />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography color="text.primary">{formatMessage({ id: 'accountMenu.termsConditions' })}</Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="/third-party-licenses.txt" target="_blank" onClick={handleClose}>
              <ListItemIcon sx={{ pr: 4 }}>
                <LockOpenOutlined fontSize="medium" />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography color="text.primary">{formatMessage({ id: 'accountMenu.openSourceLicenses' })}</Typography>
              </ListItemText>
            </MenuItem>
          </Box>
        </Collapse>
        <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
        <MenuItem onClick={handleClose} component={Link} to="/logout">
          <ListItemIcon sx={{ pr: 4 }}>
            <LogoutOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>
            <Typography color="text.primary">{formatMessage({ id: 'accountMenu.signOut' })}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default AccountMenu
