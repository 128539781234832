import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import GuidanceBanner from '@app/src/components/GuidanceBanner'
import Table from '@app/src/components/Table'
import { useAccount } from '@app/src/context/AccountContext'
import ReportFlagRuleHeader from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/Flags/ReportFlagRuleHeader'
import ReportFlagRuleRow from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/Flags/ReportFlagRuleRow'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { QuestionInTemplate, QuestionnaireTemplate, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

type TemplateFlagsProps = {
  id: string
}

export interface RequestItemWithOrderNumbers extends QuestionInTemplate {
  sectionNumber: number
  itemNumber: number
}

const ReportFlagRules: React.FC<TemplateFlagsProps> = ({ id }) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const {
    items: templates,
    count,
    isLoading,
    isError,
  } = useFetchCollectionWithPost<QuestionnaireTemplate>({
    key: [FetchKey.OrganizationOwnedTemplates, id],
    endpoint: endpoints.organizationOwnedTemplates,
    payload: {
      filter: [{ name: 'id', filters: [{ value: id, operator: Operators.EqualTo }] }],
      include: ['sections.questions.questionType', 'sections.questions.reportFlagRules'],
      pagination: { pageNumber: 1, itemsPerPage: 1 },
    },
  })

  const requestTemplate = templates?.[0]

  const requestItemsToDisplay: RequestItemWithOrderNumbers[] | undefined = useMemo(
    () =>
      requestTemplate?.sections
        ?.flatMap((s, sectionNumber) => s.questions.map((ri, itemNumber) => ({ ...ri, sectionNumber, itemNumber })))
        .filter(
          it =>
            it.creatorOrganizationId === account?.organization?.id &&
            (it.questionType?.name?.toLowerCase() === QuestionTypes.Options.toLowerCase() || !it.isRequired),
        ) ?? [],
    [requestTemplate?.id, templates],
  )

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" height={100} mb={2}>
      <Helmet>
        <title>{formatMessage({ id: 'automations.pageTitle' }, { templateName: requestTemplate?.title ?? '' })}</title>
      </Helmet>

      <Box mb={4} mr={5}>
        {requestTemplate && (
          <GuidanceBanner
            title={formatMessage({ id: 'automations.guidanceTitle' })}
            description={formatMessage({
              id:
                requestTemplate.questionnaireTemplateType === QuestionnaireTypeEnum.Shared
                  ? 'automations.guidanceInfoShared'
                  : 'automations.guidanceInfo',
            })}
          />
        )}
      </Box>

      <Table<RequestItemWithOrderNumbers>
        RowComponent={ReportFlagRuleRow}
        HeaderComponent={ReportFlagRuleHeader}
        data={requestItemsToDisplay}
        count={count}
        isLoading={isLoading}
        isError={isError}
        page={0}
        pageSize={10000}
        noPagination
      />
    </Box>
  )
}

export default ReportFlagRules
