import endpoints from '@app/src/api/endpoints'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useApi from '@app/src/hooks/api'
import useErrorNotification from '@app/src/hooks/errorNotification'
import {
  FilterGroup,
  Operators,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
  RESPONSE_LATEST_SUBMITTED_FILTER,
} from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FileResponse } from '@app/src/types/api'
import { ExportColumn } from '@app/src/types/export'
import { RawExportPayload } from '@app/src/types/filter'
import { Schema } from '@app/src/types/schemas'
import { br } from '@app/src/utils/translationMarkup'
import { NotificationSeverity, ResourceTypes } from '@app/src/wf-constants'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerView from '../DrawerView'

const FILTERED_DATA = 'filtered'
const RAW_DATA = 'raw'
const SELECTED_DATA = 'selected'

interface DrawerViewExportProps {
  resourceType: string
  count: number
  rawExportPayload: RawExportPayload
  userFilter: FilterGroup[]
  exportColumns?: ExportColumn[]
  selectedIds?: number[]
  onlyLatest?: boolean
}

export const getExportColumns = (schema: Schema) =>
  schema?.items
    .filter(schema => schema.display?.export && schema.exportPath)
    .map(schema => ({ translationId: schema.translationId, exportPath: schema.exportPath ?? '' }))

const DrawerViewExport: React.FC<DrawerViewExportProps> = ({
  resourceType,
  count,
  rawExportPayload,
  userFilter,
  exportColumns,
  selectedIds = [],
  onlyLatest,
}) => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const { post } = useApi()
  const { closeDrawer } = useDrawer()

  const [data, setData] = useState<string>(RAW_DATA)
  const [isLoading, setLoading] = useState(false)

  const exportColumnsResolved = useMemo(
    () =>
      exportColumns?.map(column => ({
        displayName: formatMessage({ id: column.translationId }),
        path: column.exportPath,
      })),
    [exportColumns],
  )

  const handleChange: RadioGroupProps['onChange'] = event => {
    setData(event.target.value)
  }

  const getOnlyLatestFilter = () => {
    if (!onlyLatest) return []

    if (resourceType === ResourceTypes.Response) return [RESPONSE_LATEST_SUBMITTED_FILTER]
    if ([ResourceTypes.ResponseItem, ResourceTypes.Answer].includes(resourceType))
      return [RESPONSE_ITEM_LATEST_SUBMITTED_FILTER]

    return []
  }

  const getExportObject = () => {
    const onlyLatestFilter = getOnlyLatestFilter()
    if (data === RAW_DATA) {
      return {
        ...rawExportPayload,
        filter: [...(rawExportPayload?.filter ? rawExportPayload.filter : []), ...onlyLatestFilter],
        export: exportColumnsResolved,
      }
    }
    if (data === FILTERED_DATA) {
      return {
        ...rawExportPayload,
        filter: [...(rawExportPayload?.filter ? rawExportPayload.filter : []), ...userFilter, ...onlyLatestFilter],
        export: exportColumnsResolved,
      }
    }
    if (data === SELECTED_DATA) {
      return {
        ...rawExportPayload,
        filter: [
          ...(rawExportPayload?.filter ? rawExportPayload.filter : []),
          ...[{ name: 'id', filters: [{ operator: Operators.In, value: selectedIds }] }],
          ...onlyLatestFilter,
        ],
        export: exportColumnsResolved,
      }
    }
    return {}
  }

  const handleSubmit = async () => {
    const exportObject = getExportObject()

    setLoading(true)

    try {
      const res = await post<FileResponse, { [key: string]: unknown }>({
        url: endpoints.queueDataExport(resourceType),
        body: exportObject,
      })
      if (res) {
        showSnackbar({
          message: formatMessage({ id: 'notifications.successfulExportQueueRequest' }, { br }),
          severity: NotificationSeverity.success,
        })
      } else {
        showSnackbar({
          message: formatMessage({ id: 'notifications.error' }),
          severity: NotificationSeverity.error,
        })
      }
    } catch (e) {
      const typedError = e as { requestError: RequestError }
      showErrorNotification({ requestError: typedError.requestError })
    } finally {
      setLoading(false)
      closeDrawer()
    }
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'export.title' })}
      subTitle={formatMessage({ id: `resourceTypes.${resourceType}` })}
      buttons={[
        {
          label: formatMessage({ id: 'export.export' }),
          variant: 'contained',
          onClick: handleSubmit,
          disabled: !data,
          loading: isLoading,
        },
      ]}
    >
      <Box px={2}>
        <FormControl variant="outlined">
          <FormLabel component="legend">{formatMessage({ id: 'export.description' })}</FormLabel>
          <RadioGroup value={data} onChange={handleChange}>
            <FormControlLabel value={RAW_DATA} control={<Radio />} label={formatMessage({ id: 'export.rawOption' })} />
            {Boolean(userFilter?.length) && Boolean(count) && (
              <FormControlLabel
                value={FILTERED_DATA}
                control={<Radio />}
                label={formatMessage({ id: 'export.filteredOption' }, { rows: count })}
              />
            )}
            {Boolean(selectedIds?.length) && (
              <FormControlLabel
                value={SELECTED_DATA}
                control={<Radio />}
                label={formatMessage({ id: 'export.selectedOption' }, { rows: selectedIds.length })}
              />
            )}
          </RadioGroup>
        </FormControl>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewExport
