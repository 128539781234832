import { IdToken, useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import qs from 'qs'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import AuthenticationError from '@app/src/components/AuthenticationError'
import WfLoader from '@app/src/components/WfLoader'

import useLocalStorage from '@app/src/hooks/localStorage'
import { useQueryParams } from '@app/src/hooks/queryParams'
import AuthenticationContext, { GetToken, Options, Params, Scope } from './AuthenticationContext'

type AuthenticationContextProviderProps = {
  children: ReactNode
}

const LOCAL_STORAGE_SCOPE = 'wf_scope'

const JWT_CLAIM_USER_ID = 'https://worldfavor.com/claim/userId'
const JWT_CLAIM_ORGANIZATION_ID = 'https://worldfavor.com/claim/organizationId'
const JWT_CLAIM_SOLUTION_TYPE = 'https://worldfavor.com/claim/solutionType'
const JWT_CLAIM_ROLE = 'https://worldfavor.com/claim/role'

// allow us to access the token from the requestWrapper, kinda dirty, i don't really like it, let's hope auth0 improves their module
// TODO: Go through this later
// If API files are changed into hook we don't need to expose this - we could just get the token from this context
let _getToken: GetToken

export const getAuth0Utils = (): {
  getToken: GetToken
} => ({
  getToken: (params?: Params, options?: Options): Promise<string | undefined> =>
    _getToken && _getToken(params, options),
})

const AuthenticationContextProvider: FC<AuthenticationContextProviderProps> = ({
  children,
}: AuthenticationContextProviderProps) => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout: auth0Logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0()
  const { search } = useLocation()
  const [userId, setUserId] = useState<number | null>(null)
  const [scope, setScope] = useLocalStorage<Scope>(LOCAL_STORAGE_SCOPE, { role: 'default', solution: 'default' })
  const [error, setError] = useState<{ code: string; description?: string } | undefined>(undefined)

  const logout = (): void => {
    auth0Logout({
      returnTo: window.location.origin,
    })
  }
  const history = useHistory()
  const params = useQueryParams()
  const referral = useMemo(() => params.get('referral'), [params])

  useEffect(() => {
    const { error, error_description: errorDescription }: { error?: string; error_description?: string } =
      qs.parse(search.slice(1)) ?? {}

    if (error) {
      setError({
        code: error,
        description: errorDescription,
      })
    }
  }, [search])

  useEffect(() => {
    if (isLoading || isAuthenticated || error) {
      return
    }

    if (window.location.pathname === '/chill') return

    const authenticate = async (): Promise<void> => {
      //TODO remove the following line (key: authScope) in a few weeks, it's kept here for now just to clean up the old localstorage entries :)
      localStorage.removeItem('authScope')
      localStorage.removeItem(LOCAL_STORAGE_SCOPE)
      const targetUrl =
        window.location.pathname === '/logout' || window.location.pathname === '/signup'
          ? '/'
          : window.location.pathname

      await loginWithRedirect({
        appState: { targetUrl: targetUrl + window.location.search },
        referral: referral ?? undefined,
        screen_hint: window.location.pathname === '/signup' || Boolean(referral) ? 'signup' : 'login',
      })
    }

    authenticate()
  }, [isLoading, isAuthenticated, error, loginWithRedirect])

  const getScopeFromClaims = (claim?: IdToken): Scope => {
    if (!claim) {
      return {}
    }
    return {
      organization: claim?.[JWT_CLAIM_ORGANIZATION_ID],
      solution: claim?.[JWT_CLAIM_SOLUTION_TYPE],
      role: claim?.[JWT_CLAIM_ROLE],
    }
  }

  /**
   *
   * getToken : function to get an id_token (access token), to send in the Authorization header to each API call
   *
   * an id_token contains a set of claims, which are information about the user
   * the claims we are mostly interested in are the ones composing what we call a `Scope` :
   *
   * JWT_CLAIM_ORGANIZATION_ID: ID of the organization the user is currently logged in
   * JWT_CLAIM_SOLUTION_TYPE: solution the user is currently logged in
   * JWT_CLAIM_ROLE: role the user has within this solution
   *
   * The function will prioritize the params because it means the user is changing solution
   * if there are no params it will take whatever scope was in the local storage
   *
   * we also check the current scope in the id_token (getIdTokenClaims), if there is a mismatch between the current scope and the newScope we are trying to get
   * we set the `ignoreCache` param to true, which will force auth0 to ignore the cached id_token and fetch a new one with the correct scope
   */
  const getToken: GetToken = async (params, options) => {
    const currentClaims = await getIdTokenClaims()
    const currentScope = getScopeFromClaims(currentClaims)
    const userId = currentClaims?.[JWT_CLAIM_USER_ID]
    setUserId(userId)
    let newScope = scope

    if (params) {
      params = { ...params, solution: params.solution?.toLowerCase(), role: params.role?.toLowerCase() }
      setScope(params)
      newScope = params
    }

    const changedScope =
      newScope.solution !== currentScope.solution ||
      newScope.role !== currentScope.role ||
      newScope.organization !== currentScope.organization ||
      Boolean(params) ||
      (options && options.forceNewToken)

    try {
      return await getAccessTokenSilently({
        wf_app_solution: newScope.solution,
        wf_app_role: newScope.role,
        wf_app_organization: newScope.organization,
        ignoreCache: changedScope,
      })
    } catch (e) {
      Sentry.captureException(e)

      console.error('Could not get the access token, is user logged in ?', e)
      history.push('/')
      throw e
    }
  }

  _getToken = getToken

  if (isLoading) return <WfLoader />

  return (
    <AuthenticationContext.Provider value={{ authenticated: isAuthenticated, logout, getToken, scope, userId }}>
      <AuthenticationError error={error}>{children}</AuthenticationError>
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationContextProvider
