import Dialog from '@app/src/components/Dialog'
import Markdown from '@app/src/components/Markdown'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { MenuBookOutlined } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

type RequestItemGuidanceProps = {
  item: RequestItem
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
})

const RequestItemGuidance: React.FC<RequestItemGuidanceProps> = ({ item }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [open, openDialog, closeDialog] = useDialogState()

  if (!item.guidance) {
    return null
  }

  return (
    <Box ml={1}>
      <Button size="small" color="primary" aria-label="question guidance" component="span" onClick={openDialog}>
        <Stack direction="row" spacing={1}>
          <MenuBookOutlined fontSize="small" />
          <Typography variant="button">{formatMessage({ id: 'schemas.question.guidance' })}</Typography>
        </Stack>
      </Button>
      <Dialog
        onClose={closeDialog}
        open={open}
        title={
          <Typography variant="subtitle1" className={classes.bold}>
            <Markdown>{item.description}</Markdown>
          </Typography>
        }
        content={<Markdown>{item.guidance}</Markdown>}
        maxWidth="md"
        fullWidth={false}
        buttons={[
          {
            label: formatMessage({ id: 'general.close' }),
            variant: 'outlined',
            onClick: closeDialog,
          },
        ]}
      />
    </Box>
  )
}

export default RequestItemGuidance
