import { Document, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer'
import { default as EChartsReactCore, default as ReactEChartsCore } from 'echarts-for-react/lib/core'
import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import React, { RefObject, useState } from 'react'

const pageWidth = 595.28

const styles = StyleSheet.create({
  pageFooter: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    fontSize: 6,
  },
  chartContainer: {
    padding: 24,
    borderRadius: 8,
    border: '1pt solid #E0E0E0',
    marginBottom: 16,
    marginLeft: 40,
    marginRight: 40,
    width: pageWidth - 80,
  },
  logo: {
    width: 20,
    height: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  pageLayout: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  chartImage: {
    width: pageWidth - 180,
  },
  titleText: {
    fontSize: 8,
    marginBottom: 16,
  },
  filterImageStyle: {
    width: pageWidth,
    marginBottom: 20,
    marginLeft: 40,
    marginRight: 40,
    height: 'auto',
    transform: 'scale(1.5)',
    transformOrigin: 'top left',
  },
})

const performPdfGeneration = async (
  fileName: string,
  data: { title: string; echarts: EChartsReactCore }[],
  filtersRef?: RefObject<HTMLDivElement>,
) => {
  let filterImage
  if (filtersRef) {
    const filterCanvas = await html2canvas(filtersRef.current as HTMLElement)
    filterImage = filterCanvas.toDataURL()
  }

  const blob = await pdf(
    <Document>
      <Page size="A4" style={styles.pageLayout}>
        <Image src="/img/brand/worldfavor_Logo_Black.png" style={styles.logo} fixed />
        {filterImage && <Image src={filterImage} style={styles.filterImageStyle} />}
        {data.map(({ title, echarts }, index) => {
          const image = echarts
            .getEchartsInstance()
            .getDataURL({ backgroundColor: '#FFFFFF', type: 'png', pixelRatio: 2.0 })
          return (
            <View key={index} style={styles.chartContainer} wrap={false}>
              <Text style={styles.titleText}>{title}</Text>
              <Image src={image} style={styles.chartImage} />
            </View>
          )
        })}
        <Text style={styles.pageFooter} render={({ pageNumber }) => <>Page {pageNumber}</>} fixed />
      </Page>
    </Document>,
  ).toBlob()

  saveAs(blob, fileName)
}

const useExportEchartsToPDF = () => {
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)

  const generatePDF = async (
    fileName: string,
    data: { title: string; echarts: ReactEChartsCore }[],
    filtersRef?: RefObject<HTMLDivElement>,
  ) => {
    setIsGeneratingPdf(true)
    setTimeout(async () => {
      try {
        await performPdfGeneration(fileName, data, filtersRef)
      } finally {
        setIsGeneratingPdf(false)
      }
    }, 300)
  }

  return { generatePDF, isGeneratingPdf }
}

export default useExportEchartsToPDF
