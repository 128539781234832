import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useMenuState } from '@app/src/hooks/mui-hooks'
import { configurations, createProduct, manageRequest } from '@app/src/wf-constants/paths'
import { ArrowDropDown } from '@mui/icons-material'
import { Button, ButtonGroup, Divider, Menu, MenuItem, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import { CONFIGURATION_PAGE_IDS } from '../Configurations/ConfigurationsScene'
import { ViewTypeName } from '../ResourceCollection/Collections/ManageRequests/ManageRequestsScene'

const DiscoverButton = () => {
  const { formatMessage } = useIntl()
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()
  const { productMapping } = useFlags()
  const history = useHistory()
  const { hasPermission } = usePermissions()

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" sx={{ borderRadius: 8 }}>
        <Button onClick={openMenu}>{formatMessage({ id: 'schemas.product.create.new' })}</Button>
        <Button size="small" onClick={openMenu}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={closeMenu}>
        {productMapping && hasPermission(Permissions.SOURCING_USER) && (
          <MenuItem onClick={() => history.push(createProduct)}>
            {formatMessage({ id: 'schemas.mappingNode.product' })}
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            history.push({
              pathname: generatePath(manageRequest, { view: ViewTypeName.Requests }),
              state: { openCreateDialog: true },
            })
          }
        >
          {formatMessage({ id: 'schemas.response.request' })}
        </MenuItem>
        {hasPermission(Permissions.ACCESS_SOLUTION_CONFIGURATION) && (
          <>
            <MenuItem
              onClick={() =>
                history.push({
                  pathname: generatePath(configurations, {
                    configurationsPage: CONFIGURATION_PAGE_IDS.Automations,
                  }),
                  state: { openCreateDialog: true },
                })
              }
            >
              {formatMessage({ id: 'configurations.requestAutomation' })}
            </MenuItem>
            <Divider />
            <Typography variant="subtitle2" ml={2}>
              {formatMessage({ id: 'resourceCollections.invite.invite' })}
            </Typography>
            <MenuItem
              onClick={() =>
                history.push({
                  pathname: generatePath(configurations, {
                    configurationsPage: CONFIGURATION_PAGE_IDS.Colleagues,
                  }),
                  state: { openCreateDialog: true },
                })
              }
            >
              {formatMessage({ id: 'schemas.colleagues.colleague' })}
            </MenuItem>
          </>
        )}
      </Menu>
    </React.Fragment>
  )
}

export default DiscoverButton
