import React from 'react'

const RightBackBlob = () => {
  return (
    <svg width="265" height="101" viewBox="0 0 265 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M183.5 27.5C136.256 58.0753 72.2415 -28.2696 0.280666 76.0967L52.5318 195.26C148.725 186.396 336.538 146.304 312.933 58.2086C288.945 -31.3161 218.399 4.91403 183.5 27.5Z"
        fill="#2C5D55"
      />
    </svg>
  )
}

export default RightBackBlob
