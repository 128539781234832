import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import useOrganizationCurrency from '@app/src/hooks/organizationCurrency'
import { Provider, Spend } from '@app/src/types/organizations'
import { toCurrencyString } from '@app/src/utils/currencyUtils'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Add, AddOutlined, DateRangeOutlined, DeleteOutline, EditOutlined, MoreHoriz } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../Drawer/DrawerContext'
import DrawerViewAddAnnualSpend from '../Drawer/Views/DrawerViewAddAnnualSpend'
import LoadingButton from '../LoadingButton'
import { useSpendPeriodOptions } from './spendPeriodOptions'

interface ProviderSpendsProps {
  provider: Provider
}

const ProviderSpends: React.FC<ProviderSpendsProps> = ({ provider }) => {
  const { openDrawer } = useDrawer()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedSpend, setSelectedSpend] = useState<Spend>()
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const open = Boolean(anchorEl)
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { mutate: deleteAnnualSpend, isLoading: isDeleting } = useDeleteResource()
  const { SPEND_PERIOD_OPTIONS } = useSpendPeriodOptions()
  const { organizationCurrency, isLoading } = useOrganizationCurrency()

  const periodsWithSpend = provider.spends?.map(spend => spend.periodName)
  const periodsWithoutSpend = SPEND_PERIOD_OPTIONS.filter(period => !periodsWithSpend?.includes(String(period.value)))
  const hasSpendForLatestReportingPeriod = provider.spends?.some(
    spend => spend.periodName === String(new Date().getFullYear()),
  )

  const handleOpen = (event: React.MouseEvent<HTMLElement>, spend: Spend) => {
    setSelectedSpend(spend)
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleCloseDeleteDialog = () => {
    handleCloseMenu()
    setDeleteDialogOpen(false)
  }

  const handleDelete = () => {
    if (!selectedSpend) return
    deleteAnnualSpend(
      { url: endpoints.providerAnnualSpendDelete(selectedSpend.id) },
      {
        onSuccess: async () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
            severity: NotificationSeverity.success,
          })
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          handleCloseDeleteDialog()
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  if (isLoading) return <Skeleton variant="rounded" height={80} />

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" color="textSecondary">
          {formatMessage({ id: 'schemas.supplier.annualSpend.spend' })}
        </Typography>
        <Button
          size="small"
          startIcon={<Add />}
          onClick={() =>
            openDrawer(<DrawerViewAddAnnualSpend providerId={provider.id} periodsWithoutSpend={periodsWithoutSpend} />)
          }
        >
          {formatMessage({ id: 'schemas.supplier.annualSpend.addPeriod' })}
        </Button>
      </Stack>
      <Stack spacing={2}>
        {!hasSpendForLatestReportingPeriod && (
          <ListItem disablePadding sx={{ mt: 2 }}>
            <ListItemAvatar sx={{ minWidth: 'unset', mr: 2 }}>
              <DateRangeOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={new Date().getFullYear()}
              secondary={<Typography color="textSecondary">{formatMessage({ id: 'general.notAdded' })}</Typography>}
            />
            <IconButton
              onClick={() =>
                openDrawer(
                  <DrawerViewAddAnnualSpend providerId={provider.id} periodsWithoutSpend={periodsWithoutSpend} />,
                )
              }
            >
              <AddOutlined />
            </IconButton>
          </ListItem>
        )}
        {provider.spends?.map((spend, i) => (
          <ListItem disablePadding sx={{ mt: 2 }} key={i}>
            <ListItemAvatar sx={{ minWidth: 'unset', mr: 2 }}>
              <DateRangeOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={spend.periodName}
              secondary={
                <Typography color="textSecondary">{toCurrencyString(spend.amount, organizationCurrency)}</Typography>
              }
            />
            <IconButton onClick={e => handleOpen(e, spend)}>
              <MoreHoriz />
            </IconButton>
          </ListItem>
        ))}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              openDrawer(
                <DrawerViewAddAnnualSpend
                  spend={selectedSpend}
                  providerId={provider.id}
                  periodsWithoutSpend={periodsWithoutSpend}
                />,
              )
              handleCloseMenu()
            }}
          >
            <ListItemIcon>
              <EditOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'general.edit' })}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteOutline fontSize="small" />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'general.delete' })}</ListItemText>
          </MenuItem>
        </Menu>
      </Stack>
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{formatMessage({ id: 'general.areYouSure' })}</DialogTitle>
        <DialogContent>{formatMessage({ id: 'schemas.supplier.annualSpend.deleteSpendMessage' })}</DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button disabled={isDeleting} onClick={handleCloseDeleteDialog}>
              {formatMessage({ id: 'general.cancel' })}
            </Button>
            <LoadingButton variant="contained" loading={isDeleting} onClick={handleDelete}>
              {formatMessage({ id: 'general.delete' })}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ProviderSpends
