import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport, { getExportColumns } from '@app/src/components/Drawer/Views/DrawerViewExport'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import MappingNodeHeader from '@app/src/pages/ResourceCollection/Collections/MappingNode/MappingNodeHeader'
import MappingNodeRow from '@app/src/pages/ResourceCollection/Collections/MappingNode/MappingNodeRow'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import MappingNodeSchema from '@app/src/schemas/mappingNode'
import { MappingNode } from '@app/src/types/product'
import { ResourceTypes } from '@app/src/wf-constants'
import React from 'react'
import { useIntl } from 'react-intl'
import MappingNodeFilters from '../Filters/MappingNodeFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'

type MappingNodeCollectionProps = {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
}

const allowedFilters: string[] = [
  'name',
  'tier',
  'product.id',
  'product.name',
  'product.gtin',
  'parentNode.id',
  'actorTypeModel.name',
  'provider.organization.id',
  'parentNode.provider.organization.id',
  'parentNode.actorTypeModel.name',
  'product.purchaseOrder',
  'product.productNumber',
  'product.productCustomId',
]

const MappingNodeCollection: React.FC<MappingNodeCollectionProps> = ({ relatedContentFilter = [], embedded }) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { openDrawer } = useDrawer()

  const filters = useGetApiQueryFilters(allowedFilters)

  const rawExportPayload = {
    filter: [
      {
        name: 'tier',
        filters: [{ value: '0', operator: Operators.NotEqualTo }],
      },
      ...relatedContentFilter,
    ],
    sort: sorting,
    include: [
      'product',
      'actorTypeModel',
      'provider.organization',
      'parentNode.organization',
      'parentNode.provider',
      'parentNode.actorTypeModel',
      'parentNode.provider.organization',
    ],
  }

  const payload = {
    ...rawExportPayload,
    filter: [...filters, ...rawExportPayload.filter],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: mappingNodes,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<MappingNode>({
    key: FetchKey.MappingNodeCollection,
    endpoint: endpoints.mappingNodeCollection,
    payload,
  })

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.valueChains' })}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.MappingNode}
                  count={count}
                  userFilter={filters}
                  exportColumns={getExportColumns(MappingNodeSchema)}
                  rawExportPayload={rawExportPayload}
                />,
              ),
            disabled: isLoading || count === 0,
          },
        ]}
        filter={<MappingNodeFilters allowedFilters={allowedFilters} implicitFilters={rawExportPayload.filter} />}
        embedded={embedded}
      >
        <Table<MappingNode>
          HeaderComponent={() => <MappingNodeHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
          RowComponent={MappingNodeRow}
          data={mappingNodes}
          count={count}
          page={page}
          isError={isError}
          isLoading={isLoading || isFetching}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={1}
        />
      </ResourceCollectionScene>
    </>
  )
}

export default MappingNodeCollection
