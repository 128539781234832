import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import FlagIndicatorCell from '@app/src/components/Table/Cells/FlagIndicatorCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { RequestItemWithOrderNumbers } from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/Flags/ReportFlagRules'
import { SustainabilityLibraryParams } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import paths from '@app/src/wf-constants/paths'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { TableRow } from '@mui/material'
import markdownToTxt from 'markdown-to-txt'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, matchPath, useHistory, useLocation } from 'react-router'

type ReportFlagRuleRowProps = {
  row: RequestItemWithOrderNumbers
}

const ReportFlagRuleRow = ({ row: requestItem }: ReportFlagRuleRowProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { pathname } = useLocation()

  const matchedPath = matchPath<SustainabilityLibraryParams>(pathname, paths.sustainabilityLibrary)
  const flagCount = requestItem.reportFlagRules?.length ?? 0

  function goToEditAutomationPage() {
    history.push(
      generatePath(paths.sustainabilityLibrary, {
        configurationsPage: matchedPath?.params.configurationsPage,
        configurationsSubPage: matchedPath?.params.configurationsSubPage,
        activeTabParam: matchedPath?.params.activeTabParam,
        activeTabSubId: requestItem.id,
      }),
    )
  }

  return (
    <TableRow data-testid="report-flag-rule-row">
      <FlagIndicatorCell count={flagCount} disabled={!flagCount} bgcolor="error.main" />
      <ValueCell value={`${requestItem.sectionNumber + 1}.${requestItem.itemNumber + 1}`} width={10} fontWeight={600} />
      <ValueCell value={markdownToTxt(requestItem.questionText)} />
      <ValueCell value={requestItem.reportFlagRules?.length ? requestItem.reportFlagRules?.[0].activationType : ''} />
      {requestItem.reportFlagRules ? (
        <ButtonCell
          label={formatMessage({ id: 'automations.editFlag' })}
          startIcon={<EditIcon />}
          onClick={goToEditAutomationPage}
        />
      ) : (
        <ButtonCell
          label={formatMessage({ id: 'automations.createFlag' })}
          onClick={goToEditAutomationPage}
          startIcon={<AddIcon />}
        />
      )}
    </TableRow>
  )
}

export default ReportFlagRuleRow
