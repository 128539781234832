import { Chip } from '@mui/material'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { RequestTags, SfdrRequestTags } from '@app/src/wf-constants'
import { SFDR_REPORT_QUESTION_KEYS } from './SfdrReportWithoutCompanies'
import SelectableTemplateWithPreview from '@app/src/components/CreateNewRequestModal/Common/SelectableTemplateWithPreview'

type SfdrRequestTemplateProps = {
  template: QuestionnaireTemplate
}

const SfdrRequestTemplate: React.FC<SfdrRequestTemplateProps> = ({ template }) => {
  const { setValue, watch } = useFormContext()
  const { formatMessage } = useIntl()

  const selectedTemplates: [{ id: number; title: string }] = watch(SFDR_REPORT_QUESTION_KEYS.TEMPLATE_IDS)

  useEffect(() => {
    if (
      template.tags?.includes(SfdrRequestTags.SfdrSusApproach) &&
      !selectedTemplates.find(selectedTemplate => selectedTemplate.id === template.id)
    ) {
      setValue(SFDR_REPORT_QUESTION_KEYS.TEMPLATE_IDS, [
        ...selectedTemplates,
        { id: template.id, title: template.title },
      ])
    }
  }, [])

  const getTemplateDescription = (tags?: RequestTags[]) => {
    if (tags?.includes(SfdrRequestTags.SfdrSusApproach)) {
      return formatMessage({ id: 'form.createRequest.sfdr.templateDescriptions.susApproach' })
    }
    if (tags?.includes(SfdrRequestTags.SfdrPai1)) {
      return formatMessage({ id: 'form.createRequest.sfdr.templateDescriptions.pai1' })
    }
    if (tags?.includes(SfdrRequestTags.SfdrPai2a)) {
      return formatMessage({ id: 'form.createRequest.sfdr.templateDescriptions.pai2a' })
    }
    if (tags?.includes(SfdrRequestTags.SfdrPai2b)) {
      return formatMessage({ id: 'form.createRequest.sfdr.templateDescriptions.pai2b' })
    }
    if (tags?.includes(SfdrRequestTags.SfdrArticle8and9)) {
      return formatMessage({ id: 'form.createRequest.sfdr.templateDescriptions.article89' })
    }
    return 'SFDR'
  }

  return (
    <SelectableTemplateWithPreview
      template={template}
      chip={<Chip label={getTemplateDescription(template.tags)} />}
      formKey={SFDR_REPORT_QUESTION_KEYS.TEMPLATE_IDS}
    />
  )
}

export default SfdrRequestTemplate
