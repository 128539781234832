import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import EmptyState from '@app/src/components/EmptyState'
import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { ExternalVerificationStatus, InternalVerificationStatus } from '@app/src/types/resourceExplorer'
import { CommentsDisabledOutlined } from '@mui/icons-material'
import { Box, Divider, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

const VerificationMessages: React.FC = () => {
  const { response } = useReport()
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()

  const verification = response?.verifications?.[0]

  return (
    <>
      {Boolean(!verification) && (
        <EmptyState
          iconComponent={CommentsDisabledOutlined}
          title={formatMessage({ id: 'reporting.messagesEmptyState.title' })}
          sx={{ my: 8 }}
          description={formatMessage({ id: 'reporting.messagesEmptyState.description' })}
        />
      )}
      {verification?.externalVerificationStatus &&
        verification?.externalVerificationStatus !== ExternalVerificationStatus.NotSet && (
          <Box pt={2}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <StatusCell value={verification.externalVerificationStatus} disableCell />
              <Typography variant="caption" color="textSecondary" ml={1}>
                {formatMessage({ id: 'questionnaire.externalCommentVisibility' })}
              </Typography>
            </Box>

            <ListItem alignItems="flex-start" disablePadding sx={{ py: 2 }}>
              <ListItemAvatar sx={({ spacing }) => ({ minWidth: spacing(5) })}>
                <CompanyAvatar
                  imageUrl={verification.creatorOrganization?.image?.url}
                  organizationName={verification.creatorOrganization?.name ?? ''}
                  size={AvatarSize.Small}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Stack direction="row" alignItems="center">
                    <Typography>{verification.creatorOrganization?.name}</Typography>
                    <Typography variant="caption" color="textSecondary" ml={1}>
                      {formatMessage(
                        { id: 'general.valueWithDotSeparatorBefore' },
                        { value: formatValueToDate({ value: verification.createdAt }) },
                      )}
                    </Typography>
                  </Stack>
                }
                secondary={verification.externalVerificationComment}
              />
            </ListItem>

            <Divider variant="fullWidth" />
          </Box>
        )}

      {verification?.internalVerificationStatus &&
        verification?.internalVerificationStatus !== InternalVerificationStatus.NotSet && (
          <Box pt={2}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <StatusCell value={verification.internalVerificationStatus} disableCell />
              <Typography variant="caption" color="textSecondary" ml={1}>
                {formatMessage({ id: 'questionnaire.internalCommentVisibility' })}
              </Typography>
            </Box>

            <ListItem alignItems="flex-start" disablePadding sx={{ py: 2 }}>
              <ListItemAvatar sx={({ spacing }) => ({ minWidth: spacing(5) })}>
                <CompanyAvatar
                  imageUrl={verification.creatorUser?.image?.url}
                  organizationName={verification.creatorUser?.name ?? ''}
                  size={AvatarSize.Small}
                  variant="circular"
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Stack direction="row" alignItems="center">
                    <Typography>{verification.creatorUser?.name}</Typography>
                    <Typography variant="caption" color="textSecondary" ml={1}>
                      {formatMessage(
                        { id: 'general.valueWithDotSeparatorBefore' },
                        { value: formatValueToDate({ value: verification.createdAt }) },
                      )}
                    </Typography>
                  </Stack>
                }
                secondary={verification.internalVerificationComment}
              />
            </ListItem>

            <Divider variant="fullWidth" />
          </Box>
        )}
    </>
  )
}

export default VerificationMessages
