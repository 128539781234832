import { useTheme } from '@mui/material/styles'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { TextCommonOption } from 'echarts/types/src/util/types'
import React from 'react'

export const valueToMaxRatio = (value: number, maxValue: number) => {
  if (!maxValue) {
    return 0
  }
  return (value / maxValue) * 100
}

interface StatisticsBarProps {
  value: number
  max: number
  color: string
  barWidth?: number
  onBarClick?: () => void
  percent?: boolean
  showBackground?: boolean
  withCount?: boolean
  label?: string
}

const StatisticsBar: React.FC<StatisticsBarProps> = ({
  value,
  max,
  color,
  barWidth = 28,
  percent,
  withCount,
  showBackground = false,
  onBarClick = () => null,
  label,
}) => {
  const { palette, typography } = useTheme()

  echarts.use([BarChart, SVGRenderer, GridComponent, LegendComponent, TitleComponent])

  const options: EChartsOption = {
    textStyle: {
      fontFamily: typography.fontFamily,
      fontWeight: 600,
      color: palette.text?.primary,
    },
    title: {
      show: false,
    },
    legend: { show: false },
    xAxis: {
      max: percent ? 100 : max,
      show: true,
      position: 'top',
      splitLine: {
        show: false,
      },
      triggerEvent: true,
    },
    yAxis: {
      inverse: true,
      type: 'category',
      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      triggerEvent: true,
      axisLabel: {
        show: false,
      },
    },
    grid: {
      top: 0,
      bottom: 0,
      right: 0,
      containLabel: false,
      show: false,
      left: 0,
    },
    series: [
      {
        type: 'bar',
        stack: 'chart',
        barWidth,
        data: [percent ? Math.round(valueToMaxRatio(value, max)) : value],
        label: {
          show: true,
          position: valueToMaxRatio(value, max) > 10 ? 'insideRight' : 'outside',
          formatter: l => {
            return label ? `${label}` : `{value|${l.value}${percent ? `%${withCount ? ` (${value})` : ''}` : ''}}`
          },
          rich: {
            value: {
              color: valueToMaxRatio(value, max) > 10 ? palette.common.white : palette.common.black,
            } as TextCommonOption,
          },
        },
        itemStyle: {
          color,
          borderType: 'solid',
          borderRadius: 4,
        },
        showBackground,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)',
          borderRadius: 4,
        },
      },
    ],
  }

  return <ReactEChartsCore onEvents={{ click: onBarClick }} option={options} echarts={echarts} style={{ height: 50 }} />
}

export default StatisticsBar
