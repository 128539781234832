import { Grid } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { FieldError, UseFormMethods } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { getQuarters, getYears } from '@app/src/utils'
import { getHalfYears, getMonths, getMonthsLastDay } from '../../utils'
import { Option } from '../Form/Select'
import SimpleSelect from '../Form/Select/SimpleSelect'

interface Props {
  register: UseFormMethods['register']
  error?: FieldError
  required?: boolean
  periodName?: string
  startAtName?: string
  endsAtName?: string
}

export const Periods = {
  Yearly: 'Yearly',
  HalfYearly: 'HalfYearly',
  Quarterly: 'Quarterly',
  Monthly: 'Monthly',
}

const PeriodField = ({ register, required, endsAtName, periodName, startAtName }: Props): JSX.Element => {
  const [state, setState] = useState<{ periodStartValue: string; periodEndValue: string }>({
    periodStartValue: '',
    periodEndValue: '',
  })
  const [periodType, setPeriodType] = useState<Option<string> | null>(null)
  const [isPeriodTypeOpen, openPeriodType] = useState(false)
  const [year, setYear] = useState<Option<number> | null>(null)
  const [isYearOpen, openYear] = useState(false)
  const [period, setPeriod] = useState<Option<number> | null>(null)
  const [isPeriodOpen, openPeriod] = useState(false)
  const { periodStartValue, periodEndValue } = state
  const { formatMessage } = useIntl()

  const PERIOD_TYPES = useMemo(
    () => [
      {
        label: formatMessage({ id: 'schemas.request.periodTypes.yearly' }),
        value: Periods.Yearly,
      },
      {
        label: formatMessage({ id: 'schemas.request.periodTypes.halfYearly' }),
        value: Periods.HalfYearly,
      },
      {
        label: formatMessage({ id: 'schemas.request.periodTypes.quarterly' }),
        value: Periods.Quarterly,
      },
      {
        label: formatMessage({ id: 'schemas.request.periodTypes.monthly' }),
        value: Periods.Monthly,
      },
    ],
    [formatMessage],
  )

  const onChange = (item: Option<number> | Option<number>[] | null): void => {
    setPeriod([item].flat()?.[0])
    if (!item) {
      setState({
        periodStartValue: '',
        periodEndValue: '',
      })
      return
    }

    const itemAsOptionNumber = [item].flat()?.[0]

    if (periodType?.value === Periods.Yearly) {
      const y = itemAsOptionNumber?.value
      setState({
        periodStartValue: y ? `${y}-01-01 00:00:00` : '',
        periodEndValue: y ? `${y}-12-31 23:59:59` : '',
      })
    } else if (periodType?.value === Periods.Quarterly || periodType?.value === Periods.HalfYearly) {
      const length = periodType.value === Periods.Quarterly ? 3 : 6
      let startMonth = String(Number(itemAsOptionNumber?.value) * length + 1)
      let endMonth = String(Number(itemAsOptionNumber?.value) * length + length)

      const endDay = getMonthsLastDay(year?.value, endMonth)

      if (startMonth?.length < 2) startMonth = '0' + startMonth
      if (endMonth?.length < 2) endMonth = '0' + endMonth

      setState({
        periodStartValue: `${year?.value}-${startMonth}-01 00:00:00`,
        periodEndValue: `${year?.value}-${endMonth}-${endDay} 23:59:59`,
      })
    } else if (periodType?.value === Periods.Monthly) {
      const endDay = getMonthsLastDay(year?.value, itemAsOptionNumber?.value)
      const month =
        String(itemAsOptionNumber?.value).length < 2 ? `0${itemAsOptionNumber?.value}` : itemAsOptionNumber?.value

      setState({
        periodStartValue: `${year?.value}-${month}-01 00:00:00`,
        periodEndValue: `${year?.value}-${month}-${endDay} 23:59:59`,
      })
    } else {
      setState({
        periodStartValue: '',
        periodEndValue: '',
      })
    }
  }

  const periodTypeValue = periodType?.value
  const yearValue = year?.value
  const periodOptions = useMemo((): Option<number>[] => {
    if (periodTypeValue === Periods.Yearly) return getYears()?.map(y => ({ value: y, label: String(y) }))
    if (periodTypeValue === Periods.HalfYearly) return getHalfYears(yearValue)
    if (periodTypeValue === Periods.Quarterly) return getQuarters(yearValue)
    return getMonths()
  }, [periodTypeValue, yearValue])
  const yearsOption = useMemo(() => getYears().map(y => ({ value: y, label: String(y) })), [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SimpleSelect<string>
          onChange={(value: Option<string> | Option<string>[] | null) => {
            setPeriodType([value].flat()?.[0])
            setYear(null)
            setPeriod(null)
          }}
          open={isPeriodTypeOpen}
          setOpen={openPeriodType}
          loading={false}
          options={PERIOD_TYPES}
          fieldLabel={formatMessage({ id: 'schemas.request.periodTypes.label' })}
          value={periodType}
        />
      </Grid>
      {periodType !== null && periodType.value !== Periods.Yearly && (
        <Grid item xs={4}>
          <SimpleSelect<number>
            onChange={(value: Option<number> | Option<number>[] | null) => {
              setYear([value].flat()?.[0])
              setPeriod(null)
            }}
            open={isYearOpen}
            setOpen={openYear}
            loading={false}
            options={yearsOption}
            fieldLabel={formatMessage({ id: 'schemas.request.year' })}
            value={year}
          />
        </Grid>
      )}
      {(year !== null || periodType?.value === Periods.Yearly) && (
        <Grid item xs={4}>
          <SimpleSelect<number>
            onChange={onChange}
            open={isPeriodOpen}
            setOpen={openPeriod}
            loading={false}
            options={periodOptions}
            fieldLabel={formatMessage({ id: 'schemas.request.period' })}
            value={period}
          />
        </Grid>
      )}
      <input type="hidden" value={periodType?.value} name={periodName ?? 'periodType'} ref={register} />
      <input
        type="hidden"
        value={periodStartValue}
        name={startAtName ?? 'periodStartsAt'}
        ref={register({ required: required ? 'This field is required' : false })}
      />
      <input type="hidden" value={periodEndValue} name={endsAtName ?? 'periodEndsAt'} ref={register} />
    </Grid>
  )
}

export default PeriodField
