import { Box, Grid, Typography } from '@mui/material'
import { GridProps } from '@mui/material/Grid/Grid'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles(({ spacing }) => ({
  hide: {
    display: 'none',
  },
  inputContainer: {
    marginBottom: spacing(4),
  },
  question: {
    marginBottom: spacing(2),
  },
  disabledQuestion: {
    marginBottom: spacing(2),
  },
}))

interface StepContainerProps extends GridProps {
  step: number
  activeStep: number
}

interface QuestionProps extends GridProps {
  disabled?: boolean
  title: string
}

export const Question = ({ disabled, title, children, ...props }: PropsWithChildren<QuestionProps>): JSX.Element => {
  const classes = useStyles()
  return (
    <Grid item {...props}>
      <Box className={classes.inputContainer}>
        <Typography variant="body1" className={classNames(classes.question, { [classes.disabledQuestion]: disabled })}>
          {title}
        </Typography>
        {children}
      </Box>
    </Grid>
  )
}

const StepContainer = ({
  children,
  step,
  activeStep,
  ...props
}: PropsWithChildren<StepContainerProps>): JSX.Element => {
  const classes = useStyles()
  return (
    <Grid
      container
      item
      spacing={3}
      xs={12}
      className={classNames({
        [classes.hide]: activeStep !== step,
      })}
      {...props}
    >
      {children}
    </Grid>
  )
}

export default StepContainer
