import { ChipCellProps } from '@app/src/components/Table/Cells/ChipCell'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { Chip } from '@mui/material'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'
import ValueCell from './ValueCell'

export type AssessmentTemplateCellProps = {
  assessmentQuestionnaireLink?: QuestionnaireTemplate['assessmentQuestionnaireLink']
  solutionType: string | undefined
} & ChipCellProps

const AssessmentQuestionnaireLinkCell: React.FC<AssessmentTemplateCellProps> = ({
  assessmentQuestionnaireLink,
  solutionType,
  disableCell,
  ...props
}) => {
  if (!assessmentQuestionnaireLink) return <ValueCell />
  if (!solutionType) return <ValueCell />

  const availableTemplates = assessmentQuestionnaireLink.filter(
    aql =>
      !aql.assessmentTemplate?.solutionAvailability?.length ||
      aql.assessmentTemplate.solutionAvailability.some(
        solution => solution.solutionType.toLowerCase() === solutionType.toLowerCase(),
      ),
  )

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      {availableTemplates.map(aql => (
        <Chip key={aql.id} label={aql.assessmentTemplate?.name ?? '-'} sx={{ mr: 1, mb: 1 }} {...props} />
      ))}
    </ParentCellOrFragment>
  )
}

export default AssessmentQuestionnaireLinkCell
