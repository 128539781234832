import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import useQueryFilters, { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import StandardQuestionHeader from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/StandardQuestionHeader'
import StandardQuestionRow from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/StandardQuestionRow'
import StandardQuestionFilters from '@app/src/pages/ResourceCollection/Filters/StandardQuestionFilters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollection from '@app/src/pages/ResourceCollection/index'
import { Question } from '@app/src/types/resourceExplorer'
import { FilterListOffOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useIntl } from 'react-intl'

const allowedFilters: string[] = ['questionText', 'options.id']

export interface QuestionSelectionProps {
  selectedQuestionIds: Array<number>
  setSelectedQuestionIds: Dispatch<SetStateAction<number[]>>
  questionIdsAlreadyInQuestionnaire: Array<number>
}

interface StandardQuestionCollectionProps {
  selectionProps?: QuestionSelectionProps
}

const StandardQuestionCollection: FC<StandardQuestionCollectionProps> = ({ selectionProps }) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const { clearQueryFilter } = useQueryFilters(allowedFilters)

  const implicitFilters = [{ name: 'isStandard', filters: [{ operator: Operators.EqualTo, value: true }] }]
  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Question>({
    key: FetchKey.Questions,
    endpoint: endpoints.questionCollection,
    payload: {
      sort: sorting,
      include: ['options.category'],
      filter: [...implicitFilters, ...userFilters],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, question: Question) => {
    if (e.target.checked) {
      selectionProps?.setSelectedQuestionIds(prev => [...prev, question.id])
    } else {
      selectionProps?.setSelectedQuestionIds(prev => prev.filter(questionId => questionId !== question.id))
    }
  }

  return (
    <ResourceCollection
      title=""
      embedded
      filter={
        <StandardQuestionFilters
          allowedFilters={allowedFilters}
          resetPage={resetPage}
          implicitFilters={implicitFilters}
        />
      }
    >
      <Table<Question>
        RowComponent={({ row }) => (
          <StandardQuestionRow
            question={row}
            selectionProps={
              selectionProps && {
                selectedQuestionIds: selectionProps.selectedQuestionIds,
                onCheckboxChange: onCheckboxChange,
                questionIdsAlreadyInQuestionnaire: selectionProps.questionIdsAlreadyInQuestionnaire,
              }
            }
          />
        )}
        HeaderComponent={() => (
          <StandardQuestionHeader
            toggleSorting={toggleSorting}
            activeSorting={sorting}
            includeCheckboxHeader={Boolean(selectionProps)}
            selectionProps={
              selectionProps && {
                selectedQuestionIds: selectionProps.selectedQuestionIds,
                setSelectedQuestionIds: selectionProps.setSelectedQuestionIds,
                onCheckboxChange: onCheckboxChange,
                questionIdsAlreadyInQuestionnaire: selectionProps.questionIdsAlreadyInQuestionnaire,
              }
            }
            questionsInPage={items}
          />
        )}
        emptyState={
          <EmptyState
            iconComponent={FilterListOffOutlined}
            title={formatMessage({ id: 'general.noResults' })}
            description={formatMessage({ id: 'general.noResultSubtitle' })}
          >
            <Button variant="outlined" onClick={clearQueryFilter}>
              {formatMessage({ id: 'general.clearFilters' })}
            </Button>
          </EmptyState>
        }
        data={items}
        isLoading={isLoading || isFetching}
        count={count}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollection>
  )
}

export default StandardQuestionCollection
