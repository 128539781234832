import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import CustomCategoriesFilters from '@app/src/pages/ResourceCollection/Filters/CustomCategoriesFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Solutions } from '@app/src/wf-constants'
import { Box, Button, Chip, createFilterOptions, Grid, Tooltip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FacetItem, FiltersProps } from '..'
import EvaluationStatus from '../Collections/Evaluation/EvaluationStatus'
import AllFiltersButton from './AllFiltersButton'
import FilterSection from './FilterSection'
import OptionWithLabel from './OptionWithLabel'
import RangeFilter from './RangeFilter'
import TextFieldFilter from './TextFieldFilter'

const EvaluationsFilters = ({ allowedFilters, implicitFilters, resetPage }: FiltersProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const { renderWithPermission } = usePermissions()
  const { solution } = useAuthentication().scope

  const tierFilter = createFilterOptions<FacetItem>({
    stringify: option =>
      formatMessage(
        {
          id: `schemas.provider.tierValues`,
        },
        { tier: option.value },
      ),
  })

  return (
    <Grid container spacing={1}>
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item md={3} sm={10}>
                <Filter name="indicator.section.framework.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.evaluationsWithFacets,
                        facetsParam: [{ name: 'indicator.section.framework.title' }],
                      }}
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'schemas.evaluation.framework' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={2}>
                <Filter name="indicator.section.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.evaluationsWithFacets,
                        facetsParam: [{ name: 'indicator.section.title' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.evaluation.frameworkSection' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={2}>
                <Filter name="indicator.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.evaluationsWithFacets,
                        facetsParam: [{ name: 'indicator.description' }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.evaluation.frameworkIndicator' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={2}>
                <Filter name="value" operator={Operators.In}>
                  {({ value, onChange, filterName }) => {
                    const disabled = !filters['indicator.id']
                    return (
                      <Tooltip
                        disableTouchListener={!disabled}
                        disableFocusListener={!disabled}
                        disableHoverListener={!disabled}
                        title="Select an Indicator first"
                      >
                        <div>
                          <FilterFacetSelect
                            disabled={disabled}
                            implicitFilters={implicitFilters}
                            size="small"
                            multiple
                            facetsParam={{
                              key: [FetchKey.RequestFacets, filterName],
                              endpoint: endpoints.evaluationsWithFacets,
                              facetsParam: [{ name: filterName }],
                            }}
                            filterName={filterName}
                            onChange={value => onChange(value)}
                            fieldLabel={formatMessage({ id: 'schemas.evaluation.result' })}
                            value={[value].flat()}
                            renderOption={(props, option, { selected }): JSX.Element => (
                              <li {...props}>
                                <OptionIcon selected={selected} multiple />
                                {Number(option.value) ? <>{option.value}</> : <EvaluationStatus value={option.label} />}
                                <OptionAdornment option={option} />
                              </li>
                            )}
                          />
                        </div>
                      </Tooltip>
                    )
                  }}
                </Filter>
              </Grid>
              <Grid item md={3}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.evaluations' })}>
                              <Filter name="indicator.section.framework.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.evaluationsWithFacets,
                                      facetsParam: [{ name: 'indicator.section.framework.title' }],
                                    }}
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.evaluation.framework' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="periodName" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.evaluationsWithFacets,
                                      facetsParam: [{ name: 'periodName', isEnum: true }],
                                      sort: { target: 'label', order: SortOrder.DESCENDING },
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.evaluation.period' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="indicator.section.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.evaluationsWithFacets,
                                      facetsParam: [{ name: 'indicator.section.title' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.evaluation.frameworkSection' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="indicator.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.evaluationsWithFacets,
                                      facetsParam: [{ name: 'indicator.description' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.evaluation.frameworkIndicator' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="value" operator={Operators.In}>
                                {({ value, onChange, filterName }) => {
                                  const disabled = !filters['indicator.id']
                                  return (
                                    <Tooltip
                                      disableTouchListener={!disabled}
                                      disableFocusListener={!disabled}
                                      disableHoverListener={!disabled}
                                      title="Select an Indicator first"
                                    >
                                      <div>
                                        <FilterFacetSelect
                                          disabled={disabled}
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.RequestFacets, filterName],
                                            endpoint: endpoints.evaluationsWithFacets,
                                            facetsParam: [{ name: filterName }],
                                          }}
                                          filterName={filterName}
                                          onChange={value => onChange(value)}
                                          fieldLabel={formatMessage({ id: 'schemas.evaluation.result' })}
                                          value={[value].flat()}
                                          renderOption={(props, option, { selected }): JSX.Element => (
                                            <li {...props}>
                                              <OptionIcon selected={selected} multiple />
                                              {Number(option.value) ? (
                                                <>{option.value}</>
                                              ) : (
                                                <EvaluationStatus value={option.label} />
                                              )}
                                              <OptionAdornment option={option} />
                                            </li>
                                          )}
                                        />
                                      </div>
                                    </Tooltip>
                                  )
                                }}
                              </Filter>
                              <RangeFilter
                                uniqueIdFrom="createdFrom"
                                uniqueIdTo="createdTo"
                                name="createdAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.filter.createdFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.filter.createdTo' })}
                              />
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.evaluation.evidenceInformation' })}>
                              <Box mb={2}>
                                <Filter name="evidence" operator={Operators.Contains}>
                                  {({ filterName, operator, value }) => (
                                    <TextFieldFilter
                                      defaultValue={value}
                                      value={value}
                                      filterName={filterName}
                                      operator={operator}
                                      size="small"
                                      fullWidth
                                      label={formatMessage({ id: 'schemas.evaluation.evidence' })}
                                    />
                                  )}
                                </Filter>
                              </Box>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.evaluation.targetInformation' })}>
                              <Box mb={2}>
                                <Filter name="targetObjectType" operator={Operators.In}>
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.evaluationsWithFacets,
                                        facetsParam: [{ name: filterName }],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.evaluation.targetType' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter name="targetObjectId" operator={Operators.In}>
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.evaluationsWithFacets,
                                        facetsParam: [{ name: 'targetObject.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.evaluation.target' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter name="targetObject.country.name" operator={Operators.In}>
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.evaluationsWithFacets,
                                        facetsParam: [{ name: filterName, isEnum: true }],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.evaluation.country' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              {renderWithPermission({
                                [Permissions.SOURCING_USER]: (
                                  <>
                                    <Box mb={2}>
                                      <Filter name="targetObject.supplierUsage" operator={Operators.In}>
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.RequestFacets, filterName],
                                              endpoint: endpoints.evaluationsWithFacets,
                                              facetsParam: [{ name: filterName, isEnum: true }],
                                            }}
                                            filterName={filterName}
                                            onChange={value => onChange(value)}
                                            fieldLabel={formatMessage({ id: 'schemas.supplier.supplierUsage' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                    <Box mb={2}>
                                      <Filter name="targetObject.tier" operator={Operators.In}>
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.RequestFacets, filterName],
                                              endpoint: endpoints.evaluationsWithFacets,
                                              facetsParam: [{ name: filterName, isEnum: true }],
                                            }}
                                            filterName={filterName}
                                            onChange={value => onChange(value)}
                                            fieldLabel={formatMessage({ id: 'schemas.supplier.tier' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                  </>
                                ),
                              })}
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.additionalInformation' })}>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="targetObject.ownerUserId">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.evaluationsWithFacets,
                                        facetsParam: [{ name: 'targetObject.ownerUser.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="targetObject.finalRiskRating">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.evaluationsWithFacets,
                                        facetsParam: [{ name: 'targetObject.finalRiskRating', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }): JSX.Element => (
                                        <li {...props}>
                                          <OptionIcon selected={selected} multiple />
                                          <FinalRiskRatingIcon value={option?.label} />
                                          {option?.label !== undefined
                                            ? formatMessage({
                                                id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                              })
                                            : ''}
                                        </li>
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                icon={<FinalRiskRatingIcon value={option.label} />}
                                                label={formatMessage({
                                                  id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="targetObject.priority">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.evaluationsWithFacets,
                                        facetsParam: [{ name: 'targetObject.priority', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }): JSX.Element => (
                                        <OptionWithLabel
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.priorityValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.provider.priorityValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              {solution !== Solutions.Finance && (
                                <>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="targetObject.activityStatus">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.RequestFacets, filterName],
                                            endpoint: endpoints.evaluationsWithFacets,
                                            facetsParam: [{ name: 'targetObject.activityStatus', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }): JSX.Element => (
                                            <OptionWithLabel
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.activityStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.activityStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="targetObject.providerApprovalStatus">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.RequestFacets, filterName],
                                            endpoint: endpoints.evaluationsWithFacets,
                                            facetsParam: [
                                              { name: 'targetObject.providerApprovalStatus', isEnum: true },
                                            ],
                                          }}
                                          renderOption={(props, option, { selected }): JSX.Element => (
                                            <OptionWithLabel
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.providerApprovalStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              )}
                              {renderWithPermission({
                                [Permissions.SOURCING_USER]: (
                                  <>
                                    <Box mb={2}>
                                      <Filter operator={Operators.In} name="targetObject.tier">
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.RequestFacets, filterName],
                                              endpoint: endpoints.evaluationsWithFacets,
                                              facetsParam: [{ name: 'targetObject.tier', isEnum: true }],
                                            }}
                                            filterOptions={(options, state) => [...tierFilter(options, state)]}
                                            renderOption={(props, option, { selected }): JSX.Element => (
                                              <OptionWithLabel
                                                props={props}
                                                label={formatMessage(
                                                  {
                                                    id: `schemas.provider.tierValues`,
                                                  },
                                                  { tier: option?.label ?? '' },
                                                )}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage(
                                                        {
                                                          id: `schemas.provider.tierValues`,
                                                        },
                                                        { tier: option.label },
                                                      )}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                  </>
                                ),
                              })}
                            </FilterSection>
                            <CustomCategoriesFilters baseFilterName="targetObject" />
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default EvaluationsFilters
