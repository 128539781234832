import CategoryOptionLabel from '@app/src/components/CategoryOptionLabel'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import { FilterValue, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { addSpaceBetweenWords } from '@app/src/utils/helpers'
import React from 'react'
import { useIntl } from 'react-intl'

export const useGetFilterLabels = (complexValues: { [key: string]: FacetItem[] }) => {
  const { formatMessage } = useIntl()
  const getFilterLabel = (property: string, value: FilterValue | undefined, operator?: Operators): React.ReactNode => {
    if (Array.isArray(value)) {
      if (property.endsWith('priority')) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.priority` })}: ${value
          .map(v =>
            formatMessage({
              id: `schemas.provider.priorityValues.${v}`,
            }),
          )
          .join(', ')}`
      }
      if (property.endsWith('actorTypeModel.id') && complexValues?.actorTypeNames) {
        return `${formatMessage({
          id: `dashboard.sourcing.companyStatistics.actorType`,
        })}: ${complexValues.actorTypeNames
          ?.map(actorType => formatMessage({ id: `schemas.mappingNode.actorTypeValues.${actorType.label}` }))
          .join(', ')}`
      }
      if (property.endsWith('actorTypeModel.name')) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.actorType` })}: ${value
          .map(v => addSpaceBetweenWords(v))
          .join(', ')}`
      }
      if (property.endsWith('mappingNodes.tier')) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.productMappingTier` })}: ${value
          .map(v => formatMessage({ id: 'schemas.provider.tierValues' }, { tier: v }))
          .join(', ')}`
      }
      if (property.endsWith('tier')) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.tier` })}: ${value
          .map(v => formatMessage({ id: 'schemas.provider.tierValues' }, { tier: v }))
          .join(', ')}`
      }
      if (property.endsWith('supplierUsage')) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.usage` })}: ${value
          .map(v =>
            formatMessage({
              id: `schemas.provider.supplierUsageValues.${v}`,
            }),
          )
          .join(', ')}`
      }
      if (property.endsWith('finalRiskRating')) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.finalRiskRating` })}: ${value
          .map(v =>
            formatMessage({
              id: `schemas.provider.finalRiskRatingValues.${v}`,
            }),
          )
          .join(', ')}`
      }
      if (property.endsWith('providerApprovalStatus')) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.approvalStatus` })}: ${value
          .map(v =>
            formatMessage({
              id: `schemas.provider.providerApprovalStatusValues.${v}`,
            }),
          )
          .join(', ')}`
      }
      if (property.endsWith('activityStatus')) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.activityStatus` })}: ${value
          .map(v => addSpaceBetweenWords(v))
          .join(', ')}`
      }
      if (property.endsWith('country.id') && complexValues?.countryNames) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.country` })}: ${complexValues.countryNames
          ?.map(countryName => countryName.label)
          .join(', ')}`
      }
      if (property.endsWith('target.id') && complexValues?.companyNames) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.companies` })}: ${complexValues.companyNames
          ?.map(companyName => companyName.value)
          .join(', ')}`
      }
      if (property.endsWith('target.organizationId') && complexValues?.organizationNames) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.companies` })}: ${complexValues.organizationNames
          ?.map(companyName => companyName.label)
          .join(', ')}`
      }
      if (property.endsWith('ownerUserId') && complexValues?.userNames) {
        return `${formatMessage({ id: `dashboard.sourcing.companyStatistics.owner` })}: ${complexValues.userNames
          ?.map(userName => userName.label)
          .join(', ')}`
      }
      if (property.endsWith('questionId') && complexValues?.questionNames) {
        return `${formatMessage({ id: `schemas.filter.questions` })}: ${complexValues.questionNames
          ?.map(question => question.label)
          .join(', ')}`
      }
      if (property.endsWith('questionnaireTemplateId') && complexValues?.questionnaireNames) {
        return `${formatMessage({ id: `resourceTypes.questionnaires` })}: ${complexValues.questionnaireNames
          ?.map(questionnaire => questionnaire.label)
          .join(', ')}`
      }
      if (property.endsWith('template.options.id') && complexValues?.legislationNames) {
        return `${formatMessage({ id: `schemas.question.legislation` })}: ${complexValues.legislationNames
          ?.map(legislation => legislation.label)
          .join(', ')}`
      }
      if (property.endsWith('spends.periodName') && complexValues?.periodNames) {
        return `${formatMessage({ id: `dataHub.spendPeriods` })}: ${complexValues.periodNames
          ?.map(period => period.label)
          .join(', ')}`
      }
      if (property.endsWith('periodName') && complexValues?.periodNames) {
        return `${formatMessage({ id: `dataHub.periods` })}: ${complexValues.periodNames
          ?.map(period => period.label)
          .join(', ')}`
      }
      if (property.endsWith('verifications.externalVerificationStatus') && complexValues?.verificationStatuses) {
        return `${formatMessage({ id: 'filters.responseItem.externalVerificationStatus' })}: ${complexValues.verificationStatuses
          ?.map(status =>
            formatMessage({
              id: `reporting.reviewStatuses.${status.label}`,
            }),
          )
          .join(', ')}`
      }
      if (
        property.endsWith('verifications.internalVerificationStatus') &&
        complexValues?.internalVerificationStatuses
      ) {
        return `${formatMessage({ id: 'filters.responseItem.internalVerificationStatus' })}: ${complexValues.internalVerificationStatuses
          ?.map(status =>
            formatMessage({
              id: `reporting.internalVerificationStatuses.${status.label}`,
            }),
          )
          .join(', ')}`
      }

      if (property.endsWith('categoryOptions.id')) {
        return (
          <>
            {`${formatMessage({ id: `dashboard.sourcing.companyStatistics.customCategories` })}: `}

            {value.map((v, i) => (
              <CategoryOptionLabel
                key={v}
                categoryOptionId={String(v)}
                categoryCount={value.length}
                isLastItem={i === value.length - 1}
              />
            ))}
          </>
        )
      }
      return ''
    }
    if (property.endsWith('submittedAt')) {
      return `${formatMessage({ id: `schemas.response.${operator === Operators.GreaterThanOrEqual ? 'responseDateFrom' : 'responseDateTo'}` })}: ${value}`
    }
    if (property.endsWith('amount')) {
      return `${formatMessage({ id: `dataHub.${operator === Operators.GreaterThanOrEqual ? 'spendFrom' : 'spendTo'}` })}: ${value}`
    }
    return ''
  }

  return { getFilterLabel }
}
