import { Add } from '@mui/icons-material'
import { Chip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { ProviderPriority } from '@app/src/types/categories'
import ButtonCell, { ButtonCellProps } from './ButtonCell'
import ParentCellOrFragment from './ParentCellOrFragment'
import ValueCell from './ValueCell'

type Props = {
  value?: ProviderPriority
  disableCell?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>
  size?: ButtonCellProps['size']
}

const PriorityCell: React.FC<Props> = ({ value, disableCell, size, onClick }) => {
  const { formatMessage } = useIntl()

  if (value === ProviderPriority.NotSelected) {
    return onClick ? (
      <ButtonCell
        label={formatMessage({ id: 'general.add' })}
        startIcon={<Add />}
        onClick={onClick}
        disableCell={disableCell}
        size={size}
      />
    ) : (
      <ValueCell
        value={formatMessage({ id: `schemas.provider.priorityValues.${value}` })}
        disableCell={disableCell}
        onClick={onClick}
      />
    )
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Chip
        clickable={Boolean(onClick)}
        onClick={onClick}
        sx={{ bgcolor: 'white' }}
        variant="outlined"
        size="small"
        label={formatMessage({ id: `schemas.provider.priorityValues.${value}` })}
      />
    </ParentCellOrFragment>
  )
}

export default PriorityCell
