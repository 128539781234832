import { Box, Typography } from '@mui/material'
import React from 'react'

import Markdown from '@app/src/components/Markdown'
import RequestItem from '@app/src/pages/Questionnaire/Provider/Report/RequestItem'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { RequestSection } from '@app/src/types/resourceExplorer'
import { useIntl } from 'react-intl'

type SectionProps = {
  section: RequestSection
  sectionNumber: number
}

const Section: React.FC<SectionProps> = ({ section, sectionNumber }) => {
  const { response } = useReport()
  const { formatMessage } = useIntl()

  return (
    <>
      <Box display="flex" flexDirection="column" mt={sectionNumber > 1 ? 7 : 0}>
        <Typography variant="overline" gutterBottom>
          {formatMessage({ id: 'questionnaire.section' }, { number: sectionNumber })}
        </Typography>
        <Typography variant="h2" gutterBottom>
          <Markdown>{section.title}</Markdown>
        </Typography>
        <Typography variant="body1">
          <Markdown>{section.description}</Markdown>
        </Typography>
      </Box>

      {section.items.map((item, index) => {
        const responseItem = response?.items.find(responseItem => responseItem.requestItemId === item.id)
        return (
          <RequestItem
            key={item.id}
            requestItem={item}
            responseItem={responseItem}
            itemNumber={index + 1}
            sectionNumber={sectionNumber}
          />
        )
      })}
    </>
  )
}

export default Section
