import Drawer from '@app/src/components/Drawer_DEPRECATED'
import { OrganisationDrawerProps } from '@app/src/components/Form'
import CategoryDrawerContent, {
  CategoryDrawerViews,
  ProviderUpdateType,
} from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import React from 'react'
import { Category } from '@app/src/types/categories'

type UpdateProviderCategoryDrawerProps = {
  selectedProviders: ProviderUpdateType[]
  categoryName: Category
  setSelectedProviders: React.Dispatch<React.SetStateAction<Array<number>>>
}

const UpdateProviderCategoryDrawer = ({
  categoryName,
  closeDrawer,
  isDrawerOpen,
  selectedProviders,
  setSelectedProviders,
}: UpdateProviderCategoryDrawerProps & OrganisationDrawerProps): JSX.Element => {
  return (
    <Drawer open={isDrawerOpen} closeDrawer={closeDrawer}>
      <CategoryDrawerContent
        category={categoryName}
        setSelectedProviders={setSelectedProviders}
        providersUpdate={selectedProviders}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        view={CategoryDrawerViews.SetStandardCategory}
      />
    </Drawer>
  )
}

export default UpdateProviderCategoryDrawer
