import Permissions, { usePermissions, UsePermissionsType } from '@app/src/auth/permissions'
import { ConfigItems } from '@app/src/pages/Configurations/ConfigurationsScene'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'

const hasPermissionToShow = (
  permissions: Permissions | Permissions[] = [],
  hasPermission: UsePermissionsType['hasPermission'],
) => {
  const filteredPermissions = [permissions].flat().filter(permission => permission)

  if (!filteredPermissions.length) {
    return true
  }

  return filteredPermissions.some(permission => hasPermission(permission as Permissions))
}

const useConfigurationFilter = (items: ConfigItems) => {
  const { hasPermission } = usePermissions()
  const flags = useFlags()

  return useMemo(
    () =>
      items.reduce<ConfigItems>((prev, curr) => {
        const hasPermissions = hasPermissionToShow(curr.permissions, hasPermission)
        const featureToggleEnabled = !curr.featureToggleName || Boolean(flags[curr.featureToggleName])
        if (hasPermissions && featureToggleEnabled) {
          prev.push(curr)
        }

        return prev
      }, []),
    [items, flags],
  )
}

export default useConfigurationFilter
