import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import { useAiSuggestionsUploadFile } from '@app/src/hooks/useAiSuggestionsUploadFile'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import FileRepoHeader from '@app/src/pages/Configurations/ConfigurationsPages/FileRepository/FileRepoHeader'
import FileRepoRow from '@app/src/pages/Configurations/ConfigurationsPages/FileRepository/FileRepoRow'
import { AutoAwesomeOutlined, UploadFileOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'

export interface FileResult {
  name: string
  createdAt: string
}

const FileRepository: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { formatMessage } = useIntl()
  const { isWaitingToReload, isUploading, uploadFile } = useAiSuggestionsUploadFile()

  const {
    data: files,
    isLoading,
    isError,
  } = useFetchResource<FileResult[]>({
    endpoint: endpoints.suggestionFiles,
    key: FetchKey.FileRepository,
  })

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      await uploadFile(selectedFile)
    }
  }

  return (
    <ConfigurationCollection
      actionButtons={[
        {
          label: formatMessage({ id: 'schemas.repositoryFile.upload' }),
          onClick: handleFileClick,
          variant: 'contained',
          loading: isUploading,
          startIcon: <UploadFileOutlined />,
        },
      ]}
      enablePadding={{ right: true, top: false }}
    >
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      <Table<FileResult>
        HeaderComponent={() => <FileRepoHeader />}
        RowComponent={({ row }) => <FileRepoRow file={row} />}
        data={files?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) ?? []}
        isLoading={isLoading || isUploading || isWaitingToReload}
        isError={isError}
        count={10000}
        page={1}
        pageSize={10000}
        noPagination
        emptyState={
          <EmptyState
            title={formatMessage({ id: 'schemas.repositoryFile.emptyStateTitle' })}
            iconComponent={AutoAwesomeOutlined}
            customIconColor="communication.dark"
            description={formatMessage({ id: 'schemas.repositoryFile.emptyStateDescription' })}
            negative
          >
            <LoadingButton
              onClick={handleFileClick}
              loading={isUploading}
              startIcon={<UploadFileOutlined />}
              variant="outlined"
            >
              {formatMessage({ id: 'schemas.repositoryFile.upload' })}
            </LoadingButton>
          </EmptyState>
        }
      />
    </ConfigurationCollection>
  )
}

export default FileRepository
