import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { SimpleOrganization } from '@app/src/types/organizations'
import paths from '@app/src/wf-constants/paths'
import { TableRow } from '@mui/material'
import React from 'react'
import { generatePath } from 'react-router'

interface UserRowProps {
  row: SimpleOrganization
}

export const OrganizationRow = ({ row: organization }: UserRowProps): JSX.Element => {
  return (
    <TableRow>
      <ValueCell value={organization.id} />
      <ButtonCell
        to={generatePath(paths.configurations, {
          configurationsPage: CONFIGURATION_PAGE_IDS.Organizations,
          configurationsSubPage: organization.id,
        })}
        label={`${organization.name}`}
      />
      <ValueCell value={organization.vatNumber} />
      <ValueCell value={organization.websiteAddress} />
    </TableRow>
  )
}

export default OrganizationRow
