import { FilterInterface, useFiltersContext } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import React, { useEffect } from 'react'

export interface FilterChildrenRenderProps {
  filterName: string
  value: FilterInterface['value']
  operator: Operators
  onChange: (value: FilterInterface['value'] | null, replace?: boolean) => void
}

interface Props {
  name: string
  operator: Operators
  children: ({ value, onChange }: FilterChildrenRenderProps) => JSX.Element
  isQuickFilter?: boolean
  uniqueId?: string
  intersect?: boolean //Controls filter combination. If false: response.items.some(it => it.filter1 && it.filter2) if true: response.items.some(it => it.filter1) && response.items.some(it => it.filter2)
}

export const hasMultipleFilter = (filter: FilterInterface | FilterInterface[]): filter is FilterInterface[] => {
  return Array.isArray(filter)
}

const getFilterValue = (filter: FilterInterface | FilterInterface[], uniqueId?: string): FilterInterface['value'] => {
  if (hasMultipleFilter(filter)) {
    return filter?.find(f => f.uniqueId === uniqueId)?.['value'] ?? ''
  }
  if (!uniqueId || (uniqueId && filter?.uniqueId === uniqueId)) {
    return filter?.value ?? ''
  }
  return ''
}

const Filter = ({ name, operator, children, isQuickFilter, uniqueId, intersect }: Props): JSX.Element => {
  const { allowedFilters, filters, setQueryFilter, addQuickFilter, setQueryFilterOperator } = useFiltersContext()

  useEffect(() => {
    if (Boolean(allowedFilters.length) && !allowedFilters.includes(name)) {
      console.error(
        'Using the filter',
        `"${name}"`,
        'which is not included in the allowed filters.',
        'Allowed filters are:',
        allowedFilters,
      )
    }
  }, [name, allowedFilters])

  useEffect(() => {
    if (isQuickFilter) addQuickFilter(name)
  }, [isQuickFilter, name])

  useEffect(() => {
    setQueryFilterOperator(name, operator, uniqueId)
  }, [operator])

  const filter = filters[name]
  const value = getFilterValue(filter, uniqueId)

  const handleChange = (value: FilterInterface['value'] | null, replace?: boolean) => {
    setQueryFilter(name, value ?? '', operator, uniqueId, replace, intersect)
  }

  return (
    <>
      {children({
        filterName: name,
        value,
        operator,
        onChange: handleChange,
      })}
    </>
  )
}

export default Filter
