import * as amplitude from '@amplitude/analytics-browser'
import { createContext, useContext } from 'react'
import { AccountContextProps } from './AccountContext'

export type TrackEvent = {
  name: string
  /* eslint-disable @typescript-eslint/no-explicit-any */
  eventProps?: Record<string, any>
  eventOptions?: amplitude.Types.EventOptions
}

export type AmplitudeContextProps = {
  trackEvent: (event: TrackEvent) => void
  trackEventCloseBrowser: (event: TrackEvent) => void
  account: AccountContextProps['account']
}

const AmplitudeContext = createContext<AmplitudeContextProps>({
  trackEvent: () => undefined,
  trackEventCloseBrowser: () => undefined,
  account: undefined,
})

export const useAmplitude = (): AmplitudeContextProps => {
  return useContext(AmplitudeContext)
}

export default AmplitudeContext
