import Tabs from '@app/src/components/Tabs'
import { useQueryState } from '@app/src/hooks/queryState'
import CustomQuestionCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/CustomQuestionCollection'
import { QuestionsConfigTabs } from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/QuestionsConfig'
import StandardQuestionCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/StandardQuestionCollection'
import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, Divider, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

interface SelectQuestionsModalProps {
  isOpen: boolean
  confirmDialogSelection: (addedQuestionIds: Array<number>) => void
  cancelDialogSelection: () => void
  questionIdsAlreadyInQuestionnaire: Array<number>
  templateTitle?: string
}

const SelectQuestionsModal: React.FC<SelectQuestionsModalProps> = ({
  isOpen,
  confirmDialogSelection,
  cancelDialogSelection,
  questionIdsAlreadyInQuestionnaire,
  templateTitle,
}) => {
  const { formatMessage } = useIntl()
  const [currentTab, setCurrentTab] = useState<QuestionsConfigTabs>(QuestionsConfigTabs.Standard)

  const [selectedQuestionIds, setSelectedQuestionIds] = useState<Array<number>>([])
  const [, setQueryFilters] = useQueryState('filters', [])
  const tabs = [
    {
      type: QuestionsConfigTabs.Standard,
      label: formatMessage({ id: 'questionnaireConfig.standardTab' }),
      url: '',
    },
    {
      type: QuestionsConfigTabs.Custom,
      label: formatMessage({ id: 'questionnaireConfig.customTab' }),
      url: '',
    },
  ]

  useEffect(() => {
    if (isOpen) return

    setSelectedQuestionIds([])
    setQueryFilters([])
  }, [isOpen])

  const selectionProps = { selectedQuestionIds, setSelectedQuestionIds, questionIdsAlreadyInQuestionnaire }

  return (
    <Dialog
      onClose={cancelDialogSelection}
      open={isOpen}
      fullScreen
      sx={{ m: 2 }}
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <Box height="100%" margin={0} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" py={2} px={4}>
          <Stack>
            <Typography variant="h6">{formatMessage({ id: 'templateBuilder.selectQuestions.questions' })}</Typography>
            <Typography variant="body2" color="textSecondary">
              {templateTitle}
            </Typography>
          </Stack>

          <IconButton onClick={cancelDialogSelection}>
            <Close />
          </IconButton>
        </Box>

        <Divider />
        <Tabs
          sx={{ px: 4 }}
          tabs={tabs}
          activeTab={currentTab}
          handleChange={(_, value) => setCurrentTab(value as QuestionsConfigTabs)}
        />
        {currentTab === QuestionsConfigTabs.Standard && <StandardQuestionCollection selectionProps={selectionProps} />}
        {currentTab === QuestionsConfigTabs.Custom && <CustomQuestionCollection selectionProps={selectionProps} />}
        <Box display="flex" justifyContent="right" px={6} py={2} gap={1}>
          <Button disabled={selectedQuestionIds.length === 0} onClick={() => setSelectedQuestionIds([])}>
            {formatMessage({ id: 'filters.clearAll' })}
          </Button>
          <Button
            variant="contained"
            disabled={!selectedQuestionIds.length}
            onClick={() => confirmDialogSelection(selectedQuestionIds)}
          >
            {formatMessage(
              { id: 'templateBuilder.selectQuestions.addToQuestionnaire' },
              { count: selectedQuestionIds.length },
            )}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default SelectQuestionsModal
