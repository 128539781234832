import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Alert } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ProviderSelectorWithFilters from '@app/src/components/CreateNewRequestModal/ProviderSelectorWithFilters'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import ControlledDateField from '@app/src/components/Form/ControlledDateField'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { BaseFormData } from '..'
import { Periods } from '../PeriodField'
import RequestQuestionStep from '../RequestQuestionStep'
import ReviewTable from '../ReviewTable'
import PreviewAndSelect from './PreviewAndSelect'

type SfdrReportProps = {
  onClose: () => void
  onBack: () => void
  disableInitialBack: boolean
}

export enum FormValuesEnum {
  DATE_TYPE = 'dateType',
  DATE_YEAR = 'dateYear',
}

export type FormData = BaseFormData

export enum SFDR_REPORT_QUESTION_KEYS {
  TEMPLATE_IDS = 'templateIds',
  PERIOD_NAME = 'dateYear',
  RESPONDERS = 'responders',
  DEADLINE = 'deadline',
  REVIEW = 'review',
  ACTIVATE_AT = 'activateAt',
}

const useStyles = makeStyles(({ spacing }) => ({
  infoAlert: {
    marginTop: spacing(2),
  },
}))

const SfdrReport: React.FC<SfdrReportProps> = ({ onClose, onBack, disableInitialBack }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { activeStep, setTotalSteps } = useCreationModalProgress()

  const formMethods = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: { dateType: Periods.Yearly, templateIds: [], responders: [], deadline: '' },
  })
  const { control, getValues, setValue } = formMethods

  const WF_REPORT_QUESTIONS = [
    {
      title: formatMessage({ id: 'form.createRequest.sfdr.title' }),
      description: formatMessage({ id: 'form.createRequest.sfdr.body' }),
      fieldnames: [SFDR_REPORT_QUESTION_KEYS.TEMPLATE_IDS],
      hasSkipButton: false,
      children: <PreviewAndSelect />,
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectCompaniesTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectCompaniesBody' }),
      fieldnames: [SFDR_REPORT_QUESTION_KEYS.RESPONDERS],
      children: <ProviderSelectorWithFilters control={control} setValue={setValue} />,
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectDeadlineTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectDeadlineBody' }),
      fieldnames: [SFDR_REPORT_QUESTION_KEYS.DEADLINE],
      hasSkipButton: true,
      children: (
        <ControlledDateField
          disablePast
          fieldLabel={formatMessage({ id: 'form.createRequest.selectDeadlineLabel' })}
          control={control}
          name="deadline"
          slotProps={{ textField: { fullWidth: true } }}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.reviewTitle' }),
      description: (
        <Alert severity="info" className={classes.infoAlert}>
          {formatMessage({ id: 'form.createRequest.reviewInfo' })}
        </Alert>
      ),
      fieldnames: [],
      children: <ReviewTable data={getValues()} />,
    },
  ]

  const activeQuestion = useMemo(() => WF_REPORT_QUESTIONS[activeStep - 1], [activeStep])

  useEffect(() => {
    setTotalSteps(WF_REPORT_QUESTIONS.length)
  }, [])

  return (
    <CreationModalContainer title={formatMessage({ id: 'form.createRequest.sfdr.modalTitle' })} onClose={onClose}>
      <FormProvider {...formMethods}>
        {activeQuestion && (
          <RequestQuestionStep
            fieldnames={activeQuestion.fieldnames}
            title={activeQuestion.title}
            description={activeQuestion.description}
            hasSkipButton={activeQuestion.hasSkipButton}
            key={activeQuestion.title}
            onClose={onClose}
            onBack={onBack}
            disableInitialBack={disableInitialBack}
          >
            {React.cloneElement(activeQuestion.children, { fieldnames: activeQuestion.fieldnames })}
          </RequestQuestionStep>
        )}
      </FormProvider>
    </CreationModalContainer>
  )
}

export default SfdrReport
