import { alpha, Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface GraphHoverDialogLink {
  text: string
  action: () => void
}

export interface GraphHoverDialogItem {
  title: string
  description: string
  chipColor: string
  dialogLink: GraphHoverDialogLink
  subRows?: string[]
}

interface GraphHoverDialogProps {
  title: React.ReactNode
  dialogItems: GraphHoverDialogItem[]
}

const useStyles = makeStyles({
  hideOverflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  clickableRow: {
    '&:hover': {
      background: alpha('#000', 0.05),
      textDecoration: 'none',
    },
    cursor: 'pointer',
  },
})
const GraphHoverDialogContent: React.FC<GraphHoverDialogProps> = ({ title, dialogItems }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" width={450}>
      <Box className={classes.hideOverflow}>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <Box marginTop={2}>
        {dialogItems.map(({ title, description, dialogLink, subRows, chipColor }) => (
          <Grid
            container
            columnSpacing={1}
            alignItems="center"
            key={title}
            className={classes.clickableRow}
            onClick={dialogLink.action}
          >
            <Grid item md={1} display="flex" justifyContent="center">
              <Box width="4px" height="16px" bgcolor={chipColor}></Box>
            </Grid>
            <Grid item md={3}>
              <Typography variant="body1">{title}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="body1" className={classes.hideOverflow}>
                {description}
              </Typography>
            </Grid>
            <Grid item md={2} display="flex" justifyContent="end">
              <Button onClick={dialogLink.action} variant="text">
                {dialogLink.text}
              </Button>
            </Grid>
            {subRows && (
              <>
                <Grid item md={1}></Grid>
                <Grid item md={11}>
                  <Typography variant="caption">
                    {subRows.map(subRow => (
                      <Box key={subRow} mt={1} mb={1} className={classes.hideOverflow}>
                        {subRow}
                      </Box>
                    ))}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Box>
    </Box>
  )
}
export default GraphHoverDialogContent
