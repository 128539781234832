import Select from '@app/src/components/Form/Select/ControlledSelect'
import { RequestItemWithOrderNumbers } from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/Flags/ReportFlagRules'
import { ReportFlagCheckValue, ReportFlagRule, ReportFlagType } from '@app/src/types/flags'
import { Box } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface ReportFlagOptionRulesProps {
  name: string
  requestItem: RequestItemWithOrderNumbers
  existingCheckValues?: ReportFlagCheckValue[]
}

interface FlagFormData extends Omit<ReportFlagRule, 'checkValue'> {
  checkValue: ReportFlagCheckValue[] | ReportFlagCheckValue
}

const ReportFlagOptionRules: React.FC<ReportFlagOptionRulesProps> = ({ name, requestItem, existingCheckValues }) => {
  const { setValue, control, errors, watch } = useFormContext<{
    rules: FlagFormData[]
    conditionType: { message: string }
  }>()
  const { formatMessage } = useIntl()

  const isMultiple = requestItem.allowMultiChoice ?? false
  const values = watch()

  return (
    <Box p={4}>
      {isMultiple ? (
        <Select
          defaultValue={existingCheckValues ?? []}
          name={name}
          findSelectedValue={(value, option) =>
            [value]
              .flat()
              .map(r => r.value?.toLowerCase())
              .includes(option.value.value.toLowerCase())
          }
          multiple
          required
          onInputChange={(e, value) => {
            if (!value) {
              setValue(name, undefined)
            }
          }}
          control={control}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          error={errors.conditionType?.message}
          fieldLabel={formatMessage({ id: 'automations.triggerDropdownLabel' })}
          options={
            requestItem.requestCollection?.items.map(value => ({
              label: value.value,
              value: { flag: ReportFlagType.Red, value: value.value.toString() },
              disabled: Boolean(
                values.rules.find(r =>
                  Array.isArray(r.checkValue)
                    ? r.checkValue?.some(cv => cv?.value === value?.value)
                    : r.checkValue?.value === value?.value,
                ),
              ),
            })) ?? []
          }
        />
      ) : (
        <Select
          name={name}
          findSelectedValue={(value, option) =>
            option.value.value?.toLowerCase() === [value].flat()?.[0]?.value?.toLowerCase()
          }
          defaultValue={existingCheckValues?.[0]}
          multiple={false}
          control={control}
          required
          onInputChange={(e, value) => {
            if (!value) {
              setValue(name, undefined)
            }
          }}
          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
          error={errors.conditionType?.message}
          fieldLabel={formatMessage({ id: 'automations.triggerDropdownLabel' })}
          options={
            requestItem.requestCollection?.items.map(value => ({
              label: value.value,
              value: { flag: ReportFlagType.Red, value: value.value.toString() },
              disabled: Boolean(
                values.rules.find(r =>
                  Array.isArray(r.checkValue) ? r.checkValue?.[0]?.value : r.checkValue?.value === value?.value,
                ),
              ),
            })) ?? []
          }
        />
      )}
    </Box>
  )
}

export default ReportFlagOptionRules
