import { QuestionType } from '@app/src/types/reporting'
import React from 'react'
import NumberClassification from './NumberClassification'
import OptionsClassification from './OptionsClassification'

type AdvancedClassificationProps = {
  questionTypeId: QuestionType
}

const AdvancedClassification: React.FC<AdvancedClassificationProps> = ({ questionTypeId }) => {
  if (questionTypeId === QuestionType.Number) {
    return <NumberClassification />
  }

  if (questionTypeId === QuestionType.Options) {
    return <OptionsClassification />
  }

  return null
}

export default AdvancedClassification
