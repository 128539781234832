import Permissions, { usePermissions } from '@app/src/auth/permissions'
import type { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems'
import React from 'react'
import { useIntl } from 'react-intl'
import navbarLinks from '@app/src/wf-constants/navbarLinks'
import MenuWrapper, { MenuWrapperProps } from './MenuWrapper'

const TransparencyProductMenu: React.FC<NavbarItemProps> = ({ closeMenuDrawer }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()

  if (!hasPermission(Permissions.PRODUCT_MAPPING_RESPOND_ACCESS)) return null

  const menuItems: MenuWrapperProps['menuItems'] = [
    {
      title: formatMessage({ id: 'navbar.receivedMappingRequests' }),
      link: navbarLinks.mappingRequests,
      permission: [Permissions.TRANSPARENCY_USER],
    },
    {
      title: formatMessage({ id: 'navbar.companies' }),
      link: navbarLinks.productMappingCompanies,
      permission: [Permissions.TRANSPARENCY_USER],
    },
    {
      title: formatMessage({ id: 'navbar.contacts' }),
      link: navbarLinks.productMappingContacts,
    },
    {
      title: formatMessage({ id: 'navbar.allProducts' }),
      link: navbarLinks.products,
      permission: [Permissions.TRANSPARENCY_USER],
    },
  ]

  return (
    <MenuWrapper
      closeMenuDrawer={closeMenuDrawer}
      menuItems={menuItems}
      title={formatMessage({ id: 'navbar.productMapping' })}
    />
  )
}

export default TransparencyProductMenu
