import WifiOffIcon from '@mui/icons-material/WifiOff'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import GuidanceBanner from '@app/src/components/GuidanceBanner'
import Table from '@app/src/components/Table'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { SortOrder } from '@app/src/types/filter'
import { PeriodDetails, ProviderProgressView, ResponseItem } from '@app/src/types/resourceExplorer'
import sortDistinctPeriodLabels from '@app/src/utils/sortDistinctPeriodLabels'
import { br } from '@app/src/utils/translationMarkup'
import { QuestionTypes } from '@app/src/wf-constants'
import ProviderProgressFilter from '../Filters/ProviderProgressFilter'
import {
  FilterGroup,
  Operators,
  RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
  RESQUEST_ITEM_IS_DELETED_FILTER,
} from '../Filters/useFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import ProviderProgressHeader from './ProviderProgress/ProviderProgressHeader'
import ProviderProgressRow from './ProviderProgress/ProviderProgressRow'

type TransparencyProviderProgressCollectionProps = {
  relatedContentFilter?: FilterGroup[]
}

export const allowedFilters = ['response.request.template.id', 'response.request.periodName']

const TransparencyProviderProgressCollection: React.FC<TransparencyProviderProgressCollectionProps> = ({
  relatedContentFilter = [],
}) => {
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const [isBannerVisible, setBannerVisible] = useState(true)

  const payload = {
    filter: [
      ...userFilters,
      ...relatedContentFilter,
      RESPONSE_ITEM_LATEST_SUBMITTED_FILTER,
      RESQUEST_ITEM_IS_DELETED_FILTER,
    ],
    include: [
      'response.request',
      'response.request.template',
      'requestItem.unit',
      'requestItem.questionType',
      'requestItem.requestCollection',
    ],
    pagination: {
      pageNumber: 1,
      itemsPerPage: 1000,
    },
    sort: {
      target: 'requestItem.questionId',
      order: SortOrder.DESCENDING,
    },
  }

  const { items, count, isLoading, isError } = useFetchCollectionWithPost<ResponseItem>({
    key: FetchKey.ProviderProgressPage,
    endpoint: endpoints.responseItemsCollection,
    payload,
  })

  const {
    facets: [allAvailablePeriods],
    isLoading: isLoadingPeriods,
  } = useFetchFacets({
    key: [FetchKey.ResponseItemsFacets],
    endpoint: endpoints.responseItemsWithFacets,
    options: { enabled: Boolean(userFilters.length) },
    facetsParam: [{ name: 'response.request.periodName' }],
    filter: [...userFilters, { name: 'isAnswered', filters: [{ operator: Operators.EqualTo, value: true }] }],
  })

  const getAnswer = (item: ResponseItem) => {
    switch (item.requestItem.questionType.name) {
      case QuestionTypes.File:
        return item.cannotAnswer
          ? formatMessage({ id: 'schemas.providerProgress.fileUpload.weDoNotHaveThis' })
          : formatMessage({ id: 'schemas.providerProgress.fileUpload.uploaded' })
      case QuestionTypes.Options:
      case QuestionTypes.Number:
      case QuestionTypes.Text:
        return item.answer
    }
  }

  const transformData = (data?: ResponseItem[]) => {
    const groupedData = data?.reduce<Record<number, ProviderProgressView>>((acc, item) => {
      const questionId = item.requestItem.questionId

      if (!acc[questionId]) {
        acc[questionId] = {
          id: questionId,
          templateId: item.response.request.questionnaireTemplateId,
          requestId: item.response.request.id,
          responseId: item.responseId,
          parentObject: {
            id: questionId,
            title: item.requestItem.description,
            questionType: item.requestItem.questionType.name,
            unit: item.requestItem.unit,
          },
          periodDetails: [],
        }
      }

      let collectionResponse

      if (item.isCollection) {
        collectionResponse = item.requestItem.allowMultiChoice
          ? `${item.selectedOptions.length} / ${item.requestItem.requestCollection.items.length}`
          : item.selectedOptions[0]
      }

      const periodDetails: PeriodDetails = {
        periodName: item.response.request.periodName,
        answer: getAnswer(item),
        cannotAnswer: item.cannotAnswer,
        collectionResponse,
        isMultiSelectOptionQuestion: Boolean(item.requestItem.allowMultiChoice),
      }

      acc[questionId].periodDetails.push(periodDetails)
      return acc
    }, {})

    return Object.values(groupedData ?? [])
  }

  const transformedData = useMemo(() => transformData(items), [items])
  const sortedDistinctAvailablePeriodLabels = sortDistinctPeriodLabels(allAvailablePeriods)

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'resourceTypes.yourProgress' })}
      filter={<ProviderProgressFilter allowedFilters={allowedFilters} implicitFilters={relatedContentFilter} />}
      subtitle={
        isBannerVisible && (
          <GuidanceBanner
            title={formatMessage({ id: 'schemas.providerProgress.bannerTitle' })}
            description={formatMessage({ id: 'schemas.providerProgress.bannerDescription' }, { br })}
            buttons={[
              {
                label: formatMessage({ id: 'general.close' }),
                onClick: () => setBannerVisible(false),
              },
            ]}
          />
        )
      }
    >
      <Table<ProviderProgressView>
        HeaderComponent={() => <ProviderProgressHeader periods={sortedDistinctAvailablePeriodLabels} />}
        RowComponent={({ row }): JSX.Element => (
          <ProviderProgressRow periods={sortedDistinctAvailablePeriodLabels} row={row} />
        )}
        data={transformedData}
        page={1}
        pageSize={count}
        noPagination
        isLoading={isLoading || isLoadingPeriods}
        count={count}
        isError={isError}
        emptyState={
          <EmptyState
            iconComponent={WifiOffIcon}
            title={formatMessage({ id: 'schemas.providerProgress.emptyTransparencyQuestionnaireState.title' })}
            description={formatMessage({
              id: 'schemas.providerProgress.emptyTransparencyQuestionnaireState.description',
            })}
            sx={{ my: 2 }}
          />
        }
      />
    </ResourceCollectionScene>
  )
}

export default TransparencyProviderProgressCollection
