import { Alert, Box, CircularProgress, Grid, Typography } from '@mui/material'
import TextField from '@app/src/components/Ui/TextField'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

type EdciDialogContentProps = {
  isLoading: boolean
}

const EdciDialogContent: React.FC<EdciDialogContentProps> = ({ isLoading }) => {
  const { formatMessage } = useIntl()
  const { register, setValue } = useFormContext()

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <Box mb={2}>
        <Alert severity="info">{formatMessage({ id: 'form.createRequest.edci.reviewInfo' })}</Alert>
      </Box>

      <Typography variant="body1" color="textSecondary" paragraph>
        {formatMessage({ id: 'form.createRequest.edci.reviewBody' })}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            fullWidth
            name="gpName"
            label={formatMessage({ id: 'form.createRequest.edci.gpName' })}
            variant="outlined"
            type="text"
            required
            disabled
            onClear={(): void => setValue('gpName', '')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            fullWidth
            name="gpKey"
            label={formatMessage({ id: 'form.createRequest.edci.gpKey' })}
            variant="outlined"
            type="text"
            required
            disabled
            onClear={(): void => setValue('gpKey', '')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            fullWidth
            name="gpTotalProcos"
            label={formatMessage({ id: 'form.createRequest.edci.gpTotalPortcos' })}
            variant="outlined"
            type="number"
            required
            onClear={(): void => setValue('gpTotalProcos', '')}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EdciDialogContent
