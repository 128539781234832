import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Tabs from '@app/src/components/Tabs'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import StatisticsCard from '@app/src/pages/Dashboards/StatisticsCard'
import { CompanyProgressSection } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/Overview/CompanyProgressSection'
import { SortOrder } from '@app/src/types/filter'
import { QuestionnaireCompanyProgressStatistics } from '@app/src/types/resourceExplorer'
import { Box, CircularProgress, Divider, Stack, TablePagination } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

enum OverviewCompanyProgressSceneViews {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}

interface OverviewCompanyProgressProps {
  allowedFilters?: string[]
}

const OverviewCompanyProgressSection: React.FC<OverviewCompanyProgressProps> = ({ allowedFilters }) => {
  const { formatMessage } = useIntl()
  const [activeView, setActiveView] = useState(OverviewCompanyProgressSceneViews.IN_PROGRESS)
  const userFilters = useGetApiQueryFilters(allowedFilters ?? [])

  const [page, pageSize, setPage] = usePagination({ page: 1, pageSize: 20 })

  const { data: inProgressCompanies, isLoading: inProgressCompaniesIsLoading } =
    useFetchCollectionWithPost<QuestionnaireCompanyProgressStatistics>({
      key: [FetchKey.QuestionnaireCompanyStatistics, OverviewCompanyProgressSceneViews.IN_PROGRESS],
      endpoint: endpoints.companyOverviewStatisticsInProgress,
      payload: {
        filter: userFilters,
        include: [],
        pagination: {
          pageNumber: page,
          itemsPerPage: pageSize,
        },
        sort: {
          target: 'responderCompanyName',
          order: SortOrder.DESCENDING,
        },
      },
    })

  const { data: completedCompanies, isLoading: completedCompaniesIsLoading } =
    useFetchCollectionWithPost<QuestionnaireCompanyProgressStatistics>({
      key: [FetchKey.QuestionnaireCompanyStatistics, OverviewCompanyProgressSceneViews.COMPLETED],
      endpoint: endpoints.companyOverviewStatisticsCompleted,
      payload: {
        filter: userFilters,
        include: [],
        pagination: {
          pageNumber: page,
          itemsPerPage: pageSize,
        },
        sort: {
          target: 'responderCompanyName',
          order: SortOrder.DESCENDING,
        },
      },
    })

  const tabs = [
    {
      type: OverviewCompanyProgressSceneViews.IN_PROGRESS,
      label: formatMessage({ id: 'dashboard.inProgressWithCount' }, { count: inProgressCompanies?.totalResult ?? 0 }),
      url: '',
    },
    {
      type: OverviewCompanyProgressSceneViews.COMPLETED,
      label: formatMessage({ id: 'dashboard.completedWithCount' }, { count: completedCompanies?.totalResult ?? 0 }),
      url: '',
    },
  ]

  if (inProgressCompaniesIsLoading || completedCompaniesIsLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <StatisticsCard title={formatMessage({ id: 'statistics.companyProgressOverview' })}>
      <Stack width="100%" height="100%" justifyContent="space-between">
        <Stack width="100%">
          <Tabs
            activeTab={activeView}
            tabs={tabs}
            handleChange={(_, value) => {
              setActiveView(value as OverviewCompanyProgressSceneViews)
              setPage(1)
            }}
          />
          <Divider />
          <Stack>
            {Boolean(inProgressCompanies?.items?.length) &&
              activeView === OverviewCompanyProgressSceneViews.IN_PROGRESS && (
                <>
                  {inProgressCompanies?.items?.map((statistics, i) => (
                    <React.Fragment key={statistics.providerId}>
                      <CompanyProgressSection statistics={statistics} userFilters={userFilters} />
                      {i !== inProgressCompanies?.items.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </>
              )}
            {Boolean(completedCompanies?.items?.length) &&
              activeView === OverviewCompanyProgressSceneViews.COMPLETED && (
                <>
                  {completedCompanies?.items?.map((statistics, i) => (
                    <React.Fragment key={statistics.providerId}>
                      <CompanyProgressSection statistics={statistics} userFilters={userFilters} />
                      {i !== completedCompanies?.items?.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </>
              )}
          </Stack>
          {(activeView === OverviewCompanyProgressSceneViews.IN_PROGRESS
            ? Boolean(inProgressCompanies?.items?.length)
            : Boolean(completedCompanies?.items?.length)) && (
            <TablePagination
              colSpan={3}
              count={
                activeView === OverviewCompanyProgressSceneViews.IN_PROGRESS
                  ? inProgressCompanies?.totalResult ?? 0
                  : completedCompanies?.totalResult ?? 0
              }
              rowsPerPage={20}
              page={page - 1}
              onPageChange={(e, page) => setPage(page + 1)}
              component="span"
              rowsPerPageOptions={[20]}
            />
          )}
        </Stack>
      </Stack>
    </StatisticsCard>
  )
}

export default OverviewCompanyProgressSection
