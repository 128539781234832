import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const SuggestedContactsHeader: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell label="" minWidth={TableCellWidth.EMPTY} />
      <TableHeaderCell label={formatMessage({ id: 'suggestedContact.requestedAt' })} minWidth={TableCellWidth.SMALL} />
      <TableHeaderCell label={formatMessage({ id: 'suggestedContact.status' })} minWidth={TableCellWidth.SMALL} />
      <TableHeaderCell label={formatMessage({ id: 'suggestedContact.provider' })} />
      <TableHeaderCell label={formatMessage({ id: 'suggestedContact.email' })} />
      <TableHeaderCell label={formatMessage({ id: 'suggestedContact.name' })} />
      <TableHeaderCell label={formatMessage({ id: 'suggestedContact.accessor' })} />
      <TableHeaderCell label={formatMessage({ id: 'suggestedContact.requestedBy' })} />
    </TableRow>
  )
}

export default SuggestedContactsHeader
