import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewProviderRiskCard from '@app/src/components/Drawer/Views/DrawerViewProviderRiskCard'
import ListItemSkeleton from '@app/src/components/Skeleton/ListItem'
import RiskChipCell, { getColorBasedOnRisk } from '@app/src/components/Table/Cells/RiskChipCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { RiskPrioritizationSummary } from '@app/src/types/resourceExplorer'
import { ChevronRightOutlined } from '@mui/icons-material'
import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

type RiskStatusOverviewProps = {
  providerId: number
}

const RiskStatusOverview: React.FC<RiskStatusOverviewProps> = ({ providerId }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const { data: riskPrioritization, isLoading: isLoadingRisk } = useFetchCollectionWithPost<RiskPrioritizationSummary>({
    key: FetchKey.ProviderRisk,
    endpoint: endpoints.riskPrioritization,
    payload: {
      sort: {
        order: SortOrder.DESCENDING,
        target: 'periodName',
      },
      filter: [
        {
          name: 'provider.id',
          filters: [
            {
              operator: Operators.EqualTo,
              value: providerId,
            },
          ],
        },
      ],
      include: ['provider'],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 1,
      },
    },
  })

  const { riskStatus, periodName } = riskPrioritization?.items?.[0]?.riskPrioritization ?? {}
  const { iconBgColor } = useMemo(() => getColorBasedOnRisk(riskStatus), [riskStatus])

  if (isLoadingRisk) {
    return (
      <ListItemSkeleton
        avatar
        listItemAvatarSx={{ minWidth: 64 }}
        avatarProps={{ size: AvatarSize.XL, sx: { bgcolor: iconBgColor } }}
        primary
        secondary
      />
    )
  }

  return (
    <ListItem disablePadding sx={{ color: 'text.primary' }}>
      <ListItemButton
        onClick={() =>
          openDrawer(<DrawerViewProviderRiskCard riskPrioritizationSummary={riskPrioritization?.items?.[0]} />)
        }
        disabled={!riskPrioritization?.items?.[0]}
      >
        <Box width="100%">
          <ListItemText
            primary={formatMessage({ id: 'schemas.providerRisk.riskStatusWithYear' }, { year: periodName })}
          />
          <RiskChipCell disableCell risk={riskStatus} size="small" />
        </Box>
        <ChevronRightOutlined color="action" />
      </ListItemButton>
    </ListItem>
  )
}

export default RiskStatusOverview
