import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import endpoints from '../api/endpoints'
import { FetchKey } from '../api/fetchHooks'
import { useCreateResource } from '../api/updateHooks'
import { useSnackbar } from '../context/SnackbarContext'
import { br } from '../utils/translationMarkup'
import { NotificationSeverity } from '../wf-constants'
import useErrorNotification from './errorNotification'

export const useAiSuggestionsUploadFile = () => {
  const [isWaitingToReload, setIsWaitingToReload] = React.useState(false)
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const confirm = useConfirm()
  const { showSnackbar } = useSnackbar()
  const { mutateAsync: uploadFileAsync, isLoading: isUploading } = useCreateResource<unknown, FormData>()

  const uploadFile = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    await uploadFileAsync(
      { url: endpoints.suggestionFiles, body: formData },
      {
        onSuccess: () => {
          setIsWaitingToReload(true)
          showSnackbar({
            message: formatMessage({ id: 'resourceExplorer.activity.successfullyUploadedFile' }),
            severity: NotificationSeverity.success,
          })
          setTimeout(() => {
            queryClient.invalidateQueries(FetchKey.SuggestedResponse)
            queryClient.invalidateQueries(FetchKey.SuggestedFiles)
            queryClient.invalidateQueries(FetchKey.FileRepository)
            setIsWaitingToReload(false)
          }, 2000)
        },
        onError: error => {
          if (error.statusCode === 403 && error.body?.message?.toLowerCase() === 'file extension not allowed') {
            confirm({
              title: formatMessage({ id: 'suggestedAnswers.fileFormatNotSupportedHeader' }),
              content: formatMessage({ id: 'suggestedAnswers.fileFormatNotSupportedText' }, { br }),
              confirmationText: formatMessage({ id: 'general.close' }),
              confirmationButtonProps: { variant: 'text' },
              hideCancelButton: true,
            })
            return
          }
          showErrorNotification({
            requestError: error,
            specificFallbackErrorMessage: formatMessage({ id: 'reporting.uploadFileError' }),
          })
        },
      },
    )
  }

  return { isWaitingToReload, isUploading, uploadFile }
}
