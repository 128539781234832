import CreationModalProgressContext from '@app/src/context/CreationModalProgressContext'
import React, { useEffect, useMemo, useState } from 'react'

interface Props {
  children: JSX.Element
}

const CreationModalProgressContextProvider = ({ children }: Props): JSX.Element => {
  const [totalSteps, setTotalSteps] = useState(0)
  const [activeStep, setActiveStep] = useState(0)
  const [allowsNextWithoutData, setAllowsNextWithoutData] = useState(false)
  const [enableEmptyState, setEnableEmptyState] = useState(false)

  useEffect(() => {
    if (activeStep === 0) setActiveStep(1)
  }, [totalSteps])

  useEffect(() => {
    setAllowsNextWithoutData(false)
  }, [activeStep])

  const progress = useMemo(() => {
    if (!totalSteps) return 0

    const currentProgress = Math.round((activeStep / totalSteps) * 100)
    return currentProgress > 100 ? 100 : currentProgress
  }, [activeStep, totalSteps])

  const contextValue = useMemo(
    () => ({
      activeStep,
      totalSteps,
      progress,
      setTotalSteps,
      setActiveStep,
      allowsNextWithoutData,
      setAllowsNextWithoutData,
      enableEmptyState,
      setEnableEmptyState,
    }),
    [activeStep, totalSteps, progress, setTotalSteps, setActiveStep, allowsNextWithoutData],
  )

  return <CreationModalProgressContext.Provider value={contextValue}>{children}</CreationModalProgressContext.Provider>
}

export default CreationModalProgressContextProvider
