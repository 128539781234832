import LinkButton from '@app/src/components/LinkButton'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import paths from '@app/src/wf-constants/paths'
import { Check, CloseOutlined } from '@mui/icons-material'
import { Button, Dialog, DialogContent, Divider, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface SubmitSuccessScreenProps {
  open: boolean
  onClose: () => void
  senderName?: string
}

function SubmitSuccessScreen({ open, onClose, senderName }: SubmitSuccessScreenProps) {
  const { formatMessage } = useIntl()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{ m: 4 }}
      PaperProps={{ sx: { borderRadius: 2 } }}
      closeAfterTransition
    >
      <Stack direction="row-reverse" p={2}>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </Stack>
      <Divider />
      <DialogContent>
        <Stack height="100%" alignItems="center">
          <Stack flexGrow={1} alignItems="center" justifyContent="center" spacing={4}>
            <Avatar variant="circular" size={AvatarSize.XXXL} sx={{ bgcolor: 'success.main' }}>
              <Check color="primary" sx={{ fontSize: 64 }} />
            </Avatar>
            <Stack textAlign="center" spacing={1}>
              <Typography variant="subtitle1">{formatMessage({ id: 'questionnaire.submitSuccess.title' })}</Typography>
              <Typography color="textSecondary">
                {formatMessage(
                  { id: 'questionnaire.submitSuccess.description' },
                  { hasSender: Boolean(senderName), senderName },
                )}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} mt={4} width={500}>
              <Button fullWidth onClick={onClose} variant="outlined">
                {formatMessage({ id: 'general.close' })}
              </Button>
              <LinkButton fullWidth variant="contained" to={paths.dashboard} onClick={onClose}>
                {formatMessage({ id: 'questionnaire.submitSuccess.backToDashboard' })}
              </LinkButton>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <Divider sx={{ mb: 8 }} />
    </Dialog>
  )
}

export default SubmitSuccessScreen
