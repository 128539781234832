import { Box, useTheme } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { TextCommonOption } from 'echarts/types/src/util/types'
import React from 'react'
import { useIntl } from 'react-intl'

enum GoalTrackerMatchTypes {
  MatchGoal = 'match-goal',
  DoesNotMatchGoal = 'does-not-match-goal',
}

interface ChartEventCallbacks<T> {
  click: ({ data }: { data: T }) => void
  [key: string]: ({ data }: { data: T }) => void
}

interface MarkLineDataItem {
  yAxis?: number
  label: {
    show: boolean
    position: string
    formatter: string
    color: string
    backgroundColor: string
    borderRadius: number
    padding: number[]
    margin: number[]
  }
  lineStyle: {
    width: number
    color: string
  }
}

type MarkLineData = MarkLineDataItem[]

interface DataLabel {
  show: boolean
}

interface DataItem {
  value: number
  label?: DataLabel
  [key: string]: unknown
}

interface BackgroundBarDataItem extends DataItem {
  name: GoalTrackerMatchTypes | string
}

type BackgroundBarData = BackgroundBarDataItem[]

type RichXAxisData = { value: string; companiesAnswered: number }

interface BarChartProps<T> {
  data: T[]
  xAxisData: string[] | RichXAxisData[]
  yAxisMax: number
  backgroundBarData: BackgroundBarData
  markLineData?: MarkLineData
  events?: ChartEventCallbacks<T>
  grid?: {
    left?: string
    right?: string
    top?: string
    bottom?: string
  }
}

export function BarChart<T>({
  data,
  xAxisData,
  yAxisMax,
  backgroundBarData,
  markLineData,
  events,
  grid,
}: BarChartProps<T>) {
  const { palette, typography } = useTheme()
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const { formatMessage } = useIntl()

  const isRichXAxisData = (data: string | RichXAxisData): data is RichXAxisData => typeof data !== 'string'

  const filteredXAxisData = (xAxisData as Array<string | RichXAxisData>).filter((item: string | RichXAxisData) => {
    if (isRichXAxisData(item)) {
      return item.companiesAnswered !== 0
    }
    return true
  })

  const filteredData = data.filter((_, index) => {
    const item = xAxisData[index]
    if (isRichXAxisData(item)) {
      return item.companiesAnswered !== 0
    }
    return true
  })
  const filteredBackgroundBarData = backgroundBarData.filter((_, index) => {
    const item = xAxisData[index]
    if (isRichXAxisData(item)) {
      return item.companiesAnswered !== 0
    }
    return true
  })

  const barOptions = {
    grid,
    tooltip: {
      show: true,
      axisPointer: { type: 'none' },
    },
    xAxis: {
      type: 'category',
      data: filteredXAxisData,
      axisLabel: {
        position: 'bottom',
        show: true,
        formatter: (value: string, index: number) => {
          const companiesAnsweredCount = (xAxisData[index] as RichXAxisData)?.companiesAnswered
          return `{periodFormat|${value}} ${
            companiesAnsweredCount
              ? `\n\n {amountFormat|${formatMessage(
                  { id: 'statistics.reportOverview.responseCompanies' },
                  { count: (xAxisData[index] as RichXAxisData).companiesAnswered },
                )}}`
              : ''
          }  `
        },
        rich: {
          periodFormat: {
            fontFamily: typography.fontFamily,
            fontWeight: typography.subtitle2.fontWeight,
            fontSize: typography.subtitle2.fontSize,
          } as TextCommonOption,
          amountFormat: {
            fontFamily: typography.fontFamily,
            fontWeight: typography.caption.fontWeight,
            fontSize: typography.caption.fontSize,
            color: '#6E6E71',
          } as TextCommonOption,
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      max: yAxisMax,
      splitLine: {
        color: palette.visualization[2],
      },
      splitNumber: 2,
      type: 'value',
    },
    series: [
      {
        data: filteredData,
        type: 'bar',
        showBackground: true,
        stack: 'chart',
        barWidth: '40%',
        itemStyle: {
          color: palette.visualization[2],
          borderRadius: 4,
        },
        backgroundStyle: {
          color: palette.grey[200],
        },
        markLine: markLineData
          ? {
              silent: true,
              symbol: 'none',
              data: markLineData,
            }
          : undefined,
      },
      {
        type: 'bar',
        stack: 'chart',
        data: filteredBackgroundBarData,
        itemStyle: {
          color: palette.grey[200],
          borderRadius: [8, 8, 0, 0],
          borderType: 'solid',
          emphasis: {
            color: palette.grey[100],
          },
        },
      },
    ],
  }
  return (
    <Box width="100%">
      <ReactEChartsCore
        option={barOptions}
        echarts={echarts}
        onEvents={events}
        style={{ width: '100%' }}
        ref={eChartsRef}
      />
    </Box>
  )
}

export default BarChart
