import React from 'react'

const GovernanceIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3.918a2.085 2.085 0 0 0-2.076 2.076c0 1.114.911 2.076 2.076 2.076a2.085 2.085 0 0 0 2.076-2.076A2.085 2.085 0 0 0 12 3.918Zm0 3.342a1.34 1.34 0 0 1-1.317-1.317c0-.709.557-1.316 1.317-1.316.76 0 1.316.607 1.316 1.316 0 .76-.607 1.317-1.316 1.317Z"
        fill="#131315"
      />
      <path
        d="M22.785 20.576h-1.418V10.095h1.418a.4.4 0 0 0 .405-.405V5.892a.38.38 0 0 0-.253-.354L12.152 1.133a.323.323 0 0 0-.303 0L1.063 5.538a.38.38 0 0 0-.253.354V9.69a.4.4 0 0 0 .405.405h1.417v10.48H1.216a.4.4 0 0 0-.405.406c0 .253.151.405.405.405H22.836a.4.4 0 0 0 .405-.405c0-.253-.253-.405-.456-.405Zm-18.177 0H3.393V10.095h1.215v10.48Zm6.025 0H5.368V10.095h5.265v10.48Zm1.975 0h-1.215V10.095h1.215v10.48Zm6.025 0h-5.265V10.095h5.265v10.48Zm1.975 0h-1.215V10.095h1.215v10.48Zm-1.57-11.24H1.57v-3.19L12 1.891l10.43 4.253v3.19H19.039ZM23.595 22.095H.405A.4.4 0 0 0 0 22.5c0 .253.152.405.405.405h23.19A.4.4 0 0 0 24 22.5a.4.4 0 0 0-.405-.405Z"
        fill="#131315"
      />
    </svg>
  )
}

export default GovernanceIcon
