import endpoints from '@app/src/api/endpoints'
import { useCreateResource } from '@app/src/api/updateHooks'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useHistory } from 'react-router'
import { ReportCreateModel, Request } from '@app/src/types/resourceExplorer'
import { reporting } from '@app/src/wf-constants/paths'

export function useInquiryNavigator(): {
  isCreateReportLoading: false | true
  handleInquiryClick: (
    inquiryTemplateId: number,
    periodStartsAt: string,
    periodEndsAt: string,
    inquiryId: number,
    requestId?: number,
  ) => Promise<void>
} {
  const { showErrorNotification } = useErrorNotification()
  const history = useHistory()
  const { mutateAsync, isLoading: isCreateReportLoading } = useCreateResource<Request, ReportCreateModel>()

  const handleReportClick = async (
    inquiryTemplateId: number,
    periodStartsAt: string,
    periodsEndsAt: string,
    inquiryId: number,
  ) => {
    if (isCreateReportLoading) return
    await mutateAsync(
      {
        url: endpoints.getOrCreateReport,
        body: {
          templateId: inquiryTemplateId,
          periodStartsAt: periodStartsAt,
          periodEndsAt: periodsEndsAt,
          inquiryId,
        },
      },
      {
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSuccess: data => {
          history.push({ pathname: reporting, search: `requestId=${data.id}` })
        },
      },
    )
  }

  const handleInquiryClick = async (
    inquiryTemplateId: number,
    periodStartsAt: string,
    periodEndsAt: string,
    inquiryId: number,
    requestId?: number,
  ) => {
    if (!requestId) {
      await handleReportClick(inquiryTemplateId, periodStartsAt, periodEndsAt, inquiryId)
      return
    }

    history.push({ pathname: reporting, search: `requestId=${requestId}` })
  }

  return { handleInquiryClick, isCreateReportLoading }
}
