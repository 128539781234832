import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { ConfigurationsParams } from '@app/src/pages/Configurations/ConfigurationsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SuggestedContact, SuggestedContactStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'
import SuggestedContactsHeader from './SuggestedContactsHeader'
import { SuggestedContactsRow } from './SuggestedContactsRow'

export enum SuggestedContactsConfigTabs {
  Requested = 'requested',
  Processed = 'processed',
}

const SuggestedContactsConfig: React.FC = () => {
  const { formatMessage } = useIntl()
  const { configurationsSubPage } = useParams<ConfigurationsParams>()
  const [page, pageSize, setPage, setPageSize] = usePagination()

  const { items, isLoading, isError, count } = useFetchCollectionWithPost<SuggestedContact>({
    key: FetchKey.SuggestedContacts,
    endpoint: endpoints.suggestedContact,
    payload: {
      filter: [
        {
          filters: [
            ...insertIf(configurationsSubPage === SuggestedContactsConfigTabs.Requested, {
              operator: Operators.EqualTo,
              value: SuggestedContactStatus.Requested,
            }),
            ...insertIf(configurationsSubPage === SuggestedContactsConfigTabs.Processed, {
              operator: Operators.NotEqualTo,
              value: SuggestedContactStatus.Requested,
            }),
          ],
          name: 'status',
        },
      ],
      include: ['creatorOrganization', 'creatorUser', 'organization.country', 'organization.locations'],
      pagination: {
        itemsPerPage: pageSize,
        pageNumber: page,
      },
    },
    options: {
      enabled: Boolean(configurationsSubPage),
    },
  })

  useEffect(() => {
    setPage(1)
  }, [configurationsSubPage])

  return (
    <ConfigurationCollection
      actionTabs={[
        {
          type: SuggestedContactsConfigTabs.Requested,
          url: SuggestedContactsConfigTabs.Requested,
          label: formatMessage({ id: 'suggestedContact.requested' }),
        },
        {
          type: SuggestedContactsConfigTabs.Processed,
          url: SuggestedContactsConfigTabs.Processed,
          label: formatMessage({ id: 'suggestedContact.processed' }),
        },
      ]}
      enablePadding={{ top: false, bottom: false }}
    >
      <Table<SuggestedContact>
        RowComponent={SuggestedContactsRow}
        HeaderComponent={SuggestedContactsHeader}
        data={items}
        isLoading={isLoading}
        count={count}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ConfigurationCollection>
  )
}
export default SuggestedContactsConfig
