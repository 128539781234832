export enum Consent {
  INTERNAL_USAGE_CONSENT = 'internalUsageConsent',
  OPEN_NETWORK_CONSENT = 'openNetworkConsent',
  CORRECT_INFORMATION = 'correctInformation',
}

export interface Assertion {
  type: Consent
  value: boolean
}

export enum QuestionType {
  Text = 1,
  Number = 2,
  File = 3,
  Options = 4,
}

export const QUESTION_TYPE_OPTIONS = [
  {
    label: 'Text',
    value: QuestionType.Text,
  },
  {
    label: 'Number',
    value: QuestionType.Number,
  },
  {
    label: 'File',
    value: QuestionType.File,
  },
  {
    label: 'Options',
    value: QuestionType.Options,
  },
]

export enum FileUploadType {
  FileUploaded = 'File Uploaded',
  FileNotUploaded = 'File Not Uploaded',
}

export const FILE_UPLOAD_TYPE_OPTIONS = [
  {
    value: FileUploadType.FileUploaded,
  },
  {
    value: FileUploadType.FileNotUploaded,
  },
]
