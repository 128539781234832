import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import GraphHoverDialogContent, { GraphHoverDialogItem } from '@app/src/components/GraphHoverDialogContent'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import WfLoader from '@app/src/components/WfLoader'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Inquiry, InquiryStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

interface CompanyProgressDialogProps {
  companyName: string
  providerId: number
  userFilters: FilterGroup[]
  imageUrl: string
}

const inquiriesToShowPerStatus = 5

const submittedStatuses = [InquiryStatus.Submitted, InquiryStatus.Approved, InquiryStatus.CorrectionNeeded]

const OverviewCompanyProgressDialog: React.FC<CompanyProgressDialogProps> = ({
  companyName,
  providerId,
  userFilters,
  imageUrl,
}) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { palette } = useTheme()

  const { stringifyQueryFilters } = useStringifyQueryFilters()

  const {
    items: submittedItems,
    isLoading: isLoadingSubmitted,
    count: submittedCount,
  } = useFetchCollectionWithPost<Inquiry>({
    endpoint: endpoints.inquiriesCollection,
    payload: {
      filter: [
        ...userFilters,
        { name: 'providerId', filters: [{ value: providerId, operator: Operators.EqualTo }] },
        { name: 'status', filters: [{ value: submittedStatuses, operator: Operators.In }] },
      ],
      pagination: { pageNumber: 1, itemsPerPage: inquiriesToShowPerStatus },
      include: ['template'],
    },
    options: {
      refetchOnMount: false,
    },
    key: [FetchKey.Inquiry, providerId],
  })

  const {
    items: requestedItems,
    isLoading: isLoadingRequested,
    count: requestedCount,
  } = useFetchCollectionWithPost<Inquiry>({
    endpoint: endpoints.inquiriesCollection,
    payload: {
      filter: [
        ...userFilters,
        { name: 'providerId', filters: [{ value: providerId, operator: Operators.EqualTo }] },
        { name: 'status', filters: [{ value: InquiryStatus.Requested, operator: Operators.EqualTo }] },
      ],
      pagination: { pageNumber: 1, itemsPerPage: inquiriesToShowPerStatus },
      include: ['template'],
    },
    options: {
      refetchOnMount: false,
    },
    key: [FetchKey.Inquiry, providerId],
  })

  const isLoading = isLoadingRequested || isLoadingSubmitted
  const totalCount = submittedCount + requestedCount
  const dataByStatus = [
    ...insertIf(submittedCount > 0, {
      status: InquiryStatus.Submitted,
      statusCount: submittedCount,
      inquiryTitles: submittedItems.map(si => si.template.title),
    }),
    ...insertIf(requestedCount > 0, {
      status: InquiryStatus.Requested,
      statusCount: requestedCount,
      inquiryTitles: requestedItems.map(si => si.template.title),
    }),
  ]

  const userFiltersToExclude = ['status', 'provider.id']
  const relevantUserFilters = userFilters
    .filter(f => !userFiltersToExclude.includes(f.name.toLowerCase()))
    .map(f => ({ name: f.name, operator: f.filters?.[0].operator, value: f.filters?.[0].value }))

  const dialogItems: GraphHoverDialogItem[] = dataByStatus.map(s => {
    const location = stringifyQueryFilters({
      url: generatePath(paths.manageRequest, {
        view: ViewTypeName.Requests,
      }),
      queryParams: {
        filters: [
          ...relevantUserFilters,
          {
            name: 'status',
            value: s.status === InquiryStatus.Submitted ? submittedStatuses : [s.status],
            operator: Operators.In,
          },
          {
            name: 'provider.id',
            value: [providerId],
            operator: Operators.In,
          },
        ],
      },
    })

    const subRows = [
      ...s.inquiryTitles,
      ...insertIf(
        s.statusCount > inquiriesToShowPerStatus,
        formatMessage({ id: 'general.andAdditional' }, { amount: s.statusCount - inquiriesToShowPerStatus }),
      ),
    ]
    return {
      title: formatMessage({ id: `reporting.statuses.${s.status}` }),
      chipColor: s.status === InquiryStatus.Submitted ? palette.visualization[1] : 'rgba(180, 180, 180, 0.2)',
      description: `${s.statusCount}/${totalCount}`,
      dialogLink: {
        text: formatMessage({ id: 'general.seeAll' }),
        action: () => history.push(location),
      },
      subRows: subRows,
    }
  })

  return (
    <>
      {isLoading ? (
        <>
          <Box width={30} height={30}>
            <WfLoader />
          </Box>
        </>
      ) : (
        <GraphHoverDialogContent
          title={
            <Box display="flex" gap={1} alignItems="center">
              <CompanyAvatar organizationName={companyName} imageUrl={imageUrl} size={AvatarSize.Medium} />
              {companyName}
            </Box>
          }
          dialogItems={dialogItems}
        />
      )}
    </>
  )
}

export default OverviewCompanyProgressDialog
