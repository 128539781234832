import { OptionAdornment, OptionIcon } from '@app/src/components/Form/Select'
import PeriodTypeCell from '@app/src/pages/ResourceCollection/Collections/CellComponents/PeriodTypeCell'
import React from 'react'
import { FacetItem } from '..'

interface Props {
  props: React.HTMLAttributes<HTMLLIElement>
  selected: boolean
  option: FacetItem
}

const PeriodTypeOption = ({ props, selected, option }: Props): JSX.Element => {
  return (
    <li {...props}>
      <OptionIcon selected={selected} multiple />
      <PeriodTypeCell periodTypeName={option.value.toString()} />
      <OptionAdornment option={option} />
    </li>
  )
}

export default PeriodTypeOption
