import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { COMPANY_REPORT_QUESTION_KEYS } from '@app/src/components/CreateNewRequestModal/CompanyReport'
import ProviderSelectorFilterDrawer, {
  PROVIDER_SELECTOR_ALLOWED_FILTERS,
} from '@app/src/components/CreateNewRequestModal/ProviderSelectorFilterDrawer'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useQueryState } from '@app/src/hooks/queryState'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Provider } from '@app/src/types/organizations'
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Control, UseFormMethods } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface ProviderSelectorProps {
  control: Control
  setValue: UseFormMethods['setValue']
}

const ProviderSelectorWithFilters: React.FC<ProviderSelectorProps> = ({ control, setValue }) => {
  const { formatMessage } = useIntl()
  const apiFilters = useGetApiQueryFilters(PROVIDER_SELECTOR_ALLOWED_FILTERS)
  const { openDrawer } = useDrawer()
  const [, setQueryFilters] = useQueryState('filters', [])

  //Hardcoded for now since it is very hard to get or set its height. It will also become bigger when you select many options
  //We need the button to have the same height as the select has initially
  const initialSelectHeight = 53.65

  const onFilterChanged = () => setValue(COMPANY_REPORT_QUESTION_KEYS.RESPONDERS, [])

  const isLinkedFilter = {
    name: 'linked',
    filters: [
      {
        value: 'true',
        operator: Operators.EqualTo,
      },
    ],
  }

  const { items: providers, count } = useFetchCollectionWithPost<Provider>({
    endpoint: endpoints.providersCollection,
    payload: {
      include: ['organization'],
      filter: [...apiFilters, isLinkedFilter],
      sort: { target: 'name', order: SortOrder.ASCENDING },
      pagination: { itemsPerPage: 5_000_000, pageNumber: 1 },
    },
    key: FetchKey.Provider,
  })

  const { count: unfilteredProviderCount } = useFetchCollectionWithPost<Provider>({
    endpoint: endpoints.providersCollection,
    payload: {
      include: [],
      filter: [isLinkedFilter],
      pagination: { itemsPerPage: 1, pageNumber: 1 },
    },
    key: FetchKey.Provider,
  })

  const fieldLabel = apiFilters.length
    ? formatMessage(
        { id: 'form.createRequest.selectCompaniesLabelFiltered' },
        { filteredCount: count, unfilteredCount: unfilteredProviderCount },
      )
    : formatMessage({ id: 'form.createRequest.selectCompaniesLabel' })

  useEffect(() => {
    setQueryFilters([])
    return () => setQueryFilters([])
  }, [])

  return (
    <Box display="flex" alignItems="flex-end" marginRight={1}>
      <Box flex={1} marginRight={2}>
        <Select<Partial<Provider>, Provider>
          name={COMPANY_REPORT_QUESTION_KEYS.RESPONDERS}
          fieldLabel={fieldLabel}
          control={control}
          required
          enableAutoSelect
          enableSelectAll
          multiple
          forceFetch
          options={providers.map(provider => ({
            label: provider.organization.name,
            value: {
              name: provider.organization.name,
              id: provider.id,
              organizationId: provider.organizationId,
            },
          }))}
          findSelectedValue={(value, option): boolean => value?.organizationId === option?.value?.organizationId}
        />
      </Box>
      <Filters allowedFilters={PROVIDER_SELECTOR_ALLOWED_FILTERS} resetPage={onFilterChanged}>
        {({ inDrawerFiltersCount }) => (
          <AllFiltersButton
            inDrawerFiltersCount={inDrawerFiltersCount}
            openDrawer={() =>
              openDrawer(
                <DrawerViewFilters allowedFilters={PROVIDER_SELECTOR_ALLOWED_FILTERS} resetPage={onFilterChanged}>
                  <Box px={2}>
                    <ProviderSelectorFilterDrawer implicitFilters={[isLinkedFilter]} />
                  </Box>
                </DrawerViewFilters>,
                { showAboveDialogs: true },
              )
            }
            height={initialSelectHeight}
          />
        )}
      </Filters>
    </Box>
  )
}

export default ProviderSelectorWithFilters
