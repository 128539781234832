import { FacetItem } from '@app/src/pages/ResourceCollection/index'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts-for-react'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

const getItemBorderRadius = (
  levels: Record<number, FacetItem | undefined>,
  i: number,
): [number, number, number, number] => {
  const lowerLevelsHaveValue = Object.values(levels)
    .filter(level => Number(level?.value) > i)
    .some(level => level?.count)
  const higherLevelsHaveValue = Object.values(levels)
    .filter(level => Number(level?.value) < i)
    .some(level => level?.count)

  if (lowerLevelsHaveValue && higherLevelsHaveValue) return [0, 0, 0, 0]
  if (lowerLevelsHaveValue && !higherLevelsHaveValue) return [5, 0, 0, 5]
  if (!lowerLevelsHaveValue && higherLevelsHaveValue) return [0, 5, 5, 0]
  return [5, 5, 5, 5]
}

export type AssessmentOverViewGraphDatapoint = {
  value: number
  level: string
  name: string
}

const useBaseAssessmentOverviewGraph = (levels: Record<number, FacetItem | undefined>): EChartsOption => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const seriesForLevel = useCallback(
    (levelNumber: number, color: string) => {
      const datapoint: AssessmentOverViewGraphDatapoint = {
        level: levels[levelNumber]?.label ?? '',
        value: levels[levelNumber]?.count ?? 0,
        name: formatMessage({ id: 'baseAssessment.totalLevel' }, { value: levelNumber }),
      }
      return {
        type: 'bar',
        stack: 'x',
        data: [datapoint],
        emphasis: {
          focus: 'self',
        },
        itemStyle: {
          color: color,
          borderType: 'solid',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius: getItemBorderRadius(levels, levelNumber),
        },
      }
    },
    [formatMessage, levels],
  )

  const max = useMemo(() => Object.values(levels)?.reduce((acc, level) => acc + (level?.count ?? 0), 0) ?? 0, [levels])

  return useMemo(
    () => ({
      type: 'bar',
      stack: 'chart',
      itemStyle: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      xAxis: {
        show: false,
        max: max,
        splitLine: {
          show: false,
        },
        triggerEvent: true,
      },
      yAxis: {
        inverse: true,
        type: 'category',

        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        triggerEvent: true,
        axisLabel: {
          show: false,
        },
      },
      grid: {
        top: 0,
        bottom: 0,
        right: 0,
        containLabel: false,
        show: false,
        left: 0,
      },
      series: [
        seriesForLevel(1, palette.semantic.error),
        seriesForLevel(2, palette.error.dark),
        seriesForLevel(3, palette.warning.dark),
        seriesForLevel(4, palette.success.dark),
        seriesForLevel(5, palette.brandDecorative.emerald),
      ],
    }),
    [palette, seriesForLevel, max],
  )
}

export default useBaseAssessmentOverviewGraph
