import ListItemSkeleton from '@app/src/components/Skeleton/ListItem'
import Avatar from '@app/src/components/Ui/Avatar'
import { Provider } from '@app/src/types/organizations'
import { ReferralContact } from '@app/src/types/resourceExplorer'
import { getSentEmailCountOutOfTotalPlanned } from '@app/src/utils/referralContactEmails'
import { AddOutlined, ChevronRight } from '@mui/icons-material'
import { Button, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from './Drawer/DrawerContext'
import DrawerViewCreateReferralContact from './Drawer/Views/DrawerViewCreateReferralContact'
import DrawerViewReferralContact from './Drawer/Views/DrawerViewReferralContact'

type ReferralContactListItemProps = {
  provider: Provider
  referralContact: ReferralContact
  isReferralContactsLoading: boolean
}

const ReferralContactListItem: React.FC<ReferralContactListItemProps> = ({
  provider,
  isReferralContactsLoading,
  referralContact,
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const { sentCount, totalCount } = getSentEmailCountOutOfTotalPlanned(referralContact?.referralEmailHistory ?? [])

  if (isReferralContactsLoading) {
    return (
      <ListItemSkeleton
        avatar
        primary
        secondary
        listItemProps={{ dense: true }}
        avatarProps={{ variant: 'circular' }}
      />
    )
  }

  if (referralContact) {
    return (
      <ListItem dense disablePadding secondaryAction={<ChevronRight />}>
        <ListItemButton onClick={() => openDrawer(<DrawerViewReferralContact provider={provider} />)}>
          <ListItemAvatar>
            <Avatar
              variant="circular"
              sx={({ palette }) =>
                sentCount
                  ? { backgroundColor: palette.secondary.main }
                  : { backgroundColor: palette.grey.A400, color: palette.text.primary }
              }
            >
              {sentCount}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              sentCount
                ? formatMessage(
                    { id: 'referral.invitationSchedule.sentOutOfTotal' },
                    { sent: sentCount, total: totalCount },
                  )
                : formatMessage({ id: 'referral.invitationSchedule.noEmailsSent' })
            }
            secondary={referralContact.name}
          />
        </ListItemButton>
      </ListItem>
    )
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => openDrawer(<DrawerViewCreateReferralContact provider={provider} />)}>
        <ListItemText primary={formatMessage({ id: 'referral.contact.addContactPerson' })} />
        <Button startIcon={<AddOutlined />} size="small" sx={{ ml: 1 }}>
          {formatMessage({ id: 'general.add' })}
        </Button>
      </ListItemButton>
    </ListItem>
  )
}

export default ReferralContactListItem
