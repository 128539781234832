import { ActionButton } from '@app/src/components/ActionButtons'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { ConfigurationsParams } from '@app/src/pages/Configurations/ConfigurationsScene'
import CustomQuestionnaireCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/CustomQuestionnaireCollection'
import StandardQuestionnaireCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/StandardQuestionnaireCollection'
import { insertIf } from '@app/src/utils/helpersTs'
import { ADMIN_ROLES } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useParams, useRouteMatch } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import SustainabilityLibraryCollection from '../SustainabilityLibraryCollection'

export enum QuestionnairesConfigTabs {
  Standard = 'standard',
  Custom = 'custom',
}

const QuestionnairesConfig: React.FC = () => {
  const { formatMessage } = useIntl()
  const { path } = useRouteMatch()
  const { configurationsPage, configurationsSubPage } = useParams<ConfigurationsParams>()
  const { questionnaireBuilder } = useFlags()
  const { role } = useAuthentication().scope
  const canCreateQuestionnaires = questionnaireBuilder && ADMIN_ROLES.includes(role?.toLowerCase() ?? '')

  return (
    <SustainabilityLibraryCollection
      actionTabs={[
        {
          type: QuestionnairesConfigTabs.Standard,
          url: QuestionnairesConfigTabs.Standard,
          label: formatMessage({ id: 'questionnaireConfig.standardTab' }),
          skipQueryParams: true,
        },
        {
          type: QuestionnairesConfigTabs.Custom,
          url: QuestionnairesConfigTabs.Custom,
          label: formatMessage({ id: 'questionnaireConfig.customTab' }),
          skipQueryParams: true,
        },
      ]}
      actionButtons={[
        ...insertIf<ActionButton>(canCreateQuestionnaires, {
          label: formatMessage({ id: 'resourceCollections.general.create' }),
          variant: 'contained',
          to: generatePath(paths.editTemplate),
        }),
      ]}
      enablePadding={{
        top: false,
      }}
    >
      <Switch>
        <Route
          path={generatePath(path, {
            configurationsPage,
            configurationsSubPage,
            activeTabParam: QuestionnairesConfigTabs.Standard,
          })}
        >
          <StandardQuestionnaireCollection />
        </Route>

        <Route
          path={generatePath(path, {
            configurationsPage,
            configurationsSubPage,
            activeTabParam: QuestionnairesConfigTabs.Custom,
          })}
        >
          <CustomQuestionnaireCollection />
        </Route>
      </Switch>
    </SustainabilityLibraryCollection>
  )
}

export default QuestionnairesConfig
