import CountryRisksGraph from '@app/src/pages/Dashboards/SourcingDashboard/CountryRisks/CountryRisksGraphProps'
import { OrganizationCountriesExposure, RiskTableView, Supplier } from '@app/src/types/organizations'
import { Country, RiskStatus, RiskType } from '@app/src/types/resourceExplorer'
import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { LocationTypes } from './CountryRiskSection'

interface CountryRiskStatsProps {
  riskScreening: RiskTableView[]
  activeRiskType?: RiskType
  selectedRiskId: number
  countries: Country[]
  selectedLocationType: string
}

const getHighestRiskCountry = (
  countriesExposure: OrganizationCountriesExposure[],
  countryRisks: Country[],
  riskTypeId: number,
) => {
  const result: { country: Country; riskStatus: RiskStatus }[] = []

  for (const exposure of countriesExposure) {
    const matchingCountry = countryRisks.find(country => country.countryCode === exposure.country.countryCode)

    if (matchingCountry) {
      const matchingRisk = matchingCountry.risks.find(risk => risk.riskTypeId === riskTypeId)

      if (matchingRisk) {
        result.push({
          country: matchingCountry,
          riskStatus: matchingRisk.status,
        })
      }
    }
  }

  result.sort((a, b) => {
    const statusOrder = [RiskStatus.Extreme, RiskStatus.High, RiskStatus.Medium, RiskStatus.Low]
    return statusOrder.indexOf(a.riskStatus) - statusOrder.indexOf(b.riskStatus)
  })

  return result[0]
}

const CountryRiskStats: React.FC<CountryRiskStatsProps> = ({
  riskScreening,
  activeRiskType,
  selectedRiskId,
  countries,
  selectedLocationType,
}) => {
  const { formatMessage } = useIntl()

  const getCountryCode = (provider: Supplier) => {
    if (!provider.organization?.organizationsCountriesExposure) {
      return provider?.country?.countryCode
    }

    switch (selectedLocationType) {
      case LocationTypes.OWNED_OPERATIONS:
        return getHighestRiskCountry(
          provider.organization.organizationsCountriesExposure.filter(exposure => exposure.variant === 'Operation'),
          countries,
          selectedRiskId,
        )?.country?.countryCode
      case LocationTypes.SUB_SUPPLIERS:
        return getHighestRiskCountry(
          provider.organization.organizationsCountriesExposure.filter(exposure => exposure.variant === 'Subsupplier'),
          countries,
          selectedRiskId,
        )?.country?.countryCode
      default:
        return provider?.country?.countryCode
    }
  }

  const providers = useMemo(() => riskScreening.map(risk => risk.parentObject), [riskScreening])

  const risksCounts = useMemo(() => {
    const initializeRiskCounters = countries.reduce<{ [riskId: number]: { [riskStatus: string]: number } }>(
      (acc, country) => {
        country.risks.reduce((innerAcc, risk) => {
          if (!innerAcc[risk.riskTypeId]) {
            innerAcc[risk.riskTypeId] = {}
          }
          innerAcc[risk.riskTypeId][risk.status] = 0
          return innerAcc
        }, acc)
        return acc
      },
      {},
    )

    const incrementRiskCounts = providers.reduce((acc, provider) => {
      const countryCode = getCountryCode(provider)
      const country = countries.find(c => c?.countryCode === countryCode)
      if (country) {
        country.risks.reduce((innerAcc, risk) => {
          if (innerAcc[risk.riskTypeId] && innerAcc[risk.riskTypeId][risk.status] !== undefined) {
            innerAcc[risk.riskTypeId][risk.status]++
          }
          return innerAcc
        }, acc)
      }
      return acc
    }, initializeRiskCounters)

    return incrementRiskCounts
  }, [selectedLocationType, providers])

  if (!activeRiskType) {
    return null
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} p={2}>
      <CountryRisksGraph
        selectedLocationType={selectedLocationType}
        title={formatMessage({ id: `schemas.risk.riskIndexes.${activeRiskType.source}.label` })}
        hoverTitle={formatMessage(
          { id: 'schemas.risk.status' },
          { value: formatMessage({ id: `schemas.risk.riskIndexes.${activeRiskType.source}.label` }) },
        )}
        data={risksCounts[activeRiskType.id]}
        totalProviders={providers.length}
      />
    </Box>
  )
}

export default CountryRiskStats
