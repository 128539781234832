import { Drawer } from '@mui/material'
import React, { PropsWithChildren, ReactNode, useState } from 'react'
import DrawerContext, { DrawerContextProps, DrawerViewOptions, INITIAL_OPTIONS } from './DrawerContext'

const DrawerContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [views, setViews] = useState<ReactNode[]>([])

  const [options, _setOptions] = useState<DrawerViewOptions>(INITIAL_OPTIONS)

  const setOptions: DrawerContextProps['setOptions'] = options => {
    _setOptions({ ...INITIAL_OPTIONS, ...options })
  }

  const openDrawer: DrawerContextProps['openDrawer'] = (view, options = INITIAL_OPTIONS) => {
    setOptions(options)
    setViews(views => [view, ...views])
    setOpen(true)
  }

  const goBack = () => {
    setViews(views => views.slice(1))
    setOptions(INITIAL_OPTIONS)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  const handleExitTransition = () => {
    setViews([])
    setOptions(INITIAL_OPTIONS)
  }

  return (
    <DrawerContext.Provider
      value={{
        hasPreviousView: views.length > 1,
        openDrawer,
        goBack,
        closeDrawer,
        options,
        setOptions,
      }}
    >
      {children}

      <Drawer
        open={open}
        onClose={closeDrawer}
        SlideProps={{
          onExited: handleExitTransition,
        }}
        anchor={options.anchor}
        keepMounted
        PaperProps={{
          sx: theme =>
            options.fullScreen
              ? {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: `calc(100% - ${theme.spacing(4)})`,
                  height: `calc(100% - ${theme.spacing(4)})`,
                  padding: 0,
                }
              : { width: '100%', maxWidth: 390, padding: 0 },
        }}
        sx={({ zIndex }) => ({
          zIndex: options.showAboveDialogs ? zIndex.modal + 1 : zIndex.drawer,
        })}
      >
        {views[0]}
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default DrawerContextProvider
