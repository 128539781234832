import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { ChipsValue, VisibleTemplates } from './EsgLibrary'
import FilterChip from './FilterChip'

const useStyles = makeStyles(({ spacing }) => ({
  chip: {
    marginRight: spacing(1),
  },
}))

interface EsgLibraryFiltersProps {
  setVisibleTemplates: React.Dispatch<React.SetStateAction<VisibleTemplates>>
  setTagsFilters: React.Dispatch<React.SetStateAction<string[] | undefined>>
}

const EsgLibraryFilters: React.FC<EsgLibraryFiltersProps> = ({ setVisibleTemplates, setTagsFilters }) => {
  const { chip } = useStyles()
  const [activeChips, setActiveChips] = useState<ChipsValue[]>([ChipsValue.ALL])

  const handleChipClick = (value: ChipsValue) => {
    // When RECOMMENDED is active we should display the data from the recommended endpoint
    setVisibleTemplates(
      value === ChipsValue.RECOMMENDED ? VisibleTemplates.RecommendedTemplates : VisibleTemplates.AllTemplates,
    )

    // When ALL or RECOMMENDED is clicked the filters should be cleaned up, all the other selected tags should be cleaned up
    if (value === ChipsValue.ALL || value === ChipsValue.RECOMMENDED) {
      setTagsFilters(undefined)
      setActiveChips([value])
    }

    // If the chip clicked is already in the ActiveChips it should be removed except for ALL
    if (value !== ChipsValue.ALL && activeChips.includes(value)) {
      setActiveChips(curr => curr.filter(chip => chip !== value))
      setTagsFilters(curr => curr?.filter(tag => tag !== value))

      // If we're clearing the last chip it should go back to ALL
      if (activeChips.length === 1) {
        setActiveChips([ChipsValue.ALL])
        setTagsFilters(undefined)
      }
      return
    }

    // If any other chip is clicked it should add the corresponding filter and they can pile up
    if (value !== ChipsValue.ALL && value !== ChipsValue.RECOMMENDED) {
      setActiveChips(curr => [
        ...curr.filter(chip => chip !== ChipsValue.ALL && chip !== ChipsValue.RECOMMENDED),
        value,
      ])
      setTagsFilters(curr => [...(curr || []), value])
    }
  }

  return (
    <Box display="flex" mt={2}>
      <FilterChip className={chip} activeChips={activeChips} value={ChipsValue.ALL} onClick={handleChipClick} />
      <FilterChip className={chip} activeChips={activeChips} value={ChipsValue.RECOMMENDED} onClick={handleChipClick} />
      <FilterChip className={chip} activeChips={activeChips} value={ChipsValue.ENVIRONMENT} onClick={handleChipClick} />
      <FilterChip className={chip} activeChips={activeChips} value={ChipsValue.SOCIAL} onClick={handleChipClick} />
      <FilterChip className={chip} activeChips={activeChips} value={ChipsValue.GOVERNANCE} onClick={handleChipClick} />
      <FilterChip className={chip} activeChips={activeChips} value={ChipsValue.REGULATIONS} onClick={handleChipClick} />
      <FilterChip className={chip} activeChips={activeChips} value={ChipsValue.INITIATIVES} onClick={handleChipClick} />
      <FilterChip
        className={chip}
        activeChips={activeChips}
        value={ChipsValue.SUPPLY_CHAIN}
        onClick={handleChipClick}
      />
    </Box>
  )
}

export default EsgLibraryFilters
