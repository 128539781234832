import { Components } from '@mui/material'

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },
}

export default MuiTab
