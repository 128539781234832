import { ActionButton } from '@app/src/components/ActionButtons'
import { TabsProps } from '@app/src/components/Tabs'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { insertIf } from '@app/src/utils/helpersTs'
import { Roles } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Alert } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { Route, Switch, generatePath, useParams } from 'react-router-dom'
import RiskScreeningAllRisksScene from './RiskScreeningAllRisksScene'
import RiskScreeningHqScene from './RiskScreeningHqScene'
import RiskScreeningMap from './RiskScreeningMap'

export enum RiskScreeningViews {
  Headquarter = 'headquarter',
  AllLocations = 'all-locations',
  Map = 'map',
}

interface RiskScreeningSceneParams {
  view: string
}

const RiskScreeningScene: React.FC = (): JSX.Element => {
  const { view } = useParams<RiskScreeningSceneParams>()
  const { role } = useAuthentication().scope
  const { formatMessage } = useIntl()

  const actionTabs: TabsProps['tabs'] = [
    {
      type: RiskScreeningViews.Headquarter,
      url: generatePath(paths.riskScreening, { view: RiskScreeningViews.Headquarter }),
      label: formatMessage({ id: 'riskScreening.tabs.headquarter' }),
    },
    {
      type: RiskScreeningViews.AllLocations,
      url: generatePath(paths.riskScreening, { view: RiskScreeningViews.AllLocations }),
      label: formatMessage({ id: 'riskScreening.tabs.allLocations' }),
    },
    {
      type: RiskScreeningViews.Map,
      url: generatePath(paths.riskScreening, { view: RiskScreeningViews.Map }),
      label: formatMessage({ id: 'riskScreening.tabs.map' }),
    },
  ]

  const subTitle = <Alert severity="info">{formatMessage({ id: 'riskScreening.generalAdvice' })}</Alert>
  const actionButtons = [
    ...insertIf<ActionButton>(Boolean(role === Roles.Admin), {
      label: formatMessage({ id: 'riskScreening.setIndices' }),
      variant: 'outlined',
      to: generatePath(paths.configurations, { configurationsPage: CONFIGURATION_PAGE_IDS.RiskScreening }),
    }),
  ]

  return (
    <Switch>
      <Route path={generatePath(paths.riskScreening, { view: RiskScreeningViews.Headquarter })}>
        <RiskScreeningHqScene
          tabs={{ actionTabs, activeTabParam: view }}
          subtitle={subTitle}
          actionButtons={actionButtons}
        />
      </Route>
      <Route path={generatePath(paths.riskScreening, { view: RiskScreeningViews.AllLocations })}>
        <RiskScreeningAllRisksScene
          tabs={{ actionTabs, activeTabParam: view }}
          subtitle={subTitle}
          actionButtons={actionButtons}
        />
      </Route>
      <Route path={generatePath(paths.riskScreening, { view: RiskScreeningViews.Map })}>
        <RiskScreeningMap tabs={{ actionTabs, activeTabParam: view }} actionButtons={actionButtons} />
      </Route>
    </Switch>
  )
}
export default RiskScreeningScene
