import {
  BlockOutlined,
  CircleOutlined,
  MailOutline,
  PhoneOutlined,
  PublicOffOutlined,
  PublicOutlined,
  WorkOutline,
} from '@mui/icons-material'
import { List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { ActionButton } from '@app/src/components/ActionButtons'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAccount } from '@app/src/context/AccountContext'
import React from 'react'
import { useIntl } from 'react-intl'
import { User } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { ResourceTypes, Roles, Solutions } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'
import DrawerViewDeleteResource from './DrawerViewDeleteResource'
import DrawerViewEditUser from './DrawerViewEditUser'
import DrawerViewEditUserTransparency from './DrawerViewEditUserTransparency'

export type DrawerViewUserProps = {
  user: User
  canDelete?: boolean
  canEdit?: boolean
  hidePublicConctact?: boolean
} & Omit<DrawerViewProps, 'title'>

export const isCurrentUser = (resourceType: string, accountId?: number, userId?: number) =>
  resourceType === ResourceTypes.User && accountId === userId

const DrawerViewUser: React.FC<DrawerViewUserProps> = ({
  user,
  canDelete = false,
  canEdit = false,
  hidePublicConctact = false,
  ...props
}) => {
  const { openDrawer } = useDrawer()
  const { account } = useAccount()
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()

  return (
    <DrawerView
      title=""
      buttons={[
        ...insertIf<ActionButton>(!canDelete && !canEdit, {
          label: formatMessage({ id: 'schemas.user.sendEmail' }),
          variant: 'contained',
          href: `mailto:${user.email}`,
          target: '_blank',
        }),
        ...insertIf<ActionButton>(canDelete, {
          label: formatMessage({ id: 'general.delete' }),
          variant: 'text',
          onClick: () =>
            openDrawer(
              <DrawerViewDeleteResource
                fetchKey={FetchKey.Colleague}
                resourceId={user.id}
                solution={Solutions.Resources}
                resourceType={user.type.toLowerCase()}
                subTitle={user.name}
                invalidateTimeoutMS={1000}
              />,
            ),
          disabled: user.isPublicContact || isCurrentUser(user.type.toLowerCase(), account?.user.id, user.id),
        }),
        ...insertIf<ActionButton>(canEdit, {
          label: formatMessage({ id: 'general.edit' }),
          variant: 'contained',
          disabled:
            !hasPermission(Permissions.TRANSPARENCY_USER) &&
            isCurrentUser(user.type.toLowerCase(), account?.user.id, user.id),
          onClick: () =>
            openDrawer(
              hasPermission(Permissions.TRANSPARENCY_USER) ? (
                <DrawerViewEditUserTransparency user={user} />
              ) : (
                <DrawerViewEditUser user={user} />
              ),
            ),
        }),
      ]}
      {...props}
    >
      <Stack pt={2} flexGrow={1} gap={2}>
        <Stack alignItems="center">
          <Avatar
            variant="circular"
            size={AvatarSize.XXL}
            src={user.image?.url}
            sx={({ palette }) => ({ backgroundColor: palette.secondary.dark, color: palette.secondary.contrastText })}
          >
            {user.name[0]}
          </Avatar>
          <Typography variant="h6" align="center" mt={2} px={2}>
            {user.name}
          </Typography>
        </Stack>

        <List disablePadding>
          <ListItem>
            <ListItemAvatar>
              <MailOutline color="action" />
            </ListItemAvatar>
            <ListItemText primary={formatMessage({ id: 'schemas.user.email' })} secondary={user.email} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <WorkOutline color="action" />
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: 'schemas.user.position' })}
              secondary={user.position || formatMessage({ id: 'general.notAdded' })}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <PhoneOutlined color="action" />
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: 'schemas.user.phoneNumber' })}
              secondary={user.phoneNumber || formatMessage({ id: 'general.notAdded' })}
            />
          </ListItem>

          {canEdit && (
            <ListItem>
              <ListItemAvatar>
                {user.role.toLowerCase() === Roles.Contributor ? (
                  <BlockOutlined color="action" />
                ) : (
                  <CircleOutlined color="action" />
                )}
              </ListItemAvatar>
              <ListItemText primary={formatMessage({ id: 'schemas.user.role' })} secondary={user.role} />
            </ListItem>
          )}

          {!hidePublicConctact && hasPermission(Permissions.TRANSPARENCY_USER) && (
            <ListItem>
              <ListItemAvatar>
                {user.isPublicContact ? <PublicOutlined color="action" /> : <PublicOffOutlined color="action" />}
              </ListItemAvatar>
              <ListItemText
                primary={formatMessage({ id: 'schemas.user.publicContact' })}
                secondary={formatMessage({ id: 'schemas.user.publicWfNetwork' }, { isPublic: user.isPublicContact })}
              />
            </ListItem>
          )}
        </List>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewUser
