import Report from '@app/src/pages/Questionnaire/Provider/Report'
import ReportContext from '@app/src/pages/Questionnaire/ReportContext'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { mapToReportContextPropsForPreview } from '@app/src/utils/previewMapper'
import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Divider, Dialog as MUIDialog } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

type TemplatePreviewProps = {
  template?: QuestionnaireTemplate
  showPreview: boolean
  onBack: () => void
}
const TemplatePreview: React.FC<TemplatePreviewProps> = ({ showPreview, template, onBack }) => {
  const { formatMessage } = useIntl()
  const formMethods = useForm()

  if (!template) {
    return null
  }

  return (
    <MUIDialog open={showPreview} onClose={onBack} fullWidth maxWidth="lg">
      <ReportContext.Provider value={mapToReportContextPropsForPreview(template)}>
        <FormProvider {...formMethods}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2} px={4}>
            <Button startIcon={<ArrowBack color="primary" />} onClick={onBack}>
              {formatMessage({ id: 'general.back' })}
            </Button>

            <Divider light sx={{ mt: 2 }} />
            <Box maxWidth={920} alignSelf="center">
              <Report />
            </Box>
          </Box>
        </FormProvider>
      </ReportContext.Provider>
    </MUIDialog>
  )
}
export default TemplatePreview
