import { TableRow } from '@mui/material'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import LinkCell from '@app/src/components/Table/Cells/LinkCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { Product } from '@app/src/types/product'
import paths from '@app/src/wf-constants/paths'

interface ProductRowProps {
  row: Product
}

export const ProductRow: React.FC<ProductRowProps> = ({ row: product }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <LinkCell label={product?.name} to={generatePath(paths.product, { id: product?.id })} />
      <ValueCell value={product?.gtin} />
      <ValueCell value={product?.productNumber} />
      <ValueCell value={product?.productCustomId} />
      <ValueCell value={product?.purchaseOrder} />
      <CompanyCell company={product?.provider} drawer />
      <CountryCell countryCode={product?.country?.countryCode} />
      <CategoryCell
        value={formatMessage({ id: `schemas.product.productStatusValues.${product?.productStatus}` })}
        defaultValue={formatMessage({ id: 'schemas.product.productStatusValues.NotSelected' })}
      />
      <CategoryCell
        value={formatMessage({ id: `schemas.product.mappingStatusValues.${product?.mappingStatus}` })}
        defaultValue={formatMessage({ id: 'schemas.product.mappingStatusValues.NotSelected' })}
      />
      <DateCell value={product?.createdAt} />
      <DateCell value={product?.updatedAt} />
    </TableRow>
  )
}

export default ProductRow
