import { TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const ProductHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.name' })}
        sorting={{ name: 'name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.gtin' })}
        sorting={{ name: 'gtin', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.productNumber' })}
        sorting={{ name: 'productNumber', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.productCustomId' })}
        sorting={{ name: 'productCustomId', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.purchaseOrder' })}
        sorting={{ name: 'purchaseOrder', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.responsibleSupplier' })}
        sorting={{ name: 'provider.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: `schemas.product.countryOfOrigin` })}
        sorting={{ name: 'country.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.productStatus' })}
        sorting={{ name: 'productStatus', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.mappingStatus' })}
        sorting={{ name: 'mappingStatus', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.createdAt' })}
        sorting={{ name: 'createdAt', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.product.updatedAt' })}
        sorting={{ name: 'updatedAt', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.MEDIUM_SMALL}
      />
    </TableRow>
  )
}

export default ProductHeader
