import { Components } from '@mui/material'
import { palette } from '@app/src/theme/palette'

const MuiAlert: Components['MuiAlert'] = {
  defaultProps: {
    variant: 'filled',
  },
  styleOverrides: {
    root: {
      padding: '12px',
      color: '#000',
      alignItems: 'center',
      borderRadius: 4,
    },
    icon: {
      padding: 0,
      marginLeft: '8px',
    },
    action: {
      marginRight: '16px',
    },
    message: {
      padding: 0,
      fontWeight: 500,
      fontSize: '1.75rem',
      lineHeight: '3rem',
      letterSpacing: '0.01875rem',
      flexGrow: 1,
    },
    standardInfo: {
      backgroundColor: palette.info.light,
    },
    standardError: {
      backgroundColor: palette.error.light,
    },
    standardWarning: {
      backgroundColor: palette.warning.light,
    },
    standardSuccess: {
      backgroundColor: palette.success.light,
    },
  },
}

export default MuiAlert
