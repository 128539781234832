import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import ProductForm from '@app/src/pages/Product/ProductForm'
import ProductSceneContainer from '@app/src/pages/Product/ProductSceneContainer'
import { Product, ProductFormData, ProductSceneView } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Box, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router-dom'

const CreateProductScene: React.FC = () => {
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()

  const requestListRef = useRef<HTMLDivElement | null>(null)
  const [scroll, setScroll] = useState(false)

  const formMethods = useForm<ProductFormData>()

  const scrollToRequestList = (): void => {
    requestListRef.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    formMethods.reset({})
  }, [])

  useEffect(() => {
    if (scroll && requestListRef.current) {
      scrollToRequestList()
      setScroll(false)
    }
  }, [scroll, requestListRef])

  const { mutate: createProduct, isLoading: isCreatingProduct } = useCreateResource<Product, Partial<Product>>({
    options: {
      onSuccess: res => {
        showSnackbar({
          message: formatMessage({ id: 'schemas.product.create.success' }),
          severity: NotificationSeverity.success,
        })
        history.push(generatePath(paths.product, { id: res.id }))
        queryClient.invalidateQueries(FetchKey.Product)
      },
      onError: error => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const onSubmit = (values: ProductFormData) => {
    const { provider, ...rest } = values

    createProduct({
      url: endpoints.saveProduct,
      body: { ...rest, providerId: provider?.id },
    })
  }

  const onSubmitProduct = () => {
    formMethods.handleSubmit(onSubmit)()
  }

  return (
    <ProductSceneContainer
      view={ProductSceneView.Create}
      onCreateRequest={() => setScroll(true)}
      isUpdating={isCreatingProduct}
    >
      <Grid container spacing={5} px={5}>
        <Grid item xs={12} md={12}>
          <Paper
            elevation={0}
            sx={{
              marginBottom: 4,
              marginTop: 4,
              padding: 6,
            }}
          >
            <Box mb={3}>
              <Typography variant="h5" gutterBottom align="left">
                {formatMessage({ id: 'schemas.product.productDetails' })}
              </Typography>
            </Box>
            <FormProvider {...formMethods}>
              <ProductForm
                onCancel={() => history.push(generatePath(paths.productsCollection))}
                isSavingProduct={isCreatingProduct}
                onSubmitProduct={onSubmitProduct}
              />
            </FormProvider>
          </Paper>
        </Grid>
      </Grid>
    </ProductSceneContainer>
  )
}

export default CreateProductScene
