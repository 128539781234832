import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import LinkButton from '@app/src/components/LinkButton'
import { BaseAssessmentViews } from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/BaseAssessmentScene'
import { AssessmentTemplate, AssessmentType } from '@app/src/types/resourceExplorer'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import paths from '@app/src/wf-constants/paths'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Chip, MenuItem, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { Operators } from '../ResourceCollection/Filters/useFilters'
import { AssessmentExploreViews } from './AssessmentExplore/AssessmentsExploreScene'
import AssessmentOverviewGraph from './AssessmentOverviewGraph'

type AssessmentOverviewGraphWithHeaderProps = {
  assessmentTemplate: AssessmentTemplate
}

const AssessmentOverviewGraphWithHeader: React.FC<AssessmentOverviewGraphWithHeaderProps> = ({
  assessmentTemplate,
}) => {
  const { formatMessage } = useIntl()
  const [activePeriod, setActivePeriod] = useState<number>()
  const [providersCount, setProvidersCount] = useState<number>()

  const {
    facets: [periodFacets],
    isLoading: isLoadingPeriod,
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'periods'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [
      { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
    ],
  })

  const orderedPeriods = useMemo(
    () => periodFacets?.map(facet => facet.label).sort((a, b) => comparePeriods(b, a)),
    [periodFacets],
  )

  useEffect(() => {
    if (orderedPeriods?.[0]) {
      setActivePeriod(Number(orderedPeriods?.[0]))
    }
  }, [orderedPeriods])

  const periodProvidersCount = periodFacets?.find(x => x.value === activePeriod?.toString())?.count

  useEffect(() => {
    setProvidersCount(periodProvidersCount)
  }, [activePeriod])

  const isLoadedWithData =
    !isLoadingPeriod && Boolean(activePeriod) && Boolean(providersCount) && Boolean(orderedPeriods)
  const isLoadedWithoutData =
    !isLoadingPeriod &&
    activePeriod === undefined &&
    providersCount === undefined &&
    orderedPeriods !== undefined &&
    orderedPeriods.length === 0

  const isLoading = !(isLoadedWithData || isLoadedWithoutData)

  if (isLoading) {
    return (
      <Stack bgcolor="grey.200" borderRadius={1} p={4} gap={4} minHeight={700} height="100%" flexGrow={1}>
        <Typography variant="h2">{assessmentTemplate.name}</Typography>

        <Stack flexGrow={1} alignItems="flex-end" justifyContent="stretch" width="100%">
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack bgcolor={!activePeriod ? 'grey.200' : 'common.white'} borderRadius={1} p={4} gap={3} height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{assessmentTemplate.name}</Typography>

        {Boolean(orderedPeriods?.length && activePeriod) && (
          <Stack direction="row" ml={2} alignSelf="start" pt={0.5}>
            <Typography color="primary" variant="button" mr={1}>
              {formatMessage({ id: 'reporting.period' })}
            </Typography>

            <TextField
              select
              size="small"
              variant="standard"
              onChange={e => setActivePeriod(Number(e.target.value))}
              value={activePeriod ?? ''}
              disabled={isLoadingPeriod}
            >
              {orderedPeriods?.map(period => (
                <MenuItem key={period.toString()} value={period.toString()}>
                  <Chip label={period} size="small" color="default" />
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        )}
      </Stack>

      {Boolean(providersCount) && (
        <Stack>
          <Typography>{formatMessage({ id: 'assessments.suppliersAssessed' })}</Typography>
          <Typography variant="subtitle1">{providersCount}</Typography>
        </Stack>
      )}

      <Paper
        elevation={0}
        variant={activePeriod ? 'outlined' : undefined}
        sx={({ palette }) =>
          activePeriod ? { padding: 2, outline: `1px solid ${palette.grey[300]}`, borderRadius: 2 } : {}
        }
      >
        <AssessmentOverviewGraph assessmentTemplate={assessmentTemplate} activePeriod={activePeriod} />
      </Paper>

      {Boolean(providersCount) && (
        <LinkButton
          sx={{ alignSelf: 'center' }}
          variant="contained"
          endIcon={<OpenInNewIcon />}
          to={
            assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment
              ? generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Overview })
              : generatePath(paths.exploreAssessment, {
                  view: AssessmentExploreViews.Overview,
                  assessmentTemplateId: assessmentTemplate.id,
                  activePeriod: activePeriod,
                })
          }
        >
          {formatMessage({ id: 'assessments.overviewGraph.exploreResult' })}
        </LinkButton>
      )}
    </Stack>
  )
}

export default AssessmentOverviewGraphWithHeader
