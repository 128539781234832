import { getYears } from '@app/src/utils'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export interface PeriodOptions {
  value: number
  label: string
}

export const useSpendPeriodOptions = () => {
  const { formatMessage } = useIntl()

  const getYearOptionLabel = (year: number) => {
    const isCurrentYear = year === new Date().getFullYear()
    return `${String(year)}${isCurrentYear ? ` (${formatMessage({ id: `form.createRequest.lastReportingPeriod` })})` : ''}`
  }

  const SPEND_PERIOD_OPTIONS: PeriodOptions[] = useMemo(
    () =>
      getYears(10, 0).map(y => ({
        value: y,
        label: getYearOptionLabel(y),
      })),
    [],
  )

  return { SPEND_PERIOD_OPTIONS }
}
