import { Components } from '@mui/material'
import { grey } from '@mui/material/colors'

const MuiAvatar: Components['MuiAvatar'] = {
  defaultProps: {
    variant: 'rounded',
  },
  styleOverrides: {
    root: {
      background: grey[600],
      fontWeight: 400,
      fontSize: '2.25rem',
      lineHeight: '3rem',
      letterSpacing: '0.01875rem',
    },
    circular: {
      img: {
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '50%',
      },
    },
    rounded: {
      img: {
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
      },
    },
    square: {
      img: {
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
  },
}

export default MuiAvatar
