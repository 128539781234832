import { Theme, createTheme } from '@mui/material/styles'
import { breakpoints } from './breakpoints'
import { components } from './components'
import { palette } from './palette'
import shadows from './shadows'
import { typography } from './typography'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Theme {
    wfShadows: string[]
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    wfShadows: string[]
  }
}

export default createTheme({
  typography,
  palette,
  components,
  wfShadows: shadows,
  breakpoints: { values: breakpoints },
  shape: {
    borderRadius: 4,
  },
})
