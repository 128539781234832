import { Box, Grid } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import ReportActions from './Actions'
import Report from './Report'
import ReportingHeader from './ReportingHeader'

const AccessorReport: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box px={{ xs: 2, md: 5 }} mt={5} ref={headerRef}>
        <ReportingHeader />
      </Box>

      <Box bgcolor="grey.100" px={{ xs: 2, md: 5 }} py={5}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={7} lg={8}>
            <Report />
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <ReportActions />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AccessorReport
