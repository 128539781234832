import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import React from 'react'
import { CategoryOption } from '@app/src/types/categories'

interface CategoryOptionLabelProps {
  categoryOptionId: string
  categoryCount: number
  isLastItem: boolean
}

const CategoryOptionLabel = ({ categoryOptionId, categoryCount, isLastItem }: CategoryOptionLabelProps) => {
  const { data: category, isLoading } = useFetchResource<CategoryOption[]>({
    key: [FetchKey.CategoryOption, categoryOptionId],
    endpoint: endpoints.categoryOptionById(categoryOptionId),
    options: {
      enabled: Boolean(categoryOptionId),
    },
  })

  return (
    <>
      {category?.[0].name}
      {!isLoading && categoryCount > 1 && !isLastItem && ', '}
    </>
  )
}

export default CategoryOptionLabel
