import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import endpoints from '@app/src/api/endpoints'
import { useFileDownload } from '@app/src/api/fetchHooks'
import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { useAccount } from '@app/src/context/AccountContext'
import { useInquiryNavigator } from '@app/src/hooks/inquiryNavigator'
import qs from 'qs'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { getPeriodDisplayName } from '@app/src/utils'
import { plural } from '@app/src/utils/translationMarkup'
import paths, { providerBaseAssessmentWithId, reporting, resourceExplorer } from '@app/src/wf-constants/paths'
import { NotificationItem } from './NotificationsScene'

export const NotificationTypes = {
  CorrectionNeeded: 'correctionneeded',
  DueInDays: 'dueindays',
  OverdueForDays: 'overduefordays',
  Activate: 'activate',
  InvitationRejected: 'invitationrejected',
  InvitationAccepted: 'invitationaccepted',
  Approved: 'approved',
  Submitted: 'submitted',
  ExcelExport: 'excelexport',
  ExportFailed: 'exportfailed',
  Created: 'created',
  InquiryReceived: 'inquiryreceived',
  Shared: 'shared',
  MappingRequestSent: 'mappingrequestsent',
  MappingResponseSubmitted: 'mappingresponsesubmitted',
  AnswerExpired: 'answerexpired',
  Import: 'import',
  BaseLineAssessmentLevelChange: 'baselineassessmentlevelchange',
}

const useStyles = makeStyles(({ palette }) => ({
  infoText: {
    color: palette.grey[palette.mode === 'light' ? 500 : 200],
  },
}))

export const useNotificationData = (): {
  getNotificationAction: (notification: NotificationItem) => string | (() => Promise<void>) | null
  getNotificationTitle: (notification: NotificationItem) => React.ReactNode
  isActionButtonLoading: boolean
} => {
  const { hasPermission } = usePermissions()
  const { account } = useAccount()
  const { handleInquiryClick, isCreateReportLoading } = useInquiryNavigator()
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { downloadFile, isFileDownloading } = useFileDownload()

  const getNotificationAction = (notification: NotificationItem): string | (() => Promise<void>) | null => {
    const { requestId, productId, responseId, periodStartsAt, periodEndsAt, templateId, targetObjectId } = notification
    const goToInquiryCallback = () =>
      handleInquiryClick(templateId ?? 0, periodStartsAt ?? '', periodEndsAt ?? '', targetObjectId ?? 0)

    const handleExportDownload = async (id: number) => {
      await downloadFile(endpoints.exportDownload(id))
    }
    const downloadExcelExportCallback = () => handleExportDownload(targetObjectId ?? 0)

    switch (notification.sourceEventType.toLowerCase()) {
      // SOURCE OBJECT TYPE: REQUEST
      case NotificationTypes.DueInDays:
      case NotificationTypes.OverdueForDays:
        return goToInquiryCallback
      case NotificationTypes.Activate:
        if (!requestId) return null
        return `${reporting}?${qs.stringify({ requestId })}`

      // SOURCE OBJECT TYPE: RESPONSE
      case NotificationTypes.CorrectionNeeded:
      case NotificationTypes.Approved:
      case NotificationTypes.Submitted:
        if (!requestId || !responseId) return null
        return `${reporting}?${qs.stringify({ requestId, responseId })}`
      case NotificationTypes.ExcelExport:
        return downloadExcelExportCallback
      case NotificationTypes.Created: {
        if (hasPermission(Permissions.TRANSPARENCY_USER)) return ''

        return generatePath(resourceExplorer, {
          resourceType: notification.providerType?.toLowerCase() ?? '',
          resourceId: notification.providerId ?? '',
          activeTabParam: 'overview',
        })
      }
      case NotificationTypes.Shared:
        return requestId ? `${reporting}?${qs.stringify({ requestId, responseId })}` : null
      case NotificationTypes.InquiryReceived:
        return goToInquiryCallback
      case NotificationTypes.MappingRequestSent:
        return generatePath(paths.mappingRequest, { id: requestId })
      case NotificationTypes.MappingResponseSubmitted:
        return generatePath(paths.product, { id: productId })
      case NotificationTypes.AnswerExpired:
        return `${reporting}?${qs.stringify({ requestId })}`
      case NotificationTypes.InvitationRejected:
      case NotificationTypes.ExportFailed:
      case NotificationTypes.Import:
        return downloadExcelExportCallback
      case NotificationTypes.BaseLineAssessmentLevelChange:
        return generatePath(providerBaseAssessmentWithId, {
          supplierId: notification.providerId ?? 0,
        })
      default:
        return null
    }
  }

  const getNotificationTitle = (notification: NotificationItem): React.ReactNode => {
    const { objectTitle, role, solutionType, days, originalFileName } = notification
    const sourceEventType = notification.sourceEventType.toLowerCase()

    switch (sourceEventType) {
      case NotificationTypes.CorrectionNeeded:
        return formatMessage({ id: 'schemas.notification.transparency.correctionneeded' }, { request: objectTitle })
      case NotificationTypes.DueInDays:
      case NotificationTypes.OverdueForDays:
        return formatMessage(
          { id: `schemas.notification.transparency.${sourceEventType}` },
          {
            days: days,
            request: objectTitle,
            b: (chunk: React.ReactNode) => <b>{chunk}</b>,
          },
        )
      case NotificationTypes.Import:
        return formatMessage(
          { id: `schemas.notification.import` },
          {
            name: objectTitle,
            successCount: notification?.successCount ?? 0,
            errorCount: notification?.errorCount ?? 0,
          },
        )
      case NotificationTypes.InvitationRejected:
      case NotificationTypes.InvitationAccepted:
        return formatMessage(
          { id: `schemas.notification.${sourceEventType}` },
          {
            name: objectTitle,
            role: formatMessage({ id: `roles.${role}` }),
            solution: formatMessage({ id: `solutions.${solutionType}` }),
          },
        )
      case NotificationTypes.ExcelExport:
        return formatMessage(
          {
            id: 'schemas.notification.excelExport',
          },
          { name: originalFileName, small: (text: React.ReactNode) => <b className={classes.infoText}>{text}</b> },
        )
      case NotificationTypes.ExportFailed:
        return formatMessage({ id: 'schemas.notification.exportFailed' })
      case NotificationTypes.Created: {
        if (
          hasPermission(Permissions.TRANSPARENCY_USER) &&
          notification.creatorOrganizationId !== account?.organization?.id // product mapping edgecase where some transparency users can invite organizations
        ) {
          return formatMessage(
            { id: 'schemas.notification.providerCreated' },
            {
              providerType: formatMessage({ id: `general.${notification.providerType}` }, plural),
              creatorOrganizationName: notification.creatorOrganizationName,
            },
          )
        } else {
          return formatMessage(
            { id: 'schemas.notification.providerReferralCreated' },
            {
              providerType: formatMessage({ id: `general.${notification.providerType}` }, plural),
              providerName: notification.providerName,
            },
          )
        }
      }
      case NotificationTypes.InquiryReceived:
        return (
          <Typography variant="body1">
            {formatMessage({ id: 'schemas.notification.transparency.activate' })}
            <strong>{objectTitle}</strong>
          </Typography>
        )
      case NotificationTypes.Shared:
        return formatMessage(
          { id: 'schemas.notification.sharedRequest' },
          {
            providerName: <strong>{notification.providerName}</strong>,
            requestName: <strong>{notification.objectTitle}</strong>,
          },
        )
      case NotificationTypes.Submitted:
        return formatMessage(
          { id: 'schemas.notification.submitted' },
          {
            providerName: <strong>{notification.providerName}</strong>,
            requestName: <strong>{notification.objectTitle}</strong>,
          },
        )
      case NotificationTypes.MappingRequestSent:
        return formatMessage(
          { id: 'schemas.notification.mappingRequestSent' },
          {
            productName: <strong>{notification.productName}</strong>,
          },
        )
      case NotificationTypes.MappingResponseSubmitted:
        return formatMessage(
          { id: 'schemas.notification.mappingResponseSubmitted' },
          {
            productName: <strong>{notification.productName}</strong>,
            creatorOrganizationName: <strong>{notification.creatorOrganizationName}</strong>,
          },
        )
      case NotificationTypes.AnswerExpired:
        return formatMessage(
          { id: 'schemas.notification.answerExpired' },
          {
            providerName: <strong>{notification.providerName}</strong>,
            requestName: <strong>{notification.requestName}</strong>,
            periodName: <strong>{getPeriodDisplayName(notification.periodStartsAt, notification.periodEndsAt)}</strong>,
          },
        )
      case NotificationTypes.BaseLineAssessmentLevelChange:
        return formatMessage(
          { id: 'schemas.notification.baseLineAssessmentLevelChange' },
          {
            providerName: <strong>{notification?.providerName}</strong>,
            oldTotalLevel: notification?.oldTotalLevel ?? 0,
            newTotalLevel: notification?.newTotalLevel ?? 0,
          },
        )
      default:
        if (notification.activationType)
          switch (notification.activationType) {
            case 'Deactivated':
              return (
                <Typography variant="body1">
                  {formatMessage(
                    { id: 'schemas.notification.flagsDeactivated' },
                    { questionName: <strong>{notification.objectTitle}</strong> },
                  )}
                </Typography>
              )
            default:
              return (
                <Typography variant="body1">
                  {formatMessage(
                    { id: 'schemas.notification.flagsApplied' },
                    { questionName: <strong>{notification.objectTitle}</strong> },
                  )}
                </Typography>
              )
          }

        return (
          <Typography variant="body1">
            {formatMessage({
              id: `schemas.notification${
                hasPermission(Permissions.TRANSPARENCY_USER) ? '.transparency' : ''
              }.${sourceEventType}`,
            })}
            <strong>{objectTitle}</strong>
          </Typography>
        )
    }
  }
  return {
    getNotificationAction,
    getNotificationTitle,
    isActionButtonLoading: isFileDownloading || isCreateReportLoading,
  }
}
