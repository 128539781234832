import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useMenuState } from '@app/src/hooks/mui-hooks'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { ADMIN_ROLES } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Create as CreateIcon, Delete as DeleteIcon, Visibility } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useConfirm } from 'material-ui-confirm'
import qs from 'qs'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath } from 'react-router'
import { Link, useHistory } from 'react-router-dom'

export type ReportMenuCellProps = {
  questionnaireTemplate: QuestionnaireTemplate
  isStandard?: boolean
}

const ReportMenuCell: React.FC<ReportMenuCellProps> = ({ questionnaireTemplate, isStandard }) => {
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()

  const { formatMessage } = useIntl()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { mutateAsync, isLoading: isDeleting } = useDeleteResource<QuestionnaireTemplate>()
  const confirm = useConfirm()
  const { account } = useAccount()
  const { questionnaireBuilder } = useFlags()
  const { role } = useAuthentication().scope

  const canEditAndDeleteTemplate =
    !isStandard &&
    questionnaireTemplate.creatorOrganizationId === account?.organization?.id &&
    questionnaireBuilder &&
    ADMIN_ROLES.includes(role?.toLowerCase() ?? '')

  const handleDelete = async () => {
    await mutateAsync(
      {
        url: endpoints.deleteTemplate(questionnaireTemplate.id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.SharedAndOwnedTemplates)
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
            severity: 'success',
          })
          closeMenu()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return (
    <ParentCellOrFragment sx={{ display: 'flex', justifyContent: 'right' }}>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={closeMenu}>
        <MenuItem
          disabled={isDeleting}
          onClick={() =>
            history.push({
              pathname: generatePath(paths.sustainabilityLibrary, {
                configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questionnaires,
                configurationsSubPage: questionnaireTemplate.id,
              }),
              search: isStandard ? qs.stringify({ isStandard: true }) : undefined,
            })
          }
        >
          <ListItemIcon>
            <Visibility color="primary" fontSize="small" />
          </ListItemIcon>
          {formatMessage({ id: 'schemas.requestTemplate.preview' })}
        </MenuItem>
        {canEditAndDeleteTemplate && (
          <MenuItem
            disabled={isDeleting}
            component={Link}
            to={generatePath(paths.editTemplate, {
              templateId: questionnaireTemplate.id,
            })}
          >
            <ListItemIcon>
              <CreateIcon color="primary" fontSize="small" />
            </ListItemIcon>
            {formatMessage({ id: 'general.edit' })}
          </MenuItem>
        )}
        {canEditAndDeleteTemplate && (
          <MenuItem
            disabled={isDeleting}
            onClick={() =>
              confirm({
                title: formatMessage({ id: 'general.areYouSure' }),
                content: formatMessage({ id: 'templateBuilder.delete' }, { br: <br /> }),
                confirmationText: formatMessage({ id: 'general.delete' }),
                confirmationButtonProps: { variant: 'text' },
                cancellationButtonProps: { variant: 'text' },
                buttonOrder: ['confirm', 'cancel'],
              }).then(handleDelete)
            }
          >
            <ListItemIcon>
              <DeleteIcon color="primary" fontSize="small" />
            </ListItemIcon>
            {formatMessage({ id: 'general.delete' })}
          </MenuItem>
        )}
      </Menu>
    </ParentCellOrFragment>
  )
}

export default ReportMenuCell
