import { Box } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { usePatchUpdateResource } from '@app/src/api/updateHooks'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { ProviderPriority } from '@app/src/types/categories'
import { Provider } from '@app/src/types/organizations'
import { NotificationSeverity } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewPriortyProps = {
  provider: Provider
} & Omit<DrawerViewProps, 'title'>

type UpdateProviderPriority = Pick<Provider, 'priority'>

const DrawerViewPriorty: React.FC<DrawerViewPriortyProps> = ({ provider, ...props }) => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { closeDrawer } = useDrawer()
  const { mutate: saveResource, isLoading: isUpdating } = usePatchUpdateResource()

  const formMethods = useForm<UpdateProviderPriority>({
    mode: 'onChange',
    defaultValues: {
      priority: provider.priority,
    },
  })

  const handleSaveFinalRiskRating = async (values: UpdateProviderPriority) => {
    saveResource(
      { url: endpoints.provider(provider.id), body: { priority: values.priority } },
      {
        onError: requestError => {
          showErrorNotification({ requestError })
        },
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceSave' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          queryClient.invalidateQueries(FetchKey.RiskScreening)
          closeDrawer()
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.provider.priority' })}
      subTitle={provider.name}
      buttons={[
        {
          loading: isUpdating,
          label: formatMessage({ id: 'general.update' }),
          variant: 'contained',
          color: 'primary',
          type: 'submit',
        },
      ]}
      onFormSubmit={formMethods.handleSubmit(handleSaveFinalRiskRating)}
      {...props}
    >
      <Box px={2} mt={2}>
        <Select
          name="priority"
          control={formMethods.control}
          required
          options={Object.keys(ProviderPriority).map(priority => ({
            label: formatMessage({ id: `schemas.provider.priorityValues.${priority}` }),
            value: priority,
          }))}
          findSelectedValue={(value, option) => option.value === value}
          fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
        />
      </Box>
    </DrawerView>
  )
}

export default DrawerViewPriorty
