import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ActorTypeModel } from '@app/src/types/product'

interface UseActorTypesOptions {
  enabled?: boolean
  filter?: FilterGroup[]
}

export default ({ filter = [], enabled = true }: UseActorTypesOptions = {}) => {
  return useFetchCollectionWithPost<ActorTypeModel>({
    endpoint: endpoints.actorTypesCollection,
    key: FetchKey.ActorTypeCollection,
    payload: {
      filter: filter,
      include: [],
    },
    options: {
      refetchOnMount: false,
      enabled,
    },
  })
}
