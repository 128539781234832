import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import CreationModalStep, { CreationModalStepProps } from '@app/src/components/CreationModal/CreationModalStep'
import SendEmailCustomization from '@app/src/components/ReferralContact/SendEmailCustomization'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { ProductMappingRequest } from '@app/src/types/product'
import { Inquiry } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { SendOutlined } from '@mui/icons-material'
import { Dialog, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { GroupedRequest } from '../../SentMappingRequestCollection'
import { GroupedInquiries } from '../AccessorRequestsScene'
import ReminderSuccessScreen from './ReminderSuccessScreen'
import RequestReminderEmailPreview from './RequestReminderEmailPreview'
import RequestsReminderList from './RequestsReminderList'
import SendReminderReview from './SendReminderReview'

export enum REQUEST_TYPES {
  Questionnaire = 'questionnaire',
  MappingRequest = 'mapping_request',
}

export enum REQUESTS_REMINDER_QUESTION_KEYS {
  REQUESTS_IDS = 'requestsIds',
  OVERVIEW = 'overview',
}

type RequestsReminderModalProps = {
  open: boolean
  onClose: () => void
  unhandledRequests: (Inquiry | ProductMappingRequest)[]
  unhandledRequestsByResponder: (GroupedRequest | GroupedInquiries)[]
  requestType: REQUEST_TYPES
}

export type ContactPersonInfo = {
  name: string
  email: string
  phoneNumber?: string
}

interface Reminder {
  responderOrganizationId: number
  requestIds: number[]
}

export type RequestsReminderFormData = {
  reminders: Reminder[]
  requestsIds?: Array<number>
  organizationImageUrl?: string
  customMessage?: string
  contactPersonInfo?: ContactPersonInfo
}

function RequestsReminderModal({
  open,
  onClose,
  unhandledRequests,
  unhandledRequestsByResponder,
  requestType,
}: RequestsReminderModalProps) {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const queryClient = useQueryClient()
  const { trackEvent } = useAmplitude()
  const { showErrorNotification } = useErrorNotification()
  const { mutate: sendBulkReminders, isLoading: isSendingReminders } = useCreateResource<RequestsReminderFormData>()
  const { activeStep, setActiveStep, setTotalSteps } = useCreationModalProgress()
  const formMethods = useForm<RequestsReminderFormData>({
    shouldUnregister: false,
    defaultValues: {
      [REQUESTS_REMINDER_QUESTION_KEYS.REQUESTS_IDS]: [],
    },
  })
  const { handleSubmit, reset, watch } = formMethods
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)

  const watchRequests = watch(REQUESTS_REMINDER_QUESTION_KEYS.REQUESTS_IDS)

  const currentlySelectedGroups = unhandledRequestsByResponder.filter(gr =>
    gr.requests.some(r => watchRequests?.includes(r.id)),
  )

  const QUESTIONS: CreationModalStepProps[] = [
    {
      title: formatMessage({ id: 'form.requestsReminder.sendReminder' }),
      description: (
        <Typography>
          {formatMessage(
            { id: 'form.requestsReminder.sendReminderDescription' },
            {
              b: (chunks: React.ReactNode) => (
                <span style={{ fontWeight: 600, color: palette.common.black }}>{chunks}</span>
              ),
              requestsCount: watchRequests?.length,
              companyCount: currentlySelectedGroups.length,
            },
          )}
        </Typography>
      ),
      fieldnames: [REQUESTS_REMINDER_QUESTION_KEYS.REQUESTS_IDS],
      children: (
        <RequestsReminderList
          groupedRequests={unhandledRequestsByResponder}
          currentlySelectedGroups={currentlySelectedGroups}
        />
      ),
      disableInitialBack: true,
      overrideButtonTextId: 'general.continue',
    },
    {
      title: formatMessage({ id: 'form.requestsReminder.emailPreview' }),
      description: '',
      fieldnames: [],
      children: <SendEmailCustomization />,
      disableInitialBack: true,
      splitView: {
        enabled: true,
        component: (
          <RequestReminderEmailPreview
            responder={currentlySelectedGroups?.[0]?.responder}
            respondersCount={currentlySelectedGroups?.[0]?.requests?.length ?? 0}
          />
        ),
      },
      overrideButtonTextId: 'general.continue',
    },
    {
      title: '',
      description: '',
      fieldnames: [REQUESTS_REMINDER_QUESTION_KEYS.OVERVIEW],
      children: (
        <SendReminderReview
          respondersCount={currentlySelectedGroups?.length ?? 0}
          descriptionText={formatMessage(
            { id: 'form.requestsReminder.reviewDescription' },
            { respondersCount: currentlySelectedGroups?.length ?? 0 },
          )}
        />
      ),
      hideNextButton: true,
      disableInitialBack: true,
      overrideButtonTextId: 'general.send',
      nextButtonProps: {
        startIcon: <SendOutlined />,
      },
      isLoading: isSendingReminders,
    },
  ]

  const activeQuestion = useMemo(() => QUESTIONS[activeStep - 1], [activeStep, QUESTIONS])

  const onSubmit = (values: RequestsReminderFormData) => {
    const body = {
      reminders: currentlySelectedGroups.map(r => ({
        responderOrganizationId: r.responder.id,
        requestIds: r.requests.map(rq => rq.id),
      })),
      customMessage: values.customMessage,
      emailContactPerson: values.contactPersonInfo,
    }

    sendBulkReminders(
      {
        url: endpoints.sendRequestReminders,
        body,
      },
      {
        onSuccess: () => {
          trackEvent({
            name: AmplitudeTrackingEvents.Accessor.RequestReminder.ReminderSent,
            eventProps: {
              request_type: requestType,
              count: values?.reminders?.length ?? 0,
            },
          })

          queryClient.invalidateQueries(FetchKey.Request)
          setShowSuccessScreen(true)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  useEffect(() => {
    if (open) {
      trackEvent({
        name: AmplitudeTrackingEvents.Accessor.RequestReminder.Opened,
        eventProps: {
          request_type: requestType,
        },
      })
    }
  }, [open])

  useEffect(() => {
    const unhandledRequestsIds = unhandledRequests.map(request => request.id)
    reset({
      [REQUESTS_REMINDER_QUESTION_KEYS.REQUESTS_IDS]: unhandledRequestsIds,
    })

    return () => {
      setActiveStep(1)
      reset()
      setShowSuccessScreen(false)
    }
  }, [open])

  useEffect(() => {
    if (!unhandledRequests.length) {
      onClose()
    }
  }, [unhandledRequests.length])

  useEffect(() => {
    setTotalSteps(QUESTIONS.length)
  }, [QUESTIONS.length])

  return (
    <Dialog open={open} onClose={onClose} fullScreen closeAfterTransition>
      {showSuccessScreen ? (
        <ReminderSuccessScreen onClose={onClose} respondersCount={currentlySelectedGroups?.length ?? 0} />
      ) : (
        <CreationModalContainer
          title={
            activeStep === 1
              ? formatMessage({ id: 'form.requestsReminder.sendReminder' })
              : activeStep === 2
                ? formatMessage({ id: 'form.requestsReminder.sendReminder' })
                : formatMessage({ id: 'form.requestsReminder.sendReminder' })
          }
          onClose={onClose}
        >
          <FormProvider {...formMethods}>
            <Stack component="form" flexGrow={1} alignItems="center" onSubmit={handleSubmit(onSubmit)}>
              {activeQuestion && (
                <CreationModalStep {...activeQuestion} widthOverride={584}>
                  {activeQuestion.children}
                </CreationModalStep>
              )}
            </Stack>
          </FormProvider>
        </CreationModalContainer>
      )}
    </Dialog>
  )
}

export default RequestsReminderModal
