import { AddCircleOutlined } from '@mui/icons-material'
import { Box, ButtonBase, Divider, lighten, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { PropsWithChildren } from 'react'
import { Handle, Position } from 'reactflow'

const useStyles = makeStyles(({ spacing, palette }) => ({
  baseNode: {
    '& .addParent, & .addChild': {
      overflow: 'hidden',
      opacity: 0,
      maxHeight: 0,
      transition: 'max-height 0.17s ease-out, opacity 0.2s ease-in',
    },
    /*&:hover .addParent, */ '&:hover .addChild': {
      transition: 'max-height 0.5s ease-in, opacity 0.3s ease-in',
      opacity: 1,
      maxHeight: 500,
    },
  },
  addNodeButton: {
    padding: spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: palette.grey[400],
    '& hr': {
      backgroundColor: palette.grey[400],
      height: 2,
    },
    '&:hover': {
      color: 'rgba(0, 153, 255, 1)',
    },
    '&:hover hr': {
      backgroundColor: 'rgba(0, 153, 255, 1)',
    },
  },
}))

const CreateNodeButton: React.FC<{ openDrawer: () => void }> = ({ openDrawer }) => {
  const classes = useStyles()
  return (
    <ButtonBase className={classes.addNodeButton} onClick={openDrawer}>
      <Box width="35%">
        <Divider />
      </Box>
      <AddCircleOutlined fontSize="large" />
      <Box width="35%">
        <Divider />
      </Box>
    </ButtonBase>
  )
}

const BaseNode: React.FC<
  PropsWithChildren<{ showSource?: boolean; showTarget?: boolean; openDrawer?: () => void }>
> = ({ children, showTarget, showSource, openDrawer }) => {
  const classes = useStyles()
  return (
    <Box className={classes.baseNode}>
      {showTarget && <Handle style={{ visibility: 'hidden' }} type="target" position={Position.Top} isConnectable />}
      <Paper
        elevation={0}
        sx={theme => ({ border: `solid 1px`, borderColor: lighten(theme.palette.text.primary, 0.87) })}
      >
        {children}
      </Paper>
      {openDrawer && (
        <Box className="addChild">
          <CreateNodeButton openDrawer={openDrawer} />
        </Box>
      )}
      {showSource && <Handle style={{ visibility: 'hidden' }} type="source" position={Position.Bottom} isConnectable />}
    </Box>
  )
}

export default BaseNode
