import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResourceWithoutBody } from '@app/src/api/updateHooks'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { SuggestedContact, SuggestedContactStatus } from '@app/src/types/resourceExplorer'
import { AccessTime, ChatOutlined, FeedbackOutlined, MailOutline, ManageAccountsOutlined } from '@mui/icons-material'
import { Alert, AlertProps, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../DrawerContext'

export type DrawerViewSuggestedContactReviewProps = {
  contact: SuggestedContact
} & Omit<DrawerViewProps, 'title'>

enum Action {
  Approve = 'approve',
  Reject = 'reject',
}

export const mapSuggestedContactStatusToSeverity = (status: SuggestedContactStatus): AlertProps['severity'] => {
  switch (status) {
    case SuggestedContactStatus.Disapproved:
      return 'error'
    case SuggestedContactStatus.InvitationAccepted:
      return 'success'
    case SuggestedContactStatus.InvitationSent:
    case SuggestedContactStatus.Requested:
      return 'info'
  }
}

const DrawerViewSuggestedContactReview: React.FC<DrawerViewSuggestedContactReviewProps> = ({ contact, ...props }) => {
  const { formatMessage } = useIntl()
  const confirm = useConfirm()
  const { formatValueToDate } = useGetFormattedDate()
  const { mutate, isLoading } = useCreateResourceWithoutBody()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { closeDrawer } = useDrawer()
  const queryClient = useQueryClient()

  const performAction = (action: Action) => {
    mutate(
      {
        url:
          action === Action.Approve
            ? endpoints.acceptSuggestedContact(contact.id)
            : endpoints.rejectSuggestedContact(contact.id),
      },
      {
        onSuccess: () => {
          showSnackbar({ message: formatMessage({ id: 'schemas.organization.update' }), severity: 'success' })
          queryClient.invalidateQueries(FetchKey.SuggestedContacts)
          closeDrawer()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const confirmApproval = async () => {
    confirm({
      content: formatMessage(
        { id: 'suggestedContact.confirm.approve.content' },
        {
          name: contact.name,
          organizationName: contact.organization?.name,
          b: (chunks: React.ReactNode) => <b>{chunks}</b>,
        },
      ),
      confirmationText: formatMessage({ id: 'suggestedContact.confirm.approve.confirmationButtonText' }),
    }).then(() => performAction(Action.Approve))
  }

  const confirmDenial = async () => {
    confirm({
      confirmationText: formatMessage({ id: 'suggestedContact.confirm.reject.confirmationButtonText' }),
    }).then(() => performAction(Action.Reject))
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'suggestedContact.review' })}
      subTitle={contact.organization?.name}
      buttons={
        contact.status === SuggestedContactStatus.Requested
          ? [
              {
                label: formatMessage({ id: 'general.approve' }),
                onClick: confirmApproval,
                variant: 'contained',
                color: 'success',
                disabled: isLoading,
              },
              {
                label: formatMessage({ id: 'general.reject' }),
                onClick: confirmDenial,
                variant: 'contained',
                color: 'error',
                disabled: isLoading,
              },
            ]
          : undefined
      }
      stackButtons
      {...props}
    >
      <Stack flexGrow={1}>
        {contact.status !== SuggestedContactStatus.Requested && (
          <Alert
            severity={mapSuggestedContactStatusToSeverity(contact.status)}
            variant="filled"
            sx={{ alignItems: 'flex-start', borderRadius: 0 }}
          >
            {contact.status}
          </Alert>
        )}

        <List disablePadding dense>
          <ListItem>
            <ListItemAvatar>
              <PersonAvatar name={contact.name} size={AvatarSize.Small} disabled />
            </ListItemAvatar>
            <ListItemText primary={formatMessage({ id: 'suggestedContact.name' })} secondary={contact.name} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <MailOutline color="action" />
            </ListItemAvatar>
            <ListItemText primary={formatMessage({ id: 'suggestedContact.email' })} secondary={contact.email} />
          </ListItem>
          <ListItem divider>
            <ListItemAvatar>
              <AccessTime color="action" />
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: 'suggestedContact.requestedAt' })}
              secondary={formatValueToDate({ value: contact.createdAt })}
            />
          </ListItem>

          {contact.creatorOrganizationId ? (
            <>
              <ListItem>
                <ListItemAvatar>
                  <ManageAccountsOutlined color="action" />
                </ListItemAvatar>
                <ListItemText
                  primary={formatMessage({ id: 'suggestedContact.requestedBy' })}
                  secondary={formatMessage(
                    { id: 'general.valueAtOtherValue' },
                    { value: contact.creatorUser?.name, otherValue: contact.creatorOrganization?.name },
                  )}
                />
              </ListItem>
              <ListItem divider={!contact.reasonExtraInfo}>
                <ListItemAvatar>
                  <FeedbackOutlined color="error" />
                </ListItemAvatar>
                <ListItemText
                  primary={formatMessage({ id: 'suggestedContact.reason' })}
                  secondary={formatMessage({ id: `schemas.addProviderContact.form.reasonTypes.${contact.reasonType}` })}
                />
              </ListItem>
              {contact.reasonExtraInfo && (
                <ListItem divider>
                  <ListItemAvatar>
                    <ChatOutlined color="error" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatMessage({ id: 'suggestedContact.reasonDescription' })}
                    secondary={contact.reasonExtraInfo}
                  />
                </ListItem>
              )}
            </>
          ) : (
            <ListItem divider>
              <ListItemAvatar>
                <FeedbackOutlined color="error" />
              </ListItemAvatar>
              <ListItemText
                primary={formatMessage({ id: 'suggestedContact.organizationAccessRequest' })}
                secondary={formatMessage({ id: 'suggestedContact.organizationAccessRequestDescription' })}
              />
            </ListItem>
          )}
        </List>

        <Stack px={2} gap={2} mt={4}>
          <Stack alignItems="center" flexGrow={1}>
            <CompanyAvatar size={AvatarSize.XXL} organizationName={contact.organization?.name ?? ''} />
            <Typography variant="h6" align="center" mt={2} px={2}>
              {contact.organization?.name}
            </Typography>
          </Stack>

          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.websiteAddress' })}</Typography>
            <ValueCell value={contact.organization?.websiteAddress} disableCell color="textSecondary" />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.address' })}</Typography>
            <ValueCell value={contact.organization?.locations?.[0]?.address} disableCell color="textSecondary" />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.country' })}</Typography>
            <CountryCell countryCode={contact.organization?.country?.countryCode} disableCell color="textSecondary" />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.createdAt' })}</Typography>
            <DateCell value={contact.organization?.createdAt} disableCell />
          </Stack>
        </Stack>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewSuggestedContactReview
