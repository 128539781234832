import { Alert, InputAdornment, Stack } from '@mui/material'
import TextField from '@app/src/components/Ui/TextField'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Question } from '@app/src/types/resourceExplorer'
import { GOAL_TRACKER_QUESTION_KEYS, GoalTrackerFormType } from './CreateGoalTrackerModal'
import GoalDirectionField from './GoalDirectionField'
import { extractBooleanAndUnitId } from './SelectAnswerStep'

type WriteGoalValueStepProps = {
  validRequestItems: Question[]
}

const WriteGoalValueStep: React.FC<WriteGoalValueStepProps> = ({ validRequestItems }) => {
  const { formatMessage } = useIntl()
  const { register, watch, setValue } = useFormContext()

  const watchRequestItemTemplateId: GoalTrackerFormType['questionId'] = watch(
    GOAL_TRACKER_QUESTION_KEYS.REQUEST_ITEM_TEMPLATE_ID,
  )

  const { unitSymbol, unitId, isSimpleNumerical } = extractBooleanAndUnitId(
    validRequestItems,
    watchRequestItemTemplateId,
  )

  return (
    <Stack spacing={3}>
      <GoalDirectionField />
      <TextField
        name={GOAL_TRACKER_QUESTION_KEYS.GOAL_VALUE}
        inputRef={register}
        label={formatMessage({ id: 'schemas.goalTracker.inputGoalValue.label' })}
        fullWidth
        minRows={3}
        type="number"
        inputProps={{ max: unitSymbol === '%' ? 100 : undefined }}
        onChange={e => {
          let value = e.target.value
          if (unitSymbol === '%' && Number(value) > 100) {
            value = '100'
          }

          if (!value) {
            setValue(GOAL_TRACKER_QUESTION_KEYS.GOAL_VALUE, undefined)
          } else {
            setValue(GOAL_TRACKER_QUESTION_KEYS.GOAL_VALUE, value)
            setValue(GOAL_TRACKER_QUESTION_KEYS.GOAL_VALUE_SYMBOL, unitSymbol)
            setValue(GOAL_TRACKER_QUESTION_KEYS.UNIT_ID, unitId)
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unitSymbol}</InputAdornment>,
        }}
      />

      {isSimpleNumerical && (
        <Alert variant="filled" severity="info">
          {formatMessage({ id: 'schemas.goalTracker.simpleNumericalInfo' })}
        </Alert>
      )}
    </Stack>
  )
}

export default WriteGoalValueStep
