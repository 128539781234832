import Permissions, { usePermissions } from '@app/src/auth/permissions'
import EmptyState from '@app/src/components/EmptyState'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import LinkButton from '@app/src/components/LinkButton'
import LoadingButton from '@app/src/components/LoadingButton'
import IconCell from '@app/src/components/Table/Cells/IconCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useInquiryNavigator } from '@app/src/hooks/inquiryNavigator'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import theme from '@app/src/theme'
import { ProductMappingRequest } from '@app/src/types/product'
import { InquiriesByTemplate } from '@app/src/types/resourceExplorer'
import { getDateInDays, getPeriodDisplayName } from '@app/src/utils'
import { distinctBy } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import { AccountTreeOutlined } from '@mui/icons-material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'

interface DeadlineChipProps {
  date: string
}

const DeadlineChip: React.FC<DeadlineChipProps> = ({ date }) => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()

  const today = new Date()
  const inOneWeek = getDateInDays(7)
  const formattedTodaysDate = formatDate(today)?.split(' ')[0] ?? ''
  const formattedWithinAWeeksDate = formatDate(inOneWeek)?.split(' ')[0] ?? ''

  const isExpired = date < formattedTodaysDate
  const isDueSoon = date >= formattedTodaysDate && date < formattedWithinAWeeksDate

  return (
    <Chip
      sx={{
        ...(isDueSoon && { backgroundColor: palette.warning.main }),
        ...(isExpired && { backgroundColor: palette.error.main }),
      }}
      label={formatMessage({ id: 'transparencyOverview.dueInDate' }, { date: formatDate(date)?.split(' ')?.[0] })}
      size="small"
    />
  )
}

interface PendingRequestsProps {
  inquiriesByTemplate: InquiriesByTemplate[]
  productMappingRequests: ProductMappingRequest[]
  isLoading: boolean
}

const PendingRequests: React.FC<PendingRequestsProps> = ({
  inquiriesByTemplate,
  productMappingRequests,
  isLoading,
}) => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const { isCreateReportLoading, handleInquiryClick } = useInquiryNavigator()
  const [inquiriesExpanded, setInquiriesExpanded] = useState(false)
  const [mappingRequestsExpanded, setMappingRequestsExpanded] = useState(false)
  const history = useHistory()
  const classes = {
    cell: {
      display: 'flex',
      alignItems: 'center',
    },
    actionCell: {
      justifyContent: 'flex-end',
    },
  }

  const { hasPermission } = usePermissions()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const maxEntriesToShow = isSmallScreen ? 2 : 3

  if (isLoading) {
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" flexGrow={1}>
        <CircularProgress />
      </Stack>
    )
  }

  // sort in code instead of in the requests since we want nulls last on a list sorted by deadline ascending
  function ascendingWithNullOrUndefinedLast(a: ProductMappingRequest, b: ProductMappingRequest) {
    return !a.deadline ? 1 : !b.deadline ? -1 : new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
  }

  return (
    <Stack px={4} py={2} flexGrow={1}>
      <Box mb={2}>
        <Typography variant="h6">{formatMessage({ id: 'transparencyOverview.toDo' })}</Typography>
      </Box>
      {hasPermission(Permissions.PRODUCT_MAPPING_RESPOND_ACCESS) && productMappingRequests.length > 0 && (
        <>
          <Box my={2}>
            <Typography variant="subtitle1">{formatMessage({ id: 'resourceTypes.productMappingRequest' })}</Typography>
          </Box>
          <Collapse
            in={mappingRequestsExpanded}
            collapsedSize={productMappingRequests.length > 4 ? 270 : 68 * productMappingRequests.length}
          >
            {productMappingRequests.sort(ascendingWithNullOrUndefinedLast).map((mappingRequest, i) => {
              const onClick = () =>
                history.push({
                  pathname: generatePath(paths.mappingRequest, { id: mappingRequest.id }),
                })
              return (
                <React.Fragment key={mappingRequest.id}>
                  <Box
                    sx={{
                      '&:hover': {
                        backgroundColor: palette.grey[100],
                      },
                    }}
                    px={1}
                    py={2}
                    borderRadius="4px"
                  >
                    <Grid container>
                      <Grid item xs={3} sx={classes.cell}>
                        <IconCell
                          title={`${mappingRequest?.product?.name} - ${mappingRequest.periodName}`}
                          icon={<AccountTreeOutlined fontSize="small" />}
                          onClick={onClick}
                          disableCell
                        />
                      </Grid>
                      <Grid item xs={6} sx={classes.cell}>
                        <Box mr={2} display="flex" alignItems="center">
                          <CompanyAvatar
                            organizationName={mappingRequest?.creatorOrganization?.name}
                            imageUrl={mappingRequest?.creatorOrganization?.image?.url}
                            size={AvatarSize.Small}
                          />
                          <Typography ml={1} variant="body1">
                            {mappingRequest?.creatorOrganization?.name}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sx={classes.cell}>
                        {mappingRequest?.deadline && <DeadlineChip date={mappingRequest.deadline} />}
                      </Grid>
                      <Grid item xs={1} sx={{ ...classes.cell, ...classes.actionCell }}>
                        <LoadingButton loading={isCreateReportLoading} variant="contained" onClick={onClick}>
                          {formatMessage({ id: 'reporting.respond' })}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                  {i !== productMappingRequests.length - 1 && <Divider />}
                </React.Fragment>
              )
            })}
          </Collapse>
          {productMappingRequests.length > 4 && (
            <Box display="flex" justifyContent="center">
              <Button
                endIcon={mappingRequestsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => setMappingRequestsExpanded(prev => !prev)}
              >
                {mappingRequestsExpanded
                  ? formatMessage({ id: 'general.showLess' })
                  : formatMessage({ id: 'general.showMore' })}
              </Button>
            </Box>
          )}
        </>
      )}

      {inquiriesByTemplate.length > 0 && (
        <>
          <Box my={2}>
            <Typography variant="subtitle1">{formatMessage({ id: 'resourceTypes.questionnaireRequest' })}</Typography>
          </Box>
          <Collapse
            in={inquiriesExpanded}
            collapsedSize={inquiriesByTemplate.length > 4 ? 270 : 68 * inquiriesByTemplate.length}
          >
            {inquiriesByTemplate.map((inquiryByTemplate, i) => {
              const inquiryInfos = distinctBy(inquiryByTemplate?.inquiryInfos ?? [], inq => inq.accessorOrganizationId)
              const onClick = () =>
                handleInquiryClick(
                  inquiryByTemplate.templateId,
                  inquiryByTemplate.periodStartsAt,
                  inquiryByTemplate.periodEndsAt,
                  inquiryInfos[0].inquiryId,
                  inquiryByTemplate.requestId,
                )
              return (
                <React.Fragment
                  key={[
                    inquiryByTemplate.templateId,
                    inquiryByTemplate.periodStartsAt,
                    inquiryByTemplate.periodEndsAt,
                  ].join(',')}
                >
                  <Box
                    sx={{
                      '&:hover': {
                        backgroundColor: palette.grey[100],
                      },
                    }}
                    px={1}
                    py={2}
                    borderRadius="4px"
                  >
                    <Grid container>
                      <Grid item xs={3} sx={classes.cell}>
                        <Box width="100%" pr={1}>
                          <RequestCell
                            title={`${inquiryByTemplate.templateTitle} - ${getPeriodDisplayName(
                              inquiryByTemplate.periodStartsAt,
                              inquiryByTemplate.periodEndsAt,
                            )}`}
                            onClick={onClick}
                            imageUrl={inquiryByTemplate.templateImageUrl}
                            disableCell
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sx={classes.cell}>
                        {inquiryInfos?.slice(0, maxEntriesToShow)?.map(inquiryInfo => (
                          <Box mr={2} key={inquiryInfo.inquiryId} display="flex" alignItems="center">
                            <CompanyAvatar
                              organizationName={inquiryInfo.accessorName}
                              imageUrl={inquiryInfo.accessorImageUrl}
                              size={AvatarSize.Small}
                            />
                            <Typography ml={1} variant="body1">
                              {inquiryInfo.accessorName}
                            </Typography>
                          </Box>
                        ))}
                        {inquiryInfos?.length > maxEntriesToShow && (
                          <Typography variant="body1" color="textSecondary">
                            {formatMessage(
                              { id: 'general.andAdditional' },
                              { amount: inquiryInfos?.length - maxEntriesToShow },
                            )}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={2} sx={classes.cell}>
                        {inquiryByTemplate.closestDeadline && <DeadlineChip date={inquiryByTemplate.closestDeadline} />}
                      </Grid>
                      <Grid item xs={1} sx={{ ...classes.cell, ...classes.actionCell }}>
                        <LoadingButton loading={isCreateReportLoading} variant="contained" onClick={onClick}>
                          {formatMessage({ id: 'reporting.respond' })}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                  {i !== inquiriesByTemplate.length - 1 && <Divider />}
                </React.Fragment>
              )
            })}
          </Collapse>
          {inquiriesByTemplate.length > 4 && (
            <Box display="flex" justifyContent="center">
              <Button
                endIcon={inquiriesExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => setInquiriesExpanded(prev => !prev)}
              >
                {inquiriesExpanded
                  ? formatMessage({ id: 'general.showLess' })
                  : formatMessage({ id: 'general.showMore' })}
              </Button>
            </Box>
          )}
        </>
      )}
      {!inquiriesByTemplate.length && !productMappingRequests?.length && (
        <Stack bgcolor="background.paper" justifyContent="center" height="100%">
          <EmptyState
            title={formatMessage({ id: 'transparencyOverview.toDoEmptyStateHeader' })}
            description={formatMessage({ id: 'transparencyOverview.toDoEmptyStateBody' })}
            iconComponent={PlaylistAddIcon}
          >
            <LinkButton to={paths.sustainabilityProfile}>
              {formatMessage({ id: 'transparencyOverview.visitSusProfile' })}
            </LinkButton>
          </EmptyState>
        </Stack>
      )}
    </Stack>
  )
}

export default PendingRequests
