import { Drawer } from '@mui/material'
import React from 'react'
import { Category } from '@app/src/types/categories'
import CategoryDrawerContent, { CategoryDrawerViews } from './CategoryDrawerContent'

export interface CategoryDrawerProps {
  category?: Category
  categories?: Category[]
  isDrawerOpen: boolean
  closeDrawer: () => void
  view: CategoryDrawerViews
}

const CategoryDrawer = ({
  category,
  isDrawerOpen,
  closeDrawer,
  view,
  categories,
}: CategoryDrawerProps): JSX.Element => {
  return (
    <Drawer open={isDrawerOpen} onClose={closeDrawer} anchor="right" data-testid="settings-categories-edit">
      <CategoryDrawerContent
        category={category}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        view={view}
        categories={categories}
      />
    </Drawer>
  )
}

export default CategoryDrawer
