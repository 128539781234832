import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const RequestTemplateHeader: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'schemas.contact.name' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.contact.position' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.contact.role' })} minWidth={TableCellWidth.SMALL} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.contact.email' })} />
      <TableHeaderCell label="" minWidth={TableCellWidth.EMPTY} />
    </TableRow>
  )
}

export default RequestTemplateHeader
