import { Skeleton, Stack } from '@mui/material'
import React from 'react'
const ConfigurationSkeleton: React.FC = () => (
  <Stack width="100%">
    <Skeleton width="100%" height={50} sx={{ my: 1, mt: 2 }} />

    <Stack spacing={2} mt={8} ml={4}>
      {Array.from({ length: 8 }).map((_, i) => (
        <Skeleton key={i} width="50%" height={32} variant="rounded" />
      ))}
    </Stack>
  </Stack>
)

export default ConfigurationSkeleton
