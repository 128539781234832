import { TableRow } from '@mui/material'
import EmailCell from '@app/src/components/Table/Cells/EmailCell'
import UserCell from '@app/src/components/Table/Cells/UserCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { User } from '@app/src/types/resourceExplorer'

interface ProviderPublicContactRowProps {
  row: User
}

export const ProviderPublicContactRow: React.FC<ProviderPublicContactRowProps> = ({ row: publicContact }) => {
  return (
    <TableRow>
      <UserCell user={publicContact} drawer />
      <ValueCell value={publicContact.position} maxRows={1} />
      <ValueCell value={publicContact.phoneNumber} />
      <EmailCell mail={publicContact.email} />
    </TableRow>
  )
}

export default ProviderPublicContactRow
