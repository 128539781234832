import { AnswerWithFlagCellProps, QuestionItemAnswer } from '@app/src/components/Table/Cells/AnswerWithFlagCell'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { getSxByRank } from '@app/src/pages/QuestionEditor/AnswerClassification/ClassificationRow'
import { QuestionAnswerRank } from '@app/src/types/resourceExplorer'
import { Check, Close, Remove, ReportGmailerrorred } from '@mui/icons-material'
import { AvatarGroup, Stack, SvgIcon, Tooltip, alpha, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'

type AnswerWithClassificationCellProps = {} & AnswerWithFlagCellProps

const rankIconTypes = {
  [QuestionAnswerRank.NotSet]: Remove,
  [QuestionAnswerRank.Neutral]: Remove,
  [QuestionAnswerRank.Positive]: Check,
  [QuestionAnswerRank.Negative]: Close,
  [QuestionAnswerRank.NegativeAdverse]: ReportGmailerrorred,
}

const RANK_TYPE_ORDER = [
  QuestionAnswerRank.Positive,
  QuestionAnswerRank.NegativeAdverse,
  QuestionAnswerRank.Negative,
  QuestionAnswerRank.Neutral,
  QuestionAnswerRank.NotSet,
]

const AnswerWithClassificationCell: React.FC<AnswerWithClassificationCellProps> = ({
  item,
  maxRows,
  responderName,
  disableCell,
}) => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const ranks = item.questionAnswerClassificationResults?.map(q => q.rank) ?? []

  const tooltipText = useMemo(
    () =>
      ranks
        ?.sort((a, b) => RANK_TYPE_ORDER.indexOf(a) - RANK_TYPE_ORDER.indexOf(b))
        .map(x =>
          formatMessage({
            id: `templateBuilder.answerClassifications.classificationsRanks.${x}`,
          }),
        )
        .join(', '),
    [ranks],
  )

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Stack direction="row" alignItems="center" gap={1}>
        {Boolean(ranks?.length) && (
          <Tooltip arrow title={tooltipText}>
            <AvatarGroup
              max={4}
              sx={{
                marginLeft: '-2px',
                '& .MuiAvatar-root': {
                  marginLeft: '-16px',
                },
              }}
            >
              {ranks?.map(rank => (
                <Avatar key={rank} size={AvatarSize.Small} sx={{ bgcolor: getSxByRank(palette, rank).bgcolor }}>
                  <SvgIcon
                    fontSize="small"
                    component={rankIconTypes[rank]}
                    sx={{
                      color:
                        rank === QuestionAnswerRank.Neutral || rank === QuestionAnswerRank.Negative
                          ? alpha(palette.common.black, 0.6)
                          : palette.common.white,
                    }}
                  />
                </Avatar>
              ))}
            </AvatarGroup>
          </Tooltip>
        )}
        <QuestionItemAnswer item={item} maxRows={maxRows} responderName={responderName} />
      </Stack>
    </ParentCellOrFragment>
  )
}

export default AnswerWithClassificationCell
