import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import UpdateProviderCategoryDrawer from '@app/src/components/ManageProviderDrawer/CategoryDrawer/UpdateProviderCategoryDrawer'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { BaseAssessmentsExportColumnsAccessor } from '@app/src/export-columns/baseAssessments'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import BaseAssessmentFilters from '@app/src/pages/ResourceCollection/Filters/BaseAssessmentFilters'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import Assessment from '@app/src/types/assessment'
import { ProviderStandardCategoryNames } from '@app/src/types/categories'
import { Provider } from '@app/src/types/organizations'
import { AssessmentType } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, ResourceTypes } from '@app/src/wf-constants'
import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import AssessmentHeader from './AssessmentHeader'
import AssessmentRow from './AssessmentRow'

type AssessmentDetailsSceneProps = {
  allowedFilters: string[]
  userFilters: FilterGroup[]
} & Pick<ResourceCollectionSceneProps, 'actionButtons' | 'tabs'>

const AssessmentDetailsScene: React.FC<AssessmentDetailsSceneProps> = ({
  allowedFilters,
  userFilters,
  actionButtons,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const { trackEvent } = useAmplitude()
  const { openDrawer } = useDrawer()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const [selectedProviders, setSelectedProviders] = useState<Array<number>>([])
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(false)
  const [isUpdatePriorityDrawerOpen, openUpdatePriorityDrawer, closeUpdatePriorityDrawer] = useDrawer_DEPRECATED(false)
  const { assessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentType: AssessmentType.BaselineAssessment,
  })
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)

  const basePayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [{ value: assessmentQuestionnaireLink?.assessmentTemplateId, operator: Operators.EqualTo }],
      },
    ],
    sort: sorting,
    include: ['provider.categoryOptions', 'provider.organization.country.risks.riskType'],
  }

  const filterPayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [{ value: assessmentQuestionnaireLink?.assessmentTemplateId, operator: Operators.EqualTo }],
      },
    ],
    sort: sorting,
    include: ['provider.categoryOptions', 'provider.country.risks.riskType', 'provider.organization.contacts.user'],
  }

  const payload = {
    ...basePayload,
    include: [...basePayload.include],
    filter: [...basePayload.filter, ...userFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: assessments,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload,
    options: { enabled: canAccessAssessmentTemplate && Boolean(assessmentQuestionnaireLink?.assessmentTemplateId) },
  })

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => {
    if (e.target.checked) {
      setSelectedProviders(prev => [...prev, provider.id])
    } else {
      setSelectedProviders(prev => prev.filter(providerId => providerId !== provider.id))
    }
  }

  return (
    <ResourceCollectionScene
      enableScroll
      title={formatMessage({ id: 'navbar.baseAssessment' })}
      filter={
        <>
          <BaseAssessmentFilters allowedFilters={allowedFilters} />
          {Boolean(selectedProviders?.length) && (
            <Box display="flex" alignItems="center" mt={2} gap={1}>
              <Typography variant="body2" color="textSecondary">
                {formatMessage({ id: 'baseAssessment.companiesCount' }, { count: selectedProviders.length })}
              </Typography>
              <Button variant="outlined" onClick={openUpdateDrawer} disabled={isLoading || isFetching} size="small">
                {formatMessage({ id: 'baseAssessment.categorize' }, { count: selectedProviders.length })}
              </Button>
              <Button
                variant="outlined"
                onClick={openUpdatePriorityDrawer}
                disabled={isLoading || isFetching}
                size="small"
              >
                {formatMessage({ id: 'resourceCollections.general.priority' }, { count: selectedProviders.length })}
              </Button>
            </Box>
          )}
        </>
      }
      actionButtons={[
        ...(actionButtons ?? []),
        {
          label: formatMessage({ id: 'resourceCollections.general.export' }),
          variant: 'outlined',
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Analyze.Assessment.ExportedExcel,
            })

            openDrawer(
              <DrawerViewExport
                resourceType={ResourceTypes.Assessment}
                count={count}
                userFilter={userFilters}
                exportColumns={BaseAssessmentsExportColumnsAccessor}
                rawExportPayload={filterPayload}
                selectedIds={selectedProviders}
              />,
            )
          },
          disabled: isLoading || count === 0,
        },
      ]}
      {...props}
    >
      <>
        <Table<Assessment>
          RowComponent={({ row }): JSX.Element => (
            <AssessmentRow row={row} onCheckboxChange={handleCheckboxChange} selectedProviders={selectedProviders} />
          )}
          HeaderComponent={() => (
            <AssessmentHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              selectedProviders={selectedProviders}
              setSelectedProviders={setSelectedProviders}
              providersInPage={assessments.flatMap(assessment => assessment.provider)}
            />
          )}
          data={assessments ?? []}
          count={count}
          isLoading={isLoading || isFetching}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={2}
        />

        <UpdateProvidersDrawer
          selectedProviders={assessments
            .flatMap(assessment => assessment.provider)
            .filter(provider => selectedProviders.includes(provider.id))
            .map(provider => ({
              ...provider,
              providerId: provider.id,
              categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
            }))}
          setSelectedProviders={setSelectedProviders}
          isDrawerOpen={isUpdateDrawerOpen}
          closeDrawer={closeUpdateDrawer}
        />
        <UpdateProviderCategoryDrawer
          categoryName={{ name: ProviderStandardCategoryNames.Priority }}
          selectedProviders={assessments
            .flatMap(assessment => assessment.provider)
            .filter(provider => selectedProviders.includes(provider.id))
            .map(provider => ({
              ...provider,
              providerId: provider.id,
              categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
            }))}
          setSelectedProviders={setSelectedProviders}
          isDrawerOpen={isUpdatePriorityDrawerOpen}
          closeDrawer={closeUpdatePriorityDrawer}
        />
      </>
    </ResourceCollectionScene>
  )
}

export default AssessmentDetailsScene
