import { SvgIcon } from '@mui/material'
import React from 'react'

export const WorldfavorDeforestation = () => {
  return (
    <SvgIcon fontSize="large">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <g clipPath="url(#clip0_2753_1280)">
          <path d="M0 0.193359V24.1934H24V0.193359H0Z" fill="black" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.576 8.40154C15.96 8.04154 16.464 7.87354 17.136 7.87354H18.168V8.92954C18.168 8.92954 18.024 8.92954 17.304 8.92954C16.968 8.92954 16.704 9.00153 16.536 9.19353C16.368 9.36153 16.272 9.60153 16.272 9.93753V10.4895H17.712V11.5695H16.272V16.7295H15V11.5455H14.088L12.504 16.6815H10.992C10.992 16.6815 10.224 13.8735 10.128 13.5135H10.104C10.032 13.8735 9.16801 16.6815 9.16801 16.6815H7.68001L5.76001 10.4655H7.12801C7.12801 10.4655 8.40001 15.2655 8.47201 15.5295H8.49601C8.56801 15.3375 9.19201 13.2975 9.48001 12.4095C9.55201 12.1455 9.57601 11.8575 9.48001 11.5935L9.19201 10.5855H10.44L10.464 10.6815L11.328 13.9455C11.4 14.2095 11.688 15.3855 11.712 15.5295H11.736C11.76 15.3855 13.104 10.4655 13.104 10.4655H15.024V9.86553C15.024 9.24153 15.216 8.73754 15.576 8.40154Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2753_1280">
            <rect width="24" height="24" fill="white" transform="translate(0 0.193359)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default WorldfavorDeforestation
