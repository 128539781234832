import { Button, darken, lighten, Paper, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView from '@app/src/components/Drawer/DrawerView'
import GradientOverlay from '@app/src/components/GradientOverlay'
import Image from '@app/src/components/Image'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { Invitation } from '@app/src/types/resourceExplorer'
import { DefaultImages, NotificationSeverity } from '@app/src/wf-constants'

interface Props {
  backgroundImage?: string
  invitation: Invitation
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  organizationContainer: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(12),
  },
  contentSection: {
    flexGrow: 1,
    borderRadius: 20,
    marginTop: -75, //TODO: Remove negative margin?
  },
  orgName: {
    textAlign: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  invitationSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    minHeight: 511, //TODO: Looks like a very specific minHeight
  },
  invitationContainer: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(3),
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.background.paper, 0.4)
        : darken(theme.palette.background.paper, 0.4),
  },
  gradientOverlay: {
    borderRadius: 20,
  },
  invitationTitle: {
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  invitationWrongPerson: {
    '& > button': {
      color: theme.palette.grey[600],
      textDecoration: 'underline',
    },
    color: theme.palette.grey[600],
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
}))

const InvitationCard: React.FC<Props> = ({ backgroundImage, invitation }) => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { openDrawer, closeDrawer } = useDrawer()
  const { creatorOrganization, role, solutionType: solution } = invitation
  const classes = useStyles()
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useUpdateResource<Invitation>()

  const handleInvitation = async (status: 'Accepted' | 'Rejected'): Promise<void> => {
    mutate(
      {
        url: `${endpoints.invitations}/save`,
        body: {
          ...invitation,
          status,
        },
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: `notifications.${status}Invitation` }),
            severity: NotificationSeverity.success,
          })
          queryClient.invalidateQueries(FetchKey.Invitation)
          queryClient.invalidateQueries(FetchKey.AccessibleOrganizations)
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSettled: () => {
          closeDrawer()
        },
      },
    )
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Image
          src={backgroundImage}
          style={{ borderRadius: 20, display: 'flex', flexDirection: 'column', height: '100%' }}
          position="center top"
          overlay={<GradientOverlay className={classes.gradientOverlay} />}
        >
          <div className={classes.organizationContainer}>
            <Image
              style={{
                borderRadius: 5,
                height: 55,
                width: 55,
                minWidth: 55,
                border: '4px solid white',
                marginBottom: theme.spacing(2),
                backgroundColor: theme.palette.background.default,
              }}
              size="contain"
              src={creatorOrganization.image?.url}
              placeholder={DefaultImages.Organization}
            />
            <Typography variant="h5" className={classes.orgName}>
              {creatorOrganization.name}
            </Typography>
          </div>
          <Paper className={classes.contentSection}>
            <div className={classes.invitationSection}>
              <Paper className={classes.invitationContainer}>
                <div className={classes.invitationTitle}>
                  {formatMessage(
                    { id: 'schemas.invitation.card.title' },
                    {
                      solution: formatMessage({ id: `solutions.${solution.toLowerCase()}` }),
                      role,
                      organizationName: creatorOrganization.name,
                    },
                  )}
                </div>
                <div className={classes.buttonsContainer}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() =>
                      openDrawer(
                        <DrawerView
                          title={formatMessage({ id: 'schemas.invitation.drawer.accept' })}
                          buttons={[
                            {
                              label: formatMessage({ id: 'schemas.invitation.card.accept' }),
                              variant: 'contained',
                              onClick: () => handleInvitation('Accepted'),
                              loading: isLoading,
                            },
                          ]}
                        >
                          <Typography variant="body1" px={2}>
                            {formatMessage({ id: 'schemas.invitation.drawer.acceptDescription' })}
                          </Typography>
                        </DrawerView>,
                      )
                    }
                  >
                    {formatMessage({ id: 'schemas.invitation.card.accept' })}
                  </Button>
                </div>
                <div className={classes.invitationWrongPerson}>
                  {formatMessage({
                    id: 'schemas.invitation.card.wrongPerson',
                  })}
                  <br />
                  {formatMessage({
                    id: 'schemas.invitation.card.contactColleagueOrWorldfavor',
                  })}
                  <br />
                  <Button
                    className={classes.button}
                    onClick={() =>
                      openDrawer(
                        <DrawerView
                          title={formatMessage({ id: 'schemas.invitation.drawer.reject' })}
                          buttons={[
                            {
                              label: formatMessage({ id: 'schemas.invitation.card.reject' }),
                              variant: 'contained',
                              onClick: () => handleInvitation('Rejected'),
                              loading: isLoading,
                            },
                          ]}
                        >
                          <Typography variant="body1" px={2}>
                            {formatMessage({ id: 'schemas.invitation.drawer.rejectDescription' })}
                          </Typography>
                        </DrawerView>,
                      )
                    }
                  >
                    {formatMessage({ id: 'schemas.invitation.card.reject' })}
                  </Button>
                </div>
              </Paper>
            </div>
          </Paper>
        </Image>
      </Paper>
    </>
  )
}

export default InvitationCard
