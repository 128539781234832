import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import DatePicker from '@app/src/components/Form/DatePicker'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Box, Button, Chip, Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import AllFiltersButton from './AllFiltersButton'
import RangeFilter from './RangeFilter'
import StatusOption from './StatusOption'
import TextFieldFilter from './TextFieldFilter'

const ProviderResponsesFilters = ({ allowedFilters, resetPage, implicitFilters }: FiltersProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <Grid container spacing={1}>
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item md={4} sm={10}>
                <Filter name="request.template.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.responsesWithFacets,
                        facetsParam: [{ name: 'request.template.title' }],
                      }}
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'schemas.filter.requestName' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={3}>
                <Filter name="request.subscriptions.creatorOrganization.name" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.responsesWithFacets,
                        facetsParam: [{ name: 'request.subscriptions.creatorOrganization.name', isEnum: true }],
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.filter.companyName' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={2}>
                <Filter name="draftStatus" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.RequestFacets, filterName],
                        endpoint: endpoints.responsesWithFacets,
                        facetsParam: [{ name: 'draftStatus' }],
                      }}
                      renderOption={(props, option, { selected }): JSX.Element => (
                        <StatusOption
                          key={option.label}
                          props={props}
                          option={option}
                          selected={selected}
                          verification
                        />
                      )}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.response.responseStatus' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={3}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.responses' })}>
                              <Filter name="request.template.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.responsesWithFacets,
                                      facetsParam: [{ name: 'request.template.title' }],
                                    }}
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.requestName' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="request.subscriptions.creatorOrganization.name" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.responsesWithFacets,
                                      facetsParam: [
                                        { name: 'request.subscriptions.creatorOrganization.name', isEnum: true },
                                      ],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.companyName' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="draftStatus" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.responsesWithFacets,
                                      facetsParam: [{ name: 'draftStatus' }],
                                    }}
                                    renderOption={(props, option, { selected }): JSX.Element => (
                                      <StatusOption
                                        key={option.label}
                                        props={props}
                                        option={option}
                                        selected={selected}
                                        verification
                                      />
                                    )}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.response.responseStatus' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>

                            <FilterSection label={formatMessage({ id: 'schemas.filter.responseVerification' })}>
                              <Filter name="externalVerificationStatus" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.responsesWithFacets,
                                      facetsParam: [{ name: filterName, isEnum: true }],
                                    }}
                                    renderOption={(props, option, { selected }): JSX.Element => (
                                      <StatusOption
                                        key={option.label}
                                        props={props}
                                        option={option}
                                        selected={selected}
                                        verification
                                      />
                                    )}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({
                                      id: 'filters.responseItem.externalVerificationStatus',
                                    })}
                                    value={[value].flat()}
                                    skipNotSet={false}
                                  />
                                )}
                              </Filter>
                              <Filter name="externalVerificationComment" operator={Operators.Contains}>
                                {({ filterName, operator, value }) => (
                                  <TextFieldFilter
                                    defaultValue={value}
                                    value={value}
                                    filterName={filterName}
                                    operator={operator}
                                    size="small"
                                    fullWidth
                                    label={formatMessage({ id: 'schemas.response.externalVerificationComment' })}
                                  />
                                )}
                              </Filter>
                            </FilterSection>

                            <FilterSection label={formatMessage({ id: 'schemas.filter.responseTimeline' })}>
                              <RangeFilter
                                uniqueIdFrom="responseDateFrom"
                                uniqueIdTo="responseDateTo"
                                name="submittedAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.response.responseDateFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.response.responseDateTo' })}
                              />
                              <Filter name="request.subscriptions.dueAt" operator={Operators.EqualTo}>
                                {({ value, onChange }) => (
                                  <DatePicker
                                    value={[value].flat()?.[0]}
                                    onChange={onChange}
                                    label={formatMessage({ id: 'schemas.filter.dueAt' })}
                                    slotProps={{ textField: { size: 'small' } }}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
              <Filter name="request.creatorUserId" operator={Operators.NotEqualTo}>
                {({ value, onChange }) => (
                  <>
                    {value && (
                      <Grid item xs={12}>
                        <Chip
                          label={formatMessage({ id: 'reporting.statuses.Requested' })}
                          onDelete={() => onChange(null)}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Filter>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default ProviderResponsesFilters
