import React, { createContext, useContext } from 'react'
import { Ownership } from './CompaniesStatisticsFilterContextProvider'

export interface CompaniesStatisticsFilterProps {
  selectedOwnership: Ownership
  setSelectedOwnership: React.Dispatch<React.SetStateAction<Ownership>>
}

const CompaniesStatisticsFilterContext = createContext<undefined | CompaniesStatisticsFilterProps>(undefined)

export function useCompaniesStatisticsFilter(): CompaniesStatisticsFilterProps {
  const context = useContext(CompaniesStatisticsFilterContext)
  if (context === undefined) {
    throw new Error('useCompaniesStatisticsFilter must be used within CompaniesStatisticsFilterContextProvider')
  }
  return context
}

export default CompaniesStatisticsFilterContext
