import Permissions, { usePermissions } from '@app/src/auth/permissions'
import type { NavbarItemProps } from '@app/src/components/Navbar/NavbarItems'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { insertIf } from '@app/src/utils/helpersTs'
import navbarLinks from '@app/src/wf-constants/navbarLinks'
import MenuWrapper, { MenuWrapperProps } from './MenuWrapper'

const ProductsMenu: React.FC<NavbarItemProps> = ({ closeMenuDrawer }) => {
  const { formatMessage } = useIntl()
  const { productMapping } = useFlags()
  const { hasPermission } = usePermissions()
  const hasFullProductMappingAccess = hasPermission(Permissions.FULL_DASHBOARD_ACCESS)
  const isSourcingUser = hasPermission(Permissions.SOURCING_USER)

  if (!productMapping || !isSourcingUser) return null

  const menuItems: MenuWrapperProps['menuItems'] = [
    {
      title: formatMessage({ id: 'navbar.allProducts' }),
      link: navbarLinks.products,
      permission: [Permissions.SOURCING_USER],
    },
    {
      title: formatMessage({ id: 'navbar.valueChains' }),
      link: navbarLinks.valueChains,
      permission: [Permissions.SOURCING_USER],
    },
    ...insertIf(hasFullProductMappingAccess, {
      title: formatMessage({ id: 'navbar.sentMappingRequests' }),
      link: navbarLinks.sentMappingRequests,
      permission: [Permissions.FULL_DASHBOARD_ACCESS],
    }),
    ...insertIf(hasFullProductMappingAccess, {
      title: formatMessage({ id: 'navbar.allMappingResponses' }),
      link: navbarLinks.receivedMappingResponses,
      permission: [Permissions.FULL_DASHBOARD_ACCESS],
    }),
  ]

  return (
    <MenuWrapper
      closeMenuDrawer={closeMenuDrawer}
      menuItems={menuItems}
      title={formatMessage({ id: 'navbar.productMapping' })}
    />
  )
}

export default ProductsMenu
