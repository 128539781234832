import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, capitalize } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { Solution, useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import useLogin from '@app/src/hooks/login'
import { useUnsetReferral } from '@app/src/hooks/referral'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { ReferralCode, SimpleOrganization } from '@app/src/types/organizations'
import { ReferralLink } from '@app/src/types/resourceExplorer'
import { getProviderTypeFromSolution } from '@app/src/utils/solution'
import { AmplitudeTrackingEvents, Solution as SolutionEnum } from '@app/src/wf-constants'
import OnboardingForm from '../OnboardingForm'
import CompanyCard from '../OnboardingForm/CompanyCard'
import NoReferralEmptyState from './NoReferralEmptyState'

interface AcceptReferralWithExistingOrgProps {
  referralCode: string
  closeDialog: () => void
  openClaimDialog: () => void
  onBack: () => void
  onReferralExpired: () => void
}

const AcceptReferralWithExistingOrg: React.FC<AcceptReferralWithExistingOrgProps> = ({
  referralCode,
  closeDialog,
  openClaimDialog,
  onBack,
  onReferralExpired,
}) => {
  const { accessibleOrganizations, isLoading } = useAccessibleOrganizations()
  const { showErrorNotification } = useErrorNotification()
  const logIn = useLogin()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const unsetReferral = useUnsetReferral()
  const { trackEvent } = useAmplitude()

  const { data, error } = useFetchResource<ReferralCode>({
    endpoint: endpoints.referralCode(referralCode),
    key: [FetchKey.ReferralCode, referralCode],
    options: { retry: false, staleTime: Infinity, enabled: Boolean(referralCode) },
    showSnackbarOn404: false,
  })

  const { mutateAsync: mutateLinkWithReferral, isLoading: isMutateLinkWithReferralLoading } =
    useCreateResource<ReferralLink>()

  const providerTypeName = formatMessage(
    { id: `general.${capitalize(getProviderTypeFromSolution(data?.solutionType))}` },
    { count: 1 },
  ).toLowerCase()
  const accessorName = data?.creatorOrganizationName

  const linkWithReferral = async (selectedOrganization: { organization: SimpleOrganization; solution: Solution[] }) => {
    if (!referralCode) return

    const transparencySolution = selectedOrganization.solution.find(
      s => s.solution.toLowerCase() === SolutionEnum.TRANSPARENCY.toLowerCase(),
    )

    await mutateLinkWithReferral(
      {
        url: endpoints.linkWithReferrer,
        body: { code: referralCode, organizationId: selectedOrganization.organization.id },
      },
      {
        onSuccess: () => {
          unsetReferral()
          trackEvent({
            name: AmplitudeTrackingEvents.Onboarding.AddOrganization.InvitedCompany,
            eventProps: {
              org_id: selectedOrganization.organization.id,
              invited_by_id: data?.creatorOrganizationId,
              invited_by_name: data?.creatorOrganizationName,
            },
          })
          if (transparencySolution) {
            queryClient.clear()
            logIn(transparencySolution.role, transparencySolution.solution, selectedOrganization.organization.id)
            const message = formatMessage(
              { id: 'referral.acceptReferralDialogSuccess' },
              { accessorName, providerType: providerTypeName },
            )
            showSnackbar({ message, severity: 'success' })
            return
          }
          closeDialog()
        },
        onError: error => {
          if (error.statusCode === 409) {
            unsetReferral()
            if (transparencySolution) {
              queryClient.clear()
              logIn(transparencySolution.role, transparencySolution.solution, selectedOrganization.organization.id)
              showSnackbar({
                message: formatMessage({ id: 'referral.acceptReferralDialog409' }),
                severity: 'info',
              })
              return
            }
          } else {
            showErrorNotification({ requestError: error })
          }
        },
      },
    )
  }

  if (error?.statusCode === 404) return <NoReferralEmptyState onReferralExpired={onReferralExpired} />

  return (
    <OnboardingForm
      header={formatMessage({ id: 'referral.acceptWithExistingOrg.header' })}
      subheader={formatMessage({ id: 'referral.acceptWithExistingOrg.subHeader' })}
    >
      <Stack flexGrow={1} spacing={3}>
        <Stack spacing={2}>
          {accessibleOrganizations
            ?.filter(accessOrgs => Boolean(accessOrgs?.organization))
            .map(({ organization, solution }) => (
              <CompanyCard
                key={organization?.id}
                avatarAlt={organization?.name[0]}
                mainText={organization?.name}
                secondaryText={organization?.country?.name}
                isLoading={isLoading}
                action={
                  <LoadingButton
                    loading={isMutateLinkWithReferralLoading}
                    onClick={() => {
                      linkWithReferral({ organization, solution })
                    }}
                    variant="contained"
                  >
                    {formatMessage({ id: 'general.select' })}
                  </LoadingButton>
                }
              />
            ))}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button variant="text" onClick={onBack} startIcon={<ArrowBackIcon />}>
            {formatMessage({ id: 'general.back' })}
          </Button>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              openClaimDialog()
              closeDialog()
            }}
            data-testid="create-new-org-on-invite"
          >
            {formatMessage({ id: 'general.createNewOrg' })}
          </Button>
        </Stack>
      </Stack>
    </OnboardingForm>
  )
}

export default AcceptReferralWithExistingOrg
