import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCount } from '@app/src/api/fetchHooks'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AssessmentTemplate, QuestionnaireTemplate } from '@app/src/types/resourceExplorer'

export const useAssessmentQuestionnaireInquiriesCount = (
  assessmentTemplate: AssessmentTemplate | undefined,
  enabled = true,
) => {
  const { assessmentQuestionnaireLinks, isLoadingAssessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentTemplateId: assessmentTemplate?.id,
    enabled: Boolean(enabled && assessmentTemplate),
    includeAssessment: false,
    includeQuestionnaire: true,
  })
  const questionnaireTemplates = assessmentQuestionnaireLinks.map(
    x => x.questionnaireTemplate,
  ) as QuestionnaireTemplate[]
  const linkedQuestionnaireTemplateIds = questionnaireTemplates.map(x => x.id)

  const { count: inquiriesCount, isLoading: isLoadingInquiryCountEndpoint } = useFetchCount({
    key: FetchKey.InquiryCount,
    endpoint: endpoints.inquiryCount,
    payload: [
      {
        name: 'questionnaireTemplateId',
        filters: [
          {
            value: linkedQuestionnaireTemplateIds,
            operator: Operators.In,
          },
        ],
      },
    ],
    options: { enabled: enabled && Boolean(linkedQuestionnaireTemplateIds.length) },
  })

  return {
    inquiriesCount,
    isLoadingInquiriesCount: isLoadingAssessmentQuestionnaireLink || isLoadingInquiryCountEndpoint,
    questionnaireTemplates,
  }
}

export default useAssessmentQuestionnaireInquiriesCount
