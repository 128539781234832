import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewVerification from '@app/src/components/Drawer/Views/DrawerViewVerification'
import LoadingButton from '@app/src/components/LoadingButton'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import useAccessorQuestionnaire from '@app/src/pages/Questionnaire/hooks/useAccessorQuestionnaire'
import { ResponseDraftStatus, ResponseItem, Verification } from '@app/src/types/resourceExplorer'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'

export interface VerificationButtonProps {
  item?: ResponseItem
}

const VerificationButton = ({ item }: VerificationButtonProps) => {
  const { response, request, latestHistory } = useReport()
  const { hasPermission } = usePermissions()
  const { createVerification, createVerificationResponseItem, isLoadingCreateVerification } = useAccessorQuestionnaire()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { stpResponseResponseItemVerificationFilterLogic } = useFlags()

  const isPrimarySubscriber = response?.request?.isPrimarySubscriber || request?.isPrimarySubscriber
  const isLatestResponse = latestHistory?.responseId === response?.id

  const isReviewEnabled =
    (!stpResponseResponseItemVerificationFilterLogic || isPrimarySubscriber) &&
    hasPermission(Permissions.WRITE_VERIFICATION) &&
    isLatestResponse &&
    response?.draftStatus === ResponseDraftStatus.Submitted

  const handleSubmitVerification = async (verification: Partial<Verification>) => {
    if (item) {
      await createVerificationResponseItem(verification)
    } else {
      await createVerification(verification)
    }
  }

  if (!isReviewEnabled) {
    return null
  }

  return (
    <LoadingButton
      fullWidth
      variant="outlined"
      onClick={() =>
        openDrawer(
          <DrawerViewVerification
            subTitle={response.creatorOrganization.name}
            response={response}
            item={item}
            onSubmit={handleSubmitVerification}
          />,
        )
      }
      loading={isLoadingCreateVerification}
    >
      {formatMessage({ id: item ? 'questionnaire.actions.verifyItem' : 'questionnaire.actions.verify' })}
    </LoadingButton>
  )
}

export default VerificationButton
