import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewUser from '@app/src/components/Drawer/Views/DrawerViewUser'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { User } from '@app/src/types/resourceExplorer'
import { Typography } from '@mui/material'
import React from 'react'
import ChipCell, { ChipCellProps } from './ChipCell'
import ParentCellOrFragment from './ParentCellOrFragment'

type UserChipCellProps = {
  user?: User
  drawer?: boolean
  disableCell?: boolean
} & ChipCellProps

const UserChipCell: React.FC<UserChipCellProps> = ({ user, drawer, disableCell, ...props }) => {
  const { openDrawer } = useDrawer()

  if (!user) {
    return (
      <ParentCellOrFragment disableCell={disableCell}>
        <Typography variant="body1">-</Typography>
      </ParentCellOrFragment>
    )
  }

  return (
    <ChipCell
      disableCell={disableCell}
      icon={<PersonAvatar name={user.name} size={AvatarSize.XS} />}
      value={user.name}
      onClick={drawer ? () => openDrawer(<DrawerViewUser user={user} />) : undefined}
      size="small"
      sx={({ typography }) => ({ '& .MuiChip-icon': { fontSize: typography.caption.fontSize, color: 'common.white' } })}
      {...props}
    />
  )
}

export default UserChipCell
