import { Box, Button, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'

type ButtonSkeletonProps = {
  howMany?: number
}

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: theme.spacing(1),
  },
  spacing: {
    marginLeft: theme.spacing(1),
  },
}))

const ButtonSkeleton = () => {
  const classes = useStyles()

  return (
    <Box display="flex">
      <Skeleton width="100%" variant="rectangular" className={classes.button}>
        <Button variant="outlined">Loading</Button>
      </Skeleton>

      <Skeleton width="100%" variant="rectangular" className={classNames(classes.button, classes.spacing)}>
        <Button variant="outlined">Loading</Button>
      </Skeleton>
    </Box>
  )
}

const ButtonSkeletonWrapper = ({ howMany = 1 }: ButtonSkeletonProps): JSX.Element => {
  if (howMany > 1) {
    return (
      <>
        {Array.from({ length: howMany }, (_, idx) => (
          <ButtonSkeleton key={idx} />
        ))}
      </>
    )
  }

  return <ButtonSkeleton />
}

export default ButtonSkeletonWrapper
