import { ExpandMoreOutlined, LanguageOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  alpha,
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewLinkProviderCandidates from '@app/src/components/Drawer/Views/DrawerViewLinkProviderCandidates'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Provider, ProviderOrganizationResult } from '@app/src/types/organizations'

type ExistingProvidersAccordionProps = {
  providers: Array<Provider>
  selectedProviderIds: Array<number>
  onChange: (providerId: number) => void
} & Omit<AccordionProps, 'onChange' | 'children'>

const ExistingProvidersAccordion: React.FC<ExistingProvidersAccordionProps> = ({
  providers,
  selectedProviderIds,
  onChange,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const providerIds = useMemo(() => providers.map(provider => provider.id), [providers])

  const {
    data: existingOrganizations = [],
    isLoading: isLoadingExisting,
    remove: clearExistingOrganizationCache,
  } = useFetchResourceWithPost<Array<ProviderOrganizationResult>>({
    endpoint: endpoints.bulkFindOrganizations,
    key: FetchKey.ReferralContactExists,
    body: providerIds,
    options: {
      enabled: Boolean(providerIds.length),
    },
  })

  useEffect(() => {
    clearExistingOrganizationCache()
  }, [providers])

  if (!providerIds.length || (!isLoadingExisting && !existingOrganizations.length)) {
    return null
  }

  return (
    <Accordion
      elevation={0}
      disableGutters
      {...props}
      sx={[{ bgcolor: 'grey.100', '&:before': { position: 'unset' } }, ...[props.sx ?? []].flat()]}
    >
      <AccordionSummary
        expandIcon={
          isLoadingExisting ? (
            <CircularProgress size={16} sx={{ mx: 1.5 }} />
          ) : (
            <ExpandMoreOutlined fontSize="medium" sx={{ mx: 1 }} />
          )
        }
        sx={{ py: 1 }}
        disabled={isLoadingExisting}
      >
        <Stack direction="row">
          <LanguageOutlined color="secondary" />
          <Typography variant="body1" ml={3}>
            {isLoadingExisting
              ? formatMessage({ id: 'form.connectProviders.review.loadingExisting' })
              : formatMessage({ id: 'form.connectProviders.review.mayExist' }, { count: existingOrganizations.length })}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List disablePadding>
          {providers
            .filter(provider => existingOrganizations.some(existing => existing.providerId === provider.id))
            .map(provider => (
              <ListItem
                key={provider.id}
                sx={{
                  justifyContent: 'space-between',
                  borderTop: `1px solid ${alpha('#000', 0.12)}`,
                }}
                disablePadding
              >
                <ListItemButton
                  selected={selectedProviderIds.includes(provider.id)}
                  onClick={() => onChange(provider.id)}
                >
                  <Stack direction="row" alignItems="center" gap={1} width="60%">
                    <Checkbox
                      edge="start"
                      checked={selectedProviderIds.includes(provider.id)}
                      onChange={() => onChange(provider.id)}
                    />
                    <CompanyCell company={provider} disableCell />
                  </Stack>

                  <Stack direction="row" width="40%">
                    <Button
                      onClick={e => {
                        e.stopPropagation()
                        openDrawer(
                          <DrawerViewLinkProviderCandidates
                            resource={provider}
                            organizationCandidates={
                              existingOrganizations.find(item => item.providerId === provider.id)?.organizations
                            }
                            hideNewCompany
                          />,
                          { showAboveDialogs: true },
                        )
                      }}
                    >
                      {formatMessage({ id: 'form.connectProviders.review.viewMore' })}
                    </Button>
                  </Stack>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default ExistingProvidersAccordion
