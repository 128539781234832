import Assessment from '@app/src/types/assessment'
import { Provider } from '@app/src/types/organizations'
import { AssessmentType } from '@app/src/types/resourceExplorer'
import { useMemo } from 'react'
import _ from 'lodash'

export type AssessmentData = Omit<Assessment, 'provider' | 'periodName'>

type StructuredAssessmentEntry = {
  key: string
  value: AssessmentData | undefined
}

export type StructuredProviderAssessmentData = {
  provider: Provider
  assessments: StructuredAssessmentEntry[]
}

export const useOrganizedAssessmentData = (assessments: Assessment[]) => {
  const uniqueProviders = useMemo(
    () => _.uniqBy(assessments, 'provider.name').map(assessment => assessment.provider),
    [assessments],
  )

  const uniqueSortedAssessmentTemplateAndPeriod = useMemo(() => {
    return [
      ...new Set(
        assessments.map(
          assessment =>
            `${assessment.assessmentTemplate.name} • ${assessment.periodName} • ${assessment.assessmentTemplate.assessmentType}`,
        ),
      ),
    ]
      .sort((a, b) => {
        const [nameA, periodA, typeA] = a.split(' • ')
        const [nameB, periodB, typeB] = b.split(' • ')

        // Check if either is a baseline assessment
        if (typeA === AssessmentType.BaselineAssessment && typeB !== AssessmentType.BaselineAssessment) return -1
        if (typeB === AssessmentType.BaselineAssessment && typeA !== AssessmentType.BaselineAssessment) return 1

        // If both are baseline or both are not baseline, sort alphabetically
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1

        // If names are the same, sort periods in descending order
        return periodB.localeCompare(periodA)
      })
      .map(item => item.split(' • ').slice(0, 2).join(' • '))
  }, [assessments])

  const structuredData: StructuredProviderAssessmentData[] = useMemo(() => {
    // Create a map of providers with their assessments
    const providerMap = new Map<
      string,
      {
        provider: Provider
        assessments: Map<string, AssessmentData | undefined>
      }
    >()

    assessments.forEach(assessment => {
      const { provider, periodName, ...assessmentData } = assessment
      const key = `${assessment.assessmentTemplate.name} • ${periodName}`

      if (!providerMap.has(provider.name)) {
        providerMap.set(provider.name, {
          provider,
          assessments: new Map(uniqueSortedAssessmentTemplateAndPeriod.map(header => [header, undefined])),
        })
      }

      providerMap.get(provider.name)?.assessments.set(key, assessmentData)
    })

    return Array.from(providerMap.values()).map(({ provider, assessments }) => ({
      provider,
      assessments: Array.from(assessments.entries()).map(([key, value]) => ({ key, value })),
    }))
  }, [assessments, uniqueSortedAssessmentTemplateAndPeriod])

  return {
    uniqueProviders,
    uniqueSortedAssessmentTemplateAndPeriod,
    structuredData,
  }
}
