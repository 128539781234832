import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { ValueChainEmptyStateAlternative } from '@app/src/pages/Product/ProductRequestCard/ProductRequestCard'
import ValueChainView, { View } from '@app/src/pages/Product/ValueChainView'
import { MappingNode, Product, ProductMappingResponse } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

type DrawerViewReviewProductValueChainToCopyToNewResponseProps = {
  productToCopy: Product
  mappingRequestId: number
  onSuccess: () => void
} & Omit<DrawerViewProps, 'title'>

interface CopyValueChainFromProductRequestBody {
  sourceNodeId: MappingNode['id']
  targetNodeId: MappingNode['id']
  productId: Product['id']
}

const DrawerViewReviewProductValueChainToCopyToNewResponse: React.FC<
  DrawerViewReviewProductValueChainToCopyToNewResponseProps
> = ({ productToCopy, onSuccess, mappingRequestId, ...props }) => {
  const { formatMessage } = useIntl()
  const { options, setOptions } = useDrawer()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const { showErrorNotification } = useErrorNotification()
  const { mutate: mutateValueChainFromProduct, isLoading: isSavingValueChainFromProduct } =
    useCreateResource<CopyValueChainFromProductRequestBody>()

  const { mutate: createResponse, isLoading: isCreatingResponse } = useCreateResource<
    ProductMappingResponse,
    Partial<ProductMappingResponse>
  >({
    options: {
      onError: (error: RequestError) => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const reuseValueChainFromProduct = (targetProduct: Product) => {
    const sourceNodeId = (productToCopy?.mappingNodes ?? [])?.find(node => node.tier === 0)?.id
    const targetNodeId = targetProduct.mappingNodes?.find(node => node.tier === 0)?.id

    if (!sourceNodeId || !targetNodeId) return
    mutateValueChainFromProduct(
      {
        url: endpoints.copyValueChainFromNode,
        body: {
          sourceNodeId,
          targetNodeId,
          productId: targetProduct.id,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.Product)
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfullyCopiedValueChain' }),
            severity: NotificationSeverity.success,
          })
          onSuccess()
        },
      },
    )
  }

  const onReuseValueChainFromProduct = (targetProduct: Product) => {
    if (targetProduct.mappingNodes.length > 1) {
      confirm({
        confirmationText: formatMessage({ id: 'general.confirm' }),
        cancellationText: formatMessage({ id: 'general.cancel' }),
        title: formatMessage({ id: 'schemas.valueChain.replaceExistingValueChain.title' }),
        content: formatMessage({ id: 'schemas.valueChain.replaceExistingValueChain.description' }),
      }).then(() => reuseValueChainFromProduct(targetProduct))
      return
    }
    reuseValueChainFromProduct(targetProduct)
  }

  const createResponseProductAndReuseValueChain = () => {
    createResponse(
      {
        url: endpoints.productMappingResponse,
        body: { productMappingRequestId: mappingRequestId, productId: undefined },
      },
      {
        onSuccess: response => {
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          queryClient.invalidateQueries(FetchKey.ProductCollection)
          onReuseValueChainFromProduct(response.product)
        },
      },
    )
  }

  return (
    <DrawerView
      title={productToCopy.name}
      buttons={[
        {
          label: formatMessage({ id: options.fullScreen ? 'general.exitFullScreen' : 'general.viewFullScreen' }),
          onClick: () => setOptions({ fullScreen: !options.fullScreen }),
          variant: 'outlined',
          endIcon: options.fullScreen ? <FullscreenExit /> : <Fullscreen />,
        },
        {
          label: formatMessage({ id: 'schemas.mappingNode.addValueChain' }),
          onClick: createResponseProductAndReuseValueChain,
          loading: isSavingValueChainFromProduct || isCreatingResponse,
          variant: 'contained',
        },
      ]}
      stackButtons
      fullscreenContent={
        <ValueChainView
          showHeader={false}
          mappingNodes={productToCopy?.mappingNodes ?? []}
          forceView={View.Tree}
          treeViewHeight="100%"
          customEmptyState={<ValueChainEmptyStateAlternative />}
        />
      }
      {...props}
    >
      <Box p={2} height="100%">
        <ValueChainView
          mappingNodes={productToCopy?.mappingNodes ?? []}
          forceView={View.Table}
          showHeader={false}
          customEmptyState={<ValueChainEmptyStateAlternative />}
        />
      </Box>
    </DrawerView>
  )
}

export default DrawerViewReviewProductValueChainToCopyToNewResponse
