import endpoints from '@app/src/api/endpoints'
import { useFileDownload } from '@app/src/api/fetchHooks'
import { DateRangeOutlined } from '@mui/icons-material'
import { Box, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { useGetFormattedDate } from './DateDisplay'

type RenderFileDownloadProps = {
  fileName: string
  id: number
  expiresAt?: string | null
  children?: React.ReactNode
}
const RenderFileDownload: React.FC<RenderFileDownloadProps> = ({ fileName, id, expiresAt, children }) => {
  const { downloadFile } = useFileDownload()
  const { formatValueToDate } = useGetFormattedDate()

  const handleFileDownload = async (id: number) => {
    await downloadFile(endpoints.fileDownload(id))
  }

  return (
    <>
      <Stack>
        <Link
          variant="body1"
          underline="always"
          onClick={() => handleFileDownload(id)}
          sx={{ cursor: 'pointer', fontWeight: 'bold', '&:hover': { background: 'none' } }}
        >
          {fileName}
        </Link>

        {expiresAt && (
          <Box display="flex" alignItems="center" color={({ palette }) => palette.text.secondary}>
            <DateRangeOutlined fontSize="inherit" color="inherit" />
            <Typography variant="caption" ml={0.5} color="inherit">
              {formatValueToDate({ value: expiresAt ?? '' })}
            </Typography>
          </Box>
        )}
      </Stack>

      {children}
    </>
  )
}
export default RenderFileDownload
