import { CopyAllOutlined, FiberNewOutlined, LinkOutlined } from '@mui/icons-material'
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import DrawerViewAddMappingNode from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewAddMappingNode'
import DrawerViewSelectProductToConnect from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewSelectProductToConnect'
import DrawerViewSelectProductToCopy from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewSelectProductToCopy'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { ActorTypeModel, ProductMappingRequest, ProductMappingResponse } from '@app/src/types/product'
import { useDrawer } from '../../DrawerContext'
import DrawerView, { DrawerViewProps } from '../../DrawerView'

type DrawerViewCreateProductMappingResponseProps = {
  mappingRequest: ProductMappingRequest
  selectableActorTypes: ActorTypeModel[]
  hasAvailableMappingNodes: boolean
} & Omit<DrawerViewProps, 'title'>

const DrawerViewCreateProductMappingResponse: React.FC<DrawerViewCreateProductMappingResponseProps> = ({
  mappingRequest,
  selectableActorTypes,
  hasAvailableMappingNodes,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { openDrawer } = useDrawer()

  const { mutate: createResponse, isLoading: isCreatingResponse } = useCreateResource<
    ProductMappingResponse,
    Partial<ProductMappingResponse>
  >({
    options: {
      onError: (error: RequestError) => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const handleCreateResponse = () => {
    createResponse(
      {
        url: endpoints.productMappingResponse,
        body: { productMappingRequestId: mappingRequest.id },
      },
      {
        onSuccess: response => {
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          queryClient.invalidateQueries(FetchKey.ProductCollection)
          openDrawer(
            <DrawerViewAddMappingNode product={response.product} selectableActorTypes={selectableActorTypes} />,
          )
        },
      },
    )
  }

  return (
    <DrawerView title={formatMessage({ id: 'schemas.mappingNode.addSupplier' })} {...props}>
      <List>
        <ListItemButton disabled={isCreatingResponse} onClick={handleCreateResponse} sx={{ minHeight: 76 }}>
          <ListItemIcon>{isCreatingResponse ? <CircularProgress size={24} /> : <FiberNewOutlined />}</ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'schemas.mappingNode.createNewValueChain' })}
            secondary={formatMessage({ id: 'schemas.mappingNode.createNewValueChainDescription' })}
          />
        </ListItemButton>
        <Divider />
        <ListItemButton
          disabled={isCreatingResponse || !hasAvailableMappingNodes}
          onClick={() =>
            openDrawer(
              <DrawerViewSelectProductToCopy
                mappingRequestId={mappingRequest.id}
                productIndustry={mappingRequest.product.industry}
              />,
            )
          }
          sx={{ minHeight: 76 }}
        >
          <ListItemIcon>
            <CopyAllOutlined />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'schemas.mappingNode.copyExistingValueChain' })}
            secondary={formatMessage({ id: 'schemas.mappingNode.copyExistingValueChainDescription' })}
          />
        </ListItemButton>
        <Divider />
        <ListItemButton
          disabled={isCreatingResponse || !hasAvailableMappingNodes}
          onClick={() =>
            openDrawer(
              <DrawerViewSelectProductToConnect
                mappingRequestId={mappingRequest.id}
                productIndustry={mappingRequest.product.industry}
              />,
            )
          }
          sx={{ minHeight: 76 }}
        >
          <ListItemIcon>
            <LinkOutlined />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'schemas.mappingNode.connectToExistingProduct' })}
            secondary={formatMessage({ id: 'schemas.mappingNode.connectToExistingProductDescription' })}
          />
        </ListItemButton>
        <Divider />
      </List>
    </DrawerView>
  )
}

export default DrawerViewCreateProductMappingResponse
