import { Link, LinkProps } from '@mui/material'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'

type EmailCellProps = {
  mail: string
  label?: string
  disableCell?: boolean
} & Omit<LinkProps, 'href'>

const EmailCell: React.FC<EmailCellProps> = ({ mail, label, disableCell, ...props }) => (
  <ParentCellOrFragment disableCell={disableCell}>
    <Link href={`mailto:${mail}`} underline="hover" rel="noopener noreferrer" target="_blank" {...props}>
      {label || mail}
    </Link>
  </ParentCellOrFragment>
)

export default EmailCell
