import { Stack, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { capitalize } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { SortOrder } from '@app/src/types/filter'
import { Provider } from '@app/src/types/organizations'
import { Country } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'

type DrawerViewAddProviderPlaceholderProps = {
  providerType: string
} & Omit<DrawerViewProps, 'title'>

const DrawerViewAddProviderPlaceholder: React.FC<DrawerViewAddProviderPlaceholderProps> = ({
  providerType,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { register, errors, setValue, control, handleSubmit, setError } = useForm<Partial<Provider>>({
    mode: 'onChange',
  })
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateResource<Partial<Provider>>()

  const onSubmit = async (values: Partial<Provider>): Promise<void> => {
    await mutateAsync(
      {
        body: { ...values, name: values.name?.trim() },
        url: endpoints.saveProvider,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage(
              { id: 'notifications.successfulProviderSave' },
              { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }) },
            ),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          closeDrawer()
        },
        onError: error => {
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
            return
          }
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage(
        { id: 'resourceCollections.create.createProviderPlaceholder' },
        { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }).toLowerCase() },
      )}
      buttons={[
        {
          label: formatMessage(
            { id: 'resourceCollections.create.createProvider' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }).toLowerCase() },
          ),
          variant: 'contained',
          type: 'submit',
          loading: isLoading,
        },
      ]}
      onFormSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Stack px={2} spacing={2}>
        <Typography paragraph>
          {formatMessage(
            { id: 'resourceCollections.create.createProviderPlaceholderSubtitle' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 2 }).toLowerCase() },
          )}
        </Typography>

        <TextField
          hoveringLabel
          placeholder={formatMessage({ id: 'textFieldPlaceholders.organizationName' })}
          inputRef={register({
            required: formatMessage({ id: 'form.validation.required' }),
            minLength: {
              value: 2,
              message: formatMessage({ id: 'errorMessages.general.minLength' }, { count: 2 }),
            },
          })}
          fullWidth
          required
          name="name"
          label={formatMessage({ id: 'schemas.organization.name' })}
          error={Boolean(errors?.name)}
          type="text"
          helperText={errors?.name?.message}
          onClear={(): void => setValue('name', '')}
        />

        <Select<number, Country>
          hoveringLabel
          variant="outlined"
          name="countryId"
          rules={{ required: 'This field is required' }}
          fieldLabel={formatMessage({ id: 'schemas.organization.country' })}
          control={control}
          required
          forceFetch
          navigation={{
            url: endpoints.countries,
            type: 'post',
            postObject: {
              pagination: {
                itemsPerPage: 9999,
                pageNumber: 1,
              },
              include: [],
              filter: [],
              sort: { target: 'name', order: SortOrder.ASCENDING },
            },
          }}
          objectToOption={(country: Country): Option<number> => {
            return {
              label: country.name,
              value: country.id,
            }
          }}
          error={errors?.countryId?.message}
        />

        <TextField
          hoveringLabel
          placeholder={formatMessage({ id: 'textFieldPlaceholders.website' })}
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          fullWidth
          required
          name="websiteAddress"
          label={formatMessage({ id: 'schemas.organization.websiteAddress' })}
          error={Boolean(errors?.websiteAddress)}
          type="text"
          helperText={errors?.websiteAddress?.message}
          onClear={(): void => setValue('websiteAddress', '')}
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddProviderPlaceholder
