import { TableRow } from '@mui/material'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import UserCell from '@app/src/components/Table/Cells/UserCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { AuditLog } from '@app/src/types/resourceExplorer'
import { SelectedItemSnapshots } from './AuditLogsConfig'

interface AuditLogRowProps {
  row: AuditLog
  handleOpenSnapshotDrawer: ({ beforeSnapshot, afterSnapshot }: SelectedItemSnapshots) => void
}

export const AuditLogRow: React.FC<AuditLogRowProps> = ({ row: auditLog, handleOpenSnapshotDrawer }) => {
  const { formatMessage } = useIntl()
  const { afterSnapshot, beforeSnapshot } = auditLog
  const { formatValueToDate } = useGetFormattedDate()

  return (
    <TableRow>
      <ValueCell value={formatValueToDate({ value: auditLog.createdAt, withTime: true })} />
      <ValueCell value={auditLog.serviceType} />
      <ValueCell value={auditLog.objectType} />
      <ValueCell value={auditLog.sourceEventType} />
      <UserCell user={auditLog.creatorUser} drawer />
      <ButtonCell
        variant="contained"
        color="primary"
        label={formatMessage({ id: 'schemas.auditLog.snapshot' })}
        onClick={(): void => handleOpenSnapshotDrawer({ afterSnapshot, beforeSnapshot })}
      />
    </TableRow>
  )
}

export default AuditLogRow
