import { ChevronLeftOutlined, CloseOutlined } from '@mui/icons-material'
import { Box, IconButton, Skeleton, Stack } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { useDrawer } from './DrawerContext'

export type DrawerViewSkeletonProps = {
  showBackButton?: boolean
  buttons?: number
  stackButtons?: boolean
  title?: boolean
}

const DrawerViewSkeleton: React.FC<PropsWithChildren<DrawerViewSkeletonProps>> = ({
  showBackButton = true,
  title = true,
  children,
  buttons,
  stackButtons,
}) => {
  const { hasPreviousView, goBack, closeDrawer } = useDrawer()

  const shouldShowBack = hasPreviousView && showBackButton

  return (
    <Box display="flex" overflow="hidden" flexGrow={1}>
      <Stack width="100%" maxWidth={390}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={2}
          pl={shouldShowBack ? 1 : 3}
          pr={1}
        >
          {shouldShowBack && (
            <IconButton onClick={goBack} sx={{ mr: 1 }} size="large">
              <ChevronLeftOutlined />
            </IconButton>
          )}

          <Stack flexGrow={1} mr={2}>
            {title && <Skeleton width="100%" height={26} variant="rounded" />}
          </Stack>

          <IconButton onClick={closeDrawer} size="large">
            <CloseOutlined />
          </IconButton>
        </Box>

        <Stack flexGrow={1} overflow="hidden">
          <Stack display="flex" flexGrow={1} overflow="auto" pb={3}>
            {children ?? <Skeleton variant="rounded" width="92%" height="100%" sx={{ ml: 2 }} />}
          </Stack>

          {buttons && (
            <Box p={2} gap={stackButtons ? 2 : 1} display="flex" flexWrap={stackButtons ? 'wrap' : 'initial'}>
              {Array.from({ length: buttons }, (_, idx) => (
                <Skeleton key={idx} variant="rounded" height={40} width="100%" />
              ))}
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default DrawerViewSkeleton
