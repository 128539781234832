import { TableRow } from '@mui/material'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const RequestTemplateHeader = (): JSX.Element => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'schemas.subscription.creatorOrganization' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.subscription.request' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.subscription.createdAt' })} />
    </TableRow>
  )
}

export default RequestTemplateHeader
