import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import CompaniesStatisticsFilterContextProvider from '@app/src/context/CompaniesStatisticsFilterContextProvider'
import useApi from '@app/src/hooks/api'
import CertificateScreeningSummary from '@app/src/pages/Dashboards/CertificateScreeningSummary'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ReferralCode } from '@app/src/types/organizations'
import { Inquiry, InquiryStatus } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, SfdrRequestTags } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Equalizer } from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { generatePath, useHistory } from 'react-router'
import CompaniesByReportingProgress from '../CompaniesByReportingProgress'
import CompaniesByRequestDeadline from '../CompaniesByRequestDeadline'
import CompaniesCard from '../CompaniesCard'
import DiscoverButton from '../DiscoverButton'
import GoalTrackerSection from '../GoalTracker/GoalTrackerSection'
import PendingRequestsCard from '../PendingRequestsCard'
import RequestsByCompanyTable from '../RequestsByCompany'
import ResponsesCard from '../ResponsesCard'
import SFDRProgressCard from './SFDRProgressCard'
import SFDRStarterGuide from './SFDRStarterGuide/SFDRStarterGuide'

const InvestmentDashboard: React.FC = () => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const history = useHistory()
  const { get } = useApi()
  const { currentReportingPeriod } = useFlags()
  const { trackEvent } = useAmplitude()

  const { count: providersCount, isLoading: providersCountLoading } = useFetchFacets({
    key: FetchKey.ProviderCount,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'id' }],
    filter: [],
  })

  const { data: referralCodes } = useQuery<ReferralCode[]>(FetchKey.ReferralCodeCollection, () =>
    get<ReferralCode[]>({
      url: endpoints.getReferralCollection,
    }),
  )

  const lastReferralCode = referralCodes
    ?.sort((a, b) => b.id - a.id)
    ?.find(referral => referral?.scheduledRequests?.length)

  const periodStartsAt = `${currentReportingPeriod}-01-01T00:00:00`
  const periodEndsAt = `${currentReportingPeriod}-12-31T23:59:59`

  const {
    items: SFDRRequests,
    isLoading: isSFDRRequestsLoading,
    count: totalSFDRRequests,
  } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.SFDRRequests,
    endpoint: endpoints.inquiriesCollection,
    payload: {
      filter: [
        {
          name: 'template.tags.tag',
          filters: [
            {
              value: [SfdrRequestTags.Sfdr],
              operator: Operators.In,
            },
          ],
        },
        {
          name: 'periodStartsAt',
          filters: [
            {
              operator: Operators.EqualTo,
              value: periodStartsAt,
            },
          ],
        },
        {
          name: 'periodEndsAt',
          filters: [
            {
              operator: Operators.EqualTo,
              value: periodEndsAt,
            },
          ],
        },
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 999999,
      },
      include: ['template.tags'],
    },
    options: {
      enabled: Boolean(currentReportingPeriod),
    },
  })

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Dashboard.Overview.ViewedPage,
    })
  }, [])

  if (isSFDRRequestsLoading) {
    return (
      <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  const pendingStatuses = [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded]
  const pendingSFDRRequests = SFDRRequests.filter(request => pendingStatuses.includes(request.status)).length ?? 0

  const hasOnlySustainabilityApproach = SFDRRequests.every(request =>
    request.template.tags.includes(SfdrRequestTags.SfdrSusApproach),
  )
  const hasSFDRRequests = totalSFDRRequests > 0 && !hasOnlySustainabilityApproach
  const isSFDRProgressComplete = totalSFDRRequests > 0 && pendingSFDRRequests === 0

  return (
    <Box mt={2} display="flex" flexDirection="column" flexGrow={1}>
      <Helmet>
        <title>{formatMessage({ id: 'navbar.dashboard' })}</title>
      </Helmet>
      <Box px={4} py={2}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h1">{formatMessage({ id: 'navbar.dashboard' })}</Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              endIcon={<Equalizer />}
              onClick={() => {
                history.push(
                  generatePath(paths.dataHubCollection, {
                    view: DataHubView.DataInsights,
                  }),
                )
              }}
            >
              {formatMessage({ id: 'dashboard.investment.analyzeData' })}
            </Button>
            <DiscoverButton />
          </Stack>
        </Box>
      </Box>
      <SFDRStarterGuide hasInvestees={Boolean(providersCount)} hasReferralCode={Boolean(lastReferralCode)} />
      <Box px={4} py={2} bgcolor={palette.grey[100]} flexGrow={1} display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <CompaniesCard totalCompanies={providersCount} isLoading={providersCountLoading} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <PendingRequestsCard />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ResponsesCard />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SFDRProgressCard
              hasInvestees={Boolean(providersCount)}
              referralCode={lastReferralCode}
              hasSFDRRequests={hasSFDRRequests}
              isSFDRProgressComplete={isSFDRProgressComplete}
              pendingRequests={pendingSFDRRequests}
              totalRequests={totalSFDRRequests}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CompaniesByRequestDeadline />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CompaniesByReportingProgress />
          </Grid>
          <Grid item xs={12}>
            <GoalTrackerSection />
          </Grid>
          <Grid item xs={12}>
            <CertificateScreeningSummary />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12}>
            <CompaniesStatisticsFilterContextProvider>
              <RequestsByCompanyTable
                hasReferralCode={Boolean(lastReferralCode)}
                providersCount={providersCount}
                isLoading={providersCountLoading}
              />
            </CompaniesStatisticsFilterContextProvider>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default InvestmentDashboard
