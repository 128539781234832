import { ResourceTypes, Solutions } from '@app/src/wf-constants'

export const getProviderTypeFromSolution = (solutionType?: keyof typeof Solutions): string => {
  switch (solutionType?.toLowerCase()) {
    case Solutions.Sourcing:
      return ResourceTypes.Supplier
    case Solutions.Investment:
      return ResourceTypes.Investee
    case Solutions.Finance:
      return ResourceTypes.Company
    default:
      return 'Provider'
  }
}
