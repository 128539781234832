import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { GOAL_TRACKER_FILTER_PREFIX } from '@app/src/pages/Dashboards/GoalTracker/GoalTrackerDialog'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { useFiltersContext } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { QuestionType } from '@app/src/types/reporting'
import { InquiryStatus, Question, Request, RequestItem } from '@app/src/types/resourceExplorer'
import { uniq } from '@app/src/utils/helpers'
import paths from '@app/src/wf-constants/paths'
import SendIcon from '@mui/icons-material/Send'
import { Button, CircularProgress, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { GOAL_TRACKER_QUESTION_KEYS, GoalTrackerFormType } from './CreateGoalTrackerModal'
import SelectAnswerStep from './SelectAnswerStep'

interface SelectQuestionStepProps {
  setValidRequestItems: React.Dispatch<React.SetStateAction<Question[]>>
  currentGoalTrackerFilters?: FilterGroup[]
}

export function isNumber(items: Question[], watchRequestItemTemplateId: number) {
  const numberItem = items.find(
    u => u.id === watchRequestItemTemplateId && u.questionTypeId === QuestionType.Number,
  )?.unit

  return Boolean(numberItem)
}

const SelectQuestionStep: React.FC<SelectQuestionStepProps> = ({ setValidRequestItems, currentGoalTrackerFilters }) => {
  const { formatMessage } = useIntl()
  const { watch, setValue, control } = useFormContext()
  const { setEnableEmptyState } = useCreationModalProgress()
  const history = useHistory()
  const { clearQueryFilter, setQueryFilters } = useFiltersContext()
  const [isAnswerStepVisible, setIsAnswerStepVisible] = useState(true)

  const {
    facets: [requestSent],
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'questionnaireTemplateId' }],
    filter: [
      {
        name: 'status',
        filters: [
          {
            value: [InquiryStatus.Requested, InquiryStatus.Submitted, InquiryStatus.Approved],
            operator: Operators.In,
          },
        ],
      },
    ],
  })

  const distinctLabels = uniq(requestSent?.map(item => item.label))

  const { items: allTemplates, isLoading } = useFetchCollectionWithPost<Request>({
    key: [FetchKey.RequestForAllTemplates],
    endpoint: endpoints.worldfavorAndCustomTemplates,
    payload: {
      include: [],
      filter: [
        {
          name: 'sections.questions.questionTypeId',
          filters: [
            {
              value: [QuestionType.File, QuestionType.Options, QuestionType.Number],
              operator: Operators.In,
            },
          ],
        },
      ],
    },
  })

  const templateSent = allTemplates.filter(a => distinctLabels.includes(a.id))
  const templateIdValue: GoalTrackerFormType['templateId'] = watch(GOAL_TRACKER_QUESTION_KEYS.TEMPLATE_ID)
  const filterCollectionsValue: GoalTrackerFormType['filterCollections'] = watch(
    GOAL_TRACKER_QUESTION_KEYS.FILTER_COLLECTIONS,
  )
  const requestItemTemplateIdValue: GoalTrackerFormType['questionId'] = watch(
    GOAL_TRACKER_QUESTION_KEYS.REQUEST_ITEM_TEMPLATE_ID,
  )
  const targetReferenceValue: GoalTrackerFormType['targetReference'] = watch(
    GOAL_TRACKER_QUESTION_KEYS.TARGET_REFERENCE,
  )

  const { items: validRequestItems } = useFetchCollectionWithPost<Question>({
    key: [FetchKey.RequestItemTemplates],
    endpoint: endpoints.requestItemsCollection,
    payload: {
      include: ['unit', 'requestCollection'],
      filter: [
        {
          name: 'sections.questionnaireTemplate.id',
          filters: [
            {
              value: templateIdValue,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'questionTypeId',
          filters: [
            {
              value: [QuestionType.File, QuestionType.Options, QuestionType.Number],
              operator: Operators.In,
            },
          ],
        },
      ],
    },
    options: {
      enabled: Boolean(templateIdValue),
    },
  })

  useEffect(() => {
    if (!filterCollectionsValue) {
      clearQueryFilter()
    }
    if (currentGoalTrackerFilters) {
      const mappedFilters = currentGoalTrackerFilters?.map(filter => ({
        name: filter.name.replace(GOAL_TRACKER_FILTER_PREFIX, ''),
        value: filter.filters?.[0]?.value,
        operator: Operators.In,
      }))
      setQueryFilters(mappedFilters)
    }
  }, [currentGoalTrackerFilters])

  useEffect(() => {
    if (!requestItemTemplateIdValue || !isAnswerStepVisible) {
      setValue(GOAL_TRACKER_QUESTION_KEYS.TARGET_REFERENCE, undefined)
    }

    const isNumberQuestion = isNumber(validRequestItems, requestItemTemplateIdValue)
    if (isNumberQuestion) {
      setValue(GOAL_TRACKER_QUESTION_KEYS.TARGET_REFERENCE, ' ')
    }
  }, [requestItemTemplateIdValue, isAnswerStepVisible, targetReferenceValue])

  useDeepCompareEffect(() => {
    setValidRequestItems(validRequestItems)
  }, [validRequestItems, setValidRequestItems])

  if (isLoading) {
    return <CircularProgress />
  }
  setEnableEmptyState(!templateSent.length)

  return (
    <>
      {templateSent.length ? (
        <Stack spacing={2}>
          <Select<number, Request>
            name={GOAL_TRACKER_QUESTION_KEYS.TEMPLATE_ID}
            fieldLabel={formatMessage({ id: 'schemas.goalTracker.topic.label' })}
            control={control}
            required
            enableAutoSelect
            onInputChange={(e, value) => {
              if (!value) {
                setValue(GOAL_TRACKER_QUESTION_KEYS.TEMPLATE_ID, undefined)
                setIsAnswerStepVisible(false)
              }
            }}
            options={
              templateSent?.map(u => ({
                label: u.title,
                value: u.id,
              })) ?? []
            }
          />
          <Select<number, RequestItem>
            name={GOAL_TRACKER_QUESTION_KEYS.REQUEST_ITEM_TEMPLATE_ID}
            fieldLabel={formatMessage({ id: 'schemas.goalTracker.question.label' })}
            control={control}
            required
            enableAutoSelect
            disabled={!templateIdValue}
            key={templateIdValue}
            onInputChange={(e, value) => {
              if (!value) {
                setValue(GOAL_TRACKER_QUESTION_KEYS.REQUEST_ITEM_TEMPLATE_ID, undefined)
                setIsAnswerStepVisible(false)
              } else {
                setIsAnswerStepVisible(true)
              }
            }}
            options={
              validRequestItems?.map(u => ({
                label: u.questionText,
                value: u.id,
              })) ?? []
            }
          />
          {requestItemTemplateIdValue && isAnswerStepVisible && (
            <SelectAnswerStep validRequestItems={validRequestItems} />
          )}
        </Stack>
      ) : (
        <EmptyState
          iconComponent={SendIcon}
          title={formatMessage({ id: 'schemas.goalTracker.emptyState.title' })}
          description={formatMessage({ id: 'schemas.goalTracker.emptyState.description' })}
        >
          <Button
            variant="contained"
            onClick={() =>
              history.push(
                generatePath(paths.manageRequest, {
                  view: ViewTypeName.Requests,
                }),
              )
            }
          >
            {formatMessage({ id: 'schemas.goalTracker.emptyState.sendRequest' })}
          </Button>
        </EmptyState>
      )}
    </>
  )
}

export default SelectQuestionStep
