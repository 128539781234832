import _OptionAdornment from './OptionAdornment'
import _OptionIcon from './OptionIcon'

export const OptionAdornment = _OptionAdornment
export const OptionIcon = _OptionIcon

export interface Option<T = number | string> {
  label: string
  value: T
  adornment?: string
}
