import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { OptionIcon } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useAccount } from '@app/src/context/AccountContext'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { QuestionnaireTypeEnum, SharedTemplate, SolutionOrganization } from '@app/src/types/resourceExplorer'

const QuestionnaireType: React.FC = () => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { control, watch, setValue } = useFormContext()

  const questionnaireType: QuestionnaireTypeEnum = watch('questionnaireTemplateType')
  const sharedTemplates: Pick<SharedTemplate, 'organizationId' | 'solutionType'>[] | undefined =
    watch('sharedTemplates')

  const { data: organizationsWithSolution } = useFetchResource<SolutionOrganization[]>({
    key: FetchKey.SolutionOrganizations,
    endpoint: endpoints.solutionOrganizations,
  })

  useEffect(() => {
    if (questionnaireType === QuestionnaireTypeEnum.Regular) {
      setValue('sharedTemplates', [])
    }
  }, [questionnaireType])

  useEffect(() => {
    const includesCreatorOrganization = sharedTemplates?.some(
      template => template.organizationId === account?.organization?.id,
    )

    if (questionnaireType === QuestionnaireTypeEnum.Shared && !includesCreatorOrganization) {
      setValue('sharedTemplates', [
        {
          organizationId: account?.organization?.id,
          solutionType: account?.organization?.assignedSolutions?.find(
            assignedSolution => assignedSolution.organizationId === account?.organization?.id,
          )?.solutionType,
        },
        ...(sharedTemplates ?? []),
      ])
    }
  }, [questionnaireType, sharedTemplates])

  return (
    <>
      <Typography variant="h6" paragraph>
        {formatMessage({ id: 'templateBuilder.questionnaireType' })}
      </Typography>

      <FormControl>
        <Controller
          name="questionnaireTemplateType"
          control={control}
          defaultValue={QuestionnaireTypeEnum.Regular}
          render={({ onChange, value }): JSX.Element => (
            <RadioGroup onChange={onChange} value={value?.toString()}>
              <FormControlLabel
                value={QuestionnaireTypeEnum.Regular}
                control={<Radio />}
                label={
                  <>
                    <Typography mt={1}>{formatMessage({ id: 'templateBuilder.regularQuestionnaire' })}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {formatMessage({ id: 'templateBuilder.regularQuestionnaireDescription' })}
                    </Typography>
                  </>
                }
                sx={{ alignItems: 'flex-start' }}
              />
              <FormControlLabel
                value={QuestionnaireTypeEnum.Shared}
                control={<Radio />}
                label={
                  <>
                    <Typography mt={1}>{formatMessage({ id: 'templateBuilder.sharedTemplate' })}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {formatMessage({ id: 'templateBuilder.sharedTemplateDescription' })}
                    </Typography>
                  </>
                }
                sx={{ alignItems: 'flex-start' }}
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      {questionnaireType === QuestionnaireTypeEnum.Shared && (
        <Box mt={1} ml={4}>
          <Select<Omit<SolutionOrganization, 'organizationName'>, SolutionOrganization>
            multiple
            fieldLabel={formatMessage({ id: 'templateBuilder.sharedOrganizations' })}
            control={control}
            name="sharedTemplates"
            options={organizationsWithSolution?.map(solution => ({
              value: { organizationId: solution.organizationId, solutionType: solution.solutionType },
              label: solution.organizationName,
            }))}
            findSelectedValue={(value, option) => value?.organizationId === option?.value?.organizationId}
            objectToOption={({ organizationId, organizationName, solutionType }) => ({
              label: organizationName,
              value: { organizationId, solutionType },
            })}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={`${option.value.organizationId}-${option.value.solutionType}`}>
                <OptionIcon selected={selected} multiple />
                <Box display="flex" flexDirection="column">
                  <Typography variant="inherit">{option.label}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {option.value.solutionType}
                  </Typography>
                </Box>
              </li>
            )}
          />
        </Box>
      )}
    </>
  )
}

export default QuestionnaireType
