import MultipleStepsFormContext from '@app/src/context/MultipleStepsFormContext'
import React, { useState } from 'react'
import { FieldValues, UnpackNestedValue } from 'react-hook-form'

interface Props {
  children: JSX.Element
}

const MultipleStepsFormContextProvider = ({ children }: Props): JSX.Element => {
  const [values, setValues] = useState<UnpackNestedValue<FieldValues>>({})
  return <MultipleStepsFormContext.Provider value={{ values, setValues }}>{children}</MultipleStepsFormContext.Provider>
}

export default MultipleStepsFormContextProvider
