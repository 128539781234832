import Report from '@app/src/pages/Questionnaire/Provider/Report'
import ReportContext from '@app/src/pages/Questionnaire/ReportContext'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { mapToReportContextPropsForPreview } from '@app/src/utils/previewMapper'
import { Box } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

type TemplatePreviewProps = {
  template: QuestionnaireTemplate
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({ template }) => {
  const formMethods = useForm()

  if (!template) {
    return null
  }

  return (
    <ReportContext.Provider value={mapToReportContextPropsForPreview(template)}>
      <FormProvider {...formMethods}>
        <Box maxWidth={920} alignSelf="center" mt={2}>
          <Report />
        </Box>
      </FormProvider>
    </ReportContext.Provider>
  )
}

export default TemplatePreview
