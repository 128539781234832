import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { Check, CloseOutlined } from '@mui/icons-material'
import { Alert, Button, DialogContent, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface ReminderSuccessScreenProps {
  onClose: () => void
  respondersCount: number
}

function ReminderSuccessScreen({ onClose, respondersCount }: ReminderSuccessScreenProps) {
  const { formatMessage } = useIntl()

  return (
    <DialogContent>
      <Stack height="100%" alignItems="center">
        <Stack flexGrow={1} alignItems="center" justifyContent="center" width={488} spacing={3} textAlign="center">
          <Avatar variant="circular" size={AvatarSize.XXXL} sx={{ bgcolor: 'success.main' }}>
            <Check color="primary" sx={{ fontSize: 64 }} />
          </Avatar>
          <Typography mt={3} variant="subtitle1">
            {formatMessage({ id: 'form.requestsReminder.confirmationTitle' }, { respondersCount })}
          </Typography>
          <Typography color="textSecondary">
            {formatMessage({ id: 'form.requestsReminder.confirmationDescription' }, { respondersCount })}
          </Typography>
          <Stack spacing={2} mt={4}>
            <Button onClick={onClose} startIcon={<CloseOutlined />} variant="contained">
              {formatMessage({ id: 'general.close' })}
            </Button>
          </Stack>
        </Stack>

        <Stack width={488}>
          <Divider sx={{ my: 4 }} />
          <Alert variant="filled" severity="info" sx={{ backgroundColor: 'common.white', alignItems: 'flex-start' }}>
            {formatMessage({ id: 'form.requestsReminder.confirmationAlert' })}
          </Alert>
        </Stack>
      </Stack>
    </DialogContent>
  )
}

export default ReminderSuccessScreen
