import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource, useUpdateResource } from '@app/src/api/updateHooks'
import { MappingNodeFormValues } from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewAddMappingNode'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import ActorTypeField from '@app/src/pages/Product/ActorTypeField'
import { ActorTypeModel, MappingNode, NodeType } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Box } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../../DrawerContext'
import DrawerView, { DrawerViewProps } from '../../DrawerView'

type DrawerViewEditMappingNodeProps = {
  mappingNode?: MappingNode
  selectableActorTypes: ActorTypeModel[]
} & Omit<DrawerViewProps, 'title'>

const DrawerViewEditMappingNode: React.FC<DrawerViewEditMappingNodeProps> = ({
  mappingNode,
  selectableActorTypes,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { closeDrawer } = useDrawer()
  const { mutate, isLoading } = useUpdateResource<MappingNode, Partial<MappingNode>>()
  const { mutate: deleteMappingNode } = useDeleteResource()
  const confirm = useConfirm()

  const formMethods = useForm<MappingNodeFormValues>({
    mode: 'onChange',
    defaultValues: mappingNode,
  })

  const { register, handleSubmit, errors, control } = formMethods

  const onSubmit = (values: MappingNodeFormValues) => {
    const body = {
      ...mappingNode,
      id: mappingNode?.id,
      actorTypeId: values.actorTypeId,
    }

    mutate(
      {
        url: endpoints.mappingNode,
        body,
      },
      {
        onSuccess: () => {
          closeDrawer()
          queryClient.invalidateQueries([FetchKey.Product, mappingNode?.productId])
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          showSnackbar({
            message: formatMessage(
              { id: 'notifications.successfullyEditedNode' },
              { nodeName: mappingNode?.name ?? '' },
            ),
            severity: NotificationSeverity.success,
          })
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const deleteNode = (mappingNode: MappingNode) => {
    confirm({
      title: formatMessage({ id: 'general.areYouSure' }),
      description: formatMessage({ id: 'schemas.mappingNode.deleteDialog.content' }),
      cancellationText: formatMessage({ id: 'general.cancel' }),
      confirmationText: formatMessage({ id: 'general.delete' }),
    }).then(() => {
      deleteMappingNode(
        { url: endpoints.deleteMappingNode(mappingNode.id), body: {} },
        {
          onSuccess: async () => {
            showSnackbar({
              message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
              severity: NotificationSeverity.success,
            })
            queryClient.invalidateQueries(FetchKey.MappingNodeCollection)
            queryClient.invalidateQueries(FetchKey.Product)
            queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)

            closeDrawer()
          },
          onError: error => {
            showErrorNotification({ requestError: error, disableAutoClose: true })
          },
        },
      )
    })
  }

  return (
    <FormProvider {...formMethods}>
      <DrawerView
        title={formatMessage({ id: 'schemas.mappingNode.addSupplier' })}
        buttons={[
          {
            loading: isLoading,
            label: formatMessage({ id: 'general.delete' }),
            variant: 'outlined',
            color: 'error',
            onClick: () => {
              if (mappingNode) deleteNode(mappingNode)
            },
          },
          {
            loading: isLoading,
            label: formatMessage({ id: 'general.save' }),
            variant: 'contained',
            color: 'primary',
            type: 'submit',
          },
        ]}
        onFormSubmit={handleSubmit(onSubmit)}
        {...props}
      >
        <Box px={2} mt={2}>
          <input type="hidden" name="nodeType" ref={register()} value={NodeType.OrganizationNode} />

          <Box mt={2}>
            <Controller
              name="actorTypeId"
              defaultValue={null}
              control={control}
              render={({ onChange, value }) => (
                <ActorTypeField onChange={onChange} value={value} actorTypes={selectableActorTypes} />
              )}
              rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
              helperText={errors?.actorTypeId?.message}
              error={Boolean(errors?.actorTypeId)}
            />
          </Box>
        </Box>
      </DrawerView>
    </FormProvider>
  )
}

export default DrawerViewEditMappingNode
