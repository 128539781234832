import { Schema } from '@app/src/types/schemas'

const ReportsSchema: Schema = {
  name: 'report',
  items: [],
  relatedTabs: [],
  actions: [],
  relatedContentFilters: {},
}

export default ReportsSchema
