import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewReferralContact from '@app/src/components/Drawer/Views/DrawerViewReferralContact'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { Provider } from '@app/src/types/organizations'
import { ButtonBase, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import TextTruncate, { TextTruncateProps } from 'react-text-truncate'
import ParentCellOrFragment from './ParentCellOrFragment'

type ReferralContactCellProps = {
  provider: Provider
  maxRows?: TextTruncateProps['line']
  drawer?: boolean
  disableCell?: boolean
}

const ReferralContactCell: React.FC<ReferralContactCellProps> = ({ provider, maxRows, drawer, disableCell }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const [truncated, setTruncated] = useState(false)

  if (!provider.referralContact) {
    return (
      <ParentCellOrFragment disableCell={disableCell}>
        <Typography variant="body1">{formatMessage({ id: 'general.notAdded' })}</Typography>
      </ParentCellOrFragment>
    )
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Tooltip title={truncated ? provider.referralContact.name : undefined}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          component={drawer ? ButtonBase : 'span'}
          onClick={drawer ? () => openDrawer(<DrawerViewReferralContact provider={provider} />) : undefined}
          width="100%"
        >
          <PersonAvatar name={provider.referralContact.name} size={AvatarSize.Small} disabled />
          <Typography
            variant="body1"
            align="left"
            sx={{ '&:hover': { textDecoration: drawer ? 'underline' : 'none' } }}
            flexGrow={1}
            component="span"
          >
            <TextTruncate line={maxRows} text={provider.referralContact.name} onTruncated={() => setTruncated(true)} />
          </Typography>
        </Stack>
      </Tooltip>
    </ParentCellOrFragment>
  )
}

export default ReferralContactCell
