import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import FilterSelect from '@app/src/pages/ResourceCollection/Filters/FilterSelect'
import { Category, CategoryOption } from '@app/src/types/categories'
import React from 'react'

interface Props {
  category: Category
  value: string[]
  onChange: (value: string[] | null) => void
}

const CategoryFilter = ({ category, value, onChange }: Props): JSX.Element => {
  const { data: categoryOptions } = useFetchResource<CategoryOption[]>({
    key: [FetchKey.CategoryOption, category.id],
    endpoint: endpoints.categoryOptionsByCategory(category.id),
    options: { enabled: !category.options },
  })

  const optionsToDisplay = category.options ?? categoryOptions
  return (
    <FilterSelect
      size="small"
      multiple
      onChange={value => onChange(value)}
      fieldLabel={category.name}
      value={value}
      options={
        optionsToDisplay?.map(categoryOption => ({
          label: categoryOption.name,
          value: categoryOption.id,
        })) ?? []
      }
    />
  )
}

export default CategoryFilter
