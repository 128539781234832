import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { ValueChainEmptyStateAlternative } from '@app/src/pages/Product/ProductRequestCard/ProductRequestCard'
import ValueChainView, { View } from '@app/src/pages/Product/ValueChainView'
import { ProductMappingResponse } from '@app/src/types/product'
import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type DrawerViewReviewResponseValueChainProps = {
  mappingResponse: ProductMappingResponse
} & Omit<DrawerViewProps, 'title'>

const DrawerViewReviewResponseValueChain: React.FC<DrawerViewReviewResponseValueChainProps> = ({
  mappingResponse,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { options, setOptions } = useDrawer()

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.product.valueChain' })}
      buttons={[
        {
          label: formatMessage({ id: options.fullScreen ? 'general.exitFullScreen' : 'general.viewFullScreen' }),
          onClick: () => setOptions({ fullScreen: !options.fullScreen }),
          variant: 'outlined',
          endIcon: options.fullScreen ? <FullscreenExit /> : <Fullscreen />,
        },
      ]}
      stackButtons
      fullscreenContent={
        <ValueChainView
          showHeader={false}
          mappingNodes={mappingResponse.mappingNodesSnapshot}
          isSnapshot
          forceView={View.Tree}
          treeViewHeight="100%"
          customEmptyState={<ValueChainEmptyStateAlternative />}
        />
      }
      {...props}
    >
      <Box p={2} height="100%">
        <ValueChainView
          mappingNodes={mappingResponse.mappingNodesSnapshot}
          isSnapshot
          forceView={View.Table}
          showHeader={false}
          customEmptyState={<ValueChainEmptyStateAlternative />}
        />
      </Box>
    </DrawerView>
  )
}

export default DrawerViewReviewResponseValueChain
