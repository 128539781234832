import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import Dialog from '@app/src/components/Dialog'
import FilterDisplayChip from '@app/src/components/FilterDisplayChip'
import BarChart from '@app/src/components/OverTimeCharts/BarChart'
import LineChart from '@app/src/components/OverTimeCharts/LineChart'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { DataHubView } from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FileUploadType } from '@app/src/types/reporting'
import { GoalTracker, InquiryStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { PERCENTAGE_UNIT_ID, QuestionTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Box, Button, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import { BarChart as EchartsBarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, MarkLineComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import React, { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import GraphLegend from '../GraphLegend'
import { ShowValueOptions } from './GoalTrackerCard'

enum GoalTrackerMatchTypes {
  MatchGoal = 'Match goal',
  DoesNotMatchGoal = 'Doesn’t match goal',
}

export interface ChartData {
  value: number
  period: string
  symbol: string | null
  matchGoalCount?: number
  matchGoalPercentage?: number
  doesNotMatchGoalCount?: number
  doesNotMatchGoalPercentage?: number
  name?: GoalTrackerMatchTypes
}

interface GoalTrackerDialogProps {
  isOpen: boolean
  goalTrackerItem: GoalTracker
  onClose: () => void
  periods?: string[]
  questionType: string
  isSimpleNumerical: boolean
  chartData: Array<ChartData>
  showValue: ShowValueOptions
  setShowValue: React.Dispatch<React.SetStateAction<ShowValueOptions>>
  onEdit?: () => void
}

export const GOAL_TRACKER_FILTER_PREFIX = 'response.request.subscriptions.target.'

const GoalTrackerDialog: React.FC<GoalTrackerDialogProps> = ({
  isOpen,
  goalTrackerItem,
  onClose,
  periods,
  questionType,
  isSimpleNumerical,
  showValue,
  setShowValue,
  chartData,
  onEdit,
}) => {
  const { formatMessage } = useIntl()
  const { palette, spacing } = useTheme()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [isShowValueMenuOpen, setShowValueMenuOpen] = useState(false)
  const history = useHistory()
  const [highlightedPeriod, setHighlightedPeriod] = useState<ChartData>()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  const { count: notAnsweredRequests } = useFetchFacets({
    key: FetchKey.RequestedInquiries,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'id', isEnum: true }],
    filter: [
      {
        name: 'status',
        filters: [{ value: [InquiryStatus.Requested], operator: Operators.In }],
      },
      {
        name: 'template.sections.questions.id',
        filters: [
          {
            value: [goalTrackerItem.questionId],
            operator: Operators.In,
          },
        ],
      },
      ...(highlightedPeriod?.period
        ? [
            {
              name: 'periodName',
              filters: [
                {
                  value: [highlightedPeriod?.period],
                  operator: Operators.In,
                },
              ],
            },
          ]
        : []),
      ...(goalTrackerItem.filterCollections
        ? goalTrackerItem.filterCollections.map(filter => {
            const providerFilter = filter.name.split(GOAL_TRACKER_FILTER_PREFIX)[1]
            return {
              name: `provider.${providerFilter}`,
              filters: [{ value: filter.filters[0].value, operator: Operators.In }],
            }
          })
        : []),
    ],
  })

  const xAxisData =
    periods?.map(period => ({
      value: period,
      companiesAnswered:
        (chartData.find(data => data.period === period)?.matchGoalCount ?? 0) +
        (chartData.find(data => data.period === period)?.doesNotMatchGoalCount ?? 0),
      pendingRequests: notAnsweredRequests,
    })) ?? []

  const optionMatchLink = stringifyQueryFilters({
    url: generatePath(paths.dataHubCollection, {
      view: DataHubView.Answers,
    }),
    queryParams: {
      filters: [
        {
          name: 'response.request.periodName',
          value: [highlightedPeriod?.period],
          operator: Operators.In,
        },
        {
          name: 'requestItem.template.id',
          value: [goalTrackerItem.questionId],
          operator: Operators.In,
        },
        {
          name: 'cannotAnswer',
          value: false,
          operator: Operators.EqualTo,
        },
        ...(goalTrackerItem.targetReference
          ? [
              {
                name: 'answer',
                value: goalTrackerItem.targetReference,
                operator: Operators.Contains,
              },
            ]
          : []),
        ...(goalTrackerItem.filterCollections
          ? goalTrackerItem.filterCollections.map(filter => ({
              name: filter.name,
              value: filter.filters[0].value,
              operator: Operators.In,
            }))
          : []),
      ],
    },
  })

  const fileUploadedMatchLink = stringifyQueryFilters({
    url: generatePath(paths.dataHubCollection, {
      view: DataHubView.Answers,
    }),
    queryParams: {
      filters: [
        {
          name: 'response.request.periodName',
          value: [highlightedPeriod?.period],
          operator: Operators.In,
        },
        {
          name: 'requestItem.template.id',
          value: [goalTrackerItem.questionId],
          operator: Operators.In,
        },
        {
          name: 'cannotAnswer',
          value: goalTrackerItem.targetReference === FileUploadType.FileUploaded ? false : true,
          operator: Operators.EqualTo,
        },
        ...(goalTrackerItem.filterCollections
          ? goalTrackerItem.filterCollections.map(filter => ({
              name: filter.name,
              value: filter.filters[0].value,
              operator: Operators.In,
            }))
          : []),
      ],
    },
  })

  const matchLink = questionType === QuestionTypes.File ? fileUploadedMatchLink : optionMatchLink

  const isPercentageGoal = goalTrackerItem.unitId === PERCENTAGE_UNIT_ID

  const handleCloseDialog = () => {
    setShowValue(ShowValueOptions.SUM)
    onClose()
  }

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    EchartsBarChart,
    SVGRenderer,
    LegendComponent,
    MarkLineComponent,
  ])

  const periodValues = chartData.map(data => data.value)
  const yAxisMax = isPercentageGoal ? 100 : Math.max(goalTrackerItem?.goalValue, Math.max(...periodValues))

  const lineChartData = chartData.map(data => {
    return {
      ...data,
      period: data.period,
      name: GoalTrackerMatchTypes.MatchGoal,
    }
  })

  const barChartData = chartData.map(data => {
    const position = data.value > 10 ? 'insideTop' : 'top'
    return {
      ...data,
      period: data.period,
      name: GoalTrackerMatchTypes.MatchGoal,
      tooltip: {
        formatter: (params: { data: { value: number; matchGoalCount: number; name: string } }) => {
          const dataItem = params.data

          return `
          ${dataItem.name} ${dataItem.matchGoalCount} (${dataItem.value}%)<br/>
        `
        },
      },

      label: {
        position,
        show: true,
        fontWeight: 700,
        formatter: (params: { value: string | number }) => {
          return `${params.value} ${goalTrackerItem?.goalValueUnit?.symbol ?? ''}`
        },
      },
    }
  })

  const backgroundBarData = chartData.map(data => ({
    ...data,
    label: {
      show: false,
    },
    value: yAxisMax - data.value,
    name: GoalTrackerMatchTypes.DoesNotMatchGoal,
    tooltip: {
      formatter: (params: { data: { value: number; doesNotMatchGoalCount: number; name: string } }) => {
        const dataItem = params.data

        return `
        ${dataItem.name} ${dataItem.doesNotMatchGoalCount} (${dataItem.value}%)<br/>
      `
      },
    },
  }))

  const lineChartSeriesLabel = {
    show: true,
    position: 'top',
    distance: 5,
    fontWeight: 700,
    formatter: (params: { value: string | number }) => {
      return `${params.value} ${goalTrackerItem?.goalValueUnit?.symbol ?? ''}`
    },
  }

  const markLineData = [
    {
      yAxis: goalTrackerItem?.goalValue,
      label: {
        show: true,
        position: 'insideMiddle',
        formatter: `Goal: ${goalTrackerItem?.goalValue} ${goalTrackerItem?.goalValueUnit?.symbol ?? ''}`,
        color: palette.common.white,
        backgroundColor: palette.primary.main,
        borderRadius: 4,
        padding: [2, 4],
        margin: [-4, 0, 0, 0],
      },
      lineStyle: {
        width: 1.5,
        color: palette.primary.main,
      },
    },
  ]

  const grid = {
    left: `${spacing(4)}px`,
    right: `${spacing(4)}px`,
    top: `${spacing(3)}px`,
    bottom: `${spacing(8)}px`,
  }

  const events = useMemo(
    () => ({
      click: ({ data }: { data: ChartData }) => {
        history.push(
          stringifyQueryFilters({
            url: generatePath(paths.dataHubCollection, {
              view: DataHubView.Answers,
            }),
            queryParams: {
              filters: [
                {
                  name: 'response.request.periodName',
                  value: [data?.period],
                  operator: Operators.In,
                },
                {
                  name: 'requestItem.template.id',
                  value: [goalTrackerItem.questionId],
                  operator: Operators.In,
                },
                ...(goalTrackerItem.filterCollections
                  ? goalTrackerItem.filterCollections.map(filter => ({
                      name: filter.name,
                      value: filter.filters[0].value,
                      operator: Operators.In,
                    }))
                  : []),
                ...insertIf(questionType !== QuestionTypes.File, {
                  name: 'cannotAnswer',
                  value: false,
                  operator: Operators.EqualTo,
                }),
              ],
            },
          }),
        )
      },
    }),
    [goalTrackerItem.filterCollections, goalTrackerItem.questionId, history, questionType, stringifyQueryFilters],
  )

  if (!periods) {
    return null
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClose={handleCloseDialog}
      title={goalTrackerItem?.title}
      content={
        <Box>
          <Typography variant="body1">{goalTrackerItem?.description}</Typography>

          <Stack spacing={2}>
            {(Boolean(goalTrackerItem?.filterCollections) || isSimpleNumerical) && (
              <Stack direction="row" justifyContent="space-between" alignItems="end">
                <Stack direction="row" mr={2} flexWrap="wrap">
                  {goalTrackerItem.filterCollections?.map(filter => (
                    <FilterDisplayChip
                      key={filter.name}
                      filterName={filter.name}
                      filterValue={filter.filters[0].value}
                    />
                  ))}
                </Stack>

                {isSimpleNumerical && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography color="primary" variant="body1">
                      {formatMessage({ id: 'schemas.goalTracker.show' })}
                    </Typography>
                    <Box>
                      <Button
                        endIcon={<ArrowDropDownIcon />}
                        size="small"
                        onClick={() => setShowValueMenuOpen(true)}
                        ref={anchorRef}
                      >
                        {formatMessage({ id: `schemas.goalTracker.${showValue}` })}
                      </Button>
                      <Menu
                        open={isShowValueMenuOpen}
                        anchorEl={anchorRef.current}
                        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                        elevation={1}
                        onClose={() => setShowValueMenuOpen(false)}
                      >
                        <MenuItem
                          onClick={() => {
                            setHighlightedPeriod(undefined)
                            setShowValueMenuOpen(false)
                            setShowValue(ShowValueOptions.SUM)
                          }}
                        >
                          {formatMessage({ id: `schemas.goalTracker.${ShowValueOptions.SUM}` })}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setHighlightedPeriod(undefined)
                            setShowValueMenuOpen(false)
                            setShowValue(ShowValueOptions.AVG)
                          }}
                        >
                          {formatMessage({ id: `schemas.goalTracker.${ShowValueOptions.AVG}` })}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Stack>
                )}
              </Stack>
            )}

            {questionType === QuestionTypes.Number ? (
              <LineChart<ChartData>
                data={lineChartData}
                xAxisData={xAxisData}
                markLineData={markLineData}
                events={events}
                seriesLabel={lineChartSeriesLabel}
                showMarkLine={showValue === ShowValueOptions.SUM}
                grid={grid}
              />
            ) : (
              <BarChart<ChartData>
                data={barChartData}
                xAxisData={xAxisData}
                yAxisMax={yAxisMax}
                backgroundBarData={backgroundBarData}
                markLineData={markLineData}
                events={events}
                grid={grid}
              />
            )}

            {questionType !== QuestionTypes.Number && (
              <Stack direction="row" spacing={2} px={5}>
                <GraphLegend
                  legend={formatMessage({ id: 'schemas.goalTracker.matchGoal' })}
                  color={palette.visualization[2]}
                  linkTo={matchLink}
                />
                <GraphLegend
                  legend={formatMessage({ id: 'schemas.goalTracker.doNotMatchGoal' })}
                  color={palette.grey[200]}
                  linkTo={matchLink}
                />
              </Stack>
            )}
          </Stack>
        </Box>
      }
      buttons={[
        {
          label: formatMessage({ id: 'schemas.goalTracker.editGoal' }),
          onClick: onEdit,
        },
        {
          label: formatMessage({ id: 'general.close' }),
          variant: 'contained',
          onClick: handleCloseDialog,
        },
      ]}
    />
  )
}

export default GoalTrackerDialog
