import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Request } from '@app/src/types/resourceExplorer'
import { DEFAULT_TABLE_PAGE_SIZE } from '@app/src/wf-constants'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import EdciDialog from './EdciDialog'
import EdciHeader from './EdciHeader'
import EdciRow from './EdciRow'

const EdciConfig = () => {
  const { formatMessage } = useIntl()
  const [isEdciOpen, openEdciDialog, closeEdciDialog] = useDialogState()

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_TABLE_PAGE_SIZE)

  const {
    items: requests,
    count,
    isLoading,
    isError,
  } = useFetchCollectionWithPost<Request>({
    endpoint: endpoints.requests,
    key: FetchKey.Request,
    payload: {
      filter: [
        {
          name: 'tags.tag',
          filters: [
            {
              value: ['edci'],
              operator: Operators.In,
            },
          ],
        },
      ],
      include: ['responderOrganization', 'subscriptions.target'],
      sort: { target: 'responderOrganizationId', order: SortOrder.ASCENDING },
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <ConfigurationCollection
      actionButtons={[
        {
          label: formatMessage({ id: 'form.createRequest.edci.sendToEdci' }),
          variant: 'contained',
          onClick: openEdciDialog,
        },
      ]}
    >
      <Table<Request>
        RowComponent={EdciRow}
        HeaderComponent={EdciHeader}
        count={count}
        data={requests}
        isLoading={isLoading}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
      <EdciDialog isOpen={isEdciOpen} closeDialog={closeEdciDialog} />
    </ConfigurationCollection>
  )
}

export default EdciConfig
