import LinkButton, { LinkButtonProps } from '@app/src/components/LinkButton'
import LoadingButton, { LoadingButtonProps } from '@app/src/components/LoadingButton'
import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'

export type ButtonCellProps = {
  label: string
  disableCell?: boolean
  width?: number
} & (LoadingButtonProps | LinkButtonProps)

const isLinkButton = (button: LoadingButtonProps | LinkButtonProps): button is LinkButtonProps =>
  Boolean((button as LinkButtonProps).to)

const ButtonCell: React.FC<ButtonCellProps> = ({ label, disableCell, width = 10, ...props }) => (
  <ParentCellOrFragment disableCell={disableCell} width={width}>
    {isLinkButton(props) ? (
      <LinkButton {...props}>{label}</LinkButton>
    ) : (
      <LoadingButton {...props}>{label}</LoadingButton>
    )}
  </ParentCellOrFragment>
)

export default ButtonCell
