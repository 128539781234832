import { BarChartOutlined, CloseOutlined, FactCheckOutlined } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Level } from '@app/src/components/Table/Cells/LevelCell'
import React from 'react'
import { useIntl } from 'react-intl'

const InfoListItem: React.FC<{ icon: React.ReactNode; primary: string; secondary: string }> = ({
  icon,
  primary,
  secondary,
}) => {
  return (
    <ListItem disablePadding alignItems="flex-start">
      <ListItemAvatar>
        <Avatar sx={({ palette }) => ({ bgcolor: palette.info.light, color: palette.common.black })}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{ variant: 'subtitle1' }}
        secondaryTypographyProps={{ variant: 'body1' }}
      />
    </ListItem>
  )
}

interface BaseAssessmentInfoDialogProps {
  open: boolean
  closeDialog: () => void
}

const BaseAssessmentInfoDialog: React.FC<BaseAssessmentInfoDialogProps> = ({ open, closeDialog }) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
      <Box alignSelf="flex-end" pt={2} pr={2}>
        <IconButton onClick={closeDialog}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Stack px={6} pb={6} spacing={3}>
        <Typography variant="h5" textAlign="center">
          {formatMessage({ id: 'baseAssessment.infoDialog.title' })}
        </Typography>
        <InfoListItem
          icon={<FactCheckOutlined />}
          primary={formatMessage({ id: 'baseAssessment.infoDialog.baselineAssessment' })}
          secondary={formatMessage({ id: 'baseAssessment.infoDialog.baselineAssessmentInfo' })}
        />
        <InfoListItem
          icon={<BarChartOutlined />}
          primary={formatMessage({ id: 'baseAssessment.infoDialog.realCollectedData' })}
          secondary={formatMessage({ id: 'baseAssessment.infoDialog.realCollectedDataInfo' })}
        />
        <Stack spacing={1}>
          <Typography variant="h6">
            {formatMessage({ id: 'baseAssessment.infoDialog.transparencyMethodology' })}
          </Typography>
          <Typography variant="body1">
            {formatMessage({ id: 'baseAssessment.infoDialog.transparencyMethodologyInfo' })}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={8}>
          <Level value={1} />
          <Stack>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level1Title' })}
            </Typography>
            <Typography color="textSecondary">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level1HelperText' })}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={8}>
          <Level value={2} />
          <Stack>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level2Title' })}
            </Typography>
            <Typography color="textSecondary">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level2HelperText' })}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={8}>
          <Level value={3} />
          <Stack>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level3Title' })}
            </Typography>
            <Typography color="textSecondary">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level3HelperText' })}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={8}>
          <Level value={4} />
          <Stack>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level4Title' })}
            </Typography>
            <Typography color="textSecondary">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level4HelperText' })}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={8}>
          <Level value={5} />
          <Stack>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level5Title' })}
            </Typography>
            <Typography color="textSecondary">
              {formatMessage({ id: 'baseAssessment.levelDefinitions.level5HelperText' })}
            </Typography>
          </Stack>
        </Stack>

        <Box textAlign="center">
          <Button variant="text" onClick={closeDialog}>
            {formatMessage({ id: 'general.close' })}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  )
}

export default BaseAssessmentInfoDialog
