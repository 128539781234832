import { Box, Chip, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import endpoints from '@app/src/api/endpoints'
import { useCreateResource } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { ReportCreateModel, ReportTemplateState, Request } from '@app/src/types/resourceExplorer'
import { RequestTags, SfdrRequestTags, WfRequestTags } from '@app/src/wf-constants'
import { reporting } from '@app/src/wf-constants/paths'
import { TagIcon } from './TagIcon'

const useStyles = makeStyles(({ spacing, palette }) => ({
  tagChip: {
    marginRight: spacing(1),
  },
  recommendedChip: {
    backgroundColor: palette.communication.main,
  },
  reportedChip: {
    backgroundColor: palette.success.main,
  },
}))

interface TemplateRowProps {
  templateId: number
  title: string
  tags: RequestTags[]
  reportTemplateState?: ReportTemplateState
  isLastTemplate: boolean
}

const TemplateRow: React.FC<TemplateRowProps> = ({ templateId, title, tags, reportTemplateState, isLastTemplate }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { showErrorNotification } = useErrorNotification()

  const { mutateAsync, isLoading } = useCreateResource<Request, ReportCreateModel>()

  const handleOnClick = async () => {
    const previousYear = new Date().getFullYear() - 1

    await mutateAsync(
      {
        url: endpoints.getOrCreateReport,
        body: {
          templateId,
          periodStartsAt: `${previousYear}-01-01 00:00:00`,
          periodEndsAt: `${previousYear}-12-31 23:59:59`,
        },
      },
      {
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSuccess: data => {
          history.push({ pathname: reporting, search: `requestId=${data.id}` })
        },
      },
    )
  }

  return (
    <React.Fragment>
      <Box py={2}>
        <Grid container>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <TagIcon tags={tags} />
              <Typography variant="body1">{title}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              {tags[0] &&
                tags[0] !== WfRequestTags.PublicRequest1 &&
                tags[0] !== WfRequestTags.PublicRequest3 &&
                tags[0] !== SfdrRequestTags.Sfdr && (
                  <Chip
                    className={classes.tagChip}
                    label={formatMessage({ id: `transparencyOverview.tags.${tags[0]}` })}
                    size="small"
                  />
                )}
              {reportTemplateState === ReportTemplateState.Recommended && (
                <Chip
                  className={classes.recommendedChip}
                  label={formatMessage({ id: `transparencyOverview.templateRecommended` })}
                  size="small"
                />
              )}
              {reportTemplateState === ReportTemplateState.Reported && (
                <Chip
                  className={classes.reportedChip}
                  label={formatMessage({ id: `transparencyOverview.templateReported` })}
                  size="small"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton loading={isLoading} onClick={handleOnClick} variant="outlined">
                {reportTemplateState === ReportTemplateState.Reported
                  ? formatMessage({ id: `general.open` })
                  : formatMessage({ id: `general.getStarted` })}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isLastTemplate && <Divider />}
    </React.Fragment>
  )
}

export default TemplateRow
