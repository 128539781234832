import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource, useUpdateResource } from '@app/src/api/updateHooks'
import Dialog from '@app/src/components/Dialog'
import { ProviderUpdateType } from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import useOnScreen from '@app/src/hooks/onScreen'
import CategoryForm from '@app/src/pages/Configurations/ConfigurationsPages/Category/CategoryForm'
import { Category, CategoryOption } from '@app/src/types/categories'
import { br } from '@app/src/utils/translationMarkup'
import { NotificationSeverity, ResourceTypes } from '@app/src/wf-constants'
import { DeleteOutlined } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  alpha,
  Button,
  Checkbox,
  ClickAwayListener,
  ClickAwayListenerProps,
  Collapse,
  darken,
  Grow,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { capitalize } from 'lodash'
import React, { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

const useStyles = makeStyles(({ palette, spacing }) => ({
  row: {
    '&:hover': {
      backgroundColor: alpha(palette.primary.main, 0.05),
    },
  },
  paddingLeft: {
    paddingLeft: spacing(1),
  },
  openMenuButton: {
    zIndex: 1,
  },
  menuContainer: {
    zIndex: 2,
  },
  deleteButton: {
    color: palette.error.dark,
    borderColor: palette.error.dark,
    '&:hover': {
      color: darken(palette.error.dark, 0.1),
      borderColor: darken(palette.error.dark, 0.1),
    },
  },
  noPointer: {
    cursor: 'default',
  },
}))

interface CategoryOptionListItemProps {
  categoryOption: CategoryOption
  categoryOptions: CategoryOption[]
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<number | null>>
  containerRef: MutableRefObject<HTMLDivElement | null>
  providersUpdate?: ProviderUpdateType[]
  setProvidersUpdate?: Dispatch<SetStateAction<ProviderUpdateType[]>>
  setIsUnsavedChanges: Dispatch<SetStateAction<boolean>>
}

const CategoryOptionListItem: React.FC<CategoryOptionListItemProps> = ({
  categoryOption,
  editMode,
  setEditMode,
  categoryOptions,
  containerRef,
  providersUpdate,
  setProvidersUpdate,
  setIsUnsavedChanges,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { mutate: updateResource } = useUpdateResource()
  const { mutate: deleteResource } = useDeleteResource()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState(false)
  const listItemRef = useRef<HTMLDivElement | null>(null)
  const checkBoxRef = useRef<HTMLInputElement | null>(null)
  const isOnScreen = useOnScreen<HTMLDivElement, HTMLDivElement>(listItemRef, containerRef, 1)
  const isFormVisible = editMode && !open

  const scrollToItem = (): void => {
    if (isFormVisible && !isOnScreen) {
      listItemRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const updateCategoryOption = ({ name }: Pick<Category, 'name'>) => {
    updateResource(
      {
        url: endpoints.categoryOption,
        body: { name: name.trim(), id: categoryOption.id },
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage(
              { id: 'notifications.successfulCategoryOptionUpdated' },
              { categoryOptionName: name },
            ),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries([FetchKey.CategoryOption, categoryOption.categoryId])
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          setEditMode(null)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClickAway: ClickAwayListenerProps['onClickAway'] = event => {
    if (anchorRef.current && anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }

    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const deleteCategoryOptionItem = () => {
    deleteResource(
      { url: endpoints.categoryOptionById(categoryOption.id), body: {} },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage(
              { id: 'notifications.successfulCategoryOptionDeleted' },
              { categoryOptionName: categoryOption.name },
            ),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries([FetchKey.CategoryOption, categoryOption.categoryId])
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUnsavedChanges(true)
    if (!setProvidersUpdate) return
    if (e.target.checked) {
      setProvidersUpdate(prev =>
        prev.map(p => ({
          ...p,
          categoryOptionIds: !p.categoryOptionIds?.includes(categoryOption.id)
            ? [...(p.categoryOptionIds ?? []), categoryOption.id]
            : p.categoryOptionIds,
        })),
      )
      return
    }
    setProvidersUpdate(prev =>
      prev.map(p => ({
        ...p,
        categoryOptionIds: p.categoryOptionIds?.filter(id => id !== categoryOption.id),
      })),
    )
  }

  const appliedToAll = providersUpdate?.every(row => row.categoryOptionIds?.includes(categoryOption.id)) ?? false
  const appliedToN = providersUpdate?.filter(row => row.categoryOptionIds?.includes(categoryOption.id)).length ?? 0
  const appliedToSome = appliedToN > 0 && appliedToN < (providersUpdate?.length ?? 0)
  const isAssigningCategoryOptions = Boolean(providersUpdate?.length)

  return (
    <>
      <ListItem
        button
        dense
        key={categoryOption.id}
        ref={listItemRef}
        disableGutters
        className={classnames(classes.noPointer, { [classes.row]: !isFormVisible })}
      >
        {editMode && (
          <ListItemText>
            <Collapse in={isFormVisible} onEntered={scrollToItem}>
              {isFormVisible && (
                <CategoryForm
                  onCancel={() => setEditMode(null)}
                  defaultValue={categoryOption.name}
                  placeholder={formatMessage({ id: 'category.editCategoryName' })}
                  onSave={updateCategoryOption}
                  categoryOptions={categoryOptions}
                />
              )}
            </Collapse>
          </ListItemText>
        )}

        {!editMode && (
          <>
            {isAssigningCategoryOptions ? (
              <>
                <ListItemIcon>
                  <Checkbox
                    inputRef={checkBoxRef}
                    indeterminate={appliedToSome}
                    checked={appliedToAll}
                    color="primary"
                    onChange={handleCheckboxChange}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={categoryOption.name}
                  secondary={
                    appliedToSome && (
                      <Typography variant="caption">
                        {formatMessage(
                          { id: 'category.categoryOptions.appliedToSome' },
                          {
                            provider: formatMessage(
                              { id: `general.${capitalize(ResourceTypes.Company)}` },
                              { count: appliedToN },
                            ).toLowerCase(),
                            amount: appliedToN,
                          },
                        )}
                      </Typography>
                    )
                  }
                />
              </>
            ) : (
              <ListItemText className={classes.paddingLeft}>{categoryOption.name}</ListItemText>
            )}

            <IconButton onClick={handleToggle} classes={{ root: classes.openMenuButton }} ref={anchorRef} size="large">
              <MoreHorizIcon />
            </IconButton>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              className={classes.menuContainer}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem>
                          <Button
                            startIcon={<EditIcon />}
                            onClick={() => {
                              handleClose()
                              setEditMode(categoryOption.id)
                            }}
                          >
                            {formatMessage({ id: 'category.edit' })}
                          </Button>
                        </MenuItem>
                        <MenuItem>
                          <Button
                            startIcon={<DeleteOutlined />}
                            onClick={() => {
                              handleClose()
                              openDeleteDialog()
                            }}
                          >
                            {formatMessage({ id: 'category.deleteOption' })}
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
      </ListItem>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        title={formatMessage({ id: 'general.areYouSure' })}
        content={formatMessage({ id: 'category.delete.option' }, { br })}
        buttons={[
          {
            label: formatMessage({ id: 'general.confirm' }),
            variant: 'text',
            classes: { root: classes.deleteButton },
            onClick: (): void => {
              deleteCategoryOptionItem()
              closeDeleteDialog()
            },
          },
          {
            label: formatMessage({ id: 'general.cancel' }),
            variant: 'contained',
            onClick: closeDeleteDialog,
          },
        ]}
      />
    </>
  )
}

export default CategoryOptionListItem
