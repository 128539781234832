import { TableRow } from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewSuggestedContactReview, {
  mapSuggestedContactStatusToSeverity,
} from '@app/src/components/Drawer/Views/DrawerViewSuggestedContactReview'
import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import ChipCell from '@app/src/components/Table/Cells/ChipCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import EmailCell from '@app/src/components/Table/Cells/EmailCell'
import SuggestedContactCell from '@app/src/components/Table/Cells/SuggestedContactCell'
import UserCell from '@app/src/components/Table/Cells/UserCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { SuggestedContact } from '@app/src/types/resourceExplorer'

interface SuggestedContactsRowProps {
  row: SuggestedContact
}

export const SuggestedContactsRow: React.FC<SuggestedContactsRowProps> = ({ row: suggestedContact }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <TableRow>
      <ButtonCell
        variant="contained"
        size="small"
        label={formatMessage({ id: 'general.view' })}
        onClick={() => openDrawer(<DrawerViewSuggestedContactReview contact={suggestedContact} />)}
      />
      <DateCell value={suggestedContact.createdAt} />
      <ChipCell
        value={formatMessage({ id: `suggestedContact.contactStatuses.${suggestedContact.status}` })}
        color={mapSuggestedContactStatusToSeverity(suggestedContact.status)}
        size="small"
      />
      <CompanyCell company={suggestedContact.organization} />
      <EmailCell mail={suggestedContact.email} target="_blank" />
      <SuggestedContactCell contact={suggestedContact} isDisabled />
      {suggestedContact.creatorOrganizationId ? (
        <CompanyCell company={suggestedContact.creatorOrganization} />
      ) : (
        <ValueCell />
      )}
      <UserCell user={suggestedContact.creatorUser} drawer />
    </TableRow>
  )
}

export default SuggestedContactsRow
