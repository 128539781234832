import { Schema } from '@app/src/types/schemas'
import { ConstVars, DefaultImages } from '@app/src/wf-constants'

const OrganizationSchema: Schema = {
  name: 'organization',
  defaultImage: DefaultImages.Organization,
  items: [
    {
      accessor: ConstVars.OrganizationAccessors.Name,
      translationId: 'schemas.organization.name',
      fieldType: 'company',
      width: 0,
      display: {
        table: false,
        details: false,
        export: false,
      },
    },
    {
      accessor: ConstVars.OrganizationAccessors.RegistrationNumber,
      translationId: 'schemas.organization.registrationNumber',
      width: 0,
      display: {
        table: false,
        details: true,
        export: false,
      },
    },
    {
      accessor: ConstVars.OrganizationAccessors.VatNumber,
      translationId: 'schemas.organization.vatNumber',
      width: 0,
      display: {
        table: false,
        details: true,
        export: false,
      },
    },
    {
      accessor: ConstVars.OrganizationAccessors.Country,
      translationId: 'schemas.organization.country',
      fieldType: 'country',
      width: 0,
      display: {
        table: false,
        details: true,
        export: false,
      },
    },
    {
      accessor: ConstVars.OrganizationAccessors.PrimaryAddress,
      translationId: 'schemas.organization.primaryAddress',
      fieldType: 'address',
      width: 0,
      display: {
        table: false,
        details: true,
        export: false,
      },
    },
    {
      accessor: ConstVars.OrganizationAccessors.WebsiteAddress,
      translationId: 'schemas.organization.websiteAddress',
      fieldType: 'link',
      width: 0,
      display: {
        table: false,
        details: true,
        export: false,
      },
    },
  ],
  relatedContentFilters: {},
}

export default OrganizationSchema
