import Permissions, { usePermissions } from '@app/src/auth/permissions'
import HoverDialog from '@app/src/components/HoverDialog'
import LinkButton from '@app/src/components/LinkButton'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { RiskScreeningViews } from '@app/src/pages/ResourceCollection/Collections/RiskScreening/RiskScreeningScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderRiskStatus, ProviderRiskStatusType } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import GraphLegend from '../../GraphLegend'
import { LocationTypes } from './CountryRiskSection'

interface CountryRisksGraphProps {
  selectedLocationType: string
  data: { [riskStatus: string]: number }
  title: React.ReactNode
  hoverTitle: React.ReactNode
  totalProviders: number
}

const CountryRisksGraph: React.FC<CountryRisksGraphProps> = ({
  selectedLocationType,
  data,
  title,
  hoverTitle,
  totalProviders,
}) => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const history = useHistory()
  const [highlightedRisk, setHighlightedRisk] = useState<ProviderRiskStatusType>()
  const { hasPermission } = usePermissions()
  const hasFullDashboardAccess = hasPermission(Permissions.FULL_DASHBOARD_ACCESS)

  const notApplicableCount = totalProviders - data.CLow - data.DMedium - data.EHigh - data.FExtreme

  const getRiskLink = (riskStatus: ProviderRiskStatusType) =>
    stringifyQueryFilters({
      url: generatePath(paths.riskScreening, {
        view:
          selectedLocationType === LocationTypes.HQ ? RiskScreeningViews.Headquarter : RiskScreeningViews.AllLocations,
      }),
      queryParams: {
        filters: [
          {
            name: 'riskStatus',
            value: [riskStatus],
            operator: Operators.In,
          },
        ],
      },
    })

  const options: EChartsOption = {
    series: [
      {
        itemStyle: {
          normal: {
            label: {
              show: false,
            },
            labelLine: {
              show: true,
            },
            borderType: 'solid',
            borderWidth: 1,
            borderColor: 'white',
          },
        },
        type: 'pie',
        radius: ['65%', '100%'],
        color: [palette.semantic.error, palette.error.dark, palette.warning.dark, palette.info.dark, palette.grey[300]],
        data: [
          { value: data.FExtreme, name: ProviderRiskStatus.Extreme },
          { value: data.EHigh, name: ProviderRiskStatus.High },
          { value: data.DMedium, name: ProviderRiskStatus.Medium },
          { value: data.CLow, name: ProviderRiskStatus.Low },
          { value: notApplicableCount, name: ProviderRiskStatus.NotApplicable },
        ],
        emphasis: {
          scale: true,
          scaleSize: 1.2,
          focus: 'self',
        },
      },
    ],
  }

  const events = useMemo(
    () => ({
      mouseover: ({ data }: { data: { name: ProviderRiskStatusType } }) => {
        setHighlightedRisk(data.name)
      },
      click: ({ data }: { data: { name: ProviderRiskStatusType } }) => {
        if (hasFullDashboardAccess) {
          if (data.name === ProviderRiskStatus.NotApplicable) {
            return
          }

          history.push(getRiskLink(data.name))
        }
      },
    }),
    [selectedLocationType],
  )

  return (
    <HoverDialog
      content={
        <Box p={2} onMouseEnter={() => setHighlightedRisk(undefined)}>
          <Stack spacing={1}>
            <Typography variant="overline" noWrap color="text.secondary">
              {hoverTitle}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              borderRadius={2}
              bgcolor={highlightedRisk === ProviderRiskStatus.Extreme ? 'grey.100' : 'white'}
              sx={{
                '&:hover': {
                  bgcolor: 'grey.100',
                  borderRadius: 2,
                  cursor: hasFullDashboardAccess ? 'pointer' : 'default',
                },
              }}
              onMouseEnter={() => setHighlightedRisk(ProviderRiskStatus.Extreme)}
              onMouseLeave={() => setHighlightedRisk(undefined)}
              onClick={hasFullDashboardAccess ? () => history.push(getRiskLink(ProviderRiskStatus.Extreme)) : undefined}
            >
              <GraphLegend
                color={palette.semantic.error}
                variant="body1"
                legend={formatMessage(
                  { id: 'dashboard.sourcing.countryRiskMap.graphsLabels.riskStatusCount' },
                  {
                    status: formatMessage({ id: `schemas.risk.${ProviderRiskStatus.Extreme}` }),
                    count: data.FExtreme ?? 0,
                    percentage: Math.ceil((data.FExtreme * 100) / totalProviders) || 0,
                  },
                )}
              />
              {highlightedRisk === ProviderRiskStatus.Extreme && hasFullDashboardAccess && (
                <LinkButton to={getRiskLink(highlightedRisk)}>
                  {formatMessage({ id: 'dashboard.sourcing.seeAll' })}
                </LinkButton>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              borderRadius={2}
              bgcolor={highlightedRisk === ProviderRiskStatus.High ? 'grey.100' : 'white'}
              sx={{
                '&:hover': {
                  bgcolor: 'grey.100',
                  borderRadius: 2,
                  cursor: hasFullDashboardAccess ? 'pointer' : 'default',
                },
              }}
              onMouseEnter={() => setHighlightedRisk(ProviderRiskStatus.High)}
              onMouseLeave={() => setHighlightedRisk(undefined)}
              onClick={hasFullDashboardAccess ? () => history.push(getRiskLink(ProviderRiskStatus.High)) : undefined}
            >
              <GraphLegend
                color={palette.error.dark}
                variant="body1"
                legend={formatMessage(
                  { id: 'dashboard.sourcing.countryRiskMap.graphsLabels.riskStatusCount' },
                  {
                    status: formatMessage({ id: `schemas.risk.${ProviderRiskStatus.High}` }),
                    count: data.EHigh ?? 0,
                    percentage: Math.ceil((data.EHigh * 100) / totalProviders) || 0,
                  },
                )}
              />
              {highlightedRisk === ProviderRiskStatus.High && hasFullDashboardAccess && (
                <LinkButton to={getRiskLink(highlightedRisk)}>
                  {formatMessage({ id: 'dashboard.sourcing.seeAll' })}
                </LinkButton>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              borderRadius={2}
              bgcolor={highlightedRisk === ProviderRiskStatus.Medium ? 'grey.100' : 'white'}
              sx={{
                '&:hover': {
                  bgcolor: 'grey.100',
                  borderRadius: 2,
                  cursor: hasFullDashboardAccess ? 'pointer' : 'default',
                },
              }}
              onMouseEnter={() => setHighlightedRisk(ProviderRiskStatus.Medium)}
              onMouseLeave={() => setHighlightedRisk(undefined)}
              onClick={hasFullDashboardAccess ? () => history.push(getRiskLink(ProviderRiskStatus.Medium)) : undefined}
            >
              <GraphLegend
                color={palette.warning.dark}
                variant="body1"
                legend={formatMessage(
                  { id: 'dashboard.sourcing.countryRiskMap.graphsLabels.riskStatusCount' },
                  {
                    status: formatMessage({ id: `schemas.risk.${ProviderRiskStatus.Medium}` }),
                    count: data.DMedium ?? 0,
                    percentage: Math.ceil((data.DMedium * 100) / totalProviders) || 0,
                  },
                )}
              />
              {highlightedRisk === ProviderRiskStatus.Medium && hasFullDashboardAccess && (
                <LinkButton to={getRiskLink(highlightedRisk)}>
                  {formatMessage({ id: 'dashboard.sourcing.seeAll' })}
                </LinkButton>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              borderRadius={2}
              bgcolor={highlightedRisk === ProviderRiskStatus.Low ? 'grey.100' : 'white'}
              sx={{
                '&:hover': {
                  bgcolor: 'grey.100',
                  borderRadius: 2,
                  cursor: hasFullDashboardAccess ? 'pointer' : 'default',
                },
              }}
              onMouseEnter={() => setHighlightedRisk(ProviderRiskStatus.Low)}
              onMouseLeave={() => setHighlightedRisk(undefined)}
              onClick={hasFullDashboardAccess ? () => history.push(getRiskLink(ProviderRiskStatus.Low)) : undefined}
            >
              <GraphLegend
                color={palette.info.dark}
                variant="body1"
                legend={formatMessage(
                  { id: 'dashboard.sourcing.countryRiskMap.graphsLabels.riskStatusCount' },
                  {
                    status: formatMessage({ id: `schemas.risk.${ProviderRiskStatus.Low}` }),
                    count: data.CLow ?? 0,
                    percentage: Math.ceil((data.CLow * 100) / totalProviders) || 0,
                  },
                )}
              />
              {highlightedRisk === ProviderRiskStatus.Low && hasFullDashboardAccess && (
                <LinkButton to={getRiskLink(highlightedRisk)}>
                  {formatMessage({ id: 'dashboard.sourcing.seeAll' })}
                </LinkButton>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              borderRadius={2}
              bgcolor={highlightedRisk === ProviderRiskStatus.NotApplicable ? 'grey.100' : 'white'}
              sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'default' } }}
              onMouseEnter={() => setHighlightedRisk(ProviderRiskStatus.NotApplicable)}
              onMouseLeave={() => setHighlightedRisk(undefined)}
            >
              <GraphLegend
                color={palette.grey[300]}
                variant="body1"
                legend={formatMessage(
                  { id: 'dashboard.sourcing.countryRiskMap.graphsLabels.riskStatusCount' },
                  {
                    status: formatMessage({ id: 'schemas.risk.notApplicable' }),
                    count: notApplicableCount || 0,
                    percentage: Math.ceil((notApplicableCount * 100) / totalProviders) || 0,
                  },
                )}
              />
            </Stack>
          </Stack>
        </Box>
      }
    >
      <Box position="relative" display="flex" justifyContent="center" alignItems="center">
        <ReactEcharts option={options} onEvents={events} style={{ height: 200, width: 200 }} />
        <Box display="flex" position="absolute">
          <Typography variant="subtitle1" textAlign="center" maxWidth={75} lineHeight="2.5rem">
            {title}
          </Typography>
        </Box>
      </Box>
    </HoverDialog>
  )
}

export default CountryRisksGraph
