import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

import Permissions, { usePermissions } from '@app/src/auth/permissions'
import DateDisplay from '@app/src/components/DateDisplay'
import {
  ExternalVerificationStatus,
  HistoryStatus,
  RequestHistory,
  ResponseDraftStatus,
} from '@app/src/types/resourceExplorer'
import { lowercaseFirstCharacter } from '@app/src/utils/helpersTs'
import { useIntl } from 'react-intl'
import ResponseStatusAccessor from './Accessor/ResponseStatus'
import ResponseStatusProvider from './Provider/ResponseStatus'
import { useReport } from './ReportContext'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  missingOppositeContent: {
    '&:before': {
      display: 'none',
    },
  },
  timelineContent: {
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0.5, 1),
    padding: 0,
  },
  button: {
    padding: theme.spacing(1, 2),
    borderRadius: 8,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  [HistoryStatus.Draft]: {},
  [HistoryStatus.Approved]: {
    borderColor: theme.palette.success.main,
  },
  [HistoryStatus.CorrectionNeeded]: {
    borderColor: theme.palette.warning.main,
  },
  [HistoryStatus.Submitted]: {
    borderColor: theme.palette.info.main,
  },
}))

const HistoryLog = (): JSX.Element | null => {
  const classes = useStyles()
  const { requestHistory, isResponseLoading, responseId, updateResponseIdParam } = useReport()
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()

  const handleLogClick = (history: RequestHistory) => {
    updateResponseIdParam(history.responseId)
  }

  const historyStatusToProviderVerificationStatus = (historyStatus: HistoryStatus) => {
    let responseStatus
    let draftStatus

    switch (historyStatus) {
      case HistoryStatus.Approved:
        responseStatus = ExternalVerificationStatus.Approved
        break
      case HistoryStatus.CorrectionNeeded:
        responseStatus = ExternalVerificationStatus.CorrectionNeeded
        break
      case HistoryStatus.Draft:
        draftStatus = ResponseDraftStatus.Draft
        break
      case HistoryStatus.Submitted:
        draftStatus = ResponseDraftStatus.Submitted
        break
      default:
        responseStatus = ExternalVerificationStatus.NotSet
    }

    return {
      responseStatus,
      draftStatus,
    }
  }

  if (!requestHistory?.length) {
    return null
  }

  return (
    <Box>
      <Timeline classes={{ root: classes.root }}>
        {requestHistory?.map(history => (
          <TimelineItem key={history.id} classes={{ missingOppositeContent: classes.missingOppositeContent }}>
            <TimelineSeparator>
              <TimelineDot variant="outlined" className={classes[history.status]} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <Button
                onClick={() => handleLogClick(history)}
                disabled={Boolean(isResponseLoading)}
                fullWidth
                className={classes.button}
              >
                <Box display="flex" flexDirection="column" alignItems="flex-start" flexGrow={1}>
                  {Boolean(history.responseId === responseId && isResponseLoading) && (
                    <Box
                      position="absolute"
                      top={0}
                      bottom={0}
                      left={0}
                      right={0}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress size={14} />
                    </Box>
                  )}
                  {hasPermission(Permissions.TRANSPARENCY_USER) ? (
                    <ResponseStatusProvider {...historyStatusToProviderVerificationStatus(history.status)} />
                  ) : (
                    <ResponseStatusAccessor {...historyStatusToProviderVerificationStatus(history.status)} />
                  )}
                  <Typography variant="caption" align="left" mt={1}>
                    <DateDisplay value={history.createdAt} withTime />
                  </Typography>
                  <Typography variant="caption" align="left" mt={1}>
                    {formatMessage(
                      {
                        id: `questionnaire.historyLogUserAction.${lowercaseFirstCharacter(history.status)}`,
                      },
                      { b: (chunks: React.ReactNode) => <b>{chunks}</b>, name: history.creatorUser.name },
                    )}
                  </Typography>
                </Box>
              </Button>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  )
}

export default HistoryLog
