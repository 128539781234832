import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import Dialog from '@app/src/components/Dialog'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import EmptyState from '@app/src/components/EmptyState'
import CategoryDrawer from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawer'
import { CategoryDrawerViews } from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import CategoryCard from '@app/src/pages/Configurations/ConfigurationsPages/Category/CategoryCard'
import { Category } from '@app/src/types/categories'
import { br } from '@app/src/utils/translationMarkup'
import { CreationLimits } from '@app/src/wf-constants'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import React from 'react'
import { useIntl } from 'react-intl'

const CategoriesConfig: React.FC = () => {
  const [isDrawerOpen, openDrawer, closeDrawer] = useDrawer_DEPRECATED(false)
  const [isDialogOpen, openDialog, closeDialog] = useDialogState(false)
  const { formatMessage } = useIntl()
  const { data: categories } = useFetchResource<Category[]>({
    key: FetchKey.Category,
    endpoint: endpoints.category,
  })

  const handleClick = (): void => {
    if (categories && categories.length >= CreationLimits.Categories) {
      openDialog()
      return
    }
    openDrawer()
  }

  return (
    <ConfigurationCollection
      actionButtons={[
        {
          label: formatMessage({ id: 'category.create' }),
          variant: 'contained',
          startIcon: <AddIcon />,
          onClick: handleClick,
        },
      ]}
      enablePadding={{ right: true }}
    >
      {categories?.length ? (
        categories?.map(category => <CategoryCard key={category.id} category={category} />)
      ) : (
        <EmptyState
          iconComponent={SearchIcon}
          title={formatMessage({ id: 'category.emptyState.title' })}
          description={formatMessage({ id: 'category.emptyState.description' })}
        />
      )}
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        title={formatMessage({ id: 'category.limitExceeded' })}
        content={formatMessage({ id: 'category.limitExplanation' }, { categoryLimit: CreationLimits.Categories, br })}
        buttons={[
          {
            label: formatMessage({ id: 'general.close' }),
            variant: 'outlined',
            onClick: closeDialog,
          },
        ]}
      />
      <CategoryDrawer
        categories={categories}
        view={CategoryDrawerViews.CreateCategory}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
      />
    </ConfigurationCollection>
  )
}
export default CategoriesConfig
