import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import Markdown from '@app/src/components/Markdown'
import { MoveAndDeleteControls } from '@app/src/pages/TemplateBuilder/MoveAndDeleteControls'
import QuestionInputPreview from '@app/src/pages/TemplateBuilder/QuestionInputPreview'
import { Question, QuestionInTemplate } from '@app/src/types/resourceExplorer'
import { HelpOutlineOutlined, InfoOutlined } from '@mui/icons-material'
import { Box, FormControlLabel, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/styles'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { FormDataItem } from './TemplateBuilder'

type EditRequestItemProps = {
  item: Partial<FormDataItem>
  questionFromTemplate?: QuestionInTemplate
  sectionIndex: number
  requestItemIndex: number
  removeItem: () => void
  moveUp: () => void
  moveDown: () => void
  questionCount: number
}

const EditQuestionInTemplate: React.FC<EditRequestItemProps> = ({
  item,
  questionFromTemplate,
  requestItemIndex,
  sectionIndex,
  removeItem,
  moveUp,
  moveDown,
  questionCount,
}) => {
  const { register, control } = useFormContext()
  const { formatMessage } = useIntl()
  const { palette } = useTheme()

  const target = `sections[${sectionIndex}].questions[${requestItemIndex}]`

  const { data: fetchedQuestion, isFetching } = useFetchResourceWithPost<Question>({
    endpoint: endpoints.questionById(item.questionId),
    key: [FetchKey.Questions, item.questionId],
    body: ['questionType', 'unit', 'requestCollection'],
    options: {
      enabled: Boolean(item.questionId) && !questionFromTemplate,
    },
  })

  const questionToShow = questionFromTemplate ?? fetchedQuestion

  return (
    <>
      <Box py={4} px={6} border={`1px solid ${palette.grey['300']}`} borderRadius="8px" mb={4}>
        <input type="hidden" name={`${target}.questionId`} defaultValue={item?.questionId ?? ''} ref={register()} />

        <Grid container data-testid="question-in-template-builder">
          <Grid item xs={12} display="flex" justifyContent="space-between" mb={3}>
            {questionToShow?.guidance ? (
              <Tooltip title={<Markdown>{questionToShow.guidance.toString()}</Markdown>}>
                <Box display="flex" alignItems="center">
                  <HelpOutlineOutlined fontSize="medium" />
                </Box>
              </Tooltip>
            ) : (
              <Box />
            )}
            <MoveAndDeleteControls
              onDelete={removeItem}
              moveUp={moveUp}
              moveDown={moveDown}
              index={requestItemIndex}
              totalCount={questionCount}
              typeName={formatMessage({ id: 'templateBuilder.fields.controls.type.question' })}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">
              {sectionIndex + 1}.{requestItemIndex + 1}.
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle1">{questionToShow?.questionText}</Typography>
          </Grid>

          <Grid item xs={12} mb={2}>
            {isFetching ? <CircularProgress /> : <QuestionInputPreview question={questionToShow} />}
          </Grid>

          <Grid item xs={12}>
            <Controller
              name={`${target}.isRequired`}
              control={control}
              defaultValue={item.isRequired ?? false}
              render={({ onChange, value }): JSX.Element => (
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={value}
                      data-testid="switch-button"
                      onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onChange(checked)}
                    />
                  }
                  label={
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={({ palette }) => ({
                        color: palette.grey[700],
                      })}
                    >
                      <Typography>{formatMessage({ id: 'templateBuilder.mandatoryQuestion' })}</Typography>
                      <InfoOutlined color="inherit" />
                    </Stack>
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EditQuestionInTemplate
