import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import CustomFlagDrawerContent from '@app/src/components/Flags/CustomFlagDrawerContent'
import SuggestedRiskGuidanceDrawerContent from '@app/src/components/Flags/SuggestedRiskGuidanceDrawerContent'
import React from 'react'
import { useIntl } from 'react-intl'
import { ReportFlagRule } from '@app/src/types/flags'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'

type DrawerViewFlagRuleProps = {
  flagRules?: ReportFlagRule[]
} & Omit<DrawerViewProps, 'title' | 'subtitle'>

const DrawerViewFlagRule: React.FC<DrawerViewFlagRuleProps> = ({ flagRules, ...props }) => {
  const { formatMessage } = useIntl()

  const isWorldfavorRule = flagRules?.some(flagRule => flagRule.creatorOrganizationId === WF_ORGANIZATION_ID)

  return (
    <DrawerView
      title={
        isWorldfavorRule
          ? formatMessage({ id: 'automations.suggestedRiskIdentified' })
          : formatMessage({ id: 'automations.flagIdentified' })
      }
      {...props}
    >
      {isWorldfavorRule && flagRules?.[0]?.suggestedRiskGuidance ? (
        <SuggestedRiskGuidanceDrawerContent suggestedRiskGuidance={flagRules?.[0]?.suggestedRiskGuidance} />
      ) : (
        <CustomFlagDrawerContent flagRules={flagRules} />
      )}
    </DrawerView>
  )
}

export default DrawerViewFlagRule
