import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactNode } from 'react'

const useStyles = makeStyles(({ wfShadows }) => ({
  tooltip: {
    backgroundColor: '#FFF',
    color: '#000',
    boxShadow: wfShadows[5],
  },
  popper: ({ width }: Pick<HoverDialogProps, 'width'>) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      width,
      maxWidth: width,
    },
  }),
}))

interface HoverDialogProps {
  content?: ReactNode
  children: JSX.Element
  width?: number
  positionOffset?: [number, number]
  placement?: TooltipProps['placement']
}

const HoverDialog: React.FC<HoverDialogProps> = ({
  content,
  children,
  width = 400,
  positionOffset = [0, 0],
  placement = 'bottom',
}) => {
  const classes = useStyles({ width })
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, popper: classes.popper }}
      title={content}
      placement={placement}
      enterDelay={300}
      enterNextDelay={300}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: positionOffset,
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  )
}

export default HoverDialog
