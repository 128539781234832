import InfoIconMarkdownCell from '@app/src/components/Table/Cells/InfoIconMarkdownCell'
import QuestionLinkCell from '@app/src/components/Table/Cells/QuestionLinkCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import QuestionCategoryCell from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/QuestionCategoryCell'
import QuestionCheckboxCell, {
  QuestionCheckboxCellProps,
} from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/QuestionCheckboxCell'
import { Question } from '@app/src/types/resourceExplorer'
import { BASIC_QUESTION_CATEGORIES } from '@app/src/wf-constants/questionCategories'
import { TableRow } from '@mui/material'
import React from 'react'

interface StandardQuestionRowProps {
  question: Question
  selectionProps?: Omit<QuestionCheckboxCellProps, 'question'>
}

const StandardQuestionRow: React.FC<StandardQuestionRowProps> = ({ question, selectionProps }) => (
  <TableRow>
    {selectionProps ? (
      <>
        <QuestionCheckboxCell question={question} {...selectionProps} />
        <ValueCell value={question.questionText} maxRows={1} />
      </>
    ) : (
      <QuestionLinkCell question={question} />
    )}
    <InfoIconMarkdownCell value={question.guidance} />
    {BASIC_QUESTION_CATEGORIES.map(category => (
      <QuestionCategoryCell
        key={category.name}
        categorizations={question.categorizations}
        categoryName={category.name}
      />
    ))}
  </TableRow>
)

export default StandardQuestionRow
