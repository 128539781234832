import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { PeriodDetails } from '@app/src/types/resourceExplorer'

type ProviderProgressCellProps = {
  periodDetail?: PeriodDetails
}

const ProviderProgressCell: React.FC<ProviderProgressCellProps> = ({ periodDetail }) => (
  <ValueCell value={periodDetail?.collectionResponse ?? periodDetail?.answer ?? '-'} maxRows={2} />
)

export default ProviderProgressCell
