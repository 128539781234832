import { Schema } from '@app/src/types/schemas'

const ProductSchema: Schema = {
  name: 'product',
  items: [
    {
      accessor: '*',
      translationId: 'general.id',
      width: undefined,
      display: {
        details: false,
        table: false,
        export: true,
      },
      exportPath: 'id',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.providerId',
      width: undefined,
      display: {
        details: false,
        table: false,
        export: true,
      },
      exportPath: 'providerId',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.name',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'name',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.gtin',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'gtin',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.productNumber',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'productNumber',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.productCustomId',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'productCustomId',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.purchaseOrder',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'purchaseOrder',
    },
    {
      accessor: '*',
      translationId: 'schemas.categorization.supplier',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'provider.name',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.countryOfOrigin',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'country.name',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.productStatus',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'productStatus',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.mappingStatus',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'mappingStatus',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.createdAt',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'createdAt',
    },
    {
      accessor: '*',
      translationId: 'schemas.product.updatedAt',
      width: 250,
      display: {
        details: false,
        table: true,
        export: true,
      },
      exportPath: 'updatedAt',
    },
  ],
  relatedContentFilters: {},
}

export default ProductSchema
