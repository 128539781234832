import CheckIcon from '@mui/icons-material/Check'
import { alpha, Box, Button, Grid, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import LeftBackBlob from './LeftBackBlob'
import LeftFrontBlob from './LeftFrontBlob'
import RightBackBlob from './RightBackBlob'
import RightFrontBlob from './RightFrontBlob'

const useStyles = makeStyles(({ palette }) => ({
  getStartedButton: {
    backgroundColor: palette.common.white,
    color: palette.common.black,
    '&:hover': {
      backgroundColor: palette.grey[200],
    },
  },
}))

type SFDRStarterGuideProps = {
  hasInvestees: boolean
  hasReferralCode: boolean
}

interface StepProps {
  label: string
  step?: number
  isLastStep?: boolean
}

const Step: React.FC<StepProps> = ({ label, step, isLastStep }) => {
  const { palette } = useTheme()

  return (
    <Box>
      <Box display="flex" color="common.white" alignItems="center" mb={2}>
        <Box
          borderRadius="100%"
          minHeight={35}
          minWidth={35}
          bgcolor={alpha(palette.grey[100], 0.2)}
          color="inherit"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{step || <CheckIcon />}</Typography>
        </Box>
        {!isLastStep && <Box height="1px" width="100%" ml={1} bgcolor={alpha(palette.grey[100], 0.2)} />}
      </Box>
      <Box maxWidth={280}>
        <Typography>{label}</Typography>
      </Box>
    </Box>
  )
}

const SFDRStarterGuide: React.FC<SFDRStarterGuideProps> = ({ hasInvestees, hasReferralCode }) => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const { getStartedButton } = useStyles()
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useDialogState()
  const [showGuidance, setShowGuidance] = useState(true)

  if (hasInvestees || hasReferralCode || localStorage.getItem('sfdr:guidance') === 'false' || !showGuidance) {
    return null
  }

  return (
    <Box
      mx={4}
      my={2}
      p={2}
      bgcolor={palette.secondary.main}
      borderRadius="8px"
      height={475}
      position="relative"
      overflow="hidden"
    >
      <Box textAlign="right" color="common.white">
        <Button
          color="inherit"
          onClick={() => {
            setShowGuidance(false)
            localStorage.setItem('sfdr:guidance', 'false')
          }}
        >
          {formatMessage({ id: 'general.close' })}
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" height="calc(100% - 80px)">
        <Box mx={8} color="common.white" maxWidth={950}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h1">{formatMessage({ id: 'form.createRequest.sfdr.guide.title' })}</Typography>
          </Box>
          <Box mt={7} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Step label={formatMessage({ id: 'form.createRequest.sfdr.guide.stepOne' })} step={1} />
              </Grid>
              <Grid item xs={3}>
                <Step label={formatMessage({ id: 'form.createRequest.sfdr.guide.stepTwo' })} step={2} />
              </Grid>
              <Grid item xs={3}>
                <Step label={formatMessage({ id: 'form.createRequest.sfdr.guide.stepThree' })} step={3} />
              </Grid>
              <Grid item xs={3}>
                <Step label={formatMessage({ id: 'form.createRequest.sfdr.guide.stepFour' })} isLastStep />
              </Grid>
            </Grid>
          </Box>
          <Button variant="contained" className={getStartedButton} onClick={openCreateDialog}>
            {formatMessage({ id: 'general.getStarted' })}
          </Button>
        </Box>
      </Box>

      <Box position="absolute" left={0} bottom={-8} borderRadius="8px">
        <LeftBackBlob />
      </Box>
      <Box position="absolute" left={200} bottom={-8} borderRadius="8px">
        <LeftFrontBlob />
      </Box>
      <Box position="absolute" right={0} bottom={-35} borderRadius="8px">
        <RightBackBlob />
      </Box>
      <Box position="absolute" right={150} bottom={-8} borderRadius="8px">
        <RightFrontBlob />
      </Box>

      <CreationModalProgressContextProvider>
        <CreateNewRequestModal
          open={isCreateDialogOpen}
          onClose={closeCreateDialog}
          reportType={ReportType.SFDR_WITHOUT_COMPANIES}
          disableInitialBack
        />
      </CreationModalProgressContextProvider>
    </Box>
  )
}

export default SFDRStarterGuide
