import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import { DescriptionOutlined, ExpandMoreOutlined, NotificationsActiveOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  alpha,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { GroupedRequest } from '../../SentMappingRequestCollection'
import { GroupedInquiries } from '../AccessorRequestsScene'
import { REQUESTS_REMINDER_QUESTION_KEYS } from './RequestsReminderModal'

type RequestsReminderListProps = {
  groupedRequests: Array<GroupedInquiries | GroupedRequest>
  currentlySelectedGroups: Array<GroupedInquiries | GroupedRequest>
} & Omit<AccordionProps, 'onChange' | 'children'>

function RequestsReminderList({ groupedRequests, currentlySelectedGroups, ...props }: RequestsReminderListProps) {
  const { setValue, watch } = useFormContext()
  const { formatMessage } = useIntl()

  const watchRequests = watch(REQUESTS_REMINDER_QUESTION_KEYS.REQUESTS_IDS)

  function handleGroupClick(providerId: number) {
    const providersInquiriesIds =
      groupedRequests.find(gi => gi.responder.id === providerId)?.requests?.map(i => i.id) || []
    const newInquiryIds = watchRequests.filter((id: number) => !providersInquiriesIds.includes(id))

    setValue(
      REQUESTS_REMINDER_QUESTION_KEYS.REQUESTS_IDS,
      newInquiryIds.length === watchRequests.length ? [...watchRequests, ...providersInquiriesIds] : newInquiryIds,
    )
  }

  return (
    <Accordion
      elevation={0}
      disableGutters
      {...props}
      sx={[{ bgcolor: 'grey.100', '&:before': { position: 'unset' } }, ...[props.sx ?? []].flat()]}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlined fontSize="medium" sx={{ mx: 1 }} />} sx={{ py: 1 }}>
        <Stack direction="row">
          <NotificationsActiveOutlined />
          <Typography variant="body1" ml={3}>
            {formatMessage(
              { id: 'form.requestsReminder.reminderListTitle' },
              { companyCount: currentlySelectedGroups.length },
            )}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List disablePadding>
          {groupedRequests.map(gi => {
            const providersTemplateIds = gi.requests.map(i => i.id)
            return (
              <ListItem
                key={gi.responder.id}
                sx={{
                  justifyContent: 'space-between',
                  borderTop: `1px solid ${alpha('#000', 0.12)}`,
                }}
                disablePadding
              >
                <ListItemButton
                  selected={watchRequests.some((id: number) => providersTemplateIds.includes(id))}
                  onClick={() => handleGroupClick(gi.responder.id)}
                >
                  <Stack direction="row" alignItems="center" gap={1} width="60%">
                    <Checkbox
                      edge="start"
                      checked={watchRequests.some((id: number) => providersTemplateIds.includes(id))}
                      onChange={() => handleGroupClick(gi.responder.id)}
                    />
                    <CompanyCell company={gi.responder} disableCell data-testid="accordion-company-name" />
                  </Stack>

                  <Stack direction="row" width="40%">
                    <DescriptionOutlined />
                    <Typography ml={1}>
                      {formatMessage(
                        { id: 'form.requestsReminder.reminderInquiryCount' },
                        { inquiryCount: gi.requests.length },
                      )}
                    </Typography>
                  </Stack>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default RequestsReminderList
