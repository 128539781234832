import React from 'react'

import { Add } from '@mui/icons-material'
import { Box, Button, Grid } from '@mui/material'
import TextField from '@app/src/components/Ui/TextField'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import EditSection from './EditSection'
import { FormDataSection } from './TemplateBuilder'

type EditTemplateContentProps = {
  template?: QuestionnaireTemplate
}

const EditTemplateContent: React.FC<EditTemplateContentProps> = ({ template }) => {
  const { formatMessage } = useIntl()
  const { control, register, errors } = useFormContext()
  const { fields, append, remove, move } = useFieldArray<FormDataSection>({
    control,
    name: 'sections',
  })

  return (
    <Box mb={3} pr={5} flexGrow={1}>
      <Grid container justifyContent="center" mt={8}>
        <Grid container item spacing={2} xs={6} py={3} px={5} borderRadius={2}>
          <Grid item xs={12}>
            <TextField
              hoveringLabel
              fullWidth
              name="title"
              defaultValue={template?.title}
              placeholder={formatMessage({ id: 'templateBuilder.fields.questionnaireName.placeholder' })}
              label={formatMessage({ id: 'templateBuilder.fields.questionnaireName.title' })}
              size="small"
              error={Boolean(errors?.title)}
              helperText={errors?.title?.message}
              inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
              InputLabelProps={{ required: true }}
              inputProps={{
                maxLength: 500,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              hoveringLabel
              fullWidth
              name="description"
              defaultValue={template?.description}
              label={formatMessage({ id: 'templateBuilder.fields.questionnaireDescription.title' })}
              placeholder={formatMessage({ id: 'templateBuilder.fields.questionnaireDescription.placeholder' })}
              size="small"
              inputRef={register}
              multiline
              minRows={4}
              maxRows={8}
            />
          </Grid>

          {fields?.map((section, index) => (
            <Grid item xs={12} key={section.id}>
              <EditSection
                section={section}
                templateSection={template?.sections?.find(t => t.id === Number.parseInt(section.sectionId ?? '0'))}
                index={index}
                removeSection={() => remove(index)}
                moveUp={() => move(index, index - 1)}
                moveDown={() => move(index, index + 1)}
                sectionCount={fields.length}
                templateTitle={template?.title}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Box bgcolor="common.white" py={5} px={6} textAlign="center" borderRadius={2}>
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() =>
                  append({
                    questions: [],
                    description: '',
                    title: '',
                    sectionId: '0',
                  })
                }
                data-testid="add-section"
              >
                {formatMessage({ id: 'templateBuilder.addSection' })}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditTemplateContent
