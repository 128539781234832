import { Alert, Box, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResourceWithoutBody } from '@app/src/api/updateHooks'
import Dialog from '@app/src/components/Dialog'
import { useAccount } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { QuestionnaireTemplate, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'

interface PublishUnpublishDialogProps {
  template?: QuestionnaireTemplate
  isDirty: boolean
  isPublishDialogOpen: boolean
  closePublishDialog: () => void
  saveTemplate: () => Promise<void>
}

const PublishUnpublishDialog: React.FC<PublishUnpublishDialogProps> = ({
  template,
  isDirty,
  isPublishDialogOpen,
  closePublishDialog,
  saveTemplate,
}) => {
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { account } = useAccount()
  const { watch } = useFormContext()
  const { showErrorNotification } = useErrorNotification()
  const { mutateAsync: publishUnpublishAsync, isLoading: isLoadingPublishUnpublish } =
    useCreateResourceWithoutBody<QuestionnaireTemplate>()

  const questionnaireType: QuestionnaireTypeEnum = watch('questionnaireTemplateType')
  const sharedOrganizationIds: number[] | undefined = watch('sharedOrganizationIds')
  const templateName: string | undefined = watch('title')
  const sharedOrganizationIdsWithoutCreator = sharedOrganizationIds?.filter(
    id => id !== template?.creatorOrganizationId,
  )

  const handlePublish = async () => {
    if (isDirty) await saveTemplate()

    const endpoint = template?.isPublished
      ? endpoints.unpublishTemplate(template.id)
      : endpoints.publishTemplate(template?.id)

    await publishUnpublishAsync(
      {
        url: endpoint,
      },
      {
        onSuccess: async () => {
          showSnackbar({
            message: formatMessage(
              {
                id: template?.isPublished
                  ? 'templateBuilder.successfullyUnpublished'
                  : 'templateBuilder.successfullyPublished',
              },
              { b: (chunks: React.ReactNode) => <b>{chunks}</b>, templateName: templateName },
            ),
            severity: 'success',
          })

          await queryClient.invalidateQueries(FetchKey.OrganizationOwnedTemplates)
          await queryClient.invalidateQueries([FetchKey.OrganizationOwnedTemplates, template?.id])

          closePublishDialog()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return (
    <Dialog
      open={isPublishDialogOpen}
      onClose={closePublishDialog}
      title={formatMessage({
        id: template?.isPublished ? 'templateBuilder.unpublishHeader' : 'templateBuilder.publishHeader',
      })}
      content={
        template?.isPublished ? (
          <>
            {formatMessage({ id: 'templateBuilder.unpublishDialogContent' }, { br })}
            <Alert severity="info" variant="filled" sx={{ mt: 4 }}>
              {formatMessage({ id: 'templateBuilder.unpublishDialogAlert' })}
            </Alert>
          </>
        ) : (
          <>
            {questionnaireType === QuestionnaireTypeEnum.Shared && (
              <Typography mt={1}>
                {formatMessage(
                  { id: 'templateBuilder.publishDialogOrganizations' },
                  {
                    organizationName: account?.organization?.name,
                    count: sharedOrganizationIdsWithoutCreator?.length ?? 0,
                  },
                )}
              </Typography>
            )}

            <Typography mt={3}>{formatMessage({ id: 'templateBuilder.publishDialogInfo.title' })}</Typography>
            <Box component="ul" my={0} pl={3}>
              <Box component="li">
                {formatMessage(
                  { id: 'templateBuilder.publishDialogInfo.controlPanel' },
                  { regular: questionnaireType === QuestionnaireTypeEnum.Regular },
                )}
              </Box>
              <Box component="li">
                {formatMessage(
                  { id: 'templateBuilder.publishDialogInfo.access' },
                  { regular: questionnaireType === QuestionnaireTypeEnum.Regular },
                )}
              </Box>
            </Box>

            <Alert severity="info" variant="filled" sx={{ mt: 4 }}>
              {formatMessage(
                { id: 'templateBuilder.publishDialogInfo.alert' },
                { regular: questionnaireType === QuestionnaireTypeEnum.Regular },
              )}
            </Alert>
          </>
        )
      }
      buttons={[
        {
          label: formatMessage({
            id: template?.isPublished ? 'templateBuilder.unpublishSubmit' : 'templateBuilder.publishSubmit',
          }),
          variant: 'contained',
          onClick: handlePublish,
          disabled: isLoadingPublishUnpublish,
          loading: isLoadingPublishUnpublish,
          fullWidth: true,
        },
        {
          label: formatMessage({ id: 'general.cancel' }),
          variant: 'text',
          onClick: closePublishDialog,
          disabled: isLoadingPublishUnpublish,
          fullWidth: true,
        },
      ]}
      buttonsSx={{ flexDirection: 'column', gap: 2, px: 3, pb: 3 }}
    />
  )
}

export default PublishUnpublishDialog
