import Permissions from '@app/src/auth/permissions'
import useConfigurationFilter from '@app/src/hooks/configurationFilter'
import { ConfigItems } from '@app/src/pages/Configurations/ConfigurationsScene'
import SustainabilityLibraryContent from '@app/src/pages/Configurations/SustainabilityLibraryContent'
import { Box } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import ConfigurationMenu from './ConfigurationMenu'

export interface SustainabilityLibraryParams {
  configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS
  configurationsSubPage: string
  activeTabParam: string
  activeTabSubId: string
}

export enum SUSTAINABILITY_LIBRARY_PAGE_IDS {
  Questionnaires = 'questionnaires',
  Questions = 'questions',
  Assessments = 'assessments',
}

const SUSTAINABILITY_LIBRARY_ITEMS: ConfigItems = [
  {
    configId: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questionnaires,
  },
  {
    configId: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questions,
  },
  {
    configId: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
    featureToggleName: 'assessments',
    permissions: [Permissions.WF_EMAIL_ACCESS],
  },
]

const SustainabilityLibraryScene: React.FC = () => {
  const { formatMessage } = useIntl()
  const { path } = useRouteMatch()
  const { configurationsPage, configurationsSubPage, activeTabParam, activeTabSubId } =
    useParams<SustainabilityLibraryParams>()

  const filteredConfigItems = useConfigurationFilter(SUSTAINABILITY_LIBRARY_ITEMS)

  if (!configurationsPage) {
    return (
      <Redirect
        to={generatePath(path, {
          configurationsPage: filteredConfigItems[0].configId,
        })}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'configurations.sustainabilityLibrary' })}</title>
      </Helmet>

      <Box pt={2} flexGrow={1} display="flex">
        <ConfigurationMenu configItems={filteredConfigItems} />

        <Switch>
          <Route
            path={decodeURIComponent(
              generatePath(path, {
                configurationsPage: ':configurationsPage?',
                configurationsSubPage: ':configurationsSubPage?',
                activeTabParam: ':activeTabParam?',
                activeTabSubId: ':activeTabSubId?',
              }),
            )}
          >
            <SustainabilityLibraryContent
              content={configurationsPage}
              configurationsSubPage={configurationsSubPage}
              activeTabParam={activeTabParam}
              activeTabSubId={activeTabSubId}
            />
          </Route>
        </Switch>
      </Box>
    </>
  )
}

export default SustainabilityLibraryScene
