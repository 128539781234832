import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { ContactPersonInfo } from '@app/src/pages/ResourceCollection/Collections/Provider/ConnectProviders/ConnectProvidersModal'
import { useQueryClient } from 'react-query'

export type ConnectProvidersFormData = {
  providerIds: number[]
  customMessage?: string
  contactPersonInfo?: ContactPersonInfo
}

export const useSendInviteEmail = () => {
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { mutateAsync: sendBulkInvite, isLoading: isSendingInvite } = useCreateResource<ConnectProvidersFormData>()

  const sendInvite = async (formData: ConnectProvidersFormData, onSuccess: () => void) => {
    await sendBulkInvite(
      {
        url: endpoints.sendBulkInviteToReferralContacts,
        body: formData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          onSuccess()
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return { sendInvite, isSendingInvite }
}
