import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import ListItemSkeleton from '@app/src/components/Skeleton/ListItem'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import useProviderType from '@app/src/hooks/useProviderType'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AllTabs } from '@app/src/pages/ResourceExplorer/Content'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { ChevronRightOutlined, MarkEmailUnreadOutlined } from '@mui/icons-material'
import { ListItem, ListItemAvatar, ListItemButton, ListItemText, alpha } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link, generatePath } from 'react-router-dom'

type RequestsOverviewProps = {
  providerId: number
}

const RequestsOverview: React.FC<RequestsOverviewProps> = ({ providerId }) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const providerType = useProviderType()
  const formattedDate = formatDate(new Date()) as string

  const {
    facets: requestStatuses,
    count: statusCount,
    isLoading: isLoadingStatuses,
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status' }],
    filter: [
      {
        name: 'providerId',
        filters: [
          {
            operator: Operators.EqualTo,
            value: providerId,
          },
        ],
      },
    ],
  })

  const { count: overdueCount, isLoading: isLoadingOverdue } = useFetchFacets({
    key: FetchKey.InquiryOverdue,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'deadline', isEnum: true }],
    filter: [
      {
        name: 'providerId',
        filters: [
          {
            operator: Operators.EqualTo,
            value: providerId,
          },
        ],
      },
      {
        name: 'status',
        filters: [
          {
            operator: Operators.EqualTo,
            value: InquiryStatus.Requested,
          },
        ],
      },
      {
        name: 'deadline',
        filters: [
          {
            operator: Operators.LowerThan,
            value: formattedDate,
          },
        ],
      },
    ],
  })

  const responsesCount =
    requestStatuses?.[0]
      ?.filter(facet => facet.value !== InquiryStatus.Requested)
      ?.reduce((prev, curr) => prev + curr.count, 0) ?? 0

  if (isLoadingStatuses || isLoadingOverdue) {
    return (
      <ListItemSkeleton
        avatar
        listItemAvatarSx={{ minWidth: 64 }}
        avatarProps={{ size: AvatarSize.XL, sx: ({ palette }) => ({ bgcolor: alpha(palette.common.black, 0.04) }) }}
        primary
        secondary
      />
    )
  }

  return (
    <ListItem disablePadding sx={{ color: 'text.primary' }}>
      <ListItemButton
        disabled={!statusCount}
        component={Link}
        onClick={closeDrawer}
        to={generatePath(paths.resourceExplorer, {
          resourceType: providerType,
          resourceId: providerId,
          activeTabParam: AllTabs.Request,
        })}
      >
        <ListItemAvatar sx={{ minWidth: 64 }}>
          <Avatar size={AvatarSize.XL} sx={({ palette }) => ({ bgcolor: alpha(palette.common.black, 0.04) })}>
            <MarkEmailUnreadOutlined color="primary" />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={formatMessage({ id: 'companyOverview.questionnaires' }, { count: statusCount })}
          secondary={formatMessage(
            { id: 'companyOverview.responseInfo' },
            { responses: responsesCount, overdue: overdueCount },
          )}
        />

        <ChevronRightOutlined color="action" />
      </ListItemButton>
    </ListItem>
  )
}

export default RequestsOverview
