import * as Sentry from '@sentry/react'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { NotificationSeverity } from '@app/src/wf-constants'

export type Login = (
  role: string,
  solution: string,
  organization: number,
  redirect?: (solution: string) => void,
) => Promise<void>

const useLogin = (): Login => {
  const { formatMessage } = useIntl()
  const { getToken } = useAuthentication()
  const queryClient = useQueryClient()
  const history = useHistory()
  const { showSnackbar } = useSnackbar()

  return useCallback(
    async (role, solution: string, organization: number, redirect?: (solution: string) => void) => {
      try {
        const token = await getToken({ role, solution: solution, organization })
        if (token) {
          queryClient.clear()
          redirect && redirect(solution)
        } else {
          throw new Error('err_selecting_solution')
        }
      } catch (e) {
        Sentry.captureException(e)
        console.error(e)
        showSnackbar({
          message: formatMessage({ id: 'error.err_selecting_solution' }),
          severity: NotificationSeverity.error,
        })
      }
    },
    [history, getToken, queryClient, showSnackbar, formatMessage],
  )
}

export default useLogin
