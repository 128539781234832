import { FlagIndicator } from '@app/src/components/Table/Cells/FlagIndicator'
import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import * as CSS from 'csstype'
import React from 'react'

interface FlagIndicatorCellProps {
  count: number
  disabled?: boolean
  disableCell?: boolean
  bgcolor: CSS.Property.BackgroundColor
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const FlagIndicatorCell: React.FC<FlagIndicatorCellProps> = ({ count, disabled, disableCell, bgcolor, onClick }) => (
  <ParentCellOrFragment disableCell={disableCell} onClick={onClick}>
    <FlagIndicator label={count} disabled={disabled} bgcolor={bgcolor} />
  </ParentCellOrFragment>
)

export default FlagIndicatorCell
