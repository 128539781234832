import FlagAvatar from '@app/src/components/Flags/FlagAvatar'
import FlagSuggestedRiskAvatar from '@app/src/components/Flags/FlagSuggestedRiskAvatar'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAccount } from '@app/src/context/AccountContext'
import CustomQuestionnaireFlag from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/CustomQuestionnaireFlag'
import SuggestedRiskGuidanceBuilder from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/Flags/SuggestedRiskGuidanceBuilder'
import { ActivationType, ConditionType, ReportFlagRule } from '@app/src/types/flags'
import { QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { QuestionTypes, WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import { Delete } from '@mui/icons-material'
import { Alert, alpha, Box, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { FormDataRules } from './CreateAutomation'
import ReportFlagOptionRules from './ReportFlagOptionRules'
import { RequestItemWithOrderNumbers } from './ReportFlagRules'

type CreateAutomationContentProps = {
  requestItem: RequestItemWithOrderNumbers
  item: Partial<FormDataRules>
  index: number
  questionnaireTemplateType?: QuestionnaireTypeEnum
  remove: () => void
}

const CreateAutomationContent: React.FC<CreateAutomationContentProps> = ({
  requestItem,
  item,
  index,
  questionnaireTemplateType,
  remove,
}) => {
  const { account } = useAccount()
  const { formatMessage } = useIntl()
  const { control, watch } = useFormContext()

  const startOfName = `rules[${index}]`
  const optionSelector = watch(`${startOfName}.conditionType`)
  const isRequired = requestItem.isRequired
  const isRuleActive = item?.activationType === ActivationType.Active
  const isCannotAnswerSelected = Boolean(
    watch().rules?.find((r: ReportFlagRule) => r?.conditionType === ConditionType.CannotAnswer),
  )

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">{formatMessage({ id: 'automations.flagRule' }, { number: index + 1 })}</Typography>
        <Stack direction="row">
          <Tooltip title={isRuleActive ? formatMessage({ id: 'automations.cannotDeleteActiveFlag' }) : ''}>
            <span>
              <IconButton disabled={isRuleActive} size="small" onClick={remove} data-testid="delete-automation-button">
                <Delete sx={{ color: isRuleActive ? 'primary' : 'disabled' }} />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </Stack>

      {isRequired && (
        <Box mt={2}>
          <Alert severity="info">{formatMessage({ id: 'automations.questionIsRequiredAlert' })}</Alert>
        </Box>
      )}

      <Typography variant="h6" mt={4} gutterBottom>
        {formatMessage({ id: 'automations.triggerSectionTitle' })}
      </Typography>

      <Controller
        name={`${startOfName}.conditionType`}
        required
        control={control}
        render={({ onChange, value }) => (
          <Box display="flex" flexDirection="column">
            <RadioGroup onChange={onChange} value={value}>
              {!isRequired && (
                <FormControlLabel
                  disabled={isCannotAnswerSelected}
                  control={<Radio />}
                  value={ConditionType.CannotAnswer}
                  label={formatMessage({ id: 'automations.triggerOptions.cannotAnswer' })}
                />
              )}
              {requestItem?.questionType.name === QuestionTypes.Options.toString() && (
                <FormControlLabel
                  control={<Radio />}
                  value={ConditionType.Options}
                  label={
                    requestItem.allowMultiChoice
                      ? formatMessage({ id: 'automations.triggerOptions.multiOptions' })
                      : formatMessage({ id: 'automations.triggerOptions.singleOption' })
                  }
                />
              )}
            </RadioGroup>
          </Box>
        )}
      />

      {optionSelector === ConditionType.Options && (
        <ReportFlagOptionRules
          name={`${startOfName}.checkValue`}
          requestItem={requestItem}
          existingCheckValues={item?.checkValue}
        />
      )}

      <Typography variant="h6" mt={4} gutterBottom>
        {formatMessage({ id: 'automations.actionSectionTitle' })}
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        borderRadius={1}
        mt={2}
        p={2}
        bgcolor={({ palette }) => alpha(palette.common.black, 0.04)}
      >
        {item.creatorOrganizationId === WF_ORGANIZATION_ID ? (
          <>
            <FlagSuggestedRiskAvatar size={AvatarSize.Small} />
            <Typography ml={1} variant="body1">
              {formatMessage({ id: 'automations.suggestedRiskGuidance.flagAnswerInDatahub' })}
            </Typography>
          </>
        ) : (
          <>
            <FlagAvatar size={AvatarSize.Small} />
            <Typography ml={1} variant="body1">
              {formatMessage({ id: 'automations.flagAnswerInDatahub' })}
            </Typography>
          </>
        )}
      </Box>

      {account?.organization?.id === WF_ORGANIZATION_ID ? (
        <SuggestedRiskGuidanceBuilder suggestedRiskGuidance={item?.suggestedRiskGuidance} index={index} />
      ) : (
        <CustomQuestionnaireFlag
          startOfName={startOfName}
          defaultValues={{ contentTitle: item?.contentTitle, content: item?.content }}
          isTemplateShared={questionnaireTemplateType === QuestionnaireTypeEnum.Shared}
        />
      )}
    </>
  )
}

export default CreateAutomationContent
