import { Stack, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { NotificationSeverity } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'

type DrawerViewDeleteResourceProps = {
  fetchKey: FetchKey
  resourceId?: number
  solution: string
  resourceType?: string
  invalidateTimeoutMS?: number
} & Omit<DrawerViewProps, 'title' | 'buttons'>

const DrawerViewDeleteResource: React.FC<DrawerViewDeleteResourceProps> = ({
  fetchKey,
  resourceId,
  solution,
  resourceType,
  invalidateTimeoutMS = 0,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { mutate } = useDeleteResource()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { closeDrawer } = useDrawer()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true)

    mutate(
      { url: endpoints.deleteResource(solution, resourceType, resourceId) },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          setTimeout(() => {
            queryClient.invalidateQueries({
              predicate: query => {
                if (!Array.isArray(query.queryKey)) return query.queryKey === fetchKey
                return query.queryKey.includes(fetchKey) && !query.queryKey.includes(resourceId)
              },
            })

            setIsLoading(false)
            closeDrawer()
          }, invalidateTimeoutMS)
        },
        onError: error => {
          setIsLoading(false)
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'resourceExplorer.delete.header' })}
      buttons={[
        {
          label: formatMessage({ id: 'resourceCollections.general.delete' }),
          variant: 'contained',
          color: 'error',
          onClick: handleSubmit,
          loading: isLoading,
        },
      ]}
      {...props}
    >
      <Stack px={2}>
        <Typography variant="h5" paragraph>
          {formatMessage({ id: 'resourceExplorer.delete.title' })}
        </Typography>
        <Typography>{formatMessage({ id: 'resourceExplorer.delete.description' })}</Typography>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewDeleteResource
