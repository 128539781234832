import { CloseOutlined } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import SimpleRiskChipCell from '@app/src/components/Table/Cells/SimpleRiskChipCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { Risk } from '@app/src/types/resourceExplorer'

type RiskDialogContent = {
  providerName: string
  location?: string
  countryName?: string
  risk?: Risk
}

export type RiskScreeningInfoDialogProps = {
  riskDialogContent?: RiskDialogContent
  setRiskDialogContent: (content: RiskDialogContent | undefined) => void
}

const RiskScreeningInfoDialog: React.FC<RiskScreeningInfoDialogProps> = ({
  riskDialogContent,
  setRiskDialogContent,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog open={Boolean(riskDialogContent)} onClose={() => setRiskDialogContent(undefined)} fullWidth maxWidth="xs">
      <DialogTitle display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography variant="subtitle1">
            {formatMessage({ id: 'riskScreening.infoDialog.title' }, { providerName: riskDialogContent?.providerName })}
          </Typography>
          <Typography variant="caption">
            {formatMessage({ id: 'riskScreening.infoDialog.description' }, { location: riskDialogContent?.location })}
          </Typography>
        </Box>
        <IconButton size="small" onClick={() => setRiskDialogContent(undefined)}>
          <CloseOutlined fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Typography>{riskDialogContent?.countryName}</Typography>
          <SimpleRiskChipCell riskStatus={riskDialogContent?.risk?.status} disableCell />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default RiskScreeningInfoDialog
