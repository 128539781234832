export enum Operators {
  EqualTo = 'eq',
  NotEqualTo = 'neq',
  GreaterThan = 'gt',
  LowerThan = 'lt',
  GreaterThanOrEqual = 'gte',
  LowerThanOrEqual = 'lte',
  Contains = 'contains',
  NotContains = 'notcontains',
  AllowNulls = 'allownulls',
  NotAllowNulls = 'notallownulls',
  In = 'in',
  NotIn = 'notin',
  IsNull = 'isnull',
  IsNotNull = 'isnotnull',
}

export type FilterValue = string | number | string[] | number[] | boolean

export type Filter = {
  value?: FilterValue
  operator: Operators
}

export interface FilterGroup {
  name: string
  key?: string
  filters: Filter[]
  intersect?: boolean //Controls filter combination. If false: response.items.some(it => it.filter1 && it.filter2) if true: response.items.some(it => it.filter1) && response.items.some(it => it.filter2)
}

export interface FilterGroupQueryString extends FilterGroup {
  filters: FilterQueryString[]
}

export interface FilterQueryString extends Filter {
  uniqueId?: string
}

export const RESPONSE_LATEST_SUBMITTED_FILTER: FilterGroup = {
  name: 'isLatestSubmitted',
  filters: [{ operator: Operators.EqualTo, value: true }],
}

export const RESPONSE_ITEM_LATEST_SUBMITTED_FILTER: FilterGroup = {
  name: 'response.isLatestSubmitted',
  filters: [{ operator: Operators.EqualTo, value: true }],
}

export const RESQUEST_ITEM_IS_DELETED_FILTER: FilterGroup = {
  name: 'requestItem.template.deletedAt',
  filters: [{ operator: Operators.IsNull }],
}
