import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import DrawerViewAddMappingNode from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewAddMappingNode'
import DrawerViewReviewResponseValueChainToCopyToProduct from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewReviewResponseValueChainToCopyToProduct'
import DrawerViewSelectProductMappingResponse from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewSelectProductMappingResponse'
import DrawerViewSelectProductToCopyToOtherProduct from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewSelectProductToCopyToOtherProduct'
import { MappingResponseWithOrg } from '@app/src/pages/Product/ProductScene'
import { ActorTypeModel, Product } from '@app/src/types/product'
import { CopyAllOutlined, FiberNewOutlined, LinkOutlined } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type DrawerViewAddValueChainForProductProps = {
  product: Product
  latestMappingResponsesWithOrg: MappingResponseWithOrg[]
  selectableActorTypes: ActorTypeModel[]
  hasAvailableMappingNodes: boolean
} & Omit<DrawerViewProps, 'title'>

const DrawerViewAddValueChainForProduct: React.FC<DrawerViewAddValueChainForProductProps> = ({
  product,
  latestMappingResponsesWithOrg,
  selectableActorTypes,
  hasAvailableMappingNodes,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const handleSelectResponse = () => {
    if (latestMappingResponsesWithOrg.length > 1) {
      openDrawer(
        <DrawerViewSelectProductMappingResponse
          targetProduct={product}
          mappingResponses={latestMappingResponsesWithOrg}
        />,
      )
    } else {
      openDrawer(
        <DrawerViewReviewResponseValueChainToCopyToProduct
          organization={latestMappingResponsesWithOrg[0].responderOrganization}
          mappingResponse={latestMappingResponsesWithOrg[0].response}
          targetProduct={product}
        />,
      )
    }
  }

  return (
    <DrawerView title={formatMessage({ id: 'schemas.mappingNode.addChain' })} {...props}>
      <List>
        <ListItemButton
          onClick={() =>
            openDrawer(<DrawerViewAddMappingNode product={product} selectableActorTypes={selectableActorTypes} />)
          }
          sx={{ minHeight: 76 }}
          data-testid="create-new-value-chain"
        >
          {product.mappingNodes.length > 1 ? (
            <>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({ id: 'schemas.mappingNode.createNewValueChain' })}
                secondary={formatMessage({ id: 'schemas.mappingNode.createNewValueChainDescription' })}
              />
            </>
          ) : (
            <>
              <ListItemIcon>
                <FiberNewOutlined />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({ id: 'schemas.mappingNode.createNewValueChain' })}
                secondary={formatMessage({ id: 'schemas.mappingNode.createNewValueChainDescription' })}
              />
            </>
          )}
        </ListItemButton>
        <Divider />
        <ListItemButton
          disabled={!hasAvailableMappingNodes}
          onClick={() =>
            openDrawer(
              <DrawerViewSelectProductToCopyToOtherProduct
                targetProduct={product}
                productIndustry={product.industry}
              />,
            )
          }
          sx={{ minHeight: 76 }}
          data-testid="copy-existing-value-chain"
        >
          <ListItemIcon>
            <CopyAllOutlined />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'schemas.mappingNode.copyExistingValueChain' })}
            secondary={formatMessage({ id: 'schemas.mappingNode.copyExistingValueChainDescription' })}
          />
        </ListItemButton>
        <Divider />
        <ListItemButton
          disabled={!latestMappingResponsesWithOrg.length}
          onClick={handleSelectResponse}
          sx={{ minHeight: 76 }}
          data-testid="connect-existing-product"
        >
          <ListItemIcon>
            <LinkOutlined />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'schemas.mappingNode.addValueChainFromResponse' })}
            secondary={formatMessage({ id: 'schemas.mappingNode.addValueChainDescription' })}
          />
        </ListItemButton>
        <Divider />
      </List>
    </DrawerView>
  )
}
export default DrawerViewAddValueChainForProduct
