import { Box, Button, Fade } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import Dialog from '@app/src/components/Dialog'
import TextField from '@app/src/components/Ui/TextField'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Category, CategoryOption } from '@app/src/types/categories'
import { br } from '@app/src/utils/translationMarkup'
import { CreationLimits } from '@app/src/wf-constants'

interface CategoryFormProps {
  defaultValue?: string
  id?: number
  placeholder: string
  onSave: (values: Omit<Category, 'id'>) => void
  onCancel?: true | (() => void)
  showButtonsOnChange?: boolean
  disabled?: boolean
  categories?: Category[]
  categoryOptions?: CategoryOption[]
}

const useCategoryFormStyles = makeStyles(({ spacing }) => ({
  textField: {
    marginBottom: spacing(2),
  },
  textFieldRoot: (props: Pick<CategoryFormProps, 'disabled'>): {} => ({
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    paddingTop: props.disabled ? 8 : 'inherit',
  }),
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > button': {
      width: '49%',
    },
  },
}))

export const RESET_FORM = true

const CategoryForm: React.FC<CategoryFormProps> = ({
  onSave,
  placeholder,
  onCancel,
  showButtonsOnChange,
  defaultValue = '',
  disabled,
  categories,
  categoryOptions,
}) => {
  const classes = useCategoryFormStyles({ disabled })
  const [showButtons, setShowButtons] = useState(!showButtonsOnChange)
  const { handleSubmit, register, reset, errors } = useForm<Omit<Category, 'id'>>({
    defaultValues: { name: defaultValue },
    mode: 'onChange',
  })
  const { formatMessage } = useIntl()
  const [isDialogOpen, openDialog, closeDialog] = useDialogState(false)
  const isEditMode = placeholder === formatMessage({ id: 'category.editCategoryName' })

  return (
    <form
      onSubmit={handleSubmit(value => {
        if (!isEditMode && categoryOptions && categoryOptions.length >= CreationLimits.CategoryOptions) {
          openDialog()
          return
        }
        if (showButtonsOnChange) {
          setShowButtons(false)
        }
        onSave(value)
        if (defaultValue === '') {
          reset()
        }
      })}
    >
      <Box>
        <TextField
          autoFocus
          onChange={e => {
            if (showButtonsOnChange && e.target.value === '') {
              setShowButtons(false)
            } else {
              setShowButtons(true)
            }
          }}
          inputRef={register({
            validate: {
              isNotSpace: value => {
                return value.trim() !== '' || formatMessage({ id: 'category.validation.required' })
              },
              isUnique: value => {
                if (categories) {
                  return (
                    categories.findIndex(c => c.name.toLowerCase() === value.toLowerCase().trim()) === -1 ||
                    formatMessage({ id: 'category.validation.category.alreadyExists' })
                  )
                }
                if (categoryOptions) {
                  return (
                    categoryOptions.findIndex(co => co.name.toLowerCase() === value.toLowerCase().trim()) === -1 ||
                    formatMessage({ id: 'category.validation.categoryOption.alreadyExists' })
                  )
                }
                return true
              },
            },
            required: formatMessage({ id: 'category.validation.required' }),
            maxLength: {
              value: 60,
              message: formatMessage({ id: 'errorMessages.general.maxLength' }, { count: 60 }),
            },
          })}
          disabled={disabled}
          className={classes.textField}
          InputProps={{
            classes: {
              root: classes.textFieldRoot,
            },
          }}
          variant={disabled ? 'standard' : 'filled'}
          fullWidth
          name="name"
          label={placeholder}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
        />
        <Fade in={showButtons}>
          <Box className={classNames({ [classes.buttons]: onCancel })}>
            {onCancel && (
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  if (showButtonsOnChange) setShowButtons(false)
                  if (typeof onCancel === 'function') {
                    onCancel()
                  }
                  reset()
                }}
              >
                {formatMessage({ id: 'general.cancel' })}
              </Button>
            )}
            <Button size="large" variant="contained" fullWidth={!onCancel} type="submit">
              {formatMessage({ id: 'general.confirm' })}
            </Button>
          </Box>
        </Fade>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        title={formatMessage({ id: 'category.categoryOptions.limitExceeded' })}
        content={formatMessage(
          { id: 'category.categoryOptions.limitExplanation' },
          { categoryOptionLimit: CreationLimits.CategoryOptions, br },
        )}
        buttons={[
          {
            label: formatMessage({ id: 'general.close' }),
            variant: 'outlined',
            onClick: closeDialog,
          },
        ]}
      />
    </form>
  )
}

export default CategoryForm
