import { FetchFacetsOptions } from '@app/src/api/fetchHooks'
import CommonFilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/CommonFilterFacetSelect'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FacetItem } from '@app/src/pages/ResourceCollection/index'
import { AutocompleteGetTagProps, AutocompleteProps } from '@mui/material'
import React from 'react'
import { useFiltersContext } from './Filters'

type OnChangeMultiple = (value: string[], replace?: boolean) => void
type OnChangeSingle = (value: string | null, replace?: boolean) => void

export interface FilterFacetSelectCommonProps {
  disabled?: boolean
  filterName: string
  fieldLabel?: string
  required?: boolean
  facetsParam: FetchFacetsOptions
  size?: AutocompleteProps<never, never, never, never>['size']
  actionButtons?: JSX.Element
  modifiers?: JSX.Element
  renderOption?: AutocompleteProps<FacetItem, boolean, false, false, 'div'>['renderOption']
  renderTags?: (value: FacetItem[], getTagProps: AutocompleteGetTagProps) => JSX.Element[]
  implicitFilters?: FilterGroup[]
  replaceUrl?: boolean
  skipNotSet?: boolean
  filterOptions?: AutocompleteProps<FacetItem, boolean, undefined, undefined>['filterOptions']
  forceFetch?: boolean
  variant?: 'outlined' | 'filled' | 'standard' | undefined
  disableAutoFilter?: boolean
  selectAll?: boolean
  uniqueId?: string
  customSort?: (a: FacetItem, b: FacetItem) => number
}

export interface SingleModeProps {
  multiple: false
  value: string
  onChange: OnChangeSingle
}

export interface MultipleModeProps {
  multiple: true
  value: string[]
  onChange: OnChangeMultiple
}

export type FilterFacetSelectTruncateProps = SingleModeProps | MultipleModeProps

export type FilterFacetSelectProps = FilterFacetSelectCommonProps & FilterFacetSelectTruncateProps

const FilterFacetSelect = (props: FilterFacetSelectProps): JSX.Element => {
  const { apiFilters } = useFiltersContext()
  return <CommonFilterFacetSelect {...props} apiFilters={apiFilters} />
}

export default FilterFacetSelect
