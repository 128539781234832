import { Business } from '@mui/icons-material'
import { Avatar, Box, Button, Grid, Paper, Theme, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 530,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.grey[400],
  },
  avatar: {
    width: 92,
    height: 92,
  },
}))

const AddOrganizationCard = ({ openDrawer }: { openDrawer: () => void }): JSX.Element => {
  const { formatMessage } = useIntl()
  const classes = useStyles()

  return (
    <Grid item xl={3} lg={4} md={6} xs={12} data-intercom-target="add-organization">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Business fontSize="large" />
        </Avatar>
        <Box marginTop={3}>
          <Tooltip
            arrow
            title={
              <>
                <Typography variant="h6">{formatMessage({ id: 'claim.addTooltip.title' })}</Typography>
                <Typography mt={2}>{formatMessage({ id: 'claim.addTooltip.line1' })}</Typography>
                <Typography mt={2}>{formatMessage({ id: 'claim.addTooltip.line2' })}</Typography>
              </>
            }
          >
            <Button variant="outlined" onClick={openDrawer} data-cy="add-organization-card">
              {formatMessage({ id: 'claim.addOrganization' })}
            </Button>
          </Tooltip>
        </Box>
      </Paper>
    </Grid>
  )
}

export default AddOrganizationCard
