import LoadingButton from '@app/src/components/LoadingButton'
import useLocalStorage from '@app/src/hooks/localStorage'
import { useUpdateSharing } from '@app/src/hooks/updateSharing'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { distinctBy } from '@app/src/utils/helpersTs'
import { AvatarGroup } from '@mui/lab'
import { Button, Dialog, DialogContent, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'

function GiveAccessNudgeDialog() {
  const {
    requestId,
    currentAccess,
    showAccessNudge,
    setShowAccessNudge,
    setLoadingAccessNudge,
    providerInquiries,
    isFetchingProviderInquiries,
  } = useReport()
  const { formatMessage } = useIntl()
  const [nudgesAboutRequestingOrganizationIdsPerRequest, setNudgesAboutRequestingOrganizationIdsPerRequest] =
    useLocalStorage<Record<number, number[]>>(`requesting-organization-ids-nudged-about-per-request`, {})
  const { isUpdatingSharing, updateSharing } = useUpdateSharing()

  const organizationIdsNudgedAboutBefore = nudgesAboutRequestingOrganizationIdsPerRequest?.[requestId ?? 0] ?? []

  //To remember the initial value after we change in local storage
  const initialOrganizationIdsNudgedAboutBefore = useMemo(() => organizationIdsNudgedAboutBefore, [requestId])

  const uniqueOrganizationsThatWantAccess = distinctBy(
    providerInquiries?.map(inq => inq.creatorOrganization) ?? [],
    org => org.id,
  )

  const organizationWithoutAccess = uniqueOrganizationsThatWantAccess.filter(
    i => !currentAccess?.organizationIds?.includes(i.id),
  )

  const anyNotNotifiedOrganizationsWithoutAccess = organizationWithoutAccess.some(
    i => !organizationIdsNudgedAboutBefore?.includes(i.id),
  )

  const handleClose = () => {
    setShowAccessNudge(false)
  }

  useEffect(() => {
    setLoadingAccessNudge(true)

    if (!requestId || !anyNotNotifiedOrganizationsWithoutAccess) return

    setShowAccessNudge(true)
    setNudgesAboutRequestingOrganizationIdsPerRequest(currentValue => ({
      ...currentValue,
      [requestId]: organizationWithoutAccess.map(o => o.id),
    }))
    setLoadingAccessNudge(false)
  }, [anyNotNotifiedOrganizationsWithoutAccess, requestId])

  useEffect(() => {
    setLoadingAccessNudge(true)

    if (requestId && !isFetchingProviderInquiries) {
      setLoadingAccessNudge(false)
    }
  }, [requestId, isFetchingProviderInquiries])

  const shouldShowNewQuestionnaireNudge =
    !currentAccess?.organizationIds?.length && !initialOrganizationIdsNudgedAboutBefore?.length

  const moreThanOneRequestor = organizationWithoutAccess.length > 1
  if (!organizationWithoutAccess.length) {
    return null
  }

  return (
    <Dialog open={showAccessNudge} onClose={handleClose} maxWidth="xs">
      <DialogContent>
        <AvatarGroup max={2} sx={{ display: 'flex', justifyContent: 'start', mb: 1 }} variant="rounded">
          {organizationWithoutAccess.map(org => (
            <CompanyAvatar key={org.id} organizationName={org?.name} imageUrl={org?.image?.url} variant="rounded" />
          ))}
        </AvatarGroup>
        <Typography variant="subtitle1">
          {formatMessage(
            {
              id: `reporting.sharing.nudge.${
                shouldShowNewQuestionnaireNudge ? 'titleNewQuestionnaire' : 'titleExistingQuestionnaire'
              }`,
            },
            {
              firstCompanyName: organizationWithoutAccess?.[0]?.name,
              otherCompanyCount: organizationWithoutAccess?.length - 1,
            },
          )}
        </Typography>
        <Typography variant="body1">
          {moreThanOneRequestor
            ? formatMessage({ id: 'reporting.sharing.nudge.descriptionManyRequestors' })
            : formatMessage(
                { id: 'reporting.sharing.nudge.descriptionSingleRequestor' },
                { companyName: organizationWithoutAccess?.[0]?.name },
              )}
        </Typography>
        <Button variant="outlined" onClick={handleClose} fullWidth sx={{ mt: 3 }} data-testid="manage-access">
          {formatMessage({ id: 'reporting.sharing.nudge.manageAccess' })}
        </Button>
        <LoadingButton
          sx={{ mt: 2 }}
          variant="contained"
          onClick={async () => {
            await updateSharing({
              requestId: requestId ?? 0,
              organizationIdsToShareWith: uniqueOrganizationsThatWantAccess.map(o => o.id),
            })
            handleClose()
          }}
          fullWidth
          loading={isUpdatingSharing}
          data-testid="approve"
        >
          {formatMessage({
            id: `reporting.sharing.nudge.${moreThanOneRequestor ? 'respondToAll' : 'approve'}`,
          })}
        </LoadingButton>
        <Button variant="text" onClick={handleClose} fullWidth sx={{ mt: 2 }} data-testid="cancel">
          {formatMessage({ id: 'general.cancel' })}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default GiveAccessNudgeDialog
