import ConnectProvidersModalContext, { EmailPurpose } from '@app/src/context/ConnectProvidersModalContext'
import React, { useMemo, useState } from 'react'
import { useDialogState } from '../hooks/mui-hooks'
import { Provider } from '../types/organizations'

interface Props {
  children: JSX.Element
}

const ConnectProvidersModalContextProvider = ({ children }: Props): JSX.Element => {
  const [isConnectDialogOpen, openConnectDialog, closeConnectDialog] = useDialogState(false)
  const [providersToConnect, setProvidersToConnect] = useState<Provider[]>([])
  const [emailPurpose, setEmailPurpose] = useState<EmailPurpose>(EmailPurpose.InitialInvitation)

  const updateProviderToConnect = (updatedProvider: Provider) => {
    setProvidersToConnect(prev => [...prev.filter(p => p.id !== updatedProvider.id), updatedProvider])
  }

  const handleOpenConnectDialog = (providers: Provider[], reminderType: EmailPurpose) => {
    setProvidersToConnect(providers)
    setEmailPurpose(reminderType)
    openConnectDialog()
  }

  const handleCloseConnectDialog = () => {
    closeConnectDialog()
    setProvidersToConnect([])
  }

  const contextValue = useMemo(
    () => ({
      isConnectDialogOpen,
      handleCloseConnectDialog,
      handleOpenConnectDialog,
      providersToConnect,
      updateProviderToConnect,
      emailPurpose,
    }),
    [isConnectDialogOpen, closeConnectDialog, handleOpenConnectDialog, providersToConnect, setProvidersToConnect],
  )

  return <ConnectProvidersModalContext.Provider value={contextValue}>{children}</ConnectProvidersModalContext.Provider>
}

export default ConnectProvidersModalContextProvider
