import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FacetItem } from '@app/src/pages/ResourceCollection/index'
import { SortOrder } from '@app/src/types/filter'
import React from 'react'
import { useIntl } from 'react-intl'

interface QuestionCategoryFilterProps {
  fieldLabelId: string
  categoryName: string
  implicitFilters: FilterGroup[] | undefined
  customSort?: (a: FacetItem, b: FacetItem) => number
}

const QuestionCategoryFilter: React.FC<QuestionCategoryFilterProps> = ({
  fieldLabelId,
  categoryName,
  implicitFilters,
  customSort,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Filter name="options.id" operator={Operators.In} uniqueId={categoryName} intersect>
      {({ value, onChange, filterName }) => (
        <FilterFacetSelect
          implicitFilters={[
            ...(implicitFilters ?? []),
            {
              name: 'options.category.name',
              filters: [{ value: categoryName, operator: Operators.EqualTo }],
            },
          ]}
          size="small"
          multiple
          facetsParam={{
            key: [FetchKey.QuestionFacets, filterName, categoryName],
            endpoint: endpoints.questionWithFacets,
            facetsParam: [{ name: 'options.name' }],
            sort: { target: 'label', order: SortOrder.ASCENDING },
          }}
          filterName={filterName}
          onChange={onChange}
          fieldLabel={formatMessage({ id: fieldLabelId })}
          value={[value].flat()}
          uniqueId={categoryName}
          customSort={customSort}
        />
      )}
    </Filter>
  )
}

export default QuestionCategoryFilter
