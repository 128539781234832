import LinkButton from '@app/src/components/LinkButton'
import { Circle } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'

interface GraphLegendProps {
  color: string
  legend: string
  linkTo?: string
  onClick?: () => void
  disabled?: boolean
  variant?: 'caption' | 'body1'
}

const GraphLegend: React.FC<GraphLegendProps> = ({ color, legend, linkTo, onClick, disabled, variant = 'caption' }) => {
  if (linkTo) {
    return (
      <LinkButton sx={{ py: 1 }} startIcon={<Box width={4} height={16} bgcolor={color} borderRadius={2} />} to={linkTo}>
        <Typography variant={variant}>{legend}</Typography>
      </LinkButton>
    )
  }

  if (onClick) {
    return (
      <Button disabled={disabled} sx={{ py: 1 }} startIcon={<Circle sx={{ color }} />} onClick={onClick}>
        <Typography variant={variant}>{legend}</Typography>
      </Button>
    )
  }

  return (
    <Stack py={1} direction="row" spacing={1} alignItems="center">
      <Box width={4} minWidth={4} height={16} bgcolor={color} borderRadius={2} />
      <Typography variant={variant}>{legend}</Typography>
    </Stack>
  )
}

export default GraphLegend
