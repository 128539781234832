import StatusCell from '@app/src/components/Table/Cells/StatusCell'
import ExternalVerificationStatusItem from '@app/src/pages/Questionnaire/ExternalVerificationStatusItem'
import InternalVerificationStatusItem from '@app/src/pages/Questionnaire/InternalVerificationStatusItem'
import { palette } from '@app/src/theme/palette'
import {
  ExternalVerificationStatus,
  InternalVerificationStatus,
  VerificationResponseItem,
} from '@app/src/types/resourceExplorer'
import { Box, alpha } from '@mui/material'
import React from 'react'

interface Props {
  verifications?: VerificationResponseItem[]
}

const VerificationStatus = ({ verifications }: Props): JSX.Element | null => {
  if (!verifications?.length) {
    return null
  }

  const approvedExternalVerifications = verifications?.filter(
    riv => riv.externalVerificationStatus === ExternalVerificationStatus.Approved,
  )
  const correctionExternalNeededVerifications = verifications?.filter(
    riv => riv.externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded,
  )
  const internalVerifications = verifications?.filter(
    riv => riv.internalVerificationStatus !== InternalVerificationStatus.NotSet,
  )

  return (
    <Box pt={1}>
      {Boolean(correctionExternalNeededVerifications.length) && (
        <Box mt={2} p={2} borderLeft={`4px solid ${palette.warning.main}`}>
          <StatusCell verification value={ExternalVerificationStatus.CorrectionNeeded} disableCell />
          {correctionExternalNeededVerifications.map(verification => (
            <ExternalVerificationStatusItem verification={verification} key={verification.id} />
          ))}
        </Box>
      )}

      {Boolean(approvedExternalVerifications.length) && (
        <Box mt={2} p={2} borderLeft={`4px solid ${palette.success.main}`}>
          <StatusCell verification value={ExternalVerificationStatus.Approved} disableCell />
          {approvedExternalVerifications.map(verification => (
            <ExternalVerificationStatusItem verification={verification} key={verification.id} />
          ))}
        </Box>
      )}

      {Boolean(internalVerifications.length) && (
        <Box mt={1} p={2} border={({ palette }) => `1px solid ${alpha(palette.common.black, 0.12)}`} borderRadius={1}>
          {internalVerifications.map(verification => (
            <InternalVerificationStatusItem verification={verification} key={verification.id} />
          ))}
        </Box>
      )}
    </Box>
  )
}

export default VerificationStatus
