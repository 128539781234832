import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewUserGroup from '@app/src/components/Drawer/Views/DrawerViewUserGroup'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { User } from '@app/src/types/resourceExplorer'
import { PersonOffOutlined } from '@mui/icons-material'
import { AvatarGroup, Box, ButtonBase, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'
import UserCell, { UserCellProps } from './UserCell'

type UserGroupCellProps = {
  users?: User[]
  disableCell?: boolean
  groupSize?: number
} & UserCellProps

const UserGroupCell: React.FC<UserGroupCellProps> = ({ users, disableCell, groupSize = 3, drawer, ...props }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  if (!users?.length) {
    return (
      <ParentCellOrFragment disableCell={disableCell}>
        <Box display="flex" gap={1}>
          <Avatar variant="circular" size={AvatarSize.Small}>
            <PersonOffOutlined fontSize="inherit" />
          </Avatar>
          <Typography color="textSecondary">
            {formatMessage({ id: 'resourceExplorer.provider.missingContact' })}
          </Typography>
        </Box>
      </ParentCellOrFragment>
    )
  }

  if (users.length === 1) {
    return <UserCell user={users[0]} drawer={drawer} {...props} subTitle="" />
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        component={drawer ? ButtonBase : Stack}
        onClick={drawer ? () => openDrawer(<DrawerViewUserGroup subTitle={props.subTitle} users={users} />) : undefined}
      >
        <AvatarGroup
          max={groupSize}
          sx={{
            '& .MuiAvatar-root': {
              width: 24,
              height: 24,
              fontSize: 12,
            },
          }}
        >
          {users.map(user => (
            <PersonAvatar key={user.id} name={user.name ?? ''} size={AvatarSize.Small} />
          ))}
        </AvatarGroup>
        <Typography variant="caption">{formatMessage({ id: 'general.contacts' }, { count: users.length })}</Typography>
      </Stack>
    </ParentCellOrFragment>
  )
}

export default UserGroupCell
