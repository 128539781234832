import QuestionCategoryOptionSelector from '@app/src/pages/QuestionEditor/QuestionCategoryOptionSelector'
import { ESRS_CATEGORIES, REGULAR_QUESTION_CATEGORIES } from '@app/src/wf-constants/questionCategories'
import { Grid, Typography, alpha } from '@mui/material'
import React from 'react'
import { Control } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface EditQuestionCategorySelectorProps {
  control: Control
}

const EditQuestionCategories: React.FC<EditQuestionCategorySelectorProps> = ({ control }) => {
  const { formatMessage } = useIntl()

  return (
    <Grid container py={3} justifyContent="center">
      <Grid
        container
        item
        spacing={3}
        xs={6}
        pt={2}
        pb={3}
        pl={2}
        pr={5}
        border={`1px solid ${alpha('#000', 0.12)}`}
        borderRadius={2}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {formatMessage({ id: 'templateBuilder.questionTags' })}
          </Typography>
          {REGULAR_QUESTION_CATEGORIES.map((q, index) => (
            <QuestionCategoryOptionSelector key={q.name} questionCategory={q} index={index} control={control} />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {formatMessage({ id: 'schemas.question.esrs' })}
          </Typography>
          {ESRS_CATEGORIES.map((q, index) => (
            <QuestionCategoryOptionSelector
              key={q.name}
              questionCategory={q}
              index={REGULAR_QUESTION_CATEGORIES.length + index}
              control={control}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditQuestionCategories
