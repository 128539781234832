import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InfoOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import StatisticsCard from '../StatisticsCard'

const SharedWithNetworkCard = () => {
  const { formatMessage } = useIntl()

  const { items: templates, isLoading: isTemplatesLoading } = useFetchFacets({
    key: FetchKey.RequestTemplateFacets,
    endpoint: endpoints.worldfavorTemplatesFacets,
    facetsParam: [
      {
        name: 'id',
      },
    ],
  })

  const { items: sharedRequests, isLoading: isSharedRequestsLoading } = useFetchFacets({
    key: FetchKey.RequestFacets,
    endpoint: endpoints.requestsWithFacets,
    facetsParam: [
      {
        name: 'template.id',
      },
    ],
    filter: [
      {
        name: 'IsAllVisibleWorldfavorNetwork',
        filters: [
          {
            value: true,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
  })

  const removeDuplicatesByValue = (array: FacetItem[]) => {
    const uniqueMap = new Map()

    for (const item of array) {
      uniqueMap.set(item.value, item)
    }

    return Array.from(uniqueMap.values())
  }

  if (isTemplatesLoading || isSharedRequestsLoading) {
    return (
      <Box height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  const templatesCount = templates?.[0]?.total ?? 0
  const sharedUniqueTemplatesCount = removeDuplicatesByValue(sharedRequests[0].items).length

  return (
    <StatisticsCard
      title={formatMessage({ id: 'transparencyOverview.topicsSharedWithNetwork' })}
      justifyContent="left"
      withBorder
      action={
        <Tooltip title={formatMessage({ id: 'transparencyOverview.sharedWithCompaniesGuidance' })} arrow>
          <InfoOutlined color="primary" fontSize="small" />
        </Tooltip>
      }
    >
      <Box display="flex" alignItems="center" py={2}>
        <Typography variant="kpi">{`${sharedUniqueTemplatesCount}/${templatesCount}`}</Typography>
      </Box>
    </StatisticsCard>
  )
}

export default SharedWithNetworkCard
