import _IntervalChartIconOne from '@app/src/pages/Assessments/icons/ThreeIntervals/IntervalChartIconOne'
import _IntervalChartIconThree from '@app/src/pages/Assessments/icons/ThreeIntervals/IntervalChartIconThree'
import _IntervalChartIconTwo from '@app/src/pages/Assessments/icons/ThreeIntervals/IntervalChartIconTwo'
import { FC } from 'react'

interface IntervalChartIconProps {
  large?: boolean
}

export const ThreeIntervalChartIconOne: FC<IntervalChartIconProps> = _IntervalChartIconOne
export const ThreeIntervalChartIconTwo: FC<IntervalChartIconProps> = _IntervalChartIconTwo
export const ThreeIntervalChartIconThree: FC<IntervalChartIconProps> = _IntervalChartIconThree
