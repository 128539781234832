import { Box, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import LinkButton, { LinkButtonProps } from '@app/src/components/LinkButton'
import WithBadge from '@app/src/components/Ui/WithBadge'
import React, { ReactNode } from 'react'
import { insertObjIf } from '@app/src/utils/helpersTs'
import LoadingButton, { LoadingButtonProps } from './LoadingButton'

export type ActionButton = {
  label: ReactNode
  badgeContent?: string | number
  badgeExpiresAt?: string
  tooltip?: string
} & (LoadingButtonProps | LinkButtonProps)

export interface ActionButtonsProps {
  classes?: string
  buttons: Array<ActionButton>
  fullWidthButtons?: boolean
  variants?: LoadingButtonProps['variant']
  sizes?: LoadingButtonProps['size']
}

const useStyles = makeStyles(({ spacing }) => ({
  badge: {
    marginRight: spacing(1),
  },
}))

const isLinkButton = (button: LoadingButtonProps | LinkButtonProps): button is LinkButtonProps => {
  return Boolean((button as LinkButtonProps).to)
}

const ActionButtons: React.FC<ActionButtonsProps> = props => {
  const classes = useStyles()
  return (
    <>
      {props.buttons?.map(button => {
        const key = button.variant + (typeof button.label === 'string' ? button.label : '')
        if (isLinkButton(button)) {
          return (
            <Tooltip title={button.tooltip} key={key}>
              <Box sx={insertObjIf(Boolean(props.fullWidthButtons), { width: '100%' })}>
                <WithBadge
                  key={key}
                  badgeContent={button.badgeContent}
                  classes={{ badge: classes.badge }}
                  expiresAt={button.badgeExpiresAt}
                >
                  <LinkButton
                    fullWidth={props.fullWidthButtons}
                    size={props.sizes}
                    variant={props.variants}
                    {...button}
                    className={props.classes}
                  >
                    {button.label}
                  </LinkButton>
                </WithBadge>
              </Box>
            </Tooltip>
          )
        }

        return (
          <Tooltip title={button.tooltip} key={key}>
            <Box sx={insertObjIf(Boolean(props.fullWidthButtons), { width: '100%' })}>
              <WithBadge
                badgeContent={button.badgeContent}
                classes={{ badge: classes.badge }}
                expiresAt={button.badgeExpiresAt}
              >
                <LoadingButton
                  fullWidth={props.fullWidthButtons}
                  size={props.sizes}
                  variant={props.variants}
                  {...button}
                  className={props.classes}
                >
                  {button.label}
                </LoadingButton>
              </WithBadge>
            </Box>
          </Tooltip>
        )
      })}
    </>
  )
}

export default ActionButtons
