import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import React from 'react'
import { Question } from '@app/src/types/resourceExplorer'

export interface QuestionCheckboxCellProps {
  question: Question
  selectedQuestionIds: Array<number>
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, question: Question) => void
  questionIdsAlreadyInQuestionnaire: Array<number>
  setSelectedQuestionIds?: React.Dispatch<React.SetStateAction<number[]>>
}

const QuestionCheckboxCell: React.FC<QuestionCheckboxCellProps> = ({
  question,
  selectedQuestionIds,
  onCheckboxChange,
  questionIdsAlreadyInQuestionnaire,
}) => {
  const isChecked = selectedQuestionIds?.some(questionId => questionId === question.id) ?? false
  const isAlreadyInQuestionnaire =
    questionIdsAlreadyInQuestionnaire?.some(questionId => questionId === question.id) ?? false

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange?.(e, question)
  }

  return (
    <CheckboxCell
      checked={isAlreadyInQuestionnaire || isChecked}
      onChange={handleCheckboxChange}
      disabled={isAlreadyInQuestionnaire}
    />
  )
}

export default QuestionCheckboxCell
