import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import React from 'react'
import { useIntl } from 'react-intl'
import { SuggestedContact } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { Operators } from '../Filters/useFilters'
import ResourceCollectionScene, { ResourceCollectionSceneProps } from '../ResourceCollectionScene'
import SuggestedContactHeader from './SuggestedContact/SuggestedContactHeader'
import SuggestedContactRow from './SuggestedContact/SuggestedContactRow'

type SuggestedContactCollectionProps = {
  providerId?: string
  tabs?: ResourceCollectionSceneProps['tabs']
}

const SuggestedContactCollection: React.FC<SuggestedContactCollectionProps> = ({ providerId, tabs }) => {
  const { formatMessage } = useIntl()
  const [page, pageSize, setPage, setPageSize] = usePagination()

  const { items, count, isLoading, isError } = useFetchCollectionWithPost<SuggestedContact>({
    key: FetchKey.SuggestedContacts,
    endpoint: endpoints.suggestedContact,
    payload: {
      filter: [
        ...insertIf(Boolean(providerId), {
          filters: [
            {
              operator: Operators.EqualTo,
              value: providerId,
            },
          ],
          name: 'providerId',
        }),
      ],
      include: [],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <ResourceCollectionScene title={formatMessage({ id: 'configurations.suggested-contacts' })} embedded tabs={tabs}>
      <Table<SuggestedContact>
        RowComponent={SuggestedContactRow}
        HeaderComponent={SuggestedContactHeader}
        count={count}
        data={items}
        isLoading={isLoading}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </ResourceCollectionScene>
  )
}

export default SuggestedContactCollection
