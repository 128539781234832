import { Box, Button } from '@mui/material'
import * as Sentry from '@sentry/react'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useIntl } from 'react-intl'

import Card from '@app/src/components/Card'
import LinkButton from '@app/src/components/LinkButton'

export const logError = (error: Error, info: { componentStack: string }): void => {
  Sentry.captureException(error)
  console.error('%cOoops, seems like there was a serious issue: ', 'color: red; font-size: 18px')
  console.error(error)
  console.error(info.componentStack)
}

const GlobalError = ({ resetErrorBoundary }: FallbackProps): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
      <Card
        title={formatMessage({ id: 'general.oops' })}
        description={formatMessage({ id: 'general.somethingWentWrong' })}
        button={[
          <Button key="try-again" variant="contained" onClick={resetErrorBoundary}>
            {formatMessage({ id: 'general.tryAgain' })}
          </Button>,
          <LinkButton key="go-back" variant="outlined" to="/">
            {formatMessage({ id: 'general.letsGoBack' })}
          </LinkButton>,
        ]}
      />
    </Box>
  )
}

export default GlobalError
