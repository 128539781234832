import React, { useState } from 'react'

export const useDialogState = (initialState = false): [boolean, () => void, () => void] => {
  const [open, setOpen] = useState(initialState)

  function openDialog(): void {
    setOpen(true)
  }

  function closeDialog(): void {
    setOpen(false)
  }

  return [open, openDialog, closeDialog]
}

export const useMenuState = (): [
  boolean,
  HTMLElement | null,
  (event: React.MouseEvent<HTMLElement>) => void,
  () => void,
] => {
  const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(menuAnchorElement)
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setMenuAnchorElement(event.currentTarget)
  const closeMenu = () => setMenuAnchorElement(null)

  return [isMenuOpen, menuAnchorElement, openMenu, closeMenu]
}
