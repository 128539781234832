import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import FilterFacetSelect from './FilterFacetSelect'

const ProductMappingContactsFilters = ({ allowedFilters, resetPage }: FiltersProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <Box display="flex">
      <Filters resetPage={resetPage} allowedFilters={allowedFilters}>
        {({ inDrawerFiltersCount, isFiltered, clearQueryFilter }) => (
          <>
            <Box flexGrow={1} marginRight={1}>
              <Filter name="user.id" operator={Operators.In}>
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.ContactFacets, filterName],
                      endpoint: endpoints.contactsWithFacets,
                      facetsParam: [{ name: 'user.name' }],
                    }}
                    filterName={filterName}
                    onChange={value => onChange(value)}
                    fieldLabel={formatMessage({ id: 'schemas.contact.name' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Box>

            <Box display="inline-block">
              <AllFiltersButton
                inDrawerFiltersCount={inDrawerFiltersCount}
                openDrawer={() =>
                  openDrawer(
                    <DrawerViewFilters allowedFilters={allowedFilters}>
                      <Box px={2}>
                        <FilterSection label={formatMessage({ id: 'schemas.filter.contacts' })}>
                          <Filter name="user.id" operator={Operators.In}>
                            {({ value, onChange, filterName }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.ContactFacets, filterName],
                                  endpoint: endpoints.contactsWithFacets,
                                  facetsParam: [{ name: 'user.name' }],
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.contact.name' })}
                                value={[value].flat()}
                              />
                            )}
                          </Filter>
                        </FilterSection>
                        <FilterSection label={formatMessage({ id: 'schemas.contact.information' })}>
                          <Filter operator={Operators.In} name="user.email">
                            {({ value, onChange, filterName }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.Provider, filterName],
                                  endpoint: endpoints.contactsWithFacets,
                                  facetsParam: [{ name: 'user.email', isEnum: true }],
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.contact.email' })}
                                value={[value].flat()}
                              />
                            )}
                          </Filter>

                          <Filter operator={Operators.In} name="user.phoneNumber">
                            {({ value, onChange, filterName }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.Provider, filterName],
                                  endpoint: endpoints.contactsWithFacets,
                                  facetsParam: [{ name: 'user.phoneNumber', isEnum: true }],
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.contact.phone' })}
                                value={[value].flat()}
                              />
                            )}
                          </Filter>
                        </FilterSection>
                      </Box>
                    </DrawerViewFilters>,
                  )
                }
              />

              {isFiltered && (
                <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                  {formatMessage({ id: 'filters.clearAll' })}
                </Button>
              )}
            </Box>
          </>
        )}
      </Filters>
    </Box>
  )
}

export default ProductMappingContactsFilters
