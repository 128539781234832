import Avatar, { AvatarProps } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { capitalize } from '@app/src/utils/helpers'

export type CompanyAvatarProps = {
  organizationName: string
  imageUrl?: string
  unlinked?: boolean
} & Omit<AvatarProps, 'children' | 'src'>

const CompanyAvatar: React.FC<CompanyAvatarProps> = ({ organizationName, imageUrl, unlinked, ...props }) => (
  <Avatar alt={organizationName} src={imageUrl} sx={{ bgcolor: unlinked ? 'grey.500' : 'secondary.main' }} {...props}>
    {capitalize(organizationName?.[0])}
  </Avatar>
)

export default CompanyAvatar
