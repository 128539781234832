import { AddOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  alpha,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewCreateReferralContact from '@app/src/components/Drawer/Views/DrawerViewCreateReferralContact'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'
import PersonAvatar from '../../CellComponents/PersonAvatar'

type ProvidersAccordionProps = {
  title: ReactNode
  providers: Array<Provider>
  selectedProviderIds: Array<number>
  onChange: (providerId: number) => void
  icon?: ReactNode
} & Omit<AccordionProps, 'onChange' | 'children'>

const ProvidersAccordion: React.FC<ProvidersAccordionProps> = ({
  title,
  providers,
  selectedProviderIds,
  onChange,
  icon,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <Accordion
      elevation={0}
      disableGutters
      {...props}
      sx={[{ bgcolor: 'grey.100', '&:before': { position: 'unset' } }, ...[props.sx ?? []].flat()]}
    >
      <AccordionSummary expandIcon={<ExpandMoreOutlined fontSize="medium" sx={{ mx: 1 }} />} sx={{ py: 1 }}>
        <Stack direction="row">
          {icon}
          <Typography variant="body1" ml={3}>
            {title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List disablePadding>
          {providers.map(provider => (
            <ListItem
              key={provider.id}
              sx={{
                justifyContent: 'space-between',
                borderTop: `1px solid ${alpha('#000', 0.12)}`,
              }}
              disablePadding
            >
              <ListItemButton
                selected={selectedProviderIds.includes(provider.id)}
                onClick={() => onChange(provider.id)}
              >
                <Stack direction="row" alignItems="center" gap={1} width="60%">
                  <Checkbox
                    edge="start"
                    checked={selectedProviderIds.includes(provider.id)}
                    onChange={() => onChange(provider.id)}
                  />
                  <CompanyCell company={provider} disableCell data-testid="accordion-company-name" />
                </Stack>

                <Stack direction="row" width="40%">
                  {provider.referralContact ? (
                    <>
                      <PersonAvatar name={provider.referralContact?.name} size={AvatarSize.Small} disabled />
                      <Typography ml={1}>{provider.referralContact?.name}</Typography>
                    </>
                  ) : (
                    <Button
                      startIcon={<AddOutlined color="primary" />}
                      onClick={e => {
                        e.stopPropagation()
                        openDrawer(<DrawerViewCreateReferralContact provider={provider} onlySave />, {
                          showAboveDialogs: true,
                        })
                      }}
                    >
                      {formatMessage({ id: 'referral.contact.addContact' })}
                    </Button>
                  )}
                </Stack>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProvidersAccordion
